import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  Calendar as BigCalendar,
  momentLocalizer,
  Event,
  stringOrDate,
  View
} from "react-big-calendar";
import InfiniteScroll from "react-infinite-scroller";
import moment from "moment";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import Fader from "../../style-guide/Fader/Fader";
import Block from "../../style-guide/Block/Block";
import Section from "../../style-guide/Section/Section";
import Text from "../../style-guide/Text/Text";
import Crumb from "../../components/Crumb/Crumb";
import { useSearch } from "../../hooks/useApi";
import {
  Booking,
  BookingResource,
  BookingSlot,
  CalendarSlot,
  GenericResponse
} from "../../types/apiResponses";
import useApiUrl from "../../hooks/useApiUrl";
import BusyBoy from "../../components/BusyBoy/BusyBoy";
import Card from "../../style-guide/Card/Card";
import { Table, Th, Td, Tr } from "../../style-guide/Table/Table";
import { Modal, ModalActions, ModalBody } from "../../style-guide/Modal/Modal";
import { ButtonList, Button } from "../../style-guide/Button/Button";
import getPopulatedValue from "../../util/getPopulatedValue";
import { BookingServiceType } from "../../constants/api";
import useForm from "../../hooks/useForm";
import capitalizeFirstLetter from "../../util/capitalizeFirstLetter";
import api from "../../util/api";
import useNotifications from "../../hooks/useNotifications";
import { AiOutlineHourglass } from "react-icons/ai";
import Scheduler from "../../components/Scheduler/Scheduler";
import Form from "../../style-guide/Inputs/Form";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import { numberFormat } from "../../util/intl";

const localizer = momentLocalizer(moment);

interface Props extends RouteComponentProps {}

const Calendar: React.FC<Props> = () => {
  const [start, setStart] = useState<Date | null>(null);
  const [end, setEnd] = useState<Date | null>(null);
  const [view, setView] = useState<View>("month");
  const url = useApiUrl(["booking", "calendar"]);
  const { spawnModal } = useModal();

  const requestOpts = { endpoint: url };
  const calendarSearch = useSearch<CalendarSlot>(requestOpts);

  const slots = useMemo<Event[]>(() => {
    return calendarSearch.results.map((s) => {
      return {
        title: `${s.title}${s.name ? ` (${s.name})` : ""}`,
        start: new Date(s.start),
        end: new Date(s.end),
        resource: s
      };
    });
  }, [calendarSearch.results]);

  const rangeChange = useCallback(
    (range: Date[] | { start: stringOrDate; end: stringOrDate }) => {
      let start: Date;
      let end: Date;

      if (Array.isArray(range)) {
        start = moment.min(range.map((r) => moment(r))).toDate();
        end = moment.max(range.map((r) => moment(r))).toDate();
      } else {
        start = moment(range.start).toDate();
        end = moment(range.end).toDate();
      }

      calendarSearch.search({ ...requestOpts, queryParams: { start, end } });
      setStart(start);
      setEnd(end);
    },
    []
  );

  const refreshView = useCallback(() => {
    calendarSearch.search({
      ...requestOpts,
      queryParams: { start, end }
    });
  }, [requestOpts, start, end]);

  const openSlot = useCallback(
    (slot: CalendarSlot) => {
      spawnModal(
        slot.type === BookingServiceType.Appointment ? (
          <BookingModal slot={slot} onUpdate={refreshView} />
        ) : (
          <EventModal slot={slot} />
        )
      );
    },
    [refreshView]
  );

  useEffect(() => {
    const today = moment();

    rangeChange({
      start: today.startOf("month").toDate(),
      end: today.endOf("month").toDate()
    });
  }, []);

  return (
    <Fader>
      <Block hugTop>
        <Text element="h1" variant="display3">
          <Crumb url="/booking">Booking</Crumb>
          Kalender
        </Text>
        <Section>
          <BusyBoy busy={calendarSearch.loading}>
            <div style={{ height: "700px" }}>
              <BigCalendar
                localizer={localizer}
                events={slots}
                view={view}
                views={{
                  month: true,
                  week: true,
                  day: true
                }}
                onRangeChange={rangeChange}
                onView={setView}
                onSelectEvent={(event) => {
                  if (event.resource) {
                    openSlot(event.resource);
                  }
                }}
                messages={{
                  today: "I dag",
                  yesterday: "I går",
                  tomorrow: "I morgen",
                  previous: "Forrige",
                  next: "Neste",
                  month: "Måned",
                  week: "Uke",
                  day: "Dag",
                  showMore: (num) => `+${num} fler`
                }}
              />
            </div>
          </BusyBoy>
        </Section>
        <Section>
          <BusyBoy busy={calendarSearch.loading}>
            <Card>
              <Table>
                <thead>
                  <Tr>
                    <Th>Booking</Th>
                    <Th>Ressurser</Th>
                    <Th>Tidspunkt</Th>
                  </Tr>
                </thead>
                <tbody>
                  {calendarSearch.hasSearched &&
                  calendarSearch.results.length === 0 ? (
                    <Tr>
                      <Td colSpan={3}>
                        <Text variant="body3">Ingen bookinger funnet</Text>
                      </Td>
                    </Tr>
                  ) : (
                    calendarSearch.results.map((s) => (
                      <Tr key={s._id}>
                        <Td>
                          <Text variant="body2">
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                openSlot(s);
                              }}
                            >
                              {s.title}
                            </a>
                          </Text>
                          <Text variant="body3">
                            {s.name || s.email
                              ? `Kunde: ${s.name || s.email}`
                              : typeof s.slots === "number" &&
                                typeof s.slotsBooked === "number"
                              ? `Kapasitet: ${s.slotsBooked}/${s.slots}`
                              : "Mangler informasjon"}
                          </Text>
                        </Td>
                        <Td>{s.resources.map((s) => s.name).join(", ")}</Td>
                        <Td>
                          <Text>
                            {capitalizeFirstLetter(
                              moment(s.start).format("dddd D[.] MMMM Y")
                            )}
                          </Text>
                          <Text>
                            {moment(s.start).format("HH:mm")}
                            {" - "}
                            {moment(s.end).format("HH:mm")}
                          </Text>
                        </Td>
                      </Tr>
                    ))
                  )}
                </tbody>
              </Table>
            </Card>
          </BusyBoy>
        </Section>
      </Block>
    </Fader>
  );
};

interface BookingModalProps {
  slot: CalendarSlot | Booking;
  onUpdate: (booking?: Booking) => any;
}

export const BookingModal: React.FC<BookingModalProps> = ({
  slot,
  onUpdate
}) => {
  const { spawnModal, despawnModal } = useModal();
  const notifications = useNotifications();
  const bookingUrl = useApiUrl(["booking", "bookings", slot._id]);
  const form = useForm<Booking>(
    {
      resources: []
    },
    {
      endpoint: bookingUrl,
      method: "PATCH",
      prefillEndpoint: bookingUrl,
      cleanField: (key, value) => {
        if (key === "resources" && Array.isArray(value)) {
          return value.map((v: string | BookingResource) =>
            typeof v === "string" ? v : v._id
          );
        }

        return value;
      }
    }
  );

  const canEdit = useMemo<boolean>(() => {
    return moment(form.data.start).isAfter(moment());
  }, [form.data.start]);

  const cancel = useCallback(async () => {
    if (
      canEdit &&
      window.confirm("Er du sikker på at du vil kansellere bookingen?")
    ) {
      notifications.spawn({
        title: "Kansellerer booking…",
        icon: <AiOutlineHourglass />
      });

      await api<GenericResponse>({
        endpoint: bookingUrl,
        method: "DELETE"
      });

      notifications.spawn({
        title: "Booking kansellert"
      });

      onUpdate();
      despawnModal();
    }
  }, [bookingUrl, notifications, onUpdate, canEdit]);

  const name =
    form.data.name || getPopulatedValue(form.data.customer, "name", "");
  const email =
    form.data.email || getPopulatedValue(form.data.customer, "email", "");
  const phone =
    form.data.phone || getPopulatedValue(form.data.customer, "phone", "");

  return (
    <Modal>
      <ModalBody>
        <BusyBoy busy={form.submitting || form.loadingPrefill}>
          <Block>
            <Text variant="title">
              {getPopulatedValue(form.data.service, "name", "Slettet")}
            </Text>
            <Text variant="body2" gutterTop>
              Hvem
            </Text>
            {name && <Text>{name}</Text>}
            {email && <Text>{email}</Text>}
            {phone && <Text>{phone}</Text>}
            <Text variant="body2" gutterTop>
              Når
            </Text>
            <Text>
              {capitalizeFirstLetter(
                moment(form.data.start).format("dddd D[.] MMMM Y")
              )}
            </Text>
            <Text>
              {moment(form.data.start).format("HH:mm")}
              {" - "}
              {moment(form.data.end).format("HH:mm")}
            </Text>
            {form.data.resources.length > 0 && (
              <>
                <Text variant="body2" gutterTop>
                  Ressurser
                </Text>
                <Text>
                  {form.data.resources
                    .map((r) => getPopulatedValue<string>(r, "name", "Slettet"))
                    .join(", ")}
                </Text>
              </>
            )}
          </Block>
        </BusyBoy>
      </ModalBody>
      <ModalActions>
        <ButtonList align="right">
          {canEdit && (
            <>
              <Button
                type="button"
                outlined
                onClick={() =>
                  spawnModal(
                    <RescheduleModal
                      booking={form.data}
                      onUpdate={(booking) => {
                        form.setData({ data: booking });
                        onUpdate();
                      }}
                    />
                  )
                }
              >
                Endre
              </Button>
              <Button type="button" outlined onClick={cancel}>
                Kanseller
              </Button>
            </>
          )}
          <Button type="button" onClick={despawnModal}>
            Lukk
          </Button>
        </ButtonList>
      </ModalActions>
    </Modal>
  );
};

interface RescheduleModalProps {
  booking: Booking;
  onUpdate: (booking: Booking) => any;
}

const RescheduleModal: React.FC<RescheduleModalProps> = ({
  booking,
  onUpdate
}) => {
  const [newSlot, setNewSlot] = useState<BookingSlot | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { despawnModal } = useModal();
  const notifications = useNotifications();
  const bookingUrl = useApiUrl(["booking", "bookings", booking._id]);

  const reschedule = useCallback(async () => {
    if (
      newSlot &&
      window.confirm("Er du sikker på at du vil endre bookingen?")
    ) {
      setLoading(true);

      notifications.spawn({
        title: "Endrer booking…",
        icon: <AiOutlineHourglass />
      });

      const booking = await api<Booking>({
        endpoint: bookingUrl,
        method: "PATCH",
        body: {
          start: newSlot.start,
          end: newSlot.end
        }
      });

      notifications.spawn({
        title: "Booking endret"
      });

      onUpdate(booking as Booking);
      despawnModal();
      setLoading(false);
    }
  }, [bookingUrl, notifications, onUpdate]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        reschedule();
      }}
    >
      <Modal>
        <ModalBody>
          <BusyBoy busy={loading}>
            <Block>
              <Text variant="title" gutterBottom>
                Endre booking
              </Text>
              <Scheduler
                onSlotSelect={setNewSlot}
                bookingServiceId={
                  typeof booking.service === "string"
                    ? booking.service
                    : booking.service._id
                }
              />
            </Block>
          </BusyBoy>
        </ModalBody>
        <ModalActions>
          <ButtonList align="right">
            <Button type="button" outlined onClick={despawnModal}>
              Avbryt
            </Button>
            <Button type="submit" disabled={!newSlot}>
              Lagre
            </Button>
          </ButtonList>
        </ModalActions>
      </Modal>
    </Form>
  );
};

interface EventModalProps {
  slot: CalendarSlot;
}

const EventModal: React.FC<EventModalProps> = ({ slot }) => {
  const { spawnModal, despawnModal } = useModal();
  const scrollParentRef = useRef<HTMLElement | null>(null);

  const endpoint = useApiUrl(["booking", "bookings"]);

  const queryOpts = {
    endpoint,
    queryParams: {
      service: slot.service,
      event: slot._id
    }
  };

  const bookingsSearch = useSearch<Booking>(queryOpts);

  return (
    <Modal fullscreen>
      <ModalBody ref={(ref) => (scrollParentRef.current = ref)}>
        <Block>
          <Section hugTop>
            <Text variant="display3">{slot.title}</Text>
          </Section>
          <Section>
            <Flex align="flex-start">
              <FlexKid flex={1}>
                <Text variant="title" gutterBottom>
                  Informasjon
                </Text>
                {slot.description && (
                  <Text gutterBottom>{slot.description}</Text>
                )}
                <Text variant="body2">Når</Text>
                <Text>
                  {capitalizeFirstLetter(
                    moment(slot.start).format("dddd D[.] MMMM Y")
                  )}
                </Text>
                <Text>
                  {moment(slot.start).format("HH:mm")}
                  {" - "}
                  {moment(slot.end).format("HH:mm")}
                </Text>
                <Text variant="body2" gutterTop>
                  Kapasitet
                </Text>
                <Text>
                  {numberFormat.format(slot.slotsBooked || 0)} /{" "}
                  {numberFormat.format(slot.slots || 0)}
                </Text>
                {slot.resources.length > 0 && (
                  <>
                    <Text variant="body2" gutterTop>
                      Ressurser
                    </Text>
                    <Text>
                      {slot.resources
                        .map((r) =>
                          getPopulatedValue<string>(r, "name", "Slettet")
                        )
                        .join(", ")}
                    </Text>
                  </>
                )}
              </FlexKid>
              <FlexKid flex={3}>
                <Text variant="title">Bookinger</Text>
                <Section>
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={() => {
                      bookingsSearch.search({
                        ...queryOpts,
                        paginate: true
                      });
                    }}
                    hasMore={bookingsSearch.hasMore}
                    useWindow={false}
                    getScrollParent={() => scrollParentRef.current}
                  >
                    <BusyBoy busy={bookingsSearch.loading}>
                      <Card outlined>
                        <Table>
                          <thead>
                            <Tr>
                              <Th>Deltaker</Th>
                              <Th align="right">Handlinger</Th>
                            </Tr>
                          </thead>
                          <tbody>
                            {bookingsSearch.hasSearched &&
                            bookingsSearch.results.length === 0 ? (
                              <Tr>
                                <Td colSpan={2}>
                                  <Text variant="body3">
                                    Ingen bookinger funnet
                                  </Text>
                                </Td>
                              </Tr>
                            ) : (
                              bookingsSearch.results.map((b) => (
                                <Tr key={b._id}>
                                  <Td>
                                    <Text>{b.name || "Mangler navn"}</Text>
                                    <Text variant="body3">
                                      {b.email || "Mangler e-post"}
                                    </Text>
                                  </Td>
                                  <Td verticalAlign="middle" align="right">
                                    <ButtonList align="right">
                                      <Button
                                        outlined
                                        smaller
                                        aria-label="Endre eller kanseller booking"
                                        onClick={() => {
                                          spawnModal(
                                            <BookingModal
                                              slot={b}
                                              onUpdate={() => {
                                                bookingsSearch.search(
                                                  queryOpts
                                                );
                                              }}
                                            />
                                          );
                                        }}
                                      >
                                        Endre / kanseller
                                      </Button>
                                    </ButtonList>
                                  </Td>
                                </Tr>
                              ))
                            )}
                          </tbody>
                        </Table>
                      </Card>
                    </BusyBoy>
                  </InfiniteScroll>
                </Section>
              </FlexKid>
            </Flex>
          </Section>
        </Block>
      </ModalBody>
      <ModalActions>
        <ButtonList align="right">
          <Button type="button" onClick={despawnModal}>
            Lukk
          </Button>
        </ButtonList>
      </ModalActions>
    </Modal>
  );
};

export default Calendar;
