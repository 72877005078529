import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  SyntheticEvent,
  MouseEvent
} from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import { useDebouncedCallback } from "use-debounce/lib";
import {
  AiOutlineSearch,
  AiOutlineDelete,
  AiOutlineFileImage,
  AiOutlineEdit
} from "react-icons/ai";
import { RiFocus3Line } from "react-icons/ri";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { RubicsImage, STOREFRONT_ENDPOINT } from "@ludens-reklame/rubics-sdk";
import useApiUrl from "../../hooks/useApiUrl";
import { SearchOpts, useSearch } from "../../hooks/useApi";
import { Media as IMedia, DependencyMap } from "../../types/apiResponses";
import Fader from "../../style-guide/Fader/Fader";
import Block from "../../style-guide/Block/Block";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import Text from "../../style-guide/Text/Text";
import Crumb from "../../components/Crumb/Crumb";
import Doorman from "../../components/Doorman/Doorman";
import { Button, ButtonList } from "../../style-guide/Button/Button";
import BusyBoy from "../../components/BusyBoy/BusyBoy";
import Card from "../../style-guide/Card/Card";
import TextInput from "../../style-guide/Inputs/TextInput";
import EmptyState from "../../style-guide/EmptyState/EmptyState";
import MediaPicker, {
  Medias,
  MediaWrapper,
  Image,
  File,
  FileName,
  MediaActions,
  MediaContent,
  FileAlt
} from "../../components/MediaPicker/MediaPicker";
import transformImage from "../../util/transformImage";
import StickyBar from "../../style-guide/StickyBar/StickyBar";
import api from "../../util/api";
import DependentsModal from "../../components/DependentsModal/DependentsModal";
import Loading from "../../style-guide/Loading/Loading";
import { Modal, ModalBody, ModalActions } from "../../style-guide/Modal/Modal";
import useNotifications from "../../hooks/useNotifications";
import { Radio } from "../../style-guide/Inputs/Radio";
import createInputField from "../../util/createInputField";
import useForm from "../../hooks/useForm";
import Form from "../../style-guide/Inputs/Form";
import Field from "../../style-guide/Inputs/Field";
import Label from "../../style-guide/Inputs/Label";
import Tagger from "../../components/Tagger/Tagger";
import getPercentageFromTwoNumbers from "../../util/getPercentageFromTwoNumbers";
import useCanonicalUrl from "../../hooks/useCanonicalUrl";
import Upload from "./components/Upload";

interface Props {}

const Media: React.FC<Props> = () => {
  const [_loading, setLoading] = useState<boolean>(false);
  const [usedQuery, setUsedQuery] = useState<string>("");
  const [query, setQuery] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);
  const [deletedMedias, setDeletedMedias] = useState<string[]>([]);
  const [selectedMedias, setSelectedMedias] = useState<IMedia[]>([]);
  const history = useHistory();
  const { spawnModal } = useModal();
  const notifications = useNotifications();

  const url = useApiUrl(["media"]);
  const checkDependenciesEndpoint = useApiUrl(["resource-dependencies"]);

  const queryOpts: SearchOpts<IMedia> = {
    endpoint: `${url}/search`,
    queryParams: {
      text: query,
      tags: tags.join(","),
      private: false
    }
  };

  const { search, results, hasMore, loading, hasSearched, setResults } =
    useSearch<IMedia>({
      ...queryOpts,
      onSuccess: () => setUsedQuery(query)
    });

  const handleSearch = useCallback(() => {
    search(queryOpts);
  }, [search, queryOpts]);

  const [debouncedSearch] = useDebouncedCallback(handleSearch, 500);

  useEffect(() => {
    if (hasSearched) {
      debouncedSearch();
    }
  }, [query]);

  const handleSpawnUploadModal = useCallback(() => {
    spawnModal(
      <Upload
        onUploaded={() => {
          search(queryOpts);
          notifications.spawn({ title: "Filer lastet opp" });
        }}
      />
    );
  }, [spawnModal]);

  const handleDelete = useCallback(async () => {
    if (
      selectedMedias.length > 0 &&
      window.confirm("Er du sikker på at du vil slette disse filene?")
    ) {
      setLoading(true);

      const dependents = await api<DependencyMap[]>({
        endpoint: checkDependenciesEndpoint,
        queryParams: {
          resources: selectedMedias.map((m) => m._id).join(",")
        }
      });

      setLoading(false);

      const dependentMedias = Array.isArray(dependents)
        ? selectedMedias.filter((m) =>
            dependents.some((d) => d.hasDependents && d._id === m._id)
          )
        : [];

      const deletableMedias = selectedMedias.filter(
        (m) => !dependentMedias.some((d) => d._id === m._id)
      );

      if (deletableMedias.length > 0) {
        setLoading(true);

        await api({
          endpoint: `${url}/bulk`,
          method: "DELETE",
          queryParams: {
            id: deletableMedias.map((m) => m._id).join(",")
          }
        });

        notifications.spawn({
          title: `${deletableMedias.length} fil(er) slettet`
        });

        setSelectedMedias(
          selectedMedias.filter(
            (m) => !deletableMedias.some((d) => d._id === m._id)
          )
        );

        setDeletedMedias([
          ...deletedMedias,
          ...deletableMedias.map((m) => m._id)
        ]);

        setLoading(false);
      }

      if (Array.isArray(dependents) && dependentMedias.length > 0) {
        spawnModal(
          <DependentsModal
            dependents={dependents}
            dependentLabels={selectedMedias.map((m) => ({
              _id: m._id,
              label: m.original_filename
            }))}
            onNav={history.push}
          />
        );
      }
    }
  }, [selectedMedias, deletedMedias]);

  const filteredResults = useMemo<IMedia[]>(() => {
    return results.filter((r) => !deletedMedias.includes(r._id));
  }, [results, deletedMedias]);

  return (
    <>
      <Fader>
        <Block hugTop>
          <Text element="h1" variant="display3">
            <Crumb url="/innhold">Innhold</Crumb>
            Filer
          </Text>
        </Block>
        {hasSearched &&
          filteredResults.length === 0 &&
          !usedQuery &&
          tags.length === 0 && (
            <EmptyState
              title="Her var det tomt, gitt"
              text="Her kan du laste opp bilder, videoer og andre filer til bruk på nettsiden din."
              icon={<AiOutlineFileImage />}
              action={
                <Doorman type="contributor">
                  <Button onClick={handleSpawnUploadModal}>
                    Last opp media
                  </Button>
                </Doorman>
              }
            />
          )}
        <BusyBoy busy={loading}>
          <InfiniteScroll
            loadMore={() => {
              search({
                ...queryOpts,
                paginate: true
              });
            }}
            hasMore={hasMore}
          >
            {(filteredResults.length > 0 || usedQuery || tags.length > 0) && (
              <Card>
                <Block>
                  <Flex>
                    <FlexKid flex={2}>
                      <TextInput
                        icon={<AiOutlineSearch />}
                        placeholder="Søk etter filer"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                      />
                    </FlexKid>
                    <FlexKid flex={1} spaceLeft>
                      <Tagger
                        placeholder="Emneknagger"
                        disableTagCreation
                        inline
                        onChange={(tags) => {
                          setTags(tags);
                          search({
                            ...queryOpts,
                            queryParams: {
                              ...queryOpts.queryParams,
                              tags: tags.join(",")
                            }
                          });
                        }}
                      />
                    </FlexKid>
                    <Doorman type="contributor">
                      <FlexKid spaceLeft>
                        <Button onClick={handleSpawnUploadModal}>
                          Last opp filer
                        </Button>
                      </FlexKid>
                    </Doorman>
                  </Flex>
                </Block>
                {filteredResults.length > 0 && (
                  <Block hugTop>
                    <Medias>
                      {filteredResults.map((r) => {
                        const selected = selectedMedias.some(
                          (m) => m._id === r._id
                        );

                        return (
                          <MediaWrapper key={r._id}>
                            <MediaContent>
                              {r.fileType === "image" ? (
                                <Image
                                  src={transformImage(r.secure_url, "w_400")}
                                  alt={r.original_filename}
                                />
                              ) : (
                                <File type={r.type} />
                              )}
                              <FileName>{r.original_filename}</FileName>
                              {r.altText && <FileAlt>{r.altText}</FileAlt>}
                              {(r.tags || []).length > 0 && (
                                <FileAlt>{r.tags.join(", ")}</FileAlt>
                              )}
                            </MediaContent>
                            <MediaActions>
                              <Radio
                                label="Velg"
                                standalone
                                checkbox
                                checked={selected}
                                onChange={() => {
                                  if (selected) {
                                    setSelectedMedias(
                                      selectedMedias.filter(
                                        (m) => m._id !== r._id
                                      )
                                    );
                                  } else {
                                    setSelectedMedias([...selectedMedias, r]);
                                  }
                                }}
                              />
                              <Button
                                smaller
                                outlined
                                circular
                                aria-label="Rediger filinformasjon"
                                onClick={() =>
                                  spawnModal(
                                    <MediaEditModal
                                      file={r}
                                      onSave={(file) => {
                                        setResults(
                                          results.map((r) => {
                                            if (r._id === file._id) {
                                              return file;
                                            }

                                            return r;
                                          })
                                        );
                                      }}
                                    />
                                  )
                                }
                              >
                                <AiOutlineEdit />
                              </Button>
                            </MediaActions>
                          </MediaWrapper>
                        );
                      })}
                    </Medias>
                  </Block>
                )}
                {filteredResults.length < 1 && (
                  <EmptyState
                    title={
                      <>
                        Ingen treff på "
                        <em>
                          {usedQuery ? `${usedQuery} ` : ""}
                          {tags.join(", ")}
                        </em>
                        "
                      </>
                    }
                  />
                )}
              </Card>
            )}
          </InfiniteScroll>
        </BusyBoy>
      </Fader>
      {selectedMedias.length > 0 && (
        <Doorman type="admin">
          <StickyBar>
            <Loading loading={_loading} />
            <Text variant="body3">Med valgte:</Text>
            <Button alternate onClick={handleDelete} disabled={_loading}>
              <AiOutlineDelete /> Slett
            </Button>
          </StickyBar>
        </Doorman>
      )}
    </>
  );
};

interface MediaPickerModalProps {
  isPrivate?: boolean;
  onUploaded: (media: RubicsImage | null) => any;
}

export const MediaPickerModal: React.FC<MediaPickerModalProps> = ({
  isPrivate,
  onUploaded
}) => {
  const { despawnModal } = useModal();

  return (
    <Modal>
      <ModalBody>
        <Block>
          <MediaPicker
            hideLibrary
            isPrivate={isPrivate}
            onChange={(image) => {
              despawnModal();
              onUploaded(image);
            }}
          />
        </Block>
      </ModalBody>
      <ModalActions>
        <Button type="button" outlined onClick={despawnModal}>
          Avbryt
        </Button>
      </ModalActions>
    </Modal>
  );
};

type Coordinates = [number, number];

interface MediaEditModalProps {
  file: IMedia;
  onSave: (file: IMedia) => any;
}

const MediaEditModal: React.FC<MediaEditModalProps> = ({ file, onSave }) => {
  const [percentagePos, setPercentagePos] = useState<Coordinates>([50, 50]);

  const canonical = useCanonicalUrl();
  const { despawnModal } = useModal();
  const notifications = useNotifications();

  const url = useApiUrl(["media", file._id]);

  const form = useForm<IMedia>(file, {
    endpoint: url,
    method: "PATCH",
    cleanField: (key, value) => {
      if (key === "previewImage" && value && (value.ref || value._id)) {
        return value.ref || value._id;
      }

      return value;
    },
    onSuccess: (file) => {
      notifications.spawn({ title: "Endringer lagret" });
      onSave(file);
      despawnModal();
    }
  });

  const getAbsolutePosition = useCallback(
    (percentageCoordinates: Coordinates, image: HTMLImageElement) => {
      const x = Math.floor(
        image.naturalWidth * (percentageCoordinates[0] / 100)
      );

      const y = Math.floor(
        image.naturalHeight * (percentageCoordinates[1] / 100)
      );

      form.setField({
        path: "focusCoordinates",
        value: { x, y }
      });
    },
    [form]
  );

  const onImageClick = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      const percentageX = getPercentageFromTwoNumbers(rect.width, x);
      const percentageY = getPercentageFromTwoNumbers(rect.height, y);

      setPercentagePos([percentageX, percentageY]);
      getAbsolutePosition([percentageX, percentageY], e.currentTarget);
    },
    [getAbsolutePosition]
  );

  const onImageLoad = useCallback(
    (e: SyntheticEvent<HTMLImageElement>) => {
      if (
        file.fileType === "image" &&
        typeof file.focusCoordinates?.x === "number" &&
        typeof file.focusCoordinates?.y === "number"
      ) {
        const image = e.currentTarget;
        const absoluteX = file.focusCoordinates.x;
        const absoluteY = file.focusCoordinates.y;
        const x = getPercentageFromTwoNumbers(image.naturalWidth, absoluteX);
        const y = getPercentageFromTwoNumbers(image.naturalHeight, absoluteY);

        setPercentagePos([x, y]);
      }
    },
    [file]
  );

  return (
    <Form onSubmit={form.submit}>
      <BusyBoy busy={form.submitting}>
        <Modal fullscreen>
          <ModalBody>
            <Block>
              <Text variant="title" gutterBottom>
                Rediger fil
              </Text>
              <MediaEditGrid>
                <FileContainer>
                  {file.fileType === "image" ? (
                    <Coordinator editing>
                      <img
                        src={file.secure_url}
                        alt={file.altText}
                        onLoad={onImageLoad}
                        onClick={onImageClick}
                      />
                      <Dot
                        style={{
                          left: `${percentagePos[0]}%`,
                          top: `${percentagePos[1]}%`
                        }}
                      >
                        <RiFocus3Line />
                      </Dot>
                    </Coordinator>
                  ) : (
                    <File type={file.type} label={file.original_filename} />
                  )}
                </FileContainer>
                <InputContainer>
                  {createInputField({
                    key: "_url",
                    type: "text",
                    label: "URL",
                    value:
                      form.data.fileType === "raw"
                        ? `${canonical}/${STOREFRONT_ENDPOINT}/media/${form.data._id}/file`
                        : form.data.secure_url,
                    readOnly: true,
                    onChange: () => {}
                  })}
                  {createInputField({
                    key: "original_filename",
                    type: "text",
                    label: "Filnavn",
                    value: form.data.original_filename,
                    onChange: (value) =>
                      form.setField({
                        path: "original_filename",
                        value
                      })
                  })}
                  {createInputField({
                    key: "altText",
                    type: "textarea",
                    label: "Alt-tekst",
                    value: form.data.altText,
                    onChange: (value) =>
                      form.setField({
                        path: "altText",
                        value
                      })
                  })}
                  <Field hugBottom>
                    <Label htmlFor="tags">Emneknagger</Label>
                    <Tagger
                      tags={form.data.tags || []}
                      inline={false}
                      placeholder="Legg til emneknagg"
                      zIndex={999}
                      onChange={(tags) =>
                        form.setField({
                          path: "tags",
                          value: tags
                        })
                      }
                    />
                  </Field>
                  {form.data.fileType !== "image" &&
                    createInputField({
                      key: "previewImage",
                      type: "media",
                      label: "Forhåndsvisningsbilde",
                      value: form.data.previewImage
                        ? typeof form.data.previewImage === "string"
                          ? form.data.previewImage
                          : {
                              ...form.data.previewImage,
                              ref: form.data.previewImage._id
                            }
                        : undefined,
                      onChange: (value) => {
                        if (!value || value.ref === "") {
                          form.setField({
                            path: "previewImage",
                            value: null
                          });
                        } else {
                          form.setField({
                            path: "previewImage",
                            value: { ...value, _id: value.ref }
                          });
                        }
                      }
                    })}
                </InputContainer>
              </MediaEditGrid>
            </Block>
          </ModalBody>
          <ModalActions>
            <ButtonList>
              <Button type="button" outlined onClick={despawnModal}>
                Avbryt
              </Button>
              <Button type="submit" disabled={!form.hasMadeChanges}>
                {form.submitting ? "Lagrer…" : "Lagre"}
              </Button>
            </ButtonList>
          </ModalActions>
        </Modal>
      </BusyBoy>
    </Form>
  );
};

const MediaEditGrid = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 500px;
  grid-gap: ${(props) => props.theme.spacing.medium};
`;

const FileContainer = styled.div`
  border: 1px solid ${(props) => props.theme.colors.borderDim};
  border-radius: 8px;
  padding: ${(props) => props.theme.spacing.small};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
`;

const InputContainer = styled.div``;

const Coordinator = styled.div<{ editing: boolean }>`
  position: relative;
  cursor: ${(props) => (props.editing ? "crosshair" : "initial")};
  width: 100%;
  height: 100%;
`;

const Dot = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  margin-top: -12px;
  margin-left: -12px;
  z-index: 9999;

  svg {
    width: 24px;
    height: 24px;
    mix-blend-mode: difference;
  }
`;

export default Media;
