import { isPlainObject } from "lodash";
import { FieldCleaner } from "../hooks/useForm";

function cleanPopulatedIds(keys: string[]): FieldCleaner {
  return function (key, value) {
    const refKey = keys.find((k) => k === key);

    if (refKey) {
      if (value) {
        if (isPlainObject(value) && "_id" in value) {
          return value._id;
        } else {
          return value;
        }
      }

      return null;
    }

    return value;
  };
}

export default cleanPopulatedIds;
