import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { SketchPicker, RGBColor } from "react-color";
import TextInput from "../../style-guide/Inputs/TextInput";
import { createPortal } from "react-dom";
import usePositioning from "../../hooks/usePositioning";

const Wrapper = styled.div`
  position: relative;
`;

interface PickerProps {
  top?: number;
  left?: number;
}

const Picker = styled.div<PickerProps>`
  position: fixed;
  top: ${(props) => props.top || 0}px;
  left: ${(props) => props.left || 0}px;
  z-index: 12;
`;

const Underlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
`;

const Layer = styled.div`
  position: fixed;
  z-index: 10;
`;

interface ColorPreviewProps {
  color: string;
  absolute?: boolean;
}

export const ColorPreview = styled.div<ColorPreviewProps>`
  background-color: ${(props) => props.color};
  height: 30px;
  width: 30px;
  border-radius: 50%;
  pointer-events: none;
  z-index: 1;

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: 7px;
      right: 6px;
    `};
`;

interface ColorInputProps {
  id?: string;
  value?: string;
  readOnly?: boolean;
  onChange?: (color: string) => any;
  onDone?: (color: string) => any;
}

const ColorPicker: React.FC<ColorInputProps> = ({
  id,
  value,
  readOnly,
  onChange,
  onDone
}) => {
  const [internalValue, setInternalValue] = useState(value || undefined);
  const [editing, setEditing] = useState(false);
  const wrapperPos = usePositioning({});

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <Wrapper ref={wrapperPos.setTargetRef}>
      {internalValue && <ColorPreview color={internalValue} absolute />}
      <TextInput
        id={id}
        type="text"
        value={value}
        onFocus={() => {
          if (!readOnly) {
            setEditing(true);
          }
        }}
        readOnly
      />
      {editing &&
        createPortal(
          <Layer>
            <Picker
              top={wrapperPos.top}
              left={wrapperPos.left}
              ref={(ref) => wrapperPos.setSelfRef(ref)}
            >
              <SketchPicker
                color={internalValue}
                onChange={(color) => {
                  const cssColor = colorToCss(color.rgb);

                  setInternalValue(cssColor);

                  if (typeof onChange === "function") {
                    onChange(cssColor);
                  }
                }}
                onChangeComplete={(color) => {
                  if (typeof onDone === "function") {
                    onDone(colorToCss(color.rgb));
                  }
                }}
              />
            </Picker>
            <Underlay onClick={() => setEditing(false)} />
          </Layer>,
          document.body
        )}
    </Wrapper>
  );
};

function colorToCss(color: RGBColor): string {
  return `rgba(${color.r},${color.g},${color.b},${color.a})`;
}

export default ColorPicker;
