import React from "react";
import { Switch, Route } from "react-router";
import Home from "./Home";

const HomeRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
    </Switch>
  );
};

export default HomeRoutes;
