import React, { useCallback, useState, useEffect } from "react";
import objectPath from "object-path";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { AiOutlineCopy } from "react-icons/ai";
import { Modal, ModalBody, ModalActions } from "../style-guide/Modal/Modal";
import { ButtonList, Button } from "../style-guide/Button/Button";
import Form from "../style-guide/Inputs/Form";
import Text from "../style-guide/Text/Text";
import Block from "../style-guide/Block/Block";
import { Elements, Element } from "../style-guide/Elements/Elements";
import useNotifications from "./useNotifications";
import stripObjectProps from "../util/stripObjectProps";

const notificationCutLimit = 2;

export interface CopyPasteData {
  [key: string]: any;
}

export interface CopyPasteObject {
  data: CopyPasteData;
  previewData?: CopyPasteData;
  pathsCopied: CopyPastePath[];
}

export interface CopyPastePath {
  path: string;
  label?: string;
  background?: boolean;
}

export interface UseCopyPasteInterface {
  copy: (data: CopyPasteData, previewData?: CopyPasteData) => Promise<void>;
  paste: () => Promise<void>;
}

export interface UseCopyPasteOpts {
  pathsToCopy: CopyPastePath[];
  onPaste: (data: CopyPasteData, previewData?: CopyPasteData) => any;
  ignoreKeys?: string[];
  bypassIgnoreKeys?: string[];
}

function useCopyPaste(opts: UseCopyPasteOpts): UseCopyPasteInterface {
  const modal = useModal();
  const notifications = useNotifications();

  const onCopy = useCallback(
    async (
      data: CopyPasteData,
      pathsCopied: CopyPastePath[],
      previewData?: CopyPasteData
    ) => {
      const obj: CopyPasteObject = {
        data,
        previewData,
        pathsCopied
      };

      await navigator.clipboard.writeText(JSON.stringify(obj));

      const printedPaths = pathsCopied
        .filter((c) => !c.background)
        .slice(0, notificationCutLimit);

      notifications.spawn({
        title: "Felter kopiert",
        subtitle: `${printedPaths.map((p) => p.label || p.path).join(", ")}${
          pathsCopied.length > notificationCutLimit
            ? ` og ${pathsCopied.length - printedPaths.length} andre`
            : ""
        }`,
        icon: <AiOutlineCopy />
      });
    },
    [notifications]
  );

  const handleCopy = useCallback(
    async (data: CopyPasteData, previewData?: CopyPasteData) => {
      const dataToCopy: CopyPasteData = {};
      const previewDataToCopy: CopyPasteData = {};

      for (let i = 0; i < opts.pathsToCopy.length; i++) {
        const path = opts.pathsToCopy[i];
        const pathData = objectPath.get(data, path.path);
        const previewPathData = previewData
          ? objectPath.get(previewData, path.path)
          : undefined;

        if (pathData) {
          objectPath.set(dataToCopy, path.path, pathData);
        }

        if (previewPathData) {
          objectPath.set(previewDataToCopy, path.path, previewPathData);
        }
      }

      if (opts.ignoreKeys) {
        stripObjectProps(dataToCopy, opts.ignoreKeys, opts.bypassIgnoreKeys);
        stripObjectProps(
          previewDataToCopy,
          opts.ignoreKeys,
          opts.bypassIgnoreKeys
        );
      }

      if (opts.pathsToCopy.filter((c) => !c.background).length > 1) {
        modal.spawnModal(
          <CopyPasteModal
            pathsToCopy={opts.pathsToCopy}
            data={dataToCopy}
            previewData={previewDataToCopy}
            onSubmit={async (data, pathsCopied, previewData) =>
              await onCopy(data, pathsCopied, previewData)
            }
          />
        );
      } else if (opts.pathsToCopy.length > 0) {
        await onCopy(dataToCopy, opts.pathsToCopy, previewDataToCopy);
      }
    },
    [opts.pathsToCopy, opts.ignoreKeys, modal, onCopy]
  );

  const handlePaste = useCallback(async () => {
    try {
      const string = await navigator.clipboard.readText();
      const data = JSON.parse(string) as CopyPasteObject;

      opts.onPaste(data.data, data.previewData);

      const printedPaths = data.pathsCopied.slice(0, notificationCutLimit);

      notifications.spawn({
        title: "Felter limt inn",
        subtitle: `${printedPaths.map((p) => p.label || p.path).join(", ")}${
          data.pathsCopied.length > notificationCutLimit
            ? ` og ${data.pathsCopied.length - printedPaths.length} andre`
            : ""
        }`,
        icon: <AiOutlineCopy />
      });
    } catch (error) {
      console.error(error);
    }
  }, [opts, notifications]);

  return {
    copy: handleCopy,
    paste: handlePaste
  };
}

interface CopyPasteModalProps {
  pathsToCopy: CopyPastePath[];
  data: CopyPasteData;
  previewData?: CopyPasteData;
  onSubmit: (
    data: CopyPasteData,
    keysCopied: CopyPastePath[],
    previewData?: CopyPasteData
  ) => any;
}

const CopyPasteModal: React.FC<CopyPasteModalProps> = ({
  pathsToCopy,
  data,
  previewData,
  onSubmit
}) => {
  const [keysToCopy, setKeysToCopy] = useState([] as string[]);
  const modal = useModal();

  useEffect(() => {
    setKeysToCopy(pathsToCopy.map((p) => p.path));
  }, [pathsToCopy]);

  const handleSubmit = useCallback(() => {
    const dataToPaste: CopyPasteData = {};
    const previewDataToPaste: CopyPasteData = {};

    for (let i = 0; i < keysToCopy.length; i++) {
      const path = keysToCopy[i];
      const pathData = objectPath.get(data, path);
      const previewPathData = previewData
        ? objectPath.get(previewData, path)
        : undefined;

      if (pathData) {
        objectPath.set(dataToPaste, path, pathData);
      }

      if (previewPathData) {
        objectPath.set(previewDataToPaste, path, previewPathData);
      }
    }

    onSubmit(
      dataToPaste,
      pathsToCopy.filter((p) => keysToCopy.includes(p.path)),
      previewDataToPaste
    );
    modal.despawnModal();
  }, [data, previewData, keysToCopy, modal, onSubmit, pathsToCopy]);

  const toggleField = useCallback(
    (path: string) => {
      if (!keysToCopy.includes(path)) {
        setKeysToCopy([...keysToCopy, path]);
      } else {
        setKeysToCopy(keysToCopy.filter((k) => k !== path));
      }
    },
    [keysToCopy]
  );

  const toggleAll = useCallback(
    (empty?: boolean) => {
      setKeysToCopy(empty ? [] : pathsToCopy.map((p) => p.path));
    },
    [pathsToCopy]
  );

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Modal>
        <ModalBody>
          <Block>
            <Text variant="title" gutterBottom>
              Felter å kopiere
            </Text>
            <Elements>
              {pathsToCopy
                .filter((p) => !p.background)
                .map((p) => (
                  <Element
                    key={p.path}
                    onClick={(e) => {
                      toggleField(p.path);
                      e.stopPropagation();
                    }}
                    icon={
                      <input
                        id={p.path}
                        type="checkbox"
                        checked={keysToCopy.includes(p.path)}
                        onChange={(e) => {
                          toggleField(p.path);
                          e.stopPropagation();
                        }}
                      />
                    }
                  >
                    <label
                      htmlFor={p.path}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {p.label || p.path}
                    </label>
                  </Element>
                ))}
            </Elements>
          </Block>
        </ModalBody>
        <ModalActions multiple>
          <ButtonList>
            <Button type="button" outlined onClick={() => toggleAll()}>
              Velg alle
            </Button>
            <Button type="button" outlined onClick={() => toggleAll(true)}>
              Velg ingen
            </Button>
          </ButtonList>
          <ButtonList align="right">
            <Button disabled={keysToCopy.length < 1}>Kopier</Button>
          </ButtonList>
        </ModalActions>
      </Modal>
    </Form>
  );
};

export default useCopyPaste;
