import React, { ReactNode } from "react";
import moment from "moment";
import Field, { Fields } from "../style-guide/Inputs/Field";
import Label, { Description } from "../style-guide/Inputs/Label";
import TextInput from "../style-guide/Inputs/TextInput";
import Drafter from "../components/Drafter/Drafter";
import { ImageRef } from "../types/apiResponses";
import MediaPicker from "../components/MediaPicker/MediaPicker";
import TextArea from "../style-guide/Inputs/TextArea";
import Select from "../style-guide/Inputs/Select";
import { Radios, Radio } from "../style-guide/Inputs/Radio";
import SearchAndSelect, {
  ArrayFilter
} from "../components/SearchAndSelect/SearchAndSelect";
import ColorPicker from "../components/ColorPicker/ColorPicker";
import OfflineLister from "../components/OfflineLister/OfflineLister";

export interface CreateInputFieldOpts {
  type:
    | "text"
    | "password"
    | "number"
    | "email"
    | "url"
    | "file"
    | "boolean"
    | "time"
    | "date"
    | "textarea"
    | "select"
    | "radio"
    | "checkbox"
    | "list"
    | "ref"
    | "media"
    | "draft"
    | "color";
  key: string;
  label?: ReactNode;
  description?: ReactNode;
  helpText?: ReactNode;
  value?: any;
  required?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  hugTop?: boolean;
  options?: {
    label: string;
    value: any;
    description?: ReactNode;
    checked?: boolean;
  }[];
  ref?: {
    url: string[];
    searchKey: string;
    titleKey: string;
    subtitleKey?: string;
    filter?: ArrayFilter;
    query?: {
      [key: string]: any;
    };
  };
  editableAlt?: boolean;
  editableCaption?: boolean;
  editableTransforms?: boolean;
  editableLazyLoad?: boolean;
  minimize?: boolean;
  inlineField?: ReactNode;
  nonNullable?: boolean;
  nullFalsy?: boolean;
  min?: number;
  max?: number;
  accept?: string;
  syncState?: string;
  onChange: (value: any) => any;
  onBlur?: () => any;
}

function createInputField(opts: CreateInputFieldOpts): ReactNode {
  let input: ReactNode = null;
  const descriptionId = opts.description
    ? `description-${opts.key}`
    : undefined;

  switch (opts.type) {
    case "ref":
      input = opts.ref ? (
        <SearchAndSelect
          url={opts.ref.url}
          itemToString={(resource) =>
            resource ? resource[opts.ref!.titleKey] : ""
          }
          itemToSubString={
            opts.ref.subtitleKey
              ? (resource) => (resource ? resource[opts.ref!.subtitleKey!] : "")
              : undefined
          }
          searchKey={opts.ref!.searchKey}
          readOnly={opts.readOnly}
          required={opts.required}
          autoFocus={opts.autoFocus}
          placeholder={opts.placeholder}
          value={opts.value || ""}
          query={opts.ref.query}
          filter={opts.ref.filter}
          onChange={(resource) => {
            if (resource) {
              opts.onChange(resource);
            } else {
              opts.onChange(null);
            }
          }}
        />
      ) : null;

      break;
    case "select":
      input = (
        <Select
          id={opts.key}
          aria-describedby={descriptionId}
          value={opts.value || ""}
          disabled={opts.readOnly}
          required={opts.required}
          autoFocus={opts.autoFocus}
          onChange={(e) => {
            if (opts.nullFalsy && !e.target.value) {
              opts.onChange(null);
            } else {
              opts.onChange(e.target.value);
            }
          }}
        >
          {!opts.nonNullable && <option value="">Ingen</option>}
          {(opts.options || []).map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </Select>
      );

      break;
    case "radio":
    case "checkbox":
      input = (
        <Radios>
          {(opts.options || []).map((o) => (
            <Radio
              key={o.value}
              checkbox={opts.type === "checkbox"}
              label={o.label}
              value={o.value}
              description={o.description}
              readOnly={opts.readOnly}
              checked={o.checked || opts.value === o.value}
              onChange={(e) => opts.onChange(e.target.value)}
              disabled={opts.readOnly}
            />
          ))}
        </Radios>
      );

      break;
    case "list":
      input = (
        <OfflineLister
          values={opts.value || []}
          writeMode
          onChange={opts.onChange}
          selectLabel={opts.placeholder}
        />
      );

      break;
    case "boolean":
      input = (
        <Select
          id={opts.key}
          aria-describedby={descriptionId}
          value={opts.value ? "true" : "false"}
          required={opts.required}
          disabled={opts.readOnly}
          onChange={(e) => opts.onChange(e.target.value === "true")}
        >
          <option value="false">Nei</option>
          <option value="true">Ja</option>
        </Select>
      );

      break;
    case "textarea":
      input = (
        <TextArea
          id={opts.key}
          aria-describedby={descriptionId}
          value={opts.value || ""}
          placeholder={opts.placeholder}
          required={opts.required}
          readOnly={opts.readOnly}
          disabled={opts.readOnly}
          onChange={(e) => opts.onChange(e.target.value)}
          onBlur={opts.onBlur}
        />
      );

      break;
    case "time":
    case "date":
      input = (
        <TextInput
          id={opts.key}
          aria-describedby={descriptionId}
          type={opts.type === "time" ? "datetime-local" : "date"}
          value={
            opts.value
              ? moment(opts.value).format(
                  opts.type === "time"
                    ? moment.HTML5_FMT.DATETIME_LOCAL
                    : moment.HTML5_FMT.DATE
                )
              : ""
          }
          placeholder={opts.placeholder}
          required={opts.required}
          readOnly={opts.readOnly}
          disabled={opts.readOnly}
          onChange={(e) => opts.onChange(e.target.value)}
          onBlur={opts.onBlur}
        />
      );

      break;

    case "media":
      const defaultImage: ImageRef | undefined = opts.value || undefined;

      input = (
        <MediaPicker
          onChange={(image) => {
            if (image) {
              opts.onChange({
                ...image,
                alt: image.alt,
                caption: image.caption,
                ref: image.ref,
                url: image.url
              });
            } else {
              opts.onChange(null);
            }
          }}
          readOnly={opts.readOnly}
          value={
            defaultImage
              ? {
                  ref:
                    !!defaultImage.ref && typeof defaultImage.ref !== "string"
                      ? defaultImage.ref._id
                      : defaultImage.ref || "",
                  alt: defaultImage.alt,
                  caption: defaultImage.caption,
                  url: !!defaultImage.url
                    ? defaultImage.url
                    : !!defaultImage.ref && typeof defaultImage.ref !== "string"
                    ? defaultImage.ref.secure_url
                    : ""
                }
              : undefined
          }
          editableAlt={opts.editableAlt}
          editableCaption={opts.editableCaption}
          editableTransforms={opts.editableTransforms}
          editableLazyLoad={opts.editableLazyLoad}
          minimize={opts.minimize}
        />
      );

      break;
    case "draft":
      input = (
        <Drafter
          key={opts.syncState}
          data={opts.value}
          onChange={(value) => opts.onChange(value)}
          readOnly={opts.readOnly}
        />
      );

      break;
    case "color":
      input = (
        <ColorPicker
          id={opts.key}
          value={opts.value || ""}
          readOnly={opts.readOnly}
          onChange={opts.onChange}
        />
      );

      break;
    case "number":
      input = (
        <TextInput
          id={opts.key}
          aria-describedby={descriptionId}
          type={opts.type}
          value={typeof opts.value === "number" ? opts.value : opts.value || ""}
          placeholder={opts.placeholder}
          required={opts.required}
          readOnly={opts.readOnly}
          disabled={opts.disabled}
          autoFocus={opts.autoFocus}
          min={opts.min}
          max={opts.max}
          onChange={(e) => {
            opts.onChange(e.target.value);
          }}
          onBlur={opts.onBlur}
        />
      );

      break;
    case "file":
      input = (
        <TextInput
          id={opts.key}
          aria-describedby={descriptionId}
          type="file"
          placeholder={opts.placeholder}
          required={opts.required}
          readOnly={opts.readOnly}
          disabled={opts.disabled}
          autoFocus={opts.autoFocus}
          onChange={(e) => {
            if ((e.target.files?.length || 0) > 0) {
              opts.onChange(e.target.files![0]);
            } else {
              opts.onChange(undefined);
            }
          }}
          onBlur={opts.onBlur}
          accept={opts.accept}
        />
      );

      break;
    default:
      input = (
        <TextInput
          id={opts.key}
          aria-describedby={descriptionId}
          type={opts.type}
          value={opts.value || ""}
          placeholder={opts.placeholder}
          required={opts.required}
          readOnly={opts.readOnly}
          disabled={opts.disabled}
          autoFocus={opts.autoFocus}
          onChange={(e) => {
            opts.onChange(e.target.value);
          }}
          onBlur={opts.onBlur}
        />
      );

      break;
  }

  return (
    <Field hugBottom hugTop={opts.hugTop}>
      {opts.label && (
        <Label htmlFor={opts.key} hug={!!opts.description}>
          {opts.label}
          {opts.required ? " *" : ""}
        </Label>
      )}
      {opts.description && (
        <Description id={descriptionId}>{opts.description}</Description>
      )}
      <Fields>
        {opts.inlineField}
        {input}
      </Fields>
      {opts.helpText && <>{opts.helpText}</>}
    </Field>
  );
}

export default createInputField;
