import React from "react";
import { FiEdit } from "react-icons/fi";
import Fader from "../../../style-guide/Fader/Fader";
import Text from "../../../style-guide/Text/Text";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import { CardLink } from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Section from "../../../style-guide/Section/Section";
import Grid from "../../../style-guide/Grid/Grid";
import Icon from "../../../components/Icon/Icon";
import Crumb from "../../../components/Crumb/Crumb";

const EmailsOverview: React.FC = () => {
  return (
    <Fader>
      <Block hugTop>
        <Text element="h1" variant="display3">
          <Crumb url="/Innstillinger">Innstillinger</Crumb>
          Tekster til utsendelser
        </Text>
      </Block>
      <Section hugTop>
        <Grid gap="medium" columns={3}>
          <CardLink to="/innstillinger/epost-tekster/ordrekvittering">
            <Block flex>
              <Flex flex={1}>
                <FlexKid flex={1}>
                  <Text element="h2" variant="title">
                    Ordrekvittering
                  </Text>
                  <Text variant="body3">Endre ordrekvittering</Text>
                </FlexKid>
                <FlexKid spaceLeft>
                  <Icon size="large">
                    <FiEdit />
                  </Icon>
                </FlexKid>
              </Flex>
            </Block>
          </CardLink>
        </Grid>
      </Section>
    </Fader>
  );
};

export default EmailsOverview;
