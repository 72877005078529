import React, { useContext, useMemo } from "react";
import { RouteComponentProps, Prompt, Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import { PaymentPlan, Site as ISite } from "../../../types/apiResponses";
import Text from "../../../style-guide/Text/Text";
import Form from "../../../style-guide/Inputs/Form";
import Fader from "../../../style-guide/Fader/Fader";
import Block from "../../../style-guide/Block/Block";
import usePublisher from "../../../hooks/usePublisher";
import Section from "../../../style-guide/Section/Section";
import ActionBar from "../../../components/ActionBar/ActionBar";
import Card from "../../../style-guide/Card/Card";
import createPublisherInputField from "../../../util/createPublisherInputField";
import Crumb from "../../../components/Crumb/Crumb";
import { UserContext } from "../../../context/User";
import { LEAVE_PAGE_MESSAGE } from "../../../constants/general";
import { SiteContext } from "../../../context/Site";
import { SearchOpts, useSearch } from "../../../hooks/useApi";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";
import EmptyState from "../../../style-guide/EmptyState/EmptyState";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import { ButtonLink } from "../../../style-guide/Button/Button";
import { Table, Tr, Th, Td } from "../../../style-guide/Table/Table";

interface Props extends RouteComponentProps<{}> {}

const PartialPayments: React.FC<Props> = () => {
  const user = useContext(UserContext).data;
  const siteContext = useContext(SiteContext);

  const publisher = usePublisher<ISite>({
    id: user.site!,
    baseUrl: ["site"],
    baseDashboardUrl: "/innstillinger/b2b",
    initialData: {},
    skipId: true,
    pathsToCopy: [
      {
        path: "b2bEnabled",
        label: "B2B aktivert"
      }
    ],
    onSubmit: (site: ISite) => {
      if (site) {
        siteContext.setData(site);
      }
    }
  });

  const form = publisher.form;

  const queryOpts = useMemo<SearchOpts<PaymentPlan>>(
    () => ({
      endpoint: "payment-plans"
    }),
    []
  );

  const paymentPlans = useSearch<PaymentPlan>(queryOpts);

  return (
    <Form onSubmit={publisher.form.submit}>
      <Prompt
        when={publisher.form.hasMadeChanges}
        message={() => LEAVE_PAGE_MESSAGE}
      />
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/innstillinger">Innstillinger</Crumb>
            Delbetaling
          </Text>
        </Block>
        <Section>
          <Card>
            <Block>
              <Section hugTop>
                <Text element="h2" variant="title">
                  Generelt
                </Text>
              </Section>
              {createPublisherInputField(publisher, {
                path: "partialPaymentEnabled",
                label: "Aktiver delbetaling",
                type: "boolean"
              })}
            </Block>
          </Card>
        </Section>
        {((!form.hasMadeChanges && form.data.partialPaymentEnabled) ||
          (form.hasMadeChanges && !form.data.partialPaymentEnabled)) && (
          <BusyBoy busy={paymentPlans.loading}>
            <InfiniteScroll
              pageStart={1}
              initialLoad
              loadMore={() =>
                paymentPlans.search({
                  ...queryOpts,
                  paginate: true
                })
              }
              hasMore={paymentPlans.hasMore}
            >
              <Section>
                <Card>
                  <Block>
                    <Flex>
                      <FlexKid flex={1}>
                        <Text element="h2" variant="title">
                          Betalingsplaner
                        </Text>
                      </FlexKid>
                      {!publisher.isRevision && (
                        <FlexKid spaceLeft>
                          <ButtonLink
                            type="button"
                            outlined
                            to="delbetaling/opprett"
                          >
                            Opprett betalingsplan
                          </ButtonLink>
                        </FlexKid>
                      )}
                    </Flex>
                  </Block>
                  {paymentPlans.hasSearched &&
                  paymentPlans.results.length > 0 ? (
                    <Table>
                      <thead>
                        <Tr>
                          <Th>Betalingsplan</Th>
                        </Tr>
                      </thead>
                      <tbody>
                        {paymentPlans.results.map((p) => (
                          <Tr key={p._id}>
                            <Td>
                              <Text variant="body2">
                                <Link
                                  to={`/innstillinger/delbetaling/${p._id}`}
                                >
                                  {p.name}
                                </Link>
                              </Text>
                              <Text variant="body3">
                                {p.description || "Mangler beskrivelse"}
                              </Text>
                            </Td>
                          </Tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <Block>
                      <EmptyState
                        title="Ingen betalingsplaner opprettet enda"
                        text="Betalingsplaner lar deg dele opp betalingen av utvalgte produkter i to eller flere deler."
                        action={
                          <ButtonLink type="button" to="delbetaling/opprett">
                            Opprett betalingsplan
                          </ButtonLink>
                        }
                      />
                    </Block>
                  )}
                </Card>
              </Section>
            </InfiniteScroll>
          </BusyBoy>
        )}
      </Fader>
      <ActionBar publisher={publisher} />
    </Form>
  );
};

export default PartialPayments;
