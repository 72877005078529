import React, { useEffect } from "react";
import { RouteComponentProps, Switch, Route, Prompt } from "react-router-dom";
import { MdPayment } from "react-icons/md";
import { AiOutlineProfile } from "react-icons/ai";
import { Order as IOrder } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import Fader from "../../style-guide/Fader/Fader";
import Block from "../../style-guide/Block/Block";
import usePublisher from "../../hooks/usePublisher";
import Section from "../../style-guide/Section/Section";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import Crumb from "../../components/Crumb/Crumb";
import General from "./SubViews/General";
import Form from "../../style-guide/Inputs/Form";
import ActionBar from "../../components/ActionBar/ActionBar";
import { OrderType, PaymentStatus, RevisionType } from "../../constants/api";
import { LEAVE_PAGE_MESSAGE } from "../../constants/general";
import {
  InlineActionLink,
  InlineActions
} from "../../style-guide/InlineActions/InlineActions";
import PublisherHelpers from "../../util/PublisherHelpers";
import Payments from "./SubViews/Payments";
import Status from "../../style-guide/Status/Status";
import localize from "../../util/localize";
import { paymentStatus } from "../../constants/localizations";

interface Props
  extends RouteComponentProps<{
    order: string;
  }> {}

const Order: React.FC<Props> = ({ match }) => {
  const orderId = match.params.order;

  const breadcrumbs = useBreadcrumbs();

  const publisher = usePublisher<IOrder>({
    id: orderId,
    baseUrl: ["orders"],
    baseDashboardUrl: "/butikk/ordrer",
    initialData: {
      type: OrderType.Order,
      invoiceInformation: {},
      orderLines: [],
      payments: [],
      refunds: []
    }
  });

  const form = publisher.form;
  const order = publisher.form.data;

  useEffect(() => {
    breadcrumbs.swapEntry(orderId, publisher.form.data.orderNumber);
  }, [breadcrumbs, publisher.form.data, orderId]);

  return (
    <Form onSubmit={publisher.form.submit}>
      <Prompt
        when={form.hasMadeChanges}
        message={(location) => {
          if (location.pathname.includes(orderId)) {
            return true;
          }

          return LEAVE_PAGE_MESSAGE;
        }}
      />
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/butikk/ordrer">Ordrer</Crumb>
            {form.data.orderNumber ? `#${form.data.orderNumber}` : "Laster…"}
          </Text>
        </Block>
        <Block hugTop hugBottom>
          <InlineActions>
            <InlineActionLink
              exact
              to={PublisherHelpers.createDashboardUrl(publisher)}
            >
              <AiOutlineProfile /> Generelt
            </InlineActionLink>
            <InlineActionLink
              exact
              to={PublisherHelpers.createDashboardUrl(publisher, [
                "betalinger"
              ])}
            >
              <MdPayment /> Betalinger
            </InlineActionLink>
            <Status
              wider
              onBackground
              variant={
                order.paymentStatus === PaymentStatus.Paid
                  ? "good"
                  : order.paymentStatus === PaymentStatus.Reserved ||
                    order.paymentStatus === PaymentStatus.PartiallyPaid
                  ? "neutral"
                  : undefined
              }
            >
              {localize(
                paymentStatus,
                order.paymentStatus || PaymentStatus.Manual
              )}
            </Status>
          </InlineActions>
        </Block>
        <Section hugTop>
          <Switch>
            <Route
              exact
              path={publisher.dashboardUrl}
              render={() => <General publisher={publisher} />}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/betalinger`}
              render={() => <Payments publisher={publisher} />}
            />
          </Switch>
        </Section>
      </Fader>
      <ActionBar
        publisher={publisher}
        revisions
        revisionType={RevisionType.Order}
        revisionReference={publisher.form.data._id}
      />
    </Form>
  );
};

export default Order;
