import React, {
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { ThemeContext } from "styled-components";
import BusyBoy from "../../components/BusyBoy/BusyBoy";
import Crumb from "../../components/Crumb/Crumb";
import { useApi } from "../../hooks/useApi";
import useApiUrl from "../../hooks/useApiUrl";
import useCanonicalUrl from "../../hooks/useCanonicalUrl";
import Block from "../../style-guide/Block/Block";
import Card from "../../style-guide/Card/Card";
import EmptyState from "../../style-guide/EmptyState/EmptyState";
import Fader from "../../style-guide/Fader/Fader";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import { BiggerLoading } from "../../style-guide/Loading/Loading";
import Section from "../../style-guide/Section/Section";
import { Table, Td, Th, Tr } from "../../style-guide/Table/Table";
import Text from "../../style-guide/Text/Text";
import { PageViewsStats } from "../../types/apiResponses";
import calculatePercentageGrowth from "../../util/calculatePercentageGrowth";
import createInputField from "../../util/createInputField";
import { numberFormat } from "../../util/intl";

const PageViewsByPeriod = React.lazy(
  () => import("../../components/Charts/StatGroupLineChart")
);

const Visitors: React.FC = () => {
  const themeContext = useContext(ThemeContext);
  const pageViewsEndpoint = useApiUrl(["stats", "pageViews"]);
  const [fromDate, setFromDate] = useState(null as Date | null);
  const [toDate, setToDate] = useState(null as Date | null);
  const baseCanonicalUrl = useCanonicalUrl();

  const pageViews = useApi<PageViewsStats>({
    endpoint: pageViewsEndpoint,
    initialData: {
      pageViewsLast7Days: {
        currentPeriod: 0,
        lastPeriod: 0
      },
      pageViewsByPeriod: [],
      pageViewsByPage: []
    }
  });

  useEffect(() => {
    pageViews.fetch({
      endpoint: pageViewsEndpoint,
      queryParams: {
        fromDate,
        toDate
      }
    });
  }, [fromDate, toDate]);

  const percentageGrowth = useMemo(
    () =>
      calculatePercentageGrowth(
        pageViews.resource.pageViewsLast7Days.currentPeriod,
        pageViews.resource.pageViewsLast7Days.lastPeriod
      ),
    [pageViews.resource]
  );

  return (
    <Fader>
      <Block hugTop>
        <Text element="h1" variant="display3">
          <Crumb url="/rapporter">Rapporter</Crumb>
          Besøk
        </Text>
      </Block>
      <BusyBoy>
        <Block hugTop>
          <Flex>
            <FlexKid>
              {createInputField({
                key: "fromDate",
                type: "time",
                label: "Fra-dato",
                hugTop: true,
                value: fromDate,
                onChange: (value) => setFromDate(value)
              })}
            </FlexKid>
            <FlexKid spaceLeft>
              {createInputField({
                key: "toDate",
                type: "time",
                label: "Til-dato",
                hugTop: true,
                value: toDate,
                onChange: (value) => setToDate(value)
              })}
            </FlexKid>
          </Flex>
        </Block>
        <Flex align="flex-start">
          <FlexKid flex={2}>
            <Section hugTop>
              <Card>
                <Block>
                  <Text element="h2" variant="title">
                    Sidevisninger per dag
                  </Text>
                </Block>
                {pageViews.hasFetched &&
                  pageViews.resource.pageViewsByPeriod.length < 2 && (
                    <Block hugTop>
                      <Card outlined>
                        <EmptyState
                          title="Mangler data"
                          text="Her er det dessverre ikke nok data til å vise en graf... enda!"
                        />
                      </Card>
                    </Block>
                  )}
                {pageViews.resource.pageViewsByPeriod.length > 1 && (
                  <Block hugTop>
                    <Suspense
                      fallback={<BiggerLoading loading label="Laster graf…" />}
                    >
                      <PageViewsByPeriod
                        valueLabel="Sidevisninger"
                        data={pageViews.resource.pageViewsByPeriod}
                      />
                    </Suspense>
                  </Block>
                )}
              </Card>
            </Section>
            <Section>
              <Card>
                <Block>
                  <Text element="h2" variant="title">
                    Mest besøkte sider
                  </Text>
                </Block>
                {pageViews.hasFetched &&
                  pageViews.resource.pageViewsByPage.length < 1 && (
                    <Block hugTop>
                      <Card outlined>
                        <EmptyState
                          title="Mangler data"
                          text="Her er det dessverre ikke nok data til å vise en graf... enda!"
                        />
                      </Card>
                    </Block>
                  )}
                {pageViews.resource.pageViewsByPage.length > 0 && (
                  <Table>
                    <thead>
                      <Tr>
                        <Th>URL</Th>
                        <Th align="right">Antall sidevisninger</Th>
                      </Tr>
                    </thead>
                    <tbody>
                      {pageViews.resource.pageViewsByPage.map((p) => (
                        <Tr key={p._id.type}>
                          <Td>
                            <a
                              href={`${baseCanonicalUrl}${p._id.type}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {p._id.type}
                            </a>
                          </Td>
                          <Td align="right">{numberFormat.format(p.count)}</Td>
                        </Tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Card>
            </Section>
          </FlexKid>
          <FlexKid flex={1} spaceLeft>
            <Card outlined>
              <Block>
                <Text variant="display3">
                  {numberFormat.format(
                    pageViews.resource.pageViewsLast7Days.currentPeriod
                  )}
                </Text>
                {pageViews.resource.pageViewsLast7Days.currentPeriod > 0 && (
                  <Text
                    variant="title"
                    color={
                      percentageGrowth >= 0
                        ? themeContext.colors.good
                        : themeContext.colors.warning
                    }
                  >
                    {percentageGrowth > 0 && "+"}
                    {percentageGrowth === 0
                      ? "+∞"
                      : numberFormat.format(percentageGrowth)}
                    %
                  </Text>
                )}
                <Text element="h2" variant="subheading" gutterTop>
                  Sidevisninger siste 7 dager
                </Text>
              </Block>
            </Card>
          </FlexKid>
        </Flex>
      </BusyBoy>
    </Fader>
  );
};

export default Visitors;
