import React, { useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { AiOutlineProfile, AiOutlineEdit } from "react-icons/ai";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import usePublisher from "../../hooks/usePublisher";
import { Component as IComponent } from "../../types/apiResponses";
import Fader from "../../style-guide/Fader/Fader";
import Form from "../../style-guide/Inputs/Form";
import Block from "../../style-guide/Block/Block";
import Text from "../../style-guide/Text/Text";
import Crumb from "../../components/Crumb/Crumb";
import PublisherHelpers from "../../util/PublisherHelpers";
import {
  InlineActionLink,
  InlineActions
} from "../../style-guide/InlineActions/InlineActions";
import Section from "../../style-guide/Section/Section";
import ActionBar from "../../components/ActionBar/ActionBar";
import Card from "../../style-guide/Card/Card";
import createPublisherInputField from "../../util/createPublisherInputField";
import useTheme from "../../hooks/useTheme";
import { RevisionType } from "../../constants/api";

interface Props
  extends RouteComponentProps<{
    component: string;
  }> {}

const Component: React.FC<Props> = ({ match }) => {
  const theme = useTheme();
  const breadcrumbs = useBreadcrumbs();
  const history = useHistory();
  const publisher = usePublisher<IComponent>({
    id: match.params.component,
    baseUrl: ["components"],
    baseDashboardUrl: "/innhold/komponenter",
    publishEndpoint: "publish",
    discardEndpoint: "discard",
    onCreateNew: (component) => {
      history.push(`/designer/komponenter/${component._id}`);
    },
    pathsToCopy: [
      {
        path: "name",
        label: "Navn"
      },
      {
        path: "components",
        label: "Seksjoner"
      }
    ]
  });

  useEffect(() => {
    breadcrumbs.swapEntry(match.params.component, publisher.form.data.name);
  }, [breadcrumbs, publisher.form.data, match.params.component]);

  return (
    <Form onSubmit={publisher.form.submit}>
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/innhold/komponenter">Komponenter</Crumb>
            {PublisherHelpers.getResourceTitle(
              publisher,
              "name",
              "Ny komponent"
            )}
          </Text>
        </Block>
        <Block hugTop hugBottom>
          <InlineActions>
            <InlineActionLink
              exact
              to={PublisherHelpers.createDashboardUrl(publisher)}
            >
              <AiOutlineProfile /> Generelt
            </InlineActionLink>
            {!publisher.isNew && (
              <InlineActionLink
                highlight
                exact
                to={`/designer/komponenter/${match.params.component}${window.location.search}`}
              >
                <AiOutlineEdit /> Gå til designmodus
              </InlineActionLink>
            )}
          </InlineActions>
        </Block>
        <Section hugTop>
          <Card>
            <Block>
              <Section hugTop>
                <Text element="h2" variant="title">
                  Generelt
                </Text>
              </Section>
              {createPublisherInputField(publisher, {
                path: "name",
                label: "Navn",
                type: "text",
                required: true,
                autosave: true
              })}
              {createPublisherInputField(publisher, {
                path: "component",
                label: "Komponent",
                type: "select",
                options: theme?.components
                  .filter((c) => !c.richContent)
                  .map((c) => ({
                    label: c.label || c.name,
                    value: c.name
                  })),
                required: true,
                autosave: true,
                readOnly: !publisher.isNew
              })}
              {createPublisherInputField(publisher, {
                path: "description",
                label: "Beskrivelse",
                type: "textarea",
                autosave: true
              })}
            </Block>
          </Card>
        </Section>
      </Fader>
      <ActionBar
        publisher={publisher}
        publish
        discard
        destroy
        revisions
        revisionType={RevisionType.Component}
        revisionReference={publisher.form.data.draftFor}
      />
    </Form>
  );
};

export default Component;
