import React, { useCallback, useState, useMemo } from "react";
import { Image as ImageClass } from "@ludens-reklame/rubics-sdk";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import { Product, ImageRef } from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import Card from "../../../style-guide/Card/Card";
import convertImage from "../../../util/convertImage";
import { Button, ButtonList } from "../../../style-guide/Button/Button";
import { Table, Th, Td, Tr } from "../../../style-guide/Table/Table";
import Image from "../../../style-guide/Image/Image";
import EmptyState from "../../../style-guide/EmptyState/EmptyState";
import { AiOutlinePicture, AiOutlineSearch } from "react-icons/ai";
import CrudModal from "../../../components/CrudModal/CrudModal";
import Label from "../../../style-guide/Inputs/Label";
import MediaPicker from "../../../components/MediaPicker/MediaPicker";
import Select from "../../../style-guide/Inputs/Select";
import Block from "../../../style-guide/Block/Block";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Text from "../../../style-guide/Text/Text";
import TextInput from "../../../style-guide/Inputs/TextInput";

interface Props {
  publisher: UsePublisherInterface<Product>;
}

const Images: React.FC<Props> = ({ publisher }) => {
  const form = publisher.form;
  const data = form.data;

  const [query, setQuery] = useState("");
  const { spawnModal } = useModal();

  const handleImage = useCallback(
    (image?: ImageRef) => {
      spawnModal(
        <CrudModal
          title="Produktbilde"
          initialData={image || {}}
          onSubmit={(imageData) => {
            if (image) {
              form.setField({
                path: "images",
                value: data.images.map((i) => {
                  if (i._id === image._id) {
                    return imageData;
                  }

                  return i;
                }),
                submit: !publisher.isNew
              });
            } else {
              form.setField({
                path: "images",
                value: [...data.images, imageData],
                submit: !publisher.isNew
              });
            }
          }}
          fields={[
            {
              key: "image",
              render: ({ data, setData }) => (
                <>
                  <Label>Bilde</Label>
                  <MediaPicker
                    onChange={(image) => {
                      if (image) {
                        setData({
                          ...data,
                          alt: image.alt,
                          caption: image.caption,
                          ref: image.ref || undefined,
                          url: image.url
                        });
                      } else {
                        setData(null);
                      }
                    }}
                    value={
                      data
                        ? {
                            ref:
                              typeof data.ref !== "string" &&
                              typeof data.ref !== "undefined" &&
                              data.ref !== null
                                ? data.ref._id
                                : data.ref || "",
                            alt: data.alt,
                            caption: data.caption,
                            url:
                              typeof data.ref !== "string" &&
                              typeof data.ref !== "undefined" &&
                              data.ref !== null
                                ? data.ref.secure_url
                                : data.url || ""
                          }
                        : undefined
                    }
                    editableAlt
                    editableCaption
                  />
                </>
              )
            }
          ]}
        />
      );
    },
    [spawnModal, data.images, form, publisher.isNew]
  );

  const handleDelete = useCallback(
    (id: string) => {
      if (window.confirm("Er du sikker på at du vil fjerne bildet?")) {
        form.setField({
          path: "images",
          value: data.images.filter((i) => i._id !== id),
          submit: !publisher.isNew
        });
      }
    },
    [form, data.images, publisher.isNew]
  );

  const queriedImages = useMemo(
    () =>
      query.length > 0
        ? data.images.filter((i) => {
            if (i.ref && typeof i.ref !== "string") {
              if (
                i.ref.original_filename
                  .toLowerCase()
                  .includes(query.toLowerCase())
              ) {
                return true;
              }
            }

            return false;
          })
        : data.images,
    [query, data.images]
  );

  return (
    <Fader>
      {data.images.length > 0 ? (
        <Card>
          <Block>
            <Flex>
              <FlexKid flex={1}>
                <TextInput
                  icon={<AiOutlineSearch />}
                  placeholder="Søk etter bilde"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </FlexKid>
              {!publisher.isRevision && (
                <FlexKid spaceLeft>
                  <Button onClick={() => handleImage()}>Nytt bilde</Button>
                </FlexKid>
              )}
            </Flex>
          </Block>
          <Table>
            <thead>
              <Tr>
                <Th>Bilde</Th>
                <Th>Filnavn</Th>
                <Th>Hovedbilde</Th>
                <Th>Bruk i forhåndsvisning</Th>
                <Th align="right">Handlinger</Th>
              </Tr>
            </thead>
            <tbody>
              {queriedImages.length > 0 ? (
                queriedImages.map((i) => {
                  const image = convertImage(i);
                  const url = ImageClass.createUrl({
                    image,
                    transforms: "w_120"
                  });

                  return (
                    <Tr key={i._id}>
                      <Td verticalAlign="middle">
                        <Image src={url} alt={image.alt} />
                      </Td>
                      <Td verticalAlign="middle">
                        {i.ref && typeof i.ref !== "string"
                          ? i.ref.original_filename
                          : "Mangler"}
                      </Td>
                      <Td verticalAlign="middle">
                        <Select
                          value={i.primary ? "true" : "false"}
                          disabled={publisher.isRevision}
                          onChange={(e) => {
                            form.setField({
                              path: "images",
                              value: data.images.map((ii) => {
                                const isSet = e.target.value === "true";

                                let primary = ii.primary;

                                if (ii._id === i._id) {
                                  if (isSet) {
                                    primary = true;
                                  } else {
                                    primary = false;
                                  }
                                } else if (isSet) {
                                  primary = false;
                                }

                                return {
                                  ...ii,
                                  primary
                                };
                              }),
                              submit: !publisher.isNew
                            });
                          }}
                        >
                          <option value="false">Nei</option>
                          <option value="true">Ja</option>
                        </Select>
                      </Td>
                      <Td verticalAlign="middle">
                        <Select
                          value={i.useAsPreviewBackground ? "true" : "false"}
                          disabled={publisher.isRevision}
                          onChange={(e) => {
                            form.setField({
                              path: "images",
                              value: data.images.map((ii) => {
                                const isSet = e.target.value === "true";

                                let useAsPreviewBackground =
                                  ii.useAsPreviewBackground;

                                if (ii._id === i._id) {
                                  if (isSet) {
                                    useAsPreviewBackground = true;
                                  } else {
                                    useAsPreviewBackground = false;
                                  }
                                } else if (isSet) {
                                  useAsPreviewBackground = false;
                                }

                                return {
                                  ...ii,
                                  useAsPreviewBackground
                                };
                              }),
                              submit: !publisher.isNew
                            });
                          }}
                        >
                          <option value="false">Nei</option>
                          <option value="true">Ja</option>
                        </Select>
                      </Td>
                      <Td align="right" verticalAlign="middle">
                        <ButtonList align="right">
                          <Button
                            type="button"
                            outlined
                            disabled={publisher.isRevision}
                            onClick={() => {
                              handleImage(i);
                            }}
                          >
                            Rediger
                          </Button>
                          <Button
                            type="button"
                            outlined
                            disabled={publisher.isRevision}
                            onClick={() => handleDelete(i._id)}
                          >
                            Slett
                          </Button>
                        </ButtonList>
                      </Td>
                    </Tr>
                  );
                })
              ) : (
                <Tr>
                  <Td colSpan={4}>
                    <Text variant="subheading">Ingen bilder funnet!</Text>
                  </Td>
                </Tr>
              )}
            </tbody>
          </Table>
        </Card>
      ) : (
        <EmptyState
          title="Mangler bilder"
          text={
            <>
              <Text gutterBottom>
                Hva er vel et produkt uten bilde? Her kan du legge inn både ett
                og to og tre, kanskje til og med fire bilder, dersom du ønsker
                det.
              </Text>
              <Text>
                <strong>Tips:</strong> Du kan legge inn fler enn fire bilder.
              </Text>
            </>
          }
          action={<Button onClick={() => handleImage()}>Legg til bilde</Button>}
          icon={<AiOutlinePicture />}
        />
      )}
    </Fader>
  );
};

export default Images;
