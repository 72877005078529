import React, { useContext } from "react";
import { Link, Prompt, useHistory } from "react-router-dom";
import { RiBuildingLine } from "react-icons/ri";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { Site } from "../../../types/apiResponses";
import Text from "../../../style-guide/Text/Text";
import Tabler from "../../../components/Tabler/Tabler";
import useForm from "../../../hooks/useForm";
import Form from "../../../style-guide/Inputs/Form";
import {
  Modal,
  ModalActions,
  ModalBody
} from "../../../style-guide/Modal/Modal";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";
import createInputField from "../../../util/createInputField";
import Block from "../../../style-guide/Block/Block";
import { Button, ButtonList } from "../../../style-guide/Button/Button";
import { UserContext } from "../../../context/User";
import { SiteContext } from "../../../context/Site";
import usePublisher from "../../../hooks/usePublisher";
import { LEAVE_PAGE_MESSAGE } from "../../../constants/general";
import Fader from "../../../style-guide/Fader/Fader";
import Crumb from "../../../components/Crumb/Crumb";
import Section from "../../../style-guide/Section/Section";
import ActionBar from "../../../components/ActionBar/ActionBar";
import Card from "../../../style-guide/Card/Card";
import createPublisherInputField from "../../../util/createPublisherInputField";
import { RevisionType } from "../../../constants/api";
import useSiteSelection from "../../../hooks/useSiteSelection";

const Departments: React.FC = () => {
  const { spawnModal } = useModal();
  const user = useContext(UserContext).data;
  const siteContext = useContext(SiteContext);
  const { setSite } = useSiteSelection();

  const publisher = usePublisher<Site>({
    id: user.site!,
    baseUrl: ["site"],
    baseDashboardUrl: "/innstillinger/avdelinger",
    initialData: {},
    skipId: true,
    pathsToCopy: [
      {
        path: "departmentsEnabled",
        label: "Aktiver avdelinger"
      }
    ],
    onSubmit: (site: Site) => {
      if (site) {
        siteContext.setData(site);
      }
    }
  });

  if (!siteContext.data.departmentsEnabled) {
    return (
      <Form onSubmit={publisher.form.submit}>
        <Prompt
          when={publisher.form.hasMadeChanges}
          message={() => LEAVE_PAGE_MESSAGE}
        />
        <Fader>
          <Block hugTop hugBottom>
            <Text element="h1" variant="display3" gutterBottom>
              <Crumb url="/innstillinger">Innstillinger</Crumb>
              Avdelinger
            </Text>
          </Block>
          <Section>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Generelt
                  </Text>
                </Section>
                {createPublisherInputField(publisher, {
                  path: "departmentsEnabled",
                  label: "Aktiver avdelinger",
                  type: "boolean"
                })}
              </Block>
            </Card>
          </Section>
        </Fader>
        <ActionBar
          publisher={publisher}
          revisions
          revisionType={RevisionType.Site}
          revisionReference={publisher.form.data._id}
        />
      </Form>
    );
  }

  return (
    <Tabler
      title="Avdelinger"
      crumb={{
        label: "Innstillinger",
        path: "/innstillinger"
      }}
      actions={
        <Button
          onClick={() => {
            spawnModal(<CreateDepartmentModal />);
          }}
        >
          Opprett avdeling
        </Button>
      }
      emptyState={{
        title: "Opprett din første avdeling",
        icon: <RiBuildingLine />,
        text: "Avdelinger gjør det mulig å dele innhold og produkter fra hovednettsiden ned til ulike avdelinger. Avdelinger fungerer som frittstående nettsider, men arver innhold fra denne nettsiden.",
        action: (
          <Button
            onClick={() => {
              spawnModal(<CreateDepartmentModal />);
            }}
          >
            Opprett avdeling
          </Button>
        )
      }}
      tight
      url={["site", "departments"]}
      columns={[
        {
          Header: "Avdeling",
          accessor: "label",
          verticalAlign: "middle"
        },
        {
          Header: "Handlinger",
          accessor: "actions",
          align: "right",
          verticalAlign: "middle"
        }
      ]}
      createRow={(department: Site) => ({
        ...department,
        label: (
          <Text variant="body2">
            <Link to={`/innstillinger/avdelinger/${department._id}`}>
              {department.displayName || department.name}
            </Link>
          </Text>
        ),
        actions: (
          <ButtonList align="right">
            <Button
              type="button"
              outlined
              onClick={() => {
                setSite(department);
                window.location.href = "/";
              }}
            >
              Gå til dashbord
            </Button>
          </ButtonList>
        )
      })}
    />
  );
};

const CreateDepartmentModal: React.FC = () => {
  const history = useHistory();
  const { despawnModal } = useModal();
  const form = useForm<Site>(
    {},
    {
      endpoint: "site/departments",
      method: "POST",
      onSuccess: (site: Site) => {
        if ("_id" in site) {
          despawnModal();
          history.push(`/innstillinger/avdelinger/${site._id}`);
        }
      }
    }
  );

  return (
    <Form onSubmit={form.submit}>
      <Modal maxWidth="32rem">
        <BusyBoy busy={form.submitting}>
          <ModalBody>
            <Block>
              <Text variant="title" gutterBottom>
                Opprett avdeling
              </Text>
              {createInputField({
                key: "displayName",
                label: "Navn",
                value: form.getValue("displayName"),
                required: true,
                type: "text",
                onChange: (value) =>
                  form.setField({
                    path: "displayName",
                    value
                  })
              })}
              {createInputField({
                key: "notificationEmail",
                label: "Varslingsepost",
                value: form.getValue("notificationEmail"),
                type: "email",
                onChange: (value) =>
                  form.setField({
                    path: "notificationEmail",
                    value
                  })
              })}
            </Block>
          </ModalBody>
          <ModalActions>
            <ButtonList align="right">
              <Button
                type="button"
                outlined
                onClick={() => {
                  despawnModal();
                }}
              >
                Avbryt
              </Button>
              <Button type="submit" disabled={form.submitting}>
                {form.submitting ? "Oppretter…" : "Opprett"}
              </Button>
            </ButtonList>
          </ModalActions>
        </BusyBoy>
      </Modal>
    </Form>
  );
};

export default Departments;
