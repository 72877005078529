import React, { ReactNode, useCallback, useMemo, useState } from "react";
import {
  DraggableProvidedDragHandleProps,
  DraggableStateSnapshot
} from "react-beautiful-dnd";
import slugify from "slugify";
import { AiOutlineDelete } from "react-icons/ai";
import { MdDragHandle } from "react-icons/md";
import { RubicsFormFieldType } from "@ludens-reklame/rubics-sdk";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import {
  CustomizeOptionCondition,
  Form,
  FormField,
  FormFieldOption
} from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import Section from "../../../style-guide/Section/Section";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import createPublisherInputField from "../../../util/createPublisherInputField";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import { Button, ButtonList } from "../../../style-guide/Button/Button";
import createId, { createObjectId } from "../../../util/createId";
import {
  InlineEditColumn,
  InlineEditHead
} from "../../../style-guide/InlineEdit/InlineEdit";
import { Dragger, Dropper } from "../../../components/DnD/DnD";
import InlineEdit from "../../../components/InlineEdit/InlineEdit";
import localize from "../../../util/localize";
import { formFieldType, operations } from "../../../constants/localizations";
import createInputField from "../../../util/createInputField";
import CrudModal, {
  createCrudModalField
} from "../../../components/CrudModal/CrudModal";
import { Table, Tr, Th, Td } from "../../../style-guide/Table/Table";

interface Props {
  publisher: UsePublisherInterface<Form>;
}

const General: React.FC<Props> = ({ publisher }) => {
  const form = publisher.form;

  const [newId, setNewId] = useState<string | null>(null);

  const createField = useCallback(() => {
    const id = createObjectId();

    form.setField({
      path: "fields",
      value: [
        ...(form.data.fields || []),
        {
          _id: id,
          type: RubicsFormFieldType.String,
          options: [],
          conditions: []
        }
      ]
    });

    setNewId(id);
  }, [form]);

  return (
    <Fader>
      <Section hugTop>
        <Card>
          <Block>
            <Section hugTop>
              <Text element="h2" variant="title">
                Generelt
              </Text>
            </Section>
            {createPublisherInputField(publisher, {
              path: "name",
              label: "Navn",
              type: "text",
              required: true
            })}
            {createPublisherInputField(publisher, {
              path: "internalDescription",
              label: "Intern beskrivelse",
              type: "textarea"
            })}
            {createPublisherInputField(publisher, {
              path: "publicDescription",
              label: "Offentlig beskrivelse",
              type: "draft"
            })}
            {createPublisherInputField(publisher, {
              path: "submitButtonText",
              label: "Send inn-knappetekst",
              type: "text"
            })}
            {createPublisherInputField(publisher, {
              path: "successText",
              label: "Takk-tekst",
              type: "draft"
            })}
            {createPublisherInputField(publisher, {
              path: "forwardUrl",
              label: "URL for videresending",
              type: "url"
            })}
          </Block>
        </Card>
      </Section>
      <Section>
        <Card>
          <Block>
            <Flex>
              <FlexKid flex={1}>
                <Text element="h2" variant="title">
                  Felter
                </Text>
              </FlexKid>
              <FlexKid>
                <Button type="button" outlined onClick={() => createField()}>
                  Nytt felt
                </Button>
              </FlexKid>
            </Flex>
          </Block>
          <InlineEditHead>
            <InlineEditColumn width="400px">Navn</InlineEditColumn>
            <InlineEditColumn width="200px">Type</InlineEditColumn>
            <InlineEditColumn width="200px">Påkrevd</InlineEditColumn>
          </InlineEditHead>
          <Dropper
            id="fields"
            onDragEnd={(r) => {
              if (r.destination) {
                const list = Array.from(form.data.fields || []);
                const [removed] = list.splice(r.source.index, 1);

                list.splice(r.destination.index, 0, removed);

                form.setField({
                  path: "fields",
                  value: list
                });
              }
            }}
          >
            {form.data.fields.length > 0 ? (
              form.data.fields.map((f, k) => (
                <Dragger key={f._id} id={f._id} index={k} delegateDragHandle>
                  <Field
                    key={f._id}
                    field={f}
                    fields={form.data.fields}
                    isNew={f._id === newId}
                    onChange={(data) =>
                      form.setField({
                        path: "fields",
                        value: form.data.fields.map((ff, kk) => {
                          if (kk === k) {
                            return data;
                          }

                          return ff;
                        })
                      })
                    }
                    onDelete={() => {
                      if (window.confirm("Er du sikker på at du vil slette?")) {
                        form.setField({
                          path: "fields",
                          value: form.data.fields.filter((_, kk) => kk !== k)
                        });
                      }
                    }}
                  />
                </Dragger>
              ))
            ) : (
              <Block hugTop>
                <Text variant="subheading">Ingen felter funnet</Text>
              </Block>
            )}
          </Dropper>
        </Card>
      </Section>
    </Fader>
  );
};

interface FieldProps {
  field: FormField;
  fields: FormField[];
  isNew: boolean;
  onChange: (field: FormField) => any;
  onDelete: () => any;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  dragState?: DraggableStateSnapshot;
}

const Field: React.FC<FieldProps> = ({
  field,
  fields,
  isNew,
  onChange,
  onDelete,
  dragHandleProps,
  dragState
}) => {
  const { spawnModal } = useModal();

  const hasOptions = useMemo<boolean>(() => {
    return (
      field.type === RubicsFormFieldType.Select ||
      field.type === RubicsFormFieldType.Radio ||
      field.type === RubicsFormFieldType.Checkbox
    );
  }, [field.type]);

  const createConditionsModal = useCallback(
    (condition?: CustomizeOptionCondition, key?: number) => {
      spawnModal(
        <CrudModal
          title="Vis tilpasningsfelt dersom…"
          initialData={condition || { internalId: createId() }}
          onSubmit={(data) => {
            if (condition) {
              onChange({
                ...field,
                conditions: (field.conditions || []).map((f, k) => {
                  if (k === key) {
                    return data;
                  }

                  return f;
                })
              });
            } else
              onChange({
                ...field,
                conditions: [...(field.conditions || []), data]
              });
          }}
          fields={[
            {
              key: "targetKey",
              render: (payload) =>
                createCrudModalField({
                  payload,
                  key: "targetKey",
                  type: "select",
                  label: "Mål",
                  required: true,
                  options: fields
                    .filter((f) => f.name !== field.name)
                    .map((f) => ({
                      value: f.name,
                      label: f.label
                    }))
                })
            },
            {
              key: "targetOperation",
              render: (payload) =>
                createCrudModalField({
                  payload,
                  key: "targetOperation",
                  type: "select",
                  label: "Operasjon",
                  required: true,
                  options: Object.keys(operations).map((k) => ({
                    value: k,
                    label: operations[k]
                  }))
                })
            },
            {
              key: "targetValue",
              render: (payload) => {
                const target = payload.data.targetKey
                  ? fields.find((f) => f.name === payload.data.targetKey)
                  : undefined;

                if (!target) {
                  return null;
                }

                if (payload.data.targetOperation === "set") {
                  return null;
                }

                const hasOptions =
                  target.type === RubicsFormFieldType.Radio ||
                  target.type === RubicsFormFieldType.Select;

                return createCrudModalField({
                  payload,
                  key: "targetValue",
                  type: hasOptions ? "select" : "text",
                  label: "Verdi",
                  required: true,
                  options: hasOptions
                    ? (target.options || []).map((o) => ({
                        value: o.value,
                        label: o.value
                      }))
                    : undefined
                });
              }
            }
          ]}
        />
      );
    },
    [field, fields]
  );

  const createOptionModal = useCallback(
    (option?: FormFieldOption, key?: number) => {
      spawnModal(
        <CrudModal
          title="Valg"
          initialData={option || { internalId: createId() }}
          onSubmit={(data) => {
            if (option) {
              onChange({
                ...field,
                options: field.options.map((o, k) => {
                  if (k === key) {
                    return data;
                  }

                  return o;
                })
              });
            } else
              onChange({
                ...field,
                options: [...field.options, data]
              });
          }}
          fields={[
            {
              key: "value",
              render: (payload) =>
                createCrudModalField({
                  payload,
                  key: "value",
                  type: "text",
                  label: "Verdi",
                  required: true
                })
            },
            {
              key: "label",
              render: (payload) =>
                createCrudModalField({
                  payload,
                  key: "label",
                  type: "text",
                  label: "Etikett",
                  required: true
                })
            },
            {
              key: "required",
              render: (payload) =>
                createCrudModalField({
                  payload,
                  key: "required",
                  type: "boolean",
                  label: "Påkrevd"
                })
            }
          ]}
        />
      );
    },
    [field]
  );

  const controllerSettings = useMemo<ReactNode>(() => {
    switch (field.type) {
      case RubicsFormFieldType.File:
        return createInputField({
          key: "accept",
          type: "text",
          label: "Aksepterer filtyper",
          value: field.accept,
          placeholder: "image/*,.pdf",
          onChange: (value) => {
            onChange({
              ...field,
              accept: value
            });
          }
        });
      case RubicsFormFieldType.Number:
        return (
          <>
            {createInputField({
              key: "min",
              type: "number",
              label: "Minimumsverdi",
              value: field.min,
              placeholder: "0",
              onChange: (value) => {
                onChange({
                  ...field,
                  min: value
                });
              }
            })}
            {createInputField({
              key: "max",
              type: "number",
              label: "Maksverdi",
              value: field.max,
              placeholder: "9999999",
              onChange: (value) => {
                onChange({
                  ...field,
                  max: value
                });
              }
            })}
          </>
        );
      case RubicsFormFieldType.String:
      case RubicsFormFieldType.Text:
      case RubicsFormFieldType.Email:
      case RubicsFormFieldType.Url:
      case RubicsFormFieldType.Tel:
        return (
          <>
            {(field.type === RubicsFormFieldType.String ||
              field.type === RubicsFormFieldType.Text) &&
              createInputField({
                key: "autocomplete",
                type: "text",
                label: "Autofullfør",
                value: field.autocomplete,
                placeholder: "off, on, name, street-address, osv",
                onChange: (value) => {
                  onChange({
                    ...field,
                    autocomplete: value
                  });
                }
              })}
            {createInputField({
              key: "minlength",
              type: "number",
              label: "Minimumslengde",
              value: field.minlength,
              placeholder: "0",
              onChange: (value) => {
                onChange({
                  ...field,
                  minlength: value
                });
              }
            })}
            {createInputField({
              key: "maxlength",
              type: "number",
              label: "Makslengde",
              value: field.maxlength,
              placeholder: "9999999",
              onChange: (value) => {
                onChange({
                  ...field,
                  maxlength: value
                });
              }
            })}
            {field.type !== RubicsFormFieldType.Text &&
              createInputField({
                key: "pattern",
                type: "text",
                label: "Avansert validering (pattern)",
                value: field.pattern,
                placeholder: "[a-z]{4,8}",
                onChange: (value) => {
                  onChange({
                    ...field,
                    pattern: value
                  });
                }
              })}
            {field.pattern &&
              createInputField({
                key: "patternDescription",
                type: "text",
                label: "Beskrivelse av avansert validering",
                value: field.patternDescription,
                required: true,
                placeholder:
                  "Feltet må være i små bokstaver og ha en lengde på 4-8 karakterer.",
                onChange: (value) => {
                  onChange({
                    ...field,
                    patternDescription: value
                  });
                }
              })}
          </>
        );
      default:
        return null;
    }
  }, [field, onChange]);

  return (
    <InlineEdit
      expanded={isNew}
      background={
        dragState && dragState.isDragging ? "backgroundVariant" : undefined
      }
      alignItems="flex-start"
      actions={[
        <Button
          type="button"
          aria-label="Slett"
          circular
          outlined
          smaller
          onClick={onDelete}
        >
          <AiOutlineDelete />
        </Button>,
        <Button
          type="button"
          aria-label="Endre rekkefølge"
          outlined
          circular
          smaller
          {...dragHandleProps}
        >
          <MdDragHandle />
        </Button>
      ]}
      headerColumns={[
        {
          width: "400px",
          node: (
            <>
              <Text>{field.label || "Mangler etikett"}</Text>
              <Text variant="body3">{field.name || "Mangler navn"}</Text>
            </>
          )
        },
        {
          width: "200px",
          node: (
            <Text variant="body3">{localize(formFieldType, field.type)}</Text>
          )
        },
        {
          width: "200px",
          node: <Text variant="body3">{field.required ? "Ja" : "Nei"}</Text>
        }
      ]}
    >
      <Block>
        {field.type === RubicsFormFieldType.Info ? (
          <Flex align="top">
            <FlexKid flex={1}>
              {createInputField({
                key: "type",
                label: "Type",
                type: "select",
                value: field.type,
                required: true,
                hugTop: true,
                autoFocus: isNew,
                options: Object.keys(formFieldType).map((k) => ({
                  label: formFieldType[k],
                  value: k
                })),
                onChange: (value) => {
                  onChange({
                    ...field,
                    type: value
                  });
                }
              })}
            </FlexKid>
            <FlexKid flex={1} spaceLeft>
              {createInputField({
                key: "description",
                type: "draft",
                label: "Beskrivelse",
                value: field.description,
                hugTop: true,
                onChange: (value) => {
                  onChange({
                    ...field,
                    description: value
                  });
                }
              })}
            </FlexKid>
          </Flex>
        ) : (
          <Flex align="top">
            <FlexKid flex={1}>
              {createInputField({
                key: "type",
                label: "Type",
                type: "select",
                value: field.type,
                required: true,
                hugTop: true,
                autoFocus: isNew,
                options: Object.keys(formFieldType).map((k) => ({
                  label: formFieldType[k],
                  value: k
                })),
                onChange: (value) => {
                  onChange({
                    ...field,
                    type: value
                  });
                }
              })}
              {createInputField({
                key: "label",
                type: "text",
                label: "Etikett",
                value: field.label,
                required: true,
                onChange: (value) => {
                  if (!isNew) {
                    onChange({
                      ...field,
                      label: value
                    });
                  } else {
                    onChange({
                      ...field,
                      label: value,
                      name: slugify(value, { lower: true })
                    });
                  }
                }
              })}
              {createInputField({
                key: "name",
                type: "text",
                label: "Systemnavn",
                value: field.name,
                required: true,
                onChange: (value) => {
                  onChange({
                    ...field,
                    name: value
                  });
                }
              })}
              {field.type !== RubicsFormFieldType.Checkbox &&
                field.type !== RubicsFormFieldType.Hidden &&
                createInputField({
                  key: "required",
                  label: "Påkrevd",
                  type: "boolean",
                  value: field.required,
                  onChange: (value) => {
                    onChange({
                      ...field,
                      required: value
                    });
                  }
                })}
              {field.type !== RubicsFormFieldType.Hidden &&
                createInputField({
                  key: "disabled",
                  label: "Deaktivert",
                  type: "boolean",
                  value: field.disabled,
                  onChange: (value) => {
                    onChange({
                      ...field,
                      disabled: value
                    });
                  }
                })}
              {field.type !== RubicsFormFieldType.Checkbox &&
                field.type !== RubicsFormFieldType.Select &&
                field.type !== RubicsFormFieldType.Radio &&
                field.type !== RubicsFormFieldType.File &&
                field.type !== RubicsFormFieldType.Hidden &&
                createInputField({
                  key: "readonly",
                  label: "Kun lesbar",
                  type: "boolean",
                  value: field.readonly,
                  onChange: (value) => {
                    onChange({
                      ...field,
                      readonly: value
                    });
                  }
                })}
              {controllerSettings}
            </FlexKid>
            <FlexKid flex={1} spaceLeft>
              {field.type !== RubicsFormFieldType.File &&
                field.type !== RubicsFormFieldType.Checkbox &&
                createInputField({
                  key: "defaultValue",
                  label: "Standardverdi",
                  type:
                    field.type === RubicsFormFieldType.Select ||
                    field.type === RubicsFormFieldType.Radio
                      ? "select"
                      : "text",
                  value: field.defaultValue,
                  options: field.options,
                  hugTop: true,
                  onChange: (value) => {
                    onChange({
                      ...field,
                      defaultValue: value
                    });
                  }
                })}
              {field.type !== RubicsFormFieldType.File &&
                field.type !== RubicsFormFieldType.Radio &&
                field.type !== RubicsFormFieldType.Select &&
                field.type !== RubicsFormFieldType.Checkbox &&
                field.type !== RubicsFormFieldType.Hidden &&
                createInputField({
                  key: "placeholder",
                  type: "text",
                  label: "Eksempeltekst",
                  value: field.placeholder,
                  onChange: (value) => {
                    onChange({
                      ...field,
                      placeholder: value
                    });
                  }
                })}
              {field.type !== RubicsFormFieldType.Hidden &&
                createInputField({
                  key: "description",
                  type: "draft",
                  label: "Beskrivelse",
                  value: field.description,
                  hugTop:
                    field.type === RubicsFormFieldType.File ||
                    field.type === RubicsFormFieldType.Checkbox,
                  onChange: (value) => {
                    onChange({
                      ...field,
                      description: value
                    });
                  }
                })}
            </FlexKid>
          </Flex>
        )}
        {hasOptions && (
          <Section hugBottom tight>
            <Section tight>
              <Flex>
                <FlexKid flex={1}>
                  <Text>Valg</Text>
                </FlexKid>
                <FlexKid>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      createOptionModal();
                    }}
                  >
                    + Nytt valg
                  </a>
                </FlexKid>
              </Flex>
            </Section>
            <Card outlined>
              <Table>
                <thead>
                  <Tr>
                    <Th>Verdi</Th>
                    <Th>Etikett</Th>
                    <Th>Påkrevd</Th>
                    <Th align="right">Handlinger</Th>
                  </Tr>
                </thead>
                <tbody>
                  {field.options.length > 0 ? (
                    field.options.map((o, k) => (
                      //@ts-ignore
                      <Tr key={o._id || o.internalId}>
                        <Td verticalAlign="middle">{o.value}</Td>
                        <Td verticalAlign="middle">{o.label}</Td>
                        <Td verticalAlign="middle">
                          {o.required ? "Ja" : "Nei"}
                        </Td>
                        <Td verticalAlign="middle" align="right">
                          <ButtonList align="right">
                            <Button
                              type="button"
                              outlined
                              onClick={() => createOptionModal(o, k)}
                            >
                              Rediger
                            </Button>
                            <Button
                              type="button"
                              outlined
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Er du sikker på at du vil slette?"
                                  )
                                ) {
                                  onChange({
                                    ...field,
                                    options: field.options.filter(
                                      (_, kk) => kk !== k
                                    )
                                  });
                                }
                              }}
                            >
                              Slett
                            </Button>
                          </ButtonList>
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan={3}>
                        <Text variant="subheading">Ingen valg enda</Text>
                      </Td>
                    </Tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </Section>
        )}
        {field.type !== RubicsFormFieldType.Hidden && (
          <Section tight>
            <Section tight>
              <Flex>
                <FlexKid flex={1}>
                  <Text>Vilkår for visning</Text>
                </FlexKid>
                <FlexKid>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      createConditionsModal();
                    }}
                  >
                    + Nytt vilkår
                  </a>
                </FlexKid>
              </Flex>
            </Section>
            <Card outlined>
              <Table>
                <thead>
                  <Tr>
                    <Th>Vilkår</Th>
                    <Th align="right">Handlinger</Th>
                  </Tr>
                </thead>
                <tbody>
                  {(field.conditions || []).length > 0 ? (
                    (field.conditions || []).map((c, k) => (
                      //@ts-ignore
                      <Tr key={c._id || c.internalId}>
                        <Td verticalAlign="middle">
                          {c.targetKey}{" "}
                          {localize(
                            operations,
                            c.targetOperation || "eq"
                          ).toLowerCase()}{" "}
                          {c.targetValue}
                        </Td>
                        <Td verticalAlign="middle" align="right">
                          <ButtonList align="right">
                            <Button
                              type="button"
                              outlined
                              onClick={() => createConditionsModal(c, k)}
                            >
                              Rediger
                            </Button>
                            <Button
                              type="button"
                              outlined
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Er du sikker på at du vil slette?"
                                  )
                                ) {
                                  onChange({
                                    ...field,
                                    conditions: (field.conditions || []).filter(
                                      (_, kk) => kk !== k
                                    )
                                  });
                                }
                              }}
                            >
                              Slett
                            </Button>
                          </ButtonList>
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan={2}>
                        <Text variant="subheading">Ingen vilkår enda</Text>
                      </Td>
                    </Tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </Section>
        )}
      </Block>
    </InlineEdit>
  );
};

export default General;
