import { useContext, useCallback, useState } from "react";
import { SiteContext } from "../context/Site";
import { Site } from "../types/apiResponses";
import api from "../util/api";

interface UseSiteRefreshInterface {
  refresh: () => Promise<void>;
  loading: boolean;
}

function useSiteRefresh(): UseSiteRefreshInterface {
  const [loading, setLoading] = useState<boolean>(false);
  const siteContext = useContext(SiteContext);

  const handleRefresh = useCallback(async () => {
    setLoading(true);

    const site = await api<Site>({
      endpoint: "site"
    });

    if (site && "name" in site) {
      siteContext.setData(site);
    }

    setLoading(false);
  }, [siteContext.setData]);

  return {
    refresh: handleRefresh,
    loading
  };
}

export default useSiteRefresh;
