import React from "react";
import { Switch, Route, Redirect } from "react-router";
import Commerce from "./Commerce";
import Visitors from "./Visitors";

const ReportsRoutes: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path="/rapporter"
        render={() => <Redirect to="/rapporter/besok" />}
      />
      <Route exact path="/rapporter/besok" component={Visitors} />
      <Route exact path="/rapporter/butikk" component={Commerce} />
    </Switch>
  );
};

export default ReportsRoutes;
