import styled from "styled-components";

const Pre = styled.pre`
  display: block;
  border: 1px solid ${(props) => props.theme.colors.borderDim};
  border-radius: 8px;
  padding: ${(props) => props.theme.spacing.small};
`;

export default Pre;
