import React, { useMemo, useContext } from "react";
import { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";
import moment from "moment";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import {
  Company,
  CompanyTeam,
  Customer,
  Order,
  OrderRefundItem
} from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Section from "../../../style-guide/Section/Section";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import Notes from "../../../components/Notes/Notes";
import { Table, Th, Tr, Td, Tfoot } from "../../../style-guide/Table/Table";
import { currencyFormat, numberFormat } from "../../../util/intl";
import { CompanyChip, CustomerChip } from "../../../components/Chip/Chip";
import Field from "../../../style-guide/Inputs/Field";
import Ruler from "../../../style-guide/Ruler/Ruler";
import createPublisherInputField from "../../../util/createPublisherInputField";
import { orderStatus } from "../../../constants/localizations";
import { OrderStatus, OrderType } from "../../../constants/api";
import getPopulatedValue from "../../../util/getPopulatedValue";
import { BookingModal } from "../../Booking/Calendar";
import Status from "../../../style-guide/Status/Status";

interface Props {
  publisher: UsePublisherInterface<Order>;
}

const General: React.FC<Props> = ({ publisher }) => {
  const order = publisher.form.data;

  const customer = useMemo<Customer | undefined>(() => {
    if (order.customer && typeof order.customer !== "string") {
      return order.customer;
    }

    return undefined;
  }, [order]);

  const company = useMemo<Company | undefined>(() => {
    if (order.company && typeof order.company !== "string") {
      return order.company;
    }

    return undefined;
  }, [order]);

  const team = useMemo<CompanyTeam | undefined>(() => {
    if (order.team && typeof order.team !== "string") {
      return order.team;
    }

    return undefined;
  }, [order]);

  return (
    <Fader>
      <Flex align="flex-start">
        <FlexKid flex={5}>
          <Section hugTop>
            <Card>
              <Block>
                <Text element="h2" variant="title">
                  Ordrelinjer
                </Text>
              </Block>
              <OrderTable
                order={order}
                onUpdate={() => publisher.form.refresh()}
              />
            </Card>
          </Section>
          {Array.isArray(order.subscriptions) &&
            order.subscriptions.length > 0 && (
              <Section>
                <Card>
                  <Block>
                    <Text element="h2" variant="title">
                      Tilknyttede abonnementer
                    </Text>
                  </Block>
                  <Table>
                    <thead>
                      <Tr>
                        <Th>Abonnement</Th>
                        <Th>Opprettet</Th>
                        <Th>Neste ordre</Th>
                        <Th align="center">Aktiv</Th>
                      </Tr>
                    </thead>
                    <tbody>
                      {order.subscriptions.map((s) => {
                        if (typeof s === "string") {
                          return null;
                        }

                        return (
                          <Tr key={s._id}>
                            <Td verticalAlign="middle">
                              <Text variant="body2">
                                <Link to={`/butikk/abonnementer/${s._id}`}>
                                  #{s.subscriptionNumber}
                                </Link>
                              </Text>
                            </Td>
                            <Td verticalAlign="middle">
                              <Text variant="body3">
                                {moment(s.startedDate).format("D[.]M[.]YYYY")}
                              </Text>
                            </Td>
                            <Td verticalAlign="middle">
                              <Text variant="body3">
                                {moment(s.renewalDate).format("D[.]M[.]YYYY")}
                              </Text>
                            </Td>
                            <Td align="center" verticalAlign="middle">
                              <Status variant={s.active ? "good" : "neutral"}>
                                {s.active ? "Ja" : "Nei"}
                              </Status>
                            </Td>
                          </Tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card>
              </Section>
            )}
          <Section>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Notater
                  </Text>
                </Section>
                <Notes order={order} readOnly={publisher.isRevision} />
              </Block>
            </Card>
          </Section>
        </FlexKid>
        <FlexKid flex={2} spaceLeft>
          <Section hugTop>
            <Card>
              <Block>
                <Field hugTop hugBottom>
                  <Text>Opprettet</Text>
                  <Text variant="body3">
                    {moment(order.timestamp).format("D[.] MMMM Y [kl.] HH:mm")}
                  </Text>
                </Field>
                {order.type === OrderType.Order &&
                  createPublisherInputField(publisher, {
                    path: "status",
                    label: "Status",
                    type: "select",
                    readOnly:
                      order.status === OrderStatus.Canceled &&
                      !publisher.form.hasMadeChanges,
                    options: Object.keys(orderStatus)
                      .filter((k) => k !== "failed")
                      .map((k) => ({
                        label: orderStatus[k],
                        value: k
                      }))
                  })}
              </Block>
            </Card>
          </Section>
          <Section hugTop>
            <Card>
              {company || customer ? (
                <>
                  <Block>
                    <Section hugTop>
                      <Text element="h2" variant="title">
                        Kunde
                      </Text>
                    </Section>
                    {company && (
                      <Field hugBottom={!customer}>
                        <CompanyChip company={company} team={team} />
                      </Field>
                    )}
                    {customer && (
                      <Field hugBottom>
                        <CustomerChip customer={customer} />
                      </Field>
                    )}
                  </Block>
                  <Ruler />
                </>
              ) : (
                <Block hugBottom>
                  <Text element="h2" variant="title" gutterBottom>
                    Kunde
                  </Text>
                </Block>
              )}
              <Block hugTop>
                <Text variant="body2">Kontaktinformasjon</Text>
                <Text variant="body3">
                  {order.invoiceInformation.name || "Mangler navn"}
                </Text>
                <Text variant="body3">
                  {order.invoiceInformation.email || "Mangler e-post"}
                </Text>
                <Text variant="body2" gutterTop>
                  Leveringsadresse
                </Text>
                <Text variant="body3">
                  {order.invoiceInformation.address || "Mangler adresse"}
                </Text>
                <Text variant="body3">
                  {order.invoiceInformation.postalCode}{" "}
                  {order.invoiceInformation.city}
                </Text>
                <Text variant="body3">{order.invoiceInformation.country}</Text>
              </Block>
            </Card>
          </Section>
        </FlexKid>
      </Flex>
    </Fader>
  );
};

interface OrderTableProps {
  order: Order;
  onUpdate?: (order: Order) => any;
}

export const OrderTable: React.FC<OrderTableProps> = ({ order, onUpdate }) => {
  const themeContext = useContext(ThemeContext);
  const { spawnModal } = useModal();

  const refund = useMemo<number>(() => {
    return (order.refunds || []).reduce((v, r) => v + r.total, 0);
  }, [order]);

  const orderTotal = useMemo<number>(() => {
    return order.total - refund;
  }, [refund, order]);

  return (
    <Table>
      <thead>
        <Tr>
          <Th>Beskrivelse</Th>
          <Th align="right">Kostnad</Th>
          <Th align="right">Antall</Th>
          <Th align="right">Totalt</Th>
          <Th align="right">Avgift</Th>
        </Tr>
      </thead>
      <tbody>
        {order.orderLines.map((l) => {
          const refunds = getLineRefund("orderLine", order, l._id);
          const booking =
            l.booking && typeof l.booking !== "string" ? l.booking : undefined;

          return (
            <Tr key={l._id}>
              <Td>
                <Flex align="flex-start">
                  {!!l.bundleReference && <Flex>— &nbsp;</Flex>}
                  <FlexKid flex={1}>
                    <Text>{l.name}</Text>
                    {booking && (
                      <Text>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            spawnModal(
                              <BookingModal
                                slot={booking}
                                onUpdate={() => {
                                  if (typeof onUpdate === "function") {
                                    onUpdate(order);
                                  }
                                }}
                              />
                            );
                          }}
                        >
                          {getPopulatedValue(
                            booking.service,
                            "name",
                            "Slettet"
                          )}{" "}
                          {moment(booking.start).format(
                            "dddd D[.] MMMM Y [kl] HH:mm"
                          )}{" "}
                          – {moment(booking.end).format("HH:mm")}
                        </a>
                      </Text>
                    )}
                    {l.customizations.map((c) => {
                      let value = c.value;

                      if (
                        value &&
                        typeof value === "object" &&
                        "url" in value
                      ) {
                        value = (
                          <a
                            href={c.value.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {c.value.filename}
                          </a>
                        );
                      }

                      return (
                        <Text key={c._id} variant="body3">
                          {c.label}: {value}
                        </Text>
                      );
                    })}
                    {l.productAttributes.map((a) => (
                      <Text key={a._id} variant="body3">
                        {a.name}: {a.value}
                      </Text>
                    ))}
                  </FlexKid>
                </Flex>
              </Td>
              <Td align="right">
                <Text align="right">
                  {currencyFormat.format(l.productPrice)}
                </Text>
                {l.customizations.map((c) =>
                  typeof c.subtotal === "number" ? (
                    <Text align="right">
                      {currencyFormat.format(c.subtotal || 0)}
                    </Text>
                  ) : null
                )}
              </Td>
              <Td align="right">
                <Text align="right">{numberFormat.format(l.amount)}</Text>
                {refunds.amount > 0 && (
                  <Text align="right" color={themeContext.colors.warning}>
                    {numberFormat.format(
                      -Math.abs(
                        refunds.amount < l.amount ? refunds.amount : l.amount
                      )
                    )}
                  </Text>
                )}
              </Td>
              <Td align="right">
                <Text align="right">{currencyFormat.format(l.total)}</Text>
                {l.discount > 0 && (
                  <Text variant="body3" align="right">
                    {l.discounts.map((d) => d.name).join(", ")}:{" "}
                    {currencyFormat.format(l.discount)} rabatt
                  </Text>
                )}
                {refunds.total > 0 && (
                  <Text align="right" color={themeContext.colors.warning}>
                    {currencyFormat.format(-Math.abs(refunds.total))}
                  </Text>
                )}
              </Td>
              <Td align="right">
                <Text align="right">{currencyFormat.format(l.totalTax)}</Text>
                {refunds.totalTax > 0 && (
                  <Text align="right" color={themeContext.colors.warning}>
                    {currencyFormat.format(-Math.abs(refunds.totalTax))}
                  </Text>
                )}
              </Td>
            </Tr>
          );
        })}
        {order.shippingLines.map((l) => {
          const refunds = getLineRefund("shippingLine", order, l._id);

          return (
            <Tr key={l._id}>
              <Td>
                <Text>{l.methodName}</Text>
                <Text variant="body3">Frakt</Text>
              </Td>
              <Td align="right">{currencyFormat.format(l.total)}</Td>
              <Td align="right">
                <Text align="right">1</Text>
                {refunds.amount > 0 && (
                  <Text align="right" color={themeContext.colors.warning}>
                    {numberFormat.format(-1)}
                  </Text>
                )}
              </Td>
              <Td align="right">
                <Text align="right">{currencyFormat.format(l.total)}</Text>
                {refunds.total > 0 && (
                  <Text align="right" color={themeContext.colors.warning}>
                    {currencyFormat.format(-Math.abs(refunds.total))}
                  </Text>
                )}
              </Td>
              <Td align="right">
                <Text align="right">{currencyFormat.format(l.totalTax)}</Text>
                {refunds.totalTax > 0 && (
                  <Text align="right" color={themeContext.colors.warning}>
                    {currencyFormat.format(-Math.abs(refunds.totalTax))}
                  </Text>
                )}
              </Td>
            </Tr>
          );
        })}
      </tbody>
      <Tfoot style={{ lineHeight: "2" }}>
        <Tr>
          <Td align="right" colSpan={4}>
            <Text align="right">Varer</Text>
            <Text align="right">Frakt</Text>
            <Text align="right">Avgift</Text>
            {refund > 0 && (
              <Text align="right" color={themeContext.colors.warning}>
                Refundert
              </Text>
            )}
            <Text align="right">Total</Text>
          </Td>
          <Td align="right">
            <Text align="right" variant="body2">
              {currencyFormat.format(
                order.orderLines.reduce((v, l) => v + l.total, 0)
              )}
            </Text>
            <Text align="right" variant="body2">
              {currencyFormat.format(
                order.shippingLines.reduce((v, l) => v + l.total, 0)
              )}
            </Text>
            <Text align="right" variant="body2">
              {currencyFormat.format(order.totalTax)}
            </Text>
            {refund > 0 && (
              <Text
                align="right"
                variant="body2"
                color={themeContext.colors.warning}
              >
                {currencyFormat.format(-Math.abs(refund))}
              </Text>
            )}
            <Text align="right" variant="body2">
              {currencyFormat.format(orderTotal)}
            </Text>
          </Td>
        </Tr>
      </Tfoot>
    </Table>
  );
};

interface LineRefund {
  amount: number;
  total: number;
  totalTax: number;
}

function getLineRefund(
  type: OrderRefundItem["type"],
  order: Order,
  lineId: string
): LineRefund {
  return (order.refunds || []).reduce(
    (v, r) => {
      const payment = (order.payments || []).find((p) => p._id === r.payment);

      if (payment) {
        for (let i = 0; i < r.items.length; i++) {
          const item = r.items[i];

          if (item.type === type) {
            const paymentItem = payment.items.find((ii) => ii._id === item.ref);

            if (paymentItem && paymentItem.ref === lineId) {
              v.amount += item.amount;
              v.total += item.total;
              v.totalTax += item.totalTax;
            }
          }
        }
      }

      return v;
    },
    { amount: 0, total: 0, totalTax: 0 }
  );
}

export default General;
