export function getPotentiallyPopulatedId(
  id?: string | { _id: string } | null
): string | undefined {
  if (!id) {
    return undefined;
  }

  if (typeof id === "string") {
    return id;
  }

  return id._id;
}
