import objectPath from "object-path";
import { FieldCleaner } from "../hooks/useForm";

interface CleanReffer {
  key: string;
  refPath: string;
}

function cleanRefs(refs: CleanReffer[]): FieldCleaner {
  return function (key, value) {
    const reffer = refs.find((r) => r.key === key);

    if (reffer) {
      const idPath = `${reffer.refPath}._id`;
      const refId = objectPath.get(value, idPath);

      if (typeof refId === "string") {
        objectPath.set(value, reffer.refPath, objectPath.get(value, idPath));
      }
    }

    return value;
  };
}

export default cleanRefs;
