import * as React from "react";
import styled, { ThemeContext } from "styled-components";
import Text from "../../style-guide/Text/Text";

interface Props {
  value: string;
  limit: number;
}

const SEOHelper: React.FunctionComponent<Props> = ({ value, limit }) => {
  const length = value.length;
  const isAboveLimit = React.useMemo(() => length > limit, [value, limit]);
  const themeContext = React.useContext(ThemeContext);

  return (
    <Wrapper>
      <Text variant="body3">
        Du bruker{" "}
        <Text
          variant="body3"
          element="span"
          color={
            isAboveLimit
              ? themeContext.colors.warning
              : themeContext.colors.good
          }
        >
          {length}
        </Text>
        /{limit} tegn av anbefalt tekstlengde.
      </Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: ${(props) => props.theme.spacing.xs} 0;
`;

export default SEOHelper;
