import { useState, useCallback, useEffect } from "react";
import storage from "../util/storage";

const TIPSTER_STORAGE_KEY = "tipster";

export interface TipsterUserSettings {
  [key: string]: boolean;
}

export interface UseTipsterInterface {
  show: boolean;
  toggleShow: (show?: boolean) => void;
}

function useTipster(key: string): UseTipsterInterface {
  const [_show, setShow] = useState(true);

  useEffect(() => {
    const userSettings = storage.getUnserialize<TipsterUserSettings>(
      TIPSTER_STORAGE_KEY
    );

    if (userSettings && key in userSettings) {
      setShow(userSettings[key]);
    }
  }, [key]);

  useEffect(() => {
    const userSettings =
      storage.getUnserialize<TipsterUserSettings>(TIPSTER_STORAGE_KEY) || {};

    userSettings[key] = _show;

    storage.setSerialize(TIPSTER_STORAGE_KEY, userSettings);
  }, [_show]);

  const toggleShow = useCallback(
    (show?: boolean) => {
      setShow(show || !_show);
    },
    [_show]
  );

  return {
    show: _show,
    toggleShow
  };
}

export default useTipster;
