import * as React from 'react';
import { PowersearchContext, PowersearchProvider } from "./PowersearchContext";
import { PowersearchInput } from "./PowersearchInput";
import styled from "styled-components";
import PowersearchResults, { PowersearchInfo } from "./PowersearchResults";
import { BackgroundDrop } from "./powersearch.styles";
import { powerSearchKeyboardShortcut } from "./powersearch.utils";

export function Powersearch() {
  const [active, setActive] = React.useState(false);

  const handleOnActive = React.useCallback(() => setActive(true), []);
  const handleOnClosed = React.useCallback(() => setActive(false), []);

  React.useEffect(() => {
    const fn = powerSearchKeyboardShortcut(active, setActive);
    document.addEventListener("keydown", fn);
    return () => {
      document.removeEventListener("keydown", fn);
    }
  }, [active]);

  return (
    <PowersearchProvider active={active} onClose={handleOnClosed}>
      <Wrapper>
        {active && <StyledBackgroundDrop onClick={handleOnClosed} />}
        <PowersearchInput active={active} onFocus={handleOnActive} />
        <PowersearchContext.Consumer>
          {({ results }) => {
            if (!active) return null;
            if (results.length === 0) return <StyledPowersearchInfo />;
            return <StyledPowersearchResults />;
          }}
        </PowersearchContext.Consumer>
      </Wrapper>
    </PowersearchProvider>
  )
}

const StyledBackgroundDrop = styled(BackgroundDrop)`
  z-index: -1;
`

const StyledPowersearchInfo = styled(PowersearchInfo)`
  position: absolute;
  width: 100%;
`;

const StyledPowersearchResults = styled(PowersearchResults)`
  position: absolute;
  width: 100%;
`;

const Wrapper = styled.div`
  position: relative;
`
