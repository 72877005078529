import React from "react";
import { Switch, Route } from "react-router";
import List from "./List";
import Apps from "./Main";
import Authorize from "./Authorize";

const AppsRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/apper" component={Apps} />
      <Route exact path="/marked" component={List} />
      <Route exact path="/authorize/:app" component={Authorize} />
    </Switch>
  );
};

export default AppsRoutes;
