import { useContext } from "react";
import { Theme } from "@ludens-reklame/rubics-sdk";
import { SiteThemeContext } from "../context/Theme";

function useTheme(): Theme | undefined {
  const theme = useContext(SiteThemeContext).data;

  return theme;
}

export default useTheme;
