import React, { useState } from "react";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import { ProductTemplate } from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import { Button } from "../../../style-guide/Button/Button";
import { createObjectId } from "../../../util/createId";
import {
  InlineEditHead,
  InlineEditColumn
} from "../../../style-guide/InlineEdit/InlineEdit";
import { Dropper, Dragger } from "../../../components/DnD/DnD";
import Attribute from "../Components/Attribute";

interface Props {
  publisher: UsePublisherInterface<ProductTemplate>;
}

const Attributes: React.FC<Props> = ({ publisher }) => {
  const form = publisher.form;

  const [newId, setNewId] = useState<string | null>(null);

  return (
    <Fader>
      <Card>
        <Block>
          <Flex>
            <FlexKid flex={1}>
              <Text element="h2" variant="title">
                Attributter
              </Text>
            </FlexKid>
            {!publisher.isRevision && (
              <FlexKid>
                <Button
                  type="button"
                  outlined
                  onClick={() => {
                    const id = createObjectId();

                    form.setField({
                      path: "attributes",
                      value: [{ _id: id }, ...form.data.attributes]
                    });

                    setNewId(id);
                  }}
                >
                  Legg til attributt
                </Button>
              </FlexKid>
            )}
          </Flex>
        </Block>
        <InlineEditHead>
          <InlineEditColumn width="400px">Attributt</InlineEditColumn>
          <InlineEditColumn width="200px">
            For enkle varianter?
          </InlineEditColumn>
        </InlineEditHead>
        <Dropper
          id="attributes"
          onDragEnd={(r) => {
            if (r.destination) {
              const list = Array.from(form.data.attributes || []);
              const [removed] = list.splice(r.source.index, 1);

              list.splice(r.destination.index, 0, removed);

              form.setField({
                path: "attributes",
                value: list
              });
            }
          }}
        >
          {form.data.attributes.length > 0 ? (
            form.data.attributes.map((a, k) => (
              <Dragger
                key={a._id}
                id={a._id}
                index={k}
                delegateDragHandle
                isDragDisabled={publisher.isRevision}
              >
                <Attribute
                  key={a._id}
                  attribute={a}
                  usedAttributes={form.data.attributes}
                  readOnly={publisher.isRevision}
                  isNew={a._id === newId}
                  onChange={(data) =>
                    form.setField({
                      path: "attributes",
                      value: form.data.attributes.map((aa, kk) => {
                        if (kk === k) {
                          return data;
                        }

                        return aa;
                      })
                    })
                  }
                  onDelete={() => {
                    if (window.confirm("Er du sikker på at du vil slette?")) {
                      form.setField({
                        path: "attributes",
                        value: form.data.attributes.filter((_, kk) => kk !== k)
                      });
                    }
                  }}
                />
              </Dragger>
            ))
          ) : (
            <Block hugTop>
              <Text variant="subheading">Ingen attributter enda</Text>
            </Block>
          )}
        </Dropper>
      </Card>
    </Fader>
  );
};

export default Attributes;
