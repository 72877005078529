import React from "react";
import { AiOutlineBank, AiOutlineFile } from "react-icons/ai";
import useSite from "../../../hooks/useSite";
import Icon from "../../../components/Icon/Icon";
import Crumb from "../../../components/Crumb/Crumb";
import Fader from "../../../style-guide/Fader/Fader";
import Text from "../../../style-guide/Text/Text";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import { CardLink } from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Section from "../../../style-guide/Section/Section";
import Grid from "../../../style-guide/Grid/Grid";

const Cookies: React.FC = () => {
  const site = useSite();

  return (
    <Fader>
      <Block hugTop>
        <Text element="h1" variant="display3">
          <Crumb url="/Innstillinger">Innstillinger</Crumb>
          Informasjonskapsler
        </Text>
      </Block>
      {site.cookieDialogEnabled && (
        <Section hugTop>
          <Grid gap="medium" columns={3}>
            <CardLink to="/innstillinger/informasjonskapsler/innebygde" stretch>
              <Block flex>
                <Flex flex={1}>
                  <FlexKid flex={1}>
                    <Text element="h2" variant="title">
                      Innebygde
                    </Text>
                    <Text variant="body3">
                      Vis innebygde informasjonskapsler
                    </Text>
                  </FlexKid>
                  <FlexKid spaceLeft>
                    <Icon size="large">
                      <AiOutlineFile />
                    </Icon>
                  </FlexKid>
                </Flex>
              </Block>
            </CardLink>

            <CardLink to="/innstillinger/informasjonskapsler/egendefinerte">
              <Block flex>
                <Flex flex={1}>
                  <FlexKid flex={1}>
                    <Text element="h2" variant="title">
                      Egendefinerte
                    </Text>
                    <Text variant="body3">
                      Administrer egendefinerte informasjonskapsler
                    </Text>
                  </FlexKid>
                  <FlexKid spaceLeft>
                    <Icon size="large">
                      <AiOutlineBank />
                    </Icon>
                  </FlexKid>
                </Flex>
              </Block>
            </CardLink>

            <CardLink
              to="/innstillinger/informasjonskapsler/konfigurasjon"
              stretch
            >
              <Block flex>
                <Flex flex={1}>
                  <FlexKid flex={1}>
                    <Text element="h2" variant="title">
                      Konfigurasjon
                    </Text>
                    <Text variant="body3">
                      Konfigurer dialog for informasjonskapsler
                    </Text>
                  </FlexKid>
                  <FlexKid spaceLeft>
                    <Icon size="large">
                      <AiOutlineFile />
                    </Icon>
                  </FlexKid>
                </Flex>
              </Block>
            </CardLink>
          </Grid>
        </Section>
      )}
    </Fader>
  );
};

export default Cookies;
