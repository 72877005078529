import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineGift,
  AiOutlineSearch
} from "react-icons/ai";
import { IoMdGlobe } from "react-icons/io";
import {
  Image as ImageClass,
  Product as ProductClass,
  ProductType
} from "@ludens-reklame/rubics-sdk";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { useSearch } from "../../hooks/useApi";
import useApiUrl from "../../hooks/useApiUrl";
import Block from "../../style-guide/Block/Block";
import Card from "../../style-guide/Card/Card";
import { Element, Elements } from "../../style-guide/Elements/Elements";
import { Table, Tr, Th, Td } from "../../style-guide/Table/Table";
import Text from "../../style-guide/Text/Text";
import { Catalog, CategoryTreeEntry, Product } from "../../types/apiResponses";
import convertImage from "../../util/convertImage";
import Image from "../../style-guide/Image/Image";
import Status from "../../style-guide/Status/Status";
import { CatalogPriceType, EntityStatus } from "../../constants/api";
import localize from "../../util/localize";
import { entityStatus, productType } from "../../constants/localizations";
import { currencyFormat } from "../../util/intl";
import EmptyState from "../../style-guide/EmptyState/EmptyState";
import BusyBoy from "../BusyBoy/BusyBoy";
import TextInput from "../../style-guide/Inputs/TextInput";
import { useDebouncedCallback } from "use-debounce/lib";
import { ApiOpts, RequestData } from "../../util/api";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import useMultiSelect from "../../hooks/useMultiSelect";
import { Dot, Dotter } from "../../style-guide/Dotter/Dotter";
import { ButtonLink } from "../../style-guide/Button/Button";
import useSite from "../../hooks/useSite";
import { CloneParentProductModal } from "../../views/Products/Products";

const TableWrapper = styled.div`
  min-width: 900px;
`;

interface Props {
  renderView?: (products: Product[]) => ReactNode;
  queryParams?: RequestData;
  useWindow?: boolean;
  getScrollParent?: () => HTMLElement | null;
  filter?: (product: Product) => boolean;
}

const ProductPicker: React.FC<Props> = ({
  renderView,
  queryParams,
  useWindow,
  getScrollParent,
  filter
}) => {
  const [usedQuery, setUsedQuery] = useState("");
  const [query, setQuery] = useState("");
  const [itemPath, setItemPath] = useState<CategoryTreeEntry[]>([]);
  const [primaryCategory, setPrimaryCategory] = useState<string | undefined>(
    undefined
  );

  const productsUrl = useApiUrl(["products"]);
  const categoryTreeUrl = useApiUrl(["pages", "tree"]);

  const categoryTreeSearch = useSearch<CategoryTreeEntry>({
    endpoint: categoryTreeUrl,
    fetchOnMount: true,
    queryParams: {
      isCategory: true
    }
  });

  useEffect(() => {
    setItemPath([
      {
        _id: "_top",
        title: "_top",
        parent: null,
        children: categoryTreeSearch.results
      }
    ]);
  }, [categoryTreeSearch.results]);

  const lastEntry = useMemo<CategoryTreeEntry>(
    () => itemPath[itemPath.length - 1],
    [itemPath]
  );

  const productsSearch = useSearch<Product>({
    endpoint: productsUrl,
    onSuccess: () => setUsedQuery(query)
  });

  useEffect(() => {
    if (lastEntry) {
      setPrimaryCategory(lastEntry._id !== "_top" ? lastEntry._id : undefined);
    }
  }, [lastEntry]);

  const queryOpts = useMemo<ApiOpts>(
    () => ({
      endpoint: productsUrl,
      queryParams: {
        ...queryParams,
        category: primaryCategory,
        text: query
      }
    }),
    [queryParams, productsUrl, primaryCategory, query]
  );

  const handleSearch = useCallback(() => {
    productsSearch.search(queryOpts);
  }, [productsSearch.search, queryOpts]);

  const [debouncedSearch] = useDebouncedCallback(handleSearch, 500);

  useEffect(() => {
    if (productsSearch.hasSearched) {
      debouncedSearch();
    }
  }, [query]);

  useEffect(() => {
    productsSearch.search({
      endpoint: productsUrl,
      queryParams: {
        ...queryParams,
        category: primaryCategory,
        text: query
      }
    });
  }, [primaryCategory]);

  const filteredResults = useMemo<Product[]>(() => {
    if (!filter) {
      return productsSearch.results;
    }

    return productsSearch.results.filter(filter);
  }, [productsSearch.results, filter]);

  const hasCategories = categoryTreeSearch.results.length > 0;
  const categoryEntries = lastEntry?.children || [];

  return (
    <Card>
      <BusyBoy busy={productsSearch.loading}>
        {hasCategories && lastEntry && (
          <Block>
            <Text variant="subheading" gutterBottom>
              Kategorier
            </Text>
            <Elements>
              {lastEntry._id !== "_top" && (
                <Element
                  href="#"
                  icon={<AiOutlineArrowLeft />}
                  onClick={(e) => {
                    e.preventDefault();
                    setItemPath(itemPath.slice(0, -1));
                  }}
                >
                  {lastEntry.title}
                </Element>
              )}
              {categoryEntries.map((c) => (
                <Element
                  key={c._id}
                  href="#"
                  rightIcon={<AiOutlineArrowRight />}
                  onClick={(e) => {
                    e.preventDefault();
                    setItemPath([...itemPath, c]);
                  }}
                >
                  {c.title}
                </Element>
              ))}
            </Elements>
          </Block>
        )}
        <Block hugTop={hasCategories && !!lastEntry}>
          <Text variant="subheading" gutterBottom>
            Produkter
          </Text>
          <TextInput
            icon={<AiOutlineSearch />}
            placeholder="Søk etter produkter"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </Block>
        <InfiniteScroll
          pageStart={2}
          initialLoad={false}
          loadMore={() => {
            productsSearch.search({
              ...queryOpts,
              paginate: true
            });
          }}
          hasMore={productsSearch.hasMore}
          useWindow={useWindow}
          getScrollParent={getScrollParent}
        >
          {productsSearch.hasSearched && filteredResults.length > 0 && (
            <>
              {typeof renderView === "function" ? (
                renderView(filteredResults)
              ) : (
                <ProductTable products={filteredResults} linkable />
              )}
            </>
          )}
        </InfiniteScroll>
        {productsSearch.hasSearched &&
          filteredResults.length === 0 &&
          !usedQuery && (
            <EmptyState
              title={
                lastEntry.title === "_top" && useWindow
                  ? "Opprett ditt første produkt"
                  : "Ingen produkter funnet"
              }
              text={
                lastEntry.title === "_top" ? (
                  <>
                    Dersom du ønsker å selge noe i nettbutikken din lønner det
                    seg å opprette noen produkter.
                  </>
                ) : (
                  <>
                    Vi fant dessverre ingen produkter med kategori{" "}
                    <em>{lastEntry.title}</em>.
                  </>
                )
              }
              icon={<AiOutlineGift />}
              action={
                lastEntry.title === "_top" && useWindow ? (
                  <ButtonLink to="produkter/opprett">
                    Opprett produkt
                  </ButtonLink>
                ) : undefined
              }
            />
          )}
        {productsSearch.hasSearched &&
          filteredResults.length === 0 &&
          usedQuery && (
            <EmptyState
              title={
                <>
                  Ingen treff på "<em>{usedQuery}</em>"
                </>
              }
            />
          )}
      </BusyBoy>
    </Card>
  );
};

interface ProductRowProps {
  product: Product;
  linkable?: boolean;
  selectable?: boolean;
  onChange?: (selected: boolean) => any;
  selectedProducts?: Product[];
  multipleSelect?: boolean;
  displayCatalogs?: boolean;
  renderActions?: (product: Product) => ReactNode;
}

const ProductRow: React.FC<ProductRowProps> = ({
  product,
  linkable,
  onChange,
  selectable,
  selectedProducts,
  multipleSelect,
  displayCatalogs,
  renderActions
}) => {
  const site = useSite();
  const { spawnModal } = useModal();
  const image = useMemo(
    () =>
      ProductClass.getPrimaryImage({
        images: product.images.map(convertImage)
      }),
    [product.images]
  );

  const imageUrl = useMemo(
    () => ImageClass.createUrl({ image, transforms: "w_82,h_82,c_pad" }),
    [image]
  );

  const selected = useMemo<boolean>(() => {
    if (selectable && Array.isArray(selectedProducts)) {
      return selectedProducts.some((p) => p._id === product._id);
    }

    return false;
  }, [product, selectable, selectedProducts]);

  const types = useMemo<string[]>(() => {
    if (product.redirectEnabled && typeof product.redirectUrl === "string") {
      return [`Omdirigires til ${product.redirectUrl}`];
    }

    const types: string[] = [
      localize(productType, product.type || ProductType.Default)
    ];

    if (product.bundle) {
      types.push("Pakke");
    }

    if (product.notForIndependentSale) {
      types.push("Kan ikke kjøpes selvstendig");
    }

    if (product.hideInStore) {
      types.push("Skjult i nettbutikken");
    }

    return types;
  }, [product]);

  const isInherited = useMemo<boolean>(() => {
    return site._id ? product.site !== site._id : false;
  }, [product.site, site._id]);

  const price = useMemo<number>(() => {
    if (isInherited && site.departmentProductCatalog) {
      const catalogId =
        typeof site.departmentProductCatalog === "string"
          ? site.departmentProductCatalog
          : site.departmentProductCatalog._id;

      const catalog = product.catalogEntries.find((e) => {
        const id = typeof e.catalog === "string" ? e.catalog : e.catalog._id;
        return id === catalogId;
      });

      if (catalog) {
        if (catalog.priceType === CatalogPriceType.ByAppointment) {
          return 0;
        } else if (typeof catalog.price === "number") {
          if (catalog.priceType === CatalogPriceType.Fixed) {
            return product.price.value + catalog.price;
          } else if (catalog.priceType === CatalogPriceType.NewPrice) {
            return catalog.price;
          }

          return product.price.value * (1 + catalog.price / 100);
        }
      }
    }

    return product.price.value;
  }, [product, isInherited, site.departmentProductCatalog]);

  return (
    <Tr>
      <Td verticalAlign="middle" tight>
        {selectable ? (
          <Flex>
            <FlexKid>
              <input
                id={`product_${product._id}`}
                name="product-picker-products"
                type={multipleSelect ? "checkbox" : "radio"}
                onChange={(e) => {
                  if (onChange) {
                    if (multipleSelect) {
                      onChange(e.target.checked);
                    } else {
                      onChange(true);
                    }
                  }
                }}
                checked={selected}
              />
            </FlexKid>
            <FlexKid spaceLeft>
              <Image width={41} height={41} src={imageUrl} alt={image.alt} />
            </FlexKid>
          </Flex>
        ) : (
          <Image width={41} height={41} src={imageUrl} alt={image.alt} />
        )}
      </Td>
      <Td tight>
        <Text variant="body2">
          {linkable ? (
            <Link
              to={`/butikk/produkter/${product._id}`}
              onClick={(e) => {
                if (isInherited) {
                  e.preventDefault();
                  spawnModal(<CloneParentProductModal product={product} />);
                }
              }}
            >
              {product.name}
            </Link>
          ) : selectable ? (
            <label htmlFor={`product_${product._id}`}>{product.name}</label>
          ) : (
            product.name
          )}
          {isInherited && (
            <>
              {" "}
              <IoMdGlobe />
            </>
          )}
        </Text>
        <Text variant="body3">
          <Dotter>
            <Dot>{product.sku || "Mangler SKU"}</Dot>
            {product.modified && (
              <Dot>
                <Text element="span" variant="subtitle">
                  Upubliserte endringer
                </Text>
              </Dot>
            )}
          </Dotter>
        </Text>
      </Td>
      {displayCatalogs && (
        <Td verticalAlign="middle" tight>
          <Text variant="body3">
            {product.inStandardCatalog !== false && <span>Standard</span>}
            {(product.catalogEntries || [])
              .filter((e) => typeof e.catalog !== "string")
              .map((e) => {
                const catalog = e.catalog as Catalog;

                return (
                  <span key={e._id}>
                    ,{" "}
                    {linkable ? (
                      <Link
                        to={`/butikk/produktkataloger/${catalog._id}${
                          isInherited ? "/produkter" : ""
                        }`}
                      >
                        {catalog.name}
                      </Link>
                    ) : (
                      catalog.name
                    )}
                  </span>
                );
              })}
          </Text>
        </Td>
      )}
      <Td verticalAlign="middle" tight>
        <Text variant="body3">
          <Dotter>
            {types.map((t) => (
              <Dot key={t}>{t}</Dot>
            ))}
          </Dotter>
        </Text>
      </Td>
      <Td align="right" verticalAlign="middle" tight>
        {product.bundle ? (
          <Text align="right" variant="body3">
            Automatisk
          </Text>
        ) : (
          <>
            <Text align="right" variant="body3">
              {currencyFormat.format(price)}
            </Text>
            {!isInherited && product.price.compareAtValue && (
              <Text variant="body3" strike align="right">
                {currencyFormat.format(product.price.compareAtValue)}
              </Text>
            )}
          </>
        )}
      </Td>
      <Td align="center" verticalAlign="middle" tight>
        <Status
          variant={
            product.status === EntityStatus.Inactive ? "neutral" : undefined
          }
        >
          {localize(entityStatus, product.status || EntityStatus.Inactive)}
        </Status>
      </Td>
      {typeof renderActions === "function" && (
        <Td align="right" verticalAlign="middle" tight>
          {renderActions(product)}
        </Td>
      )}
    </Tr>
  );
};

interface ProductTableProps {
  products: Product[];
  linkable?: boolean;
  onSelect?: (products: Product[]) => any;
  multipleSelect?: boolean;
  selectedProducts?: Product[];
  renderActions?: (product: Product) => ReactNode;
}

export const ProductTable: React.FC<ProductTableProps> = ({
  products,
  linkable,
  onSelect,
  multipleSelect,
  selectedProducts,
  renderActions
}) => {
  const site = useSite();
  const multiSelect = useMultiSelect<Product>({
    elements: products,
    onSelect,
    multipleSelect,
    initialValue: selectedProducts
  });

  const selectable = useMemo<boolean>(() => {
    return typeof onSelect === "function";
  }, [onSelect]);

  return (
    <TableWrapper>
      <Table>
        <thead>
          <Tr>
            <Th style={{ width: "1px" }}>
              {multipleSelect && (
                <input
                  type="checkbox"
                  onChange={(e) => multiSelect.selectAll(e.target.checked)}
                />
              )}
            </Th>
            <Th>Produkt</Th>
            <Th>Type</Th>
            <Th align="right">Pris</Th>
            <Th style={{ width: "120px" }} align="center">
              Status
            </Th>
            {typeof renderActions === "function" && (
              <Th align="right">Handlinger</Th>
            )}
          </Tr>
        </thead>
        <tbody>
          {products.map((p) => (
            <ProductRow
              key={p._id}
              product={p}
              linkable={linkable}
              selectable={selectable}
              onChange={(selected) => multiSelect.select(p, selected)}
              selectedProducts={multiSelect.selectedElements}
              multipleSelect={multipleSelect}
              renderActions={renderActions}
            />
          ))}
        </tbody>
      </Table>
    </TableWrapper>
  );
};

export default ProductPicker;
