import React from "react";
import { Switch, Route } from "react-router";
import Subscriptions from "./Subscriptions";
import Subscription from "./Subscription";

const SubscriptionsRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/butikk/abonnementer" component={Subscriptions} />
      <Route
        path="/butikk/abonnementer/:subscription"
        render={(props) => (
          <Subscription key={props.match.params.subscription} {...props} />
        )}
      />
    </Switch>
  );
};

export default SubscriptionsRoutes;
