import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext
} from "react";
import styled from "styled-components";
import { AiOutlineClose, AiOutlineMeh } from "react-icons/ai";
import { MessageType } from "@ludens-reklame/rubics-app-bridge/dist/util/constants";
import { useApi } from "../../hooks/useApi";
import { App, AppInitializer, AppSetting } from "../../types/apiResponses";
import useApiUrl from "../../hooks/useApiUrl";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import fallbackImage from "../../util/images/empty_preview.png";
import { Crumb } from "../../context/Breadcrumbs";
import useNotifications from "../../hooks/useNotifications";
import { Button, ButtonList } from "../../style-guide/Button/Button";
import Fader from "../../style-guide/Fader/Fader";
import { ThemeSwitcherContext } from "../../context/ThemeSwitcher";
import useThemeSwitcher from "../../hooks/useThemeSwitcher";
import Section from "../../style-guide/Section/Section";
import Card from "../../style-guide/Card/Card";
import Block from "../../style-guide/Block/Block";
import Text from "../../style-guide/Text/Text";
import { MainBlock } from "../../style-guide/Layout/Layout";
import Ruler from "../../style-guide/Ruler/Ruler";
import createInputField from "../../util/createInputField";
import useForm from "../../hooks/useForm";
import BusyBoy from "../../components/BusyBoy/BusyBoy";
import Form from "../../style-guide/Inputs/Form";
import EmptyState from "../../style-guide/EmptyState/EmptyState";

interface WrapperProps {
  hasAppSettings?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  height: 100vh;

  > *:nth-child(${(props) => (props.hasAppSettings ? 3 : 2)}) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

const AppHeader = styled.div`
  background-color: ${(props) => props.theme.colors.backgroundInBetween};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderDim};
  padding: ${(props) =>
    `${props.theme.spacing.small} ${props.theme.spacing.medium}`};
`;

const IFrame = styled.iframe`
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  border-radius: 8px;
  flex: 1;
`;

const Logo = styled.img`
  display: block;
  height: 24px;
`;

interface MessagePayload {
  type: MessageType;
  data: any;
}

interface Props
  extends RouteComponentProps<{
    app: string;
  }> {}

const AppRenderer: React.FC<Props> = ({ match }) => {
  const app = match.params.app;

  const history = useHistory();
  const { spawn } = useNotifications();
  const { setTheme } = useContext(ThemeSwitcherContext);
  const url = useApiUrl(["apps", app, "initialize"]);
  const [appCrumbs, setAppCrumbs] = useState<Crumb[]>([]);

  const currentPath = useMemo(() => {
    const split = history.location.pathname.split(`/apper/${app}`);

    if (split.length > 1) {
      return split[1] || "/";
    }

    return "/";
  }, [history.location.pathname]);

  const currentPathRef = useRef<string>("/");

  const appRequest = useApi<AppInitializer | null>({
    endpoint: url,
    fetchOnMount: true,
    initialData: null,
    queryParams: {
      path: currentPath
    }
  });

  currentPathRef.current = currentPath;

  const navigate = useCallback(
    (path: string) => {
      if (appRequest.resource) {
        const frame = document.getElementById("appFrame") as HTMLIFrameElement;

        if (frame) {
          const payload: MessagePayload = {
            type: MessageType.PopState,
            data: path
          };

          frame.contentWindow!.postMessage(
            payload,
            appRequest.resource.app.url
          );
        }
      }
    },
    [appRequest.resource]
  );

  const communicationHandler = useCallback(
    (e: MessageEvent) => {
      if (e.origin === appRequest.resource?.app.url) {
        const payload: MessagePayload = e.data;

        switch (payload.type) {
          case MessageType.SetBreadcrumbs:
            //@ts-ignore
            const path =
              payload.data.length > 0
                ? payload.data[payload.data.length - 1].path
                : "/";

            if (currentPathRef.current !== path) {
              history.replace(`/apper/${app}${path}`);
            }

            setAppCrumbs(
              //@ts-ignore
              payload.data.map((c) => ({
                ...c,
                onClick: () => navigate(c.path)
              }))
            );

            break;
          case MessageType.SpawnNotification:
            spawn({
              title: payload.data.title,
              subtitle: payload.data.subtitle
            });

            break;
          case MessageType.ToggleTheme:
            setTheme(payload.data);

            break;
        }
      } else {
        console.error("Bad origin");
      }
    },
    [appRequest.resource, history.replace]
  );

  useEffect(() => {
    if (appRequest.resource) {
      window.addEventListener("message", communicationHandler, false);
    }
  }, [appRequest.resource]);

  useThemeSwitcher();

  const logoUrl = useMemo(() => {
    if (appRequest.resource && appRequest.resource.app.logo) {
      return appRequest.resource.app.logo;
    }

    return fallbackImage;
  }, [appRequest.resource]);

  const appSettings = useMemo<AppSetting[]>(() => {
    if (
      appRequest.resource &&
      Array.isArray(appRequest.resource.app.settings)
    ) {
      return appRequest.resource.app.settings;
    }

    return [];
  }, [appRequest.resource]);

  const hasAppDashboard = useMemo<boolean>(() => {
    if (
      appRequest.hasFetched &&
      appSettings.length < 1 &&
      appRequest.resource &&
      !appRequest.resource.url
    ) {
      return false;
    }

    return true;
  }, [appSettings, appRequest]);

  return (
    <Wrapper hasAppSettings={appSettings.length > 0}>
      <Fader variant="normal">
        <AppHeader>
          <Flex>
            <FlexKid>
              <Logo
                src={logoUrl}
                alt={
                  appRequest.resource ? appRequest.resource.app.name : "Laster…"
                }
              />
            </FlexKid>
            <FlexKid flex={1}>
              <Breadcrumbs
                crumbs={[
                  {
                    path: "/apper",
                    slug: "apper",
                    label: "Apper"
                  },
                  {
                    path: `/apper/${app}`,
                    slug: app,
                    label: appRequest.resource
                      ? appRequest.resource.app.name
                      : app,
                    onClick: () => navigate("/")
                  },
                  ...appCrumbs.map((c) => ({
                    ...c,
                    path: `/apper/${app}${c.path}`
                  }))
                ]}
              />
            </FlexKid>
            <FlexKid spaceLeft>
              <Button
                onClick={() => {
                  history.goBack();
                }}
                outlined
              >
                <AiOutlineClose /> Lukk app
              </Button>
            </FlexKid>
          </Flex>
        </AppHeader>
      </Fader>
      {!hasAppDashboard && (
        <Fader>
          <EmptyState
            title="Her var det tomt, gitt"
            text="Denne appen mangler dashbord for å endre instillinger. Det er helt OK, da slipper du å gjøre noe som helst!"
            icon={<AiOutlineMeh />}
          />
        </Fader>
      )}
      {appSettings.length > 0 && (
        <Section hugBottom>
          <MainBlock smaller>
            <Fader>
              <AppSettings app={appRequest.resource!.app} />
            </Fader>
          </MainBlock>
          {appRequest.resource && appRequest.resource.url && (
            <Ruler gutterTop hugBottom />
          )}
        </Section>
      )}
      {appRequest.resource && appRequest.resource.url && (
        <Fader>
          <IFrame id="appFrame" src={appRequest.resource.url} />
        </Fader>
      )}
    </Wrapper>
  );
};

interface AppSettingsProps {
  app: App;
}

const AppSettings: React.FC<AppSettingsProps> = ({ app }) => {
  const url = useApiUrl(["apps", app.identifier]);
  const notifications = useNotifications();

  const {
    hasMadeChanges,
    loadingPrefill,
    submit,
    submitting,
    getValue,
    setField,
    refresh
  } = useForm<any>(
    {},
    {
      endpoint: url,
      method: "PATCH",
      prefillEndpoint: url,
      postAll: true,
      onSuccess: () => {
        notifications.spawn({
          title: "Innstillinger lagret"
        });
      }
    }
  );

  const renderField = useCallback(
    (field: AppSetting) => {
      switch (field.type) {
        case "boolean":
          return createInputField({
            key: field.name,
            type: "boolean",
            label: field.label,
            value: getValue(field.name),
            onChange: (value) => setField({ path: field.name, value })
          });
        case "textarea":
          return createInputField({
            key: field.name,
            type: "textarea",
            label: field.label,
            placeholder: field.helpText,
            value: getValue(field.name),
            onChange: (value) => setField({ path: field.name, value })
          });
        default:
          return createInputField({
            key: field.name,
            type: field.sensitive ? "password" : "text",
            label: field.label,
            placeholder: field.helpText,
            value: getValue(field.name),
            onChange: (value) => setField({ path: field.name, value })
          });
      }
    },
    [setField, getValue]
  );

  return (
    <BusyBoy busy={loadingPrefill || submitting}>
      <Form onSubmit={submit}>
        <Card outlined>
          <Block hugBottom>
            <Section hugTop>
              <Text element="h2" variant="title">
                Innstillinger
              </Text>
            </Section>
            <Section>{app.settings.map(renderField)}</Section>
          </Block>
          <Block hugTop>
            <ButtonList align="right">
              <Button
                type="button"
                outlined
                disabled={!hasMadeChanges}
                onClick={() => {
                  if (window.confirm("Er du sikker på at du vil avbryte?")) {
                    refresh();
                  }
                }}
              >
                Avbryt
              </Button>
              <Button type="submit" disabled={!hasMadeChanges}>
                {submitting ? "Lagrer…" : "Lagre"}
              </Button>
            </ButtonList>
          </Block>
        </Card>
      </Form>
    </BusyBoy>
  );
};

export default AppRenderer;
