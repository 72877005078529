import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { AiOutlineDelete } from "react-icons/ai";
import { useApi } from "../../hooks/useApi";
import { Order, OrderNote } from "../../types/apiResponses";
import useApiUrl from "../../hooks/useApiUrl";
import Card from "../../style-guide/Card/Card";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import { Button, ButtonList } from "../../style-guide/Button/Button";
import Text from "../../style-guide/Text/Text";
import createInputField from "../../util/createInputField";
import Form from "../../style-guide/Inputs/Form";
import Section from "../../style-guide/Section/Section";
import useForm from "../../hooks/useForm";
import useNotifications from "../../hooks/useNotifications";
import BusyBoy from "../BusyBoy/BusyBoy";
import { Radio } from "../../style-guide/Inputs/Radio";

const NoteList = styled.div`
  > *:not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing.small};
  }
`;

const NoteText = styled.div`
  padding: ${(props) => props.theme.spacing.small};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderDim};
`;

const NoteActions = styled.div`
  font-size: ${(props) => props.theme.sizes.negOne};
  padding: ${(props) =>
    `${props.theme.spacing.xs} ${props.theme.spacing.xs} ${props.theme.spacing.xs} ${props.theme.spacing.small}`};
`;

interface Props {
  order: Order;
  readOnly?: boolean;
  onCreate?: (note: OrderNote) => any;
}

const Notes: React.FC<Props> = ({ order, readOnly, onCreate }) => {
  const [notes, setNotes] = useState<OrderNote[]>(order.notes || []);
  const orderNoteUrl = useApiUrl(["orders", order._id, "notes"]);
  const notifications = useNotifications();

  const form = useForm<OrderNote>(
    {
      internal: true
    },
    {
      endpoint: orderNoteUrl,
      method: "POST",
      onSuccess: (note) => {
        notifications.spawn({
          title: "Notat lagret!"
        });

        form.setData({
          data: {
            internal: true
          }
        });

        setNotes([...notes, note]);

        if (typeof onCreate === "function" && note) {
          onCreate(note);
        }
      }
    }
  );

  const destroyOpts = {
    endpoint: orderNoteUrl,
    method: "DELETE",
    initialData: null
  };

  const destroy = useApi<null>(destroyOpts);

  useEffect(() => {
    setNotes(order.notes);
  }, [order.notes]);

  return (
    <>
      <BusyBoy busy={destroy.loading}>
        <NoteList>
          {notes.map((n) => (
            <Card key={n._id} outlined>
              <NoteText>{n.text}</NoteText>
              <NoteActions>
                <Flex>
                  <FlexKid flex={1}>
                    <Text variant="body3">
                      For {moment(n.timestamp).fromNow()} av{" "}
                      {n.author && typeof n.author !== "string"
                        ? n.author.name
                          ? n.author.name
                          : n.author.email
                        : "ukjent"}
                    </Text>
                  </FlexKid>
                  <FlexKid spaceLeft>
                    <Button
                      type="button"
                      aria-label="Slett notat"
                      disabled={readOnly}
                      circular
                      outlined
                      smaller
                      onClick={() => {
                        if (
                          window.confirm("Er du sikker på at du vil slette?")
                        ) {
                          destroy.fetch({
                            ...destroyOpts,
                            endpoint: `${orderNoteUrl}/${n._id}`,
                            onSuccess: () => {
                              setNotes(notes.filter((nn) => nn._id !== n._id));

                              notifications.spawn({
                                title: "Notat slettet"
                              });
                            }
                          });
                        }
                      }}
                    >
                      <AiOutlineDelete />
                    </Button>
                  </FlexKid>
                </Flex>
              </NoteActions>
            </Card>
          ))}
        </NoteList>
      </BusyBoy>
      <Section hugBottom>
        <BusyBoy busy={form.submitting}>
          <Form onSubmit={form.submit}>
            {createInputField({
              key: "note",
              type: "textarea",
              label: "Skriv et nytt notat",
              readOnly,
              required: true,
              value: form.getValue("note"),
              onChange: (value) =>
                form.setField({
                  path: "note",
                  value
                })
            })}
            <Section tight hugBottom>
              <ButtonList align="right">
                <Radio
                  label="Lagre internt"
                  standalone
                  value="true"
                  disabled={readOnly}
                  checked={form.getValue("internal")}
                  onChange={(e) =>
                    form.setField({
                      path: "internal",
                      value: e.target.value === "true"
                    })
                  }
                />
                <Radio
                  label="Send til kunde"
                  standalone
                  value="false"
                  disabled={readOnly}
                  checked={!form.getValue("internal")}
                  onChange={(e) =>
                    form.setField({
                      path: "internal",
                      value: e.target.value === "true"
                    })
                  }
                />
                <Button outlined disabled={readOnly || !form.getValue("note")}>
                  {form.submitting ? "Lagrer…" : "Lagre notat"}
                </Button>
              </ButtonList>
            </Section>
          </Form>
        </BusyBoy>
      </Section>
    </>
  );
};

export default Notes;
