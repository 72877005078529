import React from "react";
import styled, { css } from "styled-components";
import { FADE_IN } from "../Fader/Fader";

const StickyBarWrapper = styled.div<Props>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${(props) =>
    `${props.theme.spacing.small} ${props.theme.spacing.medium}`};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  animation: ${FADE_IN} 0.24s ease-out both;
  pointer-events: none;
  z-index: 2;

  > * {
    pointer-events: auto;
  }

  > *:not(:last-child) {
    margin-right: ${(props) => props.theme.spacing.xs};
  }

  ${(props) =>
    props.variant !== "normal" &&
    css`
      pointer-events: auto;
    `}

  ${(props) =>
    props.variant === "info" &&
    css`
      background-color: ${props.theme.colors.neutral};
    `}

  ${(props) =>
    props.variant === "experiment" &&
    css`
      background-color: ${props.theme.colors.good};
    `}

  ${(props) =>
    props.image &&
    css`
      padding-left: calc(
        70px + ${props.theme.spacing.small} + ${props.theme.spacing.small}
      );
    `}
`;

const StickyImage = styled.img`
  display: block;
  position: absolute;
  bottom: 0;
  left: ${(props) => props.theme.spacing.small};
  width: 70px;
`;

const StickyBarText = styled.div`
  margin-right: auto !important;
  color: ${(props) => props.theme.colors.textOnColor};
`;

interface Props {
  variant?: "normal" | "info" | "alert" | "experiment";
  image?: string;
  imageAlt?: string;
}

const StickyBar: React.FC<Props> = ({ variant, image, imageAlt, children }) => {
  return (
    <StickyBarWrapper variant={variant} image={image}>
      {image && <StickyImage src={image} alt={imageAlt} />}
      {children}
    </StickyBarWrapper>
  );
};

export default StickyBar;
export { StickyBarText };
