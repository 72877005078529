import React from "react";
import { Link, useHistory } from "react-router-dom";
import { MdOutlineLabel } from "react-icons/md";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import Text from "../../style-guide/Text/Text";
import Tabler from "../../components/Tabler/Tabler";
import useForm from "../../hooks/useForm";
import Form from "../../style-guide/Inputs/Form";
import { Modal, ModalBody, ModalActions } from "../../style-guide/Modal/Modal";
import BusyBoy from "../../components/BusyBoy/BusyBoy";
import Block from "../../style-guide/Block/Block";
import createInputField from "../../util/createInputField";
import { Button, ButtonList } from "../../style-guide/Button/Button";
import { ProductLabel } from "../../components/ProductLabel/ProductLabel";
import { Label } from "../../types/apiResponses";

export const Labels: React.FC = () => {
  const { spawnModal } = useModal();

  return (
    <Tabler
      title="Etiketter"
      tight
      crumb={{
        label: "Butikk",
        path: "/butikk"
      }}
      actions={
        <Button onClick={() => spawnModal(<CreateLabelModal />)}>
          Opprett etikett
        </Button>
      }
      emptyState={{
        title: "Opprett din første etikett",
        icon: <MdOutlineLabel />,
        text: (
          <>
            <Text>
              Etiketter lar deg merke produkter med fargerike merkelapper.
            </Text>
          </>
        ),
        action: (
          <Button onClick={() => spawnModal(<CreateLabelModal />)}>
            Opprett etikett
          </Button>
        )
      }}
      url={["labels"]}
      columns={[
        {
          Header: "Etikett",
          accessor: "title",
          verticalAlign: "middle"
        },
        {
          Header: "Forhåndsvisning",
          accessor: "preview",
          align: "center"
        }
      ]}
      createRow={(label: Label) => ({
        title: <TitleRow label={label} />,
        preview: <ProductLabel label={label} smaller />
      })}
    />
  );
};

interface TitleRowProps {
  label: Label;
}

const TitleRow: React.FC<TitleRowProps> = ({ label }) => {
  return (
    <Text variant="body2">
      <Link to={`/butikk/etiketter/${label._id}`}>{label.name}</Link>
    </Text>
  );
};

const CreateLabelModal: React.FC = () => {
  const history = useHistory();
  const { despawnModal } = useModal();
  const form = useForm<Label>(
    {},
    {
      endpoint: "labels",
      method: "POST",
      onSuccess: (label: Label) => {
        if (label) {
          despawnModal();
          history.push(`/butikk/etiketter/${label._id}`);
        }
      }
    }
  );

  return (
    <Form onSubmit={form.submit}>
      <Modal maxWidth="32rem">
        <BusyBoy busy={form.submitting}>
          <ModalBody>
            <Block>
              <Text variant="title" gutterBottom>
                Opprett etikett
              </Text>
              {createInputField({
                key: "name",
                label: "Navn",
                value: form.getValue("name"),
                required: true,
                type: "text",
                onChange: (value) =>
                  form.setField({
                    path: "name",
                    value
                  })
              })}
            </Block>
          </ModalBody>
          <ModalActions>
            <ButtonList align="right">
              <Button
                type="button"
                outlined
                onClick={() => {
                  despawnModal();
                }}
              >
                Avbryt
              </Button>
              <Button type="submit" disabled={form.submitting}>
                {form.submitting ? "Oppretter…" : "Opprett"}
              </Button>
            </ButtonList>
          </ModalActions>
        </BusyBoy>
      </Modal>
    </Form>
  );
};
