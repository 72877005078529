import React, { useContext } from "react";
import { Invite as IInvite, Site } from "../../../types/apiResponses";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import Text from "../../../style-guide/Text/Text";
import localize from "../../../util/localize";
import { Table, Tr, Th, Td } from "../../../style-guide/Table/Table";
import Card from "../../../style-guide/Card/Card";
import useForm from "../../../hooks/useForm";
import useNotifications from "../../../hooks/useNotifications";
import { Button, ButtonList } from "../../../style-guide/Button/Button";
import { SiteContext } from "../../../context/Site";
import { memberPermissions } from "../../../constants/localizations";
import {
  ModalBody,
  Modal,
  ModalActions
} from "../../../style-guide/Modal/Modal";
import Block from "../../../style-guide/Block/Block";
import Form from "../../../style-guide/Inputs/Form";
import createInputField from "../../../util/createInputField";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import { useApi } from "../../../hooks/useApi";
import EmptyState from "../../../style-guide/EmptyState/EmptyState";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";

interface InviteProps {
  onInvite: () => any;
}

const Invite: React.FC<InviteProps> = ({ onInvite }) => {
  const { despawnModal } = useModal();
  const notifications = useNotifications();
  const form = useForm<IInvite>(
    {
      _id: "",
      email: "",
      permissions: ""
    },
    {
      endpoint: "invites",
      method: "POST",
      queryParams: {
        dashboard: 1
      },
      onSuccess: () => {
        despawnModal();
        onInvite();
        notifications.spawn({
          title: "Invitasjon sendt"
        });
      }
    }
  );

  return (
    <Form onSubmit={form.submit}>
      <Modal maxWidth="38rem">
        <BusyBoy busy={form.submitting}>
          <ModalBody>
            <Block>
              <Text variant="title" gutterBottom>
                Inviter bruker
              </Text>
              {createInputField({
                key: "email",
                label: "E-postadresse",
                value: form.data.email || "",
                required: true,
                type: "email",
                onChange: (value) =>
                  form.setField({
                    path: "email",
                    value
                  })
              })}
              {createInputField({
                key: "permissions",
                label: "Tilgangsnivå",
                value: form.data.permissions || "",
                required: true,
                type: "select",
                options: Object.keys(memberPermissions).map((k) => ({
                  label: localize(memberPermissions, k),
                  value: k
                })),
                onChange: (value) =>
                  form.setField({
                    path: "permissions",
                    value
                  })
              })}
            </Block>
          </ModalBody>
          <ModalActions>
            <ButtonList align="right">
              <Button
                type="button"
                outlined
                onClick={() => {
                  despawnModal();
                }}
              >
                Avbryt
              </Button>
              <Button type="submit">
                {form.submitting ? "Sender…" : "Send invitasjon"}
              </Button>
            </ButtonList>
          </ModalActions>
        </BusyBoy>
      </Modal>
    </Form>
  );
};

const Invites: React.FC = () => {
  const siteContext = useContext(SiteContext);
  const notifications = useNotifications();
  const modal = useModal();

  const updateSiteRequest = useApi<Site | null>({
    endpoint: "site",
    initialData: null,
    onSuccess: (site: Site | null) => {
      if (site) {
        siteContext.setData(site);
      }
    }
  });

  const resendInviteRequest = useApi<any>({
    endpoint: "TEMP",
    initialData: null,
    onSuccess: () => {
      notifications.spawn({
        title: "Invitasjon sendt på nytt"
      });
    }
  });

  return (
    <>
      <Card>
        <Block>
          <Flex>
            <FlexKid flex={1}>
              <Text element="h1" variant="title">
                Invitasjoner
              </Text>
            </FlexKid>
            <FlexKid spaceLeft>
              <Button
                onClick={() =>
                  modal.spawnModal(
                    <Invite onInvite={updateSiteRequest.fetch} />
                  )
                }
              >
                Inviter bruker
              </Button>
            </FlexKid>
          </Flex>
        </Block>
        {siteContext.data.invites && siteContext.data.invites.length > 0 ? (
          <Table>
            <thead>
              <Tr>
                <Th>E-postadresse</Th>
                <Th>Tilgangsnivå</Th>
                <Th align="right">Handlinger</Th>
              </Tr>
            </thead>
            <tbody>
              {siteContext.data.invites.map((invitee) => (
                <Tr>
                  <Td verticalAlign="middle">{invitee.email}</Td>
                  <Td verticalAlign="middle">
                    <Text variant="body3">
                      {localize(memberPermissions, invitee.permissions)}
                    </Text>
                  </Td>
                  <Td verticalAlign="middle" align="right">
                    <Button
                      outlined
                      onClick={() => {
                        if (
                          window.confirm(
                            "Er du sikker på at du vil sende invitasjonen på nytt?"
                          )
                        ) {
                          notifications.spawn({
                            title: "Sender invitasjon..."
                          });

                          resendInviteRequest.fetch({
                            endpoint: `invites/${invitee._id}/resend`,
                            method: "POST",
                            queryParams: {
                              dashboard: 1
                            },
                            body: {
                              email: invitee.email
                            }
                          });
                        }
                      }}
                    >
                      Send invitasjon på nytt
                    </Button>
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyState
            title="Ingen invitasjoner!"
            text="Inviter kolleger og kompiser til å hjelpe deg med nettsiden. Det går raskere da!"
            action={
              <Button
                onClick={() =>
                  modal.spawnModal(
                    <Invite onInvite={updateSiteRequest.fetch} />
                  )
                }
              >
                Inviter bruker
              </Button>
            }
          />
        )}
      </Card>
    </>
  );
};

export default Invites;
