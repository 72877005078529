import React, { useEffect } from "react";
import { RouteComponentProps, Prompt } from "react-router-dom";
import useBreadcrumbs from "../../../hooks/useBreadcrumbs";
import usePublisher from "../../../hooks/usePublisher";
import { ShippingClass as IShippingClass } from "../../../types/apiResponses";
import Form from "../../../style-guide/Inputs/Form";
import { LEAVE_PAGE_MESSAGE } from "../../../constants/general";
import Fader from "../../../style-guide/Fader/Fader";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import Crumb from "../../../components/Crumb/Crumb";
import PublisherHelpers from "../../../util/PublisherHelpers";
import Section from "../../../style-guide/Section/Section";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import ActionBar from "../../../components/ActionBar/ActionBar";
import Card from "../../../style-guide/Card/Card";
import createPublisherInputField from "../../../util/createPublisherInputField";
import useSiteRefresh from "../../../hooks/useSiteRefresh";

interface Props
  extends RouteComponentProps<{
    shippingClass: string;
  }> {}

const ShippingClass: React.FC<Props> = ({ match }) => {
  const shippingClassId = match.params.shippingClass;

  const siteRefresh = useSiteRefresh();
  const breadcrumbs = useBreadcrumbs();

  const publisher = usePublisher<IShippingClass>({
    id: shippingClassId,
    baseUrl: ["shipping", "classes"],
    baseDashboardUrl: "/innstillinger/frakt/klasser",
    initialData: {},
    onSubmit: () => {
      siteRefresh.refresh();
    },
    pathsToCopy: [
      {
        path: "name",
        label: "Navn"
      },
      {
        path: "description",
        label: "Beskrivelse"
      }
    ]
  });

  useEffect(() => {
    breadcrumbs.swapEntry(shippingClassId, publisher.form.data.name);
  }, [breadcrumbs, publisher.form.data, shippingClassId]);

  return (
    <Form onSubmit={publisher.form.submit}>
      <Prompt
        when={publisher.form.hasMadeChanges}
        message={() => LEAVE_PAGE_MESSAGE}
      />
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/innstillinger/frakt/klasser">Fraktklasser</Crumb>
            {PublisherHelpers.getResourceTitle(
              publisher,
              "name",
              "Ny fraktklasse"
            )}
          </Text>
        </Block>
        <Section>
          <Flex align="flex-start">
            <FlexKid flex={1}>
              <Card>
                <Block>
                  <Section hugTop>
                    <Text element="h2" variant="title">
                      Generelt
                    </Text>
                  </Section>
                  {createPublisherInputField(publisher, {
                    path: "name",
                    label: "Navn",
                    type: "text",
                    required: true
                  })}
                  {createPublisherInputField(publisher, {
                    path: "description",
                    label: "Beskrivelse",
                    type: "textarea"
                  })}
                </Block>
              </Card>
            </FlexKid>
          </Flex>
        </Section>
      </Fader>
      <ActionBar publisher={publisher} destroy />
    </Form>
  );
};

export default ShippingClass;
