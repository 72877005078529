import React, { useContext, useMemo } from "react";
import {
  permissionsAdmin,
  permissionsContributor,
  permissionsAll
} from "../../util/prefilledPermissions";
import { Permissions } from "../../constants/api";
import hasPermissions from "../../util/hasPermissions";
import { SiteContext } from "../../context/Site";

export type DoormanPermissions = "all" | "contributor" | "admin";

interface Props {
  type?: DoormanPermissions;
  permissions?: Permissions[];
}

const Doorman: React.FC<Props> = ({ type, permissions, children }) => {
  const memberPermissions = useContext(SiteContext).permissions;

  const permitted = useMemo<boolean>(() => {
    if (!memberPermissions) {
      return false;
    }

    let pm: Permissions[] = permissionsContributor;

    if (permissions) {
      pm = permissions;
    } else if (type) {
      switch (type) {
        case "all":
          pm = permissionsAll;
          break;
        case "contributor":
          pm = permissionsContributor;
          break;
        case "admin":
          pm = permissionsAdmin;
          break;
      }
    }

    return hasPermissions(memberPermissions, pm);
  }, [memberPermissions, permissions]);

  if (permitted) {
    return <>{children}</>;
  }

  return null;
};

export default Doorman;
