import React from "react";
import { Switch, Route, Redirect } from "react-router";
import Quotes from "./Quotes";
import Quote from "./Quote";

const QuotesRoutes: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path="/b2b"
        render={() => <Redirect to="/b2b/tilbudsforesporsler" />}
      />
      <Route exact path="/b2b/tilbudsforesporsler" component={Quotes} />
      <Route
        exact
        path="/b2b/tilbudsforesporsler/:quote"
        render={(props) => <Quote key={props.match.params.quote} {...props} />}
      />
    </Switch>
  );
};

export default QuotesRoutes;
