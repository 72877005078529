import React, { useEffect } from "react";
import { RouteComponentProps, Prompt, Switch, Route } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";
import { FaRegEnvelope } from "react-icons/fa";
import { Form as IForm } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import HTMLForm from "../../style-guide/Inputs/Form";
import Fader from "../../style-guide/Fader/Fader";
import Block from "../../style-guide/Block/Block";
import usePublisher from "../../hooks/usePublisher";
import PublisherHelpers from "../../util/PublisherHelpers";
import {
  InlineActions,
  InlineActionLink
} from "../../style-guide/InlineActions/InlineActions";
import Section from "../../style-guide/Section/Section";
import ActionBar from "../../components/ActionBar/ActionBar";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import Crumb from "../../components/Crumb/Crumb";
import { LEAVE_PAGE_MESSAGE } from "../../constants/general";
import General from "./SubViews/General";
import Entries from "./SubViews/Entries";

interface Props
  extends RouteComponentProps<{
    form: string;
  }> {}

const Form: React.FC<Props> = ({ match }) => {
  const formId = match.params.form;
  const breadcrumbs = useBreadcrumbs();

  const publisher = usePublisher<IForm>({
    id: formId,
    baseUrl: ["forms"],
    baseDashboardUrl: "/skjemaer",
    initialData: {
      name: "",
      fields: []
    },
    pathsToCopy: [
      {
        path: "name",
        label: "Navn"
      },
      {
        path: "internalDescription",
        label: "Intern beskrivelse"
      },
      {
        path: "publicDescription",
        label: "Offentlig beskrivelse"
      },
      {
        path: "submitButtonText",
        label: "Send inn-knappetekst"
      },
      {
        path: "successText",
        label: "Takk-tekst"
      },
      {
        path: "forwardUrl",
        label: "URL for videresending"
      },
      {
        path: "fields",
        label: "Felter"
      }
    ]
  });

  useEffect(() => {
    breadcrumbs.swapEntry(formId, publisher.form.data.name);
  }, [breadcrumbs, publisher.form.data, formId]);

  return (
    <HTMLForm onSubmit={publisher.form.submit}>
      <Prompt
        when={publisher.form.hasMadeChanges}
        message={(location) => {
          if (location.pathname.includes(formId)) {
            return true;
          }

          return LEAVE_PAGE_MESSAGE;
        }}
      />
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/skjemaer">Skjemaer</Crumb>
            {PublisherHelpers.getResourceTitle(
              publisher,
              "name",
              "Nytt skjema"
            )}
          </Text>
        </Block>
        <Block hugTop hugBottom>
          <InlineActions>
            <InlineActionLink exact to={publisher.dashboardUrl}>
              <AiOutlineEdit /> Rediger skjema
            </InlineActionLink>
            {!publisher.isNew && (
              <InlineActionLink
                exact
                to={`${publisher.dashboardUrl}/innsendelser`}
              >
                <FaRegEnvelope /> Innsendelser
              </InlineActionLink>
            )}
          </InlineActions>
        </Block>
        <Section hugTop>
          <Switch>
            <Route
              exact
              path={publisher.dashboardUrl}
              render={() => <General publisher={publisher} />}
            />
            {!publisher.isNew && (
              <Route
                exact
                path={`${publisher.dashboardUrl}/innsendelser`}
                render={() => <Entries form={publisher.form.data} />}
              />
            )}
          </Switch>
        </Section>
      </Fader>
      <ActionBar publisher={publisher} />
    </HTMLForm>
  );
};

export default Form;
