import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.span`
  color: ${(props) => props.theme.colors.textSecondary};
  font-weight: 400;
`;

const StyledLink = styled(Link)`
  text-transform: capitalize;
  color: ${(props) => props.theme.colors.action};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

interface Props {
  url: string;
}

const Crumb: React.FC<Props> = ({ url, children }) => {
  return (
    <Wrapper>
      <StyledLink to={url}>{children}</StyledLink>/
    </Wrapper>
  );
};

export default Crumb;
