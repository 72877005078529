import React, { useCallback } from "react";
import { CgListTree } from "react-icons/cg";
import { AiOutlineDelete } from "react-icons/ai";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import { ProductTemplate } from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import { Button, ButtonList } from "../../../style-guide/Button/Button";
import { CrossSellModal } from "../../Products/SubViews/CrossSell";
import EmptyState from "../../../style-guide/EmptyState/EmptyState";
import { Table, Tr, Th, Td } from "../../../style-guide/Table/Table";
import { Link } from "react-router-dom";

interface Props {
  publisher: UsePublisherInterface<ProductTemplate>;
}

const CrossSell: React.FC<Props> = ({ publisher }) => {
  const form = publisher.form;
  const template = form.data;
  const crossSell = template.crossSell || [];

  const { spawnModal } = useModal();

  const addProductsModal = useCallback(() => {
    spawnModal(
      <CrossSellModal
        excludeIds={crossSell.map((i) => {
          if (typeof i.product === "string") {
            return i.product;
          }

          return i.product._id;
        })}
        onSelect={(products) => {
          form.setField({
            path: "crossSell",
            value: [...crossSell, ...products.map((p) => ({ product: p }))]
          });
        }}
      />
    );
  }, [spawnModal, crossSell]);

  return (
    <Fader>
      {crossSell.length > 0 ? (
        <Card>
          <Block>
            <Flex>
              <FlexKid flex={1}>
                <Text element="h2" variant="title">
                  Produkter for kryss-salg
                </Text>
              </FlexKid>
              {!publisher.isRevision && (
                <FlexKid>
                  <Button type="button" outlined onClick={addProductsModal}>
                    Legg til produkter
                  </Button>
                </FlexKid>
              )}
            </Flex>
          </Block>
          <Table>
            <thead>
              <Tr>
                <Th>Produkt</Th>
                <Th align="right">Handlinger</Th>
              </Tr>
            </thead>
            <tbody>
              {crossSell.map((s) => {
                if (typeof s.product === "string") {
                  return null;
                }

                const product = s.product;

                return (
                  <tr key={product._id}>
                    <Td verticalAlign="middle" tight>
                      <Text variant="body2">
                        <Link to={`/butikk/produkter/${product.draftRef!}`}>
                          {product.name}
                        </Link>
                      </Text>
                      <Text variant="body3">
                        {product.sku || "Mangler SKU"}
                      </Text>
                    </Td>
                    <Td verticalAlign="middle" align="right" tight>
                      <ButtonList align="right">
                        <Button
                          type="button"
                          aria-label="Fjern produkt fra kryss-salg"
                          circular
                          outlined
                          smaller
                          disabled={publisher.isRevision}
                          onClick={() => {
                            if (publisher.isRevision) {
                              return;
                            }

                            form.setField({
                              path: "crossSell",
                              value: crossSell.filter((i) => {
                                if (typeof i.product === "string") {
                                  return i.product !== product._id;
                                }

                                return i.product._id !== product._id;
                              })
                            });
                          }}
                        >
                          <AiOutlineDelete />
                        </Button>
                      </ButtonList>
                    </Td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card>
      ) : (
        <EmptyState
          title="Ingen produkter lagt til for kryss-salg"
          icon={<CgListTree />}
          text={
            <Text>
              Kryss-salg kan brukes til å vise relaterte produkter på en
              produktside. Du kan enten koble produkter for kryss-salg på
              enkeltprodukter, som her, eller ved bruk av <em>Produktmaler</em>.
            </Text>
          }
          action={
            <Button onClick={addProductsModal}>Legg til produkter</Button>
          }
        />
      )}
    </Fader>
  );
};

export default CrossSell;
