import React, { useEffect, useMemo } from "react";
import { RouteComponentProps, Prompt } from "react-router-dom";
import {
  DraggableProvidedDragHandleProps,
  DraggableStateSnapshot
} from "react-beautiful-dnd";
import { MdDragHandle } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { VariantType, Image as ImageClass } from "@ludens-reklame/rubics-sdk";
import {
  Attribute as IAttribute,
  AttributeTemplateAttributeOption
} from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import Form from "../../style-guide/Inputs/Form";
import Fader from "../../style-guide/Fader/Fader";
import Block from "../../style-guide/Block/Block";
import usePublisher from "../../hooks/usePublisher";
import PublisherHelpers from "../../util/PublisherHelpers";
import Section from "../../style-guide/Section/Section";
import ActionBar from "../../components/ActionBar/ActionBar";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import Crumb from "../../components/Crumb/Crumb";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import Card from "../../style-guide/Card/Card";
import createPublisherInputField from "../../util/createPublisherInputField";
import { Button } from "../../style-guide/Button/Button";
import { LEAVE_PAGE_MESSAGE } from "../../constants/general";
import TextInput from "../../style-guide/Inputs/TextInput";
import { getMetaFieldLabel } from "../Products/SubViews/Attributes";
import { variantType } from "../../constants/localizations";
import ColorPicker from "../../components/ColorPicker/ColorPicker";
import getImageUrlFromImageRef from "../../util/getImageUrlFromImageRef";
import Image from "../../style-guide/Image/Image";
import CrudModal, {
  createCrudModalField
} from "../../components/CrudModal/CrudModal";
import createId from "../../util/createId";
import { RevisionType } from "../../constants/api";
import {
  InlineEditHead,
  InlineEditColumn
} from "../../style-guide/InlineEdit/InlineEdit";
import { Dropper, Dragger } from "../../components/DnD/DnD";
import InlineEdit from "../../components/InlineEdit/InlineEdit";
import Ruler from "../../style-guide/Ruler/Ruler";

interface Props
  extends RouteComponentProps<{
    attribute: string;
  }> {}

const Attribute: React.FC<Props> = ({ match }) => {
  const attributeId = match.params.attribute;
  const breadcrumbs = useBreadcrumbs();

  const publisher = usePublisher<IAttribute>({
    id: attributeId,
    baseUrl: ["attributes"],
    baseDashboardUrl: "/butikk/attributter",
    initialData: {
      type: VariantType.String,
      options: []
    },
    pathsToCopy: [
      {
        path: "name",
        label: "Navn"
      },
      {
        path: "description",
        label: "Beskrivelse"
      },
      {
        path: "type",
        label: "Type"
      },
      {
        path: "options",
        label: "Valg"
      }
    ]
  });

  const form = publisher.form;

  useEffect(() => {
    breadcrumbs.swapEntry(attributeId, publisher.form.data.name);
  }, [breadcrumbs, publisher.form.data, attributeId]);

  const showMeta = useMemo<boolean>(
    () =>
      form.data.type === VariantType.Color ||
      form.data.type === VariantType.Image ||
      form.data.type === VariantType.Icon,
    [form.data.type]
  );

  return (
    <Form onSubmit={publisher.form.submit}>
      <Prompt
        when={publisher.form.hasMadeChanges}
        message={() => LEAVE_PAGE_MESSAGE}
      />
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/butikk/attributter">Attributter</Crumb>
            {PublisherHelpers.getResourceTitle(
              publisher,
              "name",
              "Ny attributt"
            )}
          </Text>
        </Block>
        <Section>
          <Card>
            <Block>
              <Section hugTop>
                <Text element="h2" variant="title">
                  Generelt
                </Text>
              </Section>
              {createPublisherInputField(publisher, {
                path: "name",
                label: "Navn",
                type: "text",
                onBlur: () => {
                  if (!form.data.internalName) {
                    form.setField({
                      path: "internalName",
                      value: form.data.name
                    });
                  }
                },
                required: true
              })}
              {createPublisherInputField(publisher, {
                path: "internalName",
                label: "Internt navn",
                type: "text",
                required: true
              })}
              {createPublisherInputField(publisher, {
                path: "type",
                label: "Type",
                type: "select",
                required: true,
                options: Object.keys(variantType).map((k) => ({
                  label: variantType[k],
                  value: k
                }))
              })}
              {createPublisherInputField(publisher, {
                path: "description",
                label: "Beskrivelse",
                type: "textarea"
              })}
            </Block>
          </Card>
        </Section>
        <Section>
          <Card>
            <Block>
              <Flex>
                <FlexKid flex={1}>
                  <Text element="h2" variant="title">
                    Valg
                  </Text>
                </FlexKid>
                {!publisher.isRevision && (
                  <FlexKid spaceLeft>
                    <Button
                      type="button"
                      outlined
                      onClick={() => {
                        form.setField({
                          path: "options",
                          value: [
                            { internalId: createId() },
                            ...form.data.options
                          ]
                        });
                      }}
                    >
                      Nytt valg
                    </Button>
                  </FlexKid>
                )}
              </Flex>
            </Block>
            <InlineEditHead>
              <InlineEditColumn width="320px">Verdi</InlineEditColumn>
              {showMeta && (
                <InlineEditColumn width="320px">
                  {getMetaFieldLabel(form.data.type)}
                </InlineEditColumn>
              )}
              <InlineEditColumn width="320px">Kategorisering</InlineEditColumn>
            </InlineEditHead>
            <Dropper
              id="attributeOptions"
              onDragEnd={(r) => {
                if (r.destination) {
                  const list = Array.from(form.data.options || []);
                  const [removed] = list.splice(r.source.index, 1);

                  list.splice(r.destination.index, 0, removed);

                  form.setField({
                    path: "options",
                    value: list
                  });
                }
              }}
            >
              {form.data.options.length > 0 ? (
                form.data.options.map((o, k) => (
                  <Dragger
                    //@ts-ignore
                    key={o._id || o.internalId}
                    //@ts-ignore
                    id={o._id || o.internalId}
                    index={k}
                    delegateDragHandle
                    isDragDisabled={publisher.isRevision}
                  >
                    <Option
                      //@ts-ignore
                      key={o._id || o.internalId}
                      type={form.data.type}
                      showMeta={showMeta}
                      option={o}
                      readOnly={publisher.isRevision}
                      onChange={(updatedOption) => {
                        form.setField({
                          path: "options",
                          value: form.data.options.map((oo, kk) => {
                            if (kk === k) {
                              return updatedOption;
                            }

                            return oo;
                          })
                        });
                      }}
                      onDelete={() =>
                        form.setField({
                          path: "options",
                          value: form.data.options.filter((_, kk) => kk !== k)
                        })
                      }
                    />
                  </Dragger>
                ))
              ) : (
                <>
                  <Ruler />
                  <Block hugTop>
                    <Text variant="subheading">Ingen valg enda</Text>
                  </Block>
                </>
              )}
            </Dropper>
          </Card>
        </Section>
      </Fader>
      <ActionBar
        publisher={publisher}
        revisions
        revisionReference={publisher.form.data._id}
        revisionType={RevisionType.Attribute}
        destroy
      />
    </Form>
  );
};

interface OptionProps {
  type: VariantType;
  showMeta: boolean;
  option: AttributeTemplateAttributeOption;
  onChange: (option: AttributeTemplateAttributeOption) => any;
  onDelete: () => any;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  dragState?: DraggableStateSnapshot;
  readOnly?: boolean;
}

const Option: React.FC<OptionProps> = ({
  type,
  showMeta,
  option,
  onChange,
  onDelete,
  dragHandleProps,
  dragState,
  readOnly
}) => {
  const { spawnModal } = useModal();

  const metaInput = useMemo(() => {
    switch (type) {
      case VariantType.Color:
        return (
          <ColorPicker
            value={option.meta || ""}
            readOnly={readOnly}
            onChange={(color) =>
              onChange({
                ...option,
                meta: color
              })
            }
          />
        );

      default:
        const imageUrl = ImageClass.createUrl({
          image: {
            url: getImageUrlFromImageRef(option.meta),
            alt: option.value
          },
          transforms: "w_120,h_80,c_fill"
        });

        const label = `Endre ${type === VariantType.Icon ? "ikon" : "bilde"}`;

        return (
          <a
            href="#"
            aria-label={label}
            onClick={(e) => {
              e.preventDefault();

              if (!readOnly) {
                spawnModal(
                  <CrudModal
                    initialData={option}
                    onSubmit={onChange}
                    fields={[
                      {
                        key: "meta",
                        render: (payload) =>
                          createCrudModalField({
                            payload,
                            type: "media",
                            key: "meta"
                          })
                      }
                    ]}
                    title={label}
                  />
                );
              }
            }}
          >
            <Image src={imageUrl} alt={option.value} />
          </a>
        );
    }
  }, [type, option, onChange]);

  return (
    <InlineEdit
      background={
        dragState && dragState.isDragging ? "backgroundVariant" : undefined
      }
      alignItems="flex-start"
      actions={[
        <Button
          type="button"
          aria-label="Slett"
          circular
          outlined
          smaller
          disabled={readOnly}
          onClick={() => {
            if (window.confirm("Er du sikker på at du vil slette?")) {
              onDelete();
            }
          }}
        >
          <AiOutlineDelete />
        </Button>,
        <Button
          type="button"
          aria-label="Endre rekkefølge"
          outlined
          circular
          smaller
          disabled={readOnly}
          {...dragHandleProps}
        >
          <MdDragHandle />
        </Button>
      ]}
      headerColumns={[
        {
          width: "320px",
          node: (
            <TextInput
              value={option.value}
              autoFocus={!option._id}
              readOnly={readOnly}
              onChange={(e) => {
                onChange({
                  ...option,
                  value: e.target.value
                });
              }}
            />
          )
        },
        ...(showMeta
          ? [
              {
                width: "320px",
                node: metaInput
              }
            ]
          : []),
        {
          width: "320px",
          node: (
            <TextInput
              value={option.categorization}
              readOnly={readOnly}
              onChange={(e) => {
                onChange({
                  ...option,
                  categorization: e.target.value
                });
              }}
            />
          )
        }
      ]}
    />
  );
};

export default Attribute;
