import { Revision } from "../types/apiResponses";
import { useApi } from "./useApi";
import useApiUrl from "./useApiUrl";

interface UseRevisionInterface {
  revision: Revision | undefined;
  loading: boolean;
}

function useRevision(revisionId?: string): UseRevisionInterface {
  const url = useApiUrl(["revisions", revisionId || ""]);

  const fetch = useApi<Revision | undefined>({
    endpoint: url,
    fetchOnMount: !!revisionId,
    initialData: undefined
  });

  return {
    revision: fetch.resource,
    loading: fetch.loading
  };
}

export default useRevision;
