import React, { InputHTMLAttributes, ReactNode } from "react";
import styled, { css } from "styled-components";
import { baseInputStyles } from "./TextInput";

const Wrapper = styled.div`
  ${baseInputStyles};
  padding: ${(props) => `0.8em ${props.theme.spacing.small}`};
  height: auto;
  min-height: 42px;
`;

const Label = styled.label<{ standalone?: boolean }>`
  display: flex;

  ${(props) =>
    !props.standalone &&
    css`
      &:not(:last-child) {
        border-bottom: 1px solid ${(props) => props.theme.colors.borderDim};
        padding-bottom: ${(props) => props.theme.spacing.xs};
        margin-bottom: ${(props) => props.theme.spacing.xs};
      }
    `}

  input {
    margin-top: 5px;
  }
`;

const Text = styled.span`
  display: inline-block;
  margin-left: ${(props) => props.theme.spacing.xs};
`;

const Description = styled.span`
  display: block;
  color: ${(props) => props.theme.colors.textSecondary};
`;

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: ReactNode;
  checkbox?: boolean;
  standalone?: boolean;
  description?: ReactNode;
}

const Radio: React.FC<Props> = ({
  label,
  standalone,
  checkbox,
  description,
  ...rest
}) => {
  return (
    <Label standalone={standalone}>
      <input type={checkbox ? "checkbox" : "radio"} {...rest} />
      <Text>
        {label}
        {description && <Description>{description}</Description>}
      </Text>
    </Label>
  );
};

const Radios: React.FC = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export { Radios, Radio };
