import React, { useState } from "react";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import { Company, Media } from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import Section from "../../../style-guide/Section/Section";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import createPublisherInputField from "../../../util/createPublisherInputField";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import SearchAndSelect from "../../../components/SearchAndSelect/SearchAndSelect";
import Label from "../../../style-guide/Inputs/Label";
import Field from "../../../style-guide/Inputs/Field";
import { CatalogType } from "../../../constants/api";

interface Props {
  publisher: UsePublisherInterface<Company>;
}

const General: React.FC<Props> = ({ publisher }) => {
  const [tempImage, setTempImage] = useState<Media | null>(null);
  const form = publisher.form;

  return (
    <Fader>
      <Flex align="flex-start">
        <FlexKid flex={2}>
          <Card>
            <Block>
              <Section hugTop>
                <Text element="h2" variant="title">
                  Generelt
                </Text>
              </Section>
              {createPublisherInputField(publisher, {
                path: "name",
                label: "Navn",
                type: "text",
                required: true
              })}
              <Field>
                <Label htmlFor="productCatalog">Produktkatalog</Label>
                <SearchAndSelect
                  id="productCatalog"
                  url={["catalogs"]}
                  searchKey="name"
                  itemToString={(catalog) => (catalog ? catalog.name : "")}
                  value={
                    form.data.productCatalog &&
                    typeof form.data.productCatalog !== "string"
                      ? form.data.productCatalog.name
                      : ""
                  }
                  query={{
                    type: CatalogType.Product
                  }}
                  placeholder="Standard"
                  onChange={(catalog) => {
                    form.setField({
                      path: "productCatalog",
                      value: catalog ? catalog : null
                    });
                  }}
                />
              </Field>
              <Field hugBottom>
                <Label htmlFor="contentCatalog">Innholdskatalog</Label>
                <SearchAndSelect
                  id="contentCatalog"
                  url={["catalogs"]}
                  searchKey="name"
                  itemToString={(catalog) => (catalog ? catalog.name : "")}
                  value={
                    form.data.contentCatalog &&
                    typeof form.data.contentCatalog !== "string"
                      ? form.data.contentCatalog.name
                      : ""
                  }
                  query={{
                    type: CatalogType.Content
                  }}
                  placeholder="Standard"
                  onChange={(catalog) => {
                    form.setField({
                      path: "contentCatalog",
                      value: catalog ? catalog : null
                    });
                  }}
                />
              </Field>
            </Block>
          </Card>
        </FlexKid>
        <FlexKid flex={1} spaceLeft>
          <Card>
            <Block>
              <Section hugTop>
                <Text element="h2" variant="title">
                  Logo
                </Text>
              </Section>
              {createPublisherInputField(publisher, {
                path: "logo",
                label: "Logo",
                type: "media",
                minimize: true,
                setField: (media) => {
                  if (!media || media.ref === "") {
                    publisher.form.setField({
                      path: "logo",
                      value: null
                    });
                  } else {
                    publisher.form.setField({
                      path: "logo",
                      value: media.ref
                    });

                    setTempImage(media);
                  }
                },
                value:
                  !!publisher.form.data.logo &&
                  typeof publisher.form.data.logo !== "string"
                    ? {
                        ref: publisher.form.data.logo._id,
                        url: publisher.form.data.logo.secure_url,
                        alt: "Logo"
                      }
                    : tempImage
              })}
            </Block>
          </Card>
        </FlexKid>
      </Flex>
    </Fader>
  );
};

export default General;
