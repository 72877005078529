import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";
import Text from "../../../style-guide/Text/Text";
import Block from "../../../style-guide/Block/Block";
import Crumb from "../../../components/Crumb/Crumb";
import { Site, PaymentProviderConfig, App } from "../../../types/apiResponses";
import useApiUrl from "../../../hooks/useApiUrl";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";
import Card from "../../../style-guide/Card/Card";
import Fader from "../../../style-guide/Fader/Fader";
import { Table, Tr, Th, Td } from "../../../style-guide/Table/Table";
import useApps from "../../../hooks/useApps";
import createInputField from "../../../util/createInputField";
import useForm from "../../../hooks/useForm";
import {
  Button,
  ButtonLink,
  ButtonList
} from "../../../style-guide/Button/Button";
import Ruler from "../../../style-guide/Ruler/Ruler";
import Form from "../../../style-guide/Inputs/Form";
import useNotifications from "../../../hooks/useNotifications";
import EmptyState from "../../../style-guide/EmptyState/EmptyState";

const PaymentProviders: React.FC = () => {
  const url = useApiUrl(["site"]);
  const apps = useApps();
  const notifications = useNotifications();
  const form = useForm<Site | null>(
    {},
    {
      method: "PATCH",
      endpoint: url,
      prefillEndpoint: url,
      onSuccess: () => {
        notifications.spawn({
          title: "Innstillinger lagret"
        });
      }
    }
  );

  const providers = useMemo<PaymentProviderConfig[]>(() => {
    return form.data?.paymentProviders || [];
  }, [form.data]);

  return (
    <Fader>
      <Block hugTop>
        <Text element="h1" variant="display3">
          <Crumb url="/innstillinger">Innstillinger</Crumb>
          Betalingsløsninger
        </Text>
      </Block>
      <BusyBoy busy={form.submitting || form.loadingPrefill}>
        {!form.loadingPrefill && providers.length > 0 ? (
          <Form onSubmit={form.submit}>
            <Card>
              <Table>
                <thead>
                  <Tr>
                    <Th>Navn</Th>
                    <Th>Standard</Th>
                    <Th>Aktiv</Th>
                    <Th align="right">Handlinger</Th>
                  </Tr>
                </thead>
                <tbody>
                  {providers.map((p) => (
                    <ProviderRow
                      key={p._id}
                      provider={p}
                      apps={apps.apps}
                      onChange={(provider) =>
                        form.setField({
                          path: "paymentProviders",
                          value: form.data?.paymentProviders.map((p) => {
                            if (p.name === provider.name) {
                              return provider;
                            } else if (provider.default) {
                              return {
                                ...p,
                                default: false
                              };
                            }

                            return p;
                          })
                        })
                      }
                    />
                  ))}
                </tbody>
              </Table>
              <Ruler />
              <Block hugTop>
                <ButtonList align="right">
                  <Button
                    type="button"
                    outlined
                    disabled={!form.hasMadeChanges}
                    onClick={() => {
                      if (
                        window.confirm("Er du sikker på at du vil avbryte?")
                      ) {
                        form.refresh();
                      }
                    }}
                  >
                    Avbryt
                  </Button>
                  <Button type="submit" disabled={!form.hasMadeChanges}>
                    {form.submitting ? "Lagrer…" : "Lagre"}
                  </Button>
                </ButtonList>
              </Block>
            </Card>
          </Form>
        ) : (
          <EmptyState
            title="Ingen betalingsløsninger tilgjengelig"
            text={
              <>
                Du kan finne og installere betalingsløsninger på{" "}
                <Link to="/apper">App-markedet</Link>.
              </>
            }
          />
        )}
      </BusyBoy>
    </Fader>
  );
};

interface ProviderRowProps {
  provider: PaymentProviderConfig;
  apps: App[];
  onChange: (provider: PaymentProviderConfig) => any;
}

const ProviderRow: React.FC<ProviderRowProps> = ({
  provider,
  apps,
  onChange
}) => {
  const app = useMemo<App | undefined>(() => {
    return apps.find((a) => a.identifier === provider.name);
  }, [provider, apps]);

  const name = useMemo<string>(() => {
    if (provider.name === "basic") {
      return "Standard";
    }

    if (app) {
      return app.name;
    }

    return provider.name;
  }, [provider, app]);

  const set = useCallback(
    (field: string, value: any) => {
      onChange({
        ...provider,
        [field]: value
      });
    },
    [onChange, provider]
  );

  return (
    <Tr>
      <Td verticalAlign="middle">
        <Text variant="body2">{name}</Text>
      </Td>
      <Td>
        {createInputField({
          key: `${provider._id}_default`,
          type: "boolean",
          value: provider.default,
          onChange: (value) => set("default", value),
          hugTop: true
        })}
      </Td>
      <Td>
        {createInputField({
          key: `${provider._id}_enabled`,
          type: "boolean",
          value: provider.enabled,
          onChange: (value) => set("enabled", value),
          hugTop: true
        })}
      </Td>
      <Td align="right" verticalAlign="middle">
        {app ? (
          <ButtonLink to={`/apper/${app.identifier}`} circular outlined smaller>
            <AiOutlineEdit />
          </ButtonLink>
        ) : (
          <Text variant="body3" align="right">
            Ikke tilgjengelig
          </Text>
        )}
      </Td>
    </Tr>
  );
};

export default PaymentProviders;
