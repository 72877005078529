import React, { useMemo, useContext } from "react";
import { ThemeContext } from "styled-components";
import { StatType } from "@ludens-reklame/rubics-sdk";
import Block from "../../style-guide/Block/Block";
import Text from "../../style-guide/Text/Text";
import Section from "../../style-guide/Section/Section";
import Fader from "../../style-guide/Fader/Fader";
import Timeline from "../../components/Timeline/Timeline";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import { useApi } from "../../hooks/useApi";
import { PageViewsStats, CommerceStats } from "../../types/apiResponses";
import useApiUrl from "../../hooks/useApiUrl";
import { currencyFormat, numberFormat } from "../../util/intl";
import calculatePercentageGrowth from "../../util/calculatePercentageGrowth";
import { ExtendedStatType } from "../../constants/api";
import useSite from "../../hooks/useSite";

const Home: React.FC = () => {
  const themeContext = useContext(ThemeContext);
  const baseEndpoint = useApiUrl(["stats"]);
  const site = useSite();

  const pageViewStats = useApi<PageViewsStats>({
    endpoint: baseEndpoint + "/pageViews",
    initialData: {
      pageViewsLast7Days: {
        currentPeriod: 0,
        lastPeriod: 0
      },
      pageViewsByPeriod: [],
      pageViewsByPage: []
    },
    fetchOnMount: true
  });

  const commerceStats = useApi<CommerceStats>({
    endpoint: baseEndpoint + "/commerce",
    initialData: {
      ordersLast7Days: {
        currentPeriod: 0,
        lastPeriod: 0
      },
      ordersByPeriod: [],
      ordersByProduct: []
    },
    fetchOnMount: true
  });

  const pageViewsPercentageGrowth = useMemo(
    () =>
      calculatePercentageGrowth(
        pageViewStats.resource.pageViewsLast7Days.currentPeriod,
        pageViewStats.resource.pageViewsLast7Days.lastPeriod
      ),
    [pageViewStats.resource]
  );

  const ordersPercentageGrowth = useMemo(
    () =>
      calculatePercentageGrowth(
        commerceStats.resource.ordersLast7Days.currentPeriod,
        commerceStats.resource.ordersLast7Days.lastPeriod
      ),
    [commerceStats.resource]
  );

  return (
    <Fader>
      <Flex align="flex-start">
        <FlexKid flex={5}>
          <Timeline
            types={[
              StatType.OrderCreated,
              StatType.FormSubmit,
              ExtendedStatType.CreatedSite,
              ExtendedStatType.InstalledApp,
              ExtendedStatType.UninstalledApp,
              ExtendedStatType.PublishedPage,
              ExtendedStatType.PublishedProduct,
              ExtendedStatType.SystemMessage
            ]}
          />
        </FlexKid>
        <FlexKid flex={2} spaceLeft>
          <Block>
            {site.hasEcommerce && (
              <Section hugTop>
                <Text variant="display3">
                  {currencyFormat.format(
                    commerceStats.resource.ordersLast7Days.currentPeriod
                  )}
                </Text>
                {commerceStats.resource.ordersLast7Days.currentPeriod > 0 && (
                  <Text
                    variant="title"
                    color={
                      ordersPercentageGrowth >= 0
                        ? themeContext.colors.good
                        : themeContext.colors.warning
                    }
                  >
                    {ordersPercentageGrowth > 0 && "+"}
                    {ordersPercentageGrowth === 0
                      ? "+∞"
                      : numberFormat.format(ordersPercentageGrowth)}
                    %
                  </Text>
                )}
                <Text element="h2" variant="subheading" gutterTop>
                  Omsetning siste 7 dager
                </Text>
              </Section>
            )}
            <Section hugTop>
              <Text variant="display3">
                {numberFormat.format(
                  pageViewStats.resource.pageViewsLast7Days.currentPeriod
                )}
              </Text>
              {pageViewStats.resource.pageViewsLast7Days.currentPeriod > 0 && (
                <Text
                  variant="title"
                  color={
                    pageViewsPercentageGrowth >= 0
                      ? themeContext.colors.good
                      : themeContext.colors.warning
                  }
                >
                  {pageViewsPercentageGrowth > 0 && "+"}
                  {pageViewsPercentageGrowth === 0
                    ? "+∞"
                    : numberFormat.format(pageViewsPercentageGrowth)}
                  %
                </Text>
              )}
              <Text element="h2" variant="subheading" gutterTop>
                Sidevisninger siste 7 dager
              </Text>
            </Section>
          </Block>
        </FlexKid>
      </Flex>
    </Fader>
  );
};

export default Home;
