import React from "react";
import {
  AiOutlineLayout,
  AiOutlineShopping,
  AiOutlineLineChart,
  AiOutlineAppstore,
  AiOutlineSetting,
  AiOutlineExperiment,
  AiOutlineCalendar,
  AiOutlineForm
} from "react-icons/ai";
import { MdBusiness } from "react-icons/md";
import { BsLightning } from "react-icons/bs";
import { Sidebar } from "../../style-guide/Layout/Layout";
import Logo from "../../style-guide/Logo/Logo";
import {
  Menu,
  MenuItem,
  SubMenu
} from "../../style-guide/Navigation/Navigation";
import useSite from "../../hooks/useSite";
import useTheme from "../../hooks/useTheme";
import Doorman from "../Doorman/Doorman";

const MainMenu: React.FC = () => {
  const site = useSite();
  const theme = useTheme();

  return (
    <Sidebar>
      <Logo />
      <Menu>
        <MenuItem to="/" exact icon={<BsLightning />}>
          Hjem
        </MenuItem>
        <Doorman type="contributor">
          <MenuItem
            to="/innhold"
            exact
            icon={<AiOutlineLayout />}
            subMenu={
              <SubMenu>
                <MenuItem to="/innhold/sider">Sider</MenuItem>
                <MenuItem to="/innhold/kategorier">Kategorier</MenuItem>
                {site.hasEcommerce && (
                  <MenuItem to="/innhold/produktsider">Produktsider</MenuItem>
                )}
                <MenuItem to="/innhold/filer">Filer</MenuItem>
                <Doorman type="admin">
                  <MenuItem to="/designer/globalt">Globalt</MenuItem>
                </Doorman>
                <MenuItem to="/innhold/komponenter">Komponenter</MenuItem>
                {(site.b2bEnabled || site.departmentsEnabled) && (
                  <MenuItem to="/innhold/innholdskataloger">
                    Innholdskataloger
                  </MenuItem>
                )}
                {theme &&
                  Array.isArray(theme.dataSets) &&
                  theme.dataSets.map((d) => (
                    <MenuItem key={d.name} to={"/innhold/data-sets/" + d.name}>
                      {d.label}
                    </MenuItem>
                  ))}
              </SubMenu>
            }
          >
            Innhold
          </MenuItem>
        </Doorman>
        {site.hasEcommerce && (
          <MenuItem
            to="/butikk"
            exact
            icon={<AiOutlineShopping />}
            subMenu={
              <SubMenu>
                <MenuItem to="/butikk/ordrer">Ordrer</MenuItem>
                {site.subscriptionsEnabled && (
                  <MenuItem to="/butikk/abonnementer">Abonnementer</MenuItem>
                )}
                <Doorman type="contributor">
                  <MenuItem to="/butikk/produkter">Produkter</MenuItem>
                  <MenuItem to="/butikk/produktmaler">Produktmaler</MenuItem>
                  <MenuItem to="/butikk/attributter">Attributter</MenuItem>
                  <MenuItem to="/butikk/tilbud">Tilbud</MenuItem>
                  <MenuItem to="/butikk/etiketter">Etiketter</MenuItem>
                  <MenuItem to="/butikk/kunder">Kunder</MenuItem>
                  {(site.b2bEnabled ||
                    site.departmentsEnabled ||
                    site.departmentOf) && (
                    <MenuItem to="/butikk/produktkataloger">
                      Produktkataloger
                    </MenuItem>
                  )}
                </Doorman>
              </SubMenu>
            }
          >
            Butikk
          </MenuItem>
        )}
        <MenuItem to="/skjemaer" icon={<AiOutlineForm />}>
          Skjemaer
        </MenuItem>
        {site.b2bEnabled && (
          <MenuItem
            to="/b2b"
            exact
            icon={<MdBusiness />}
            subMenu={
              <SubMenu>
                <MenuItem to="/b2b/tilbudsforesporsler">
                  Tilbudsforespørsler
                </MenuItem>
                <MenuItem to="/b2b/bedrifter">Bedrifter</MenuItem>
              </SubMenu>
            }
          >
            B2B
          </MenuItem>
        )}
        {site.bookingEnabled && (
          <MenuItem
            to="/booking"
            exact
            icon={<AiOutlineCalendar />}
            subMenu={
              <SubMenu>
                <MenuItem to="/booking/kalender">Kalender</MenuItem>
                <MenuItem to="/booking/tjenester">Tjenester</MenuItem>
                <MenuItem to="/booking/ressurser">Ressurser</MenuItem>
              </SubMenu>
            }
          >
            Booking
          </MenuItem>
        )}
        <Doorman type="contributor">
          <MenuItem to="/eksperimenter" icon={<AiOutlineExperiment />}>
            Eksperimenter
          </MenuItem>
        </Doorman>
        <MenuItem
          to="/rapporter"
          exact
          icon={<AiOutlineLineChart />}
          subMenu={
            <SubMenu>
              <MenuItem to="/rapporter/besok">Besøk</MenuItem>
              {site.hasEcommerce && (
                <MenuItem to="/rapporter/butikk">Butikk</MenuItem>
              )}
            </SubMenu>
          }
        >
          Rapporter
        </MenuItem>
        <Doorman type="contributor">
          <MenuItem to="/apper" icon={<AiOutlineAppstore />}>
            Apper
          </MenuItem>
        </Doorman>
        <MenuItem to="/innstillinger" icon={<AiOutlineSetting />}>
          Innstillinger
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};

export default MainMenu;
