import React, {
  ReactNode,
  useState,
  useMemo,
  useCallback,
  useEffect
} from "react";
import InfiniteScroll from "react-infinite-scroller";
import { AiOutlineSearch } from "react-icons/ai";
import { useDebouncedCallback } from "use-debounce/lib";
import useApiUrl from "../../hooks/useApiUrl";
import { RequestData, Service } from "../../util/api";
import { SearchOpts, useSearch } from "../../hooks/useApi";
import Table, { Col } from "../Table/Table";
import Fader from "../../style-guide/Fader/Fader";
import Block from "../../style-guide/Block/Block";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import Text from "../../style-guide/Text/Text";
import EmptyState from "../../style-guide/EmptyState/EmptyState";
import BusyBoy from "../BusyBoy/BusyBoy";
import Card from "../../style-guide/Card/Card";
import Crumb from "../Crumb/Crumb";
import TextInput from "../../style-guide/Inputs/TextInput";
import Doorman from "../Doorman/Doorman";

interface EmptyStateOpts {
  title: string;
  text: ReactNode;
  action?: ReactNode;
  icon?: ReactNode;
}

interface Props {
  title: ReactNode;
  crumb?: { label: string; path: string };
  emptyState: EmptyStateOpts;
  url: string[];
  service?: Service;
  columns: Col[];
  createRow: (row: any) => { [key: string]: ReactNode };
  queryLabel?: string;
  queryField?: string;
  queryParams?: RequestData;
  actions?: ReactNode;
  hideTitle?: boolean;
  limit?: number;
  tight?: boolean;
}

const Tabler: React.FC<Props> = ({
  title,
  crumb,
  actions,
  emptyState,
  url,
  service,
  columns,
  createRow,
  queryLabel,
  queryField,
  queryParams,
  hideTitle,
  limit,
  tight
}) => {
  const [usedQuery, setUsedQuery] = useState("");
  const [query, setQuery] = useState("");
  const urlString = useApiUrl(url);

  const queryOpts = useMemo<SearchOpts<any>>(
    () => ({
      service: service || Service.Admin,
      endpoint: urlString,
      limit,
      queryParams: queryField
        ? {
            ...queryParams,
            [queryField]: query
          }
        : queryParams
    }),
    [urlString, queryField, queryParams, query, limit]
  );

  const { results, loading, hasMore, search, hasSearched } = useSearch<any>({
    ...queryOpts,
    onSuccess: () => setUsedQuery(query)
  });

  const handleSearch = useCallback(() => {
    search(queryOpts);
  }, [search, queryOpts]);

  const [debouncedSearch] = useDebouncedCallback(handleSearch, 500);

  useEffect(() => {
    if (hasSearched) {
      debouncedSearch();
    }
  }, [query]);

  return (
    <Fader>
      {!hideTitle && (
        <Block hugTop>
          <Flex>
            <FlexKid flex={1}>
              <Text element="h1" variant="display3">
                {crumb && <Crumb url={crumb.path}>{crumb.label}</Crumb>}
                {title}
              </Text>
            </FlexKid>
            {actions && !queryField && (
              <Doorman type="contributor">
                <FlexKid>{actions}</FlexKid>
              </Doorman>
            )}
          </Flex>
        </Block>
      )}
      {hasSearched && results.length === 0 && !usedQuery && (
        <EmptyState
          title={emptyState.title}
          text={emptyState.text}
          icon={emptyState.icon}
          action={<Doorman type="contributor">{emptyState.action}</Doorman>}
        />
      )}
      <BusyBoy busy={loading}>
        <InfiniteScroll
          pageStart={1}
          initialLoad
          loadMore={() =>
            search({
              ...queryOpts,
              paginate: true
            })
          }
          hasMore={hasMore}
        >
          {(results.length > 0 || usedQuery) && (
            <Card>
              {queryField && (
                <Block>
                  <Flex>
                    <FlexKid flex={1}>
                      <TextInput
                        icon={<AiOutlineSearch />}
                        placeholder={queryLabel}
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                      />
                    </FlexKid>
                    {actions && <FlexKid spaceLeft>{actions}</FlexKid>}
                  </Flex>
                </Block>
              )}
              {results.length > 0 && (
                <Table
                  tight={tight}
                  columns={columns}
                  data={results.map(createRow)}
                />
              )}
              {results.length < 1 && (
                <EmptyState
                  title={
                    <>
                      Ingen treff på "<em>{usedQuery}</em>"
                    </>
                  }
                />
              )}
            </Card>
          )}
        </InfiniteScroll>
      </BusyBoy>
    </Fader>
  );
};

export default Tabler;
