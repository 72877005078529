import React from "react";
import { Switch, Route, Redirect } from "react-router";
import SiteSelection from "./SiteSelection";

const SiteSelectionRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/velg-nettsted" component={SiteSelection} />
      <Route path="*" render={() => <Redirect to="/velg-nettsted" />} />
    </Switch>
  );
};

export default SiteSelectionRoutes;
