import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineKey } from "react-icons/ai";
import { ClientCredential } from "../../../types/apiResponses";
import Text from "../../../style-guide/Text/Text";
import { ButtonLink } from "../../../style-guide/Button/Button";
import Tabler from "../../../components/Tabler/Tabler";
import localize from "../../../util/localize";
import { scopes } from "../../../constants/localizations";

const ClientCredentials: React.FC = () => {
  return (
    <Tabler
      title="Tilgangsnøkler"
      crumb={{
        label: "Innstillinger",
        path: "/innstillinger"
      }}
      actions={
        <ButtonLink to="tilgangsnokler/opprett">
          Opprett tilgangsnøkkel
        </ButtonLink>
      }
      emptyState={{
        title: "Opprett din første tilgangsnøkkel",
        icon: <AiOutlineKey />,
        text:
          "Tilgangsnøkler gjør det mulig for tredjepartstjenester å lese eller skrive data til nettsiden din.",
        action: (
          <ButtonLink to="tilgangsnokler/opprett">
            Opprett tilgangsnøkkel
          </ButtonLink>
        )
      }}
      url={["client-credentials"]}
      columns={[
        {
          Header: "Tilgangsnøkkel",
          accessor: "label"
        },
        {
          Header: "Tilganger",
          accessor: "scopes",
          verticalAlign: "middle"
        }
      ]}
      createRow={(clientCredential: ClientCredential) => ({
        ...clientCredential,
        label: (
          <>
            <Text variant="body2">
              <Link
                to={`/innstillinger/tilgangsnokler/${clientCredential._id}`}
              >
                {clientCredential.label}
              </Link>
            </Text>
            <Text variant="body3">
              {clientCredential.description || "Mangler beskrivelse"}
            </Text>
          </>
        ),
        scopes: (
          <Text variant="body3">
            {clientCredential.scopes.map((s) => localize(scopes, s)).join(", ")}
          </Text>
        )
      })}
    />
  );
};

export default ClientCredentials;
