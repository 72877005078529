import uuid from "uuid/v4";
import ObjectID from "bson-objectid";

function createId(): string {
  return uuid();
}

function createObjectId(): string {
  return new ObjectID().toHexString();
}

export default createId;
export { createObjectId };
