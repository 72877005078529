import React from "react";
import { useHistory } from "react-router-dom";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import Text from "../../style-guide/Text/Text";
import ProductPicker from "../../components/ProductPicker/ProductPicker";
import Crumb from "../../components/Crumb/Crumb";
import Fader from "../../style-guide/Fader/Fader";
import Block from "../../style-guide/Block/Block";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import {
  Button,
  ButtonExternalLink,
  ButtonLink,
  ButtonList
} from "../../style-guide/Button/Button";
import { Product } from "../../types/apiResponses";
import useCanonicalUrl from "../../hooks/useCanonicalUrl";
import undraftSlug from "../../util/undraftSlug";
import useForm from "../../hooks/useForm";
import Form from "../../style-guide/Inputs/Form";
import { Modal, ModalActions, ModalBody } from "../../style-guide/Modal/Modal";
import BusyBoy from "../../components/BusyBoy/BusyBoy";

const Products: React.FC = () => {
  return (
    <Fader>
      <Block hugTop>
        <Flex>
          <FlexKid flex={1}>
            <Text element="h1" variant="display3">
              <Crumb url="/butikk">Butikk</Crumb>
              Produkter
            </Text>
          </FlexKid>
          <FlexKid>
            <ButtonLink to="produkter/opprett">Opprett produkt</ButtonLink>
          </FlexKid>
        </Flex>
      </Block>
      <ProductPicker
        queryParams={{
          fetchInherited: true
        }}
      />
    </Fader>
  );
};

interface CloneParentProductModalProps {
  product: Product;
}

export const CloneParentProductModal: React.FC<
  CloneParentProductModalProps
> = ({ product }) => {
  const history = useHistory();
  const canonical = useCanonicalUrl([undraftSlug(product.slug)]);
  const { despawnModal } = useModal();

  const form = useForm<Product>(
    { id: product._id },
    {
      endpoint: "products/clone",
      method: "POST",
      onSuccess: (product: Product) => {
        if ("_id" in product) {
          despawnModal();
          history.push(`/butikk/produkter/${product._id}`);
        }
      }
    }
  );

  return (
    <Form onSubmit={form.submit}>
      <Modal maxWidth="32rem">
        <BusyBoy busy={form.submitting}>
          <ModalBody>
            <Block>
              <Text variant="title" gutterBottom>
                Globalt innhold
              </Text>
              <Text gutterBottom>
                <strong>{product.name}</strong> er publisert globalt, som betyr
                at du ikke kan gjøre endringer her.
              </Text>
              <Text>
                Dersom du ønsker å gjøre endringer på dette produktet, kan du
                lage en lokal kopi, som vil overstyre innholdet som er publisert
                globalt.
              </Text>
            </Block>
          </ModalBody>
          <ModalActions>
            <ButtonList align="right">
              <Button
                type="button"
                outlined
                onClick={() => {
                  despawnModal();
                }}
              >
                Avbryt
              </Button>
              <ButtonExternalLink
                outlined
                href={canonical}
                target="_blank"
                rel="noopener noreferrer"
              >
                Vis produkt
              </ButtonExternalLink>
              <Button type="submit">
                {form.submitting ? "Lager kopi…" : "Lag kopi"}
              </Button>
            </ButtonList>
          </ModalActions>
        </BusyBoy>
      </Modal>
    </Form>
  );
};

export default Products;
