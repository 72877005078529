import styled, { css } from "styled-components";

interface Props {
  smaller?: boolean;
  bigger?: boolean;
  noMaxWidth?: boolean;
  noBorderRadius?: boolean;
}

const Image = styled.img<Props>`
  display: block;
  max-width: 5rem;
  max-height: 42px;
  border-radius: 8px;

  ${(props) =>
    props.smaller &&
    css`
      max-width: 2rem;
      max-height: 2rem;
    `}

  ${(props) =>
    props.bigger &&
    css`
      max-width: 12rem;
      max-height: 12rem;
    `}

  ${(props) =>
    props.noMaxWidth &&
    css`
      max-width: none;
      max-height: none;
    `}

  ${(props) =>
    props.noBorderRadius &&
    css`
      border-radius: 0;
    `}
`;

export default Image;
