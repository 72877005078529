import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useContext
} from "react";
import { createPortal } from "react-dom";
import styled, { css } from "styled-components";
import Downshift from "downshift";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { baseInputStyles } from "../../style-guide/Inputs/TextInput";
import useApiUrl from "../../hooks/useApiUrl";
import useForm from "../../hooks/useForm";
import { Site } from "../../types/apiResponses";
import { Elements, Element } from "../../style-guide/Elements/Elements";
import usePositioning from "../../hooks/usePositioning";
import Absolute from "../../style-guide/Absolute/Absolute";
import { SiteContext } from "../../context/Site";

const FauxTextInput = styled.div`
  ${baseInputStyles}

  padding: 0;
  display: flex;
  align-items: center;

  form {
    flex-shrink: 0;
    min-width: 5rem;
    margin: 0;
    padding: 0;
    flex: 1;
  }
`;

const Input = styled.div`
  input {
    ${baseInputStyles}

    width: 100%;
    border: none;
    background-color: transparent;
  }
`;

interface BlipsProps {
  inline: boolean;
}

const Blips = styled.ul<BlipsProps>`
  list-style: none;
  margin: 0;
  padding: ${(props) => `0 calc(${props.theme.spacing.small} / 2)`};
  font-size: ${(props) => props.theme.sizes.negOne};
  overflow-x: auto;

  ${(props) =>
    !props.inline &&
    css`
      margin-top: ${(props) => props.theme.spacing.xs};
      padding: 0;
    `};
`;

const Blip = styled.li<BlipsProps>`
  display: inline-block;
  border-radius: 8px;
  padding: ${(props) => `4px 0 4px calc(${props.theme.spacing.small} / 2)`};
  background: ${(props) => props.theme.colors.backgroundInput};

  &:not(:last-child) {
    margin-right: ${(props) => props.theme.spacing.xs};

    ${(props) =>
      !props.inline &&
      css`
        margin-bottom: ${(props) => props.theme.spacing.xs};
      `};
  }

  button {
    box-sizing: content-box;
    background-color: transparent;
    position: relative;
    border: none;
    padding: 0 6px 0 4px;
    width: 14px;
    height: 12px;
    color: ${(props) => props.theme.colors.textPrimary};

    svg {
      position: absolute;
      top: -4px;
      left: 6px;
    }
  }
`;

interface Props {
  tags?: string[];
  readOnly?: boolean;
  disableTagCreation?: boolean;
  placeholder?: string;
  zIndex?: number;
  inline: boolean;
  onChange?: (tags: string[]) => any;
}

const Tagger: React.FC<Props> = ({
  tags,
  readOnly,
  disableTagCreation,
  placeholder,
  zIndex,
  inline,
  onChange
}) => {
  const [typedTag, setTypedTag] = useState<string>("");
  const [_tags, setTags] = useState<string[]>(tags || []);
  const siteContext = useContext(SiteContext);
  const positioning = usePositioning({
    offsetTargetHeight: true,
    useScrollPosition: true
  });

  useEffect(() => {
    setTags(tags || []);
  }, [tags]);

  const sitePatchEndpoint = useApiUrl(["site"]);
  const sitePatch = useForm<Site>(
    {},
    {
      endpoint: sitePatchEndpoint,
      method: "PATCH",
      onSuccess: (site: Site) => {
        siteContext.setData(site);
      }
    }
  );

  const tagMatch = useMemo<boolean>(() => {
    return (siteContext.data.tags || [])
      .map((t) => t.toLowerCase())
      .includes(typedTag.toLowerCase());
  }, [typedTag, siteContext.data]);

  const handleSetTags = useCallback(
    (tags: string[]) => {
      setTags(tags);

      if (typeof onChange === "function") {
        onChange(tags);
      }
    },
    [onChange]
  );

  const handleSubmit = useCallback(
    (tag?: string) => {
      let usedTag = typedTag;

      if (_tags.some((t) => t.toLowerCase() === typedTag.toLowerCase())) {
        return;
      }

      if (
        !disableTagCreation &&
        !tag &&
        typedTag &&
        !(siteContext.data.tags || []).some(
          (t) => t.toLowerCase() === typedTag.toLowerCase()
        )
      ) {
        sitePatch.setField({
          path: "tags",
          value: [...(siteContext.data.tags || []), typedTag],
          submit: true
        });
      } else if (tag) {
        usedTag = tag;
      }

      if (!usedTag) {
        return;
      }

      const newTags = [..._tags, usedTag];

      setTypedTag("");
      handleSetTags(newTags);
    },
    [disableTagCreation, sitePatch, siteContext.data, typedTag, handleSetTags]
  );

  return (
    <>
      <Downshift
        onInputValueChange={(tag, state) => {
          if (tag === state.selectedItem) {
            return;
          }

          setTypedTag(tag);
        }}
        inputValue={typedTag}
        onSelect={(tag) => {
          handleSubmit(tag);
        }}
      >
        {({
          getInputProps,
          getMenuProps,
          isOpen,
          inputValue,
          getItemProps,
          highlightedIndex
        }) => {
          const filteredTags = (siteContext.data.tags || []).filter((t) => {
            if (_tags.includes(t)) {
              return false;
            }

            return (
              inputValue && t.toLowerCase().includes(inputValue.toLowerCase())
            );
          });

          return (
            <div>
              <FauxTextInput ref={positioning.setTargetRef}>
                {_tags.length > 0 && inline && (
                  <Blips inline={inline}>
                    {_tags.map((t) => (
                      <Blip key={t} inline={inline}>
                        {t}
                        <button
                          aria-label={`Fjern tag ${t}`}
                          onClick={() => {
                            handleSetTags(_tags.filter((tt) => tt !== t));
                          }}
                        >
                          <AiOutlineClose />
                        </button>
                      </Blip>
                    ))}
                  </Blips>
                )}
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <Input>
                    <input
                      {...getInputProps()}
                      readOnly={readOnly}
                      placeholder={placeholder}
                    />
                  </Input>
                </form>
              </FauxTextInput>
              {isOpen &&
                (filteredTags.length > 0 || (typedTag && !tagMatch)) &&
                createPortal(
                  <Absolute
                    ref={positioning.setSelfRef}
                    zIndex={zIndex}
                    style={{
                      top: positioning.top,
                      left: positioning.left,
                      width: positioning.width
                    }}
                  >
                    <Elements background {...getMenuProps()}>
                      {!tagMatch && !disableTagCreation && (
                        <Element
                          rightIcon={<AiOutlinePlus />}
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          Legg til{" "}
                          <strong>
                            <em>{typedTag}</em>
                          </strong>
                        </Element>
                      )}
                      {filteredTags.map((t, k) => (
                        <Element
                          {...getItemProps({
                            key: t,
                            index: k + 1,
                            item: t,
                            className:
                              highlightedIndex === k + 1 ? "active" : undefined
                          })}
                        >
                          {t}
                        </Element>
                      ))}
                    </Elements>
                  </Absolute>,
                  document.body
                )}
            </div>
          );
        }}
      </Downshift>
      {_tags.length > 0 && !inline && (
        <Blips inline={inline}>
          {_tags.map((t) => (
            <Blip key={t} inline={inline}>
              {t}
              <button
                aria-label={`Fjern tag ${t}`}
                onClick={() => {
                  handleSetTags(_tags.filter((tt) => tt !== t));
                }}
              >
                <AiOutlineClose />
              </button>
            </Blip>
          ))}
        </Blips>
      )}
    </>
  );
};

export default Tagger;
