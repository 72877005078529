import { App } from "../types/apiResponses";
import { useSearch } from "./useApi";
import useApiUrl from "./useApiUrl";

interface UseAppsInterface {
  apps: App[];
  loading: boolean;
}

function useApps(): UseAppsInterface {
  const url = useApiUrl(["apps"]);

  const fetch = useSearch<App>({
    endpoint: url,
    fetchOnMount: true
  });

  return {
    apps: fetch.results,
    loading: fetch.loading
  };
}

export default useApps;
