import React, { useMemo } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";

interface Props
  extends RouteComponentProps<{
    app: string;
  }> {}

const Authorize: React.FC<Props> = (props) => {
  const params = useQueryParams<{ scope?: string; redirect_uri: string }>();
  const scopes = useMemo<string[]>(() => {
    if (params.scope) {
      return params.scope.split(",");
    }

    return [];
  }, [params]);

  return (
    <>
      <h1>Authorize {props.match.params.app}</h1>
      <h2>Scopes</h2>
      <ul>
        {scopes.map((s) => (
          <li key={s}>{s}</li>
        ))}
      </ul>
      <p>
        <ul>
          <li>
            <a href={params.redirect_uri}>YES</a>
          </li>
          <li>
            <Link to="/marked">Nei</Link>
          </li>
        </ul>
      </p>
    </>
  );
};

export default Authorize;
