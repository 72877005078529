import { useContext } from "react";
import { SiteContext } from "../context/Site";
import { Site } from "../types/apiResponses";

function useSite(): Site {
  const site = useContext(SiteContext).data;

  return site;
}

export default useSite;
