import React, { InputHTMLAttributes } from "react";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

interface InputProps {
  bigger?: boolean;
}

export const baseInputStyles = css`
  border-radius: 8px;
  border: none;
  padding: ${(props) => `0 ${props.theme.spacing.small}`};
  background: ${(props) => props.theme.colors.backgroundInput};
  width: 100%;
  font-size: ${(props) => props.theme.sizes.base};
  font-family: inherit;
  color: ${(props) => props.theme.colors.textPrimary};
  height: 42px;

  ::placeholder {
    color: ${(props) => props.theme.colors.textSecondary};
  }

  &[type="file"] {
    padding-top: ${(props) => props.theme.spacing.xs};
  }
`;

const Input = styled.input<InputProps>`
  ${baseInputStyles}
  display: flex;
  align-items: center;

  ${(props) =>
    props.bigger &&
    css`
      font-size: ${(props) => props.theme.sizes.plusOne};
      padding: 0 ${(props) => props.theme.spacing.medium};
      height: 54px;
      font-family: "Work Sans";
      font-weight: 500;
    `}
`;

const Icon = styled.span`
  position: absolute;
  right: ${(props) => props.theme.spacing.small};
  font-size: ${(props) => props.theme.sizes.plusThree};
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.textPrimary};
`;

interface TextProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ReactNode;
  bigger?: boolean;
}

const TextInput: React.FC<TextProps> = ({ icon, bigger, ...rest }) => {
  return (
    <Wrapper>
      {icon && <Icon>{icon}</Icon>}
      {/* 
  // @ts-ignore */}
      <Input bigger={bigger} {...rest} />
    </Wrapper>
  );
};

export default TextInput;
