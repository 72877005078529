import styled, { css } from "styled-components";
import { baseInputStyles } from "./TextInput";

interface Props {
  useBackgroundVariantColor?: boolean;
  noPadding?: boolean;
}

const DummyInput = styled.div<Props>`
  ${baseInputStyles}

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  min-height: 350px;
  max-width: 100%;

  ${(props) =>
    props.useBackgroundVariantColor &&
    css`
      background-color: ${props.theme.colors.backgroundVariant};
    `}

  ${(props) =>
    props.noPadding &&
    css`
      padding: 0;
    `}
`;

export default DummyInput;
