import { useContext } from "react";
import { Member, Site } from "../types/apiResponses";
import { SiteContext } from "../context/Site";
import getTheme from "../util/getTheme";
import { SiteThemeContext } from "../context/Theme";
import { useApi } from "../hooks/useApi";

function SiteHandler() {
  const siteContext = useContext(SiteContext);
  const themeContext = useContext(SiteThemeContext);

  useApi<Site | null>({
    endpoint: "site",
    initialData: null,
    fetchOnMount: true,
    onSuccess: (site) => {
      if (site) {
        siteContext.setData(site);

        if (site.name !== siteContext.data.name) {
          loadTheme(site.theme.name);
        }
      }
    }
  });

  useApi<Member | null>({
    endpoint: "permissions",
    initialData: null,
    fetchOnMount: true,
    onSuccess: (permissions) => {
      if (permissions) {
        siteContext.setPermissions(permissions);
      }
    }
  });

  async function loadTheme(themeName: string) {
    const theme = await getTheme(themeName);
    themeContext.setData(theme);
  }

  return null;
}

export default SiteHandler;
