import React, { useMemo, useCallback } from "react";
import slugify from "slugify";
import {
  DraggableProvidedDragHandleProps,
  DraggableStateSnapshot
} from "react-beautiful-dnd";
import { AiOutlineDelete } from "react-icons/ai";
import { MdDragHandle } from "react-icons/md";
import { CustomizeOptionType } from "@ludens-reklame/rubics-sdk";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import {
  CustomizeOption as ICustomizeOption,
  CustomizeOptionOption,
  CustomizeOptionCondition
} from "../../../types/apiResponses";
import {
  customizeOptionType,
  operations
} from "../../../constants/localizations";
import InlineEdit from "../../../components/InlineEdit/InlineEdit";
import createInputField from "../../../util/createInputField";
import Block from "../../../style-guide/Block/Block";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import { Button, ButtonList } from "../../../style-guide/Button/Button";
import Text from "../../../style-guide/Text/Text";
import localize from "../../../util/localize";
import Section from "../../../style-guide/Section/Section";
import { Table, Tr, Th, Td } from "../../../style-guide/Table/Table";
import Card from "../../../style-guide/Card/Card";
import { currencyFormat } from "../../../util/intl";
import CrudModal, {
  CrudModalField,
  createCrudModalField
} from "../../../components/CrudModal/CrudModal";
import createId from "../../../util/createId";
import {
  flattenFonts,
  fontsToOptions,
  defaultFonts
} from "../../../components/Fonter/Fonter";
import useSite from "../../../hooks/useSite";
import useTheme from "../../../hooks/useTheme";
import ColorPicker from "../../../components/ColorPicker/ColorPicker";
import Label from "../../../style-guide/Inputs/Label";
import OfflineLister from "../../../components/OfflineLister/OfflineLister";
import Field from "../../../style-guide/Inputs/Field";

interface Props {
  customizeOption: ICustomizeOption;
  customizeOptions: ICustomizeOption[];
  isNew: boolean;
  onChange: (customizeOption: ICustomizeOption) => any;
  onDelete: () => any;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  dragState?: DraggableStateSnapshot;
  readOnly?: boolean;
}

const CustomizeOption: React.FC<Props> = ({
  customizeOption,
  customizeOptions,
  isNew,
  onChange,
  onDelete,
  dragHandleProps,
  dragState,
  readOnly
}) => {
  const { spawnModal } = useModal();

  const fields = useMemo<CrudModalField[]>(() => {
    let metaFields: CrudModalField[] = [];
    let previewFields: CrudModalField[] = [];

    const defaultFields: CrudModalField[] = [
      {
        key: "label",
        render: (payload) =>
          createCrudModalField({
            payload,
            key: "label",
            type: "text",
            label: "Valg",
            required: true
          })
      },
      {
        key: "price",
        render: (payload) =>
          createCrudModalField({
            payload,
            key: "price",
            type: "number",
            label: "Pris",
            value: payload.data.price > 0 ? payload.data.price : undefined,
            onChange: (value) =>
              payload.setField(
                "price",
                value.length > 0 ? parseFloat(value) : 0
              )
          })
      }
    ];

    if (customizeOption.type !== CustomizeOptionType.StringStepper) {
      defaultFields.push({
        key: "default",
        render: (payload) =>
          createCrudModalField({
            payload,
            key: "default",
            type: "boolean",
            label: "Er standardvalg?"
          })
      });
    }

    if (customizeOption.type === CustomizeOptionType.Color) {
      metaFields = [
        {
          key: "meta",
          render: (payload) => (
            <>
              <Label>Farge</Label>
              <ColorPicker
                value={payload.data.meta || ""}
                readOnly={readOnly}
                onChange={(color) => payload.setField("meta", color)}
              />
            </>
          )
        }
      ];
    } else if (customizeOption.type === CustomizeOptionType.StringStepper) {
      metaFields = [
        {
          key: "meta",
          render: (payload) =>
            createCrudModalField({
              payload,
              key: "meta",
              type: "number",
              label: "Minimum antall tegn"
            })
        }
      ];
    }

    if (
      customizeOption.preview &&
      customizeOption.type !== CustomizeOptionType.StringStepper
    ) {
      previewFields = [
        {
          key: "previewImage",
          render: (payload) =>
            createCrudModalField({
              payload,
              key: "previewImage",
              type: "media",
              label: "Bilde for bruk i forhåndsvisning"
            })
        }
      ];
    }

    return [...defaultFields, ...metaFields, ...previewFields];
  }, [customizeOption.preview, customizeOption.type, readOnly]);

  const createOptionModal = useCallback(
    (option?: CustomizeOptionOption, key?: number) => {
      spawnModal(
        <CrudModal
          title="Valg"
          initialData={option || { internalId: createId() }}
          onSubmit={(data) => {
            if (option) {
              onChange({
                ...customizeOption,
                options: customizeOption.options.map((o, k) => {
                  if (k === key) {
                    return data;
                  }

                  return o;
                })
              });
            } else
              onChange({
                ...customizeOption,
                options: [...customizeOption.options, data]
              });
          }}
          fields={fields}
        />
      );
    },
    [customizeOption, fields]
  );

  const createConditionsModal = useCallback(
    (condition?: CustomizeOptionCondition, key?: number) => {
      spawnModal(
        <CrudModal
          title="Vis tilpasningsfelt dersom…"
          initialData={condition || { internalId: createId() }}
          onSubmit={(data) => {
            if (condition) {
              onChange({
                ...customizeOption,
                conditions: (customizeOption.conditions || []).map((c, k) => {
                  if (k === key) {
                    return data;
                  }

                  return c;
                })
              });
            } else
              onChange({
                ...customizeOption,
                conditions: [...(customizeOption.conditions || []), data]
              });
          }}
          fields={[
            {
              key: "targetKey",
              render: (payload) =>
                createCrudModalField({
                  payload,
                  key: "targetKey",
                  type: "select",
                  label: "Mål",
                  required: true,
                  options: customizeOptions
                    .filter((o) => o.key !== customizeOption.key)
                    .map((o) => ({
                      value: o.key,
                      label: o.label
                    }))
                })
            },
            {
              key: "targetOperation",
              render: (payload) =>
                createCrudModalField({
                  payload,
                  key: "targetOperation",
                  type: "select",
                  label: "Operasjon",
                  required: true,
                  options: Object.keys(operations).map((k) => ({
                    value: k,
                    label: operations[k]
                  }))
                })
            },
            {
              key: "targetValue",
              render: (payload) => {
                const target = payload.data.targetKey
                  ? customizeOptions.find(
                      (o) => o.key === payload.data.targetKey
                    )
                  : undefined;

                if (!target) {
                  return null;
                }

                const hasOptions =
                  (target.type === CustomizeOptionType.String &&
                    customizeOption.options.length > 0) ||
                  target.type === CustomizeOptionType.Checkbox ||
                  target.type === CustomizeOptionType.Radio ||
                  target.type === CustomizeOptionType.Stepper ||
                  target.type === CustomizeOptionType.Color;

                return createCrudModalField({
                  payload,
                  key: "targetValue",
                  type: hasOptions ? "select" : "text",
                  label: "Verdi",
                  required: true,
                  options: hasOptions
                    ? (target.options || []).map((o) => ({
                        value: o.label,
                        label: o.label
                      }))
                    : undefined
                });
              }
            }
          ]}
        />
      );
    },
    [customizeOption, customizeOptions]
  );

  const renderOptions = useMemo<boolean>(() => {
    return (
      customizeOption.type === CustomizeOptionType.Checkbox ||
      customizeOption.type === CustomizeOptionType.Radio ||
      customizeOption.type === CustomizeOptionType.Stepper ||
      customizeOption.type === CustomizeOptionType.Color ||
      customizeOption.type === CustomizeOptionType.StringStepper ||
      customizeOption.type === CustomizeOptionType.String
    );
  }, [customizeOption.type]);

  const hasMinMax = useMemo<boolean>(() => {
    return (
      customizeOption.type === CustomizeOptionType.Number ||
      customizeOption.type === CustomizeOptionType.NumberMultiplier ||
      customizeOption.type === CustomizeOptionType.String ||
      customizeOption.type === CustomizeOptionType.StringStepper
    );
  }, [customizeOption.type]);

  const isText = useMemo<boolean>(
    () =>
      customizeOption.type === CustomizeOptionType.String ||
      customizeOption.type === CustomizeOptionType.StringStepper,
    [customizeOption.type]
  );

  const isTextWithOptions = useMemo<boolean>(() => {
    return (
      customizeOption.type === CustomizeOptionType.String &&
      customizeOption.options.length > 0
    );
  }, [customizeOption.type, customizeOption.options.length]);

  return (
    <InlineEdit
      expanded={isNew}
      background={
        dragState && dragState.isDragging ? "backgroundVariant" : undefined
      }
      alignItems="flex-start"
      actions={[
        <Button
          type="button"
          aria-label="Slett"
          circular
          outlined
          smaller
          disabled={readOnly}
          onClick={onDelete}
        >
          <AiOutlineDelete />
        </Button>,
        <Button
          type="button"
          aria-label="Endre rekkefølge"
          outlined
          circular
          smaller
          disabled={readOnly}
          {...dragHandleProps}
        >
          <MdDragHandle />
        </Button>
      ]}
      headerColumns={[
        {
          width: "400px",
          node: (
            <>
              <Text>{customizeOption.label || "Mangler navn"}</Text>
              <Text variant="body3">
                {customizeOption.description || "Mangler beskrivelse"}
              </Text>
            </>
          )
        },
        {
          width: "200px",
          node: (
            <Text variant="body3">
              {localize(customizeOptionType, customizeOption.type)}
            </Text>
          )
        },
        {
          width: "200px",
          node: renderOptions ? (
            customizeOption.options.map((o) => (
              <Flex key={o._id}>
                <FlexKid flex={1}>
                  <Text variant="body3">{o.label}</Text>
                </FlexKid>
                <FlexKid flex={1}>
                  <Text variant="body3" align="right">
                    {o.price ? currencyFormat.format(o.price) : "Ingen"}
                  </Text>
                </FlexKid>
              </Flex>
            ))
          ) : (
            <Text variant="body3">
              {customizeOption.price
                ? currencyFormat.format(customizeOption.price)
                : "Ingen"}
            </Text>
          )
        }
      ]}
    >
      <Block>
        <Flex align="top">
          <FlexKid flex={1}>
            {createInputField({
              key: "label",
              type: "text",
              label: "Navn",
              value: customizeOption.label,
              readOnly,
              required: true,
              hugTop: true,
              autoFocus: isNew,
              onChange: (value) => {
                if (!isNew) {
                  onChange({
                    ...customizeOption,
                    label: value
                  });
                } else {
                  onChange({
                    ...customizeOption,
                    label: value,
                    key: slugify(value, { lower: true })
                  });
                }
              }
            })}
            {createInputField({
              key: "type",
              label: "Type",
              type: "select",
              readOnly,
              value: customizeOption.type,
              options: Object.keys(customizeOptionType).map((k) => ({
                label: customizeOptionType[k],
                value: k
              })),
              onChange: (value) => {
                onChange({
                  ...customizeOption,
                  type: value,
                  price: 0
                });
              }
            })}
            {createInputField({
              key: "description",
              label: "Beskrivelse",
              type: "textarea",
              readOnly,
              value: customizeOption.description,
              onChange: (value) => {
                onChange({
                  ...customizeOption,
                  description: value
                });
              }
            })}
            {hasMinMax && !isTextWithOptions && (
              <>
                {createInputField({
                  key: "min",
                  label: isText ? "Minimum antall tegn" : "Minimumsverdi",
                  type: "number",
                  readOnly,
                  value: customizeOption.min,
                  onChange: (value) => {
                    onChange({
                      ...customizeOption,
                      min: value
                    });
                  }
                })}
                {!isTextWithOptions &&
                  createInputField({
                    key: "max",
                    label: isText ? "Maks antall tegn" : "Maksverdi",
                    type: "number",
                    readOnly,
                    value: customizeOption.max,
                    onChange: (value) => {
                      onChange({
                        ...customizeOption,
                        max: value
                      });
                    }
                  })}
              </>
            )}
            {!renderOptions &&
              createInputField({
                key: "defaultValue",
                label: "Standardverdi",
                type: "text",
                readOnly,
                value: customizeOption.defaultValue,
                onChange: (value) => {
                  onChange({
                    ...customizeOption,
                    defaultValue: value
                  });
                }
              })}
            {!isTextWithOptions &&
              createInputField({
                key: "placeholder",
                label: "Plassholder",
                type: "text",
                readOnly,
                value: customizeOption.placeholder,
                onChange: (value) => {
                  onChange({
                    ...customizeOption,
                    placeholder: value
                  });
                }
              })}
          </FlexKid>
          <FlexKid flex={1} spaceLeft>
            {createInputField({
              key: "key",
              label: "Systemnavn",
              type: "text",
              value: customizeOption.key,
              hugTop: true,
              readOnly: true,
              disabled: true,
              onChange: () => {}
            })}
            {!renderOptions &&
              createInputField({
                key: "price",
                label: "Pris",
                type: "number",
                readOnly,
                value: customizeOption.price,
                onChange: (value) => {
                  onChange({
                    ...customizeOption,
                    price: value
                  });
                }
              })}
            {createInputField({
              key: "preview",
              label: "Bruk forhåndsvisning",
              type: "boolean",
              readOnly,
              value: customizeOption.preview,
              onChange: (value) => {
                onChange({
                  ...customizeOption,
                  preview: value
                });
              }
            })}
            {customizeOption.preview && (
              <>
                {createInputField({
                  key: "previewX",
                  label: "X-verdi (px)",
                  type: "number",
                  readOnly,
                  value: customizeOption.previewX,
                  onChange: (value) => {
                    onChange({
                      ...customizeOption,
                      previewX: value
                    });
                  }
                })}
                {createInputField({
                  key: "previewY",
                  label: "Y-verdi (px)",
                  type: "number",
                  readOnly,
                  value: customizeOption.previewY,
                  onChange: (value) => {
                    onChange({
                      ...customizeOption,
                      previewY: value
                    });
                  }
                })}
                {createInputField({
                  key: "previewZ",
                  label: "Z-verdi (px)",
                  type: "number",
                  readOnly,
                  value: customizeOption.previewZ,
                  onChange: (value) => {
                    onChange({
                      ...customizeOption,
                      previewZ: value
                    });
                  }
                })}
                {createInputField({
                  key: "previewScale",
                  label: "Skalering",
                  type: "number",
                  readOnly,
                  value: customizeOption.previewScale,
                  onChange: (value) => {
                    onChange({
                      ...customizeOption,
                      previewScale: value
                    });
                  }
                })}
                {isText && (
                  <DisplayTextOptions
                    customizeOption={customizeOption}
                    onChange={onChange}
                    readOnly={readOnly}
                  />
                )}
                <Field>
                  <Label>Lenkede tilpasningsfelter</Label>
                  <OfflineLister
                    selectLabel="Tilpasningsfelt"
                    items={customizeOptions.map((o) => ({
                      _id: o.key,
                      value: o.key,
                      label: o.label
                    }))}
                    values={(customizeOption.links || []).map(
                      (l) => l.targetKey
                    )}
                    onChange={(values) =>
                      onChange({
                        ...customizeOption,
                        links: values.map((v) => ({
                          targetKey: v
                        }))
                      })
                    }
                  />
                </Field>
              </>
            )}
          </FlexKid>
        </Flex>
        {renderOptions && (
          <Section hugBottom tight>
            <Section tight>
              <Flex>
                <FlexKid flex={1}>
                  <Text>Valg</Text>
                </FlexKid>
                {!readOnly && (
                  <FlexKid>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        createOptionModal();
                      }}
                    >
                      + Nytt valg
                    </a>
                  </FlexKid>
                )}
              </Flex>
            </Section>
            <Card outlined>
              <Table>
                <thead>
                  <Tr>
                    <Th>Valg</Th>
                    <Th align="right">Pris</Th>
                    <Th align="right">Handlinger</Th>
                  </Tr>
                </thead>
                <tbody>
                  {customizeOption.options.length > 0 ? (
                    customizeOption.options.map((o, k) => (
                      //@ts-ignore
                      <Tr key={o._id || o.internalId}>
                        <Td verticalAlign="middle">{o.label}</Td>
                        <Td verticalAlign="middle" align="right">
                          <Text variant="body3" align="right">
                            {o.price ? currencyFormat.format(o.price) : "Ingen"}
                          </Text>
                        </Td>
                        <Td verticalAlign="middle" align="right">
                          <ButtonList align="right">
                            <Button
                              type="button"
                              outlined
                              disabled={readOnly}
                              onClick={() => createOptionModal(o, k)}
                            >
                              Rediger
                            </Button>
                            <Button
                              type="button"
                              outlined
                              disabled={readOnly}
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Er du sikker på at du vil slette?"
                                  )
                                ) {
                                  onChange({
                                    ...customizeOption,
                                    options: customizeOption.options.filter(
                                      (_, kk) => kk !== k
                                    )
                                  });
                                }
                              }}
                            >
                              Slett
                            </Button>
                          </ButtonList>
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan={3}>
                        <Text variant="subheading">Ingen valg enda</Text>
                      </Td>
                    </Tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </Section>
        )}
        <Section tight>
          <Section tight>
            <Flex>
              <FlexKid flex={1}>
                <Text>Vilkår for visning</Text>
              </FlexKid>
              {!readOnly && (
                <FlexKid>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      createConditionsModal();
                    }}
                  >
                    + Nytt vilkår
                  </a>
                </FlexKid>
              )}
            </Flex>
          </Section>
          <Card outlined>
            <Table>
              <thead>
                <Tr>
                  <Th>Vilkår</Th>
                  <Th align="right">Handlinger</Th>
                </Tr>
              </thead>
              <tbody>
                {(customizeOption.conditions || []).length > 0 ? (
                  (customizeOption.conditions || []).map((c, k) => (
                    //@ts-ignore
                    <Tr key={c._id || c.internalId}>
                      <Td verticalAlign="middle">
                        {c.targetKey}{" "}
                        {localize(
                          operations,
                          c.targetOperation || "eq"
                        ).toLowerCase()}{" "}
                        {c.targetValue}
                      </Td>
                      <Td verticalAlign="middle" align="right">
                        <ButtonList align="right">
                          <Button
                            type="button"
                            outlined
                            disabled={readOnly}
                            onClick={() => createConditionsModal(c, k)}
                          >
                            Rediger
                          </Button>
                          <Button
                            type="button"
                            outlined
                            disabled={readOnly}
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Er du sikker på at du vil slette?"
                                )
                              ) {
                                onChange({
                                  ...customizeOption,
                                  conditions: (
                                    customizeOption.conditions || []
                                  ).filter((_, kk) => kk !== k)
                                });
                              }
                            }}
                          >
                            Slett
                          </Button>
                        </ButtonList>
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={2}>
                      <Text variant="subheading">Ingen vilkår enda</Text>
                    </Td>
                  </Tr>
                )}
              </tbody>
            </Table>
          </Card>
        </Section>
      </Block>
    </InlineEdit>
  );
};

interface DisplayTextOptionsProps {
  customizeOption: ICustomizeOption;
  onChange: (customizeOption: ICustomizeOption) => any;
  readOnly?: boolean;
}

const DisplayTextOptions: React.FC<DisplayTextOptionsProps> = ({
  customizeOption,
  onChange,
  readOnly
}) => {
  const site = useSite();
  const theme = useTheme();

  const flattenedFonts = useMemo(
    () => (theme ? flattenFonts(theme.defaultFonts, site.fonts) : []),
    [theme, site.fonts]
  );

  const fontOptions = useMemo(
    () => [...fontsToOptions(flattenedFonts), ...defaultFonts],
    [flattenedFonts]
  );

  return (
    <>
      {createInputField({
        key: "previewFontFamily",
        label: "Fontfamilie",
        type: "select",
        readOnly,
        value: customizeOption.previewFontFamily,
        options: fontOptions,
        onChange: (value) => {
          onChange({
            ...customizeOption,
            previewFontFamily: value
          });
        }
      })}
      {createInputField({
        key: "previewFontSize",
        label: "Fontstørrelse (px)",
        type: "number",
        readOnly,
        value: customizeOption.previewFontSize,
        onChange: (value) => {
          onChange({
            ...customizeOption,
            previewFontSize: value
          });
        }
      })}
      {createInputField({
        key: "previewFontAutoScale",
        label: "Autoskaler fontstørrelse",
        type: "boolean",
        readOnly,
        value: customizeOption.previewFontAutoScale,
        onChange: (value) => {
          onChange({
            ...customizeOption,
            previewFontAutoScale: value
          });
        }
      })}
      {customizeOption.previewFontAutoScale &&
        createInputField({
          key: "previewFontCharactersPerLine",
          label: "Tegn per linje",
          type: "number",
          readOnly,
          value: customizeOption.previewFontCharactersPerLine,
          required: true,
          onChange: (value) => {
            onChange({
              ...customizeOption,
              previewFontCharactersPerLine: value
            });
          }
        })}
      {createInputField({
        key: "previewFontWeight",
        label: "Fontvekt",
        type: "number",
        readOnly,
        value: customizeOption.previewFontWeight,
        onChange: (value) => {
          onChange({
            ...customizeOption,
            previewFontWeight: value
          });
        }
      })}
      {createInputField({
        key: "previewFontColor",
        label: "Tekstfarge",
        type: "text",
        readOnly,
        value: customizeOption.previewFontColor,
        onChange: (value) => {
          onChange({
            ...customizeOption,
            previewFontColor: value
          });
        }
      })}
      {createInputField({
        key: "previewFontTextAlign",
        label: "Tekstjustering",
        type: "select",
        options: [
          {
            value: "start",
            label: "Venstre"
          },
          {
            value: "end",
            label: "Høyre"
          },
          {
            value: "middle",
            label: "Midtstilt"
          }
        ],
        readOnly,
        value: customizeOption.previewFontTextAlign,
        onChange: (value) => {
          onChange({
            ...customizeOption,
            previewFontTextAlign: value
          });
        }
      })}
      {createInputField({
        key: "previewFontTextTransform",
        label: "Tekst-transformering",
        type: "select",
        options: [
          {
            value: "uppercase",
            label: "Store bokstaver"
          },
          {
            value: "lowercase",
            label: "Små bokstaver"
          },
          {
            value: "capitalize",
            label: "Store forbokstaver"
          }
        ],
        readOnly,
        value: customizeOption.previewFontTextTransform,
        onChange: (value) => {
          onChange({
            ...customizeOption,
            previewFontTextTransform: value
          });
        }
      })}
    </>
  );
};

export default CustomizeOption;
