import React from "react";
import { UsePublisherInterface } from "../../../../hooks/usePublisher";
import { BookingResource } from "../../../../types/apiResponses";
import Fader from "../../../../style-guide/Fader/Fader";
import { Flex, FlexKid } from "../../../../style-guide/Flex/Flex";
import Section from "../../../../style-guide/Section/Section";
import Card from "../../../../style-guide/Card/Card";
import Block from "../../../../style-guide/Block/Block";
import Text from "../../../../style-guide/Text/Text";
import createPublisherInputField from "../../../../util/createPublisherInputField";
import { Availability } from "../Service/General";

interface Props {
  publisher: UsePublisherInterface<BookingResource>;
}

const General: React.FC<Props> = ({ publisher }) => {
  return (
    <Fader>
      <Flex align="flex-start">
        <FlexKid flex={1}>
          <Card>
            <Block>
              <Section hugTop>
                <Text element="h2" variant="title">
                  Generelt
                </Text>
              </Section>
              {createPublisherInputField(publisher, {
                path: "name",
                label: "Navn",
                type: "text",
                required: true
              })}
              {createPublisherInputField(publisher, {
                path: "description",
                label: "Beskrivelse",
                type: "textarea"
              })}
              {createPublisherInputField(publisher, {
                path: "email",
                label: "E-postadresse",
                type: "email"
              })}
              {createPublisherInputField(publisher, {
                path: "slots",
                label: "Kapasitet",
                type: "number",
                min: 1
              })}
            </Block>
          </Card>
        </FlexKid>
        <FlexKid flex={1} spaceLeft>
          <Availability publisher={publisher} />
        </FlexKid>
      </Flex>
    </Fader>
  );
};

export default General;
