import React, { useRef, useState } from "react";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import Form from "../../../style-guide/Inputs/Form";
import {
  Modal,
  ModalBody,
  ModalActions
} from "../../../style-guide/Modal/Modal";
import Block from "../../../style-guide/Block/Block";
import { ButtonList, Button } from "../../../style-guide/Button/Button";
import { BundleContentEntry, Product } from "../../../types/apiResponses";
import ProductPicker, {
  ProductTable
} from "../../../components/ProductPicker/ProductPicker";
import Text from "../../../style-guide/Text/Text";
import { ProductType } from "@ludens-reklame/rubics-sdk";

interface Props {
  bundleContent: BundleContentEntry[];
  onSelect: (products: Product[]) => any;
}

const BundleProductSearchModal: React.FC<Props> = ({
  bundleContent,
  onSelect
}) => {
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);

  const modalBodyRef = useRef<HTMLElement | null>(null);
  const { despawnModal } = useModal();

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSelect(selectedProducts);
        despawnModal();
      }}
    >
      <Modal fullscreen>
        <ModalBody ref={(ref) => (modalBodyRef.current = ref)}>
          <Block>
            <Text variant="title" gutterBottom>
              Finn produkter som skal legges til i pakken
            </Text>
            <ProductPicker
              useWindow={false}
              getScrollParent={() => modalBodyRef.current}
              queryParams={{ draft: false }}
              filter={(p) =>
                !p.bundle &&
                p.type !== ProductType.VariantShell &&
                !bundleContent.some((c) => {
                  if (!c.product) {
                    return false;
                  }

                  if (typeof c.product === "string") {
                    return c.product === p._id;
                  }

                  return c.product._id === p._id;
                })
              }
              renderView={(products) => (
                <ProductTable
                  products={products}
                  multipleSelect
                  onSelect={(products) => setSelectedProducts(products)}
                />
              )}
            />
          </Block>
        </ModalBody>
        <ModalActions>
          <ButtonList align="right">
            <Button
              type="button"
              outlined
              onClick={(e) => {
                despawnModal();
              }}
            >
              Avbryt
            </Button>
            <Button type="submit" disabled={selectedProducts.length < 1}>
              Legg til produkter
            </Button>
          </ButtonList>
        </ModalActions>
      </Modal>
    </Form>
  );
};

export default BundleProductSearchModal;
