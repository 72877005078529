import React, { useState, ReactNode, useEffect, useRef } from "react";
import objectPath from "object-path";
import isPlainObject from "is-plain-object";
import { useHotkeys } from "react-hotkeys-hook";
import { FaGripLines } from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai";
import {
  ComponentProp,
  Theme,
  RubicsImage,
  RubicsGallery
} from "@ludens-reklame/rubics-sdk";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import useForm, { PrefillFn, UseFormInterface } from "../../hooks/useForm";
import useTheme from "../../hooks/useTheme";
import useSite from "../../hooks/useSite";
import Form from "../../style-guide/Inputs/Form";
import { Site } from "../../types/apiResponses";
import Section from "../../style-guide/Section/Section";
import Field from "../../style-guide/Inputs/Field";
import Label from "../../style-guide/Inputs/Label";
import Validation from "../../style-guide/Inputs/Validation";
import SearchAndSelect from "../SearchAndSelect/SearchAndSelect";
import Select from "../../style-guide/Inputs/Select";
import TextArea from "../../style-guide/Inputs/TextArea";
import TextInput from "../../style-guide/Inputs/TextInput";
import { Element, Elements } from "../../style-guide/Elements/Elements";
import getIcon from "../../util/getIcon";
import { Dropper, Dragger } from "../DnD/DnD";
import createId from "../../util/createId";
import { Modal, ModalBody, ModalActions } from "../../style-guide/Modal/Modal";
import Block from "../../style-guide/Block/Block";
import { ButtonList, Button } from "../../style-guide/Button/Button";
import Text from "../../style-guide/Text/Text";
import Drafter from "../Drafter/Drafter";
import ColorPicker from "../ColorPicker/ColorPicker";
import useCopyPaste from "../../hooks/useCopyPaste";
import MediaPicker from "../MediaPicker/MediaPicker";
import InlineEdit from "../InlineEdit/InlineEdit";
import { FlexKid, Flex } from "../../style-guide/Flex/Flex";
import useRevisionId from "../../hooks/useRevisionId";
import Fonter from "../Fonter/Fonter";
import { RequestData } from "../../util/api";
import CodeEditor from "../CodeEditor/CodeEditor";
import ProductSelector from "../ProductSelector/ProductSelector";
import Linker from "../Linker/Linker";
import PageSelector from "../PageSelector/PageSelector";
import moment from "moment";
import Gallery from "../Gallery/Gallery";

export interface GenericData {
  [key: string]: GenericData;
}

interface Props {
  props: ComponentProp[];
  method?: string;
  endpoint?: string;
  revision?: string;
  prefillEndpoint?: string;
  prefillFn?: PrefillFn;
  pathPrefix?: string;
  previewPathPrefix?: string;
  level?: number;
  arrayerLevel?: number;
  form?: UseFormInterface<any>;
  defaultValues?: GenericData;
  diff?: boolean;
  onSubmit?: (resource: any) => any;
  parent?: string;
  dontAutoSave?: boolean;
  refreshHash?: string;
  onLoad?: (loading: boolean) => any;
  queryParams?: RequestData;
  prefillQueryParams?: RequestData;
  disableCopyPaste?: boolean;
  dontCreateFormElement?: boolean;
}

const Putter: React.FC<Props> = ({
  endpoint,
  revision,
  props,
  method,
  prefillEndpoint,
  prefillFn,
  pathPrefix,
  previewPathPrefix,
  level,
  arrayerLevel,
  onSubmit,
  defaultValues,
  form,
  diff,
  parent,
  dontAutoSave,
  refreshHash,
  onLoad,
  queryParams,
  prefillQueryParams,
  disableCopyPaste,
  dontCreateFormElement
}) => {
  const site = useSite();
  const theme = useTheme();
  const _revision = useRevisionId();
  const usedFormRef = useRef<UseFormInterface<GenericData>>();

  const internalForm = useForm<GenericData>(
    {},
    {
      endpoint: endpoint || "",
      method,
      prefillEndpoint,
      prefillQueryParams:
        revision || _revision
          ? {
              revision: revision || _revision
            }
          : prefillQueryParams,
      prefillFn,
      onSuccess: onSubmit,
      replaceData: true,
      queryParams,
      diff
    }
  );

  const usedForm = form || internalForm;
  const pathPrefixHandled = pathPrefix ? pathPrefix.split(".") : [];
  const previewPrefixHandled = previewPathPrefix
    ? previewPathPrefix.split(".")
    : pathPrefixHandled;

  usedFormRef.current = usedForm;

  useEffect(() => {
    if (refreshHash) {
      usedForm.refresh();
    }
  }, [refreshHash]);

  useEffect(() => {
    if (parent) {
      usedForm.setField({
        path: "parent",
        value: parent
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parent]);

  useEffect(() => {
    if (typeof onLoad === "function") {
      onLoad(usedForm.submitting);
    }
  }, [onLoad, usedForm.submitting]);

  const baseData = pathPrefix ? usedForm.getValue(pathPrefix) : usedForm.data;
  const previewData = previewPathPrefix
    ? usedForm.getValue(previewPathPrefix)
    : undefined;

  const copyPaste = useCopyPaste({
    pathsToCopy: props.map((p) => ({
      path: p.name,
      label: p.label
    })),
    onPaste: (data, previewData) => {
      const _form = usedFormRef.current;

      if (_form) {
        if (pathPrefix && previewPathPrefix && previewData) {
          usedForm.setData({
            data: {
              parent,
              [pathPrefix]: { ..._form.data[pathPrefix], ...data },
              [previewPathPrefix]: {
                ..._form.data[previewPathPrefix],
                ...previewData
              }
            },
            submit: !dontAutoSave
          });
        } else if (pathPrefix) {
          const value = _form.getValue(pathPrefix) || {};

          _form.setField({
            path: pathPrefix,
            value: { ...value, ...data },
            submit: !dontAutoSave
          });
        } else {
          _form.setData({
            data: { ...baseData, ...data },
            submit: !dontAutoSave
          });
        }
      }
    }
  });

  useHotkeys(
    "c",
    () => {
      if (disableCopyPaste) {
        return;
      }

      copyPaste.copy(baseData, previewData);
    },
    {},
    [baseData, previewData, disableCopyPaste]
  );

  useHotkeys(
    "v",
    () => {
      if (disableCopyPaste) {
        return;
      }

      copyPaste.paste();
    },
    [disableCopyPaste]
  );

  if (!theme) {
    return null;
  }

  const renderInputs = props
    .filter((f) => f.editable !== false)
    .filter((p) => {
      if (typeof p.showFn === "function") {
        if (!baseData) {
          return false;
        }

        return p.showFn(baseData);
      }

      return true;
    })
    .map((p) => {
      const defaultValue =
        defaultValues && p.name in defaultValues
          ? defaultValues[p.name]
          : undefined;

      return renderProp(
        p,
        pathPrefixHandled,
        previewPrefixHandled,
        usedForm,
        site,
        theme,
        arrayerLevel || 1,
        !!revision || !!_revision,
        level,
        defaultValue,
        onSubmit,
        dontAutoSave,
        onLoad,
        queryParams,
        prefillQueryParams
      );
    });

  if (dontCreateFormElement) {
    return <>{renderInputs}</>;
  } else {
    return (
      <Form action="#" onSubmit={usedForm.submit}>
        {renderInputs}
      </Form>
    );
  }
};

function renderProp(
  prop: ComponentProp,
  path: string[],
  previewPath: string[],
  form: UseFormInterface<any>,
  site: Site,
  theme: Theme,
  arrayerLevel: number,
  isRevision: boolean,
  level?: number,
  defaultValue?: any,
  onSubmit?: (resource: any) => any,
  dontAutoSave?: boolean,
  onLoad?: (loading: boolean) => any,
  queryParams?: RequestData,
  prefillQueryParams?: RequestData
) {
  const appendedPath = [...path, prop.name];
  const appendedPreviewPath = [...previewPath, prop.name];

  if (prop.type === "object") {
    return (
      <SubLevel
        key={prop.name}
        form={form}
        site={site}
        theme={theme}
        prop={prop}
        path={appendedPath}
        previewPath={appendedPreviewPath}
        level={level ? level + 1 : 1}
        arrayerLevel={arrayerLevel}
        isRevision={isRevision}
        defaultValues={defaultValue as GenericData}
        onSubmit={onSubmit}
        dontAutoSave={dontAutoSave}
        onLoad={onLoad}
        queryParams={queryParams}
        prefillQueryParams={prefillQueryParams}
      />
    );
  }

  const pathString = appendedPath.join(".");
  const previewPathString = appendedPreviewPath.join(".");
  const value = form.getValue(pathString);
  const validation =
    typeof prop.validationFn === "function"
      ? prop.validationFn(value)
      : undefined;

  return (
    <Field key={prop.name} hugBottom>
      <Label htmlFor={prop.name}>{prop.label}</Label>
      {prop.required && !value && <Validation>Feltet må fylles ut.</Validation>}
      {!!validation && !validation.validated && (
        <Validation>{validation.message}</Validation>
      )}
      {renderField(
        prop,
        pathString,
        previewPathString,
        form,
        site,
        theme,
        arrayerLevel,
        isRevision,
        defaultValue,
        onSubmit,
        dontAutoSave,
        onLoad,
        queryParams,
        prefillQueryParams
      )}
    </Field>
  );
}

function renderField(
  prop: ComponentProp,
  path: string,
  previewPath: string,
  form: UseFormInterface<any>,
  site: Site,
  theme: Theme,
  arrayerLevel: number,
  isRevision: boolean,
  defaultValue?: any,
  onSubmit?: (resource: any) => any,
  dontAutoSave?: boolean,
  onLoad?: (loading: boolean) => any,
  queryParams?: RequestData,
  prefillQueryParams?: RequestData
) {
  switch (prop.type) {
    case "font":
      return (
        <Fonter
          prop={prop}
          path={path}
          form={form}
          site={site}
          theme={theme}
          defaultValue={defaultValue}
        />
      );
    case "draft":
      return (
        <Drafter
          key={form.syncState}
          data={form.getValue(path)}
          readOnly={isRevision}
          onChange={(data) =>
            form.setField({
              path,
              value: data,
              submit: !dontAutoSave,
              debounce: true
            })
          }
        />
      );
    case "color":
      return (
        <ColorPicker
          id={prop.name}
          value={form.getValue(path) || ""}
          readOnly={isRevision}
          onDone={(color) =>
            form.setField({
              path,
              value: color,
              submit: !dontAutoSave
            })
          }
        />
      );
    case "refId":
    case "ref":
      if (!prop.to) {
        return null;
      }

      const previewValue = form.getValue(previewPath);

      let url: string[] = [];
      let searchKey = "";
      let labelKey = prop.to === "product" ? "name" : "title";
      let query: { [key: string]: any } = {};
      let dataset =
        prop.to === "dataset"
          ? (theme.dataSets || []).find((d) => d.name === prop.dataset)
          : undefined;

      if (prop.to === "product") {
        return (
          <ProductSelector
            product={previewValue}
            queryParams={{
              draft: false,
              fetchInherited: true
            }}
            readOnly={isRevision}
            onChange={(product) => {
              if (product) {
                form.setField({
                  path,
                  value: product._id,
                  submit: !dontAutoSave
                });
              } else {
                form.setField({
                  path,
                  value: null,
                  submit: !dontAutoSave
                });
              }
            }}
          />
        );
      } else if (prop.to === "page:all" || prop.to === "page:page") {
        return (
          <PageSelector
            page={previewValue}
            queryParams={{
              draft: false,
              isProductPage: false,
              fetchInherited: true
            }}
            readOnly={isRevision}
            onChange={(page) => {
              if (page) {
                form.setField({
                  path,
                  value: page._id,
                  submit: !dontAutoSave
                });
              } else {
                form.setField({
                  path,
                  value: null,
                  submit: !dontAutoSave
                });
              }
            }}
          />
        );
      } else {
        switch (prop.to) {
          //@ts-ignore Is here for backwards compatibility. Can be removed once checked that it isn't used
          case "page:category":
            url = ["pages"];
            searchKey = "title";
            query = { draft: false, isCategory: true };

            break;
          case "dataset":
            if (dataset) {
              url = ["data-sets", dataset.name];
              searchKey = "text";
            }

            break;
          case "bookingService":
            url = ["booking", "services"];
            searchKey = "name";
            labelKey = "name";

            break;
          default:
            url = ["pages"];
            searchKey = "title";
            query = { draft: false };

            //@ts-ignore Is here for backwards compatibility. Can be removed once checked that it isn't used
            if (prop.to === "page:page" || prop.to === "page:parent") {
              query.isCategory = false;
              query.isProductPage = false;
            }

            break;
        }

        return (
          <SearchAndSelect
            url={url}
            searchKey={searchKey}
            readOnly={isRevision}
            query={query}
            itemToString={(item) => {
              if (item) {
                if (prop.to === "dataset") {
                  const dataSet = (theme.dataSets || []).find(
                    (d) => d.name === prop.dataset
                  );

                  if (dataSet) {
                    return dataSet.previewFn(item).title;
                  }
                } else {
                  return item[labelKey];
                }
              }

              return "";
            }}
            value={
              previewValue
                ? prop.to === "dataset" && dataset
                  ? dataset.previewFn(previewValue).title
                  : previewValue[labelKey]
                : ""
            }
            onChange={(item) => {
              if (item) {
                form.setField({
                  path,
                  value: item._id,
                  submit: !dontAutoSave
                });
              } else {
                form.setField({
                  path,
                  value: null,
                  submit: !dontAutoSave
                });
              }
            }}
          />
        );
      }
    case "array":
      return (
        <Arrayer
          prop={prop}
          form={form}
          path={path}
          previewPath={previewPath}
          onSubmit={onSubmit}
          level={arrayerLevel}
          isRevision={isRevision}
          dontAutoSave={dontAutoSave}
          onLoad={onLoad}
          queryParams={queryParams}
          prefillQueryParams={prefillQueryParams}
        />
      );
    case "file":
    case "image":
      const isFile = prop.type === "file";
      const value = form.getValue(previewPath) as RubicsImage | undefined;

      return (
        <MediaPicker
          key={form.data.parent}
          onChange={(image) => {
            let internalImage: RubicsImage | null = image;

            if (image) {
              internalImage = { ...image };
              //@ts-ignore
              delete internalImage.url;
            }

            form.setField({
              path,
              value: internalImage,
              submit: !dontAutoSave,
              debounce: true
            });
          }}
          value={value}
          readOnly={isRevision}
          editableAlt={!isFile}
          editableCaption={!isFile}
          editableTransforms={!isFile}
          editableLazyLoad={!isFile}
          isFile={isFile}
          minimize
        />
      );
    case "link":
      return (
        <Linker
          title={prop.label}
          populatedData={form.getValue(previewPath)}
          onChange={(value) => {
            form.setField({
              path,
              value,
              submit: !dontAutoSave
            });
          }}
        />
      );
    case "form":
      const formValue = form.getValue(previewPath);

      return (
        <SearchAndSelect
          url={["forms"]}
          searchKey="name"
          readOnly={isRevision}
          itemToString={(item) => {
            if (item) {
              return item.name;
            }

            return "";
          }}
          value={formValue ? formValue.name : ""}
          onChange={(item) => {
            if (item) {
              form.setField({
                path,
                value: { ref: item._id },
                submit: !dontAutoSave
              });
            } else {
              form.setField({
                path,
                value: null,
                submit: !dontAutoSave
              });
            }
          }}
        />
      );
    case "boolean":
      return (
        <Select
          id={prop.name}
          disabled={isRevision}
          value={
            typeof form.getValue(path) === "boolean"
              ? form.getValue(path)
                ? "true"
                : "false"
              : "false"
          }
          placeholder={defaultValue}
          onChange={(e) => {
            form.setField({
              path,
              value:
                e.target.value.length > 0 ? e.target.value === "true" : "false",
              submit: !dontAutoSave
            });
          }}
        >
          <option value="true">Ja</option>
          <option value="false">Nei</option>
        </Select>
      );
    case "tag":
      return (
        <Select
          id={prop.name}
          disabled={isRevision}
          value={form.getValue(path) || ""}
          placeholder={defaultValue}
          onChange={(e) => {
            form.setField({
              path,
              value: e.target.value,
              submit: !dontAutoSave
            });
          }}
        >
          <option value="">Ingen</option>
          {site.tags.map((t) => (
            <option key={t} value={t}>
              {t}
            </option>
          ))}
        </Select>
      );
    case "text":
      return (
        <TextArea
          id={prop.name}
          readOnly={isRevision}
          value={form.getValue(path) || ""}
          placeholder={defaultValue}
          onChange={(e) => {
            form.setField({
              path,
              value:
                prop.type === "number" && e.target.value.length > 0
                  ? parseFloat(e.target.value)
                  : e.target.value,
              submit: !dontAutoSave,
              debounce: true
            });
          }}
        />
      );
    case "html":
    case "js":
      return (
        <CodeEditor
          language={prop.type}
          code={form.getValue(path) || ""}
          onChange={(code) =>
            form.setField({
              path,
              value: code,
              submit: !dontAutoSave,
              debounce: true
            })
          }
        />
      );
    case "date":
      return (
        <TextInput
          id={prop.name}
          readOnly={isRevision}
          type={"datetime-local"}
          value={
            form.getValue(path)
              ? moment(form.getValue(path)).format(
                  moment.HTML5_FMT.DATETIME_LOCAL
                )
              : ""
          }
          placeholder={defaultValue}
          onChange={(e) => {
            form.setField({
              path,
              value: e.target.value,
              submit: !dontAutoSave
            });
          }}
        />
      );
    case "gallery":
      const val = form.getValue(previewPath) as RubicsGallery;

      return (
        <Gallery
          value={val}
          readOnly={isRevision}
          onChange={(images) => {
            form.setField({
              path,
              value: images,
              submit: !dontAutoSave
            });
          }}
        />
      );
    default:
      if (
        (Array.isArray(prop.pick) && prop.pick.length > 0) ||
        (Array.isArray(prop.select) && prop.select.length > 0)
      ) {
        const options = prop.pick
          ? prop.pick
          : prop.select!.map((s) => ({
              label: s,
              value: s
            }));

        return (
          <Select
            id={prop.name}
            disabled={isRevision}
            value={form.getValue(path) || ""}
            placeholder={defaultValue}
            onChange={(e) => {
              form.setField({
                path,
                value: e.target.value,
                submit: !dontAutoSave
              });
            }}
          >
            <option value="">Ingen verdi</option>
            {options.map((o) => (
              <option key={o.value} value={o.value}>
                {o.label}
              </option>
            ))}
          </Select>
        );
      }

      return (
        <TextInput
          id={prop.name}
          readOnly={isRevision}
          type={prop.type === "number" ? "number" : "text"}
          value={form.getValue(path) || ""}
          placeholder={defaultValue}
          onChange={(e) => {
            form.setField({
              path,
              value:
                prop.type === "number" && e.target.value.length > 0
                  ? parseFloat(e.target.value)
                  : e.target.value,
              submit: !dontAutoSave,
              debounce: true
            });
          }}
        />
      );
  }
}

interface SubLevelProps {
  prop: ComponentProp;
  site: Site;
  theme: Theme;
  level: number;
  arrayerLevel: number;
  isRevision: boolean;
  path: string[];
  previewPath: string[];
  form: UseFormInterface<any>;
  defaultValues?: GenericData;
  onSubmit?: (resource: any) => any;
  dontAutoSave?: boolean;
  onLoad?: (loading: boolean) => any;
  queryParams?: RequestData;
  prefillQueryParams?: RequestData;
}

const SubLevel: React.FC<SubLevelProps> = ({
  prop,
  site,
  theme,
  level,
  arrayerLevel,
  isRevision,
  path,
  previewPath,
  form,
  defaultValues,
  onSubmit,
  dontAutoSave,
  onLoad,
  queryParams,
  prefillQueryParams
}) => {
  return (
    <Section tight hugBottom>
      <InlineEdit
        isolated
        fullClickExpand
        headerColumns={[
          {
            width: "auto",
            node: (
              <Flex>
                <FlexKid centerContent>{getIcon(prop.icon)}</FlexKid>
                <FlexKid spaceLeft flex={1}>
                  {prop.label}
                </FlexKid>
              </Flex>
            )
          }
        ]}
      >
        <Block hugTop>
          {prop.fields &&
            prop.fields
              .filter((f) => f.editable !== false)
              .filter((f) => {
                if (typeof f.showFn === "function") {
                  if (!prop) {
                    return false;
                  }

                  return f.showFn(prop);
                }

                return true;
              })
              .filter((f) => {
                if (typeof f.showFn === "function") {
                  return f.showFn(form.data);
                }

                return true;
              })
              .map((f) => {
                const defaultValue =
                  defaultValues && f.name in defaultValues
                    ? defaultValues[f.name]
                    : undefined;

                return renderProp(
                  f,
                  path,
                  previewPath,
                  form,
                  site,
                  theme,
                  arrayerLevel,
                  isRevision,
                  level,
                  defaultValue,
                  onSubmit,
                  dontAutoSave,
                  onLoad,
                  queryParams,
                  prefillQueryParams
                );
              })}
        </Block>
      </InlineEdit>
    </Section>
  );
};

interface ArrayerProps {
  prop: ComponentProp;
  form: UseFormInterface<any>;
  path: string;
  previewPath: string;
  level: number;
  isRevision: boolean;
  onSubmit?: (resource: any) => any;
  dontAutoSave?: boolean;
  onLoad?: (loading: boolean) => any;
  queryParams?: RequestData;
  prefillQueryParams?: RequestData;
}

const Arrayer: React.FC<ArrayerProps> = ({
  prop,
  form,
  path,
  previewPath,
  level,
  isRevision,
  onSubmit,
  dontAutoSave,
  onLoad,
  queryParams,
  prefillQueryParams
}) => {
  const { spawnModal } = useModal();
  const elements = form.getValue(path);
  const previewElements = form.getValue(previewPath);

  let index = 0;

  return (
    <Elements>
      <Dropper
        id={"arrayer_" + prop.name}
        onDragEnd={(r) => {
          if (r.destination && Array.isArray(elements)) {
            const list = Array.from((elements as any[]) || []);

            const [removed] = list.splice(r.source.index, 1);

            list.splice(r.destination.index, 0, removed);
            form.setField({
              path,
              value: list,
              submit: !dontAutoSave
            });
          }
        }}
      >
        {Array.isArray(elements) &&
          elements.map((e, k) => {
            ++index;

            const ofProp = Array.isArray(prop.of)
              ? prop.of.find((p) => p.name === e.key)
              : null;

            const previewElement = Array.isArray(previewElements)
              ? previewElements.find((p) => p.id === e.id)
              : null;

            return (
              <Dragger
                key={e.id}
                id={e.id}
                index={k}
                isDragDisabled={isRevision}
              >
                <Element
                  rightIcon={!isRevision && <FaGripLines />}
                  href="#"
                  subText={
                    ofProp
                      ? renderSubtitle(ofProp, previewElement || e)
                      : undefined
                  }
                  onClick={(ev) => {
                    ev.preventDefault();

                    spawnModal(
                      <ArrayerModal
                        level={level}
                        isRevision={isRevision}
                        prop={prop}
                        path={path + "." + k}
                        previewPath={previewPath + "." + k}
                        parentForm={form}
                        editing
                        dontAutoSave={dontAutoSave}
                        queryParams={queryParams}
                        prefillQueryParams={prefillQueryParams}
                        onSubmit={(resource) => {
                          if (typeof form.prefillFn === "function") {
                            const data = form.prefillFn(resource);

                            if (data) {
                              form.setData({ data });
                            }
                          } else {
                            form.setData({ data: resource });
                          }

                          if (typeof onSubmit === "function") {
                            onSubmit(resource);
                          }
                        }}
                        onDeleteClick={() => {
                          form.setField({
                            path,
                            value: (form.getValue(path) as any[]).filter(
                              (_, kk) => kk !== k
                            ),
                            submit: !dontAutoSave
                          });
                        }}
                        onDuplicateClick={(resource) => {
                          form.setField({
                            path,
                            value: [
                              ...(form.getValue(path) as any[]),
                              resource
                            ],
                            submit: !dontAutoSave
                          });
                        }}
                        onLoad={onLoad}
                      />
                    );
                  }}
                >
                  {ofProp ? renderLabel(ofProp, previewElement || e) : "N/A"}
                </Element>
              </Dragger>
            );
          })}
      </Dropper>
      {!isRevision && (
        <Element
          rightIcon={<AiOutlinePlus />}
          href="#"
          onClick={(e) => {
            e.preventDefault();

            const elementPath = path + "." + index;
            const elementPreviewPath = previewPath + "." + index;

            if (Array.isArray(prop.of) && prop.of.length === 1) {
              objectPath.set(form.data, elementPath, {
                id: createId(),
                key: prop.of[0].name
              });
            }

            spawnModal(
              <ArrayerModal
                level={level}
                isRevision={isRevision}
                prop={prop}
                path={elementPath}
                previewPath={elementPreviewPath}
                parentForm={form}
                editing={false}
                dontAutoSave={dontAutoSave}
                onLoad={onLoad}
                queryParams={queryParams}
                prefillQueryParams={prefillQueryParams}
                onSubmit={(resource) => {
                  if (typeof form.prefillFn === "function") {
                    const data = form.prefillFn(resource);

                    if (data) {
                      form.setData({ data });
                    }
                  } else {
                    form.setData({ data: resource });
                  }

                  if (typeof onSubmit === "function") {
                    onSubmit(resource);
                  }
                }}
                onDeleteClick={() => {
                  form.setField({
                    path,
                    value: (form.getValue(path) as any[]).filter(
                      (_, kk) => kk !== index
                    ),
                    submit: !dontAutoSave
                  });
                }}
              />
            );
          }}
        >
          Legg til
        </Element>
      )}
    </Elements>
  );
};

interface ArrayerModalProps {
  prop: ComponentProp;
  path: string;
  previewPath: string;
  parentForm: UseFormInterface<any>;
  level: number;
  isRevision: boolean;
  editing: boolean;
  onSubmit: (resource: any) => any;
  onDeleteClick: () => any;
  onDuplicateClick?: (resource: any) => any;
  dontAutoSave?: boolean;
  onLoad?: (loading: boolean) => any;
  queryParams?: RequestData;
  prefillQueryParams?: RequestData;
}

const ArrayerModal: React.FC<ArrayerModalProps> = ({
  prop,
  path,
  previewPath,
  parentForm,
  level,
  isRevision,
  editing,
  onSubmit,
  onDeleteClick,
  onDuplicateClick,
  dontAutoSave,
  onLoad,
  queryParams,
  prefillQueryParams
}) => {
  const { despawnModal } = useModal();

  let defaultAddingComponent: ComponentProp | undefined = undefined;

  if (Array.isArray(prop.of)) {
    if (editing) {
      const data = parentForm.getValue(path);

      if (isPlainObject(data)) {
        defaultAddingComponent = prop.of.find((p) => p.name === data.key);
      }
    } else if (prop.of.length === 1) {
      defaultAddingComponent = prop.of[0];
    }
  }

  const [addingComponent, setAddingComponent] = useState(
    defaultAddingComponent
  );

  const form = useForm(parentForm.data, {
    method: "PATCH",
    endpoint: parentForm.endpoint,
    onSuccess: onSubmit,
    replaceData: parentForm.replaceData,
    prefillFn: parentForm.prefillFn,
    diff: false,
    queryParams,
    prefillQueryParams
  });

  useEffect(() => {
    if (typeof onLoad === "function") {
      onLoad(form.submitting);
    }
  }, [onLoad, form.submitting]);

  if (!addingComponent) {
    const elements = prop.of || [];

    return (
      <Modal>
        <ModalBody>
          <Block>
            <Elements>
              {elements.map((e) => (
                <Element
                  key={e.name}
                  rightIcon={<AiOutlinePlus />}
                  href="#"
                  onClick={(ev) => {
                    ev.preventDefault();

                    form.setField({
                      path,
                      value: {
                        id: createId(),
                        key: e.name
                      }
                    });

                    setAddingComponent(e);
                  }}
                >
                  {e.label}
                </Element>
              ))}
            </Elements>
          </Block>
        </ModalBody>
        <ModalActions>
          <ButtonList align="right">
            <Button
              type="button"
              alternate
              onClick={() => {
                despawnModal();
              }}
            >
              Avbryt
            </Button>
          </ButtonList>
        </ModalActions>
      </Modal>
    );
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        despawnModal();
      }}
    >
      <Modal>
        <ModalBody>
          <Block hugTop={!addingComponent.label}>
            {addingComponent.label && (
              <Text variant="title">{addingComponent.label}</Text>
            )}
            {!!addingComponent.fields && (
              <Putter
                props={addingComponent.fields}
                form={dontAutoSave ? parentForm : form}
                pathPrefix={path}
                previewPathPrefix={previewPath}
                arrayerLevel={level + 1}
                onSubmit={onSubmit}
                dontAutoSave={dontAutoSave}
                onLoad={onLoad}
                queryParams={queryParams}
                prefillQueryParams={prefillQueryParams}
              />
            )}
          </Block>
        </ModalBody>
        <ModalActions>
          <ButtonList align="right">
            {!editing && Array.isArray(prop.of) && prop.of.length > 1 && (
              <Button
                type="button"
                alternate
                onClick={() => {
                  setAddingComponent(undefined);
                }}
              >
                Tilbake
              </Button>
            )}
            {!isRevision && (
              <Button
                type="button"
                outlined
                onClick={() => {
                  if (window.confirm("Er du sikker på at du vil slette?")) {
                    onDeleteClick();
                    despawnModal();
                  }
                }}
              >
                Slett
              </Button>
            )}
            {!isRevision && typeof onDuplicateClick === "function" && (
              <Button
                type="button"
                outlined
                onClick={() => {
                  onDuplicateClick({
                    ...(form.getValue(path) || {}),
                    id: createId()
                  });

                  despawnModal();
                }}
              >
                Dupliser
              </Button>
            )}
            <Button type="submit">Ferdig</Button>
          </ButtonList>
        </ModalActions>
      </Modal>
    </Form>
  );
};

function renderLabel(prop: ComponentProp, data: any): ReactNode {
  let label = "";

  if (typeof prop.previewFn === "function") {
    label = prop.previewFn(translatePickValues(data, prop)).title;

    if (typeof label === "string" && label.length > 0) {
      label = label.replace(/(<([^>]+)>)/gi, "");

      if (label.length > 75) {
        label = `${label.substring(0, 75)}…`;
      }
    }
  }

  return !!label ? label : prop.label;
}

function renderSubtitle(prop: ComponentProp, data: any): string | undefined {
  if (typeof prop.previewFn === "function") {
    const sub = prop.previewFn(translatePickValues(data, prop)).subtitle;

    if (sub) {
      return sub;
    }
  }

  return undefined;
}

function translatePickValues(input: any, prop: ComponentProp): any {
  const data: any = {};

  if (isPlainObject(input)) {
    for (const key in input) {
      if (Object.prototype.hasOwnProperty.call(input, key)) {
        const value = input[key];
        const propDefinition = prop.fields?.find((p) => p.name === key);

        data[key] = value;

        if (propDefinition && Array.isArray(propDefinition.pick)) {
          const pickEntry = propDefinition.pick.find(
            (pick) => pick.value === value
          );

          if (pickEntry) {
            data[key] = pickEntry.label;
          }
        }
      }
    }
  }

  return data;
}

export default Putter;
