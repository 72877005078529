import React, { ReactNode, useMemo } from "react";
import Text from "../style-guide/Text/Text";

export interface PageMeta {
  singular: string;
  plural: string;
  title: string;
  description: ReactNode;
}

function usePageMeta(type: "page" | "category" | "productPage"): PageMeta {
  return useMemo<PageMeta>(() => {
    if (type === "category") {
      return {
        singular: "kategori",
        plural: "kategorier",
        title: "Kategorier",
        description: (
          <Text>
            Kategorier brukes for å organisere produktene dine i et hierarki.
            For eksempel <em>Klær &gt; Dame &gt; Jakker</em>.
          </Text>
        )
      };
    }

    if (type === "productPage") {
      return {
        singular: "produktside",
        plural: "produktsider",
        title: "Produktsider",
        description: (
          <>
            <Text gutterBottom>
              Produktsider brukes til å lage rikt innhold for et produkt.
            </Text>
            <Text>
              Vanligvis er du begrenset til beskrivelse og utvidet beskrivelse
              når du lager innhold til produkter, men med en produktside får du
              tilgang til Rubics visuelle designer, som gjør det enkelt å lage
              rike innholdsmoduler for et produkt.
            </Text>
          </>
        )
      };
    }

    return {
      singular: "side",
      plural: "sider",
      title: "Sider",
      description: (
        <>
          <Text gutterBottom>Hva er en nettside uten en side? Ingen ting!</Text>
          <Text>
            I Rubics skiller vi ikke på statiske sider, som forside, om
            oss-sider, etc, og artikler. En side er en side, og hvordan den
            fremstår er helt og holdent avhengig av hvilken mal den bruker.
          </Text>
        </>
      )
    };
  }, [type]);
}

export default usePageMeta;
