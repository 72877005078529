import React, { useEffect } from "react";
import { RouteComponentProps, Prompt } from "react-router-dom";
import useBreadcrumbs from "../../../hooks/useBreadcrumbs";
import usePublisher from "../../../hooks/usePublisher";
import { ClientCredential as IClientCredential } from "../../../types/apiResponses";
import Form from "../../../style-guide/Inputs/Form";
import { LEAVE_PAGE_MESSAGE } from "../../../constants/general";
import Fader from "../../../style-guide/Fader/Fader";
import ActionBar from "../../../components/ActionBar/ActionBar";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import Crumb from "../../../components/Crumb/Crumb";
import PublisherHelpers from "../../../util/PublisherHelpers";
import Section from "../../../style-guide/Section/Section";
import Card from "../../../style-guide/Card/Card";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import createPublisherInputField from "../../../util/createPublisherInputField";
import OfflineLister from "../../../components/OfflineLister/OfflineLister";
import Label from "../../../style-guide/Inputs/Label";
import Field from "../../../style-guide/Inputs/Field";
import { scopes } from "../../../constants/localizations";

interface Props
  extends RouteComponentProps<{
    clientCredential: string;
  }> {}

const ClientCredential: React.FC<Props> = ({ match }) => {
  const clientCredentialId = match.params.clientCredential;

  const breadcrumbs = useBreadcrumbs();

  const publisher = usePublisher<IClientCredential>({
    id: clientCredentialId,
    baseUrl: ["client-credentials"],
    baseDashboardUrl: "/innstillinger/tilgangsnokler",
    initialData: {
      label: "",
      scopes: []
    },
    pathsToCopy: [
      {
        path: "label",
        label: "Etikett"
      },
      {
        path: "description",
        label: "Beskrivelse"
      },
      {
        path: "scopes",
        label: "Tilganger"
      }
    ]
  });

  useEffect(() => {
    breadcrumbs.swapEntry(clientCredentialId, publisher.form.data.label);
  }, [breadcrumbs, publisher.form.data, clientCredentialId]);

  return (
    <Form onSubmit={publisher.form.submit}>
      <Prompt
        when={publisher.form.hasMadeChanges}
        message={(location) => {
          if (location.pathname.includes(clientCredentialId)) {
            return true;
          }

          return LEAVE_PAGE_MESSAGE;
        }}
      />
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/innstillinger/tilgangsnokler">Tilgangsnøkler</Crumb>
            {PublisherHelpers.getResourceTitle(
              publisher,
              "label",
              "Ny tilgangsnøkkel"
            )}
          </Text>
        </Block>
        <Section>
          <Flex align="flex-start">
            <FlexKid flex={1}>
              {!publisher.isNew && (
                <Section hugTop>
                  <Card colorTheme="orange">
                    <Block>
                      <Text element="h2" variant="title" gutterBottom>
                        Viktig
                      </Text>
                      <Text gutterBottom>
                        Vær forsiktig med å gjøre endringer i <em>Tilganger</em>
                        . Dette kan føre til at tredjepartsløsninger slutter å
                        fungere som de skal. Det samme gjelder hvis du sletter
                        nøkkelen.
                      </Text>
                      <Text>
                        <em>Etikett</em> og <em>Beskrivelse</em> kan endres uten
                        konsekvenser.
                      </Text>
                    </Block>
                  </Card>
                </Section>
              )}
              <Section hugTop={publisher.isNew}>
                <Card>
                  <Block>
                    <Section hugTop>
                      <Text element="h2" variant="title">
                        Generelt
                      </Text>
                    </Section>
                    {createPublisherInputField(publisher, {
                      path: "label",
                      label: "Etikett",
                      type: "text",
                      required: true
                    })}
                    {createPublisherInputField(publisher, {
                      path: "description",
                      label: "Beskrivelse",
                      type: "textarea"
                    })}
                    <Field hugBottom>
                      <Label>Tilganger</Label>
                      <OfflineLister
                        unique
                        selectLabel="Tilgang"
                        items={Object.keys(scopes).map((s) => ({
                          _id: s,
                          value: s,
                          label: scopes[s]
                        }))}
                        values={publisher.form.data.scopes}
                        onChange={(scopes) =>
                          publisher.form.setField({
                            path: "scopes",
                            value: scopes
                          })
                        }
                      />
                    </Field>
                  </Block>
                </Card>
              </Section>
            </FlexKid>
            <FlexKid flex={1} spaceLeft>
              {!publisher.isNew && (
                <Card outlined>
                  <Block>
                    <Section hugTop>
                      <Text element="h2" variant="title">
                        Nøkkelinformasjon
                      </Text>
                    </Section>
                    {createPublisherInputField(publisher, {
                      path: "clientId",
                      label: "Klient-ID",
                      type: "text",
                      readOnly: true
                    })}
                    {createPublisherInputField(publisher, {
                      path: "clientSecret",
                      label: "Klient-nøkkel",
                      type: "textarea",
                      readOnly: true
                    })}
                  </Block>
                </Card>
              )}
            </FlexKid>
          </Flex>
        </Section>
      </Fader>
      <ActionBar publisher={publisher} destroy />
    </Form>
  );
};

export default ClientCredential;
