import * as React from "react";
import { UserContext } from "../../context/User";
import { BackgroundDrop, Fixed } from "./powersearch.styles";
import PowersearchResults, { PowersearchInfo } from "./PowersearchResults";
import { PowersearchInput } from "./PowersearchInput";
import { PowersearchContext, PowersearchProvider } from "./PowersearchContext";
import { powerSearchKeyboardShortcut } from "./powersearch.utils";

export function PowersearchModal() {
  const user = React.useContext(UserContext);
  const [active, setActive] = React.useState(false);

  const handleOnClosed = React.useCallback(() => setActive(false), [])

  React.useEffect(() => {
    const fn = powerSearchKeyboardShortcut(active, setActive);
    document.addEventListener("keydown", fn);
    return () => {
      document.removeEventListener("keydown", fn);
    }
  }, [active])

  if (!user.data.loggedIn || !user.data.site) return null;
  if (!active) return null;

  return (
    <PowersearchProvider active={active} onClose={handleOnClosed}>
      <PowersearchContext.Consumer>
        {({ results }) => (
          <Fixed tabIndex={-1} role="dialog" aria-labelledby="powersearch" id="poweroffice-dialog">
            <PowersearchInput active />
            {results.length === 0 && <PowersearchInfo />}
            <PowersearchResults />
          </Fixed>
        )}
      </PowersearchContext.Consumer>
      <BackgroundDrop onClick={handleOnClosed} />
    </PowersearchProvider>
  )
}
