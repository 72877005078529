import React, { useCallback, useContext } from "react";
import { AiOutlineLogout } from "react-icons/ai";
import { MdPalette } from "react-icons/md";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import Fader from "../../../style-guide/Fader/Fader";
import Text from "../../../style-guide/Text/Text";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import { CardLink } from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Icon from "../../../components/Icon/Icon";
import Section from "../../../style-guide/Section/Section";
import Grid from "../../../style-guide/Grid/Grid";
import logout from "../../../util/logout";
import {
  Modal,
  ModalBody,
  ModalActions
} from "../../../style-guide/Modal/Modal";
import { ButtonList, Button } from "../../../style-guide/Button/Button";
import createInputField from "../../../util/createInputField";
import { ThemeSwitcherContext } from "../../../context/ThemeSwitcher";
import Crumb from "../../../components/Crumb/Crumb";

const User: React.FC = () => {
  const { spawnModal } = useModal();

  const createChangeDashboardModal = useCallback(() => {
    spawnModal(<ThemeModal />);
  }, []);

  return (
    <Fader>
      <Block hugTop>
        <Text element="h1" variant="display3">
          <Crumb url="/innstillinger">Innstillinger</Crumb>
          Bruker
        </Text>
      </Block>
      <Section hugTop>
        <Grid gap="medium" columns={3}>
          <CardLink
            to="#"
            onClick={(e) => {
              e.preventDefault();
              createChangeDashboardModal();
            }}
          >
            <Block flex>
              <Flex flex={1}>
                <FlexKid flex={1}>
                  <Text element="h2" variant="title">
                    Endre dashbord-tema
                  </Text>
                </FlexKid>
                <FlexKid spaceLeft>
                  <Icon size="large">
                    <MdPalette />
                  </Icon>
                </FlexKid>
              </Flex>
            </Block>
          </CardLink>
          <CardLink
            to="#"
            stretch
            onClick={(e) => {
              e.preventDefault();

              if (window.confirm("Er du sikker på at du vil logge ut?")) {
                logout();
              }
            }}
          >
            <Block flex>
              <Flex flex={1}>
                <FlexKid flex={1}>
                  <Text element="h2" variant="title">
                    Logg ut
                  </Text>
                </FlexKid>
                <FlexKid spaceLeft>
                  <Icon size="large">
                    <AiOutlineLogout />
                  </Icon>
                </FlexKid>
              </Flex>
            </Block>
          </CardLink>
        </Grid>
      </Section>
    </Fader>
  );
};

const ThemeModal: React.FC = () => {
  const { despawnModal } = useModal();
  const { theme, setTheme } = useContext(ThemeSwitcherContext);

  return (
    <Modal>
      <ModalBody>
        <Block>
          <Text variant="title" gutterBottom>
            Endre dashbord-tema
          </Text>
          {createInputField({
            key: "theme",
            label: "Velg tema",
            type: "radio",
            value: theme,
            options: [
              {
                value: "dark",
                label: "Mørkt"
              },
              {
                value: "light",
                label: "Lyst"
              }
            ],
            onChange: (value) => setTheme(value, true)
          })}
        </Block>
      </ModalBody>
      <ModalActions>
        <ButtonList align="right">
          <Button type="button" onClick={despawnModal}>
            Ferdig
          </Button>
        </ButtonList>
      </ModalActions>
    </Modal>
  );
};

export default User;
