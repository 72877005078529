import styled, { css } from "styled-components";

interface Props {
  fullscreen?: boolean;
  maxWidth?: string;
}

const Modal = styled.div<Props>`
  border-radius: 8px;
  background-color: ${props => props.theme.colors.background};
  box-shadow: ${props => props.theme.shadows.large};
  min-width: 28rem;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - ${props => props.theme.spacing.large});
  overflow-y: scroll;

  ${props =>
    props.fullscreen &&
    css`
      width: calc(100vw - ${props.theme.spacing.large});
      height: calc(100vh - ${props.theme.spacing.large});
    `};

  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `};
`;

interface ModalActionsProps {
  multiple?: boolean;
}

const ModalActions = styled.div<ModalActionsProps>`
  justify-content: flex-end;
  padding: ${props =>
    `${props.theme.spacing.small} ${props.theme.spacing.medium}`};
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.borderDim};
  height: auto;
  display: flex;

  ${props =>
    props.multiple &&
    css`
      justify-content: space-between;
    `}
`;

const ModalBody = styled.div`
  overflow: hidden;
  overflow-y: auto;
  flex: 1;
`;

export { Modal, ModalActions, ModalBody };
