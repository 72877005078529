import React from "react";
import styled, { css, keyframes } from "styled-components";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

interface WrapperProps {
  show?: boolean;
}

const spin = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.sizes.plusTwo};
  padding: 0 ${(props) => props.theme.spacing.xs};
  opacity: 0;
  transition: opacity 0.12s ease-in-out;
  animation: ${spin} 1s linear infinite;

  ${(props) =>
    props.show &&
    css`
      opacity: 1;
    `}
`;

const BiggerLoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.textSecondary};
`;

interface Props {
  loading?: boolean;
}

const Loading: React.FC<Props> = ({ loading }) => {
  return (
    <Wrapper show={loading}>
      <AiOutlineLoading3Quarters />
    </Wrapper>
  );
};

interface BiggerLoadingProps extends Props {
  label?: string;
}

const BiggerLoading: React.FC<BiggerLoadingProps> = ({ label, ...rest }) => {
  return (
    <BiggerLoadingWrapper>
      {label}
      <Loading {...rest} />
    </BiggerLoadingWrapper>
  );
};

export default Loading;
export { BiggerLoading };
