import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineCalendar } from "react-icons/ai";
import { BookingResource } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import { ButtonLink } from "../../style-guide/Button/Button";
import Tabler from "../../components/Tabler/Tabler";

interface Props {}

const Resources: React.FC<Props> = () => {
  return (
    <Tabler
      title="Ressurser"
      crumb={{
        label: "Booking",
        path: "/booking"
      }}
      actions={<ButtonLink to="ressurser/opprett">Opprett ressurs</ButtonLink>}
      emptyState={{
        title: "Ingen ressurser enda",
        icon: <AiOutlineCalendar />,
        text: "For at du skal kunne tilby booking trenger du minst én ressurs du kan tilegne en tjeneste. En ressurs kan være en ansatt, et møterom, en konsertarena, eller hva som helst, egentlig.",
        action: <ButtonLink to="ressurser/opprett">Opprett ressurs</ButtonLink>
      }}
      url={["booking", "resources"]}
      columns={[
        {
          Header: "Ressurs",
          accessor: "title"
        }
      ]}
      createRow={(resource: BookingResource) => ({
        ...resource,
        title: <TitleRow resource={resource} />
      })}
    />
  );
};

interface RowProps {
  resource: BookingResource;
}

const TitleRow: React.FC<RowProps> = ({ resource }) => {
  return (
    <>
      <Text variant="body2">
        <Link to={`/booking/ressurser/${resource._id}`}>{resource.name}</Link>
      </Text>
      <Text variant="body3">
        {resource.description || "Mangler beskrivelse"}
      </Text>
    </>
  );
};

export default Resources;
