import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { AiOutlinePercentage } from "react-icons/ai";
import { Discount } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import { ButtonLink } from "../../style-guide/Button/Button";
import Tabler from "../../components/Tabler/Tabler";
import localize from "../../util/localize";
import { discountType } from "../../constants/localizations";
import Status from "../../style-guide/Status/Status";

const Discounts: React.FC = () => {
  return (
    <Tabler
      title="Tilbud"
      crumb={{
        label: "Butikk",
        path: "/butikk"
      }}
      actions={<ButtonLink to="tilbud/opprett">Opprett tilbud</ButtonLink>}
      emptyState={{
        title: "Opprett ditt første tilbud",
        icon: <AiOutlinePercentage />,
        text: "I Rubics kan du opprette tilbud som enten er faste, altså at de gjelder for alle som handler produkter markert for tilbud, eller som tilbudskoder, at man må taste inn en kode for å dra nytte av tilbudet.",
        action: <ButtonLink to="tilbud/opprett">Opprett tilbud</ButtonLink>
      }}
      url={["discounts"]}
      columns={[
        {
          Header: "Tilbud",
          accessor: "title",
          verticalAlign: "middle"
        },
        {
          Header: "Type",
          accessor: "type",
          verticalAlign: "middle"
        },
        {
          Header: "Status",
          accessor: "status",
          verticalAlign: "middle",
          align: "center"
        }
      ]}
      createRow={(discount: Discount) => {
        return {
          ...discount,
          title: <TitleRow discount={discount} />,
          type: (
            <Text variant="body3">
              {localize(discountType, discount.__type)}
            </Text>
          ),
          status: (
            <Status variant={!discount.active ? "neutral" : undefined}>
              {discount.active ? "Aktiv" : "Inaktiv"}
            </Status>
          )
        };
      }}
    />
  );
};

interface TitleRowProps {
  discount: Discount;
}

const TitleRow: React.FC<TitleRowProps> = ({ discount }) => {
  return (
    <>
      <Text variant="body2">
        <Link to={`/butikk/tilbud/${discount._id}`}>{discount.name}</Link>
      </Text>
      <Text variant="body3">
        Fra {moment(discount.startDate).format("D.M.YYYY")}
        {discount.endDate
          ? ` til ${moment(discount.endDate).format("D.M.YYYY")}`
          : ""}
      </Text>
    </>
  );
};

export default Discounts;
