import * as React from "react";
import { useMemo } from "react";
import styled from "styled-components";
import { Image } from "@ludens-reklame/rubics-sdk";
import { InfoWrapper, SearchHits, SearchWrapper } from "./powersearch.styles";
import { getIconFromType, getNameFromType, PowerSearchResponse } from "./powersearch.utils";
import Icon from "../Icon/Icon";
import { PowersearchContext, powerSearchEditKey } from "./PowersearchContext";
import getIcon from "../../util/getIcon";

export default function PowersearchResults(props: Omit<React.HTMLAttributes<HTMLUListElement>, 'id' | 'role' | 'aria-label'>) {
  const { results, focused, onSelect } = React.useContext(PowersearchContext);
  const [ctrlActive, setCtrlActive] = React.useState(false);

  React.useEffect(() => {
    const keydown = (e: KeyboardEvent) => {
      if (e.key === powerSearchEditKey) {
        setCtrlActive(true);
      }
    }
    const keyup = (e: KeyboardEvent) => {
      if (e.key === powerSearchEditKey) {
        setCtrlActive(false);
      }
    }
    document.addEventListener("keydown", keydown);
    document.addEventListener("keyup", keyup);
    return () => {
      document.removeEventListener("keydown", keydown);
      document.removeEventListener("keyup", keyup);
    }

  }, []);

  return (
    <>
      <SearchWrapper id="powersearch-list" role="listbox" aria-label="Resultat av søk" {...props}>
        {results.map((item, i) => {
          return (
            <SearchHits
              key={item._id}
              className="powersearch-item"
              active={focused === i}
              onClick={(e) => onSelect(e, i)}
              role="option"
              aria-posinset={i}
              aria-setsize={results.length}
              aria-selected={focused === i}
              aria-label={`Gå til ${getNameFromType(item)} for ${item.title}`}
              tabIndex={-1}
            >
             <IconOrImage item={item} />
              <h3>{item.title}</h3>
              <p>{getNameFromType(item)}{item.subtitle ? ` - ${item.subtitle}` : ""}{item.description ? ` - ${item.description}` : ""}</p>
              {ctrlActive && item.editPath ? <EditIcon>{getIcon('content')}</EditIcon> : null}
            </SearchHits>
          );
        })}
      </SearchWrapper>
      <div aria-live="polite" role="status" className="visually-hidden">
        ${results.length} resultater tilgjengelig.
      </div>
    </>
  );
}

function IconOrImage({ item }: { item: PowerSearchResponse }) {
  const imageProps = useMemo(() => item.image?.url ? Image.srcSet(item.image, 80, 80, { transforms: 'c_fill'}) : null, [item.image])
  return imageProps ? <img {...imageProps} /> : <RowIcon>{getIconFromType(item)}</RowIcon>;
}

export function PowersearchInfo(props: Omit<React.HTMLAttributes<HTMLDivElement>, 'id'>) {
  return (
    <InfoWrapper id="powersearch" {...props}>
      <h3>Her kan du søke på:</h3>
      <p>
        <Strong>S</Strong>ider<br />
        <Strong>M</Strong>aler<br />
        <Strong>P</Strong>rodukter<br />
        <Strong>N</Strong>avigering (Menyer)
      </p>
      <p>
        Du kan begrense søk ved å bruke den uthevede forbokstaven og mellomrom.<br />
        <strong>F.eks:</strong> Ved å bruke <Strong>s</Strong> etterfulgt av mellomrom og søkefrasen din, vil du kunne begrense søkeresultatet ditt til treff i sider.<br/>
      </p>
    </InfoWrapper>
  );
}

const Strong= styled.strong`
  color: ${props => props.theme.colors.action};
`

const RowIcon = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditIcon = styled(Icon)`
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
`;
