import React from "react";
import { StatType } from "@ludens-reklame/rubics-sdk";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import { Experiment } from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Section from "../../../style-guide/Section/Section";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import createPublisherInputField from "../../../util/createPublisherInputField";
import { statType } from "../../../constants/localizations";
import Select from "../../../style-guide/Inputs/Select";
import createInputField from "../../../util/createInputField";

interface Props {
  publisher: UsePublisherInterface<Experiment>;
}

const General: React.FC<Props> = ({ publisher }) => {
  return (
    <Fader>
      <Flex align="flex-start">
        <FlexKid flex={1}>
          <Section hugTop>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Generelt
                  </Text>
                </Section>
                {createPublisherInputField(publisher, {
                  path: "name",
                  label: "Navn",
                  type: "text",
                  required: true
                })}
                {createPublisherInputField(publisher, {
                  path: "description",
                  label: "Beskrivelse",
                  type: "textarea"
                })}
              </Block>
            </Card>
          </Section>
          <Section>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Mål
                  </Text>
                </Section>
                {createInputField({
                  key: "goal.type",
                  label: "Type",
                  type: "select",
                  required: true,
                  value: publisher.form.data.goal.type,
                  onChange: (value) => {
                    publisher.form.setField({
                      path: "goal",
                      value: {
                        type: value
                      }
                    });
                  },
                  options: Object.keys(statType).map((k) => ({
                    label: statType[k],
                    value: k
                  }))
                })}
                <GoalType publisher={publisher} />
              </Block>
            </Card>
          </Section>
        </FlexKid>
        <FlexKid flex={1} spaceLeft>
          <Card>
            <Block>
              <Section hugTop>
                <Text element="h2" variant="title">
                  Aktiv
                </Text>
              </Section>
              {createPublisherInputField(publisher, {
                path: "active",
                label: "Aktiv",
                type: "boolean"
              })}
            </Block>
          </Card>
        </FlexKid>
      </Flex>
    </Fader>
  );
};

const GoalType: React.FC<Props> = ({ publisher }) => {
  const form = publisher.form;
  const type: StatType | undefined = form.getValue("goal.type");

  if (!type) {
    return null;
  }

  if (type === StatType.PageView) {
    return (
      <>
        {createPublisherInputField(publisher, {
          path: "goal.meta1.value",
          label: "URL",
          required: true,
          value: form.getValue("goal.meta1.value"),
          type: "text",
          inlineField: (
            <Select
              value={form.getValue("goal.meta1.operation") || "equals"}
              onChange={(e) =>
                form.setField({
                  path: "goal.meta1.operation",
                  value: e.target.value
                })
              }
            >
              <option value="equals">Er lik</option>
              <option value="contains">Inneholder</option>
            </Select>
          )
        })}
      </>
    );
  } else if (type === StatType.OrderLine) {
    return (
      <>
        {createPublisherInputField(publisher, {
          path: "goal.meta1.value",
          label: `SKU ${
            form.data.goal.meta1.operation !== "contains"
              ? "(kommaseparert)"
              : ""
          }`,
          value: form.getValue("goal.meta1.value"),
          type: "text",
          inlineField: (
            <Select
              value={form.getValue("goal.meta1.operation") || "equals"}
              onChange={(e) =>
                form.setField({
                  path: "goal.meta1.operation",
                  value: e.target.value
                })
              }
            >
              <option value="equals">Er lik</option>
              <option value="contains">Inneholder</option>
            </Select>
          )
        })}
        {createPublisherInputField(publisher, {
          path: "goal.meta2.value",
          label: "Produktnavn",
          value: form.getValue("goal.meta2.value"),
          type: "text",
          inlineField: (
            <Select
              value={form.getValue("goal.meta2.operation") || "equals"}
              onChange={(e) =>
                form.setField({
                  path: "goal.meta2.operation",
                  value: e.target.value
                })
              }
            >
              <option value="equals">Er lik</option>
              <option value="contains">Inneholder</option>
            </Select>
          )
        })}
      </>
    );
  }

  return null;
};

export default General;
