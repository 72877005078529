import React, { useEffect } from "react";
import { RouteComponentProps, Prompt, Switch, Route } from "react-router-dom";
import { BookingResource } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import Form from "../../style-guide/Inputs/Form";
import Fader from "../../style-guide/Fader/Fader";
import Block from "../../style-guide/Block/Block";
import usePublisher from "../../hooks/usePublisher";
import PublisherHelpers from "../../util/PublisherHelpers";
import Section from "../../style-guide/Section/Section";
import ActionBar from "../../components/ActionBar/ActionBar";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import Crumb from "../../components/Crumb/Crumb";
import { LEAVE_PAGE_MESSAGE } from "../../constants/general";
import General from "./SubViews/Resource/General";

interface Props
  extends RouteComponentProps<{
    resource: string;
  }> {}

const Resource: React.FC<Props> = ({ match }) => {
  const resourceId = match.params.resource;
  const breadcrumbs = useBreadcrumbs();

  const publisher = usePublisher<BookingResource>({
    id: resourceId,
    baseUrl: ["booking", "resources"],
    baseDashboardUrl: "/booking/ressurser",
    initialData: {
      name: "",
      slots: 1,
      availability: []
    },
    pathsToCopy: [
      {
        path: "name",
        label: "Navn"
      },
      {
        path: "description",
        label: "Beskrivelse"
      },
      {
        path: "slots",
        label: "Kapasitet"
      },
      {
        path: "availability",
        label: "Tilgjengelighet"
      }
    ]
  });

  useEffect(() => {
    breadcrumbs.swapEntry(resourceId, publisher.form.data.name);
  }, [breadcrumbs, publisher.form.data, resourceId]);

  return (
    <Form onSubmit={publisher.form.submit}>
      <Prompt
        when={publisher.form.hasMadeChanges}
        message={(location) => {
          if (location.pathname.includes(resourceId)) {
            return true;
          }

          return LEAVE_PAGE_MESSAGE;
        }}
      />
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/booking/ressurser">Ressurser</Crumb>
            {PublisherHelpers.getResourceTitle(publisher, "name", "Ny ressurs")}
          </Text>
        </Block>
        <Section>
          <Switch>
            <Route
              exact
              path={publisher.dashboardUrl}
              render={() => <General publisher={publisher} />}
            />
          </Switch>
        </Section>
      </Fader>
      <ActionBar publisher={publisher} />
    </Form>
  );
};

export default Resource;
