import styled, { css } from "styled-components";

interface Props {
  gutterTop?: boolean;
  hugBottom?: boolean;
  tight?: boolean;
}

const Ruler = styled.hr<Props>`
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderDim};
  margin: 0 0 ${(props) => props.theme.spacing.medium};
  height: 0;

  ${(props) =>
    props.gutterTop &&
    css`
      margin-top: ${(props) => props.theme.spacing.medium};
    `}

  ${(props) =>
    props.hugBottom &&
    css`
      margin-bottom: 0;
    `}

  ${(props) =>
    props.tight &&
    css`
      margin-bottom: ${(props) => props.theme.spacing.small};

      ${props.gutterTop &&
      css`
        margin-top: ${(props) => props.theme.spacing.small};
      `}
    `}
`;

export default Ruler;
