import React, {
  FunctionComponent,
  createContext,
  useState,
  useEffect,
  useCallback
} from "react";
import storage from "../util/storage";

export type Theme = "dark" | "light";

const storageTheme = storage.get("theme");
const defaultTheme = storageTheme || "dark";

const ThemeSwitcherContext = createContext({
  theme: defaultTheme as Theme,
  defaultTheme: defaultTheme as Theme,
  setTheme: (theme: Theme, save?: boolean) => {}
});

const ThemeSwitcherProvider: FunctionComponent = ({ children }) => {
  const [theme, setTheme] = useState(defaultTheme as Theme);
  const [_defaultTheme, setDefaultTheme] = useState(defaultTheme as Theme);

  useEffect(() => {
    if (storageTheme !== "dark" && storageTheme !== "light") {
      const hasLightPreferrence = !window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;

      const theme = hasLightPreferrence ? "light" : "dark";

      setDefaultTheme(theme);
      setTheme(theme);
    }
  }, []);

  const handleSetTheme = useCallback((theme: Theme, save?: boolean) => {
    setTheme(theme);

    if (save) {
      storage.set("theme", theme);
      setDefaultTheme(theme);
    }
  }, []);

  return (
    <ThemeSwitcherContext.Provider
      value={{
        theme,
        defaultTheme: _defaultTheme,
        setTheme: handleSetTheme
      }}
    >
      {children}
    </ThemeSwitcherContext.Provider>
  );
};

export { ThemeSwitcherContext, ThemeSwitcherProvider };
