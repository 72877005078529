import React from "react";
import { Switch, Route } from "react-router";
import ProductTemplates from "./ProductTemplates";
import ProductTemplate from "./ProductTemplate";

const ProductTemplatesRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/butikk/produktmaler" component={ProductTemplates} />
      <Route
        path="/butikk/produktmaler/:productTemplate"
        render={props => (
          <ProductTemplate
            key={props.match.params.productTemplate}
            {...props}
          />
        )}
      />
    </Switch>
  );
};

export default ProductTemplatesRoutes;
