import React, { useState, useCallback, useMemo } from "react";
import { CustomizeOptionType } from "@ludens-reklame/rubics-sdk";
import { AiOutlineSearch } from "react-icons/ai";
import {
  ProductTemplate,
  CustomizeOption as ICustomizeOption
} from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import { Button } from "../../../style-guide/Button/Button";
import { createObjectId } from "../../../util/createId";
import {
  InlineEditHead,
  InlineEditColumn
} from "../../../style-guide/InlineEdit/InlineEdit";
import CustomizeOption from "../Components/CustomizeOption";
import { Dropper, Dragger } from "../../../components/DnD/DnD";
import { UseFormInterface } from "../../../hooks/useForm";
import TextInput from "../../../style-guide/Inputs/TextInput";
import EmptyState from "../../../style-guide/EmptyState/EmptyState";
import { MdPalette } from "react-icons/md";

interface Props {
  form: UseFormInterface<Pick<ProductTemplate, "customizeOptions">>;
  readOnly: boolean;
  submitOnSet?: boolean;
  outline?: boolean;
  search?: boolean;
}

const CustomizeOptions: React.FC<Props> = ({
  form,
  readOnly,
  submitOnSet,
  outline,
  search
}) => {
  const [newId, setNewId] = useState<string | null>(null);
  const [query, setQuery] = useState("");

  const handleCustomizeOption = useCallback(() => {
    const id = createObjectId();

    form.setField({
      path: "customizeOptions",
      value: [
        {
          _id: id,
          type: CustomizeOptionType.String,
          options: []
        },
        ...(form.data.customizeOptions || [])
      ]
    });

    setNewId(id);
  }, [form]);

  const queriedCustomizeOptions = useMemo<ICustomizeOption[]>(
    () =>
      query.length > 0
        ? (form.data.customizeOptions || []).filter((a) => {
            return a.label.toLowerCase().includes(query.toLowerCase());
          })
        : form.data.customizeOptions || [],
    [query, form.data.customizeOptions]
  );

  return (
    <Fader>
      {(form.data.customizeOptions || []).length > 0 ? (
        <Card outlined={outline}>
          <Block>
            {search ? (
              <Flex>
                <FlexKid flex={1}>
                  <TextInput
                    icon={<AiOutlineSearch />}
                    placeholder="Søk etter tilpasningsfelt"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                </FlexKid>
                {!readOnly && (
                  <FlexKid spaceLeft>
                    <Button onClick={() => handleCustomizeOption()}>
                      Nytt tilpasningsfelt
                    </Button>
                  </FlexKid>
                )}
              </Flex>
            ) : (
              <Flex>
                <FlexKid flex={1}>
                  <Text element="h2" variant="title">
                    Tilpasningsfelter
                  </Text>
                </FlexKid>
                {!readOnly && (
                  <FlexKid>
                    <Button
                      type="button"
                      outlined
                      onClick={() => handleCustomizeOption()}
                    >
                      Nytt tilpasningsfelt
                    </Button>
                  </FlexKid>
                )}
              </Flex>
            )}
          </Block>
          <InlineEditHead>
            <InlineEditColumn width="400px">Navn</InlineEditColumn>
            <InlineEditColumn width="200px">Type</InlineEditColumn>
            <InlineEditColumn width="200px">Kostnad</InlineEditColumn>
          </InlineEditHead>
          <Dropper
            id="customizeOptions"
            onDragEnd={(r) => {
              if (r.destination) {
                const list = Array.from(form.data.customizeOptions || []);
                const [removed] = list.splice(r.source.index, 1);

                list.splice(r.destination.index, 0, removed);

                form.setField({
                  path: "customizeOptions",
                  value: list,
                  submit: submitOnSet
                });
              }
            }}
          >
            {queriedCustomizeOptions.length > 0 ? (
              queriedCustomizeOptions.map((o, k) => (
                <Dragger
                  key={o._id}
                  id={o._id}
                  index={k}
                  delegateDragHandle
                  isDragDisabled={readOnly}
                >
                  <CustomizeOption
                    key={o._id}
                    customizeOption={o}
                    customizeOptions={form.data.customizeOptions}
                    readOnly={readOnly}
                    isNew={o._id === newId}
                    onChange={(data) =>
                      form.setField({
                        path: "customizeOptions",
                        value: form.data.customizeOptions.map((oo, kk) => {
                          if (kk === k) {
                            return data;
                          }

                          return oo;
                        }),
                        submit: submitOnSet,
                        debounce: true
                      })
                    }
                    onDelete={() => {
                      if (window.confirm("Er du sikker på at du vil slette?")) {
                        form.setField({
                          path: "customizeOptions",
                          value: form.data.customizeOptions.filter(
                            (_, kk) => kk !== k
                          ),
                          submit: submitOnSet
                        });
                      }
                    }}
                  />
                </Dragger>
              ))
            ) : (
              <Block hugTop>
                <Text variant="subheading">Ingen tilpasningsfelter funnet</Text>
              </Block>
            )}
          </Dropper>
        </Card>
      ) : (
        <EmptyState
          title="Ingen tilpasningsfelter"
          text="Tilpasningsfelter lar kunden sette sin egen vri på produktet.
          Det kan, for eksempel, være navn og nummer bak på fotballdrakta,
          farge på kaffekoppen, også videre. Kun fantasien setter grenser!"
          icon={<MdPalette />}
          action={
            <Button onClick={() => handleCustomizeOption()}>
              Opprett tilpasningsfelt
            </Button>
          }
        />
      )}
    </Fader>
  );
};

export default CustomizeOptions;
