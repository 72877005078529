import React from "react";
import {
  DraggableProvidedDragHandleProps,
  DraggableStateSnapshot
} from "react-beautiful-dnd";
import { AiOutlineDelete } from "react-icons/ai";
import { MdDragHandle } from "react-icons/md";
import { ProductTemplateAttribute } from "../../../types/apiResponses";
import InlineEdit from "../../../components/InlineEdit/InlineEdit";
import createInputField from "../../../util/createInputField";
import { Button } from "../../../style-guide/Button/Button";

interface Props {
  attribute: ProductTemplateAttribute;
  usedAttributes: ProductTemplateAttribute[];
  isNew: boolean;
  onChange: (attribute: ProductTemplateAttribute) => any;
  onDelete: () => any;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  dragState?: DraggableStateSnapshot;
  noSimpleVariants?: boolean;
  readOnly?: boolean;
}

const Attribute: React.FC<Props> = ({
  attribute,
  usedAttributes,
  isNew,
  onChange,
  onDelete,
  dragHandleProps,
  dragState,
  noSimpleVariants,
  readOnly
}) => {
  return (
    <InlineEdit
      expanded={isNew}
      background={
        dragState && dragState.isDragging ? "backgroundVariant" : undefined
      }
      alignItems="flex-start"
      actions={[
        <Button
          type="button"
          aria-label="Slett"
          circular
          outlined
          smaller
          disabled={readOnly}
          onClick={onDelete}
        >
          <AiOutlineDelete />
        </Button>,
        <Button
          type="button"
          aria-label="Endre rekkefølge"
          outlined
          circular
          smaller
          disabled={readOnly}
          {...dragHandleProps}
        >
          <MdDragHandle />
        </Button>
      ]}
      headerColumns={[
        {
          width: "400px",
          node: createInputField({
            key: "ref",
            type: "ref",
            required: true,
            autoFocus: isNew,
            readOnly,
            ref: {
              url: ["attributes"],
              searchKey: "internalName",
              titleKey: "internalName",
              subtitleKey: "description",
              filter: (item) =>
                !usedAttributes.map((a) => a.ref).includes(item._id)
            },
            hugTop: true,
            value: attribute.populatedRef
              ? attribute.populatedRef.internalName
              : "",
            onChange: (value) =>
              onChange({
                ...attribute,
                ref: value ? value._id : null,
                populatedRef: value
              })
          })
        },
        {
          width: "200px",
          node: noSimpleVariants
            ? null
            : createInputField({
                key: "filterable",
                type: "boolean",
                hugTop: true,
                value: attribute.filterable,
                readOnly,
                onChange: (value) =>
                  onChange({
                    ...attribute,
                    filterable: value
                  })
              })
        }
      ]}
    />
  );
};

export default Attribute;
