import React from "react";
import { Switch, Route, Redirect } from "react-router";
import Calendar from "./Calendar";
import Resource from "./Resource";
import Resources from "./Resources";
import Service from "./Service";
import Services from "./Services";

const BookingRoutes: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path="/booking"
        render={() => <Redirect to="/booking/kalender" />}
      />
      <Route path="/booking/kalender" component={Calendar} />
      <Route exact path="/booking/tjenester" component={Services} />
      <Route exact path="/booking/ressurser" component={Resources} />
      <Route
        path="/booking/tjenester/:service"
        render={(props) => (
          <Service key={props.match.params.service} {...props} />
        )}
      />
      <Route
        path="/booking/ressurser/:resource"
        render={(props) => (
          <Resource key={props.match.params.resource} {...props} />
        )}
      />
    </Switch>
  );
};

export default BookingRoutes;
