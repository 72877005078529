import React, { useContext } from "react";
import { Prompt, RouteComponentProps } from "react-router-dom";
import { RevisionType } from "../../../constants/api";
import { LEAVE_PAGE_MESSAGE } from "../../../constants/general";
import { Site as ISite } from "../../../types/apiResponses";
import { SiteContext } from "../../../context/Site";
import { UserContext } from "../../../context/User";
import usePublisher from "../../../hooks/usePublisher";
import createPublisherInputField from "../../../util/createPublisherInputField";
import Text from "../../../style-guide/Text/Text";
import Form from "../../../style-guide/Inputs/Form";
import Fader from "../../../style-guide/Fader/Fader";
import Block from "../../../style-guide/Block/Block";
import Section from "../../../style-guide/Section/Section";
import Card from "../../../style-guide/Card/Card";
import Crumb from "../../../components/Crumb/Crumb";
import ActionBar from "../../../components/ActionBar/ActionBar";

interface Props extends RouteComponentProps {}

const OrderReceipt: React.FC<Props> = () => {
  const user = useContext(UserContext).data;
  const siteContext = useContext(SiteContext);

  const publisher = usePublisher<ISite>({
    id: user.site!,
    baseUrl: ["site"],
    baseDashboardUrl: "/innstillinger/epost-tekster/ordrekvittering",
    initialData: {},
    skipId: true,
    pathsToCopy: [
      {
        path: "emailTexts.orderReceipt",
        label: "Egendefinert tekst til ordrekvittering"
      }
    ],
    onSubmit: (site: ISite) => {
      if (site) {
        siteContext.setData(site);
      }
    }
  });

  return (
    <Form onSubmit={publisher.form.submit}>
      <Prompt
        when={publisher.form.hasMadeChanges}
        message={() => LEAVE_PAGE_MESSAGE}
      />
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/innstillinger/epost-tekster">
              Tekster til utsendelser
            </Crumb>
            Ordrekvittering
          </Text>
        </Block>
        <Section>
          <Card>
            <Block>
              <Section hugTop>
                <Text element="h2" variant="title">
                  Generelt
                </Text>
              </Section>
              {createPublisherInputField(publisher, {
                path: "emailTexts.orderReceipt",
                label: "Egendefinert tekst til ordrekvittering",
                type: "draft",
                description:
                  'Standardtekst på ordre er: "Hei, <navn>. Takk for din bestilling. Her er din ordrekvittering"',
                syncState: publisher.form.syncState
              })}
            </Block>
          </Card>
        </Section>
      </Fader>
      <ActionBar
        publisher={publisher}
        revisions
        revisionType={RevisionType.Site}
        revisionReference={publisher.form.data._id}
      />
    </Form>
  );
};

export default OrderReceipt;
