import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { AiOutlineShopping } from "react-icons/ai";
import { Order } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import Tabler from "../../components/Tabler/Tabler";
import { currencyFormat } from "../../util/intl";
import Status from "../../style-guide/Status/Status";
import localize from "../../util/localize";
import { orderStatus, paymentStatus } from "../../constants/localizations";
import { RequestData } from "../../util/api";
import { OrderStatus, OrderType, PaymentStatus } from "../../constants/api";

interface Props {
  hideTitle?: boolean;
  query?: RequestData;
}

const Orders: React.FC<Props> = ({ hideTitle, query }) => {
  return (
    <Tabler
      tight
      title="Ordrer"
      crumb={{
        label: "Butikk",
        path: "/butikk"
      }}
      hideTitle={hideTitle}
      emptyState={{
        title: "Ingen ordrer enda",
        icon: <AiOutlineShopping />,
        text: "Frykt ikke, når kundene får øynene opp for utvalget ditt vil ordre strømme inn!"
      }}
      url={["orders"]}
      queryParams={{
        type: OrderType.Order,
        ...query
      }}
      queryField="orderNumber"
      queryLabel="Ordrenummer"
      columns={[
        {
          Header: "Ordre",
          accessor: "title",
          verticalAlign: "middle"
        },
        {
          Header: "Dato",
          accessor: "timestamp",
          verticalAlign: "middle"
        },
        {
          Header: "Status",
          accessor: "status",
          verticalAlign: "middle",
          align: "center",
          style: { width: "210px" }
        },
        {
          Header: "Betalingsstatus",
          accessor: "paymentStatus",
          verticalAlign: "middle",
          align: "center",
          style: { width: "210px" }
        },
        {
          Header: "Beløp",
          accessor: "total",
          verticalAlign: "middle",
          align: "right"
        }
      ]}
      createRow={(order: Order) => ({
        ...order,
        title: <TitleRow order={order} />,
        timestamp: (
          <Text variant="body3">
            {moment(order.timestamp).format("D. MMMM Y")}
          </Text>
        ),
        status: (
          <Status
            wider
            variant={
              order.status === OrderStatus.Pending ||
              order.status === OrderStatus.Processing
                ? "neutral"
                : order.status === OrderStatus.Failed
                ? "bad"
                : undefined
            }
          >
            {localize(orderStatus, order.status)}
          </Status>
        ),
        paymentStatus: (
          <Status
            wider
            variant={
              order.paymentStatus === PaymentStatus.Reserved ||
              order.paymentStatus === PaymentStatus.PartiallyPaid
                ? "neutral"
                : undefined
            }
          >
            {localize(
              paymentStatus,
              order.paymentStatus || PaymentStatus.Manual
            )}
          </Status>
        ),
        total: (
          <Text align="right" variant="body3">
            {currencyFormat.format(order.total)}
          </Text>
        )
      })}
    />
  );
};

interface TitleRowProps {
  order: Order;
}

const TitleRow: React.FC<TitleRowProps> = ({ order }) => {
  const customer = useMemo(() => {
    return (
      order.invoiceInformation.name ||
      order.invoiceInformation.email ||
      "Mangler kundeinformasjon"
    );
  }, [order.invoiceInformation]);

  return (
    <Text variant="body2">
      <Link to={`/butikk/ordrer/${order._id}`}>
        #{order.orderNumber} · {customer}
      </Link>
    </Text>
  );
};

export default Orders;
