import { ThemeComponent } from "@ludens-reklame/rubics-sdk";

type DescribableComponent = Pick<ThemeComponent, "description">;

function getComponentDescription(
  component?: DescribableComponent | null
): string {
  if (component && component.description) {
    return component.description;
  }

  return "";
}

export default getComponentDescription;
