import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineGold } from "react-icons/ai";
import { Component } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import { ButtonLink } from "../../style-guide/Button/Button";
import Tabler from "../../components/Tabler/Tabler";

interface Props {}

const Components: React.FC<Props> = () => {
  return (
    <Tabler
      title="Komponenter"
      crumb={{
        label: "Innhold",
        path: "/innhold"
      }}
      actions={
        <ButtonLink to="komponenter/opprett">Opprett komponent</ButtonLink>
      }
      emptyState={{
        title: "Opprett din første komponent",
        icon: <AiOutlineGold />,
        text:
          "Komponenter du lager her kan gjenbrukes i maler og annet innhold, slik at du slipper unødvendig publisering der du har behov for å vise samme innhold flere steder.",
        action: (
          <ButtonLink to="komponenter/opprett">Opprett komponent</ButtonLink>
        )
      }}
      url={["components"]}
      columns={[
        {
          Header: "Navn",
          accessor: "name"
        }
      ]}
      createRow={(component: Component) => ({
        ...component,
        name: <TitleRow component={component} />,
        options: null
      })}
    />
  );
};

interface TitleRowProps {
  component: Component;
}

const TitleRow: React.FC<TitleRowProps> = ({ component }) => {
  return (
    <>
      <Text variant="body2">
        <Link to={`/innhold/komponenter/${component._id}`}>
          {component.name}
        </Link>
      </Text>
      <Text variant="body3">
        {component.description || "Ingen beskrivelse"}
      </Text>
    </>
  );
};

export default Components;
