import { useState, FormEvent, useEffect, useCallback, useRef } from "react";
import equal from "deep-equal";
import objectPath from "object-path";
import clone from "clone-deep";
import { useDebouncedCallback } from "use-debounce";
import api, { ApiOpts, RequestData } from "../util/api";
import chchchanges from "../util/chchchanges";
import { useApi } from "./useApi";

export interface SetFieldOpts {
  path: string;
  value: any;
  submit?: boolean;
  debounce?: boolean;
  onSuccess?: OnSuccesser;
}

export interface SetDataOpts {
  data: Data;
  submit?: boolean;
  debounce?: boolean;
  reset?: boolean;
}

export type FieldSetter = (setFieldOpts: SetFieldOpts) => any;
export type Submitter = (e?: FormEvent, data?: Data) => Promise<any>;
export type DataSetter = (setDataOpts: SetDataOpts) => any;
export type PrefillFn = (data: any) => Data | null | undefined;
export type ValueGetter = (path: string) => any;
export type OnSuccesser = (resource: any) => any;
export type FieldCleaner = (key: string, value: any) => any;

interface Data {
  [key: string]: any;
}

export interface UseFormInterface<T> {
  data: T;
  submitting: boolean;
  success: boolean;
  hasMadeChanges: boolean;
  loadingPrefill: boolean;
  setField: FieldSetter;
  getValue: ValueGetter;
  setData: DataSetter;
  submit: Submitter;
  endpoint: string;
  diff?: boolean;
  prefillFn?: PrefillFn;
  replaceData?: boolean;
  syncState?: string;
  refresh: (endpoint?: string) => Promise<void>;
}

interface UseFormOpts extends ApiOpts {
  onSuccess?: OnSuccesser;
  onPrefillLoad?: OnSuccesser;
  prefillEndpoint?: string;
  prefillQueryParams?: RequestData;
  prefillFn?: PrefillFn;
  replaceData?: boolean;
  askBeforeSave?: string;
  diff?: boolean;
  submitOnSet?: boolean;
  postAll?: boolean;
  cleanField?: FieldCleaner;
}

function useForm<T>(initialData: Data, opts: UseFormOpts): UseFormInterface<T> {
  const [originalData, setOriginalData] = useState(initialData);
  const [data, setData] = useState(clone(initialData));
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [hasMadeChanges, setHasMadeChanges] = useState(false);
  const [syncState, setSyncState] = useState<string | undefined>();

  const lastChangeHash = useRef<string>("");

  const request = useApi({
    endpoint: opts.prefillEndpoint || "",
    initialData: {},
    queryParams: opts.prefillQueryParams,
    onSuccess: (prefillResource) => {
      if (typeof opts.prefillFn === "function") {
        const r = opts.prefillFn(prefillResource);

        if (r) {
          setData(r);
          setOriginalData(r);
        }
      } else {
        setData(prefillResource);
        setOriginalData(prefillResource);
      }

      if (typeof opts.onPrefillLoad === "function") {
        opts.onPrefillLoad(prefillResource);
      }

      setSyncState(Date.now().toString());
    }
  });

  useEffect(() => {
    if (opts.prefillEndpoint) {
      request.fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = useCallback(
    async (
      e?: FormEvent,
      customData?: Data,
      onSuccess?: OnSuccesser,
      hash?: string
    ) => {
      if (!!opts.askBeforeSave && !window.confirm(opts.askBeforeSave)) {
        return;
      }

      if (e) {
        e.preventDefault();
      }

      setSubmitting(true);

      const baseData = !!customData ? customData : data;
      let dataToSend: Data = {};

      if (opts.diff) {
        dataToSend = chchchanges(originalData, baseData, ["parent"]);
      } else if (opts.postAll) {
        dataToSend = baseData;
      } else {
        for (const k in baseData) {
          const value = baseData[k];

          if (
            !opts.prefillEndpoint ||
            !(k in originalData) ||
            !equal(originalData[k], value)
          ) {
            dataToSend[k] = value;
          }
        }
      }

      if (Object.keys(dataToSend).length < 1) {
        setSubmitting(false);
        return;
      }

      if (typeof opts.cleanField === "function") {
        for (const key in dataToSend) {
          dataToSend[key] = opts.cleanField(key, dataToSend[key]);
        }
      }

      try {
        const resource = await api<T>({
          ...opts,
          method: opts.method || "POST",
          body: dataToSend,
          queryParams: opts.queryParams
        });

        setSubmitting(false);
        setSuccess(true);
        setOriginalData(resource);
        setHasMadeChanges(false);

        if (typeof opts.onSuccess === "function") {
          opts.onSuccess(resource);
        }

        if (typeof onSuccess === "function") {
          onSuccess(resource);
        }

        const isLastRequest = !hash || hash === lastChangeHash.current;

        if (opts.replaceData && isLastRequest) {
          if (typeof opts.prefillFn === "function") {
            const r = opts.prefillFn(resource);

            if (r) {
              setData(r);
            }
          } else {
            setData(resource);
          }
        }
      } catch (error) {
        setSubmitting(false);
        setSuccess(false);
        console.error(error);
      }
    },
    [data, opts, originalData]
  );

  const [debouncedSubmit] = useDebouncedCallback(submit, 500);

  const _submit = useCallback(
    (debounce?: boolean) =>
      async (e?: FormEvent, customData?: Data, onSuccess?: OnSuccesser) => {
        const lastChange = new Date().getTime().toString();

        lastChangeHash.current = lastChange;

        if (debounce) {
          debouncedSubmit(e, customData, onSuccess, lastChange);
        } else {
          submit(e, customData, onSuccess, lastChange);
        }
      },
    [submit, debouncedSubmit]
  );

  const setField = useCallback(
    (setFieldOpts: SetFieldOpts) => {
      const updatedData = clone(data);

      objectPath.set(updatedData, setFieldOpts.path, setFieldOpts.value);

      setData(updatedData);
      setHasMadeChanges(!equal(originalData, updatedData));

      if (opts.submitOnSet || setFieldOpts.submit) {
        if (setFieldOpts.debounce) {
          _submit(true)(undefined, updatedData, setFieldOpts.onSuccess);
        } else {
          _submit(false)(undefined, updatedData, setFieldOpts.onSuccess);
        }
      }
    },
    [data, opts, originalData, submit, debouncedSubmit]
  );

  const getValue = useCallback(
    (path: string) => objectPath.get(data, path),
    [data]
  );

  async function refresh(endpoint?: string) {
    try {
      setSubmitting(true);
      const data = await api<T>({
        endpoint: endpoint || opts.prefillEndpoint || opts.endpoint,
        queryParams: opts.queryParams
      });

      if (typeof opts.prefillFn === "function") {
        const r = opts.prefillFn(data);

        if (r) {
          setData(r);
          setOriginalData(r);
        }
      } else {
        setData(data);
        setOriginalData(data);
      }

      setSubmitting(false);
      setHasMadeChanges(false);
      setSyncState(Date.now().toString());
    } catch (error) {
      console.error(error);
    }
  }

  return {
    data: data as T,
    submitting,
    success,
    hasMadeChanges,
    loadingPrefill: request.loading,
    setField,
    getValue,
    setData: (setDataOpts: SetDataOpts) => {
      setData(setDataOpts.data);

      if (setDataOpts.reset) {
        setOriginalData(setDataOpts.data);
        setHasMadeChanges(false);
      } else {
        setHasMadeChanges(!equal(originalData, setDataOpts.data));

        if (setDataOpts.submit) {
          if (setDataOpts.debounce) {
            _submit(true)(undefined, setDataOpts.data);
          } else {
            _submit(false)(undefined, setDataOpts.data);
          }
        }
      }
    },
    submit: _submit(false),
    refresh,
    endpoint: opts.endpoint,
    diff: opts.diff,
    prefillFn: opts.prefillFn,
    syncState,
    replaceData: opts.replaceData
  };
}

export default useForm;
