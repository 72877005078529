import React, { useState, useCallback, useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
import {
  AiOutlineEdit,
  AiOutlineFileImage,
  AiOutlinePlus,
  AiOutlineSearch
} from "react-icons/ai";
import { useDebouncedCallback } from "use-debounce";
import InfiniteScroll from "react-infinite-scroller";
import { RubicsGalleryItem, RubicsGallery } from "@ludens-reklame/rubics-sdk";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { FaGripLines } from "react-icons/fa";
import { SearchOpts, useSearch } from "../../hooks/useApi";
import { Media as IMedia } from "../../types/apiResponses";
import useApiUrl from "../../hooks/useApiUrl";
import transformImage from "../../util/transformImage";
import Tagger from "../Tagger/Tagger";
import { Dragger, Dropper } from "../DnD/DnD";
import BusyBoy from "../BusyBoy/BusyBoy";
import {
  Medias,
  MediaWrapper,
  Image,
  File,
  FileName,
  MediaActions,
  MediaContent,
  FileAlt
} from "../../components/MediaPicker/MediaPicker";
import Fader from "../../style-guide/Fader/Fader";
import EmptyState from "../../style-guide/EmptyState/EmptyState";
import Card from "../../style-guide/Card/Card";
import { Radio } from "../../style-guide/Inputs/Radio";
import { Button, ButtonList } from "../../style-guide/Button/Button";
import { Element, Elements } from "../../style-guide/Elements/Elements";
import { Modal, ModalActions, ModalBody } from "../../style-guide/Modal/Modal";
import Block from "../../style-guide/Block/Block";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import TextInput from "../../style-guide/Inputs/TextInput";
import Text from "../../style-guide/Text/Text";
import PreviewImage from "../../style-guide/PreviewImage/PreviewImage";
import emptyPreviewImage from "../../util/images/empty_preview.png";

interface GalleryInput {
  image: string;
  caption?: string;
}

interface Props {
  value: RubicsGallery;
  readOnly: boolean;
  onChange: (images: GalleryInput[]) => void;
}

const Gallery: React.FC<Props> = ({ onChange, value = [], readOnly }) => {
  const { spawnModal, despawnModal } = useModal();

  const _previews = useMemo(() => {
    const urls = value.map((image) => image.image.url);

    const list = urls.concat(
      Array.from(
        { length: Math.max(0, 3 - urls.length) },
        () => emptyPreviewImage
      )
    );

    return list.slice(0, 3);
  }, [value]);

  const addGalleryHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    spawnModal(
      <GalleryEditor
        value={value}
        readOnly={readOnly}
        despawnModal={despawnModal}
        onChange={onChange}
      />
    );
  };

  return (
    <GalleryWrapper>
      <ImagePreview>
        <CardDeck>
          <CardDeck>
            {_previews.map((src, index) => (
              <ImageCard key={`${src}-${index}`} image={src} />
            ))}
          </CardDeck>
        </CardDeck>
      </ImagePreview>
      <ButtonList gutterTop align="right">
        <Button outlined circular smaller onClick={addGalleryHandler}>
          <AiOutlineEdit />
        </Button>
      </ButtonList>
    </GalleryWrapper>
  );
};

interface GalleryEditorModalProps {
  value: RubicsGallery;
  onChange: (images: GalleryInput[]) => void;
  readOnly: boolean;
  despawnModal: () => void;
}

const GalleryEditor: React.FC<GalleryEditorModalProps> = ({
  value,
  onChange,
  readOnly
}) => {
  const { spawnModal, despawnModal } = useModal();
  const [images, setImages] = useState<RubicsGallery>(value);

  const gallerySearchHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    spawnModal(
      <GallerySearchModal
        value={images}
        handleOnChange={handleOnChange}
        despawnModal={despawnModal}
      />
    );
  };

  const handleOnChange = (items: RubicsGallery) => {
    setImages(items);

    const updated = items.map((item) => ({
      image: item.image._id,
      caption: item.caption || ""
    }));

    onChange(updated);
  };

  return (
    <Modal>
      <ModalBody>
        <Block>
          <Text variant="title" element="h1" gutterBottom>
            Bildesamling
          </Text>

          <Elements>
            <Dropper
              id="gallery"
              onDragEnd={(r) => {
                if (r.destination && Array.isArray(images)) {
                  const list = Array.from(images || []);
                  const [removed] = list.splice(r.source.index, 1);

                  list.splice(r.destination.index, 0, removed);

                  handleOnChange(list);
                }
              }}
            >
              {images.map((m, i) => {
                return (
                  <Dragger
                    key={m.image._id}
                    id={m.image._id}
                    index={i}
                    isDragDisabled={readOnly}
                  >
                    <Element
                      rightIcon={<FaGripLines />}
                      thumbnail={m.image.url}
                      biggerRightSpace
                      onClick={() =>
                        spawnModal(
                          <PreviewOrDeleteModal
                            currentImage={m}
                            value={images}
                            readOnly={readOnly}
                            despawnModal={despawnModal}
                            handleOnChange={handleOnChange}
                          />
                        )
                      }
                    >
                      {m.image.filename}
                    </Element>
                  </Dragger>
                );
              })}
            </Dropper>
            {!readOnly && (
              <Element
                href="#"
                biggerRightSpace
                icon={<AiOutlinePlus />}
                onClick={gallerySearchHandler}
              >
                Legg til
              </Element>
            )}
          </Elements>
        </Block>
      </ModalBody>
      <ModalActions>
        <ButtonList>
          <Button type="button" outlined onClick={despawnModal}>
            Avbryt
          </Button>
          <Button type="button" onClick={despawnModal}>
            Ferdig
          </Button>
        </ButtonList>
      </ModalActions>
    </Modal>
  );
};

interface GallerySearchModalProps {
  value: RubicsGallery;
  handleOnChange: (images: RubicsGallery) => void;
  despawnModal: () => void;
}

const GallerySearchModal: React.FC<GallerySearchModalProps> = ({
  value,
  handleOnChange,
  despawnModal
}) => {
  const [usedQuery, setUsedQuery] = React.useState<string>("");
  const [query, setQuery] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);
  const [selectedMedias, setSelectedMedias] = useState<IMedia[]>([]);
  const url = useApiUrl(["media"]);

  const queryOpts: SearchOpts<IMedia> = {
    endpoint: `${url}/search`,
    limit: 99,
    queryParams: {
      text: query,
      tags: tags.join(","),
      private: false,
      fileType: "image"
    }
  };

  const { search, results, hasMore, loading, hasSearched } = useSearch<IMedia>({
    ...queryOpts,
    onSuccess: () => setUsedQuery(query)
  });

  const handleSearch = useCallback(() => {
    search(queryOpts);
  }, [search, queryOpts]);

  const [debouncedSearch] = useDebouncedCallback(handleSearch, 500);

  useEffect(() => {
    if (hasSearched) {
      debouncedSearch();
    }
  }, [query]);

  const _usableResults = results.filter(
    (item1) => !value.some((item2) => item2.image._id === item1._id)
  );

  return (
    <Modal fullscreen>
      <ModalBody>
        <Block>
          <Fader>
            {hasSearched &&
              _usableResults.length === 0 &&
              !usedQuery &&
              tags.length === 0 && (
                <EmptyState
                  title="Her var det tomt, gitt"
                  text="Her kan du laste opp bilder, videoer og andre filer til bruk på nettsiden din."
                  icon={<AiOutlineFileImage />}
                />
              )}
            <BusyBoy busy={loading}>
              <InfiniteScroll
                loadMore={() => {
                  search({
                    ...queryOpts,
                    paginate: true
                  });
                }}
                hasMore={hasMore}
              >
                {(_usableResults.length > 0 ||
                  usedQuery ||
                  tags.length > 0) && (
                  <Card>
                    <Block>
                      <Flex>
                        <FlexKid flex={2}>
                          <TextInput
                            icon={<AiOutlineSearch />}
                            placeholder="Søk etter filer"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                          />
                        </FlexKid>
                        <FlexKid flex={1} spaceLeft>
                          <Tagger
                            placeholder="Emneknagger"
                            disableTagCreation
                            inline
                            onChange={(tags) => {
                              setTags(tags);
                              search({
                                ...queryOpts,
                                queryParams: {
                                  ...queryOpts.queryParams,
                                  tags: tags.join(",")
                                }
                              });
                            }}
                          />
                        </FlexKid>
                        <FlexKid
                          spaceLeft
                          onClick={() => {
                            setSelectedMedias(_usableResults);
                          }}
                        >
                          <Button>Velg alle</Button>
                        </FlexKid>
                      </Flex>
                    </Block>
                    {_usableResults.length > 0 && (
                      <Block hugTop>
                        <Medias>
                          {_usableResults.map((r) => {
                            const selected = selectedMedias.some(
                              (m) => m._id === r._id
                            );

                            return (
                              <MediaWrapper key={r._id}>
                                <MediaContent>
                                  {r.fileType === "image" ? (
                                    <Image
                                      src={transformImage(
                                        r.secure_url,
                                        "w_400"
                                      )}
                                      alt={r.original_filename}
                                    />
                                  ) : (
                                    <File type={r.type} />
                                  )}
                                  <FileName>{r.original_filename}</FileName>
                                  {r.altText && <FileAlt>{r.altText}</FileAlt>}
                                  {(r.tags || []).length > 0 && (
                                    <FileAlt>{r.tags.join(", ")}</FileAlt>
                                  )}
                                </MediaContent>
                                <MediaActions>
                                  <Radio
                                    label="Velg"
                                    standalone
                                    checkbox
                                    checked={selected}
                                    onChange={() => {
                                      if (selected) {
                                        setSelectedMedias(
                                          selectedMedias.filter(
                                            (m) => m._id !== r._id
                                          )
                                        );
                                      } else {
                                        setSelectedMedias([
                                          ...selectedMedias,
                                          r
                                        ]);
                                      }
                                    }}
                                  />
                                </MediaActions>
                              </MediaWrapper>
                            );
                          })}
                        </Medias>
                      </Block>
                    )}

                    {_usableResults.length < 1 && (
                      <EmptyState
                        title={
                          <>
                            Ingen treff på "
                            <em>
                              {usedQuery ? `${usedQuery} ` : ""}
                              {tags.join(", ")}
                            </em>
                            "
                          </>
                        }
                      />
                    )}
                  </Card>
                )}
              </InfiniteScroll>
            </BusyBoy>
          </Fader>
        </Block>
      </ModalBody>
      <ModalActions>
        <ButtonList>
          <Button type="button" outlined onClick={despawnModal}>
            Avbryt
          </Button>
          <Button
            type="submit"
            disabled={selectedMedias.length === 0}
            onClick={() => {
              const current: RubicsGallery = selectedMedias.map((m) => ({
                image: {
                  _id: m._id,
                  url: m.secure_url,
                  filename: m.original_filename,
                  fileType: m.fileType,
                  alt: m.altText || ""
                },
                caption: ""
              }));

              handleOnChange([...value, ...current]);
              despawnModal();
            }}
          >
            Legg til
          </Button>
        </ButtonList>
      </ModalActions>
    </Modal>
  );
};

interface PreviewOrDeleteModalProps {
  currentImage: RubicsGalleryItem;
  handleOnChange: (images: RubicsGallery) => void;
  despawnModal: () => void;
  value: RubicsGallery;
  readOnly: boolean;
}

const PreviewOrDeleteModal: React.FC<PreviewOrDeleteModalProps> = ({
  currentImage,
  value,
  handleOnChange,
  despawnModal
}) => {
  return (
    <Modal>
      <ModalBody>
        <Block>
          <Text element="h1" variant="title" gutterBottom>
            {currentImage.image.filename}
          </Text>
          <PreviewImage
            src={currentImage.image.url}
            alt={currentImage.image.filename}
            maxWidth="500px"
          />
        </Block>
      </ModalBody>
      <ModalActions>
        <ButtonList>
          <Button
            type="button"
            outlined
            onClick={() => {
              const updated = value.filter(
                (item) => item.image._id !== currentImage.image._id
              );

              handleOnChange(updated);
              despawnModal();
            }}
          >
            Fjern
          </Button>
          <Button type="button" onClick={despawnModal}>
            Ferdig
          </Button>
        </ButtonList>
      </ModalActions>
    </Modal>
  );
};

const GalleryWrapper = styled.div`
  padding: ${(props) => props.theme.spacing.small};
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 8px;
  padding: 0;
  display: flex;

  > * {
    &:last-child {
      margin: 0 6px;
      flex: 1;
    }
  }
`;

const ImagePreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.theme.sizes.plusFive};
  color: ${(props) => props.theme.colors.border};
  padding: ${(props) => props.theme.spacing.small};
  margin-bottom: 0;
  width: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-right: 1px solid ${(props) => props.theme.colors.borderDim};
`;

const CardDeck = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const ImageCard = styled.div<{ image?: string }>`
  display: flex;
  align-items: center;
  border-radius: 8px;
  position: relative;
  background-color: ${(props) => props.theme.colors.background};
  z-index: 3;

  &:first-of-type {
    transform: rotate(-13deg);
    left: 30px;
    z-index: 2;
  }

  &:last-of-type {
    transform: rotate(13deg);
    right: 30px;
    z-index: 2;
  }

  ${(props) =>
    props.image &&
    css`
      background-image: url(${props.image});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100px;
      height: 100px;
      box-shadow: 2px 2px 8px 3px ${(props) => props.theme.colors.border};

      &:first-of-type {
        width: 80px;
        height: 80px;
      }

      &:last-of-type {
        width: 80px;
        height: 80px;
      }
    `};
`;

export default Gallery;
