import React from "react";
import styled, { css } from "styled-components";
import Text from "../Text/Text";

interface ElementsProps {
  bare?: boolean;
  splitter?: boolean;
  background?: boolean;
}

const Elements = styled.div<ElementsProps>`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 8px;

  ${(props) =>
    props.bare &&
    css`
      border: none;
      border-radius: 0;
    `}

  ${(props) =>
    props.splitter &&
    css`
      border-bottom-width: 4px;
    `}


  > a:last-child {
    border-bottom: none;
  }

  ${(props) =>
    props.background &&
    css`
      background-color: ${props.theme.colors.backgroundVariant};
    `}
`;

interface ElementWrapperProps {
  rightIcon?: boolean;
  leftIcon?: boolean;
  hug?: boolean;
  biggerSpacing?: boolean;
  biggerRightSpace?: boolean;
  minHeight?: boolean;
}

const ElementWrapper = styled.a<ElementWrapperProps>`
  display: flex;
  color: ${(props) => props.theme.colors.textPrimary};
  padding: ${(props) => `0.74em ${props.theme.spacing.small}`};
  cursor: pointer;
  transition: all 0.12s ease-in-out;
  text-decoration: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderDim};
  position: relative;
  align-items: center;

  &:hover,
  &:focus,
  &:active,
  &.active {
    text-decoration: none;
    background-color: ${(props) => props.theme.colors.background};
  }

  ${(props) =>
    props.hug &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}

  ${(props) =>
    props.biggerSpacing &&
    css`
      padding-left: ${props.theme.spacing.medium};
      padding-right: ${props.theme.spacing.medium};
    `}

  ${(props) =>
    props.rightIcon &&
    css`
      padding-right: calc(30px + ${(props) => props.theme.spacing.small});

      ${props.biggerRightSpace &&
      css`
        padding-right: calc(30px + ${(props) => props.theme.spacing.large});
      `}
    `}

    ${(props) =>
    props.minHeight &&
    css`
      min-height: 64px;
    `};
`;

export const SimpleElement = styled.a<ElementWrapperProps>`
  display: block;
  color: ${(props) => props.theme.colors.textPrimary};
  padding: ${(props) => `0.74em ${props.theme.spacing.small}`};
  cursor: pointer;
  transition: all 0.12s ease-in-out;
  text-decoration: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderDim};
  position: relative;
  align-items: center;

  &:hover,
  &:focus,
  &:active,
  &.active {
    text-decoration: none;
    background-color: ${(props) => props.theme.colors.background};
  }

  ${(props) =>
    props.biggerSpacing &&
    css`
      padding-left: ${props.theme.spacing.medium};
      padding-right: ${props.theme.spacing.medium};
    `}
`;

interface TitleProps {
  bigger?: boolean;
}

const Title = styled.p<TitleProps>`
  margin: 0;

  ${(props) =>
    props.bigger &&
    css`
      font-size: ${(props) => props.theme.sizes.plusOne};
      font-family: "Work Sans";
    `}
`;

interface IconProps {
  right?: boolean;
  bigger?: boolean;
}

const Icon = styled.span<IconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  margin-right: ${(props) => props.theme.spacing.small};
  flex-shrink: 0;

  ${(props) =>
    props.right &&
    css`
      margin-right: 0;
      position: absolute;
      left: auto;
      right: 6px;
      border-radius: 50%;
      border: 1px solid ${(props) => props.theme.colors.border};

      ${props.bigger &&
      css`
        right: ${props.theme.spacing.small};
      `}
    `}

  > img {
    width: 30px;
  }

  a {
    color: inherit;
  }
`;

const Thumbnail = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => props.theme.spacing.small};
  flex-shrink: 0;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  object-fit: cover;
`;

interface ElementProps extends React.HTMLProps<HTMLAnchorElement> {
  icon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  className?: string;
  subText?: string;
  bigger?: boolean;
  biggerSpacing?: boolean;
  biggerRightSpace?: boolean;
  hug?: boolean;
  minHeight?: boolean;
  thumbnail?: string;
}

const Element: React.FC<ElementProps> = ({
  icon,
  rightIcon,
  children,
  onClick,
  href,
  subText,
  className,
  bigger,
  biggerSpacing,
  biggerRightSpace,
  hug,
  target,
  rel,
  minHeight,
  thumbnail
}) => {
  return (
    <ElementWrapper
      className={className}
      href={href}
      onClick={onClick}
      rightIcon={!!rightIcon}
      leftIcon={!!icon}
      hug={hug}
      biggerSpacing={biggerSpacing}
      biggerRightSpace={biggerRightSpace}
      target={target}
      rel={rel}
      minHeight={minHeight}
    >
      {icon && <Icon>{icon}</Icon>}
      {thumbnail && <Thumbnail src={thumbnail} alt="thumbnail" />}
      <div>
        <Title bigger={bigger}>{children}</Title>
        {subText && <Text variant="body3">{subText}</Text>}
      </div>
      {rightIcon && (
        <Icon right bigger={bigger || biggerRightSpace}>
          {rightIcon}
        </Icon>
      )}
    </ElementWrapper>
  );
};

export { Elements, Element };
