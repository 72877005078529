import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineFileAdd
} from "react-icons/ai";
import {
  Image as ImageClass,
  Product as ProductClass,
  RubicsImage
} from "@ludens-reklame/rubics-sdk";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import Image from "../../style-guide/Image/Image";
import convertImage from "../../util/convertImage";
import { Product } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import { Button, ButtonList } from "../../style-guide/Button/Button";
import CrudModal from "../CrudModal/CrudModal";
import ProductPicker, { ProductTable } from "../ProductPicker/ProductPicker";
import { RequestData } from "../../util/api";

export const Wrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 8px;
  display: flex;

  > * {
    &:last-child {
      margin: 0 6px;
      flex: 1;
    }
  }
`;

export const Content = styled.div`
  padding: ${(props) =>
    `${props.theme.spacing.xs} ${props.theme.spacing.small}`};
  width: 100%;
  border-right: 1px solid ${(props) => props.theme.colors.borderDim};
`;

export const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.theme.sizes.plusFive};
  color: ${(props) => props.theme.colors.border};
  height: 60px;
  width: 100%;
`;

interface Props {
  product?: Product;
  queryParams?: RequestData;
  onChange: (product?: Product) => any;
  readOnly?: boolean;
}

const ProductSelector: React.FC<Props> = ({
  product,
  queryParams,
  onChange,
  readOnly
}) => {
  const { spawnModal } = useModal();

  const primaryImage = useMemo<RubicsImage>(
    () =>
      ProductClass.getPrimaryImage({
        images: product ? product.images.map(convertImage) : []
      }),
    [product]
  );

  const addProductModal = useCallback(() => {
    if (readOnly) {
      return;
    }

    spawnModal(
      <CrudModal
        title="Legg til produkt"
        initialData={{}}
        fullscreen
        onSubmit={(data) => {
          if (!readOnly && data && Array.isArray(data.products)) {
            if (data.products.length > 0) {
              onChange(data.products[0]);
            } else {
              onChange(undefined);
            }
          }
        }}
        fields={[
          {
            key: "product",
            render: (payload) => (
              <ProductPicker
                queryParams={queryParams}
                useWindow={false}
                getScrollParent={() => payload.modalBodyRef.current}
                renderView={(products) => (
                  <ProductTable
                    products={products}
                    selectedProducts={product ? [product] : undefined}
                    onSelect={(products) =>
                      payload.setField("products", products)
                    }
                  />
                )}
              />
            )
          }
        ]}
      />
    );
  }, [product, queryParams, onChange, readOnly]);

  return (
    <Wrapper>
      <Content>
        {product ? (
          <Flex>
            <FlexKid>
              <Image
                noMaxWidth
                width={60}
                height={60}
                src={ImageClass.createUrl({
                  image: primaryImage,
                  transforms: "w_120,h_120,c_pad"
                })}
              />
            </FlexKid>
            <FlexKid flex={1} spaceLeft tight>
              <Text variant="body2">{product.name}</Text>
              <Text variant="body3">{product.sku || "Mangler SKU"}</Text>
            </FlexKid>
          </Flex>
        ) : (
          <EmptyState>
            <AiOutlineFileAdd />
          </EmptyState>
        )}
      </Content>
      <ButtonList align="center" tight vertical>
        <Button
          type="button"
          circular
          outlined
          smaller
          disabled={readOnly}
          onClick={addProductModal}
        >
          <AiOutlineEdit />
        </Button>
        {product && (
          <Button
            type="button"
            circular
            outlined
            smaller
            disabled={readOnly}
            onClick={() => {
              if (!readOnly) {
                if (window.confirm("Er du sikker på at du vil slette?")) {
                  onChange();
                }
              }
            }}
          >
            <AiOutlineDelete />
          </Button>
        )}
      </ButtonList>
    </Wrapper>
  );
};

export default ProductSelector;
