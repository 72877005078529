import React, { useContext, ReactNode } from "react";
import { RouteComponentProps } from "react-router";
import InfiniteScroll from "react-infinite-scroller";
import {
  ComponentProp,
  DataSet as DataSetType
} from "@ludens-reklame/rubics-sdk";
import apiUrl from "../../util/apiUrl";
import getImage from "../../util/getImageUrlFromImageRef";
import { SiteThemeContext } from "../../context/Theme";
import { useApi, useSearch } from "../../hooks/useApi";
import BusyBoy from "../../components/BusyBoy/BusyBoy";
import Crumb from "../../components/Crumb/Crumb";
import {
  ButtonLink,
  ButtonList,
  Button
} from "../../style-guide/Button/Button";
import Card from "../../style-guide/Card/Card";
import { Table, Tr, Th, Td } from "../../style-guide/Table/Table";
import Text from "../../style-guide/Text/Text";
import Image from "../../style-guide/Image/Image";
import { ImageRef } from "../../types/apiResponses";
import Block from "../../style-guide/Block/Block";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import EmptyState from "../../style-guide/EmptyState/EmptyState";
import Doorman from "../../components/Doorman/Doorman";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

interface Params {
  dataSet: string;
}

const DataSet: React.FC<RouteComponentProps<Params>> = ({ match }) => {
  const baseUrl = apiUrl(["data-sets", match.params.dataSet]);
  const theme = useContext(SiteThemeContext).data;

  const dataSet =
    theme && Array.isArray(theme.dataSets)
      ? theme.dataSets.find((d) => d.name === match.params.dataSet)
      : undefined;

  const queryOpts = {
    endpoint: baseUrl
  };

  const datasetRequest = useSearch<any>(queryOpts);

  const destroyRequest = useApi<any>({
    endpoint: "",
    method: "DELETE",
    initialData: null
  });

  if (!dataSet) {
    return null;
  }

  return (
    <>
      <Block hugTop>
        <Flex justify="space-between">
          <FlexKid>
            <Text element="h1" variant="display3">
              <Crumb url="/innhold">Innhold</Crumb>
              {dataSet.label}
            </Text>
          </FlexKid>
          <Doorman type="contributor">
            <FlexKid>
              <ButtonLink to={`${dataSet.name}/opprett`}>Opprett ny</ButtonLink>
            </FlexKid>
          </Doorman>
        </Flex>
      </Block>
      {datasetRequest.hasSearched && datasetRequest.results.length === 0 && (
        <EmptyState
          title="Her var det tomt, gitt"
          action={
            <Doorman type="contributor">
              <ButtonLink to={`${dataSet.name}/opprett`}>Opprett ny</ButtonLink>
            </Doorman>
          }
        />
      )}
      <BusyBoy busy={datasetRequest.loading || destroyRequest.loading}>
        <InfiniteScroll
          pageStart={0}
          loadMore={() =>
            datasetRequest.search({
              ...queryOpts,
              paginate: true
            })
          }
          hasMore={datasetRequest.hasMore}
        >
          {datasetRequest.results.length > 0 && (
            <Card>
              <Table>
                <thead>
                  <Tr>
                    {dataSet.props
                      .filter(
                        (p) =>
                          p.type !== "array" &&
                          p.type !== "object" &&
                          p.type !== "draft"
                      )
                      .map((p) => (
                        <Th key={p.name}>{p.label || p.name}</Th>
                      ))}
                    <Th align="right">Handlinger</Th>
                  </Tr>
                </thead>
                <tbody>
                  {datasetRequest.results.map((resource) => (
                    <Tr key={resource._id}>
                      {dataSet.props
                        .filter(
                          (p) =>
                            p.type !== "array" &&
                            p.type !== "object" &&
                            p.type !== "draft"
                        )
                        .map((p) => {
                          return (
                            <Td verticalAlign="middle" key={p.name}>
                              {renderValue(
                                p,
                                resource[p.name],
                                theme!.dataSets || []
                              )}
                            </Td>
                          );
                        })}
                      <Td verticalAlign="middle" align="right">
                        <ButtonList align="right">
                          <ButtonLink
                            circular
                            outlined
                            smaller
                            to={`${dataSet.name}/${resource._id}`}
                          >
                            <AiOutlineEdit />
                          </ButtonLink>
                          <Button
                            circular
                            outlined
                            smaller
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  "Er du sikker på at du vil slette?"
                                )
                              ) {
                                destroyRequest.fetch({
                                  endpoint: `${baseUrl}/${resource._id}`,
                                  method: "DELETE",
                                  onSuccess: () => {
                                    datasetRequest.search(queryOpts);
                                  }
                                });
                              }
                            }}
                          >
                            <AiOutlineDelete />
                          </Button>
                        </ButtonList>
                      </Td>
                    </Tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          )}
        </InfiniteScroll>
      </BusyBoy>
    </>
  );
};

function renderValue(
  prop: ComponentProp,
  value: any,
  datasets: DataSetType[]
): ReactNode {
  switch (prop.type) {
    case "ref":
      if (prop.to === "dataset") {
        const referredDataset = datasets.find((d) => d.name === prop.dataset);

        if (
          value &&
          referredDataset &&
          typeof referredDataset.previewFn === "function"
        ) {
          return referredDataset.previewFn(value).title;
        }
      }

      return "Mangler forhåndsvisning";

    case "image":
      const image = value as ImageRef;

      return (
        <Image
          src={getImage(image)}
          alt={image ? image.alt : "Mangler bilde"}
        />
      );

    case "draft":
      return null;
    default:
      return value;
  }
}

export default DataSet;
