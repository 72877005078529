import { useCallback, useState } from "react";

interface IdImplementer {
  _id: string;
}

interface UseMultiSelectOpts<T> {
  elements: T[];
  onSelect?: (elements: T[]) => any;
  multipleSelect?: boolean;
  initialValue?: T[];
}

interface UseMultiSelectInterface<T> {
  selectedElements: T[];
  select: (element: T, selected: boolean) => void;
  selectAll: (selected: boolean) => void;
}

function useMultiSelect<T extends IdImplementer>(
  opts: UseMultiSelectOpts<T>
): UseMultiSelectInterface<T> {
  const [selectedElements, setSelectedElements] = useState<T[]>(
    opts.initialValue || []
  );

  const select = useCallback(
    (element: T, selected: boolean) => {
      let elements = selectedElements;

      if (opts.multipleSelect) {
        const elementInList = elements.some((e) => e._id === element._id);

        if (selected) {
          if (!elementInList) {
            elements = [...elements, element];
          }
        } else if (elementInList) {
          elements = elements.filter((e) => e._id !== element._id);
        }
      } else {
        elements = [element];
      }

      setSelectedElements(elements);

      if (typeof opts.onSelect === "function") {
        opts.onSelect(elements);
      }
    },
    [selectedElements, opts.onSelect, opts.multipleSelect]
  );

  const selectAll = useCallback(
    (selected: boolean) => {
      let elements = selectedElements;

      for (let i = 0; i < opts.elements.length; i++) {
        const element = opts.elements[i];
        const elementInList = elements.some((e) => e._id === element._id);

        if (selected) {
          if (!elementInList) {
            elements = [...elements, element];
          }
        } else if (elementInList) {
          elements = elements.filter((e) => e._id !== element._id);
        }
      }

      setSelectedElements(elements);

      if (typeof opts.onSelect === "function") {
        opts.onSelect(elements);
      }
    },
    [opts.onSelect, opts.elements]
  );

  return {
    selectedElements,
    select,
    selectAll
  };
}

export default useMultiSelect;
