const safeRawDraft = (text: string) =>
  `{"blocks":[{"key":"foooo","text":"${text}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;

function safeDraft(richTextOrJustString: string): any {
  if (
    richTextOrJustString.length > 0 &&
    richTextOrJustString.charAt(0) === "{"
  ) {
    try {
      return JSON.parse(richTextOrJustString);
    } catch (e) {}
  }

  // Replaces all occurrences of " to \". Will not replace \" to \\".
  const jsonSafeText = richTextOrJustString.replace(/([^\\])(")/g, '$1\\"');

  try {
    return JSON.parse(safeRawDraft(jsonSafeText));
  } catch (e) {
    return JSON.parse(safeRawDraft(""));
  }
}

export default safeDraft;
