import React, { ReactNode, useMemo } from "react";
import moment from "moment";
import isPlainObject from "is-plain-object";
import fileSize from "filesize";
import InfiniteScroll from "react-infinite-scroller";
import { AiOutlineDownload } from "react-icons/ai";
import { RubicsFormFieldType } from "@ludens-reklame/rubics-sdk";
import { Form, FormEntry, FormField } from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import Section from "../../../style-guide/Section/Section";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";
import { SearchOpts, useSearch } from "../../../hooks/useApi";
import { Table, Tr, Th, Td } from "../../../style-guide/Table/Table";
import { Element, Elements } from "../../../style-guide/Elements/Elements";

interface Props {
  form: Form;
}

const Entries: React.FC<Props> = ({ form }) => {
  const queryOpts = useMemo<SearchOpts<FormEntry>>(
    () => ({
      endpoint: `forms/${form._id}/entries`,
      limit: 50
    }),
    [form._id]
  );

  const { results, search, loading, hasMore, hasSearched } =
    useSearch<FormEntry>(queryOpts);

  return (
    <Fader>
      <Section hugTop>
        <Card>
          <Block>
            <Text element="h2" variant="title">
              Innsendelser
            </Text>
          </Block>
          <BusyBoy busy={loading}>
            <InfiniteScroll
              pageStart={1}
              initialLoad
              loadMore={() =>
                search({
                  ...queryOpts,
                  paginate: true
                })
              }
              hasMore={hasMore}
            >
              <EntriesTable
                form={form}
                entries={results}
                hasFetchedEntries={hasSearched}
                showMeta
              />
            </InfiniteScroll>
          </BusyBoy>
        </Card>
      </Section>
    </Fader>
  );
};

interface EntriesTableProps {
  form: Form;
  entries: FormEntry[];
  hasFetchedEntries: boolean;
  truncateFieldLimit?: number;
  showMeta?: boolean;
}

export const EntriesTable: React.FC<EntriesTableProps> = ({
  form,
  entries,
  hasFetchedEntries,
  truncateFieldLimit,
  showMeta
}) => {
  const fields = useMemo<FormField[]>(() => {
    if (
      typeof truncateFieldLimit === "number" &&
      form.fields.length > truncateFieldLimit
    ) {
      return form.fields.slice(0, truncateFieldLimit);
    }

    return form.fields;
  }, [form.fields, truncateFieldLimit]);

  const truncated = useMemo<boolean>(() => {
    return form.fields.length > fields.length;
  }, [form.fields, fields]);

  return (
    <Table>
      <thead>
        <Tr>
          {fields.map((f) => (
            <Th key={f.name}>{f.label}</Th>
          ))}
          {showMeta && <Th>Innsendt</Th>}
          {truncated && <Th>…</Th>}
        </Tr>
      </thead>
      <tbody>
        {hasFetchedEntries && entries.length === 0 ? (
          <Tr>
            <Td colSpan={showMeta ? fields.length + 1 : fields.length}>
              <Text variant="body3">Ingen innsendelser funnet</Text>
            </Td>
          </Tr>
        ) : (
          entries.map((e) => (
            <Tr key={e._id}>
              {fields.map((f) => (
                <Row key={f.name} field={f} entry={e} />
              ))}
              {showMeta && (
                <Td>
                  <Text>
                    {moment(e.timestamp).format("D[.] MMMM Y [kl.] HH:mm")}
                  </Text>
                  <Text variant="body3">{e.referrer || "–"}</Text>
                </Td>
              )}
              {truncated && <Td>…</Td>}
            </Tr>
          ))
        )}
      </tbody>
    </Table>
  );
};

interface RowProps {
  field: FormField;
  entry: FormEntry;
}

const Row: React.FC<RowProps> = ({ field, entry }) => {
  const value = useMemo<ReactNode>(() => {
    const data = entry.data.find((d) => d.field === field.name);

    if (!data) {
      return <Text variant="body3">–</Text>;
    }

    if (field.type === RubicsFormFieldType.File && Array.isArray(data.value)) {
      if (data.value.length === 0) {
        return <Text variant="body3">–</Text>;
      }

      return (
        <Elements>
          {data.value
            .filter((v) => isPlainObject(v))
            .map((v) => (
              <Element
                key={v._id}
                href={v.url}
                target="_blank"
                rel="noopener noreferrer nofollow"
                rightIcon={<AiOutlineDownload />}
                biggerRightSpace
              >
                <Text>{v.filename}</Text>
                <Text variant="body3">{fileSize(v.size)}</Text>
              </Element>
            ))}
        </Elements>
      );
    } else if (
      field.type === RubicsFormFieldType.Checkbox &&
      Array.isArray(data.value)
    ) {
      return <Text>{data.value.join(", ")}</Text>;
    }

    return <Text>{data.value}</Text>;
  }, [field, entry]);

  return <Td>{value}</Td>;
};

export default Entries;
