import React, { useState, useEffect, ReactNode } from "react";
import AnimateHeight from "react-animate-height";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import {
  InlineEditElement,
  InlineEditHeader,
  InlineEditBody,
  InlineEditColumn,
  InlineEditElementBackground
} from "../../style-guide/InlineEdit/InlineEdit";
import { Button, ButtonList } from "../../style-guide/Button/Button";
import { FlexAlign } from "../../types/general";

interface HeaderColumn {
  width: string;
  node: ReactNode;
  alignRight?: boolean;
  alignSelf?: FlexAlign;
  hide?: boolean;
}

interface Props {
  headerColumns: HeaderColumn[];
  actions?: ReactNode[];
  expanded?: boolean;
  alignItems?: FlexAlign;
  background?: InlineEditElementBackground;
  fullClickExpand?: boolean;
  isolated?: boolean;
  tight?: boolean;
}

const InlineEdit: React.FC<Props> = ({
  headerColumns,
  expanded,
  actions,
  alignItems,
  background,
  isolated,
  fullClickExpand,
  tight,
  children
}) => {
  const [_expanded, setExpanded] = useState(expanded || false);
  const [renderContent, setRenderContent] = useState(expanded || false);

  useEffect(() => {
    if (typeof expanded === "boolean") {
      setExpanded(expanded);
    }
  }, [expanded]);

  return (
    <InlineEditElement isolated={isolated} background={background}>
      <InlineEditHeader
        alignItems={alignItems}
        fullClickExpand={fullClickExpand}
        onClick={() => {
          if (fullClickExpand) {
            if (!_expanded) {
              setRenderContent(true);
            }

            setExpanded(!_expanded);
          }
        }}
      >
        {headerColumns
          .filter((c) => !c.hide)
          .map((c, k) => (
            <InlineEditColumn
              key={k}
              width={c.width}
              alignRight={c.alignRight}
              alignSelf={c.alignSelf}
              tight={tight}
            >
              {c.node}
            </InlineEditColumn>
          ))}
        {(children || actions) && (
          <InlineEditColumn alignRight alignSelf="center">
            <ButtonList align="right">
              {actions}
              {children && (
                <Button
                  type="button"
                  circular
                  smaller
                  outlined
                  aria-label={_expanded ? "Minimer felt" : "Ekspander felt"}
                  onClick={() => {
                    if (!_expanded) {
                      setRenderContent(true);
                    }

                    setExpanded(!_expanded);
                  }}
                >
                  {_expanded ? <AiOutlineUp /> : <AiOutlineDown />}
                </Button>
              )}
            </ButtonList>
          </InlineEditColumn>
        )}
      </InlineEditHeader>
      {children && (
        <AnimateHeight
          height={_expanded ? "auto" : 0}
          onAnimationEnd={() => {
            if (!_expanded) {
              setRenderContent(false);
            }
          }}
        >
          {renderContent && (
            <InlineEditBody isolated={isolated}>{children}</InlineEditBody>
          )}
        </AnimateHeight>
      )}
    </InlineEditElement>
  );
};

export default InlineEdit;
