import { useMemo } from "react";
import * as qs from "qs";

function useRevisionId(): string | undefined {
  return useMemo<string | undefined>(() => {
    const params = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    return params.revision;
  }, []);
}

export default useRevisionId;
