import React, { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";
import Card from "../Card/Card";
import Block from "../Block/Block";

const Timeline = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 auto;
`;

export const TimelineItemWrapper = styled.li`
  &:not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing.small};
  }
`;

const Topline = styled.div`
  color: ${(props) => props.theme.colors.textSecondary};
`;

const Byline = styled.div`
  color: ${(props) => props.theme.colors.textSecondary};
  padding-left: ${(props) => props.theme.spacing.small};
`;

const Head = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderDim};
`;

const Icon = styled.div`
  padding: ${(props) =>
    `${props.theme.spacing.small} ${props.theme.spacing.medium}`};
  padding-right: 0;
`;

const HeadContent = styled.div`
  padding: ${(props) =>
    `${props.theme.spacing.small} ${props.theme.spacing.medium}`};
  padding-left: ${(props) => props.theme.spacing.small};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

const TimelineHl = styled.span`
  color: ${(props) => props.theme.colors.textPrimary};
`;

interface TimelineItemProps {
  icon: ReactNode;
  topLine: ReactNode;
  byline: ReactNode;
}

const TimelineItem: FunctionComponent<TimelineItemProps> = ({
  icon,
  topLine,
  byline,
  children
}) => {
  return (
    <TimelineItemWrapper>
      <Card>
        <Head>
          <Icon>{icon}</Icon>
          <HeadContent>
            <Topline>{topLine}</Topline>
            <Byline>{byline}</Byline>
          </HeadContent>
        </Head>
        <Block>{children}</Block>
      </Card>
    </TimelineItemWrapper>
  );
};

export { Timeline, TimelineItem, TimelineHl };
