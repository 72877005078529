import React from "react";
import { Switch, Route } from "react-router";
import Attributes from "./Attributes";
import Attribute from "./Attribute";

const AttributesRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/butikk/attributter" component={Attributes} />
      <Route
        path="/butikk/attributter/:attribute"
        render={props => (
          <Attribute key={props.match.params.attribute} {...props} />
        )}
      />
    </Switch>
  );
};

export default AttributesRoutes;
