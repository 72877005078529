export default function ellipsis(str: string) {
  const length = 170;
  const ending = "...";

  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  }

  return str;
}
