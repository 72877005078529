import React from "react";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroller";
import { AiOutlineMeh } from "react-icons/ai";
import Fader from "../../../style-guide/Fader/Fader";
import useApiUrl from "../../../hooks/useApiUrl";
import { SearchOpts, useSearch } from "../../../hooks/useApi";
import { Activity as IActivity } from "../../../types/apiResponses";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";
import {
  Timeline,
  TimelineItem,
  TimelineHl
} from "../../../style-guide/Timeline/Timeline";
import Avatar from "../../../style-guide/Avatar/Avatar";
import fallbackImage from "../../../util/images/empty_preview.png";
import Pre from "../../../style-guide/Pre/Pre";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import Crumb from "../../../components/Crumb/Crumb";
import EmptyState from "../../../style-guide/EmptyState/EmptyState";

const Activity: React.FC = () => {
  const url = useApiUrl(["activity"]);

  const searchOpts: SearchOpts<IActivity> = {
    endpoint: url
  };

  const activity = useSearch<IActivity>(searchOpts);

  return (
    <Fader>
      <Block hugTop>
        <Text element="h1" variant="display3">
          <Crumb url="/innstillinger">Innstillinger</Crumb>
          Aktivitetslogg
        </Text>
      </Block>
      <InfiniteScroll
        pageStart={1}
        loadMore={() =>
          activity.search({
            ...searchOpts,
            paginate: true
          })
        }
        hasMore={activity.hasMore}
      >
        <BusyBoy busy={activity.loading}>
          {activity.hasSearched && activity.results.length > 0 ? (
            <Timeline>
              {activity.results.map((a) => {
                const user = typeof a.user !== "string" ? a.user : null;

                return (
                  <TimelineItem
                    key={a._id}
                    icon={
                      <Avatar
                        size="36px"
                        image={
                          user && user.profileImage
                            ? user.profileImage
                            : fallbackImage
                        }
                      />
                    }
                    topLine={
                      <TimelineHl>
                        {user ? user.name || user.email : "Anonym bruker"}
                      </TimelineHl>
                    }
                    byline={moment(a.timestamp).fromNow()}
                  >
                    <Pre>
                      {a.method} {a.url}
                    </Pre>
                  </TimelineItem>
                );
              })}
            </Timeline>
          ) : (
            <EmptyState
              title="Her var det lite aktivitet, gitt"
              text="Men, frykt ikke, denne fyller seg opp raskere enn hva som er komfortabelt."
              icon={<AiOutlineMeh />}
            />
          )}
        </BusyBoy>
      </InfiniteScroll>
    </Fader>
  );
};

export default Activity;
