import React from "react";
import { Switch, Route } from "react-router";
import Component from "./Component";
import Components from "./Components";

const ComponentsRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/innhold/komponenter" component={Components} />
      <Route
        exact
        path="/innhold/komponenter/:component"
        component={Component}
      />
    </Switch>
  );
};

export default ComponentsRoutes;
