import React, { ReactNode, useState } from "react";
import { FaGripLines } from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { RequestData } from "../../util/api";
import SearchAndSelect, {
  ItemToString,
  ArrayFilter
} from "../SearchAndSelect/SearchAndSelect";
import { Elements, Element } from "../../style-guide/Elements/Elements";
import { Dropper, Dragger } from "../DnD/DnD";
import Block from "../../style-guide/Block/Block";
import { ButtonList, Button } from "../../style-guide/Button/Button";
import Text from "../../style-guide/Text/Text";
import Section from "../../style-guide/Section/Section";
import { Modal } from "../../style-guide/Modal/Modal";

interface ListerProps {
  id: string;
  items: any[];
  previewItems: any[];
  url: string[];
  searchKey: string;
  labelKey: string;
  itemToString: ItemToString;
  onChange: (items: any[], previewItems: any[]) => any;
  query?: RequestData;
  searchLabel?: string;
  filter?: ArrayFilter;
  disableDrag?: boolean;
  renderRightIcon?: (item: any) => ReactNode;
  readOnly?: boolean;
}

const Lister: React.FC<ListerProps> = ({
  id,
  items,
  previewItems,
  url,
  searchKey,
  labelKey,
  itemToString,
  onChange,
  query,
  searchLabel,
  filter,
  disableDrag,
  renderRightIcon,
  readOnly
}) => {
  const modal = useModal();

  return (
    <Elements>
      <Dropper
        id={"lister_" + id}
        onDragEnd={(r) => {
          if (r.destination) {
            const list = Array.from((items as any[]) || []);
            const [removed] = list.splice(r.source.index, 1);

            list.splice(r.destination.index, 0, removed);
            onChange(list, previewItems);
          }
        }}
      >
        {items.map((i, k) => {
          let previewElement = previewItems.find((p) => p._id === i);

          if (!previewElement && !(typeof i === "string")) {
            return null;
          }

          if (!previewElement) {
            previewElement = i;
          }

          const id =
            typeof previewElement === "string"
              ? previewElement
              : previewElement._id;

          return (
            <Dragger
              key={id}
              id={id}
              index={k}
              isDragDisabled={disableDrag || readOnly}
            >
              <Element
                rightIcon={
                  !renderRightIcon && disableDrag ? undefined : (
                    <>
                      {renderRightIcon ? renderRightIcon(i) : undefined}
                      {!disableDrag ? <FaGripLines /> : undefined}
                    </>
                  )
                }
                href="#"
                onClick={(ev) => {
                  ev.preventDefault();

                  if (readOnly) {
                    return;
                  }

                  modal.spawnModal(
                    <ListerModal
                      url={url}
                      query={query}
                      searchKey={searchKey}
                      itemToString={itemToString}
                      item={previewElement}
                      searchLabel={searchLabel}
                      filter={filter}
                      onSubmit={(item) =>
                        onChange(
                          items.map((ii, kk) => {
                            if (kk === k) {
                              return typeof item === "string" ? item : item._id;
                            }

                            return ii;
                          }),
                          [...previewItems, item]
                        )
                      }
                      onDeleteClick={() => {
                        onChange(
                          items.filter((_, kk) => kk !== k),
                          previewItems
                        );
                      }}
                    />
                  );
                }}
              >
                {previewElement
                  ? typeof previewElement === "string"
                    ? previewElement
                    : previewElement[labelKey]
                  : "N/A"}
              </Element>
            </Dragger>
          );
        })}
      </Dropper>
      <Element
        rightIcon={<AiOutlinePlus />}
        href="#"
        disabled={readOnly}
        onClick={(e) => {
          e.preventDefault();

          if (readOnly) {
            return;
          }

          modal.spawnModal(
            <ListerModal
              url={url}
              query={query}
              searchKey={searchKey}
              itemToString={itemToString}
              searchLabel={searchLabel}
              filter={filter}
              onSubmit={(item) =>
                onChange(
                  [...items, typeof item === "string" ? item : item._id],
                  [...previewItems, item]
                )
              }
            />
          );
        }}
      >
        Legg til
      </Element>
    </Elements>
  );
};

interface ListerModalProps {
  url: string[];
  searchKey: string;
  itemToString: ItemToString;
  onSubmit: (item: any) => any;
  onDeleteClick?: () => any;
  searchLabel?: string;
  item?: any;
  query?: RequestData;
  filter?: ArrayFilter;
}

const ListerModal: React.FC<ListerModalProps> = ({
  onSubmit,
  url,
  itemToString,
  searchKey,
  onDeleteClick,
  searchLabel,
  item,
  query,
  filter
}) => {
  const [internalItem, setInternalItem] = useState(item);
  const modal = useModal();

  return (
    <Modal>
      <Block>
        {searchLabel && (
          <Section hugTop>
            <Text variant="title">{searchLabel}</Text>
          </Section>
        )}
        <SearchAndSelect
          value={itemToString(item)}
          url={url}
          itemToString={itemToString}
          searchKey={searchKey}
          query={query}
          nonDeletable
          filter={filter}
          onChange={(item) => {
            setInternalItem(item);
          }}
        />
        <ButtonList align="right" gutterTop>
          {!!onDeleteClick && (
            <Button
              type="button"
              alternate
              onClick={() => {
                if (window.confirm("Er du sikker på at du vil slette?")) {
                  onDeleteClick();
                  modal.despawnModal();
                }
              }}
            >
              Slett
            </Button>
          )}
          <Button
            type="button"
            alternate
            onClick={() => {
              modal.despawnModal();
            }}
          >
            Avbryt
          </Button>
          {!!internalItem && (
            <Button
              type="button"
              onClick={() => {
                onSubmit(internalItem);
                modal.despawnModal();
              }}
            >
              Lagre
            </Button>
          )}
        </ButtonList>
      </Block>
    </Modal>
  );
};

export default Lister;
