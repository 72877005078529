import React, { useEffect } from "react";
import { RouteComponentProps, Switch, Route, Prompt } from "react-router-dom";
import { AiOutlineProfile, AiOutlineShoppingCart } from "react-icons/ai";
import { Subscription as ISubscription } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import Fader from "../../style-guide/Fader/Fader";
import Block from "../../style-guide/Block/Block";
import usePublisher from "../../hooks/usePublisher";
import Section from "../../style-guide/Section/Section";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import Crumb from "../../components/Crumb/Crumb";
import General from "./SubViews/General";
import Form from "../../style-guide/Inputs/Form";
import ActionBar from "../../components/ActionBar/ActionBar";
import { LEAVE_PAGE_MESSAGE } from "../../constants/general";
import {
  InlineActionLink,
  InlineActions
} from "../../style-guide/InlineActions/InlineActions";
import PublisherHelpers from "../../util/PublisherHelpers";
import Orders from "./SubViews/Orders";

interface Props
  extends RouteComponentProps<{
    subscription: string;
  }> {}

const Subscription: React.FC<Props> = ({ match }) => {
  const subscriptionId = match.params.subscription;

  const breadcrumbs = useBreadcrumbs();

  const publisher = usePublisher<ISubscription>({
    id: subscriptionId,
    baseUrl: ["subscriptions"],
    baseDashboardUrl: "/butikk/abonnementer",
    initialData: {}
  });

  const form = publisher.form;

  useEffect(() => {
    breadcrumbs.swapEntry(
      subscriptionId,
      publisher.form.data.subscriptionNumber
    );
  }, [breadcrumbs, publisher.form.data, subscriptionId]);

  return (
    <Form onSubmit={publisher.form.submit}>
      <Prompt
        when={form.hasMadeChanges}
        message={(location) => {
          if (location.pathname.includes(subscriptionId)) {
            return true;
          }

          return LEAVE_PAGE_MESSAGE;
        }}
      />
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/butikk/abonnementer">Abonnementer</Crumb>
            {form.data.subscriptionNumber
              ? `#${form.data.subscriptionNumber}`
              : "Laster…"}
          </Text>
        </Block>
        <Block hugTop hugBottom>
          <InlineActions>
            <InlineActionLink
              exact
              to={PublisherHelpers.createDashboardUrl(publisher)}
            >
              <AiOutlineProfile /> Generelt
            </InlineActionLink>
            <InlineActionLink
              exact
              to={PublisherHelpers.createDashboardUrl(publisher, ["ordrer"])}
            >
              <AiOutlineShoppingCart /> Ordrer
            </InlineActionLink>
          </InlineActions>
        </Block>
        <Section hugTop>
          <Switch>
            <Route
              exact
              path={publisher.dashboardUrl}
              render={() => <General publisher={publisher} />}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/ordrer`}
              render={() => <Orders publisher={publisher} />}
            />
          </Switch>
        </Section>
      </Fader>
      <ActionBar publisher={publisher} />
    </Form>
  );
};

export default Subscription;
