import React, { useState } from "react";
import { Prompt, RouteComponentProps } from "react-router-dom";
import { AiOutlineBook, AiOutlineProfile } from "react-icons/ai";
import { MdOutlineChangeCircle } from "react-icons/md";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { Catalog, Site } from "../../../types/apiResponses";
import Text from "../../../style-guide/Text/Text";
import Fader from "../../../style-guide/Fader/Fader";
import Block from "../../../style-guide/Block/Block";
import Section from "../../../style-guide/Section/Section";
import Card from "../../../style-guide/Card/Card";
import Crumb from "../../../components/Crumb/Crumb";
import createInputField from "../../../util/createInputField";
import {
  InlineActionButton,
  InlineActionLink,
  InlineActions
} from "../../../style-guide/InlineActions/InlineActions";
import Form from "../../../style-guide/Inputs/Form";
import {
  Modal,
  ModalActions,
  ModalBody
} from "../../../style-guide/Modal/Modal";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";
import { ButtonList, Button } from "../../../style-guide/Button/Button";
import useForm from "../../../hooks/useForm";
import usePublisher from "../../../hooks/usePublisher";
import createPublisherInputField from "../../../util/createPublisherInputField";
import PublisherHelpers from "../../../util/PublisherHelpers";
import ActionBar from "../../../components/ActionBar/ActionBar";
import { LEAVE_PAGE_MESSAGE } from "../../../constants/general";
import cleanPopulatedIds from "../../../util/cleanPopulatedIds";
import useSiteSelection from "../../../hooks/useSiteSelection";

interface Props
  extends RouteComponentProps<{
    department: string;
  }> {}

const Department: React.FC<Props> = ({ match }) => {
  const departmentId = match.params.department;
  const { setSite } = useSiteSelection();
  const publisher = usePublisher<Site>({
    id: departmentId,
    baseUrl: ["site", "departments"],
    baseDashboardUrl: "/innstillinger/avdelinger",
    cleanField: cleanPopulatedIds([
      "departmentProductCatalog",
      "departmentContentCatalog"
    ])
  });

  const data = publisher.form.data;

  return (
    <Form onSubmit={publisher.form.submit}>
      <Prompt
        when={publisher.form.hasMadeChanges}
        message={() => LEAVE_PAGE_MESSAGE}
      />
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/innstillinger/avdelinger">Avdelinger</Crumb>
            {PublisherHelpers.getResourceTitle(
              publisher,
              "displayName",
              data.name || "Laster…"
            )}
          </Text>
        </Block>
        <Block hugTop hugBottom>
          <InlineActions>
            <InlineActionLink exact to={departmentId}>
              <AiOutlineProfile /> Generelt
            </InlineActionLink>
            <InlineActionButton
              onClick={() => {
                setSite(data);
                window.location.href = "/";
              }}
            >
              <MdOutlineChangeCircle /> Gå til dashbord
            </InlineActionButton>
            {data && data.departmentProductCatalog && (
              <InlineActionLink
                exact
                to={`/butikk/produktkataloger/${
                  typeof data.departmentProductCatalog === "string"
                    ? data.departmentProductCatalog
                    : data.departmentProductCatalog._id
                }`}
              >
                <AiOutlineBook /> Produktkatalog
              </InlineActionLink>
            )}
            {data && data.departmentContentCatalog && (
              <InlineActionLink
                exact
                to={`/innhold/innholdskataloger/${
                  typeof data.departmentContentCatalog === "string"
                    ? data.departmentContentCatalog
                    : data.departmentContentCatalog._id
                }`}
              >
                <AiOutlineBook /> Innholdskatalog
              </InlineActionLink>
            )}
          </InlineActions>
        </Block>
        <Section>
          <Card>
            <Block>
              <Section hugTop>
                <Text element="h2" variant="title">
                  Generelt
                </Text>
              </Section>
              {createPublisherInputField(publisher, {
                path: "displayName",
                label: "Navn",
                type: "text",
                required: true
              })}
              {createPublisherInputField(publisher, {
                path: "notificationEmail",
                label: "Varslingsepost",
                type: "email",
                required: true
              })}
              {createPublisherInputField(publisher, {
                path: "departmentProductCatalog",
                label: "Produktkatalog",
                type: "ref",
                value: publisher.form.getValue("departmentProductCatalog.name"),
                ref: {
                  url: ["catalogs"],
                  searchKey: "name",
                  titleKey: "name",
                  query: {
                    type: "product"
                  }
                }
              })}
              {createPublisherInputField(publisher, {
                path: "departmentContentCatalog",
                label: "Innholdskatalog",
                type: "ref",
                value: publisher.form.getValue("departmentContentCatalog.name"),
                ref: {
                  url: ["catalogs"],
                  searchKey: "name",
                  titleKey: "name",
                  query: {
                    type: "content"
                  }
                }
              })}
            </Block>
          </Card>
        </Section>
      </Fader>
      <ActionBar publisher={publisher} />
    </Form>
  );
};

interface ProductCatalogModal {
  department: Site;
  onSave: (catalog?: Catalog) => any;
}

const ProductCatalogModal: React.FC<ProductCatalogModal> = ({
  department,
  onSave
}) => {
  const [type, setType] = useState<"existing" | "new">("existing");
  const [catalog, setCatalog] = useState<Catalog | undefined>(undefined);
  const { despawnModal } = useModal();
  const createCatalogform = useForm<Catalog>(
    {
      name: department.displayName || department.name
    },
    {
      endpoint: "catalogs",
      method: "POST",
      onSuccess: (catalog: Catalog) => {
        if ("_id" in catalog) {
          despawnModal();
          onSave(catalog);
        }
      }
    }
  );

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();

        if (type === "new") {
          createCatalogform.submit();
        } else {
          onSave(catalog);
        }
      }}
    >
      <Modal maxWidth="32rem">
        <BusyBoy busy={createCatalogform.submitting}>
          <ModalBody>
            <Block>
              <Text variant="title" gutterBottom>
                Tilknytt produktkatalog
              </Text>
              <Text variant="body3" gutterBottom>
                Denne avdelingen har ikke en tilknyttet produktkatalog, så den
                bruker de samme produktene og prisene som standardkatalogen.
                Dersom du ønsker å endre dette, kan du opprette en ny
                produktkatalog for avdelingen, eller velge en eksisterende.
              </Text>
              {createInputField({
                key: "_type",
                value: type,
                type: "radio",
                options: [
                  {
                    value: "existing",
                    label: "Bruk eksisterende katalog"
                  },
                  {
                    value: "new",
                    label: "Lag ny katalog"
                  }
                ],
                onChange: setType
              })}
              {type === "existing" ? (
                createInputField({
                  key: "existing",
                  label: "Velg katalog",
                  type: "ref",
                  value: catalog ? catalog.name : "",
                  onChange: setCatalog,
                  ref: {
                    url: ["catalogs"],
                    searchKey: "name",
                    titleKey: "name",
                    query: {
                      type: "product"
                    }
                  }
                })
              ) : (
                <>
                  {createInputField({
                    key: "name",
                    label: "Navn på katalog",
                    value: createCatalogform.getValue("name"),
                    required: true,
                    type: "text",
                    onChange: (value) =>
                      createCatalogform.setField({
                        path: "name",
                        value
                      })
                  })}
                  {createInputField({
                    key: "description",
                    label: "Beskrivelse av katalog",
                    value: createCatalogform.getValue("description"),
                    type: "textarea",
                    onChange: (value) =>
                      createCatalogform.setField({
                        path: "description",
                        value
                      })
                  })}
                </>
              )}
            </Block>
          </ModalBody>
          <ModalActions>
            <ButtonList align="right">
              <Button
                type="button"
                outlined
                onClick={() => {
                  despawnModal();
                }}
              >
                Avbryt
              </Button>
              <Button type="submit" disabled={createCatalogform.submitting}>
                {createCatalogform.submitting ? "Lagrer…" : "Lagre"}
              </Button>
            </ButtonList>
          </ModalActions>
        </BusyBoy>
      </Modal>
    </Form>
  );
};

export default Department;
