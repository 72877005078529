import { ImageRef } from "../types/apiResponses";
import fallbackImage from "./images/empty_preview.png";

export default function getImageUrlFromImageRef(image?: ImageRef): string {
  if (!image) {
    return fallbackImage;
  }

  if (image.url) {
    return image.url;
  }

  if (image.ref && typeof image.ref !== "string") {
    return image.ref.secure_url;
  }

  return fallbackImage;
}
