import styled, { css } from "styled-components";

const PreviewImage = styled.img<{ maxWidth?: string }>`
  display: block;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.border};
  margin-bottom: ${(props) => props.theme.spacing.small};
  border-radius: 8px;

  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `};
`;

export default PreviewImage;
