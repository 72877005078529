import React, {
  FunctionComponent,
  createContext,
  useState,
  useMemo
} from "react";
import { UserState } from "../types/general";
import storage from "../util/storage";

const defaultUser: UserState = {
  id: "",
  name: "",
  profileImage: null,
  loggedIn: false,
  site: null,
  siteId: null
};

const UserContext = createContext({
  data: defaultUser,
  setData: (data: UserState) => {},
  logOut: () => {}
});

function readUserDataFromStorage(): UserState {
  const userData = storage.getUnserialize<UserState>("user");
  return userData ? userData : defaultUser;
}

const UserProvider: FunctionComponent = ({ children }) => {
  const localStorageUser = useMemo(() => readUserDataFromStorage(), []);
  const [state, setState] = useState(localStorageUser);

  return (
    <UserContext.Provider
      value={{
        data: state,
        setData: (data) => setState(data),
        logOut: () => {
          setState({
            ...state,
            loggedIn: false
          });

          storage.delete("user");
        }
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider, defaultUser };
