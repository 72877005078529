import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import Label from "../../style-guide/Inputs/Label";

interface WrapperProps {
  dontCompensate?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  ${(props) =>
    !props.dontCompensate &&
    css`
      margin-top: -${(props) => props.theme.spacing.xs};
    `};
`;

const Nodes = styled.div``;

const Node = styled.div``;

interface ItemProps {
  level: number;
}

const Item = styled.div<ItemProps>`
  display: flex;
  flex-direction: row;
  border: 1px solid ${(props) => props.theme.colors.borderDim};
  padding: ${(props) => props.theme.spacing.xs};
  align-items: center;
  padding-left: ${(props) =>
    `calc(${props.theme.spacing.small} * ${props.level})`};

  &:not(:last-child) {
    border-bottom: none;
  }
`;

const Check = styled.span`
  display: inline-block;
  padding-right: ${(props) => props.theme.spacing.xs};
`;

const Actions = styled.div`
  margin-left: auto;
`;

export interface Node {
  _id: string;
  children?: Node[];
  [key: string]: any;
}

interface Props {
  tree: Node[];
  labelField: string;
  values?: string[];
  dontCompensate?: boolean;
  onChange?: (id: string, selected: boolean) => any;
  renderActions?: (node: Node) => ReactNode;
  readOnly?: boolean;
}

const Tree: React.FC<Props> = ({
  tree,
  labelField,
  values,
  onChange,
  renderActions,
  readOnly,
  dontCompensate
}) => {
  return (
    <Wrapper dontCompensate={dontCompensate}>
      <Nodes>
        {tree.map((n) =>
          renderNode({
            node: n,
            level: 1,
            labelField,
            values,
            onChange,
            renderActions,
            readOnly
          })
        )}
      </Nodes>
    </Wrapper>
  );
};

interface RenderNodeOpts {
  node: Node;
  level: number;
  labelField: string;
  values?: string[];
  onChange?: (id: string, selected: boolean) => any;
  renderActions?: (node: Node) => ReactNode;
  readOnly?: boolean;
}

function renderNode(opts: RenderNodeOpts) {
  return (
    <Node key={opts.node._id}>
      <Item level={opts.level}>
        <div>
          {typeof opts.onChange === "function" && (
            <Check>
              <input
                id={"tree_" + opts.node._id}
                type="checkbox"
                onChange={(e) =>
                  opts.onChange!(opts.node._id, e.target.checked)
                }
                disabled={opts.readOnly}
                checked={
                  Array.isArray(opts.values)
                    ? opts.values.includes(opts.node._id)
                    : false
                }
              />
            </Check>
          )}
          <Label htmlFor={"tree_" + opts.node._id} inline>
            {opts.node[opts.labelField]}
          </Label>
        </div>
        {typeof opts.renderActions === "function" && (
          <Actions>{opts.renderActions(opts.node)}</Actions>
        )}
      </Item>
      {Array.isArray(opts.node.children) && (
        <Nodes>
          {opts.node.children.map((n) =>
            renderNode({
              ...opts,
              node: n,
              level: opts.level + 1
            })
          )}
        </Nodes>
      )}
    </Node>
  );
}

export default Tree;
