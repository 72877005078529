import React, { FunctionComponent, createContext, useState } from "react";
import { Theme } from "@ludens-reklame/rubics-sdk";

const defaultTheme: undefined | Theme = undefined;

const SiteThemeContext = createContext({
  data: defaultTheme as undefined | Theme,
  setData: (data: Theme) => {}
});

const SiteThemeProvider: FunctionComponent = ({ children }) => {
  const [state, setState] = useState(defaultTheme as Theme | undefined);

  return (
    <SiteThemeContext.Provider
      value={{
        data: state,
        setData: (data) => setState(data)
      }}
    >
      {children}
    </SiteThemeContext.Provider>
  );
};

export { SiteThemeContext, SiteThemeProvider };
