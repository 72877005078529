import React, { useRef, useState } from "react";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import Form from "../../../style-guide/Inputs/Form";
import {
  Modal,
  ModalBody,
  ModalActions
} from "../../../style-guide/Modal/Modal";
import Block from "../../../style-guide/Block/Block";
import { ButtonList, Button } from "../../../style-guide/Button/Button";
import { Product } from "../../../types/apiResponses";
import Label from "../../../style-guide/Inputs/Label";
import useSimplePatch from "../../../hooks/useSimplePatch";
import useApiUrl from "../../../hooks/useApiUrl";
import { useSearch } from "../../../hooks/useApi";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";
import Field from "../../../style-guide/Inputs/Field";
import { FieldSetter } from "../../../hooks/useForm";
import { Radios, Radio } from "../../../style-guide/Inputs/Radio";
import ProductPicker, {
  ProductTable
} from "../../../components/ProductPicker/ProductPicker";
import Text from "../../../style-guide/Text/Text";

interface Props {
  product: Product;
  setField: FieldSetter;
  onChange: (product: Product) => any;
}

const ConnectionSearchModal: React.FC<Props> = ({
  product,
  onChange,
  setField
}) => {
  const [connectionRule, setConnectionRule] = useState<"A" | "B">("A");
  const [connectedProduct, setConnectedProduct] = useState<Product | null>(
    null
  );

  const modalBodyRef = useRef<HTMLElement | null>(null);

  const { despawnModal } = useModal();
  const patchUrl = useApiUrl([
    "products",
    connectionRule === "B"
      ? product._id
      : connectedProduct
      ? connectedProduct._id
      : ""
  ]);

  const connectionsUrl = useApiUrl(["products"]);

  const connectionsSearch = useSearch<Product>({
    endpoint: connectionsUrl,
    fetchOnMount: !!product.master,
    queryParams: {
      draft: true,
      master: product.master
    }
  });

  const simplePatch = useSimplePatch<Product>({
    endpoint: patchUrl,
    initialData: {},
    publish: connectionRule === "A",
    publishEndpoint: "publish",
    nameLabel: connectedProduct?.name,
    onSuccess: (product) => {
      despawnModal();

      if (product) {
        onChange(product);
      }
    }
  });

  return (
    <BusyBoy busy={connectionsSearch.loading || simplePatch.loading}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();

          if (connectedProduct) {
            if (connectionRule === "A" && !product.master) {
              setField({
                path: "master",
                value: product.name,
                submit: true
              });
            }

            simplePatch.form.setField({
              path: "master",
              value:
                connectionRule === "A"
                  ? product.master
                    ? product.master
                    : product.name
                  : connectedProduct.master,
              submit: true
            });
          }
        }}
      >
        <Modal fullscreen>
          <ModalBody ref={(ref) => (modalBodyRef.current = ref)}>
            <Block>
              <Text variant="title" gutterBottom>
                Finn produkt som skal kobles
              </Text>
              <ProductPicker
                useWindow={false}
                getScrollParent={() => modalBodyRef.current}
                queryParams={{ draft: true }}
                filter={(p) => {
                  if (!!product.master && !connectionsSearch.hasSearched) {
                    return false;
                  }

                  if (!product.master) {
                    return p._id !== product._id;
                  }

                  return !connectionsSearch.results
                    .map((r) => r._id)
                    .includes(p._id);
                }}
                renderView={(products) => (
                  <ProductTable
                    products={products}
                    onSelect={(products) =>
                      setConnectedProduct(
                        products.length > 0 ? products[0] : null
                      )
                    }
                  />
                )}
              />
              {connectedProduct && !!connectedProduct.master && (
                <>
                  <Field hugBottom>
                    <Label>Kobling</Label>
                    <Radios>
                      <Radio
                        value="A"
                        checked={connectionRule === "A"}
                        onChange={() => setConnectionRule("A")}
                        label={
                          <>
                            Koble <strong>{connectedProduct.name}</strong> til{" "}
                            <strong>{product.name}</strong>
                          </>
                        }
                      />
                      <Radio
                        value="B"
                        checked={connectionRule === "B"}
                        onChange={() => setConnectionRule("B")}
                        label={
                          <>
                            Koble <strong>{product.name}</strong> til{" "}
                            <strong>{connectedProduct.name}</strong>
                          </>
                        }
                      />
                    </Radios>
                  </Field>
                </>
              )}
            </Block>
          </ModalBody>
          <ModalActions>
            <ButtonList align="right">
              <Button
                type="button"
                outlined
                onClick={(e) => {
                  despawnModal();
                }}
              >
                Avbryt
              </Button>
              <Button type="submit" disabled={!connectedProduct}>
                Koble produkt
              </Button>
            </ButtonList>
          </ModalActions>
        </Modal>
      </Form>
    </BusyBoy>
  );
};

export default ConnectionSearchModal;
