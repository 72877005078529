import React from "react";
import { Switch, Route } from "react-router";
import Form from "./Form";
import Forms from "./Forms";

const FormsRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/skjemaer" component={Forms} />
      <Route
        path="/skjemaer/:form"
        render={(props) => <Form key={props.match.params.form} {...props} />}
      />
    </Switch>
  );
};

export default FormsRoutes;
