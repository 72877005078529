import React, {
  useState,
  ReactNode,
  useCallback,
  useMemo,
  useRef
} from "react";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import Form from "../../style-guide/Inputs/Form";
import { Modal, ModalBody, ModalActions } from "../../style-guide/Modal/Modal";
import Block from "../../style-guide/Block/Block";
import { ButtonList, Button } from "../../style-guide/Button/Button";
import Text from "../../style-guide/Text/Text";
import Field from "../../style-guide/Inputs/Field";
import createInputField, {
  CreateInputFieldOpts
} from "../../util/createInputField";

export type CrudModalFieldSetter = (key: string, value: any) => void;
export type CrudModalDataSetter = (data: any) => void;

export interface RenderFieldPayload {
  data: any;
  setField: CrudModalFieldSetter;
  setData: CrudModalDataSetter;
  modalBodyRef: React.MutableRefObject<HTMLElement | null>;
}

export interface CrudModalField {
  key: string;
  render: (payload: RenderFieldPayload) => ReactNode;
  hide?: (data: any) => boolean;
}

interface Props {
  initialData: any;
  fields: CrudModalField[];
  title?: string;
  onSubmit?: (data: any) => any;
  onDelete?: () => any;
  fullscreen?: boolean;
  submitLabel?: string;
}

const CrudModal: React.FC<Props> = ({
  initialData,
  fields,
  title,
  onSubmit,
  onDelete,
  fullscreen,
  submitLabel
}) => {
  const [internalData, setInternalData] = useState(initialData);
  const { despawnModal } = useModal();
  const modalBodyRef = useRef<HTMLElement | null>(null);

  const setField = useCallback<CrudModalFieldSetter>(
    (key, value) => {
      setInternalData({
        ...internalData,
        [key]: value
      });
    },
    [internalData, setInternalData]
  );

  const visibleFields = useMemo(
    () => fields.filter((f) => !f.hide || !f.hide(internalData)),
    [fields, internalData]
  );

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        despawnModal();

        if (typeof onSubmit === "function") {
          const copy = { ...internalData };

          delete copy._id;

          onSubmit(copy);
        }
      }}
    >
      <Modal
        fullscreen={fullscreen}
        maxWidth={fullscreen ? undefined : "38rem"}
      >
        <ModalBody ref={(ref) => (modalBodyRef.current = ref)}>
          <Block>
            {title && (
              <Text variant="title" gutterBottom>
                {title}
              </Text>
            )}
            {visibleFields.map((f, k) => (
              <Field key={f.key} hugBottom={k + 1 === visibleFields.length}>
                {f.render({
                  data: internalData,
                  setField,
                  setData: setInternalData,
                  modalBodyRef
                })}
              </Field>
            ))}
          </Block>
        </ModalBody>
        <ModalActions>
          <ButtonList align="right">
            <Button
              type="button"
              outlined
              onClick={() => {
                despawnModal();
              }}
            >
              Avbryt
            </Button>
            {typeof onDelete === "function" && (
              <Button
                type="button"
                outlined
                onClick={() => {
                  if (window.confirm("Er du sikker på at du vil slette?")) {
                    onDelete();
                    despawnModal();
                  }
                }}
              >
                Slett
              </Button>
            )}
            {typeof onSubmit === "function" && (
              <Button type="submit">{submitLabel || "Lagre"}</Button>
            )}
          </ButtonList>
        </ModalActions>
      </Modal>
    </Form>
  );
};

export interface CreateCrudModalFieldOpts
  extends Omit<CreateInputFieldOpts, "onChange"> {
  payload: RenderFieldPayload;
  onChange?: (value: any) => any;
}

function createCrudModalField(opts: CreateCrudModalFieldOpts): ReactNode {
  return createInputField({
    value: opts.payload.data[opts.key],
    ...opts,
    onChange: opts.onChange
      ? opts.onChange
      : (value) => opts.payload.setField(opts.key, value)
  });
}

export default CrudModal;
export { createCrudModalField };
