import isPlainObject from "is-plain-object";
import equal from "deep-equal";

export interface GenericData {
  [key: string]: GenericData;
}

function chchchanges(
  x: GenericData,
  y: GenericData,
  ignore?: string[]
): GenericData {
  const z: GenericData = {};

  for (const k in y) {
    const yV = y[k];

    if (k in x) {
      if (ignore && ignore.includes(k)) {
        z[k] = yV;
      } else {
        const xV = x[k];

        if (isPlainObject(yV)) {
          const rec = chchchanges(xV, yV);

          if (Object.keys(rec).length > 0) {
            z[k] = rec;
          }
        } else if (Array.isArray(yV)) {
          if (!equal(xV, yV)) {
            z[k] = yV;
          }
        } else if (yV !== xV) {
          z[k] = yV;
        }
      }
    } else {
      z[k] = yV;
    }
  }

  return z;
}

export default chchchanges;
