import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Fader from "../../style-guide/Fader/Fader";
import Logo from "../../style-guide/Logo/Logo";
import Card from "../../style-guide/Card/Card";
import WidthConstraints from "../../style-guide/WidthConstraints/WidthConstraints";
import Block from "../../style-guide/Block/Block";
import {
  ButtonList,
  ButtonExternalLink
} from "../../style-guide/Button/Button";
import { User } from "../../types/apiResponses";
import { UserContext } from "../../context/User";
import { useApi } from "../../hooks/useApi";
import { UserState } from "../../types/general";
import storage from "../../util/storage";
import Section from "../../style-guide/Section/Section";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";

const Login: React.FC = () => {
  return (
    <Fader>
      <WidthConstraints size="small">
        <Section>
          <Card>
            <Flex>
              <FlexKid>
                <Logo noMargin hugRight height="25px" />
              </FlexKid>
              <FlexKid flex={1}>
                <Block>
                  <ButtonList align="stretch">
                    <ButtonExternalLink
                      href={
                        process.env.REACT_APP_RUBICS_URL +
                        "/auth/login/sso/?dashboard=true"
                      }
                    >
                      Logg inn med Ludens
                    </ButtonExternalLink>
                  </ButtonList>
                </Block>
              </FlexKid>
            </Flex>
          </Card>
        </Section>
      </WidthConstraints>
    </Fader>
  );
};

const SignOn: React.FC = () => {
  const userContext = useContext(UserContext);
  const history = useHistory();
  const userRequest = useApi<User | null>({
    endpoint: "users/me",
    initialData: null,
    fetchOnMount: true
  });

  useEffect(() => {
    if (userRequest.resource && userRequest.resource._id) {
      const userState: UserState = {
        id: userRequest.resource._id,
        name: userRequest.resource.name,
        profileImage: userRequest.resource.profileImage,
        loggedIn: true,
        site: null,
        siteId: null
      };

      storage.setSerialize("user", userState);
      userContext.setData(userState);
      history.replace("/");
    }
  }, [userRequest.resource]);

  return null;
};

export { Login, SignOn };
