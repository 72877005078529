import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import { FaGripLines } from "react-icons/fa";
import clone from "clone-deep";
import {
  AiOutlinePlus,
  AiOutlineClose,
  AiOutlineStar,
  AiOutlineGold,
  AiOutlineSearch,
  AiOutlineLeft
} from "react-icons/ai";
import { Droppable } from "react-beautiful-dnd";
import { STOREFRONT_ENDPOINT } from "@ludens-reklame/rubics-sdk";
import { Wrapper, Sidebar, Main } from "../../style-guide/Layout/Layout";
import { Elements, Element } from "../../style-guide/Elements/Elements";
import Block from "../../style-guide/Block/Block";
import Preview from "../../components/Preview/Preview";
import Text from "../../style-guide/Text/Text";
import Section from "../../style-guide/Section/Section";
import getIcon from "../../util/getIcon";
import usePreviewToken from "../../hooks/usePreviewToken";
import useApiUrl from "../../hooks/useApiUrl";
import useTheme from "../../hooks/useTheme";
import {
  TemplateComponent,
  Template,
  AppComponent,
  Component
} from "../../types/apiResponses";
import Frame, { DashboardMessagePayload } from "../../util/Frame";
import SidebarPager from "../../components/SidebarPager/SidebarPager";
import Fader from "../../style-guide/Fader/Fader";
import Putter from "../../components/Putter/Putter";
import ActionBar from "../../components/ActionBar/ActionBar";
import usePublisher from "../../hooks/usePublisher";
import {
  ButtonLink,
  Button,
  ButtonList
} from "../../style-guide/Button/Button";
import RichContentEditor from "../../components/RichContentEditor/RichContentEditor";
import { Dropper, Dragger } from "../../components/DnD/DnD";
import { useSearch } from "../../hooks/useApi";
import createId from "../../util/createId";
import { RevisionType } from "../../constants/api";
import Label from "../../style-guide/Inputs/Label";
import TextInput from "../../style-guide/Inputs/TextInput";
import useThemeSwitcher from "../../hooks/useThemeSwitcher";
import Ruler from "../../style-guide/Ruler/Ruler";
import getBaseApiUrl from "../../util/getBaseApiUrl";
import useRevisionId from "../../hooks/useRevisionId";
import useAppComponents from "../../hooks/useAppComponents";
import getComponentDescription from "../../util/getComponentDescription";
import Card from "../../style-guide/Card/Card";
import EmptyState from "../../style-guide/EmptyState/EmptyState";
import useExperimentId from "../../hooks/useExperimentId";
import { RequestData } from "../../util/api";
import useComponents from "../../hooks/useComponents";
import useSite from "../../hooks/useSite";

interface Props
  extends RouteComponentProps<{
    template: string;
  }> {}

const TemplateDesigner: React.FC<Props> = ({ match }) => {
  const templateId = match.params.template;
  const urlArray = ["templates"];

  const [isNavigatingComponent, setIsNavigatingComponent] =
    useState<boolean>(false);
  const [componentSearchQuery, setComponentSearchQuery] = useState<string>("");

  const experimentId = useExperimentId();
  const revisionId = useRevisionId();
  const url = useApiUrl([...urlArray, templateId]);
  const templatesUrl = useApiUrl(["templates"]);
  const previewToken = usePreviewToken();
  const theme = useTheme();
  const appComponents = useAppComponents();
  const customComponents = useComponents();
  const isExperiment = useMemo<boolean>(() => !!experimentId, [experimentId]);
  const site = useSite();
  const history = useHistory();

  const publisher = usePublisher<Template>({
    id: templateId,
    baseUrl: urlArray,
    baseDashboardUrl: "/designer/maler",
    publishEndpoint: "publish",
    discardEndpoint: "discard",
    onDeleteFallbackUrl: "/innstillinger/maler/liste",
    initialData: {
      components: []
    },
    onPublishDiscard: () => {
      const iframe = new Frame("preview");
      iframe.reload();
    },
    onSubmit: () => {
      const iframe = new Frame("preview");
      iframe.reload();
    }
  });

  const form = publisher.form;

  const [addingSection, setAddingSection] = useState(false);
  const [editingSection, setEditingSection] = useState(
    undefined as TemplateComponent | undefined
  );
  const [editingRichContentComponent, setEditingRichContentComponent] =
    useState(undefined as TemplateComponent | undefined);
  const [editingRichContentIndex, setEditingRichContentIndex] = useState(-1);

  const [listData, setListData] = useState({
    list: form.data.components,
    head: form.data.components.filter((c) => c.inHeader),
    main: form.data.components.filter((c) => !c.inHeader && !c.inFooter),
    foot: form.data.components.filter((c) => c.inFooter)
  });

  const templatesSearch = useSearch<Template>({
    endpoint: templatesUrl,
    fetchOnMount: true,
    limit: 999
  });

  useEffect(() => {
    setListData({
      list: form.data.components,
      head: form.data.components.filter((c) => c.inHeader),
      main: form.data.components.filter((c) => !c.inHeader && !c.inFooter),
      foot: form.data.components.filter((c) => c.inFooter)
    });
  }, [form.data.components]);

  useThemeSwitcher("light");

  const components = useMemo<AppComponent[]>(() => {
    if (!theme) {
      return [];
    }

    return [...theme.components, ...appComponents.components].filter((c) => {
      if (c.name === "global:booking") {
        return site.bookingEnabled;
      }

      return true;
    });
  }, [theme, appComponents.components, site]);

  const selectableComponents = useMemo<(AppComponent | Component)[]>(() => {
    return [
      ...components.filter((c) => c.usableInSection),
      ...customComponents.components
    ].sort((a, b) => {
      const aTitle = "label" in a && a.label ? a.label : a.name;
      const bTitle = "label" in b && b.label ? b.label : b.name;

      if (aTitle < bTitle) {
        return -1;
      }

      if (aTitle > bTitle) {
        return 1;
      }

      return 0;
    });
  }, [components, customComponents.components]);

  const queriedComponents = useMemo<(AppComponent | Component)[]>(() => {
    if (componentSearchQuery.length > 0) {
      const query = componentSearchQuery.toLowerCase();

      return selectableComponents.filter((c) => {
        let title: string;

        if ("label" in c && c.label) {
          title = c.label;
        } else {
          title = c.name;
        }

        return title.toLowerCase().includes(query);
      });
    }

    return selectableComponents;
  }, [selectableComponents, componentSearchQuery]);

  const renderElement = useCallback(
    (c: TemplateComponent, k: number) => {
      const themeComponent = !!c.component
        ? components.find((tc) => tc.name === c.component)
        : null;
      const template = !!c.template
        ? templatesSearch.results.find((tt) => tt.draftFor === c.template!.ref)
        : null;
      const customComponent = !!c.componentRef
        ? customComponents.components.find((cc) => cc._id === c.componentRef)
        : null;

      return (
        <Dragger
          key={c.internalId || c._id}
          id={c.internalId || c._id}
          index={k}
          isDragDisabled={publisher.isRevision}
        >
          <Element
            icon={
              c.componentRef ? (
                <AiOutlineGold />
              ) : themeComponent ? (
                themeComponent.appIcon ? (
                  <img
                    src={themeComponent.appIcon}
                    alt={themeComponent.appName}
                  />
                ) : (
                  getIcon(themeComponent.icon)
                )
              ) : (
                <AiOutlineStar />
              )
            }
            rightIcon={!publisher.isRevision ? <FaGripLines /> : undefined}
            biggerRightSpace
            minHeight
            href="#"
            subText={
              customComponent && customComponent.description
                ? customComponent.description
                : getComponentDescription(themeComponent)
            }
            onClick={(e) => {
              e.preventDefault();

              setEditingSection(c);

              const iframe = new Frame("preview");
              iframe.scrollTo(c.name);
            }}
          >
            {customComponent
              ? customComponent.name
              : themeComponent
              ? themeComponent.label || themeComponent.name
              : template
              ? template.name
              : null}
          </Element>
        </Dragger>
      );
    },
    [
      templatesSearch.results,
      components,
      publisher.isRevision,
      customComponents.components
    ]
  );

  const templatesFilter = useMemo(() => {
    return !form.data.partial
      ? templatesSearch.results.filter((t) => t.partial)
      : [];
  }, [form.data.partial, templatesSearch.results]);

  const themeComponent = useMemo(() => {
    return !!editingSection
      ? components.find((c) => c.name === editingSection.component)
      : undefined;
  }, [editingSection, components]);

  const customComponent = useMemo(() => {
    return !!editingSection && editingSection.componentRef
      ? customComponents.components.find(
          (c) => c._id === editingSection.componentRef
        )
      : undefined;
  }, [editingSection, customComponents.components]);

  const editingSectionIndex = useMemo(() => {
    return editingSection
      ? form.data.components.findIndex((c) => c._id === editingSection._id)
      : -1;
  }, [form.data, editingSection]);

  const editingSectionTemplate = useMemo(() => {
    if (editingSection && !!editingSection.template) {
      return templatesSearch.results.find(
        (tt) => tt._id === editingSection.template!.ref
      );
    }

    return undefined;
  }, [editingSection, templatesSearch]);

  useEffect(() => {
    setIsNavigatingComponent(false);
  }, [publisher.publishDiscardHash]);

  const previewUrl = useMemo<string>(
    () =>
      getBaseApiUrl() +
      "/" +
      STOREFRONT_ENDPOINT +
      "/" +
      url +
      "/render?preview=true&showControls=true&token=" +
      previewToken +
      (revisionId ? `&revision=${revisionId}` : "") +
      (!!experimentId
        ? `&experiment=${experimentId.experiment}&experimentVariant=${experimentId.experimentVariant}`
        : ""),
    [url, previewToken, revisionId, experimentId]
  );

  useEffect(() => {
    function handle(e: MessageEvent) {
      if (
        e.origin.endsWith(process.env.REACT_APP_RUBICS_DOMAIN || "rubics.as")
      ) {
        const payload: DashboardMessagePayload = e.data;

        if (payload.type === "openComponent") {
          const component = form.data.components.find((c) => {
            if (payload.data.parent) {
              return c.name === payload.data.parent;
            }

            return c.name === payload.data.component;
          });

          if (component) {
            if (payload.data.parent) {
              const children =
                Array.isArray(component.children) &&
                component.children.length > 0
                  ? component.children
                  : [];

              const child = children.find((c) => {
                return c.name === payload.data.component;
              });

              const index = children.findIndex((c) => {
                return c.name === payload.data.component;
              });

              if (child) {
                setIsNavigatingComponent(true);
                setEditingSection(component);
                setEditingRichContentComponent(child);
                setEditingRichContentIndex(index);
              }
            } else {
              setIsNavigatingComponent(false);
              setEditingSection(component);
              setEditingRichContentComponent(undefined);
              setEditingRichContentIndex(-1);
            }
          }
        }
      }
    }

    window.addEventListener("message", handle);

    return () => {
      window.removeEventListener("message", handle);
    };
  }, [form.data.components]);

  const queryParams = useMemo<RequestData | undefined>(
    () =>
      isExperiment
        ? {
            experiment: experimentId!.experiment,
            experimentVariant: experimentId!.experimentVariant
          }
        : undefined,
    [isExperiment, experimentId]
  );

  if (!theme) {
    return null;
  }

  return (
    <Wrapper widerSidebar>
      <Sidebar wider>
        <Block>
          <Fader variant="left">
            <Section hugTop>
              <Text element="h1" variant="display3">
                {publisher.form.data.name}
              </Text>
            </Section>
          </Fader>
          {!editingSection && !addingSection && (
            <Fader variant="left">
              <Section>
                {history.length > 0 ? (
                  <Button outlined onClick={() => history.goBack()}>
                    <AiOutlineLeft />
                    Tilbake
                  </Button>
                ) : (
                  <ButtonLink
                    to={`/innstillinger/maler/liste/${templateId}${window.location.search}`}
                    outlined
                    alternate
                  >
                    <AiOutlineClose /> Lukk designer
                  </ButtonLink>
                )}
              </Section>
              <Section>
                <Text element="h2" variant="title">
                  Seksjoner
                </Text>
                <Text gutterTop>
                  Husk at endringer du gjør her vil kunne påvirke alle sider som
                  bruker denne malen.
                </Text>
              </Section>
              <Section>
                <Dropper
                  id="templateComponents"
                  onDragEnd={(r) => {
                    if (r.source && r.destination) {
                      const source = Array.from(
                        listData[r.source.droppableId as "main"]
                      );
                      const target =
                        r.source.droppableId !== r.destination.droppableId
                          ? Array.from(
                              listData[r.destination.droppableId as "main"]
                            )
                          : source;

                      const [removed] = source.splice(r.source.index, 1);
                      const element = clone(removed);

                      if (r.destination.droppableId === "head") {
                        element.inHeader = true;
                        element.inFooter = false;
                      } else if (r.destination.droppableId === "foot") {
                        element.inFooter = true;
                        element.inHeader = false;
                      } else {
                        element.inFooter = false;
                        element.inHeader = false;
                      }

                      target.splice(r.destination.index, 0, element);

                      const finalList = {
                        ...listData,
                        [r.source.droppableId]: source,
                        [r.destination.droppableId]: target
                      };

                      setListData(finalList);

                      form.setField({
                        path: "components",
                        value: finalList.head.concat(
                          finalList.main,
                          finalList.foot
                        ),
                        submit: true
                      });
                    }
                  }}
                >
                  {!form.data.partial && (
                    <>
                      <Text variant="subtitle" gutterBottom>
                        Header
                      </Text>
                      <Elements>
                        <Droppable droppableId="head">
                          {(p) => (
                            <div ref={p.innerRef} style={{ minHeight: "64px" }}>
                              {listData.head.map(renderElement)}
                              {p.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </Elements>
                    </>
                  )}
                  {!form.data.partial && (
                    <Text variant="subtitle" gutterTop gutterBottom>
                      Innhold
                    </Text>
                  )}
                  <Elements>
                    <Droppable droppableId="main">
                      {(p) => (
                        <div ref={p.innerRef} style={{ minHeight: "64px" }}>
                          {listData.main.map(renderElement)}
                          {p.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Elements>
                  {!form.data.partial && (
                    <>
                      <Text variant="subtitle" gutterTop gutterBottom>
                        Footer
                      </Text>
                      <Elements>
                        <Droppable droppableId="foot">
                          {(p) => (
                            <div ref={p.innerRef} style={{ minHeight: "64px" }}>
                              {listData.foot.map(renderElement)}
                              {p.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </Elements>
                    </>
                  )}
                </Dropper>
              </Section>
              {!publisher.isRevision && (
                <Section>
                  <ButtonList align="stretch">
                    <Button
                      type="button"
                      onClick={() => {
                        setAddingSection(true);
                        setComponentSearchQuery("");
                      }}
                    >
                      Legg til seksjon
                    </Button>
                  </ButtonList>
                </Section>
              )}
            </Fader>
          )}
          {addingSection && (
            <SidebarPager onClose={() => setAddingSection(false)}>
              <Section>
                <Text element="h2" variant="title">
                  Legg til seksjon
                </Text>
              </Section>
              <Section>
                <TextInput
                  icon={<AiOutlineSearch />}
                  placeholder="Søk etter komponenter"
                  aria-label="Søk etter komponenter"
                  value={componentSearchQuery}
                  onChange={(e) => setComponentSearchQuery(e.target.value)}
                  autoFocus
                />
              </Section>
              <Section>
                <Elements>
                  {queriedComponents.length === 0 ? (
                    <EmptyState title="Ingen komponenter funnet" smaller />
                  ) : (
                    queriedComponents.map((c) => (
                      <Element
                        key={c.name}
                        icon={
                          "site" in c ? (
                            <AiOutlineGold />
                          ) : c.appIcon ? (
                            <img src={c.appIcon} alt={c.appName} />
                          ) : (
                            getIcon(c.icon)
                          )
                        }
                        rightIcon={<AiOutlinePlus />}
                        subText={getComponentDescription(c)}
                        biggerRightSpace
                        minHeight
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          const id = createId();
                          const similarComponents = form.data.components.filter(
                            (cc) =>
                              "site" in c
                                ? cc.componentRef === c._id
                                : cc.component === c.name
                          ).length;

                          const reference = "site" in c ? c._id : c.name;

                          form.setField({
                            path: "components",
                            value: [
                              ...form.data.components,
                              {
                                internalId: id,
                                name: (
                                  (form.data.partial ? "partial_" : "") +
                                  reference +
                                  "_" +
                                  (similarComponents + 1)
                                ).toLowerCase(),
                                component: "site" in c ? undefined : c.name,
                                componentRef:
                                  "site" in c ? reference : undefined,
                                app: "app" in c && c.app ? c.app : undefined
                              }
                            ],
                            submit: true,
                            onSuccess: (rawTemplate) => {
                              const template = rawTemplate as Template;
                              if (template.components.length > 0) {
                                const component =
                                  template.components[
                                    template.components.length - 1
                                  ];

                                setEditingSection(component);

                                const iframe = new Frame("preview");
                                iframe.reload();

                                window.setTimeout(() => {
                                  iframe.scrollTo(component.name);
                                }, 750);
                              }
                            }
                          });

                          setAddingSection(false);
                        }}
                      >
                        {"label" in c && c.label ? c.label : c.name}
                      </Element>
                    ))
                  )}
                </Elements>
              </Section>
              {templatesFilter.length > 0 && (
                <Section>
                  <Elements>
                    {templatesFilter
                      .filter((t) => !!t.draftFor)
                      .map((t) => (
                        <Element
                          key={t._id}
                          icon={<AiOutlineStar />}
                          rightIcon={<AiOutlinePlus />}
                          href="#"
                          minHeight
                          onClick={(e) => {
                            e.preventDefault();
                            form.setField({
                              path: "components",
                              value: [
                                ...form.data.components,
                                {
                                  internalId: createId(),
                                  template: {
                                    ref: t.draftFor
                                  }
                                }
                              ],
                              submit: true
                            });

                            setAddingSection(false);
                          }}
                        >
                          {t.name}
                        </Element>
                      ))}
                  </Elements>
                </Section>
              )}
            </SidebarPager>
          )}
          {editingSection && (
            <>
              {!isNavigatingComponent && (
                <SidebarPager
                  onClose={() => {
                    setEditingSection(undefined);
                  }}
                >
                  {!!customComponent ? (
                    <Section>
                      <Text element="h2" variant="title">
                        {customComponent.name}
                      </Text>
                      <Text variant="body3">
                        {customComponent.description || "Mangler beskrivelse"}
                      </Text>
                    </Section>
                  ) : !!themeComponent ? (
                    <>
                      <Section>
                        <Text element="h2" variant="title">
                          {themeComponent.label || themeComponent.name}
                        </Text>
                        <Text variant="body3">
                          {getComponentDescription(themeComponent)}
                        </Text>
                      </Section>
                      {Array.isArray(themeComponent.props) &&
                      themeComponent.props.length > 0 ? (
                        <Section>
                          <Putter
                            endpoint={url + "/components/" + editingSection._id}
                            prefillEndpoint={url}
                            prefillFn={(template) =>
                              template
                                ? (template as Template).components.find(
                                    (c) => c._id === editingSection._id
                                  )
                                : undefined
                            }
                            method="PATCH"
                            props={themeComponent.props}
                            pathPrefix="props"
                            previewPathPrefix="propsPopulated"
                            onSubmit={(template) => {
                              form.setData({
                                data: template as Template,
                                reset: true
                              });
                              const iframe = new Frame("preview");
                              iframe.reload();
                            }}
                            onLoad={publisher.setLoading}
                            refreshHash={publisher.publishDiscardHash}
                            queryParams={queryParams}
                            prefillQueryParams={queryParams}
                          />
                        </Section>
                      ) : !themeComponent.richContent ? (
                        <Card outlined>
                          <EmptyState
                            title="Denne seksjonen har ingen felter å endre!"
                            text={
                              <span>
                                Det kan være seksjonen henter innhold fra{" "}
                                <strong>
                                  <Link to="/designer/globalt">
                                    Globalt innhold
                                  </Link>
                                </strong>
                                . Prøv der!
                              </span>
                            }
                            smaller
                          />
                        </Card>
                      ) : null}
                    </>
                  ) : (
                    <Section>
                      <Text element="h2" variant="title">
                        {editingSectionTemplate?.name}
                      </Text>
                    </Section>
                  )}
                </SidebarPager>
              )}
              {!!themeComponent && themeComponent.richContent && (
                <>
                  <RichContentEditor
                    key={publisher.publishDiscardHash}
                    endpoint={url + "/components/" + editingSection._id}
                    components={components}
                    prefillEndpoint={url}
                    prefillFn={(template) =>
                      template
                        ? (template as Template).components.find(
                            (c) => c._id === editingSection._id
                          )
                        : undefined
                    }
                    onSubmit={(template) => {
                      form.setData({ data: template as Template, reset: true });
                      const iframe = new Frame("preview");
                      iframe.reload();
                    }}
                    onClose={() => setEditingSection(undefined)}
                    onLoad={publisher.setLoading}
                    onNavigateComponent={(exit) =>
                      setIsNavigatingComponent(!exit)
                    }
                    initialEditingSection={editingRichContentComponent}
                    initialEditingIndex={editingRichContentIndex}
                    queryParams={queryParams}
                    prefillQueryParams={queryParams}
                  />
                  {!isNavigatingComponent && <Ruler />}
                </>
              )}
              {!isNavigatingComponent && (
                <Fader variant="left">
                  {editingSection.component && (
                    <Section>
                      <Label htmlFor="name">Navn</Label>
                      <TextInput
                        id="name"
                        type="text"
                        value={form.getValue(
                          "components." + editingSectionIndex + ".name"
                        )}
                        onChange={(e) => {
                          form.setField({
                            path: "components." + editingSectionIndex + ".name",
                            value: e.target.value,
                            submit: true,
                            debounce: true
                          });
                        }}
                        readOnly={publisher.isRevision}
                      />
                    </Section>
                  )}
                  {!publisher.isRevision && (
                    <Section>
                      <ButtonList align="stretch">
                        <Button
                          type="button"
                          alternate
                          onClick={() => {
                            if (
                              window.confirm(
                                "Er du sikker på at du vil slette seksjonen?"
                              )
                            ) {
                              form.submit(undefined, {
                                components: form.data.components.filter(
                                  (c) => c._id !== editingSection._id
                                )
                              });

                              setEditingSection(undefined);
                            }
                          }}
                        >
                          Slett seksjon
                        </Button>
                      </ButtonList>
                    </Section>
                  )}
                </Fader>
              )}
            </>
          )}
        </Block>
      </Sidebar>
      <Main noMaxWidth fullColumn>
        <Section hugBottom>
          {previewToken && (
            <Fader>
              <Preview id="preview" src={previewUrl} />
            </Fader>
          )}
        </Section>
        <ActionBar
          publisher={publisher}
          discard
          publish
          destroy
          revisions
          revisionReference={form.data.draftFor}
          revisionType={RevisionType.Template}
          experiment={experimentId?.experiment}
        />
      </Main>
    </Wrapper>
  );
};

export default TemplateDesigner;
