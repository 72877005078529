import { Theme } from "@ludens-reklame/rubics-sdk";
import globalComponents from "@ludens-reklame/rubics-global-components";

async function getTheme(themeName: string): Promise<Theme> {
  let theme: Theme;

  switch (themeName) {
    case "electric":
      theme = (await import("@ludens-reklame/rubics-theme-electric")).default;
      break;
    case "education":
      theme = (await import("@ludens-reklame/rubics-theme-education")).default;
      break;
    case "tech-design":
      theme = (await import("@ludens-reklame/rubics-theme-tech-design"))
        .default;
      break;
    case "shop":
      theme = (await import("@ludens-reklame/rubics-theme-shop")).default;
      break;
    case "stendi":
      theme = (await import("@ludens-reklame/rubics-theme-stendi")).default;
      break;
    case "alt":
      theme = (await import("@ludens-reklame/rubics-theme-alt-installation"))
        .default;
      break;
    case "brand-style-guide":
      theme = (await import("@ludens-reklame/rubics-theme-brand-style-guide"))
        .default;
      break;
    case "intranet":
      theme = (await import("@ludens-reklame/rubics-theme-intranet")).default;
      break;
    case "campaign":
      theme = (await import("@ludens-reklame/rubics-theme-campaign")).default;
      break;
    case "marint":
      theme = (await import("@ludens-reklame/rubics-theme-marint")).default;
      break;
    case "g24":
      theme = (await import("@ludens-reklame/rubics-theme-g24")).default;
      break;
    case "elkonor":
      theme = (await import("@ludens-reklame/rubics-theme-elkonor")).default;
      break;
    case "cox":
      theme = (await import("@ludens-reklame/rubics-theme-cox")).default;
      break;
    case "inspiration":
      theme = (await import("@ludens-reklame/rubics-theme-inspiration"))
        .default;
      break;
    case "docs":
      theme = (await import("@ludens-reklame/rubics-theme-docs")).default;
      break;
    case "eltera":
      theme = (await import("@ludens-reklame/rubics-theme-eltera")).default;
      break;
    case "eltera-v2":
      theme = (await import("@ludens-reklame/rubics-theme-eltera-v2")).default;
      break;
    case "ftg":
      theme = (await import("@ludens-reklame/rubics-theme-ftg")).default;
      break;
    case "tilvalg":
      theme = (await import("@ludens-reklame/rubics-theme-tilvalg")).default;
      break;
    default:
      throw new Error(`Theme ${themeName} not found`);
  }

  theme.components = [...theme.components, ...globalComponents];

  return theme;
}

export default getTheme;
