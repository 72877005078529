import styled from "styled-components";
import { baseInputStyles } from "./TextInput";

const TextArea = styled.textarea`
  ${baseInputStyles}
  padding: ${(props) => `0.8em ${props.theme.spacing.small}`};
  height: 10em;
`;

export default TextArea;
