import * as React from "react";
import styled, { css } from "styled-components";
import { AiOutlineSearch } from "react-icons/ai";
import Icon from "../Icon/Icon";
import { PowersearchContext } from "./PowersearchContext";
import { powersearchBoxStyles } from "./powersearch.styles";

const focusKeys = ['Space', 'Backspace'];
const inputShouldFocus = (e: KeyboardEvent) => {
  if (e.key.length === 1) return true;
  return focusKeys.includes(e.code);
}

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'ref' | 'onChange' | 'value' | 'id' | 'type'> {
  active: boolean;
}

export function PowersearchInput({ active, ...props }: Props) {
  const { results, search } = React.useContext(PowersearchContext);
  const input = React.useRef<HTMLInputElement>(null);
  const itemsLength = results.length;

  const handleOnChange = React.useCallback((e) => search(e.target.value), [search]);

  React.useEffect(() => {
    const keydown = (e: KeyboardEvent) => {
      const isInputActive = input.current !== document.activeElement;
      if(active && input.current && isInputActive && inputShouldFocus(e)) {
        input.current.focus();
      }
    }
    document.addEventListener("keydown", keydown);
    return () => {
      document.removeEventListener("keydown", keydown);
    }
  }, [active]);

  React.useEffect(() => {
    if (active && input.current) {
      input.current.select();
    }
  }, [active]);

  return (
    <Label htmlFor="powersearch-input" active={active}>
      <input
        ref={input}
        onChange={handleOnChange}
        aria-expanded={itemsLength > 0}
        type="text"
        id="powersearch-input"
        placeholder="Søk på sider, produkter osv.."
        autoCapitalize="none"
        autoComplete="off"
        role="combobox"
        aria-autocomplete="list"
        aria-controls="powersearch-list"
        {...props}
      />
      <Icon><AiOutlineSearch /></Icon>
    </Label>
  );
}

const Label = styled.label<{ active: boolean; }>`
  position: relative;
  
  > input {
    ${powersearchBoxStyles};
    background: ${(props) => props.theme.colors.backgroundInput};
    border-radius: ${props => props.theme.sizes.negOne};
    padding: 1rem;
    font-size: 1.25rem;
    color: ${(props) => props.theme.colors.textPrimary};
  }
  
  > span {
    font-size: ${(props) => props.theme.sizes.plusThree};
    position: absolute;
    top: -7px;
    right: 25px;
  }
  
  ${props => props.active && css`
    input {
      background: ${(props) => props.theme.colors.backgroundInBetween};
    }
  `};
`;
