import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { DiscountCode } from "../../../types/apiResponses";
import InlineEdit from "../../../components/InlineEdit/InlineEdit";
import createInputField from "../../../util/createInputField";
import { Button } from "../../../style-guide/Button/Button";

interface Props {
  code: DiscountCode;
  isNew: boolean;
  onChange: (code: DiscountCode) => any;
  onDelete: () => any;
  readOnly?: boolean;
}

const Code: React.FC<Props> = ({
  code,
  isNew,
  onChange,
  onDelete,
  readOnly
}) => {
  return (
    <InlineEdit
      expanded={isNew}
      alignItems="flex-start"
      actions={[
        <Button
          type="button"
          aria-label="Slett"
          circular
          outlined
          smaller
          disabled={readOnly}
          onClick={() => {
            if (!readOnly) {
              onDelete();
            }
          }}
        >
          <AiOutlineDelete />
        </Button>
      ]}
      headerColumns={[
        {
          width: "400px",
          node: createInputField({
            key: "code",
            type: "text",
            value: code.code,
            required: true,
            hugTop: true,
            autoFocus: isNew,
            readOnly,
            onChange: (value) => {
              onChange({
                ...code,
                code: value
              });
            }
          })
        },
        {
          width: "400px",
          node: createInputField({
            key: "label",
            type: "text",
            value: code.label,
            hugTop: true,
            readOnly,
            onChange: (value) => {
              onChange({
                ...code,
                label: value
              });
            }
          })
        },
        {
          width: "150px",
          node: createInputField({
            key: "active",
            type: "boolean",
            value: code.active,
            hugTop: true,
            readOnly,
            onChange: (value) => {
              onChange({
                ...code,
                active: value
              });
            }
          })
        }
      ]}
    />
  );
};

export default Code;
