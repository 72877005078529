const draftPrefix = "draft-";

function undraftSlug(slug: string): string {
  let _slug = slug;

  if (_slug.startsWith(draftPrefix)) {
    _slug = slug.replace(draftPrefix, "");
  }

  if (_slug === "index") {
    return "";
  }

  return _slug;
}

export default undraftSlug;
