import React, { useMemo } from "react";
import { Td, Tr } from "../../../style-guide/Table/Table";
import { Product, Attribute } from "../../../types/apiResponses";
import { FieldSetter } from "../../../hooks/useForm";
import Select from "../../../style-guide/Inputs/Select";
import { ANY_ATTRIBUTE_VALUE_PLACEHOLDER } from "../../../constants/general";

interface Props {
  attribute: Omit<Attribute, "site">;
  attributer: Pick<Product, "attributes">;
  setField: FieldSetter;
  saveOnSelect?: boolean;
  readOnly?: boolean;
  allowAnyValue?: boolean;
}

const AttributeEdit: React.FC<Props> = ({
  attribute,
  attributer,
  setField,
  saveOnSelect,
  readOnly,
  allowAnyValue
}) => {
  const _attribute = useMemo(
    () => attributer.attributes.find((da) => da.name === attribute.name),
    [attributer.attributes, attribute.name]
  );

  const option = useMemo(
    () =>
      _attribute
        ? attribute.options.find((o) => o.value === _attribute.value)
        : undefined,
    [_attribute, attribute.options]
  );

  return (
    <Tr>
      <Td verticalAlign="middle">{attribute.name}</Td>
      <Td verticalAlign="middle">
        <Select
          disabled={readOnly}
          value={
            option
              ? option._id
              : _attribute &&
                _attribute.value === ANY_ATTRIBUTE_VALUE_PLACEHOLDER
              ? _attribute.value
              : ""
          }
          onChange={(e) => {
            const option = attribute.options.find(
              (o) => o._id === e.target.value
            );

            if (e.target.value === ANY_ATTRIBUTE_VALUE_PLACEHOLDER || option) {
              const value = {
                name: attribute.name,
                type: attribute.type,
                value: option ? option.value : ANY_ATTRIBUTE_VALUE_PLACEHOLDER,
                meta: option ? option.meta : undefined,
                categorization: option ? option.categorization : undefined
              };

              if (_attribute) {
                setField({
                  path: "attributes",
                  value: attributer.attributes.map((a) => {
                    if (a.name === attribute.name) {
                      return {
                        ...a,
                        ...value
                      };
                    }

                    return a;
                  }),
                  submit: saveOnSelect
                });
              } else {
                setField({
                  path: "attributes",
                  value: [...attributer.attributes, value],
                  submit: saveOnSelect
                });
              }
            } else if (!e.target.value) {
              setField({
                path: "attributes",
                value: attributer.attributes.filter((a) => {
                  if (a.name === attribute.name) {
                    return false;
                  }

                  return true;
                }),
                submit: saveOnSelect
              });
            }
          }}
        >
          <option value="">Ingen verdi</option>
          {allowAnyValue && (
            <option value={ANY_ATTRIBUTE_VALUE_PLACEHOLDER}>
              Enhver verdi
            </option>
          )}
          {attribute.options.map((o) => (
            <option key={o._id} value={o._id}>
              {o.value}
            </option>
          ))}
        </Select>
      </Td>
    </Tr>
  );
};

export default AttributeEdit;
