import React from "react";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import { Product } from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Section from "../../../style-guide/Section/Section";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import createPublisherInputField from "../../../util/createPublisherInputField";
import GooglePreview from "../../../components/GooglePreview/GooglePreview";
import useCanonicalUrl from "../../../hooks/useCanonicalUrl";
import SEOHelper from "../../../components/SEOHelper/SEOHelper";
import Ruler from "../../../style-guide/Ruler/Ruler";

interface Props {
  publisher: UsePublisherInterface<Product>;
}

const SEO: React.FC<Props> = ({ publisher }) => {
  const form = publisher.form;
  const url = useCanonicalUrl([form.data.slug]);

  return (
    <Fader>
      <Section hugTop>
        <Card>
          <Block>
            <Section hugTop>
              <Text element="h2" variant="title">
                Generelt
              </Text>
            </Section>
            {createPublisherInputField(publisher, {
              path: "externalDescription",
              label: "Ekstern beskrivelse",
              description:
                "For bruk i produktfeeder, som Google Merchant Center",
              type: "textarea",
              autosave: true
            })}
            {createPublisherInputField(publisher, {
              path: "customCanonicalEnabled",
              label: "Bruk egendefinert canonical-URL",
              type: "boolean",
              autosave: true
            })}
            {form.data.customCanonicalEnabled &&
              createPublisherInputField(publisher, {
                path: "customCanonicalUrl",
                label: "Canonical-URL",
                type: "url",
                required: true,
                placeholder: "https://eksempel.no/min-url",
                autosave: true
              })}
            {createPublisherInputField(publisher, {
              path: "redirectEnabled",
              label: "Redirect til URL",
              description:
                "Ved å skru på Redirect til URL, vil besøkende bli omdirigert til URL-en du skriver inn og ikke til produktet.",
              type: "boolean",
              autosave: true
            })}
            {form.data.redirectEnabled && (
              <>
                {createPublisherInputField(publisher, {
                  path: "redirectUrl",
                  label: "Redirect-URL",
                  type: "url",
                  required: true,
                  placeholder: "https://eksempel.no/min-url",
                  autosave: true
                })}
                {createPublisherInputField(publisher, {
                  path: "redirectStatusCode",
                  label: "Redirect-statuskode",
                  type: "radio",
                  value: form.data.redirectStatusCode || 302,
                  options: [
                    {
                      value: 302,
                      label: "302 (midlertidig)"
                    },
                    {
                      value: 301,
                      label: "301 (permanent)"
                    }
                  ],
                  autosave: true
                })}
              </>
            )}
          </Block>
        </Card>
      </Section>
      <Ruler />
      <Flex align="flex-start">
        <FlexKid flex={2}>
          <Section hugTop>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Google
                  </Text>
                </Section>
                {createPublisherInputField(publisher, {
                  path: "seo.metaTitle",
                  label: "Tittel",
                  type: "text",
                  placeholder: form.getValue("name"),
                  autosave: true,
                  helpText: (
                    <SEOHelper
                      limit={60}
                      value={
                        form.getValue("seo.metaTitle")
                          ? form.getValue("seo.metaTitle")
                          : form.getValue("title")
                          ? form.getValue("title")
                          : ""
                      }
                    />
                  )
                })}
                {createPublisherInputField(publisher, {
                  path: "seo.metaDescription",
                  label: "Beskrivelse",
                  type: "textarea",
                  placeholder: form.getValue("description"),
                  autosave: true,
                  helpText: (
                    <SEOHelper
                      limit={155}
                      value={
                        form.getValue("seo.metaDescription")
                          ? form.getValue("seo.metaDescription")
                          : form.getValue("description")
                          ? form.getValue("description")
                          : ""
                      }
                    />
                  )
                })}
                {createPublisherInputField(publisher, {
                  path: "seo.metaImage",
                  label: "Bilde",
                  type: "media",
                  setField: (v) => {
                    if (v && v.ref) {
                      form.setField({
                        path: "seo.metaImage",
                        value: v.ref,
                        submit: true
                      });
                    } else {
                      form.setField({
                        path: "seo.metaImage",
                        value: null,
                        submit: true
                      });
                    }
                  },
                  value: form.getValue("seo.metaImagePopulated")
                })}
              </Block>
            </Card>
          </Section>
          <Section>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Facebook
                  </Text>
                </Section>
                {createPublisherInputField(publisher, {
                  path: "seo.ogTitle",
                  label: "Tittel",
                  type: "text",
                  placeholder: form.getValue("name"),
                  autosave: true,
                  helpText: (
                    <SEOHelper
                      limit={60}
                      value={
                        form.getValue("seo.ogTitle")
                          ? form.getValue("seo.ogTitle")
                          : form.getValue("name")
                          ? form.getValue("name")
                          : ""
                      }
                    />
                  )
                })}
                {createPublisherInputField(publisher, {
                  path: "seo.ogDescription",
                  label: "Beskrivelse",
                  type: "textarea",
                  autosave: true,
                  placeholder: form.getValue("description"),
                  helpText: (
                    <SEOHelper
                      limit={200}
                      value={
                        form.getValue("seo.ogDescription")
                          ? form.getValue("seo.ogDescription")
                          : form.getValue("description")
                          ? form.getValue("description")
                          : ""
                      }
                    />
                  )
                })}
                {createPublisherInputField(publisher, {
                  path: "seo.ogImage",
                  label: "Bilde",
                  type: "media",
                  setField: (v) => {
                    if (v && v.ref) {
                      form.setField({
                        path: "seo.ogImage",
                        value: v.ref,
                        submit: true
                      });
                    } else {
                      form.setField({
                        path: "seo.ogImage",
                        value: null,
                        submit: true
                      });
                    }
                  },
                  value: form.getValue("seo.ogImagePopulated")
                })}
              </Block>
            </Card>
          </Section>
          <Section>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Twitter
                  </Text>
                </Section>
                {createPublisherInputField(publisher, {
                  path: "seo.twitterTitle",
                  label: "Tittel",
                  type: "text",
                  placeholder: form.getValue("name"),
                  autosave: true,
                  helpText: (
                    <SEOHelper
                      limit={70}
                      value={
                        form.getValue("seo.twitterTitle")
                          ? form.getValue("seo.twitterTitle")
                          : form.getValue("name")
                          ? form.getValue("name")
                          : ""
                      }
                    />
                  )
                })}
                {createPublisherInputField(publisher, {
                  path: "seo.twitterDescription",
                  label: "Beskrivelse",
                  type: "textarea",
                  autosave: true,
                  placeholder: form.getValue("description"),
                  helpText: (
                    <SEOHelper
                      limit={200}
                      value={
                        form.getValue("seo.twitterDescription")
                          ? form.getValue("seo.twitterDescription")
                          : form.getValue("description")
                          ? form.getValue("description")
                          : ""
                      }
                    />
                  )
                })}
                {createPublisherInputField(publisher, {
                  path: "seo.twitterImage",
                  label: "Bilde",
                  type: "media",
                  setField: (v) => {
                    if (v && v.ref) {
                      form.setField({
                        path: "seo.twitterImage",
                        value: v.ref,
                        submit: true
                      });
                    } else {
                      form.setField({
                        path: "seo.twitterImage",
                        value: null,
                        submit: true
                      });
                    }
                  },
                  value: form.getValue("seo.twitterImagePopulated")
                })}
              </Block>
            </Card>
          </Section>
        </FlexKid>
        <FlexKid flex={1} spaceLeft>
          <Section hugTop>
            <Card outlined>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Forhåndsvisning
                  </Text>
                </Section>
                <GooglePreview
                  url={url}
                  title={
                    form.getValue("seo.metaTitle") || form.getValue("name")
                  }
                  description={
                    form.getValue("seo.metaDescription") ||
                    form.getValue("description")
                  }
                />
              </Block>
            </Card>
          </Section>
        </FlexKid>
      </Flex>
    </Fader>
  );
};

export default SEO;
