import React from "react";
import { Switch, Route, Redirect } from "react-router";
import Orders from "./Orders";
import Order from "./Order";

const OrdersRoutes: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path="/butikk"
        render={() => <Redirect to="/butikk/ordrer" />}
      />
      <Route exact path="/butikk/ordrer" component={Orders} />
      <Route
        path="/butikk/ordrer/:order"
        render={(props) => <Order key={props.match.params.order} {...props} />}
      />
    </Switch>
  );
};

export default OrdersRoutes;
