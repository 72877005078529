import React, { useEffect } from "react";
import { RouteComponentProps, Prompt, Switch, Route } from "react-router-dom";
import { AiOutlineProfile, AiOutlineCalculator } from "react-icons/ai";
import { MdPalette } from "react-icons/md";
import { ProductTemplate as IProductTemplate } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import Form from "../../style-guide/Inputs/Form";
import Fader from "../../style-guide/Fader/Fader";
import Block from "../../style-guide/Block/Block";
import usePublisher from "../../hooks/usePublisher";
import PublisherHelpers from "../../util/PublisherHelpers";
import {
  InlineActions,
  InlineActionLink
} from "../../style-guide/InlineActions/InlineActions";
import Section from "../../style-guide/Section/Section";
import ActionBar from "../../components/ActionBar/ActionBar";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import Crumb from "../../components/Crumb/Crumb";
import { LEAVE_PAGE_MESSAGE } from "../../constants/general";
import General from "./SubViews/General";
import CustomizeOptions from "./SubViews/CustomizeOptions";
import { RevisionType } from "../../constants/api";
import Attributes from "./SubViews/Attributes";
import { CgListTree } from "react-icons/cg";
import CrossSell from "./SubViews/CrossSell";

interface Props
  extends RouteComponentProps<{
    productTemplate: string;
  }> {}

const ProductTemplate: React.FC<Props> = ({ match }) => {
  const productTemplateId = match.params.productTemplate;

  const breadcrumbs = useBreadcrumbs();
  const publisher = usePublisher<IProductTemplate>({
    id: productTemplateId,
    baseUrl: ["product-templates"],
    baseDashboardUrl: "/butikk/produktmaler",
    initialData: {
      attributes: [],
      customizeOptions: []
    },
    pathsToCopy: [
      {
        path: "name",
        label: "Navn"
      },
      {
        path: "description",
        label: "Beskrivelse"
      },
      {
        path: "attributes",
        label: "Attributter"
      },
      {
        path: "customizeOptions",
        label: "Brukertilpasning"
      },
      {
        path: "crossSale",
        label: "Kryss-salg"
      }
    ]
  });

  useEffect(() => {
    breadcrumbs.swapEntry(productTemplateId, publisher.form.data.name);
  }, [breadcrumbs, publisher.form.data, productTemplateId]);

  return (
    <Form onSubmit={publisher.form.submit}>
      <Prompt
        when={publisher.form.hasMadeChanges}
        message={(location) => {
          if (location.pathname.includes(productTemplateId)) {
            return true;
          }

          return LEAVE_PAGE_MESSAGE;
        }}
      />
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/butikk/produktmaler">Produktmaler</Crumb>
            {PublisherHelpers.getResourceTitle(
              publisher,
              "name",
              "Ny produktmal"
            )}
          </Text>
        </Block>
        <Block hugTop hugBottom>
          <InlineActions>
            <InlineActionLink exact to={publisher.dashboardUrl}>
              <AiOutlineProfile /> Generelt
            </InlineActionLink>
            <InlineActionLink
              exact
              to={`${publisher.dashboardUrl}/attributter`}
            >
              <AiOutlineCalculator /> Attributter
            </InlineActionLink>
            <InlineActionLink
              exact
              to={`${publisher.dashboardUrl}/brukertilpasning`}
            >
              <MdPalette /> Brukertilpasning
            </InlineActionLink>
            <InlineActionLink
              exact
              to={PublisherHelpers.createDashboardUrl(publisher, [
                "kryss-salg"
              ])}
            >
              <CgListTree /> Kryss-salg
            </InlineActionLink>
          </InlineActions>
        </Block>
        <Section hugTop>
          <Switch>
            <Route
              exact
              path={publisher.dashboardUrl}
              render={() => <General publisher={publisher} />}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/attributter`}
              render={() => <Attributes publisher={publisher} />}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/brukertilpasning`}
              render={() => (
                <CustomizeOptions
                  form={publisher.form}
                  readOnly={publisher.isRevision}
                />
              )}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/kryss-salg`}
              render={() => <CrossSell publisher={publisher} />}
            />
          </Switch>
        </Section>
      </Fader>
      <ActionBar
        publisher={publisher}
        revisions
        revisionReference={publisher.form.data._id}
        revisionType={RevisionType.ProductTemplate}
        destroy
      />
    </Form>
  );
};

export default ProductTemplate;
