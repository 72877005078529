import React, { useContext, useState } from "react";
import { RouteComponentProps, Switch, Route, Prompt } from "react-router-dom";
import { Media, Site as ISite } from "../../../types/apiResponses";
import Text from "../../../style-guide/Text/Text";
import Form from "../../../style-guide/Inputs/Form";
import Fader from "../../../style-guide/Fader/Fader";
import Block from "../../../style-guide/Block/Block";
import usePublisher, {
  UsePublisherInterface
} from "../../../hooks/usePublisher";
import PublisherHelpers from "../../../util/PublisherHelpers";
import Section from "../../../style-guide/Section/Section";
import ActionBar from "../../../components/ActionBar/ActionBar";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Card from "../../../style-guide/Card/Card";
import createPublisherInputField from "../../../util/createPublisherInputField";
import Crumb from "../../../components/Crumb/Crumb";
import { UserContext } from "../../../context/User";
import { LEAVE_PAGE_MESSAGE } from "../../../constants/general";
import {
  Permissions,
  RevisionType,
  UserCreation
} from "../../../constants/api";
import { SiteContext } from "../../../context/Site";
import {
  customerCreation,
  Localization,
  protection,
  userCreation
} from "../../../constants/localizations";

interface Props extends RouteComponentProps<{}> {}

const Site: React.FC<Props> = () => {
  const user = useContext(UserContext).data;
  const siteContext = useContext(SiteContext);

  const publisher = usePublisher<ISite>({
    id: user.site!,
    baseUrl: ["site"],
    baseDashboardUrl: "/innstillinger/nettsted",
    initialData: {
      userCreation: UserCreation.Invite
    },
    skipId: true,
    pathsToCopy: [
      {
        path: "displayName",
        label: "Navn"
      },
      {
        path: "notificationEmail",
        label: "Varslingsepost"
      },
      {
        path: "askForMarketingConsent",
        label: "Spør om lov til å sende markedsføring"
      },
      {
        path: "userCreation",
        label: "Brukerregistrering"
      },
      {
        path: "customerCreation",
        label: "Kunderegistrering"
      },
      {
        path: "baseStyles",
        label: "Grunnstiler"
      },
      {
        path: "baseStyles",
        label: "Grunnstiler"
      },
      {
        path: "logo",
        label: "Logo"
      },
      {
        path: "favicon",
        label: "Favicon"
      }
    ],
    onSubmit: (site: ISite) => {
      if (site) {
        siteContext.setData(site);
      }
    }
  });

  return (
    <Form onSubmit={publisher.form.submit}>
      <Prompt
        when={publisher.form.hasMadeChanges}
        message={() => LEAVE_PAGE_MESSAGE}
      />
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/innstillinger">Innstillinger</Crumb>
            {PublisherHelpers.getResourceTitle(
              publisher,
              "displayName",
              publisher.form.data.name
            )}
          </Text>
        </Block>
        <Section>
          <Switch>
            <Route
              exact
              path="/innstillinger/nettsted"
              render={() => <General publisher={publisher} />}
            />
          </Switch>
        </Section>
      </Fader>
      <ActionBar
        publisher={publisher}
        revisions
        revisionType={RevisionType.Site}
        revisionReference={publisher.form.data._id}
      />
    </Form>
  );
};

interface PageProps {
  publisher: UsePublisherInterface<ISite>;
}

const General: React.FC<PageProps> = ({ publisher }) => {
  const [tempImage, setTempImage] = useState<Media | null>(null);

  const setField = publisher.form.setField;
  const data = publisher.form.data;

  return (
    <Fader>
      <Flex align="flex-start">
        <FlexKid flex={2}>
          <Section hugTop>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Generelt
                  </Text>
                </Section>
                {createPublisherInputField(publisher, {
                  path: "displayName",
                  label: "Navn",
                  type: "text"
                })}
                {createPublisherInputField(publisher, {
                  path: "notificationEmail",
                  label: "Varslingsepost",
                  type: "email"
                })}
                {(data.domains || []).length > 0 &&
                  createPublisherInputField(publisher, {
                    path: "canonicalDomain",
                    label: "Canonical-domene",
                    type: "select",
                    options: (data.domains || []).map((d) => ({
                      value: d,
                      label: d
                    }))
                  })}
                {!!data.canonicalDomain &&
                  createPublisherInputField(publisher, {
                    path: "redirectToCanonical",
                    label: "Redirect til canonical-URL",
                    type: "boolean"
                  })}
                {data.departmentsEnabled &&
                  createPublisherInputField(publisher, {
                    path: "sharedContentCanonicalDomain",
                    label: "Canonical-domene for delt innhold",
                    description:
                      "Siden avdelinger kan dele innhold, kan man få duplikatproblemer som søkemotorene ikke er så innmari fan av. Ved å definere et canonical-domene for delt innhold, vil eierskapet til innholdet tydeligjøres og duplikatproblematikken kan unngås.",
                    placeholder: "www.mitt-domene.no",
                    type: "text"
                  })}
                {createPublisherInputField(publisher, {
                  path: "pageTitleSuffix",
                  label: "Suffiks til sidetittel",
                  placeholder: "– Min Nettbutikk AS",
                  type: "text"
                })}
                {createPublisherInputField(publisher, {
                  path: "askForMarketingConsent",
                  label: "Spør om lov til å sende markedsføring",
                  type: "boolean"
                })}
                {createPublisherInputField(publisher, {
                  path: "cookieDialogEnabled",
                  label: "Aktiver dialog for informasjonskapsler",
                  type: "boolean"
                })}
              </Block>
            </Card>
          </Section>
          <Section>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Tilgang
                  </Text>
                </Section>
                {createPublisherInputField(publisher, {
                  path: "userCreation",
                  label: "Brukerregistrering",
                  description: (
                    <>
                      Vanligvis kreves det invitasjon for å få tilgang som
                      bruker i Rubics, men ved å velge <em>E-postdomene</em>,
                      kan hvem som helst med e-post som matcher domenene som
                      defineres i <em>E-postdomener for registrering</em> få
                      lesetilgang til nettsiden. Dette kan være verdifullt
                      nettsiden er bak innlogg (definert under{" "}
                      <em>Nettsidetilgjengelighet</em>). La det stå på{" "}
                      <em>Invitasjon</em> om du er usikker.
                    </>
                  ),
                  type: "radio",
                  options: Object.keys(userCreation).map((k) => ({
                    value: k,
                    label: userCreation[k]
                  }))
                })}
                {data.userCreation === UserCreation.EmailDomain &&
                  createPublisherInputField(publisher, {
                    path: "userCreationEmailDomains",
                    label: "E-postdomener for registrering",
                    description: (
                      <>
                        Liste med e-postdomener (eks minarbeidsplass.no) som
                        aksepteres dersom <em>Brukerregistrering</em> er satt
                        til <em>E-postdomene</em>.
                      </>
                    ),
                    placeholder: "E-postdomene",
                    type: "list"
                  })}
                {createPublisherInputField(publisher, {
                  path: "protection",
                  label: "Nettsidetilgjengelighet",
                  description: (
                    <>
                      I utgangspunktet er alle Rubics-nettsider offentlig
                      tilgjengelig, altså at hvem som helst som besøker
                      nettstedet ditt kan lese innholdet på sidene som er
                      publisert. Dersom du ønsker å skjule innholdet bak
                      innlogging, velger du noe annet enn <em>Offentlig</em>{" "}
                      her. <strong>Merk:</strong> dette skjuler ALT innhold bak
                      innlogging.
                    </>
                  ),
                  type: "radio",
                  value: data.protection || Permissions.Visitor,
                  options: Object.keys(protection).map((k) => ({
                    value: k,
                    label: protection[k],
                    description: protectionDescriptions[k]
                  }))
                })}
                {data.hasEcommerce &&
                  createPublisherInputField(publisher, {
                    path: "customerCreation",
                    label: "Kunderegistrering",
                    type: "radio",
                    options: Object.keys(customerCreation).map((k) => ({
                      value: k,
                      label: customerCreation[k]
                    }))
                  })}
              </Block>
            </Card>
          </Section>
        </FlexKid>
        <FlexKid flex={1} spaceLeft>
          <Section hugTop>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Bilder
                  </Text>
                </Section>
                {createPublisherInputField(publisher, {
                  path: "logo",
                  label: "Logo",
                  type: "media",
                  minimize: true,
                  setField: (media) => {
                    if (!media || media.ref === "") {
                      setField({
                        path: "logo",
                        value: null
                      });
                    } else {
                      setField({
                        path: "logo",
                        value: media.ref
                      });

                      setTempImage(media);
                    }
                  },
                  value:
                    !!data.logo && typeof data.logo !== "string"
                      ? {
                          ref: data.logo._id,
                          url: data.logo.secure_url,
                          alt: "Logo"
                        }
                      : tempImage
                })}
                {createPublisherInputField(publisher, {
                  path: "favicon",
                  label: "Favicon",
                  type: "media",
                  minimize: true,
                  setField: (media) => {
                    if (!media || media.ref === "") {
                      setField({
                        path: "favicon",
                        value: null
                      });
                    } else {
                      setField({
                        path: "favicon",
                        value: media.ref
                      });
                    }
                  },
                  value:
                    !!data.favicon && typeof data.favicon !== "string"
                      ? {
                          ref: data.favicon._id,
                          url: data.favicon.secure_url,
                          alt: "Favicon"
                        }
                      : undefined
                })}
              </Block>
            </Card>
          </Section>
          <Section>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Geolokasjon
                  </Text>
                </Section>
                {createPublisherInputField(publisher, {
                  path: "geo.coordinates.0",
                  label: "Lengdegrad",
                  type: "text",
                  placeholder: "0.000000"
                })}
                {createPublisherInputField(publisher, {
                  path: "geo.coordinates.1",
                  label: "Breddegrad",
                  type: "text",
                  placeholder: "0.000000"
                })}
              </Block>
            </Card>
          </Section>
        </FlexKid>
      </Flex>
    </Fader>
  );
};

const protectionDescriptions: Localization = {
  visitor: "Nettsiden er åpen for alle",
  internalVisitor: "Åpen for alle, men de må registrere seg først",
  reader: "Åpen for registrerte brukere med lesetilgang",
  admin: "Åpen for registrerte brukere med admin-tilgang"
};

export default Site;
