import React from "react";
import { useTable, Column } from "react-table";
import {
  Table as TableComponent,
  Td,
  Th,
  Tr
} from "../../style-guide/Table/Table";

export interface Col extends Column {
  align?: "left" | "center" | "right";
  verticalAlign?: "top" | "middle" | "bottom";
  style?: any;
}

interface Props {
  columns: Col[];
  data: {}[];
  tight?: boolean;
}

const Table: React.FC<Props> = ({ columns, data, tight }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data
    });

  return (
    <TableComponent {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              return (
                <Th
                  {...column.getHeaderProps()}
                  //@ts-ignore This could potentially be typed correctly
                  align={column.align}
                  //@ts-ignore This could potentially be typed correctly
                  verticalAlign={column.verticalAlign}
                  //@ts-ignore This could potentially be typed correctly
                  style={column.style}
                >
                  {column.render("Header")}
                </Th>
              );
            })}
          </Tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);

          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <Td
                    {...cell.getCellProps()}
                    //@ts-ignore This could potentially be typed correctly
                    align={cell.column.align}
                    //@ts-ignore This could potentially be typed correctly
                    verticalAlign={cell.column.verticalAlign}
                    tight={tight}
                  >
                    {cell.render("Cell")}
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </tbody>
    </TableComponent>
  );
};

export default Table;
