import React from "react";
import { Switch, Route } from "react-router";
import Products from "./Products";
import Product from "./Product";

const ProductsRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/butikk/produkter" component={Products} />
      <Route
        path="/butikk/produkter/:product"
        render={(props) => (
          <Product key={props.match.params.product} {...props} />
        )}
      />
    </Switch>
  );
};

export default ProductsRoutes;
