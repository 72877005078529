import styled from "styled-components";

interface LabelProps {
  inline?: boolean;
  hug?: boolean;
}

const Label = styled.label<LabelProps>`
  display: ${(props) => (props.inline ? "inline" : "block")};
  margin-bottom: ${(props) =>
    props.inline || props.hug ? "0" : props.theme.spacing.xs};
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
`;

const Description = styled.p`
  color: ${(props) => props.theme.colors.textSecondary};
  margin: 0 0 ${(props) => props.theme.spacing.xs};
`;

export default Label;
export { Description };
