import React from "react";
import _CodeEditor from "react-simple-code-editor";
import prism from "prismjs";
import DummyInput from "../../style-guide/Inputs/DummyInput";

interface Props {
  language: "html" | "js";
  code: string;
  onChange: (code: string) => any;
}

const CodeEditor: React.FC<Props> = ({ language, code, onChange }) => {
  return (
    <DummyInput useBackgroundVariantColor noPadding>
      <_CodeEditor
        value={code}
        highlight={(code) =>
          prism.highlight(code, prism.languages[language], language)
        }
        onValueChange={onChange}
        padding={17}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 14,
          minHeight: "200px",
          flex: 1,
          maxWidth: "850px"
        }}
      />
    </DummyInput>
  );
};

export default CodeEditor;
