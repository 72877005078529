import React, { useMemo, useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import equal from "deep-equal";
import { Wrapper, Sidebar, Main } from "../../style-guide/Layout/Layout";
import Block from "../../style-guide/Block/Block";
import Preview from "../../components/Preview/Preview";
import Text from "../../style-guide/Text/Text";
import Section from "../../style-guide/Section/Section";
import usePreviewToken from "../../hooks/usePreviewToken";
import useApiUrl from "../../hooks/useApiUrl";
import useTheme from "../../hooks/useTheme";
import { Page, Site } from "../../types/apiResponses";
import Frame from "../../util/Frame";
import Fader from "../../style-guide/Fader/Fader";
import Putter from "../../components/Putter/Putter";
import ActionBar from "../../components/ActionBar/ActionBar";
import usePublisher from "../../hooks/usePublisher";
import {
  Button,
  ButtonLink,
  ButtonList
} from "../../style-guide/Button/Button";
import { AiOutlineClose } from "react-icons/ai";
import { useSearch } from "../../hooks/useApi";
import apiUrl from "../../util/apiUrl";
import { SiteContext } from "../../context/Site";
import useThemeSwitcher from "../../hooks/useThemeSwitcher";
import useRevisionId from "../../hooks/useRevisionId";
import { RevisionType } from "../../constants/api";
import useSite from "../../hooks/useSite";
import { STOREFRONT_ENDPOINT } from "@ludens-reklame/rubics-sdk";
import getBaseApiUrl from "../../util/getBaseApiUrl";
import useExperimentId from "../../hooks/useExperimentId";
import { RequestData } from "../../util/api";
import Card from "../../style-guide/Card/Card";
import { UserContext } from "../../context/User";
import createPublisherInputField from "../../util/createPublisherInputField";
import Form from "../../style-guide/Inputs/Form";

interface Props extends RouteComponentProps {}

const ThemeDesigner: React.FC<Props> = () => {
  const user = useContext(UserContext).data;
  const site = useSite();
  const experimentId = useExperimentId();
  const revisionId = useRevisionId();
  const previewToken = usePreviewToken();
  const theme = useTheme();
  const siteContext = useContext(SiteContext);
  const pageSearchUrl = useApiUrl(["pages"]);
  const url = useApiUrl(["site"]);
  const isExperiment = useMemo<boolean>(() => !!experimentId, [experimentId]);

  const publisher = usePublisher<Site>({
    id: "",
    baseUrl: ["site"],
    baseDashboardUrl: "/designer/temainnstillinger",
    publishEndpoint: "theme/publish",
    discardEndpoint: "theme/discard",
    standalonePublishDiscardEndpoint: true,
    skipId: true,
    onPublishDiscard: (site) => {
      const iframe = new Frame("preview");
      iframe.reload();
      siteContext.setData(site);
    }
  });

  const sitePublisher = usePublisher<Site>({
    id: user.site!,
    baseUrl: ["site"],
    baseDashboardUrl: "/innstillinger/nettsted",
    skipId: true,
    onSubmit: (site: Site) => {
      const iframe = new Frame("preview");
      iframe.reload();
      siteContext.setData(site);
    }
  });

  useThemeSwitcher("light");

  const pagesSearch = useSearch<Page>({
    endpoint: pageSearchUrl,
    fetchOnMount: true,
    queryParams: {
      slug: "index",
      draft: false,
      fetchInherited: true
    }
  });

  if (pagesSearch.results.length > 0) {
    siteContext.data.staticPages.frontPage = pagesSearch.results[0]._id;
  }

  const previewUrl = useMemo(
    () =>
      previewToken && siteContext.data.staticPages.frontPage
        ? getBaseApiUrl() +
          "/" +
          STOREFRONT_ENDPOINT +
          "/" +
          apiUrl([
            "pages",
            siteContext.data.staticPages.frontPage +
              "/render?preview=true&token=" +
              previewToken +
              (revisionId ? `&revision=${revisionId}` : "") +
              (!!experimentId
                ? `&experiment=${experimentId.experiment}&experimentVariant=${experimentId.experimentVariant}`
                : "")
          ])
        : null,
    [
      siteContext.data.staticPages.frontPage,
      siteContext.data.name,
      previewToken,
      experimentId
    ]
  );

  const modified = useMemo(
    () =>
      !equal(siteContext.data.theme.config, siteContext.data.theme.configDraft),
    [siteContext.data.theme.config, siteContext.data.theme.configDraft]
  );

  const queryParams = useMemo<RequestData | undefined>(
    () =>
      isExperiment
        ? {
            experiment: experimentId!.experiment,
            experimentVariant: experimentId!.experimentVariant
          }
        : undefined,
    [isExperiment, experimentId]
  );

  if (!theme) {
    return null;
  }

  return (
    <Wrapper widerSidebar>
      <Sidebar wider>
        <Block>
          <Fader variant="left">
            <Section hugTop>
              <Text element="h1" variant="display3">
                Juster tema
              </Text>
            </Section>
          </Fader>
          <Fader variant="left">
            <Section>
              <ButtonLink to="/innstillinger" outlined alternate>
                <AiOutlineClose /> Lukk designer
              </ButtonLink>
            </Section>
            <Section>
              <Card outlined>
                <Block>
                  <Form onSubmit={sitePublisher.form.submit}>
                    <Text element="h2" variant="title">
                      Primærfarger
                    </Text>
                    <Text variant="body3" gutterTop>
                      Disse fargene brukes primært i standardkompontenter i
                      Rubics, som utsjekk og min side, men de kan også brukes av
                      enkelte temaer.
                    </Text>
                    {createPublisherInputField(sitePublisher, {
                      path: "baseStyles.color.primary",
                      label: "Primærfarge",
                      type: "color"
                    })}
                    {createPublisherInputField(sitePublisher, {
                      path: "baseStyles.color.onPrimary",
                      label: "Primærfarge-kontrast",
                      type: "color"
                    })}
                    {createPublisherInputField(sitePublisher, {
                      path: "baseStyles.color.secondary",
                      label: "Sekundærfarge",
                      type: "color"
                    })}
                    {createPublisherInputField(sitePublisher, {
                      path: "baseStyles.color.onSecondary",
                      label: "Sekundærfarge-kontrast",
                      type: "color"
                    })}
                    <ButtonList align="right" gutterTop>
                      <Button
                        type="submit"
                        disabled={!sitePublisher.form.hasMadeChanges}
                      >
                        Lagre
                      </Button>
                    </ButtonList>
                  </Form>
                </Block>
              </Card>
            </Section>
            <Section>
              <Putter
                endpoint={url}
                method="PATCH"
                prefillEndpoint={url}
                pathPrefix="theme.configDraft"
                props={theme.themeSchema}
                diff
                onSubmit={async (site) => {
                  const iframe = new Frame("preview");

                  siteContext.setData(site as Site);
                  iframe.reload();
                }}
                //@ts-ignore I don't know how to fix this
                defaultValues={theme.defaultThemeSettings}
                refreshHash={publisher.publishDiscardHash}
                queryParams={queryParams}
                prefillQueryParams={queryParams}
              />
            </Section>
          </Fader>
        </Block>
      </Sidebar>
      <Main noMaxWidth fullColumn>
        <Section>
          {previewUrl && (
            <Fader>
              <Preview id="preview" src={previewUrl} />
            </Fader>
          )}
        </Section>
        <ActionBar
          publisher={publisher}
          modified={modified}
          discard
          publish
          revisions
          revisionType={RevisionType.ThemeSettings}
          revisionReference={site._id}
          experiment={experimentId?.experiment}
        />
      </Main>
    </Wrapper>
  );
};

export default ThemeDesigner;
