import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, Prompt } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import { AiOutlineDelete, AiOutlineSave } from "react-icons/ai";
import { PriceType, ProductType } from "@ludens-reklame/rubics-sdk";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import {
  Catalog,
  Product as IProduct,
  ProductCatalogEntry
} from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import { Button } from "../../../style-guide/Button/Button";
import {
  InlineEditHead,
  InlineEditColumn
} from "../../../style-guide/InlineEdit/InlineEdit";
import Ruler from "../../../style-guide/Ruler/Ruler";
import InlineEdit from "../../../components/InlineEdit/InlineEdit";
import CrudModal from "../../../components/CrudModal/CrudModal";
import { currencyFormat } from "../../../util/intl";
import createInputField from "../../../util/createInputField";
import { catalogPriceType } from "../../../constants/localizations";
import { useSearch } from "../../../hooks/useApi";
import api, { Service } from "../../../util/api";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";
import { CatalogPriceType } from "../../../constants/api";
import useForm from "../../../hooks/useForm";
import Form from "../../../style-guide/Inputs/Form";
import useNotifications from "../../../hooks/useNotifications";
import { LEAVE_PAGE_MESSAGE } from "../../../constants/general";
import ProductPicker, {
  ProductTable
} from "../../../components/ProductPicker/ProductPicker";
import useSite from "../../../hooks/useSite";
import { Table, Tr, Th, Td } from "../../../style-guide/Table/Table";
import Status from "../../../style-guide/Status/Status";

interface Props {
  publisher: UsePublisherInterface<Catalog>;
  inherited: boolean;
}

const Products: React.FC<Props> = ({ publisher, inherited }) => {
  const form = publisher.form;
  const site = useSite();
  const [loading, setLoading] = useState<boolean>(false);
  const [unsavedProductIds, setUnsavedProductIds] = useState<string[]>([]);
  const { spawnModal } = useModal();

  const searchOpts = {
    endpoint: "products",
    queryParams: {
      catalog: form.data._id,
      fetchInherited: true
    }
  };

  const searcher = useSearch<IProduct>(searchOpts);

  const toggleProducts = useCallback(
    async (action: "add" | "remove", ids: string[]) => {
      setLoading(true);

      await api({
        service: Service.Admin,
        method: "POST",
        endpoint: `catalogs/${form.data._id}/${action}-products`,
        body: {
          ids
        }
      });

      setLoading(false);

      searcher.search(searchOpts);
    },
    [form.data._id, searcher, searchOpts]
  );

  const addProductModal = useCallback(() => {
    spawnModal(
      <CrudModal
        title="Legg til produkter"
        initialData={{}}
        fullscreen
        onSubmit={(data) => {
          if (data && data.products) {
            toggleProducts(
              "add",
              //@ts-ignore
              data.products.map((p) => p._id)
            );
          }
        }}
        fields={[
          {
            key: "product",
            render: (payload) => (
              <ProductPicker
                queryParams={{
                  notInCatalog: form.data._id,
                  parent: inherited ? true : false
                }}
                useWindow={false}
                getScrollParent={() => payload.modalBodyRef.current}
                renderView={(products) => (
                  <ProductTable
                    products={products}
                    multipleSelect
                    onSelect={(products) =>
                      payload.setField("products", products)
                    }
                  />
                )}
              />
            )
          }
        ]}
      />
    );
  }, [form.data, toggleProducts]);

  const handleUnload = useCallback(
    (e: BeforeUnloadEvent) => {
      if (unsavedProductIds.length > 0) {
        e.preventDefault();
        e.returnValue = "";
      }
    },
    [unsavedProductIds]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [handleUnload]);

  return (
    <>
      <Prompt
        when={unsavedProductIds.length > 0}
        message={() => LEAVE_PAGE_MESSAGE}
      />
      <Fader>
        <BusyBoy busy={loading}>
          <Card>
            <Block>
              <Flex>
                <FlexKid flex={1}>
                  <Text element="h2" variant="title">
                    Produkter
                  </Text>
                </FlexKid>
                {(!inherited || site.productCatalogAllowAdding) && (
                  <FlexKid spaceLeft>
                    <Button type="button" outlined onClick={addProductModal}>
                      Legg til produkter
                    </Button>
                  </FlexKid>
                )}
              </Flex>
            </Block>
            <InlineEditHead>
              <InlineEditColumn width="350px">Produkt</InlineEditColumn>
              <InlineEditColumn width="150px">Pris</InlineEditColumn>
              <InlineEditColumn width="330px">Endre pris</InlineEditColumn>
              <InlineEditColumn width="150px">Ny pris</InlineEditColumn>
            </InlineEditHead>
            <InfiniteScroll
              pageStart={0}
              loadMore={() =>
                searcher.search({
                  ...searchOpts,
                  paginate: true
                })
              }
              hasMore={searcher.hasMore}
            >
              {searcher.hasSearched && searcher.results.length > 0 ? (
                searcher.results.map((p) => (
                  <ProductCatalogRow
                    key={p._id}
                    catalog={form.data}
                    product={p}
                    inherited={inherited}
                    onDelete={(product) => {
                      toggleProducts("remove", [product._id]);
                    }}
                    onChange={(product) => {
                      setUnsavedProductIds([...unsavedProductIds, product._id]);
                    }}
                    onSave={(product) => {
                      setUnsavedProductIds(
                        unsavedProductIds.filter((id) => id !== product._id)
                      );
                    }}
                  />
                ))
              ) : (
                <>
                  <Ruler />
                  <Block hugTop>
                    <Text variant="subheading">
                      Ingen produkter lagt til enda
                    </Text>
                  </Block>
                </>
              )}
            </InfiniteScroll>
          </Card>
        </BusyBoy>
      </Fader>
    </>
  );
};

interface ProductProps {
  catalog: Catalog;
  product: IProduct;
  inherited: boolean;
  onDelete: (product: IProduct) => any;
  onChange: (product: IProduct) => any;
  onSave: (product: IProduct) => any;
}

const ProductCatalogRow: React.FC<ProductProps> = ({
  catalog,
  product,
  inherited,
  onDelete,
  onChange,
  onSave
}) => {
  const site = useSite();
  const notifications = useNotifications();
  const catalogEntry = useMemo<ProductCatalogEntry | undefined>(() => {
    return (product.catalogEntries || []).find((e) => {
      if (typeof e.catalog === "string") {
        return e.catalog === catalog._id;
      } else if (e.catalog) {
        return e.catalog._id === catalog._id;
      }

      return false;
    });
  }, [catalog]);

  const form = useForm<ProductCatalogEntry>(catalogEntry || {}, {
    method: "PATCH",
    endpoint: `products/${product._id}/catalogs/${catalog._id}`,
    onSuccess: async (data) => {
      if (data) {
        onSave(product);
        notifications.spawn({
          title: "Endringer lagret",
          icon: <AiOutlineSave />
        });
      }
    }
  });

  const canEditProductPrice = useMemo<boolean>(() => {
    if (
      product.type === ProductType.VariantShell ||
      product.price.type === PriceType.ByAppointment
    ) {
      return false;
    }

    return true;
  }, [product]);

  const newPrice = useMemo<number>(() => {
    if (form.data.priceType === CatalogPriceType.ByAppointment) {
      return 0;
    }

    if (typeof form.data.price === "number") {
      if (form.data.priceType === CatalogPriceType.Fixed) {
        return product.price.value + form.data.price;
      } else if (form.data.priceType === CatalogPriceType.NewPrice) {
        return form.data.price;
      }

      return product.price.value * (1 + form.data.price / 100);
    } else {
      return product.price.value;
    }
  }, [product, form.data.price, form.data.priceType]);

  const priceIsValid = useMemo<{
    valid: boolean;
    lower: boolean;
  }>(() => {
    let valid: boolean = true;
    let lower: boolean = false;

    if (catalog.restrictPricingLowerBound) {
      if (newPrice < product.price.value) {
        valid = false;
        lower = true;
      }
    }

    if (valid && catalog.restrictPricingUpperBound) {
      valid = newPrice <= product.price.value;
    }

    return { valid, lower };
  }, [
    product.price.value,
    newPrice,
    catalog.restrictPricingLowerBound,
    catalog.restrictPricingUpperBound
  ]);

  const handleUpdate = useCallback(
    (key: string, value: any) => {
      form.setField({
        path: key,
        value
      });

      onChange(product);
    },
    [product, catalog, catalogEntry, form, onChange]
  );

  const saveButton = form.hasMadeChanges ? (
    <FlexKid spaceLeft tight>
      <Button
        circular
        smaller
        outlined
        type="submit"
        disabled={form.submitting || !priceIsValid.valid}
      >
        <AiOutlineSave />
      </Button>
    </FlexKid>
  ) : null;

  return (
    <BusyBoy busy={form.submitting}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();

          if (priceIsValid.valid) {
            form.submit();
          }
        }}
      >
        <InlineEdit
          tight
          actions={
            !inherited || site.productCatalogAllowRemoving
              ? [
                  saveButton,
                  <Button
                    type="button"
                    aria-label="Fjern"
                    circular
                    outlined
                    smaller
                    onClick={() => {
                      if (window.confirm("Er du sikker på at du vil fjerne?")) {
                        onDelete(product);
                      }
                    }}
                  >
                    <AiOutlineDelete />
                  </Button>
                ]
              : [saveButton]
          }
          headerColumns={[
            {
              width: "350px",
              node: (
                <>
                  <Text variant="body2">
                    {inherited ? (
                      product.name
                    ) : (
                      <Link to={`/butikk/produkter/${product._id}`}>
                        {product.name}
                      </Link>
                    )}
                  </Text>
                  <Text variant="body3">{product.sku || "Mangler SKU"}</Text>
                </>
              )
            },
            {
              width: "150px",
              node: canEditProductPrice
                ? currencyFormat.format(product.price.value)
                : "N/A"
            },
            {
              width: "330px",
              node: canEditProductPrice ? (
                <Flex>
                  <FlexKid width="115px">
                    {createInputField({
                      key: "priceType",
                      type: "select",
                      required: true,
                      hugTop: true,
                      value: form.data.priceType || "",
                      options: Object.keys(catalogPriceType).map((k) => ({
                        value: k,
                        label: catalogPriceType[k]
                      })),
                      onChange: (value) => {
                        handleUpdate("priceType", value);
                      }
                    })}
                  </FlexKid>
                  {form.data.priceType !== CatalogPriceType.ByAppointment && (
                    <FlexKid spaceLeft tight width="130px">
                      {createInputField({
                        key: "price",
                        type: "number",
                        hugTop: true,
                        value: form.data.price || "",
                        onChange: (value) => {
                          handleUpdate("price", parseFloat(value));
                        }
                      })}
                    </FlexKid>
                  )}
                </Flex>
              ) : (
                "N/A"
              )
            },
            {
              width: "150px",
              node: canEditProductPrice ? (
                form.data.priceType === CatalogPriceType.ByAppointment ? (
                  "Etter avtale"
                ) : !priceIsValid.valid ? (
                  <>
                    <Status variant="bad" wider>
                      Pris for {priceIsValid.lower ? "lav" : "høy"}
                    </Status>
                  </>
                ) : (
                  currencyFormat.format(newPrice || 0)
                )
              ) : (
                "N/A"
              )
            }
          ]}
        >
          {(inherited && site.bookingEnabled) || product.bundle ? (
            <Block>
              {site.bookingEnabled &&
                createInputField({
                  key: "bookingService",
                  label: "Bookingtjeneste",
                  type: "ref",
                  ref: {
                    url: ["booking", "services"],
                    searchKey: "name",
                    titleKey: "name",
                    subtitleKey: "description"
                  },
                  hugTop: true,
                  value:
                    form.data.bookingService &&
                    typeof form.data.bookingService !== "string"
                      ? form.data.bookingService.name
                      : "",
                  onChange: (value) => {
                    handleUpdate("bookingService", value);
                  }
                })}
              {form.data.bookingService &&
                createInputField({
                  key: "bookingDurationAdjustment",
                  label: "Juster booking (minutter x antall kjøpt)",
                  type: "number",
                  value: form.data.bookingDurationAdjustment,
                  onChange: (value) => {
                    handleUpdate("bookingDurationAdjustment", value);
                  }
                })}
              {product.bundle && (
                <>
                  <Text variant="body2" gutterBottom>
                    Pakkeinnhold
                  </Text>
                  <Card outlined>
                    <Table>
                      <thead>
                        <Tr>
                          <Th tight>Produkt</Th>
                          <Th tight>Antall</Th>
                        </Tr>
                      </thead>
                      <tbody>
                        {(product.bundleContent || []).map((p) => {
                          if (!p.product || typeof p.product === "string") {
                            return null;
                          }

                          const publishedBundleContent =
                            form.data.bundleContent || [];

                          const existingValueIndex =
                            publishedBundleContent.findIndex((c) => {
                              if (typeof p.product === "string") {
                                return c.product === p.product;
                              }

                              return c.product === p.product._id;
                            });

                          const existingValue =
                            existingValueIndex !== -1
                              ? publishedBundleContent[existingValueIndex]
                              : undefined;

                          return (
                            <Tr key={p._id}>
                              <Td tight verticalAlign="middle">
                                {p.product.name}
                              </Td>
                              <Td tight verticalAlign="middle">
                                {createInputField({
                                  key: "amount",
                                  type: "number",
                                  hugTop: true,
                                  value: existingValue?.amount,
                                  onChange: (value) => {
                                    if (existingValueIndex === -1) {
                                      handleUpdate("bundleContent", [
                                        ...publishedBundleContent,
                                        {
                                          product:
                                            typeof p.product === "string"
                                              ? p.product
                                              : p.product._id,
                                          amount: value
                                        }
                                      ]);
                                    } else {
                                      handleUpdate(
                                        "bundleContent",
                                        publishedBundleContent.map((c, i) => {
                                          if (i === existingValueIndex) {
                                            return {
                                              ...c,
                                              amount: value
                                            };
                                          }

                                          return c;
                                        })
                                      );
                                    }
                                  }
                                })}
                              </Td>
                            </Tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Card>
                </>
              )}
            </Block>
          ) : null}
        </InlineEdit>
      </Form>
    </BusyBoy>
  );
};

export default Products;
