import React, { useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import {
  STOREFRONT_ENDPOINT,
  ThemeComponent
} from "@ludens-reklame/rubics-sdk";
import { Wrapper, Sidebar, Main } from "../../style-guide/Layout/Layout";
import Block from "../../style-guide/Block/Block";
import Preview from "../../components/Preview/Preview";
import Text from "../../style-guide/Text/Text";
import Section from "../../style-guide/Section/Section";
import usePreviewToken from "../../hooks/usePreviewToken";
import useApiUrl from "../../hooks/useApiUrl";
import useTheme from "../../hooks/useTheme";
import { Component } from "../../types/apiResponses";
import Frame from "../../util/Frame";
import Fader from "../../style-guide/Fader/Fader";
import Putter from "../../components/Putter/Putter";
import ActionBar from "../../components/ActionBar/ActionBar";
import usePublisher from "../../hooks/usePublisher";
import { ButtonLink } from "../../style-guide/Button/Button";
import useThemeSwitcher from "../../hooks/useThemeSwitcher";
import useRevisionId from "../../hooks/useRevisionId";
import { RevisionType } from "../../constants/api";
import getBaseApiUrl from "../../util/getBaseApiUrl";

interface Props
  extends RouteComponentProps<{
    component: string;
  }> {}

const ComponentDesigner: React.FC<Props> = ({ match }) => {
  const revisionId = useRevisionId();
  const previewToken = usePreviewToken();
  const theme = useTheme();
  const url = useApiUrl(["components", match.params.component]);

  const publisher = usePublisher<Component>({
    id: match.params.component,
    baseUrl: ["components"],
    baseDashboardUrl: "/designer/komponenter",
    publishEndpoint: "publish",
    discardEndpoint: "discard",
    onDeleteFallbackUrl: "/innhold/komponenter",
    onSubmit: () => {
      const iframe = new Frame("preview");
      iframe.reload();
    },
    onPublishDiscard: () => {
      const iframe = new Frame("preview");
      iframe.reload();
    }
  });

  useThemeSwitcher("light");

  const previewUrl = useMemo<string>(
    () =>
      getBaseApiUrl() +
      "/" +
      STOREFRONT_ENDPOINT +
      "/" +
      url +
      "/render?preview=true&token=" +
      previewToken +
      (revisionId ? `&revision=${revisionId}` : ""),
    [url, previewToken, revisionId]
  );

  const component = useMemo<ThemeComponent | null>(() => {
    if (!theme || !publisher.form.data.component) {
      return null;
    }

    const component = theme.components.find(
      (c) => c.name === publisher.form.data.component
    );

    return component || null;
  }, [publisher.form.data.component, theme]);

  const componentHasProps = useMemo<boolean>(() => {
    if (!component) {
      return false;
    }

    return Array.isArray(component.props) && component.props.length > 0;
  }, [component]);

  if (!component) {
    return null;
  }

  return (
    <Wrapper widerSidebar>
      <Sidebar wider>
        <Block>
          <Fader variant="left">
            <Section hugTop>
              <Text element="h1" variant="display3">
                {publisher.form.data.name}
              </Text>
            </Section>
          </Fader>
          <Fader variant="left">
            <Section>
              <ButtonLink
                to={`/innhold/komponenter/${match.params.component}`}
                outlined
                alternate
              >
                <AiOutlineClose /> Lukk designer
              </ButtonLink>
            </Section>
            <Section>
              {componentHasProps && (
                <Putter
                  method="PATCH"
                  pathPrefix="props"
                  form={publisher.form}
                  previewPathPrefix="propsPopulated"
                  props={component.props!}
                  refreshHash={publisher.publishDiscardHash}
                  onSubmit={() => {
                    const iframe = new Frame("preview");
                    iframe.reload();
                  }}
                />
              )}
            </Section>
          </Fader>
        </Block>
      </Sidebar>
      <Main noMaxWidth fullColumn>
        <Section>
          {previewUrl && (
            <Fader>
              <Preview id="preview" src={previewUrl} />
            </Fader>
          )}
        </Section>
        <ActionBar
          publisher={publisher}
          discard
          publish
          destroy
          revisions
          revisionReference={publisher.form.data.draftFor}
          revisionType={RevisionType.Component}
        />
      </Main>
    </Wrapper>
  );
};

export default ComponentDesigner;
