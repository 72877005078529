import React, { useContext, ReactNode, useCallback } from "react";
import { AiOutlineLike } from "react-icons/ai";
import { NotificationContext, NotificationType } from "../context/Notification";
import Text from "../style-guide/Text/Text";

export interface SpawnNotificationOpts {
  title?: ReactNode;
  subtitle?: ReactNode;
  errorMessage?: ReactNode;
  content?: ReactNode;
  icon?: ReactNode;
  type?: NotificationType;
}

export interface UseNotificationsInterface {
  spawn: (opts: SpawnNotificationOpts) => void;
  despawn: (id: string) => void;
}

function useNotifications(): UseNotificationsInterface {
  const context = useContext(NotificationContext);

  const spawn = useCallback(
    (opts: SpawnNotificationOpts) => {
      let content: ReactNode;
      let icon: ReactNode;

      if (opts.content) {
        content = opts.content;
      } else {
        content = (
          <>
            {opts.title && <Text variant="body2">{opts.title}</Text>}
            {opts.subtitle && <Text variant="body3">{opts.subtitle}</Text>}
            {opts.errorMessage && (
              <>
                <Text variant="body2" gutterTop>
                  Feilmelding: <em>{opts.errorMessage}</em>
                </Text>
              </>
            )}
          </>
        );
      }

      if (opts.icon) {
        icon = opts.icon;
      } else {
        icon = <AiOutlineLike />;
      }

      context.spawn({ ...opts, content, icon });
    },
    [context]
  );

  return {
    spawn,
    despawn: context.despawn
  };
}

export default useNotifications;
