import React, { useMemo, useEffect } from "react";
import { RouteComponentProps, Switch, Route, Link } from "react-router-dom";
import { PriceType } from "@ludens-reklame/rubics-sdk";
import { FaBoxes, FaShippingFast } from "react-icons/fa";
import { MdPalette } from "react-icons/md";
import { CgListTree } from "react-icons/cg";
import {
  AiOutlineProfile,
  AiOutlineWarning,
  AiOutlineCalculator,
  AiOutlinePicture,
  AiOutlineEye,
  AiOutlineBook
} from "react-icons/ai";
import { Product as IProduct, Template } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import Form from "../../style-guide/Inputs/Form";
import Fader from "../../style-guide/Fader/Fader";
import Block from "../../style-guide/Block/Block";
import usePublisher from "../../hooks/usePublisher";
import PublisherHelpers from "../../util/PublisherHelpers";
import {
  InlineActions,
  InlineActionLink
} from "../../style-guide/InlineActions/InlineActions";
import Section from "../../style-guide/Section/Section";
import ActionBar from "../../components/ActionBar/ActionBar";
import useSite from "../../hooks/useSite";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import Crumb from "../../components/Crumb/Crumb";
import General from "./SubViews/General";
import SEO from "./SubViews/SEO";
import Images from "./SubViews/Images";
import Attributes from "./SubViews/Attributes";
import Shipping from "./SubViews/Shipping";
import Variants from "./SubViews/Variants";
import CustomizeOptions from "../ProductTemplates/SubViews/CustomizeOptions";
import { EntityStatus, RevisionType, StockStatus } from "../../constants/api";
import useCanonicalUrl from "../../hooks/useCanonicalUrl";
import { useSearch } from "../../hooks/useApi";
import useApiUrl from "../../hooks/useApiUrl";
import cleanPopulatedIds from "../../util/cleanPopulatedIds";
import ExpandableList from "../../components/ExpandableList/ExpandableList";
import Catalogs from "./SubViews/Catalogs";
import CrossSell from "./SubViews/CrossSell";

interface Props
  extends RouteComponentProps<{
    product: string;
  }> {}

const Product: React.FC<Props> = ({ match }) => {
  const productId = match.params.product;
  const site = useSite();
  const breadcrumbs = useBreadcrumbs();
  const templatesUrl = useApiUrl(["templates"]);

  const templatesSearch = useSearch<Template>({
    endpoint: templatesUrl,
    fetchOnMount: true,
    queryParams: {
      draft: false,
      partial: false,
      fetchInherited: true,
      removeOverrides: true
    },
    limit: 100
  });

  const publisher = usePublisher<IProduct>({
    id: productId,
    baseUrl: ["products"],
    baseDashboardUrl: "/butikk/produkter",
    publishEndpoint: "publish",
    discardEndpoint: "discard",
    pathsToCopy: [
      {
        path: "name",
        label: "Navn"
      },
      {
        path: "sku",
        label: "SKU"
      },
      {
        path: "type",
        label: "Produkttype"
      },
      {
        path: "bundle",
        label: "Er pakke?"
      },
      {
        path: "bundleContent",
        label: "Pakkeinnhold"
      },
      {
        path: "description",
        label: "Beskrivelse"
      },
      {
        path: "extendedDescription",
        label: "Utvidet beskrivelse"
      },
      {
        path: "externalDescription",
        label: "Ekstern beskrivelse"
      },
      {
        path: "tags",
        label: "Emneknagger"
      },
      {
        path: "timeControlledAvailability",
        label: "Tidsstyrt tilgjengelighet"
      },
      {
        path: "availableFrom",
        label: "Tilgjengelig fra"
      },
      {
        path: "availableTo",
        label: "Tilgjengelig til"
      },
      {
        path: "hideInStore",
        label: "Skjul i nettbutikk"
      },
      {
        path: "notForIndependentSale",
        label: "Kan ikke kjøpes selvstendig"
      },
      {
        path: "categories",
        label: "Kategorier"
      },
      {
        path: "primaryCategory",
        label: "Hovedkategori"
      },
      {
        path: "price",
        label: "Kostnad"
      },
      {
        path: "subscribable",
        label: "Abonnerbar"
      },
      {
        path: "subscriptionIntervalDays",
        label: "Abonnementsintervall"
      },
      {
        path: "taxClass",
        label: "Avgiftsklasse"
      },
      {
        path: "paymentPlan",
        label: "Betalingsplan"
      },
      {
        path: "bookingService",
        label: "Bookingtjeneste"
      },
      {
        path: "bookingDurationAdjustment",
        label: "Juster booking (minutter)"
      },
      {
        path: "manageStock",
        label: "Styr lager for produkt?"
      },
      {
        path: "stockStatus",
        label: "Lagerstatus"
      },
      {
        path: "stockCount",
        label: "Lagerbeholdning"
      },
      {
        path: "backorders",
        label: "Tillat restordre?"
      },
      {
        path: "template",
        label: "Produktmal"
      },
      {
        path: "content",
        label: "Innholdsmal"
      },
      {
        path: "pageTemplate",
        label: "Sidemal"
      },
      {
        path: "customWeight",
        label: "Søkevekting"
      },
      {
        path: "images",
        label: "Bilder"
      },
      {
        path: "attributes",
        label: "Attributter"
      },
      {
        path: "simpleVariants",
        label: "Enkle varianter"
      },
      {
        path: "customizeOptions",
        label: "Brukertilpasning"
      },
      {
        path: "crossSell",
        label: "Kryss-salg"
      },
      {
        path: "virtual",
        label: "Virtuelt"
      },
      {
        path: "shipping",
        label: "Frakt"
      },
      {
        path: "seo",
        label: "Synlighet"
      },
      {
        path: "props",
        label: "Tilpasset innhold"
      },
      {
        path: "labels",
        label: "Etiketter"
      },
      ...(site.b2bEnabled || site.departmentsEnabled
        ? [
            {
              path: "catalogEntries",
              label: "Katalogtilhørlighet"
            },
            {
              path: "inStandardCatalog",
              label: "Tilgjengelig i standardkatalog"
            }
          ]
        : [])
    ],
    initialData: {
      status: EntityStatus.Inactive,
      price: {
        type: PriceType.Default
      },
      categories: [],
      attributes: [],
      labels: [],
      stockStatus: StockStatus.InStock,
      inStandardCatalog: true
    },
    bypassIgnoreKeys: [
      "images",
      "categories",
      "bookingService",
      "template",
      "content"
    ],
    cleanField: cleanPopulatedIds(["bookingService", "template", "content"]),
    onPaste: (data) => {
      return {
        ...data,
        images: Array.isArray(data.images)
          ? data.images.map((i) => {
              return {
                ...i,
                ref: !i.ref || typeof i.ref === "string" ? i.ref : i.ref._id
              };
            })
          : undefined
      };
    }
  });

  const templateId = useMemo(
    () => site.templateDistribution.product.ref,
    [site.templateDistribution.product.ref]
  );

  const templateDraftId = useMemo<string | undefined>(() => {
    return templatesSearch.results.find((t) => t._id === templateId)?.draftRef;
  }, [templateId, templatesSearch.results]);

  useEffect(() => {
    breadcrumbs.swapEntry(productId, publisher.form.data.name);
  }, [breadcrumbs, publisher.form.data, productId]);

  const canonical = useCanonicalUrl(["produkt", publisher.form.data.slug]);

  return (
    <Form onSubmit={publisher.form.submit}>
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/butikk/produkter">Produkter</Crumb>
            {PublisherHelpers.getResourceTitle(
              publisher,
              "name",
              "Nytt produkt"
            )}
          </Text>
        </Block>
        <Block hugTop hugBottom>
          <InlineActions>
            <InlineActionLink
              exact
              to={PublisherHelpers.createDashboardUrl(publisher)}
            >
              <AiOutlineProfile /> Generelt
            </InlineActionLink>
            {!publisher.isNew && (
              <>
                <InlineActionLink
                  exact
                  to={PublisherHelpers.createDashboardUrl(publisher, [
                    "bilder"
                  ])}
                >
                  <AiOutlinePicture /> Bilder
                </InlineActionLink>
                <InlineActionLink
                  exact
                  to={PublisherHelpers.createDashboardUrl(publisher, [
                    "attributter"
                  ])}
                >
                  <AiOutlineCalculator /> Attributter
                </InlineActionLink>
                <InlineActionLink
                  exact
                  to={PublisherHelpers.createDashboardUrl(publisher, [
                    "varianter"
                  ])}
                >
                  <FaBoxes /> Varianter
                </InlineActionLink>
                <InlineActionLink
                  exact
                  to={PublisherHelpers.createDashboardUrl(publisher, [
                    "synlighet"
                  ])}
                >
                  <AiOutlineEye /> Synlighet
                </InlineActionLink>
                <InlineActionLink
                  exact
                  hideUntilActive
                  to={PublisherHelpers.createDashboardUrl(publisher, [
                    "brukertilpasning"
                  ])}
                >
                  <MdPalette /> Brukertilpasning
                </InlineActionLink>
                <InlineActionLink
                  exact
                  hideUntilActive
                  to={PublisherHelpers.createDashboardUrl(publisher, ["frakt"])}
                >
                  <FaShippingFast /> Frakt
                </InlineActionLink>
                <InlineActionLink
                  exact
                  hideUntilActive
                  to={PublisherHelpers.createDashboardUrl(publisher, [
                    "kryss-salg"
                  ])}
                >
                  <CgListTree /> Kryss-salg
                </InlineActionLink>
                <InlineActionLink
                  exact
                  hideUntilActive
                  to={PublisherHelpers.createDashboardUrl(publisher, [
                    "kataloger"
                  ])}
                >
                  <AiOutlineBook /> Kataloger
                </InlineActionLink>
                <ExpandableList
                  items={[
                    {
                      linkComponent: (
                        <Link
                          to={PublisherHelpers.createDashboardUrl(publisher, [
                            "brukertilpasning"
                          ])}
                        >
                          Brukertilpasning
                        </Link>
                      )
                    },
                    {
                      linkComponent: (
                        <Link
                          to={PublisherHelpers.createDashboardUrl(publisher, [
                            "frakt"
                          ])}
                        >
                          Frakt
                        </Link>
                      )
                    },
                    {
                      linkComponent: (
                        <Link
                          to={PublisherHelpers.createDashboardUrl(publisher, [
                            "kryss-salg"
                          ])}
                        >
                          Kryss-salg
                        </Link>
                      )
                    },
                    {
                      linkComponent: (
                        <Link
                          to={PublisherHelpers.createDashboardUrl(publisher, [
                            "kataloger"
                          ])}
                        >
                          Kataloger
                        </Link>
                      ),
                      hidden: !site.b2bEnabled && !site.departmentsEnabled
                    },
                    {
                      linkComponent: (
                        <Link
                          to={`/innstillinger/maler/liste/${templateDraftId}`}
                        >
                          Gå til mal
                        </Link>
                      ),
                      hidden: !templateDraftId
                    },
                    {
                      linkComponent: (
                        <a
                          href={canonical}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Vis produkt på nettsiden
                        </a>
                      ),
                      hidden: publisher.isNew || !templateId
                    }
                  ]}
                >
                  Mer
                </ExpandableList>
              </>
            )}
            {!templateId && !publisher.form.loadingPrefill && (
              <InlineActionLink
                highlight
                warning
                exact
                to="/innstillinger/maler/bruk"
              >
                <AiOutlineWarning /> Mangler mal
              </InlineActionLink>
            )}
          </InlineActions>
        </Block>
        <Section hugTop>
          <Switch>
            <Route
              exact
              path={publisher.dashboardUrl}
              render={() => (
                <General
                  productId={productId}
                  publisher={publisher}
                  templates={templatesSearch.results}
                  hasTemplate={!!templateId}
                />
              )}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/bilder`}
              render={() => <Images publisher={publisher} />}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/attributter`}
              render={() => (
                <Attributes
                  form={publisher.form}
                  submit={!publisher.isNew}
                  readOnly={publisher.isRevision}
                />
              )}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/varianter`}
              render={() => <Variants publisher={publisher} />}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/brukertilpasning`}
              render={() => (
                <CustomizeOptions
                  form={publisher.form}
                  readOnly={publisher.isRevision}
                  submitOnSet
                />
              )}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/frakt`}
              render={() => <Shipping publisher={publisher} site={site} />}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/synlighet`}
              render={() => <SEO publisher={publisher} />}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/kataloger`}
              render={() => <Catalogs publisher={publisher} />}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/kryss-salg`}
              render={() => (
                <CrossSell
                  key={publisher.publishDiscardHash}
                  publisher={publisher}
                />
              )}
            />
          </Switch>
        </Section>
      </Fader>
      <ActionBar
        publisher={publisher}
        publish
        discard
        destroy
        revisions
        revisionReference={publisher.form.data.draftFor}
        revisionType={RevisionType.Product}
        showEntityStatus
      />
    </Form>
  );
};

export default Product;
