import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { AiOutlineBook } from "react-icons/ai";
import { Catalog, Site } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import { ButtonLink } from "../../style-guide/Button/Button";
import Tabler from "../../components/Tabler/Tabler";
import { CatalogType } from "../../constants/api";
import useSite from "../../hooks/useSite";

interface Props {
  type: CatalogType;
}

const Catalogs: React.FC<Props> = ({ type }) => {
  const site = useSite();
  const meta = useMemo(() => {
    switch (type) {
      case CatalogType.Content:
        return {
          title: "Innholdskataloger",
          singular: "innholdskatalog",
          plural: "innholdskataloger",
          section: "innhold",
          sectionTitle: "Innhold",
          helpText:
            "Innholdskataloger lar deg lage unikt innhold for bedrifter eller avdelinger."
        };

      default:
        return {
          title: "Produktkataloger",
          singular: "produktkatalog",
          plural: "produktkataloger",
          section: "butikk",
          sectionTitle: "Butikk",
          helpText:
            "Produktkataloger lar deg endre produktutvalget og priser for ulike bedrifter eller avdelinger."
        };
    }
  }, [type]);

  return (
    <Tabler
      title={meta.title}
      queryField="name"
      queryLabel={`Søk etter ${meta.plural}`}
      queryParams={{ type }}
      tight
      crumb={{
        label: meta.sectionTitle,
        path: `/${meta.section}`
      }}
      actions={
        site.b2bEnabled || site.departmentsEnabled ? (
          <ButtonLink to={`${meta.plural}/opprett`}>
            Opprett {meta.singular}
          </ButtonLink>
        ) : undefined
      }
      emptyState={{
        title: `Ingen ${meta.plural} enda`,
        icon: <AiOutlineBook />,
        text: meta.helpText,
        action:
          site.b2bEnabled || site.departmentsEnabled ? (
            <ButtonLink to={`${meta.plural}/opprett`}>
              Opprett {meta.singular}
            </ButtonLink>
          ) : undefined
      }}
      url={["catalogs"]}
      columns={[
        {
          Header: "Katalog",
          accessor: "title"
        }
      ]}
      createRow={(catalog: Catalog) => ({
        ...catalog,
        title: (
          <TitleRow
            site={site}
            catalog={catalog}
            type={type}
            section={meta.section}
          />
        )
      })}
    />
  );
};

interface RowProps {
  site: Site;
  catalog: Catalog;
  type: CatalogType;
  section: string;
}

const TitleRow: React.FC<RowProps> = ({ site, catalog, type, section }) => {
  const inherited = catalog.site !== site._id;

  return (
    <>
      <Text variant="body2">
        <Link
          to={`/${section}/${
            type === CatalogType.Product
              ? "produktkataloger"
              : "innholdskataloger"
          }/${catalog._id}${inherited ? "/produkter" : ""}`}
        >
          {catalog.name}
        </Link>
      </Text>
      <Text variant="body3">
        {catalog.description || "Mangler beskrivelse"}
      </Text>
    </>
  );
};

export default Catalogs;
