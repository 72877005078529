import * as qs from "qs";
import Cookies from "js-cookie";
import {
  ADMIN_ENDPOINT,
  STOREFRONT_ENDPOINT
} from "@ludens-reklame/rubics-sdk";
import logout from "./logout";
import { RUBICS_ERROR_EVENT_NAME } from "../helpers/NotificationEventHandler";
import getBaseApiUrl from "./getBaseApiUrl";

export interface Headers {
  [key: string]: any;
}

export interface RequestData {
  [key: string]: any;
}

export enum Service {
  Admin = "admin",
  Storefront = "storefront",
  Auth = "auth",
  SSO = "soo"
}

export interface ApiOpts {
  endpoint: string;
  method?: string;
  queryParams?: RequestData;
  body?: RequestData;
  service?: Service;
  noJSON?: boolean;
  multipart?: boolean;
  urlEncoded?: boolean;
  noCredentials?: boolean;
  headers?: { [key: string]: any };
}

async function api<T>(opts: ApiOpts): Promise<T | Response> {
  try {
    const method = opts.method || "GET";

    const queryParams = opts.queryParams
      ? `?${qs.stringify(opts.queryParams)}`
      : "";

    const url =
      getServiceURL(opts.service || Service.Admin) +
      "/" +
      opts.endpoint +
      queryParams;

    const headers: Headers = {
      ...(opts.headers || {}),
      "x-cache-bypass": "true",
      "x-csrf-token": Cookies.get("cid") || ""
    };

    let body: string | FormData | undefined = undefined;

    if (method !== "GET") {
      if (!opts.multipart) {
        headers["Content-Type"] = "application/json";
      }

      if (opts.urlEncoded) {
        headers["Content-Type"] = "application/x-www-form-urlencoded";
      }

      if (opts.body) {
        body = opts.multipart
          ? createForm(opts.body)
          : opts.urlEncoded
          ? createEncodedForm(opts.body)
          : JSON.stringify(opts.body);
      }
    }

    const response = await fetch(url, {
      method,
      headers,
      mode: "cors",
      credentials: opts.noCredentials ? "omit" : "include",
      body
    });

    if (response.status === 401 || response.status === 403) {
      await logout();
      return response;
    } else if (response.status === 404) {
      throw new Error("resourceNotFound");
    } else if (response.status === 500) {
      const json = await response.json();

      if (json && json.message === "Site not found") {
        logout();
      } else if (json && typeof json.message === "string") {
        throw new Error(json.message);
      } else {
        throw new Error("internalServerError");
      }
    } else if (response.status === 400) {
      const json = await response.json();

      if (json && typeof json.message === "string") {
        throw new Error(json.message);
      } else {
        throw new Error("badRequest");
      }
    }

    if (opts.noJSON) {
      return response;
    }

    return (await response.json()) as T;
  } catch (error) {
    const event = new CustomEvent<any>(RUBICS_ERROR_EVENT_NAME, {
      detail: error
    });

    document.dispatchEvent(event);

    throw error;
  }
}

function getServiceURL(service: Service): string {
  const rubicsPath = getBaseApiUrl();

  switch (service) {
    case Service.SSO:
      return process.env.REACT_APP_SSO_URL || "";
    case Service.Storefront:
      return `${rubicsPath}/${STOREFRONT_ENDPOINT}`;
    case Service.Auth:
      return `${rubicsPath}/auth`;
    default:
      return `${rubicsPath}/${ADMIN_ENDPOINT}`;
  }
}

function createForm(data: RequestData): FormData {
  const formData = new FormData();

  for (const k in data) {
    if (Array.isArray(data[k]) && typeof data[k] !== "string") {
      data[k].map((file: File) => formData.append(k, file));
    } else {
      formData.append(k, data[k]);
    }
  }

  return formData;
}

function createEncodedForm(body: RequestData): string {
  return Object.keys(body)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`)
    .join("&");
}

export default api;
