import React, { useEffect } from "react";
import { RouteComponentProps, Prompt, Switch, Route } from "react-router-dom";
import { AiOutlineProfile, AiOutlineCode } from "react-icons/ai";
import { Discount as IDiscount } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import Form from "../../style-guide/Inputs/Form";
import Fader from "../../style-guide/Fader/Fader";
import Block from "../../style-guide/Block/Block";
import usePublisher from "../../hooks/usePublisher";
import PublisherHelpers from "../../util/PublisherHelpers";
import {
  InlineActions,
  InlineActionLink
} from "../../style-guide/InlineActions/InlineActions";
import Section from "../../style-guide/Section/Section";
import ActionBar from "../../components/ActionBar/ActionBar";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import Crumb from "../../components/Crumb/Crumb";
import { LEAVE_PAGE_MESSAGE } from "../../constants/general";
import {
  DiscountType,
  DiscountOfferType,
  DiscountAppliesToType,
  RevisionType
} from "../../constants/api";
import General from "./SubViews/General";
import AppliesTo from "./SubViews/AppliesTo";
import Codes from "./SubViews/Codes";

interface Props
  extends RouteComponentProps<{
    discount: string;
  }> {}

const Discount: React.FC<Props> = ({ match }) => {
  const discountId = match.params.discount;

  const breadcrumbs = useBreadcrumbs();

  const publisher = usePublisher<IDiscount>({
    id: discountId,
    baseUrl: ["discounts"],
    baseDashboardUrl: "/butikk/tilbud",
    initialData: {
      name: "",
      __type: DiscountType.Automatic,
      active: false,
      offerType: DiscountOfferType.Percentage,
      appliesTo: DiscountAppliesToType.All,
      appliesToValues: [],
      populatedAppliesToValues: [],
      codes: [],
      cartRules: {
        limit: null,
        limitSelection: null
      }
    },
    pathsToCopy: [
      {
        path: "name",
        label: "Navn"
      },
      {
        path: "__type",
        label: "Type"
      },
      {
        path: "startDate",
        label: "Starttidspunkt"
      },
      {
        path: "endDate",
        label: "Sluttidspunkt"
      },
      {
        path: "offerType",
        label: "Avslagsutregning"
      },
      {
        path: "offerValue",
        label: "Avslagsverdi"
      }
    ]
  });

  useEffect(() => {
    breadcrumbs.swapEntry(discountId, publisher.form.data.name);
  }, [breadcrumbs, publisher.form.data, discountId]);

  return (
    <Form onSubmit={publisher.form.submit}>
      <Prompt
        when={publisher.form.hasMadeChanges}
        message={(location) => {
          if (location.pathname.includes(discountId)) {
            return true;
          }

          return LEAVE_PAGE_MESSAGE;
        }}
      />
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/butikk/tilbud">Tilbud</Crumb>
            {PublisherHelpers.getResourceTitle(
              publisher,
              "name",
              "Nytt tilbud"
            )}
          </Text>
        </Block>
        <Block hugTop hugBottom>
          <InlineActions>
            <InlineActionLink exact to={publisher.dashboardUrl}>
              <AiOutlineProfile /> Generelt
            </InlineActionLink>
            <InlineActionLink
              exact
              to={`${publisher.dashboardUrl}/gjelder-for`}
            >
              <AiOutlineProfile /> Hva gjelder tilbudet for?
            </InlineActionLink>
            {publisher.form.data.__type === DiscountType.Code && (
              <InlineActionLink exact to={`${publisher.dashboardUrl}/koder`}>
                <AiOutlineCode /> Koder
              </InlineActionLink>
            )}
          </InlineActions>
        </Block>
        <Section hugTop>
          <Switch>
            <Route
              exact
              path={publisher.dashboardUrl}
              render={() => <General publisher={publisher} />}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/gjelder-for`}
              render={() => <AppliesTo publisher={publisher} />}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/koder`}
              render={() => <Codes publisher={publisher} />}
            />
          </Switch>
        </Section>
      </Fader>
      <ActionBar
        publisher={publisher}
        revisions
        revisionReference={publisher.form.data._id}
        revisionType={RevisionType.Discount}
      />
    </Form>
  );
};

export default Discount;
