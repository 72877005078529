function stripObjectProps(obj: any, keys: string[], bypass?: string[]) {
  if (obj instanceof Array) {
    obj.forEach(function (item) {
      stripObjectProps(item, keys);
    });
  } else if (obj && typeof obj === "object") {
    Object.getOwnPropertyNames(obj).forEach(function (key) {
      if (!Array.isArray(bypass) || !bypass.includes(key)) {
        if (keys.indexOf(key) !== -1) {
          delete obj[key];
        } else {
          stripObjectProps(obj[key], keys);
        }
      }
    });
  }
}

export default stripObjectProps;
