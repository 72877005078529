import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { FaAngleDown } from "react-icons/fa";
import { baseInputStyles } from "./TextInput";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Icon = styled.span`
  position: absolute;
  right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.textPrimary};
  border: 1px solid ${(props) => props.theme.colors.border};
  height: 30px;
  width: 30px;
  border-radius: 50%;
  pointer-events: none;
`;

const SelectElement = styled.select`
  height: 42px;
  appearance: none;
  ${baseInputStyles}
`;

interface Props extends InputHTMLAttributes<HTMLSelectElement> {}

const Select: React.FC<Props> = (props) => {
  return (
    <Wrapper>
      {!props.disabled && (
        <Icon>
          <FaAngleDown />
        </Icon>
      )}
      <SelectElement {...props} />
    </Wrapper>
  );
};

export default Select;
