import React, { ReactNode, useCallback, useRef } from "react";
import moment from "moment";
import {
  AiOutlineDelete,
  AiOutlineHistory,
  AiOutlineDiff,
  AiOutlineArrowUp,
  AiOutlineSave
} from "react-icons/ai";
import { MdPublish, MdUndo } from "react-icons/md";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { UsePublisherInterface } from "../../hooks/usePublisher";
import StickyBar, {
  StickyBarText
} from "../../style-guide/StickyBar/StickyBar";
import Loading from "../../style-guide/Loading/Loading";
import {
  Button,
  ButtonExternalLink,
  ButtonLink,
  ButtonList
} from "../../style-guide/Button/Button";
import Text from "../../style-guide/Text/Text";
import useRevision from "../../hooks/useRevision";
import { Modal, ModalActions, ModalBody } from "../../style-guide/Modal/Modal";
import Revisions from "../Revisions/Revisions";
import { EntityStatus, RevisionType } from "../../constants/api";
import Diff from "../Diff/Diff";
import Doorman from "../Doorman/Doorman";
import { StatusBulb } from "../../style-guide/Status/Status";
import localize from "../../util/localize";
import { entityStatus } from "../../constants/localizations";
import CrudModal, { createCrudModalField } from "../CrudModal/CrudModal";

interface Props {
  publisher: UsePublisherInterface<any>;
  publish?: boolean;
  discard?: boolean;
  destroy?: boolean;
  revisions?: boolean;
  revisionType?: RevisionType;
  revisionReference?: string;
  modifiedKey?: string;
  modified?: boolean;
  experiment?: string;
  showEntityStatus?: boolean;
}

const ActionBar: React.FC<Props> = ({
  publisher,
  publish,
  discard,
  destroy,
  revisions,
  revisionType,
  revisionReference,
  modifiedKey,
  modified,
  experiment,
  showEntityStatus
}) => {
  const revision = useRevision(publisher.revision);
  const { spawnModal, despawnModal } = useModal();
  const revisionsModalBodyRef = useRef<HTMLElement | null>(null);

  const handleCreateRevisionsModal = useCallback(() => {
    spawnModal(
      <Modal>
        <ModalBody ref={(ref) => (revisionsModalBodyRef.current = ref)}>
          <Revisions
            type={revisionType || RevisionType.Page}
            reference={revisionReference || ""}
            getScrollParent={() => revisionsModalBodyRef.current}
          />
        </ModalBody>
        <ModalActions>
          <ButtonList align="right">
            <Button onClick={despawnModal}>Lukk</Button>
          </ButtonList>
        </ModalActions>
      </Modal>
    );
  }, [spawnModal, revisionReference]);

  const handleShowDiffModal = useCallback(() => {
    if (publisher.revision) {
      spawnModal(
        <Modal>
          <ModalBody>
            <Diff revisionId={publisher.revision} />
          </ModalBody>
          <ModalActions>
            <ButtonList align="right">
              <Button onClick={despawnModal}>Lukk</Button>
            </ButtonList>
          </ModalActions>
        </Modal>
      );
    }
  }, [spawnModal, publisher.revision]);

  const handleShowStatusModal = useCallback(() => {
    spawnModal(
      <CrudModal
        title="Endre status"
        initialData={publisher.form.data}
        onSubmit={(data) => {
          publisher.form.setField({
            path: "status",
            value: data.status,
            submit: true
          });
        }}
        fields={[
          {
            key: "status",
            render: (payload) =>
              createCrudModalField({
                payload,
                key: "status",
                type: "radio",
                label: "Ny status",
                options: Object.keys(entityStatus).map((k) => ({
                  value: k,
                  label: entityStatus[k]
                }))
              })
          }
        ]}
      />
    );
  }, [spawnModal, publisher.form]);

  if (publisher.form.loadingPrefill) {
    return null;
  }

  function renderActions(): ReactNode {
    if (publisher.isNew) {
      return (
        <Button type="submit" disabled={publisher.loading}>
          <AiOutlineSave />
          Lagre
        </Button>
      );
    }

    return (
      <>
        {!!experiment && (
          <ButtonList>
            <ButtonLink
              outlined
              to={`/eksperimenter/${experiment}/varianter`}
              onColor
            >
              Tilbake til eksperiment
            </ButtonLink>
          </ButtonList>
        )}
        {revisions && !experiment && (
          <Button
            type="button"
            alternate={!publisher.isRevision}
            outlined={publisher.isRevision}
            onColor={publisher.isRevision}
            onClick={handleCreateRevisionsModal}
          >
            <AiOutlineHistory />
            Versjonslogg
          </Button>
        )}
        {publisher.isRevision && (
          <>
            <Button onClick={handleShowDiffModal} outlined onColor>
              <AiOutlineDiff />
              Vis diff
            </Button>
            {revisionType !== RevisionType.Site &&
              revisionType !== RevisionType.Order && (
                <Doorman type="admin">
                  <Button onClick={publisher.rollback} outlined onColor>
                    <MdUndo />
                    Reverter til denne versjonen
                  </Button>
                </Doorman>
              )}
            <ButtonExternalLink href={publisher.dashboardUrl} outlined onColor>
              <AiOutlineArrowUp />
              Tilbake til original
            </ButtonExternalLink>
          </>
        )}
        {!publisher.isRevision && !experiment && (
          <>
            {destroy && (
              <Doorman type="admin">
                <Button
                  type="button"
                  alternate
                  disabled={publisher.loading}
                  onClick={publisher.delete}
                >
                  <AiOutlineDelete />
                  Slett
                </Button>
              </Doorman>
            )}
            {showEntityStatus && (
              <Button
                alternate
                onClick={handleShowStatusModal}
                aria-label={`Endre status fra ${localize(
                  entityStatus,
                  publisher.form.data.status
                )}`}
              >
                <StatusBulb
                  variant={
                    publisher.form.data.status === EntityStatus.Active
                      ? "good"
                      : "bad"
                  }
                />
                {localize(entityStatus, publisher.form.data.status)}
              </Button>
            )}
            {publish || discard ? (
              (modified || publisher.form.data[modifiedKey || "modified"]) && (
                <>
                  {discard && (
                    <Button
                      type="button"
                      alternate
                      disabled={publisher.loading}
                      onClick={publisher.discard}
                    >
                      <MdUndo />
                      Forkast endringer
                    </Button>
                  )}
                  {publish && (
                    <Button
                      type="button"
                      disabled={publisher.loading}
                      onClick={publisher.publish}
                    >
                      <MdPublish />
                      Publiser
                    </Button>
                  )}
                </>
              )
            ) : (
              <Button
                type="submit"
                disabled={publisher.loading || !publisher.form.hasMadeChanges}
              >
                <AiOutlineSave />
                Lagre
              </Button>
            )}
          </>
        )}
      </>
    );
  }

  return (
    <StickyBar
      variant={
        !!experiment ? "experiment" : publisher.isRevision ? "info" : "normal"
      }
      image={!!experiment ? "/scientist.png" : undefined}
      imageAlt={!!experiment ? "Gal vitenskapsmann" : undefined}
    >
      {!!experiment && (
        <StickyBarText>
          <Text variant="title">Du er nå i eksperiment-modus</Text>
        </StickyBarText>
      )}
      {publisher.isRevision && (
        <StickyBarText>
          <Text variant="title">
            {revision.loading ? "Laster…" : revision.revision?._id}
          </Text>
          <Text>
            {revision.loading ? (
              "Laster…"
            ) : (
              <>
                Av{" "}
                {revision.revision?.author?.name ||
                  revision.revision?.author?.email ||
                  "Ukjent"}{" "}
                den{" "}
                {moment(revision.revision?.timestamp).format(
                  "D[.] MMMM Y [kl.] HH:mm"
                )}
              </>
            )}
          </Text>
        </StickyBarText>
      )}
      <Loading loading={publisher.loading} />
      {renderActions()}
    </StickyBar>
  );
};

export default ActionBar;
