export interface Localization {
  [key: string]: string;
}

export const templateRoles: Localization = {
  frontPage: "Forside",
  page: "Side",
  category: "Kategori",
  product: "Produkt",
  productPage: "Produktside",
  cart: "Handlekurv",
  myPage: "Min side",
  noAccess: "Ingen tilgang"
};

export const memberPermissions: Localization = {
  internalVisitor: "Besøkende",
  reader: "Leser",
  contributor: "Redaktør",
  admin: "Administrator"
};

export const searchResultType: Localization = {
  page: "Side",
  category: "Kategori",
  product: "Produkt",
  productPage: "Produktside",
  order: "Ordre",
  app: "App",
  template: "Mal",
  component: "Komponent"
};

export const productType: Localization = {
  default: "Standard",
  variantShell: "Variasjonsprodukt"
};

export const productPriceType: Localization = {
  default: "Standard",
  byAppointment: "Etter avtale"
};

export const variantType: Localization = {
  color: "Farge",
  size: "Størrelse",
  string: "Generisk",
  image: "Bilde",
  icon: "Ikon"
};

export const attributeTemplateAppliesToType: Localization = {
  products: "Utvalgte produkter",
  categories: "Utvalgte kategorier"
};

export const customizeOptionType: Localization = {
  string: "Tekst",
  number: "Tall",
  numberMultiplier: "Prismultiplikator",
  checkbox: "Checkbox",
  radio: "Radio",
  stepper: "Trinnvis prising",
  stringStepper: "Trinnvis prising (tekstlengde)",
  color: "Farge"
};

export const orderStatus: Localization = {
  confirmed: "Bekreftet",
  pending: "Venter",
  finished: "Fullført",
  processing: "Behandler",
  canceled: "Kansellert",
  failed: "Feilet"
};

export const paymentStatus: Localization = {
  manual: "Manuell",
  pending: "Venter",
  reserved: "Reservert",
  paid: "Betalt",
  partiallyPaid: "Delvis betalt",
  refunded: "Refundert",
  partiallyRefunded: "Delvis refundert",
  voided: "Annulert"
};

export const discountType: Localization = {
  automatic: "Automatisk",
  code: "Kode"
};

export const discountOfferType: Localization = {
  percentage: "Prosent",
  fixed: "Fast",
  newPrice: "Ny pris"
};

export const discountAppliesToType: Localization = {
  all: "Alle produkter",
  products: "Utvalgte produkter",
  categories: "Utvalgte kategorier",
  allShipping: "All frakt",
  shippingClasses: "Utvalgte fraktklasser",
  shippingClassesExcluded: "Alle fraktklasser bortsett fra følgende"
};

export const discountCartLimitSelection: Localization = {
  cheapest: "Billigst",
  mostExpensive: "Dyrest",
  first: "Første lagt til",
  last: "Siste lagt til"
};

export const shippingType: Localization = {
  flatRate: "Fastpris",
  pickup: "Lokal henting",
  free: "Gratis frakt"
};

export const shippingClassCostCalculation: Localization = {
  class: "Per fraktklasse",
  order: "Per order (dyreste fraktklasse)"
};

export const operations: Localization = {
  set: "Er satt",
  eq: "Er lik",
  neq: "Er ikke lik",
  gt: "Er større enn",
  lt: "Er mindre enn"
};

export const statType: Localization = {
  pageView: "Sidevisning",
  productView: "Produktvisning",
  cartAdd: "Lagt til i handlekurven",
  checkout: "Checkout",
  orderCreated: "Ordre opprettet",
  orderLine: "Ordrelinje opprettet",
  custom: "Egendefinert"
};

export const stockStatus: Localization = {
  inStock: "På lager",
  outOfStock: "Ikke på lager",
  onBackOrder: "Tilgjengelig for restordre"
};

export const backorders: Localization = {
  no: "Nei",
  yes: "Ja",
  notify: "Varsle"
};

export const scopes: Localization = {
  "read:content": "Les innhold",
  "write:content": "Skriv innhold",
  "read:product": "Les produkter",
  "write:product": "Skriv produkter",
  "read:discount": "Les tilbud",
  "write:discount": "Skriv tilbud",
  "read:order": "Les ordre",
  "write:order": "Skriv ordre",
  "read:shipping": "Les frakt",
  "write:shipping": "Skriv frakt",
  "read:reports": "Les rapporter"
};

export const companyRole: Localization = {
  employee: "Ansatt",
  admin: "Administrator"
};

export const entityStatus: Localization = {
  inactive: "Inaktiv",
  active: "Aktiv"
};

export const entityVisibility: Localization = {
  public: "Offentlig",
  password: "Passordbeskyttet"
};

export const domainValidationStatus: Localization = {
  PENDING_VALIDATION: "Venter på verifisering",
  VALIDATION_TIMED_OUT: "Tiden gikk ut",
  ISSUED: "Verifisert"
};

export const orderPaymentStatus: Localization = {
  waiting: "Venter",
  processing: "Behandler",
  failed: "Mislyktes",
  succeeded: "Lyktes"
};

export const userCreation: Localization = {
  invite: "Invitasjon",
  emailDomain: "E-postdomene"
};

export const customerCreation: Localization = {
  public: "Åpen",
  invite: "Invitasjon"
};

export const protection: Localization = {
  visitor: "Offentlig",
  internalVisitor: "Registrert bruker",
  reader: "Registrert bruker med lesetilgang",
  admin: "Registrert bruker med admin-tilgang"
};

export const bookingServiceType: Localization = {
  appointment: "Avtale",
  event: "Begivenhet"
};

export const days: Localization = {
  Monday: "Mandag",
  Tuesday: "Tirsdag",
  Wednesday: "Onsdag",
  Thursday: "Torsdag",
  Friday: "Fredag",
  Saturday: "Lørdag",
  Sunday: "Søndag"
};

export const formFieldType: Localization = {
  string: "Kort tekst",
  text: "Lang tekst",
  number: "Tall",
  file: "Filopplastning",
  select: "Liste",
  radio: "Valg",
  checkbox: "Avhukning",
  color: "Farge",
  date: "Dato",
  ["datetime-local"]: "Dato og tid",
  time: "Tid",
  email: "E-post",
  hidden: "Skjult",
  tel: "Telefon",
  url: "URL",
  info: "Informasjon"
};

export const catalogPriceType: Localization = {
  percentage: "Prosent",
  fixed: "Fast",
  newPrice: "Ny pris",
  byAppointment: "Etter avtale"
};

export const bookingDurationUnit: Localization = {
  minute: "Minutt",
  tenMinute: "Tiende minutt",
  quarterHour: "Kvarter",
  halfHour: "Halvtime",
  hour: "Time",
  day: "Dag"
};

export const consentCategory: Localization = {
  ad: "Annonsering",
  analytics: "Analyse",
  functionality: "Funksjonalitet",
  personalization: "Personalisering",
  security: "Sikkerhet"
};
