import React, { useContext } from "react";
import { createGlobalStyle } from "styled-components";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link
} from "react-router-dom";
import { Provider } from "@ludens-reklame/rubics-theme";
import { ModalProvider } from "@ludens-reklame/rubics-react/dist/context/Modal";
import { Wrapper, Main, TopBar } from "./style-guide/Layout/Layout";
import { Flex, FlexKid } from "./style-guide/Flex/Flex";
import LoginRoutes from "./views/Login/Routes";
import { UserContext, UserProvider } from "./context/User";
import { SiteThemeProvider } from "./context/Theme";
import { SiteProvider } from "./context/Site";
import SiteSelectionRoutes from "./views/SiteSelection/Routes";
import PagesRoutes from "./views/Pages/Routes";
import DesignerRoutes from "./views/Designer/Routes";
import SiteHandler from "./helpers/SiteHandler";
import {
  ThemeSwitcherProvider,
  ThemeSwitcherContext
} from "./context/ThemeSwitcher";
import Fader from "./style-guide/Fader/Fader";
import SettingsRoutes from "./views/Settings/Routes";
import { NotificationProvider } from "./context/Notification";
import { BreadcrumbsProvider } from "./context/Breadcrumbs";
import Notifications from "./components/Notifications/Notifications";
import MainMenu from "./components/MainMenu/MainMenu";
import ProductsRoutes from "./views/Products/Routes";
import ScrollHandler from "./helpers/ScrollHandler";
import AttributesRoutes from "./views/Attributes/Routes";
import ProductTemplatesRoutes from "./views/ProductTemplates/Routes";
import AppsRoutes from "./views/Apps/Routes";
import OrdersRoutes from "./views/Orders/Routes";
import DiscountsRoutes from "./views/Discounts/Routes";
import AppRenderer from "./views/Apps/AppRenderer";
import MediaRoutes from "./views/Media/Routes";
import CustomersRoutes from "./views/Customers/Routes";
import CompaniesRoutes from "./views/Companies/Routes";
import CatalogsRoutes from "./views/Catalogs/Routes";
import QuotesRoutes from "./views/Quotes/Routes";
import HomeRoutes from "./views/Home/Routes";
import DataSetRoutes from "./views/DataSet/Routes";
import NotificationEventHandler from "./helpers/NotificationEventHandler";
import ReportsRoutes from "./views/Reports/Routes";
import ExperimentRoutes from "./views/Experiment/Routes";
import ComponentsRoutes from "./views/Components/Routes";
import BookingRoutes from "./views/Booking/Routes";
import FormsRoutes from "./views/Forms/Routes";
import Avatar from "./style-guide/Avatar/Avatar";
import useSite from "./hooks/useSite";
import SubscriptionsRoutes from "./views/Subscriptions/Routes";
import LabelRoutes from "./views/Label/Routes";

import "prismjs/themes/prism.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-calendar/dist/Calendar.css";
import { PowersearchModal } from "./components/Powersearch/PowersearchModal";
import { Powersearch } from "./components/Powersearch/Powersearch";

const GlobalStyle = createGlobalStyle`
  pre {
    margin: 0;
  }

  #draggable {
    position: absolute;
    pointer-events: none;
    height: 100%;
    width: 100%;
  }

  .rdw-editor-toolbar,
  .rdw-editor-main {
    border: 1px solid ${(props) => props.theme.colors.border}!important;
    background-color: #f7f7f7;
  }

  .rdw-editor-main {
    padding: ${(props) => props.theme.spacing.small};
    background-color: ${(props) => props.theme.colors.backgroundVariant};
    min-height: 24rem;
    height: auto;

    h1, h2, h3, h4, h5, h6, p {
      margin-top: 0;
      margin-bottom: ${(props) => props.theme.spacing.small};

      > * {
        margin-top: 0;
        margin-bottom: ${(props) => props.theme.spacing.small};
      }
    }
  }

  .rdw-option-wrapper,
  .rdw-dropdown-wrapper {
    background-color: #f7f7f7;
    color: black;

    a {
      color: black;
    }
  }

  .rdw-link-modal {
    color: black;
  }

  .jsondiffpatch-added,
  .jsondiffpatch-removed,
  .jsondiffpatch-value {
    color: ${(props) => props.theme.colors.textOnColor};
  }

  .rbc-day-bg,
  .rbc-day-slot {
    background-color: ${(props) => props.theme.colors.backgroundVariant};
  }

  .rbc-row-content {
    z-index: 2;
  }

  .rbc-event {
    background-color: ${(props) => props.theme.colors.primary};
  }

  .rbc-today {
    background-color: ${(props) => props.theme.colors.goodBackground};
  }

  .rbc-off-range-bg{
    background-color: ${(props) => props.theme.colors.background};
  }

  .rbc-toolbar button {
    border-color: ${(props) => props.theme.colors.border};
    color: ${(props) => props.theme.colors.textPrimary};
  }

  .rbc-toolbar button:active, .rbc-toolbar button.rbc-active,
  .rbc-toolbar button:hover,
  .rbc-toolbar button.rbc-active:hover,
  .rbc-toolbar button:focus,
  .rbc-toolbar button.rbc-active:focus,
  .rbc-toolbar button:active:focus {
    background-color: ${(props) => props.theme.colors.backgroundVariant};
    border-color: ${(props) => props.theme.colors.border};
    box-shadow: none;
  }

  .rbc-time-view,
  .rbc-month-view,
  .rbc-header,
  .rbc-time-header-content,
  .rbc-day-slot .rbc-event,
  .rbc-time-content,
  .rbc-timeslot-group,
  .rbc-day-slot .rbc-time-slot,
  .rbc-header + .rbc-header,
  .rbc-day-bg + .rbc-day-bg,
  .rbc-month-row + .rbc-month-row,
  .rbc-time-content > * + * > * {
    border-color: ${(props) => props.theme.colors.border};
  }
`;

const RouteHandler: React.FC = () => {
  const site = useSite();
  const user = useContext(UserContext);

  return (
    <>
      <ScrollHandler />
      {!user.data.loggedIn ? (
        <LoginRoutes />
      ) : !user.data.site ? (
        <SiteSelectionRoutes />
      ) : (
        <>
          <Route component={SiteHandler} />
          <Route path="/logg-inn" render={() => <Redirect to="/" />} />
          <Switch>
            <Route path="/designer" component={DesignerRoutes} />
            <Route path="/apper/:app" component={AppRenderer} />
            <Route
              render={() => (
                <Wrapper>
                  <MainMenu />
                  <TopBar>
                    <Fader>
                      <Flex>
                        <FlexKid flex={1}>
                          <Powersearch />
                        </FlexKid>
                        <FlexKid spaceLeft>
                          <Link
                            to="/innstillinger"
                            aria-label="Gå til nettsideinstillinger"
                          >
                            <Avatar
                              contain
                              square
                              image={
                                site.favicon && typeof site.favicon !== "string"
                                  ? site.favicon.secure_url
                                  : undefined
                              }
                              fallback={site.displayName || site.name}
                            />
                          </Link>
                        </FlexKid>
                        <FlexKid spaceLeft>
                          <Link
                            to="/innstillinger/bruker"
                            aria-label="Gå til brukerinnstillinger"
                          >
                            <Avatar
                              image={user.data.profileImage || undefined}
                              fallback={user.data.name}
                            />
                          </Link>
                        </FlexKid>
                      </Flex>
                    </Fader>
                  </TopBar>
                  <Main>
                    <HomeRoutes />
                    <PagesRoutes />
                    <ComponentsRoutes />
                    <DataSetRoutes />
                    <OrdersRoutes />
                    <SubscriptionsRoutes />
                    <ProductsRoutes />
                    <AttributesRoutes />
                    <ProductTemplatesRoutes />
                    <DiscountsRoutes />
                    <CustomersRoutes />
                    <BookingRoutes />
                    <FormsRoutes />
                    <CompaniesRoutes />
                    <CatalogsRoutes />
                    <QuotesRoutes />
                    <MediaRoutes />
                    <LabelRoutes />
                    <ExperimentRoutes />
                    <ReportsRoutes />
                    <AppsRoutes />
                    <SettingsRoutes />
                  </Main>
                </Wrapper>
              )}
            />
          </Switch>
        </>
      )}
    </>
  );
};

const ThemeWrapper: React.FC = () => {
  const { theme } = useContext(ThemeSwitcherContext);

  return (
    <Provider theme={theme}>
      <GlobalStyle />
      <UserProvider>
        <SiteProvider>
          <SiteThemeProvider>
            <NotificationProvider>
              <NotificationEventHandler>
                <BreadcrumbsProvider>
                  <Router>
                    <ModalProvider>
                      <RouteHandler />
                      <Notifications />
                    </ModalProvider>
                  </Router>
                </BreadcrumbsProvider>
              </NotificationEventHandler>
            </NotificationProvider>
          </SiteThemeProvider>
        </SiteProvider>
      </UserProvider>
    </Provider>
  );
};

const App: React.FC = () => {
  return (
    <ThemeSwitcherProvider>
      <ThemeWrapper />
    </ThemeSwitcherProvider>
  );
};

export default App;
