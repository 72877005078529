import React from "react";
import { Switch, Route, Redirect } from "react-router";
import { Login, SignOn } from "./Login";

const LoginRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/logg-inn" component={Login} />
      <Route exact path="/sign-on" component={SignOn} />
      <Route render={() => <Redirect to="/logg-inn" />} />
    </Switch>
  );
};

export default LoginRoutes;
