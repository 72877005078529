import React, { useState, useMemo } from "react";
import styled from "styled-components";
//@ts-ignore
import webSafeFonts from "web-safe-fonts";
import { FaAngleUp } from "react-icons/fa";
import { AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import {
  ComponentProp,
  Theme,
  Fonts,
  FontDefinition,
  FontMediaQuery
} from "@ludens-reklame/rubics-sdk";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { UseFormInterface } from "../../hooks/useForm";
import { Site } from "../../types/apiResponses";
import Label from "../../style-guide/Inputs/Label";
import Select from "../../style-guide/Inputs/Select";
import TextInput from "../../style-guide/Inputs/TextInput";
import { Elements, Element } from "../../style-guide/Elements/Elements";
import { ButtonList, Button } from "../../style-guide/Button/Button";
import Text from "../../style-guide/Text/Text";
import CrudModal, { createCrudModalField } from "../CrudModal/CrudModal";
import Field from "../../style-guide/Inputs/Field";
import Ruler from "../../style-guide/Ruler/Ruler";

const Wrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 8px;
  padding: ${(props) => props.theme.spacing.small};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ExpandedWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 8px;
  padding: ${(props) => props.theme.spacing.small};
`;

export const defaultFonts = webSafeFonts().map(
  //@ts-ignore
  (f) => ({
    value: `'${f.family}', ${f.type}`,
    label: f.family
  })
);

interface Props {
  path: string;
  form: UseFormInterface<any>;
  prop: Pick<ComponentProp, "name">;
  site: Site;
  theme: Theme;
  defaultValue?: any;
}

const Fonter: React.FC<Props> = ({
  path,
  form,
  prop,
  site,
  theme,
  defaultValue
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { spawnModal } = useModal();

  const familyPath = path + ".family";
  const weightPath = path + ".weight";
  const sizePath = path + ".size";
  const queriesPath = path + ".mediaQueries";
  const familyValue = form.getValue(familyPath);
  const weightValue = form.getValue(weightPath);
  const sizeValue = form.getValue(sizePath);
  const queries =
    (form.getValue(queriesPath) as FontMediaQuery[] | undefined) || [];

  const flattenedFonts = useMemo(
    () => flattenFonts(theme.defaultFonts, site.fonts),
    [theme.defaultFonts, site.fonts]
  );

  const humanValue = useMemo(
    () => fontValueToHuman(familyValue, flattenedFonts),
    [familyValue, flattenedFonts]
  );

  const fontOptions = useMemo(
    () => fontsToOptions(flattenedFonts),
    [flattenedFonts]
  );

  if (isExpanded) {
    return (
      <ExpandedWrapper>
        <Field hugTop>
          <Label htmlFor={prop.name + "family"}>Familie</Label>
          <Select
            id={prop.name + "family"}
            value={familyValue}
            onChange={(e) =>
              form.setField({
                path: familyPath,
                value: e.target.value,
                submit: true
              })
            }
          >
            {fontOptions.map((f) => (
              <option key={f.value} value={f.value}>
                {f.label}
              </option>
            ))}
            {
              //@ts-ignore
              defaultFonts.map((f) => (
                <option key={f.value} value={f.value}>
                  {f.label}
                </option>
              ))
            }
          </Select>
        </Field>
        <Field>
          <Label htmlFor={prop.name + "weight"}>Vekt</Label>
          <TextInput
            id={prop.name + "weight"}
            type="number"
            value={weightValue}
            placeholder={defaultValue ? defaultValue.weight : undefined}
            onChange={(e) =>
              form.setField({
                path: weightPath,
                value: parseFloat(e.target.value),
                submit: true,
                debounce: true
              })
            }
          />
        </Field>
        <Field>
          <Label htmlFor={prop.name + "size"}>Størrelse (REM)</Label>
          <TextInput
            id={prop.name + "size"}
            type="number"
            value={sizeValue}
            placeholder={defaultValue ? defaultValue.size : undefined}
            onChange={(e) =>
              form.setField({
                path: sizePath,
                value: parseFloat(e.target.value),
                submit: true,
                debounce: true
              })
            }
          />
        </Field>
        <Field>
          <Label>Skjermstørrelser</Label>
          <Elements>
            {queries.map((q, k) => (
              <Element
                key={q.query}
                icon={<AiOutlineEdit />}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  spawnModal(
                    <QueryModal
                      fonts={flattenedFonts}
                      item={q}
                      onSubmit={(query) => {
                        form.setField({
                          path: queriesPath,
                          value: queries.map((q, qk) => {
                            if (qk === k) {
                              return query;
                            }

                            return q;
                          }),
                          submit: true
                        });
                      }}
                      onDeleteClick={() => {
                        form.setField({
                          path: queriesPath,
                          value: queries.filter((_, qk) => qk !== k),
                          submit: true
                        });
                      }}
                    />
                  );
                }}
              >
                <span
                  style={{
                    fontFamily: q.family || familyValue,
                    fontWeight: q.weight || weightValue
                  }}
                >
                  {q.query}px
                  {q.family
                    ? `, ${fontValueToHuman(q.family, flattenedFonts)}`
                    : ""}
                  {q.weight ? `, ${q.weight}` : ""}
                  {q.size ? `, ${q.size}rem` : ""}
                </span>
              </Element>
            ))}
            <Element
              icon={<AiOutlinePlus />}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                spawnModal(
                  <QueryModal
                    fonts={flattenedFonts}
                    onSubmit={(query) => {
                      form.setField({
                        path: queriesPath,
                        value: [...queries, query],
                        submit: true
                      });
                    }}
                  />
                );
              }}
            >
              Legg til skjermstørrelse
            </Element>
          </Elements>
        </Field>
        <Ruler gutterTop tight />
        <ButtonList align="right">
          <Button
            circular
            outlined
            smaller
            onClick={() => {
              setIsExpanded(false);
            }}
          >
            <FaAngleUp />
          </Button>
        </ButtonList>
      </ExpandedWrapper>
    );
  } else {
    return (
      <Wrapper
        onClick={() => {
          setIsExpanded(true);
        }}
      >
        <Text>
          <span style={{ fontFamily: familyValue, fontWeight: weightValue }}>
            {humanValue}, {weightValue}, {sizeValue}rem
          </span>
        </Text>
        <ButtonList align="center" tight>
          <Button
            type="button"
            circular
            outlined
            smaller
            onClick={() => {
              setIsExpanded(true);
            }}
          >
            <AiOutlineEdit />
          </Button>
        </ButtonList>
      </Wrapper>
    );
  }
};

export function flattenFonts(
  themeFonts: Fonts,
  siteFonts: Fonts
): FontDefinition[] {
  const flattenedFonts: FontDefinition[] = [];

  for (let i = 0; i < themeFonts.google.length; i++) {
    flattenedFonts.push(themeFonts.google[i]);
  }

  for (let i = 0; i < themeFonts.typekit.length; i++) {
    flattenedFonts.push(themeFonts.typekit[i]);
  }

  for (let i = 0; i < themeFonts.custom.length; i++) {
    flattenedFonts.push(themeFonts.custom[i]);
  }

  for (let i = 0; i < siteFonts.google.length; i++) {
    const font = siteFonts.google[i];

    if (!flattenedFonts.includes(font)) {
      flattenedFonts.push(font);
    }
  }

  for (let i = 0; i < siteFonts.typekit.length; i++) {
    const font = siteFonts.typekit[i];

    if (!flattenedFonts.includes(font)) {
      flattenedFonts.push(font);
    }
  }

  for (let i = 0; i < siteFonts.custom.length; i++) {
    const font = siteFonts.custom[i];

    if (!flattenedFonts.includes(font)) {
      flattenedFonts.push(font);
    }
  }

  return flattenedFonts;
}

export function fontsToOptions(
  fonts: FontDefinition[]
): { value: string; label: string }[] {
  return fonts.map((f) => ({
    label: f.label || f.selector,
    value: f.cssValue
  }));
}

export function fontValueToHuman(
  value: string,
  fonts: FontDefinition[]
): string | undefined {
  //@ts-ignore
  const defaultFont = defaultFonts.find((f) => f.value === value);

  if (!!defaultFont) {
    return defaultFont.label;
  }

  const rest = fonts.find((f) => f.cssValue === value);

  if (!!rest) {
    return rest.label;
  }

  return undefined;
}

interface QueryModalProps {
  fonts: FontDefinition[];
  onSubmit: (item: FontMediaQuery) => any;
  item?: any;
  onDeleteClick?: () => any;
}

const QueryModal: React.FC<QueryModalProps> = ({
  fonts,
  onSubmit,
  item,
  onDeleteClick
}) => {
  return (
    <CrudModal
      title="Skjermstørrelse"
      initialData={item || {}}
      onSubmit={onSubmit}
      onDelete={onDeleteClick}
      fields={[
        {
          key: "query",
          render: (payload) =>
            createCrudModalField({
              payload,
              key: "query",
              type: "number",
              label: "Minimum-skjermstørrelse (piksler)",
              required: true
            })
        },
        {
          key: "family",
          render: (payload) =>
            createCrudModalField({
              payload,
              key: "family",
              type: "select",
              label: "Overstyr familie",
              options: [...fontsToOptions(fonts), ...defaultFonts]
            })
        },
        {
          key: "weight",
          render: (payload) =>
            createCrudModalField({
              payload,
              key: "weight",
              type: "number",
              label: "Overstyr vekt"
            })
        },
        {
          key: "size",
          render: (payload) =>
            createCrudModalField({
              payload,
              key: "size",
              type: "number",
              label: "Overstyr størrelse (REM)"
            })
        }
      ]}
    />
  );
};

export default Fonter;
