import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { FaBoxes } from "react-icons/fa";
import Text from "../../../style-guide/Text/Text";
import Block from "../../../style-guide/Block/Block";
import Crumb from "../../../components/Crumb/Crumb";
import { useApi } from "../../../hooks/useApi";
import {
  Site,
  ShippingClass as IShippingClass
} from "../../../types/apiResponses";
import useApiUrl from "../../../hooks/useApiUrl";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";
import Card from "../../../style-guide/Card/Card";
import EmptyState from "../../../style-guide/EmptyState/EmptyState";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import { ButtonLink } from "../../../style-guide/Button/Button";
import Fader from "../../../style-guide/Fader/Fader";
import { Table, Tr, Th, Td } from "../../../style-guide/Table/Table";

const ShippingClasses: React.FC = () => {
  const url = useApiUrl(["site"]);
  const fetch = useApi<Site | null>({
    endpoint: url,
    initialData: null,
    fetchOnMount: true
  });

  const classes = useMemo<IShippingClass[]>(() => {
    return fetch.resource?.shippingClasses || [];
  }, [fetch.resource]);

  return (
    <Fader>
      <Block hugTop>
        <Flex>
          <FlexKid flex={1}>
            <Text element="h1" variant="display3">
              <Crumb url="/innstillinger/frakt">Frakt</Crumb>
              Fraktklasser
            </Text>
          </FlexKid>
          <FlexKid>
            <ButtonLink to="klasser/opprett">Opprett fraktklasse</ButtonLink>
          </FlexKid>
        </Flex>
      </Block>
      <BusyBoy busy={fetch.loading}>
        {fetch.hasFetched && classes.length > 0 ? (
          <Card>
            <Table>
              <thead>
                <Tr>
                  <Th>Navn</Th>
                  <Th>Beskrivelse</Th>
                </Tr>
              </thead>
              <tbody>
                {classes.map((c) => (
                  <Tr key={c._id}>
                    <Td>
                      <Text variant="body2">
                        <Link to={`klasser/${c._id}`}>{c.name}</Link>
                      </Text>
                    </Td>
                    <Td>
                      <Text variant="body3">
                        {c.description || "Mangler beskrivelse"}
                      </Text>
                    </Td>
                  </Tr>
                ))}
              </tbody>
            </Table>
          </Card>
        ) : (
          <EmptyState
            title="Opprett din første fraktklasse"
            icon={<FaBoxes />}
            text="Fraktklasser gjør det mulig å ha ulike fraktkostnader per produkt."
            action={
              <ButtonLink to="klasser/opprett">Opprett fraktklasse</ButtonLink>
            }
          />
        )}
      </BusyBoy>
    </Fader>
  );
};

export default ShippingClasses;
