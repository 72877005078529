import React, { useCallback, useMemo, useRef, useState } from "react";
import { Accept, ErrorCode, FileRejection, useDropzone } from "react-dropzone";
import isImage from "is-image";
import { Util } from "@ludens-reklame/rubics-sdk";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import {
  Modal,
  ModalBody,
  ModalActions
} from "../../../style-guide/Modal/Modal";
import { Button, ButtonList } from "../../../style-guide/Button/Button";
import Block from "../../../style-guide/Block/Block";
import { Media } from "../../../types/apiResponses";
import api from "../../../util/api";
import Field from "../../../style-guide/Inputs/Field";
import Label from "../../../style-guide/Inputs/Label";
import Text from "../../../style-guide/Text/Text";
import Tagger from "../../../components/Tagger/Tagger";
import Ruler from "../../../style-guide/Ruler/Ruler";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import createInputField from "../../../util/createInputField";
import { File } from "../../../components/MediaPicker/MediaPicker";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";
import styled from "styled-components";

interface FileInterface {
  id: string;
  file: File;
  queuedForUpload: boolean;
  uploading: boolean;
  data?: Media;
  error?: string;
  name?: string;
  alt?: string;
  tags?: string[];
}

interface Props {
  accept?: Accept;
  onUploaded?: () => any;
}

const Upload: React.FC<Props> = ({ accept, onUploaded }) => {
  const [files, setFiles] = useState<FileInterface[]>([]);
  const [globalTags, setGlobalTags] = useState<string[]>([]);
  const filesRef = useRef<FileInterface[]>([]);
  const { despawnModal } = useModal();

  const setFile = useCallback(
    (id: string, data: Partial<FileInterface>, remove?: boolean) => {
      filesRef.current = remove
        ? filesRef.current.filter((f) => f.id !== id)
        : filesRef.current.map((f) => {
            if (f.id === id) {
              return {
                ...f,
                ...data
              };
            }

            return f;
          });

      setFiles(filesRef.current);
    },
    []
  );

  const handleUpload = useCallback(
    async (file: FileInterface) => {
      try {
        setFile(file.id, {
          queuedForUpload: false,
          uploading: true,
          data: undefined,
          error: undefined
        });

        const uploadedFile = await upload({
          file: file.file,
          name: file.name,
          altText: file.alt,
          tags: file.tags
        });

        setFile(file.id, { uploading: false, data: uploadedFile });
      } catch (error) {
        console.error(error);
        setFile(file.id, {
          uploading: false,
          error:
            error instanceof Error
              ? error.message
              : typeof error === "string"
              ? error
              : "error"
        });
      }
    },
    [setFile]
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      for (let i = 0; i < rejectedFiles.length; i++) {
        const rejectedFile = rejectedFiles[i];

        alert(
          `Feil ved opplasting av ${
            rejectedFile.file.name
          }:\n${rejectedFile.errors
            .map((e) => `- ${translateErrorMessage(e.code, accept)}`)
            .join("\n")}`
        );
      }

      const _files: FileInterface[] = acceptedFiles.map((f) => ({
        id: Util.createId(),
        file: f,
        name: f.name,
        queuedForUpload: true,
        uploading: false
      }));

      setFiles(_files);
      filesRef.current = _files;
    },
    [accept]
  );

  const initiateUpload = useCallback(async () => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (!file.data && !file.uploading) {
        await handleUpload(file);
      }
    }

    if (filesRef.current.every((f) => !!f.data)) {
      despawnModal();

      if (typeof onUploaded === "function") {
        onUploaded();
      }
    }
  }, [files, handleUpload, onUploaded]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxSize: 5e7,
    onDrop,
    accept
  });

  const handleSetGlobalTags = useCallback(
    (tags: string[]) => {
      setGlobalTags(tags);

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        setFile(file.id, { tags });
      }
    },
    [files]
  );

  const isUploading = useMemo<boolean>(
    () => files.some((f) => f.uploading),
    [files]
  );

  return (
    <Modal fullscreen>
      <ModalBody>
        <Block>
          <Text variant="title" gutterBottom>
            Last opp filer
          </Text>
          {files.length > 0 && (
            <Field>
              <Label>Legg til emneknagger for alle filer</Label>
              <Tagger
                tags={globalTags}
                onChange={handleSetGlobalTags}
                zIndex={5}
                readOnly={isUploading}
                placeholder="Legg til emneknagg"
                inline
              />
            </Field>
          )}
          {files
            .filter((f) => !f.data)
            .map((f) => (
              <FileEdit
                key={f.id}
                file={f}
                isUploading={isUploading}
                onChange={(file) => setFile(f.id, file)}
                onDelete={() => setFile(f.id, f, true)}
              />
            ))}
          {files.length === 0 && (
            <DropzoneWrapper>
              <Dropzone {...getRootProps()} isDragActive={isDragActive}>
                <Text>
                  {isDragActive
                    ? "Slipp filene her"
                    : "Dra filer hit, eller klikk her for å laste opp"}
                </Text>
                <input {...getInputProps()} />
              </Dropzone>
            </DropzoneWrapper>
          )}
        </Block>
      </ModalBody>
      <ModalActions>
        <ButtonList align="right">
          <Button type="button" outlined onClick={despawnModal}>
            Avbryt
          </Button>
          <Button
            type="button"
            disabled={files.length === 0 || isUploading}
            onClick={initiateUpload}
          >
            {isUploading ? "Laster opp…" : "Last opp"}
          </Button>
        </ButtonList>
      </ModalActions>
    </Modal>
  );
};

interface FileEditProps {
  file: FileInterface;
  isUploading: boolean;
  onChange: (file: Partial<FileInterface>) => any;
  onDelete: () => any;
}

const FileEdit: React.FC<FileEditProps> = ({
  file,
  isUploading,
  onChange,
  onDelete
}) => {
  const imageUrl = useMemo<string | undefined>(() => {
    if (isImage(file.file.name)) {
      return URL.createObjectURL(file.file);
    }

    return undefined;
  }, [file]);

  return (
    <>
      <Ruler gutterTop />
      <BusyBoy busy={file.uploading}>
        <Flex align="top">
          <FlexKid centerContent>
            <div>
              {imageUrl ? (
                <img src={imageUrl} width={300} />
              ) : (
                <File type={file.file.type} width={300} />
              )}
              <ButtonList gutterTop align="stretch">
                <Button
                  type="button"
                  outlined
                  onClick={onDelete}
                  disabled={isUploading}
                >
                  {file.uploading ? "Laster opp…" : "Fjern fila"}
                </Button>
              </ButtonList>
            </div>
          </FlexKid>
          <FlexKid flex={1} spaceLeft>
            {createInputField({
              key: "name",
              label: "Filnavn",
              type: "text",
              hugTop: true,
              value: file.name,
              disabled: isUploading,
              onChange: (value) =>
                onChange({
                  name: value
                })
            })}
            {createInputField({
              key: "alt",
              label: "Alt-tekst",
              type: "text",
              value: file.alt,
              disabled: isUploading,
              onChange: (value) =>
                onChange({
                  alt: value
                })
            })}
            <Field>
              <Label>Emneknagger</Label>
              <Tagger
                tags={file.tags}
                onChange={(tags) => onChange({ tags })}
                zIndex={5}
                readOnly={isUploading}
                inline
                placeholder="Legg til emneknagg"
              />
            </Field>
          </FlexKid>
        </Flex>
      </BusyBoy>
    </>
  );
};

interface UploadPayload {
  file: File;
  name?: string;
  altText?: string;
  tags?: string[];
}

async function upload(payload: UploadPayload): Promise<Media> {
  if (!payload.name) {
    delete payload.name;
  }

  if (!payload.altText) {
    delete payload.altText;
  }

  if (!payload.tags) {
    delete payload.tags;
  }

  const response = await api<Media>({
    endpoint: "media",
    method: "POST",
    body: payload,
    multipart: true
  });

  if ("status" in response) {
    const body = await response.json();
    throw new Error(body.message);
  }

  return response;
}

function translateErrorMessage(
  errorCode: ErrorCode | string,
  acceptedTypes?: Accept
): string {
  switch (errorCode) {
    case ErrorCode.FileTooLarge:
      return "For stor (maks 50 mb)";
    case ErrorCode.FileInvalidType:
      return `Feil filtype${
        acceptedTypes ? ` (aksepterer ${acceptedTypes})` : ""
      }`;
    default:
      return "Ukjent feil";
  }
}

const DropzoneWrapper = styled.div`
  padding: ${(props) => props.theme.spacing.medium};
`;

const Dropzone = styled.div<{ isDragActive?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${(props) => props.theme.spacing.large};
  height: 400px;
  border-radius: 8px;
  border: 1px solid
    ${(props) =>
      props.isDragActive
        ? props.theme.colors.textPrimary
        : props.theme.colors.borderDim};

  p {
    text-align: center;
  }
`;

export default Upload;
