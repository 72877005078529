import styled from "styled-components";

const Validation = styled.p`
  margin: 0;
  color: ${(props) => props.theme.colors.warning};
  margin-bottom: ${(props) => props.theme.spacing.small};
  margin-top: calc(-${(props) => props.theme.spacing.small} / 2);
  font-weight: 600;
`;

export default Validation;
