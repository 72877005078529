import React, { useEffect } from "react";
import { Prompt, Route, RouteComponentProps, Switch } from "react-router-dom";
import {
  AiOutlineFile,
  AiOutlineProfile,
  AiOutlineShoppingCart,
  AiOutlineTeam
} from "react-icons/ai";
import { Company as ICompany } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import Fader from "../../style-guide/Fader/Fader";
import Block from "../../style-guide/Block/Block";
import usePublisher from "../../hooks/usePublisher";
import Section from "../../style-guide/Section/Section";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import Crumb from "../../components/Crumb/Crumb";
import Form from "../../style-guide/Inputs/Form";
import ActionBar from "../../components/ActionBar/ActionBar";
import PublisherHelpers from "../../util/PublisherHelpers";
import {
  InlineActionLink,
  InlineActions
} from "../../style-guide/InlineActions/InlineActions";
import General from "./SubViews/General";
import { LEAVE_PAGE_MESSAGE } from "../../constants/general";
import Teams from "./SubViews/Teams";
import Orders from "./SubViews/Orders";
import Files from "./SubViews/Files";

interface Props
  extends RouteComponentProps<{
    company: string;
  }> {}

const Company: React.FC<Props> = ({ match }) => {
  const companyId = match.params.company;

  const breadcrumbs = useBreadcrumbs();
  const publisher = usePublisher<ICompany>({
    id: companyId,
    baseUrl: ["companies"],
    baseDashboardUrl: "/b2b/bedrifter",
    initialData: {
      teams: []
    }
  });

  useEffect(() => {
    breadcrumbs.swapEntry(companyId, publisher.form.data.name || companyId);
  }, [breadcrumbs, publisher.form.data, companyId]);

  return (
    <Form onSubmit={publisher.form.submit}>
      <Prompt
        when={publisher.form.hasMadeChanges}
        message={(location) => {
          if (location.pathname.includes(companyId)) {
            return true;
          }

          return LEAVE_PAGE_MESSAGE;
        }}
      />
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/b2b/bedrifter">Bedrifter</Crumb>
            {PublisherHelpers.getResourceTitle(publisher, "name", "Ny bedrift")}
          </Text>
        </Block>
        <Block hugTop hugBottom>
          <InlineActions>
            <InlineActionLink exact to={publisher.dashboardUrl}>
              <AiOutlineProfile /> Generelt
            </InlineActionLink>
            <InlineActionLink exact to={`${publisher.dashboardUrl}/avdelinger`}>
              <AiOutlineTeam /> Avdelinger
            </InlineActionLink>
            {!publisher.isNew && (
              <InlineActionLink exact to={`${publisher.dashboardUrl}/ordrer`}>
                <AiOutlineShoppingCart /> Ordrer
              </InlineActionLink>
            )}
            <InlineActionLink exact to={`${publisher.dashboardUrl}/filer`}>
              <AiOutlineFile /> Filer
            </InlineActionLink>
          </InlineActions>
        </Block>
        <Section hugTop>
          <Switch>
            <Route
              exact
              path={publisher.dashboardUrl}
              render={() => <General publisher={publisher} />}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/avdelinger`}
              render={() => <Teams publisher={publisher} />}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/ordrer`}
              render={() => <Orders publisher={publisher} />}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/filer`}
              render={() => <Files publisher={publisher} />}
            />
          </Switch>
        </Section>
      </Fader>
      <ActionBar publisher={publisher} destroy />
    </Form>
  );
};

export default Company;
