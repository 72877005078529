import React, { ReactElement } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from "react-beautiful-dnd";
import { createPortal } from "react-dom";

interface DropperProps {
  id: string;
  onDragEnd: (result: DropResult) => any;
}

const portal = document.createElement("div");
document.body.appendChild(portal);

const Dropper: React.FC<DropperProps> = ({ id, onDragEnd, children }) => {
  return (
    <DragDropContext
      onDragEnd={(r) => typeof onDragEnd === "function" && onDragEnd(r)}
    >
      <Droppable droppableId={id}>
        {(p) => (
          <div ref={p.innerRef}>
            {children}
            {p.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

interface DraggerProps {
  id: string;
  index: number;
  delegateDragHandle?: boolean;
  isDragDisabled?: boolean;
}

const Dragger: React.FC<DraggerProps> = ({
  id,
  index,
  delegateDragHandle,
  isDragDisabled,
  children
}) => {
  function optionalPortal(styles: any, element: any) {
    if (styles.position === "fixed") {
      return createPortal(element, portal);
    }

    return element;
  }

  return (
    <Draggable draggableId={id} index={index} isDragDisabled={isDragDisabled}>
      {(p, s) => {
        const childrenWithProps = React.cloneElement(
          children as ReactElement<any>,
          {
            dragHandleProps: p.dragHandleProps,
            dragState: s
          }
        );

        return (
          <div>
            {optionalPortal(
              p.draggableProps.style,
              <div
                ref={p.innerRef}
                {...p.draggableProps}
                {...(delegateDragHandle ? {} : p.dragHandleProps)}
              >
                {childrenWithProps}
              </div>
            )}
            {p.placeholder}
          </div>
        );
      }}
    </Draggable>
  );
};

export { Dropper, Dragger };
