import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { AiOutlineForm } from "react-icons/ai";
import { Company, Order } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import Tabler from "../../components/Tabler/Tabler";
import { currencyFormat } from "../../util/intl";
import Status from "../../style-guide/Status/Status";
import localize from "../../util/localize";
import { orderStatus } from "../../constants/localizations";
import { Dotter, Dot } from "../../style-guide/Dotter/Dotter";
import { RequestData } from "../../util/api";
import { OrderStatus, OrderType } from "../../constants/api";

interface Props {
  hideTitle?: boolean;
  query?: RequestData;
}

const Quotes: React.FC<Props> = ({ hideTitle, query }) => {
  return (
    <Tabler
      title="Tilbudsforespørsler"
      crumb={{
        label: "B2B",
        path: "/b2b"
      }}
      hideTitle={hideTitle}
      emptyState={{
        title: "Ingen tilbudsforespørsler enda",
        icon: <AiOutlineForm />,
        text: (
          <>
            <Text gutterBottom>
              Når en B2B-kunde går til utsjekk kan vedkommende velge å sende inn
              en tilbudsforespørsel i stedet for å legge inn en ordre.
            </Text>
            <Text>
              En slik tilbudsforespørsel kan så endres eller godkjennes av deg
              før kunden til slutt legger inn en ordre.
            </Text>
          </>
        )
      }}
      url={["orders"]}
      queryParams={{
        type: OrderType.Quote,
        ...query
      }}
      queryField="orderNumber"
      queryLabel="Tilbudsforespørselnummer"
      columns={[
        {
          Header: "Tilbudsforespørsel",
          accessor: "title",
          verticalAlign: "middle"
        },
        {
          Header: "Status",
          accessor: "status",
          verticalAlign: "middle",
          align: "center"
        },
        {
          Header: "Dato",
          accessor: "timestamp",
          verticalAlign: "middle"
        },
        {
          Header: "Beløp",
          accessor: "total",
          verticalAlign: "middle",
          align: "right"
        }
      ]}
      createRow={(order: Order) => ({
        ...order,
        title: <TitleRow order={order} />,
        status: (
          <Status
            variant={
              order.status === OrderStatus.Pending
                ? "good"
                : order.status === OrderStatus.Processing
                ? "neutral"
                : order.status === OrderStatus.Failed
                ? "bad"
                : undefined
            }
          >
            {localize(orderStatus, order.status)}
          </Status>
        ),
        timestamp: (
          <Text variant="body3">
            {moment(order.timestamp).format("D. MMMM Y")}
          </Text>
        ),
        total: (
          <Text align="right" variant="body3">
            {currencyFormat.format(order.total)}
          </Text>
        )
      })}
    />
  );
};

interface TitleRowProps {
  order: Order;
}

const TitleRow: React.FC<TitleRowProps> = ({ order }) => {
  const company = useMemo<Company | undefined>(() => {
    if (order.company && typeof order.company !== "string") {
      return order.company;
    }

    return undefined;
  }, [order.company]);

  return (
    <>
      <Text variant="body2">
        <Link to={`/b2b/tilbudsforesporsler/${order._id}`}>
          <Dotter>
            <Dot>#{order.orderNumber}</Dot>
            {company && <Dot>{company.name}</Dot>}
          </Dotter>
        </Link>
      </Text>
    </>
  );
};

export default Quotes;
