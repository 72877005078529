import React from "react";
import { RubicsCart } from "@ludens-reklame/rubics-sdk";
import { Table, Th, Tr, Td, Tfoot } from "../../style-guide/Table/Table";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import Text from "../../style-guide/Text/Text";
import { currencyFormat, numberFormat } from "../../util/intl";

interface Props {
  data: RubicsCart;
  subscriptionInterval?: number;
}

const Cart: React.FC<Props> = ({ data, subscriptionInterval }) => {
  return (
    <Table>
      <thead>
        <Tr>
          <Th>Beskrivelse</Th>
          <Th align="right">Antall</Th>
          <Th align="right">Kostnad</Th>
        </Tr>
      </thead>
      <tbody>
        {data.items.map((i) => {
          return (
            <Tr key={i._id}>
              <Td>
                <Flex align="flex-start">
                  {!!i.bundle && <Flex>— &nbsp;</Flex>}
                  <FlexKid flex={1}>
                    <Text>{i.product.name}</Text>
                    {i.customizations.map((c) => {
                      let value = c.value;

                      if (
                        value &&
                        typeof value === "object" &&
                        "url" in value
                      ) {
                        value = (
                          <a
                            href={c.value.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {c.value.filename}
                          </a>
                        );
                      }

                      return (
                        <Text key={c.key} variant="body3">
                          {c.label}: {value}
                        </Text>
                      );
                    })}
                    {i.product.attributes.map((a) => (
                      <Text key={a._id} variant="body3">
                        {a.name}: {a.value}
                      </Text>
                    ))}
                  </FlexKid>
                </Flex>
              </Td>
              <Td align="right">
                <Text align="right">{numberFormat.format(i.amount)}</Text>
              </Td>
              <Td align="right">
                <Text align="right">{currencyFormat.format(i.cost)}</Text>
              </Td>
            </Tr>
          );
        })}
        {data.shipping && (
          <Tr>
            <Td>
              <Text>{data.shipping.methodName}</Text>
              <Text variant="body3">Frakt</Text>
            </Td>
            <Td align="right">
              <Text align="right">1</Text>
            </Td>
            <Td align="right">
              <Text align="right">
                {currencyFormat.format(data.shippingCost)}
              </Text>
            </Td>
          </Tr>
        )}
      </tbody>
      <Tfoot style={{ lineHeight: "2" }}>
        <Tr>
          <Td align="right" colSpan={2}>
            <Text align="right">Total</Text>
          </Td>
          <Td align="right">
            <Text align="right" variant="body2">
              {currencyFormat.format(data.cost)}
              {typeof subscriptionInterval === "number" &&
                ` / ${subscriptionInterval} dager`}
            </Text>
          </Td>
        </Tr>
      </Tfoot>
    </Table>
  );
};

export default Cart;
