import storage from "./storage";

function getBaseApiUrl(): string {
  let url: string;

  const site = storage.get("site");
  const rubicsUrl = process.env.REACT_APP_RUBICS_URL || "";

  if (!rubicsUrl.includes("localhost") && site) {
    url = `https://${site || "service"}.${process.env.REACT_APP_RUBICS_DOMAIN}`;
  } else {
    url = rubicsUrl;
  }

  return url;
}

export default getBaseApiUrl;
