import { Permissions } from "../constants/api";

export const permissionsAll = [
  Permissions.Reader,
  Permissions.Contributor,
  Permissions.Admin
];

export const permissionsContributor = [
  Permissions.Contributor,
  Permissions.Admin
];

export const permissionsAdmin = [Permissions.Admin];
