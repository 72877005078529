import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import { PriceType, ProductType } from "@ludens-reklame/rubics-sdk";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import {
  Catalog,
  Product,
  ProductCatalogEntry
} from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Section from "../../../style-guide/Section/Section";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import { Button, ButtonList } from "../../../style-guide/Button/Button";
import { CatalogPriceType, CatalogType } from "../../../constants/api";
import Ruler from "../../../style-guide/Ruler/Ruler";
import InlineEdit from "../../../components/InlineEdit/InlineEdit";
import { currencyFormat } from "../../../util/intl";
import createInputField from "../../../util/createInputField";
import { catalogPriceType } from "../../../constants/localizations";
import { useSearch } from "../../../hooks/useApi";
import Form from "../../../style-guide/Inputs/Form";
import {
  Modal,
  ModalActions,
  ModalBody
} from "../../../style-guide/Modal/Modal";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";
import createPublisherInputField from "../../../util/createPublisherInputField";

interface Props {
  publisher: UsePublisherInterface<Product>;
}

const Catalogs: React.FC<Props> = ({ publisher }) => {
  const form = publisher.form;
  const data = form.data;
  const { spawnModal } = useModal();

  return (
    <Fader>
      <Section hugTop>
        <Card>
          <Block>
            {createPublisherInputField(publisher, {
              path: "inStandardCatalog",
              label: "Tilgjengelig i standardkatalog?",
              type: "boolean",
              autosave: true,
              hugTop: true
            })}
          </Block>
        </Card>
      </Section>
      <Section>
        <Card>
          <Block>
            <Flex>
              <FlexKid flex={1}>
                <Text element="h2" variant="title">
                  Kataloger
                </Text>
              </FlexKid>
              {!publisher.isRevision && (
                <FlexKid>
                  <Button
                    type="button"
                    outlined
                    onClick={() =>
                      spawnModal(
                        <AddCatalogModal
                          type={CatalogType.Product}
                          existingEntries={(data.catalogEntries || []).map(
                            (c) => {
                              if (typeof c.catalog === "string") {
                                return c.catalog;
                              }

                              return c.catalog._id;
                            }
                          )}
                          onSave={(values) => {
                            form.setField({
                              path: "catalogEntries",
                              value: [...values, ...data.catalogEntries],
                              submit: true
                            });
                          }}
                        />
                      )
                    }
                  >
                    Legg til i kataloger
                  </Button>
                </FlexKid>
              )}
            </Flex>
          </Block>
          {(data.catalogEntries || []).length === 0 ? (
            <>
              <Ruler />
              <Block hugTop>
                <Text variant="subheading">
                  Ikke tilgjengelig i noen kataloger
                </Text>
              </Block>
            </>
          ) : (
            data.catalogEntries.map((c) => (
              <CatalogRow
                key={c._id}
                catalogEntry={c}
                product={data}
                readOnly={publisher.isRevision}
                onDelete={() => {
                  if (!publisher.isRevision) {
                    form.setField({
                      path: "catalogEntries",
                      value: data.catalogEntries.filter(
                        (cc) => cc._id !== c._id
                      ),
                      submit: true
                    });
                  }
                }}
                onChange={(value) => {
                  if (!publisher.isRevision) {
                    form.setField({
                      path: "catalogEntries",
                      value: data.catalogEntries.map((cc) => {
                        if (cc._id === c._id) {
                          return value;
                        }

                        return cc;
                      }),
                      submit: true
                    });
                  }
                }}
              />
            ))
          )}
        </Card>
      </Section>
    </Fader>
  );
};

interface CatalogRowProps {
  catalogEntry: ProductCatalogEntry;
  product: Product;
  readOnly: boolean;
  onDelete: () => any;
  onChange: (catalogEntry: ProductCatalogEntry) => any;
}

const CatalogRow: React.FC<CatalogRowProps> = ({
  catalogEntry,
  product,
  readOnly,
  onDelete,
  onChange
}) => {
  const catalog = catalogEntry.catalog as Catalog;
  const canEditProductPrice = useMemo<boolean>(() => {
    if (
      product.type === ProductType.VariantShell ||
      product.price.type === PriceType.ByAppointment
    ) {
      return false;
    }

    return true;
  }, [product]);

  const newPrice = useMemo<number>(() => {
    if (
      typeof catalogEntry.price === "number" &&
      catalogEntry.priceType !== CatalogPriceType.ByAppointment
    ) {
      if (catalogEntry.priceType === CatalogPriceType.Fixed) {
        return product.price.value + catalogEntry.price;
      } else if (catalogEntry.priceType === CatalogPriceType.NewPrice) {
        return catalogEntry.price;
      }

      return product.price.value * (1 + catalogEntry.price / 100);
    } else {
      return product.price.value;
    }
  }, [catalogEntry, product]);

  return (
    <InlineEdit
      tight
      actions={[
        <Button
          type="button"
          aria-label="Fjern"
          circular
          outlined
          smaller
          disabled={readOnly}
          onClick={() => {
            if (readOnly) {
              return;
            }

            if (window.confirm("Er du sikker på at du vil fjerne?")) {
              onDelete();
            }
          }}
        >
          <AiOutlineDelete />
        </Button>
      ]}
      headerColumns={[
        {
          width: "320px",
          node: (
            <>
              <Text variant="body2">
                <Link to={`/butikk/produktkataloger/${catalog._id}`}>
                  {catalog.name}
                </Link>
              </Text>
              <Text variant="body3">
                {catalog.description || "Mangler beskrivelse"}
              </Text>
            </>
          )
        },
        {
          width: "150px",
          alignRight: true,
          node: canEditProductPrice
            ? currencyFormat.format(product.price.value)
            : "N/A"
        },
        {
          width: "330px",
          node: canEditProductPrice ? (
            <Flex>
              <FlexKid width="115px">
                {createInputField({
                  key: "priceType",
                  type: "select",
                  required: true,
                  hugTop: true,
                  disabled: readOnly,
                  value: catalogEntry?.priceType || "",
                  options: Object.keys(catalogPriceType).map((k) => ({
                    value: k,
                    label: catalogPriceType[k]
                  })),
                  onChange: (value) => {
                    onChange({
                      ...catalogEntry,
                      priceType: value
                    });
                  }
                })}
              </FlexKid>
              {catalogEntry?.priceType !== CatalogPriceType.ByAppointment && (
                <FlexKid spaceLeft tight width="130px">
                  {createInputField({
                    key: "price",
                    type: "number",
                    hugTop: true,
                    disabled: readOnly,
                    value: catalogEntry?.price || "",
                    onChange: (value) => {
                      onChange({
                        ...catalogEntry,
                        price: value
                      });
                    }
                  })}
                </FlexKid>
              )}
            </Flex>
          ) : (
            "N/A"
          )
        },
        {
          width: "150px",
          alignRight: true,
          node: canEditProductPrice
            ? catalogEntry?.priceType === CatalogPriceType.ByAppointment
              ? "Etter avtale"
              : currencyFormat.format(newPrice)
            : "N/A"
        }
      ]}
    />
  );
};

interface ProductCatalogEntryWithCatalog extends ProductCatalogEntry {
  catalog: Catalog;
}

interface AddCatalogModalProps {
  type: CatalogType;
  existingEntries: string[];
  onSave: (entries: ProductCatalogEntry[]) => any;
}

export const AddCatalogModal: React.FC<AddCatalogModalProps> = ({
  type,
  existingEntries,
  onSave
}) => {
  const [values, setValues] = useState<ProductCatalogEntryWithCatalog[]>([]);
  const { despawnModal } = useModal();
  const catalogsSearch = useSearch<Catalog>({
    endpoint: "catalogs",
    fetchOnMount: true,
    queryParams: {
      type
    },
    limit: 9999
  });

  const usableCatalogs = useMemo<Catalog[]>(() => {
    if (existingEntries.length > 0) {
      return catalogsSearch.results.filter(
        (c) => !existingEntries.includes(c._id)
      );
    }

    return catalogsSearch.results;
  }, [existingEntries, catalogsSearch.results]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSave(values);
        despawnModal();
      }}
    >
      <Modal maxWidth="32rem">
        <BusyBoy busy={catalogsSearch.loading}>
          <ModalBody>
            <Block>
              <Text variant="title" gutterBottom>
                Legg til i kataloger
              </Text>
              {catalogsSearch.loading ? (
                <Card outlined>
                  <Block>
                    <Text align="center" variant="body3">
                      Laster…
                    </Text>
                  </Block>
                </Card>
              ) : catalogsSearch.hasSearched && usableCatalogs.length > 0 ? (
                createInputField({
                  key: "catalogs",
                  label: "Kataloger",
                  type: "checkbox",
                  required: true,
                  options: usableCatalogs.map((c) => ({
                    value: c._id,
                    label: c.name,
                    description: c.description,
                    checked: values.some((v) => v.catalog._id === c._id)
                  })),
                  onChange: (value) => {
                    if (values.some((v) => v.catalog._id === value)) {
                      setValues(values.filter((v) => v.catalog._id !== value));
                    } else {
                      const entry = usableCatalogs.find((c) => c._id === value);

                      if (entry) {
                        setValues([...values, { catalog: entry }]);
                      }
                    }
                  }
                })
              ) : (
                <Card outlined>
                  <Block>
                    <Text align="center" variant="body3">
                      Ingen kataloger funnet…
                    </Text>
                  </Block>
                </Card>
              )}
            </Block>
          </ModalBody>
          <ModalActions>
            <ButtonList align="right">
              <Button
                type="button"
                outlined
                onClick={() => {
                  despawnModal();
                }}
              >
                Avbryt
              </Button>
              {usableCatalogs.length > 1 && (
                <Button
                  type="button"
                  outlined
                  onClick={() => {
                    setValues(
                      usableCatalogs.map((c) => ({
                        catalog: c
                      }))
                    );
                  }}
                >
                  Velg alle
                </Button>
              )}
              <Button type="submit" disabled={values.length === 0}>
                Legg til
              </Button>
            </ButtonList>
          </ModalActions>
        </BusyBoy>
      </Modal>
    </Form>
  );
};

export default Catalogs;
