import React from "react";
import { Switch, Route } from "react-router";
import Company from "./Company";
import Companies from "./Companies";

const CompaniesRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/b2b/bedrifter" component={Companies} />
      <Route
        path="/b2b/bedrifter/:company"
        render={(props) => (
          <Company key={props.match.params.company} {...props} />
        )}
      />
    </Switch>
  );
};

export default CompaniesRoutes;
