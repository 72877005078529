import React from "react";
import styled from "styled-components";
import {
  Label as SDKLabel,
  Image as ImageClass
} from "@ludens-reklame/rubics-sdk";
import { Label as ILabel } from "../../types/apiResponses";
import draftToHtml from "../../util/draftToHtml";

const Label = styled.div<{ color?: string; textColor?: string }>`
  display: inline-flex;
  align-items: center;
  background-color: ${(props) => props.color || props.theme.colors.neutral};
  color: ${(props) => props.textColor || "inherit"};
  padding: 6px 12px;
  text-align: center;
  border-radius: 8px;

  > * {
    margin: 0;
  }
`;

const Image = styled.img<{ smaller?: boolean }>`
  display: inline-block;
  height: ${(props) => (props.smaller ? "40px" : "80px")};
`;

export const Labels = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.xs};
`;

interface Props {
  label: ILabel | SDKLabel;
  smaller?: boolean;
}

export const ProductLabel: React.FC<Props> = ({ label, smaller }) => {
  if (label.image && typeof label.image !== "string") {
    return (
      <Image
        src={ImageClass.createUrl({
          image: { url: label.image.url, alt: label.text || "" },
          transforms: "h_160"
        })}
        smaller={smaller}
      />
    );
  }

  return (
    <Label
      dangerouslySetInnerHTML={{
        __html: label.text ? draftToHtml(label.text) : label.name
      }}
      color={label.color}
      textColor={label.textColor}
    />
  );
};
