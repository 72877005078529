import React from "react";
import { Switch, Route, Redirect } from "react-router";
import Pages from "./Pages";
import Page from "./Page";

const PagesRoutes: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path="/innhold"
        render={() => <Redirect to="/innhold/sider" />}
      />
      <Route exact path="/innhold/sider" component={Pages} />
      <Route
        exact
        path="/innhold/kategorier"
        render={(props) => (
          <Pages key={props.match.url} {...props} isCategory />
        )}
      />
      <Route
        exact
        path="/innhold/produktsider"
        render={(props) => (
          <Pages key={props.match.url} {...props} isProductPage />
        )}
      />
      <Route
        path="/innhold/sider/:page"
        render={(props) => <Page key={props.match.url} {...props} />}
      />
      <Route
        path="/innhold/kategorier/:page"
        render={(props) => (
          <Page key={props.match.params.page} {...props} isCategory />
        )}
      />
      <Route
        path="/innhold/produktsider/:page"
        render={(props) => (
          <Page key={props.match.params.page} {...props} isProductPage />
        )}
      />
    </Switch>
  );
};

export default PagesRoutes;
