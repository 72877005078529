import React from "react";
import { FaCookieBite } from "react-icons/fa";
import { CookieDefinition } from "@ludens-reklame/rubics-sdk";
import { Service } from "../../../util/api";
import localize from "../../../util/localize";
import { consentCategory } from "../../../constants/localizations";
import Tabler from "../../../components/Tabler/Tabler";
import Crumb from "../../../components/Crumb/Crumb";
import Text from "../../../style-guide/Text/Text";

const DefaultCookies: React.FC = () => {
  return (
    <Tabler
      tight
      title={
        <Text element="h1" variant="display3">
          <Crumb url="/innstillinger/informasjonskapsler">
            Informasjonskapsler
          </Crumb>
          Innebygde
        </Text>
      }
      service={Service.Storefront}
      queryParams={{
        onlyDefault: true
      }}
      emptyState={{
        title: "Ingen cookies enda",
        icon: <FaCookieBite />,
        text: "Du er lovpålagt å informere brukere om bruk av cookies. Dette gjøres automatisk av Rubics for de innebygde cookiene, men du må selv legge inn cookies fra tredjeparter du bruker."
      }}
      url={["cookie-dialog/definitions"]}
      columns={[
        {
          Header: "Navn",
          accessor: "name"
        },
        {
          Header: "Formål",
          accessor: "purpose"
        },
        {
          Header: "Samtykkekategori",
          accessor: "consentCategory"
        },
        {
          Header: "Utløpstid",
          accessor: "expiry"
        },
        {
          Header: "Leverandør",
          accessor: "vendor"
        }
      ]}
      createRow={(cookie: CookieDefinition) => ({
        name: <pre>{cookie.name}</pre>,
        purpose: <Text variant="body3">{cookie.purpose}</Text>,
        consentCategory: (
          <Text variant="body3">
            {cookie.consentCategories
              .map((c) => localize(consentCategory, c))
              .join(", ")}
          </Text>
        ),
        expiry: <Text variant="body3">{cookie.expiry}</Text>,
        vendor: <Text variant="body3">{cookie.vendor}</Text>
      })}
    />
  );
};

export default DefaultCookies;
