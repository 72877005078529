import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineForm } from "react-icons/ai";
import { Form } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import Tabler from "../../components/Tabler/Tabler";
import { Dotter, Dot } from "../../style-guide/Dotter/Dotter";
import { ButtonLink } from "../../style-guide/Button/Button";

interface Props {}

const Forms: React.FC<Props> = () => {
  return (
    <Tabler
      title="Skjemaer"
      actions={<ButtonLink to="skjemaer/opprett">Opprett skjema</ButtonLink>}
      emptyState={{
        title: "Opprett ditt første skjema",
        icon: <AiOutlineForm />,
        text: "Skjemaer lar deg samle inn informasjon fra dine besøkende.",
        action: <ButtonLink to="skjemaer/opprett">Opprett skjema</ButtonLink>
      }}
      url={["forms"]}
      columns={[
        {
          Header: "Navn",
          accessor: "name",
          verticalAlign: "middle"
        }
      ]}
      createRow={(form: Form) => ({
        ...form,
        name: <TitleRow form={form} />
      })}
    />
  );
};

interface TitleRowProps {
  form: Form;
}

const TitleRow: React.FC<TitleRowProps> = ({ form }) => {
  return (
    <>
      <Text variant="body2">
        <Link to={`/skjemaer/${form._id}`}>{form.name}</Link>
      </Text>
      <Text variant="body3">
        <Dotter>
          <Dot>
            <Text element="span" variant="body3">
              {form.internalDescription || "Mangler beskrivelse"}
            </Text>
          </Dot>
        </Dotter>
      </Text>
    </>
  );
};

export default Forms;
