import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw, EditorState } from "draft-js";
import safeDraft from "../../util/safeDraft";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

interface Props {
  data?: string;
  readOnly?: boolean;
  onChange: (data: string) => any;
}

const Drafter: React.FC<Props> = ({ data, readOnly, onChange }) => {
  const [editorState, setEditorState] = useState(createEditorState(data));
  const [previousContentState, setPreviousContentState] = useState<
    string | undefined
  >(undefined);

  return (
    <Editor
      toolbar={createDrafterToolbarConfig()}
      readOnly={readOnly}
      editorState={editorState}
      stripPastedStyles
      onEditorStateChange={(state) => {
        setEditorState(state);
      }}
      onContentStateChange={(content) => {
        const contentString = JSON.stringify(content);

        if (contentString !== previousContentState) {
          onChange(contentString);
          setPreviousContentState(contentString);
        }
      }}
    />
  );
};

function createDrafterToolbarConfig() {
  return {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "list",
      "textAlign",
      "colorPicker",
      "link",
      "embedded",
      "emoji",
      "image",
      "remove",
      "history"
    ]
  };
}

function createEditorState(data?: string): EditorState {
  return !!data
    ? EditorState.createWithContent(convertFromRaw(safeDraft(data)))
    : EditorState.createEmpty();
}

export default Drafter;
export { createDrafterToolbarConfig };
