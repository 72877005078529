import React from "react";
import { Switch, Route } from "react-router";
import { Labels } from "./Labels";
import { Label } from "./Label";

const LabelRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/butikk/etiketter" component={Labels} />
      <Route
        path="/butikk/etiketter/:label"
        render={(props) => <Label key={props.match.params.label} {...props} />}
      />
    </Switch>
  );
};

export default LabelRoutes;
