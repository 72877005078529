import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Button,
  ButtonList,
  ButtonStyles,
  ButtonProps
} from "@ludens-reklame/rubics-theme/dist/components/Button";

const ButtonLink = styled(Link)<ButtonProps>`
  ${ButtonStyles}
`;

const ButtonExternalLink = styled.a`
  ${ButtonStyles}
`;

export { Button, ButtonLink, ButtonExternalLink, ButtonList };
