import React, { useContext } from "react";
import { AiOutlineEdit, AiOutlineMail } from "react-icons/ai";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { EmailTemplate } from "../../../types/apiResponses";
import { SiteContext } from "../../../context/Site";
import { useSearch } from "../../../hooks/useApi";
import OrderTemplateEdit from "./OrderTemplates/OrderTemplateEdit";
import OrderTemplatesSelection from "./OrderTemplates/OrderTemplatesSelection";
import Crumb from "../../../components/Crumb/Crumb";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";
import Fader from "../../../style-guide/Fader/Fader";
import Text from "../../../style-guide/Text/Text";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import { Table, Th, Td, Tr } from "../../../style-guide/Table/Table";
import { Button, ButtonList } from "../../../style-guide/Button/Button";

const EmailTemplateOverview: React.FC = () => {
  const siteContext = useContext(SiteContext);
  const { spawnModal, despawnModal } = useModal();

  const { results, loading } = useSearch<EmailTemplate>({
    endpoint: "email-templates/search",
    fetchOnMount: true
  });

  const selectedTemplate = results.find(
    (f) => f._id === siteContext.data.emailTemplate
  );

  return (
    <Fader>
      <Block hugTop>
        <Flex>
          <FlexKid flex={1}>
            <Text element="h1" variant="display3">
              <Crumb url="/innstillinger">Innstillinger</Crumb>
              E-postmaler
            </Text>
          </FlexKid>
        </Flex>
      </Block>

      <BusyBoy busy={loading}>
        <Card>
          <Table>
            <thead>
              <Tr>
                <Th>Maltype</Th>
                <Th align="left">Mal</Th>
                <Th align="right">Handlinger</Th>
              </Tr>
            </thead>
            <tbody>
              <Tr>
                <Td verticalAlign="middle">Ordrekvittering</Td>
                <Td verticalAlign="middle" align="left">
                  {selectedTemplate ? (
                    <>
                      <Text>{selectedTemplate.displayName}</Text>
                    </>
                  ) : (
                    "System-mal"
                  )}
                </Td>

                <Td verticalAlign="middle" align="right">
                  <ButtonList align="right">
                    {selectedTemplate && (
                      <Button
                        outlined
                        onClick={() =>
                          spawnModal(
                            <OrderTemplateEdit
                              template={selectedTemplate}
                              despawnModal={despawnModal}
                            />
                          )
                        }
                      >
                        <AiOutlineEdit />
                        Rediger mal
                      </Button>
                    )}
                    <Button
                      outlined
                      onClick={() =>
                        spawnModal(
                          <OrderTemplatesSelection
                            templates={results}
                            selectedTemplate={selectedTemplate}
                            despawnModal={despawnModal}
                          />
                        )
                      }
                    >
                      <AiOutlineMail /> Endre mal
                    </Button>
                  </ButtonList>
                </Td>
              </Tr>
            </tbody>
          </Table>
        </Card>
      </BusyBoy>
    </Fader>
  );
};

export default EmailTemplateOverview;
