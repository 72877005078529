import React, { useEffect } from "react";
import { RouteComponentProps, Prompt, Switch, Route } from "react-router-dom";
import {
  BookingService,
  BookingServiceResourceRef
} from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import Form from "../../style-guide/Inputs/Form";
import Fader from "../../style-guide/Fader/Fader";
import Block from "../../style-guide/Block/Block";
import usePublisher from "../../hooks/usePublisher";
import PublisherHelpers from "../../util/PublisherHelpers";
import Section from "../../style-guide/Section/Section";
import ActionBar from "../../components/ActionBar/ActionBar";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import Crumb from "../../components/Crumb/Crumb";
import { LEAVE_PAGE_MESSAGE } from "../../constants/general";
import General from "./SubViews/Service/General";
import { BookingServiceType } from "../../constants/api";

interface Props
  extends RouteComponentProps<{
    service: string;
  }> {}

const Service: React.FC<Props> = ({ match }) => {
  const serviceId = match.params.service;
  const breadcrumbs = useBreadcrumbs();

  const publisher = usePublisher<BookingService>({
    id: serviceId,
    baseUrl: ["booking", "services"],
    baseDashboardUrl: "/booking/tjenester",
    initialData: {
      type: BookingServiceType.Appointment,
      name: "",
      slots: 1,
      duration: 30,
      availability: [],
      resources: [],
      events: []
    },
    cleanField: (key, value) => {
      if (key === "resources") {
        return ((value || []) as BookingServiceResourceRef[]).map((v) =>
          typeof v.ref === "string"
            ? v
            : {
                ...v,
                ref: v.ref._id
              }
        );
      }

      return value;
    },
    pathsToCopy: [
      {
        path: "type",
        label: "Type"
      },
      {
        path: "name",
        label: "Navn"
      },
      {
        path: "description",
        label: "Beskrivelse"
      },
      {
        path: "slots",
        label: "Kapasitet"
      },
      {
        path: "duration",
        label: "Varighet (minutter)"
      },
      {
        path: "roundDuration",
        label: "Rund opp varighet til nærmeste…"
      },
      {
        path: "requiredProductPurchase",
        label: "Kan kun bookes gjennom produktkjøp"
      },
      {
        path: "mergeBookings",
        label: "Flere kjøp samles i én booking"
      },
      {
        path: "price",
        label: "Pris"
      },
      {
        path: "pricePer",
        label: "Pris kalkuleres per…"
      },
      {
        path: "scheduleLimitDays",
        label: "Kan bookes frem i tid (dager)"
      },
      {
        path: "scheduleLimitHours",
        label: "Kan ikke bookes før (timer)"
      },
      {
        path: "availability",
        label: "Tilgjengelighet"
      },
      {
        path: "resources",
        label: "Ressurser"
      },
      {
        path: "events",
        label: "Begivenheter"
      }
    ]
  });

  useEffect(() => {
    breadcrumbs.swapEntry(serviceId, publisher.form.data.name);
  }, [breadcrumbs, publisher.form.data, serviceId]);

  return (
    <Form onSubmit={publisher.form.submit}>
      <Prompt
        when={publisher.form.hasMadeChanges}
        message={(location) => {
          if (location.pathname.includes(serviceId)) {
            return true;
          }

          return LEAVE_PAGE_MESSAGE;
        }}
      />
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/booking/tjenester">Tjenester</Crumb>
            {PublisherHelpers.getResourceTitle(
              publisher,
              "name",
              "Ny tjeneste"
            )}
          </Text>
        </Block>
        <Section>
          <Switch>
            <Route
              exact
              path={publisher.dashboardUrl}
              render={() => <General publisher={publisher} />}
            />
          </Switch>
        </Section>
      </Fader>
      <ActionBar publisher={publisher} destroy />
    </Form>
  );
};

export default Service;
