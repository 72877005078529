import { useMemo, useState } from "react";

type RefType = HTMLDivElement | null;

export interface UsePositioningInterface {
  setTargetRef: (ref: RefType) => void;
  setSelfRef: (ref: RefType) => void;
  top: number;
  left: number;
  width: number;
}

export interface UsePositioningOpts {
  offsetTop?: number;
  offsetLeft?: number;
  offsetTargetHeight?: boolean;
  useScrollPosition?: boolean;
}

function usePositioning(opts: UsePositioningOpts): UsePositioningInterface {
  const [targetRef, setTargetRef] = useState<RefType>(null);
  const [selfRef, setSelfRef] = useState<RefType>(null);

  const position = useMemo(() => {
    const windowHeight = window.innerHeight;
    const scrollY = opts.useScrollPosition ? window.scrollY : 0;
    const scrollX = opts.useScrollPosition ? window.scrollX : 0;
    const targetDomRect = targetRef?.getBoundingClientRect();
    const selfDomRect = selfRef?.getBoundingClientRect();
    const targetY = targetDomRect?.y || 0;
    const targetX = targetDomRect?.x || 0;

    let selfHeight = selfDomRect?.height || 0;

    const bottomPosition = targetY + selfHeight;

    let top = scrollY + targetY;
    let left = scrollX + targetX;

    if (bottomPosition > windowHeight) {
      top = windowHeight - selfHeight;
    }

    top = top + (opts.offsetTop || 0);
    left = left + (opts.offsetLeft || 0);

    if (opts.offsetTargetHeight) {
      top = top + (targetDomRect?.height || 0);
    }

    return {
      top: top > 0 ? top : 0,
      left: left > 0 ? left : 0,
      width: targetDomRect?.width || 0
    };
  }, [targetRef, selfRef, opts]);

  return {
    setTargetRef,
    setSelfRef,
    top: position.top,
    left: position.left,
    width: position.width
  };
}

export default usePositioning;
