export enum Themes {
  Commie = "commie"
}

export enum Permissions {
  None = "none",
  Visitor = "visitor",
  InternalVisitor = "internalVisitor",
  Preview = "preview",
  Reader = "reader",
  Contributor = "contributor",
  Admin = "admin",
  ApiReader = "apiReader",
  ApiWriter = "apiWriter",
  ApiAdmin = "apiAdmin"
}

export enum PriceType {
  Normal = "normal",
  Discount = "discount"
}

export enum SerializedType {
  Page = "page",
  Category = "category",
  ProductPage = "productPage",
  Product = "product",
  Order = "order",
  App = "app",
  Template = "template",
  Component = "component"
}

export enum DiscountType {
  Automatic = "automatic",
  Code = "code"
}

export enum DiscountOfferType {
  Percentage = "percentage",
  Fixed = "fixed",
  NewPrice = "newPrice"
}

export enum DiscountAppliesToType {
  All = "all",
  Products = "products",
  Categories = "categories",
  AllShipping = "allShipping",
  ShippingClasses = "shippingClasses",
  ShippingClassesExcluded = "shippingClassesExcluded"
}

export enum DiscountCartLimitSelection {
  Cheapest = "cheapest",
  MostExpensive = "mostExpensive",
  First = "first",
  Last = "last"
}
export enum AttributeTemplateAppliesToType {
  Products = "products",
  Categories = "categories"
}

export enum ShippingType {
  FlatRate = "flatRate",
  Pickup = "pickup",
  Free = "free"
}

export enum ShippingClassCostCalculation {
  Class = "class",
  Order = "order"
}

export enum TaxStatus {
  Taxable = "taxable",
  NonTaxable = "nonTaxable"
}

export enum OrderStatus {
  Confirmed = "confirmed",
  Pending = "pending",
  Finished = "finished",
  Processing = "processing",
  Canceled = "canceled",
  Failed = "failed"
}

export enum PaymentStatus {
  Manual = "manual",
  Pending = "pending",
  Reserved = "reserved",
  Paid = "paid",
  PartiallyPaid = "partiallyPaid",
  Refunded = "refunded",
  PartiallyRefunded = "partiallyRefunded",
  Voided = "voided"
}

export enum ExtendedStatType {
  CreatedSite = "createdSite",
  PublishedPage = "publishedPage",
  PublishedProduct = "publishedProduct",
  InstalledApp = "installedApp",
  UninstalledApp = "uninstalledApp",
  Revision = "revision",
  SystemMessage = "systemMessage"
}

export enum RevisionType {
  Site = "site",
  ThemeSettings = "themeSettings",
  GlobalProps = "globalProps",
  Page = "page",
  Product = "product",
  Template = "template",
  Order = "order",
  Attribute = "attribute",
  ProductTemplate = "productTemplate",
  Discount = "discount",
  Component = "component"
}

export enum StockStatus {
  InStock = "inStock",
  OutOfStock = "outOfStock",
  OnBackOrder = "onBackOrder"
}

export enum Backorders {
  No = "no",
  Yes = "yes",
  Notify = "notify"
}

export enum UserCreation {
  Invite = "invite",
  EmailDomain = "emailDomain"
}

export enum CustomerCreation {
  Public = "public",
  Invite = "invite"
}

export enum CompanyRole {
  Employee = "employee",
  Admin = "admin"
}

export enum EntityStatus {
  Inactive = "inactive",
  Active = "active"
}

export enum EntityVisibility {
  Public = "public",
  Password = "password"
}

export enum OrderType {
  Order = "order",
  Quote = "quote"
}

export enum DomainValidationStatus {
  Pending = "PENDING_VALIDATION",
  TimedOut = "VALIDATION_TIMED_OUT",
  Issued = "ISSUED"
}

export enum CatalogType {
  Product = "product",
  Content = "content"
}

export enum OrderPaymentStatus {
  Waiting = "waiting",
  Processing = "processing",
  Failed = "failed",
  Succeeded = "succeeded",
  Voided = "voided"
}

export enum BookingServiceType {
  Appointment = "appointment",
  Event = "event"
}

export enum CatalogPriceType {
  Percentage = "percentage",
  Fixed = "fixed",
  NewPrice = "newPrice",
  ByAppointment = "byAppointment"
}

export enum BookingDurationUnit {
  Minute = "minute",
  TenMinute = "tenMinute",
  QuarterHour = "quarterHour",
  HalfHour = "halfHour",
  Hour = "hour",
  Day = "day"
}
