import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Label as ILabel, Media } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import Form from "../../style-guide/Inputs/Form";
import Block from "../../style-guide/Block/Block";
import usePublisher from "../../hooks/usePublisher";
import PublisherHelpers from "../../util/PublisherHelpers";
import Section from "../../style-guide/Section/Section";
import ActionBar from "../../components/ActionBar/ActionBar";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import Crumb from "../../components/Crumb/Crumb";
import Card from "../../style-guide/Card/Card";
import createPublisherInputField from "../../util/createPublisherInputField";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import { ProductLabel } from "../../components/ProductLabel/ProductLabel";
import Ruler from "../../style-guide/Ruler/Ruler";

interface Props
  extends RouteComponentProps<{
    label: string;
  }> {}

export const Label: React.FC<Props> = ({ match }) => {
  const labelId = match.params.label;
  const breadcrumbs = useBreadcrumbs();
  const [tempImage, setTempImage] = useState<Media | null>(null);
  const publisher = usePublisher<ILabel>({
    id: labelId,
    baseUrl: ["labels"],
    baseDashboardUrl: "/butikk/etiketter",
    initialData: {},
    pathsToCopy: [
      {
        path: "name",
        label: "Navn"
      },
      {
        path: "color",
        label: "Farge"
      },
      {
        path: "textColor",
        label: "Tekstfarge"
      },
      {
        path: "text",
        label: "Rikt innhold"
      },
      {
        path: "image",
        label: "Bilde"
      }
    ]
  });

  useEffect(() => {
    breadcrumbs.swapEntry(labelId, publisher.form.data.name);
  }, [breadcrumbs, publisher.form.data, labelId]);

  return (
    <Form onSubmit={publisher.form.submit}>
      <Block hugTop hugBottom>
        <Text element="h1" variant="display3" gutterBottom>
          <Crumb url="/butikk/etiketter">Etiketter</Crumb>
          {PublisherHelpers.getResourceTitle(publisher, "name", "Ny etikett")}
        </Text>
      </Block>
      <Section>
        <Flex align="flex-start">
          <FlexKid flex={1}>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Generelt
                  </Text>
                </Section>
                {createPublisherInputField(publisher, {
                  path: "name",
                  label: "Navn",
                  type: "text",
                  required: true
                })}
                {createPublisherInputField(publisher, {
                  path: "color",
                  label: "Farge",
                  type: "color"
                })}
                {createPublisherInputField(publisher, {
                  path: "textColor",
                  label: "Tekstfarge",
                  type: "color"
                })}
                {createPublisherInputField(publisher, {
                  path: "text",
                  label: "Rikt innhold",
                  type: "draft",
                  syncState: publisher.form.syncState
                })}
                {createPublisherInputField(publisher, {
                  path: "image",
                  label: "Bilde",
                  type: "media",
                  setField: (media) => {
                    if (!media || media.ref === "") {
                      publisher.form.setField({
                        path: "image",
                        value: null
                      });
                    } else {
                      publisher.form.setField({
                        path: "image",
                        value: media.ref
                      });

                      setTempImage(media);
                    }
                  },
                  value:
                    !!publisher.form.data.image &&
                    typeof publisher.form.data.image !== "string"
                      ? {
                          ref: publisher.form.data.image._id,
                          url: publisher.form.data.image.secure_url,
                          alt: "Logo"
                        }
                      : tempImage
                })}
              </Block>
            </Card>
          </FlexKid>
          <FlexKid spaceLeft width="24rem">
            <Card outlined>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Forhåndsvisning
                  </Text>
                </Section>
                <ProductLabel label={publisher.form.data} />
                <Ruler gutterTop />
                <Text variant="body3">
                  Merk: Det er opp til nettsiden din å tegne opp etiketten, så
                  det visuelle uttrykket kan endre seg fra forhåndsvsisningen
                  til slik det ser ut på nettsiden.
                </Text>
              </Block>
            </Card>
          </FlexKid>
        </Flex>
      </Section>
      <ActionBar publisher={publisher} destroy />
    </Form>
  );
};
