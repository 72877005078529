import draftJsToHtml from "draftjs-to-html";

function draftToHtml(data?: string): string {
  if (!data) {
    return "";
  }

  const parsed = jsonParser(data);

  const html = draftJsToHtml(parsed);

  if (html === "<p></p>\n") {
    return "";
  }

  return html;
}

function jsonParser(json?: string): any | null {
  if (!json) {
    return "";
  }

  try {
    return JSON.parse(json);
  } catch (e) {
    return "";
  }
}

export default draftToHtml;
