import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

const InlineActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.xs};
  margin: ${(props) => `0 0 ${props.theme.spacing.small} 0`};
`;

const InlineActionsSubList = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${(props) => props.theme.spacing.medium};
  border-left: 1px solid ${(props) => props.theme.colors.border};
`;

const InlineActionStyles = css<{ hideUntilActive?: boolean }>`
  display: inline-flex;
  align-items: center;
  color: ${(props) => props.theme.colors.textSecondary};
  text-decoration: none;
  padding: ${(props) => `0 calc(${props.theme.spacing.medium} * 0.5)`};
  font-weight: 600;
  border-radius: 8px;
  height: 42px;

  svg {
    margin-right: ${(props) => props.theme.spacing.xs};
  }

  &.active,
  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundInput};
    text-decoration: none;
  }

  &:hover {
    color: ${(props) => props.theme.colors.textPrimary};
  }

  &.active {
    color: ${(props) => props.theme.colors.textPrimary};
  }

  ${(props) =>
    props.hideUntilActive &&
    css`
      display: none;

      &.active {
        display: inline-flex;
      }
    `}
`;

const InlineActionButton = styled.button<{ hideUntilActive?: boolean }>`
  ${InlineActionStyles};
  border: 0;
  font-size: 100%;
  font-family: inherit;
  background: none;
  cursor: pointer;
`;

const InlineActionLink = styled(NavLink)<{
  highlight?: boolean;
  warning?: boolean;
  hideUntilActive?: boolean;
}>`
  ${InlineActionStyles};

  ${(props) =>
    props.highlight &&
    css`
      background-color: ${props.theme.colors.primary};
      color: ${props.theme.colors.textOnPrimary};

      &:hover {
        background-color: ${props.theme.colors.primary};
        color: ${props.theme.colors.textOnPrimary};
      }
    `}

  ${(props) =>
    props.warning &&
    css`
      background-color: ${props.theme.colors.warning};

      &:hover {
        background-color: ${props.theme.colors.warning};
      }
    `}
`;

const InlineActionAnchor = styled.a`
  ${InlineActionStyles};
`;

export {
  InlineActions,
  InlineActionsSubList,
  InlineActionButton,
  InlineActionLink,
  InlineActionAnchor
};
