import React, { useState, ReactNode } from "react";
import { createPortal } from "react-dom";
import styled, { css } from "styled-components";
import { FaMobileAlt, FaDesktop, FaExpand } from "react-icons/fa";
import storage from "../../util/storage";
import { ButtonList, Button } from "../../style-guide/Button/Button";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ContainerProps {
  responsive?: boolean;
  width?: string;
  height?: string;
  initialHeight?: string;
  fullscreen?: boolean;
}

const Container = styled.div<ContainerProps>`
  background-color: ${(props) => props.theme.colors.backgroundVariant};
  box-shadow: ${(props) => props.theme.shadows.large};
  border-radius: 8px;
  transition: all 0.12s ease-in-out;
  width: 100%;

  ${(props) =>
    props.fullscreen &&
    css`
      position: fixed;
      z-index: 4;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      border-radius: 0;
      box-shadow: none;
    `};
`;

const Controller = styled.div`
  padding: ${(props) =>
    `${props.theme.spacing.xs} ${props.theme.spacing.medium}`};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderDim};
  display: flex;
  justify-content: center;
`;

const ActionButtons = styled.div`
  display: flex;
  margin-right: auto;
`;

const App = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.initialHeight || "calc(100vh - 105px)"};

  ${(props) =>
    props.fullscreen &&
    css`
      height: calc(100vh - 49px);
    `};
`;

const IFrame = styled.iframe<ContainerProps>`
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
  height: 100%;
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: all 0.12s ease-in-out;
  box-shadow: none;

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
      box-shadow: 0 0 32px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
    `};

  ${(props) =>
    props.responsive &&
    css`
      height: ${props.initialHeight || "calc(100vh - 105px)"};
    `};

  ${(props) =>
    props.fullscreen &&
    css`
      height: calc(100vh - 49px);
      width: 100vw;
      border-radius: 0;
      box-shadow: none;
    `};
`;

type PreviewMode = "mobile" | "desktop" | "full";

interface Props {
  id: string;
  src: string;
  mode?: PreviewMode;
  actionButtons?: ReactNode;
  height?: string;
}

const Preview: React.FC<Props> = ({ id, src, mode, actionButtons, height }) => {
  const [modeState, setMode] = useState(
    (storage.get("previewMode") as PreviewMode) || mode || "desktop"
  );

  const content = (
    <Wrapper>
      <Container fullscreen={modeState === "full"}>
        <Controller>
          {actionButtons && <ActionButtons>{actionButtons}</ActionButtons>}
          <ButtonList align="center">
            <Button
              type="button"
              alternate
              circular
              outlined
              smaller
              active={modeState === "mobile"}
              onClick={() => {
                setMode("mobile");
                storage.set("previewMode", "mobile");
              }}
            >
              <FaMobileAlt />
            </Button>
            <Button
              type="button"
              alternate
              circular
              outlined
              smaller
              active={modeState === "desktop"}
              onClick={() => {
                setMode("desktop");
                storage.set("previewMode", "desktop");
              }}
            >
              <FaDesktop />
            </Button>
            <Button
              type="button"
              alternate
              circular
              outlined
              smaller
              active={modeState === "full"}
              onClick={() => {
                setMode("full");
                storage.set("previewMode", "full");
              }}
            >
              <FaExpand />
            </Button>
          </ButtonList>
        </Controller>
        <App fullscreen={modeState === "full"} initialHeight={height}>
          <IFrame
            id={id}
            src={src}
            responsive={modeState === "desktop"}
            initialHeight={height}
            height={modeState !== "desktop" ? "575px" : undefined}
            fullscreen={modeState === "full"}
            width={modeState !== "desktop" ? "375px" : undefined}
          />
        </App>
      </Container>
    </Wrapper>
  );

  if (modeState === "full") {
    return createPortal(content, document.body);
  }

  return content;
};

export default Preview;
