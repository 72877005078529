import React, { useContext, useMemo } from "react";
import styled, { css } from "styled-components";
import {
  NotificationContext,
  NotificationType
} from "../../context/Notification";
import { FADE_IN_TOP, FADE_OUT_BOTTOM } from "../../style-guide/Fader/Fader";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;
  display: flex;
  padding: ${(props) => props.theme.spacing.medium};
  flex-direction: column;
`;

interface Props {
  despawn?: boolean;
  type?: NotificationType;
}

const Notification = styled.div<Props>`
  background-color: ${(props) => props.theme.colors.textPrimary};
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.shadows.normal};
  animation: ${FADE_IN_TOP} 0.24s 0.24s ease-out both;
  display: flex;
  flex-direction: row;

  * {
    color: ${(props) => props.theme.colors.textInvertedPrimary};
  }

  &:not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing.small};
  }

  ${(props) =>
    props.type === "error" &&
    css`
      background-color: ${(props) => props.theme.colors.warning};

      * {
        color: white;
      }
    `};

  ${(props) =>
    props.despawn &&
    css`
      animation: ${FADE_OUT_BOTTOM} 0.12s ease-out both;
    `};
`;

interface IconProps {
  type?: NotificationType;
}

const Icon = styled.div<IconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  font-size: ${(props) => props.theme.sizes.plusOne};
  padding: 0 0 0 ${(props) => props.theme.spacing.small};

  * {
    color: ${(props) => props.theme.colors.textInvertedPrimary} !important;
  }

  ${(props) =>
    props.type === "error" &&
    css`
      background-color: ${(props) => props.theme.colors.warning};

      * {
        color: white !important;
      }
    `};
`;

const Content = styled.div`
  padding: ${(props) => props.theme.spacing.small};
  width: 20rem;
`;

const Notifications: React.FC<Props> = () => {
  const context = useContext(NotificationContext);
  const stack = context.notifications;
  const hasNotifications = useMemo(() => stack.length > 0, [stack]);

  return hasNotifications ? (
    <>
      <Wrapper>
        {stack.map((n) => (
          <Notification key={n.id} despawn={n.fadeOut} type={n.type}>
            {n.icon && <Icon type={n.type}>{n.icon}</Icon>}
            <Content>{n.content}</Content>
          </Notification>
        ))}
      </Wrapper>
    </>
  ) : null;
};

export default Notifications;
