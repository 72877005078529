import React, { useState } from "react";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import Form from "../../../style-guide/Inputs/Form";
import {
  Modal,
  ModalBody,
  ModalActions
} from "../../../style-guide/Modal/Modal";
import Block from "../../../style-guide/Block/Block";
import { ButtonList, Button } from "../../../style-guide/Button/Button";
import { Product } from "../../../types/apiResponses";
import useApiUrl from "../../../hooks/useApiUrl";
import { useApi } from "../../../hooks/useApi";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";
import { FieldSetter } from "../../../hooks/useForm";
import { Radios, Radio } from "../../../style-guide/Inputs/Radio";

type CreateRule = "CREATE" | "DUPLICATE";

interface Props {
  product: Product;
  setField: FieldSetter;
  onSelect: (action: CreateRule, product?: Product) => any;
}

const ConnectionNewModal: React.FC<Props> = ({
  product,
  setField,
  onSelect
}) => {
  const { despawnModal } = useModal();
  const [createRule, setCreateRule] = useState<CreateRule>("CREATE");
  const duplicateEndpoint = useApiUrl(["products", "clone"]);

  const duplicate = useApi<Product | null>({
    endpoint: duplicateEndpoint,
    method: "POST",
    initialData: null,
    body: {
      id: product._id
    },
    onSuccess: (product) => {
      if (product) {
        onSelect(createRule, product);
      }
    }
  });

  return (
    <BusyBoy busy={duplicate.loading}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();

          if (!product.master) {
            setField({
              path: "master",
              value: product.name,
              submit: true
            });
          }

          if (createRule === "CREATE") {
            onSelect(createRule);
          } else {
            duplicate.fetch();
          }

          despawnModal();
        }}
      >
        <Modal maxWidth="38rem">
          <ModalBody>
            <Block>
              <Radios>
                <Radio
                  value="CREATE"
                  checked={createRule === "CREATE"}
                  onChange={() => setCreateRule("CREATE")}
                  label="Lag nytt, tomt produkt"
                />
                <Radio
                  value="DUPLICATE"
                  checked={createRule === "DUPLICATE"}
                  onChange={() => setCreateRule("DUPLICATE")}
                  label={
                    <>
                      Dupliser <strong>{product.name}</strong>
                    </>
                  }
                />
              </Radios>
            </Block>
          </ModalBody>
          <ModalActions>
            <ButtonList align="right">
              <Button
                type="button"
                outlined
                onClick={(e) => {
                  despawnModal();
                }}
              >
                Avbryt
              </Button>
              <Button type="submit">Lag produkt</Button>
            </ButtonList>
          </ModalActions>
        </Modal>
      </Form>
    </BusyBoy>
  );
};

export default ConnectionNewModal;
