import { useApi } from "./useApi";
import { PreviewToken } from "../types/apiResponses";
import useApiUrl from "./useApiUrl";

function usePreviewToken(): undefined | string {
  const endpoint = useApiUrl(["preview-token"]);

  const { resource } = useApi<PreviewToken | null>({
    endpoint,
    fetchOnMount: true,
    initialData: null
  });

  return resource ? resource.token : undefined;
}

export default usePreviewToken;
