import React, { useCallback } from "react";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroller";
import { useSearch } from "../../hooks/useApi";
import { Revision } from "../../types/apiResponses";
import useApiUrl from "../../hooks/useApiUrl";
import BusyBoy from "../BusyBoy/BusyBoy";
import Block from "../../style-guide/Block/Block";
import { Elements, Element } from "../../style-guide/Elements/Elements";
import { RevisionType } from "../../constants/api";
import Text from "../../style-guide/Text/Text";
import Section from "../../style-guide/Section/Section";
import EmptyState from "../../style-guide/EmptyState/EmptyState";

interface Props {
  type: RevisionType;
  reference: string;
  getScrollParent: () => HTMLElement | null;
}

const Revisions: React.FC<Props> = ({ type, reference, getScrollParent }) => {
  const url = useApiUrl(["revisions"]);

  const opts = {
    endpoint: url,
    queryParams: {
      type,
      reference
    }
  };

  const search = useSearch<Revision>(opts);

  const createUrl = useCallback((revision: string) => {
    return `${window.location.pathname}?revision=${revision}`;
  }, []);

  return (
    <Block>
      <Section hugTop>
        <Text variant="title">Versjonslogg</Text>
      </Section>
      <InfiniteScroll
        pageStart={0}
        loadMore={() => {
          search.search({
            ...opts,
            paginate: true
          });
        }}
        hasMore={search.hasMore}
        useWindow={false}
        getScrollParent={getScrollParent}
      >
        <BusyBoy busy={search.loading}>
          {search.hasSearched && search.results.length > 0 ? (
            <Elements>
              {search.results.map((r) => (
                <Element
                  key={r._id}
                  href={createUrl(r._id)}
                  subText={`Av ${
                    r.author?.name || r.author?.email || "Ukjent"
                  } den ${moment(r.timestamp).format(
                    "D[.] MMMM Y [kl.] HH:mm"
                  )}`}
                >
                  {r._id}
                </Element>
              ))}
            </Elements>
          ) : (
            <EmptyState title="Ingen publiserte versjoner funnet!" />
          )}
        </BusyBoy>
      </InfiniteScroll>
    </Block>
  );
};

export default Revisions;
