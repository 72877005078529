import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineCalendar } from "react-icons/ai";
import { BookingService } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import { ButtonLink } from "../../style-guide/Button/Button";
import Tabler from "../../components/Tabler/Tabler";

interface Props {}

const Services: React.FC<Props> = () => {
  return (
    <Tabler
      title="Tjenester"
      crumb={{
        label: "Booking",
        path: "/booking"
      }}
      actions={<ButtonLink to="tjenester/opprett">Opprett tjeneste</ButtonLink>}
      emptyState={{
        title: "Ingen tjenester enda",
        icon: <AiOutlineCalendar />,
        text: "For at du skal kunne tilby booking trenger du en tjeneste folk kan booke. Det kan være alt fra massasje til konserter.",
        action: <ButtonLink to="tjenester/opprett">Opprett tjeneste</ButtonLink>
      }}
      url={["booking", "services"]}
      columns={[
        {
          Header: "Tjeneste",
          accessor: "title"
        }
      ]}
      createRow={(service: BookingService) => ({
        ...service,
        title: <TitleRow service={service} />
      })}
    />
  );
};

interface RowProps {
  service: BookingService;
}

const TitleRow: React.FC<RowProps> = ({ service }) => {
  return (
    <>
      <Text variant="body2">
        <Link to={`/booking/tjenester/${service._id}`}>{service.name}</Link>
      </Text>
      <Text variant="body3">
        {service.description || "Mangler beskrivelse"}
      </Text>
    </>
  );
};

export default Services;
