import { useMemo } from "react";
import { SimplePage } from "@ludens-reklame/rubics-sdk";

interface Crumb {
  _id: string;
  label: string;
  slug: string;
}

interface ParentsImplementer {
  _id: string;
  title: string;
  slug: string;
  parents?: SimplePage[];
}

function useParents(
  element?: ParentsImplementer,
  includeSelf?: boolean
): Crumb[] {
  const crumbs = useMemo(() => {
    if (!element) {
      return [];
    }

    const crumbs = (element.parents || []).map((p) => ({
      _id: p._id,
      label: p.title,
      slug: p.slug
    }));

    if (includeSelf) {
      crumbs.push({
        _id: element._id,
        label: element.title,
        slug: element.slug
      });
    }

    return crumbs;
  }, [element, includeSelf]);

  return crumbs;
}

export default useParents;
