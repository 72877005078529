import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { GiReceiveMoney } from "react-icons/gi";
import { Subscription } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import Tabler from "../../components/Tabler/Tabler";
import { RequestData } from "../../util/api";
import Status from "../../style-guide/Status/Status";

interface Props {
  hideTitle?: boolean;
  query?: RequestData;
}

const Subscriptions: React.FC<Props> = ({ hideTitle, query }) => {
  return (
    <Tabler
      tight
      title="Abonnementer"
      crumb={{
        label: "Butikk",
        path: "/butikk"
      }}
      hideTitle={hideTitle}
      emptyState={{
        title: "Ingen abonnementer enda",
        icon: <GiReceiveMoney />,
        text: "Frykt ikke, når kundene får øynene opp for utvalget ditt vil abonnement strømme inn!"
      }}
      url={["subscriptions"]}
      queryParams={query}
      queryField="subscriptionNumber"
      queryLabel="Abonnementnummer"
      columns={[
        {
          Header: "Abonnement",
          accessor: "title",
          verticalAlign: "middle"
        },
        {
          Header: "Opprettet",
          accessor: "started",
          verticalAlign: "middle"
        },
        {
          Header: "Neste ordre",
          accessor: "nextOrder",
          verticalAlign: "middle"
        },
        {
          Header: "Aktiv",
          accessor: "active",
          verticalAlign: "middle",
          align: "center"
        }
      ]}
      createRow={(subscription: Subscription) => ({
        ...subscription,
        title: <TitleRow subscription={subscription} />,
        started: (
          <Text variant="body3">
            {moment(subscription.startedDate).format("D[.]M[.]YYYY")}
          </Text>
        ),
        nextOrder: (
          <Text variant="body3">
            {subscription.active
              ? moment(subscription.renewalDate).format("D[.]M[.]YYYY")
              : "N/A"}
          </Text>
        ),
        active: (
          <Status variant={subscription.active ? "good" : "neutral"}>
            {subscription.active ? "Ja" : "Nei"}
          </Status>
        )
      })}
    />
  );
};

interface TitleRowProps {
  subscription: Subscription;
}

const TitleRow: React.FC<TitleRowProps> = ({ subscription }) => {
  const customerLabel =
    typeof subscription.customer !== "string"
      ? subscription.customer.name || subscription.customer.email
      : undefined;

  return (
    <Text variant="body2">
      <Link to={`/butikk/abonnementer/${subscription._id}`}>
        #{subscription.subscriptionNumber} · {customerLabel || "Mangler kunde"}
      </Link>
    </Text>
  );
};

export default Subscriptions;
