import React, { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { Elements, Element } from "../../style-guide/Elements/Elements";
import Block from "../../style-guide/Block/Block";
import { ButtonList, Button } from "../../style-guide/Button/Button";
import { Modal } from "../../style-guide/Modal/Modal";
import Select from "../../style-guide/Inputs/Select";
import Label from "../../style-guide/Inputs/Label";
import TextInput from "../../style-guide/Inputs/TextInput";
import Text from "../../style-guide/Text/Text";
import Form from "../../style-guide/Inputs/Form";

interface OfflineListerItem {
  _id: string;
  value: string;
  label: string;
}

interface OfflineListerProps {
  selectLabel?: string;
  items?: OfflineListerItem[];
  values: string[];
  onChange: (values: string[]) => any;
  unique?: boolean;
  writeMode?: boolean;
  placeholder?: string;
  pattern?: string;
  patternDescription?: string;
  required?: boolean;
  readOnly?: boolean;
}

const OfflineLister: React.FC<OfflineListerProps> = ({
  selectLabel,
  items,
  values,
  unique,
  writeMode,
  onChange,
  placeholder,
  pattern,
  patternDescription,
  required,
  readOnly
}) => {
  const modal = useModal();

  return (
    <Elements>
      {values.map((v, k) => {
        const item = (items || []).find((i) => i.value === v);

        return (
          <Element
            key={v}
            href="#"
            readOnly={readOnly}
            onClick={(ev) => {
              ev.preventDefault();

              if (readOnly) {
                return;
              }

              modal.spawnModal(
                <OfflineListerModal
                  selectLabel={selectLabel}
                  unique={unique}
                  value={v}
                  items={items}
                  values={values}
                  writeMode={writeMode}
                  placeholder={placeholder}
                  pattern={pattern}
                  patternDescription={patternDescription}
                  required={required}
                  onSubmit={(value) =>
                    onChange(
                      values.map((_, kk) => {
                        if (kk === k) {
                          return value;
                        }

                        return v;
                      })
                    )
                  }
                  onDeleteClick={() => {
                    onChange(values.filter((_, kk) => kk !== k));
                  }}
                />
              );
            }}
          >
            {item ? item.label : v}
          </Element>
        );
      })}
      <Element
        rightIcon={<AiOutlinePlus />}
        href="#"
        readOnly={readOnly}
        onClick={(e) => {
          e.preventDefault();

          if (readOnly) {
            return;
          }

          modal.spawnModal(
            <OfflineListerModal
              selectLabel={selectLabel}
              unique={unique}
              items={items}
              values={values}
              writeMode={writeMode}
              onSubmit={(value) => onChange([...values, value])}
              placeholder={placeholder}
              pattern={pattern}
              patternDescription={patternDescription}
              required={required}
            />
          );
        }}
      >
        Legg til
      </Element>
    </Elements>
  );
};

interface OfflineListerModalProps {
  onSubmit: (value: string) => any;
  onDeleteClick?: () => any;
  selectLabel?: string;
  items?: OfflineListerItem[];
  value?: string;
  values?: string[];
  unique?: boolean;
  writeMode?: boolean;
  placeholder?: string;
  pattern?: string;
  patternDescription?: string;
  required?: boolean;
}

const OfflineListerModal: React.FC<OfflineListerModalProps> = ({
  onSubmit,
  onDeleteClick,
  selectLabel,
  items,
  value,
  values,
  unique,
  writeMode,
  placeholder,
  pattern,
  patternDescription,
  required
}) => {
  const [internalValue, setInternalValue] = useState(value);
  const modal = useModal();

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();

        if (!!internalValue) {
          onSubmit(internalValue);
          modal.despawnModal();
        }
      }}
    >
      <Modal maxWidth="30rem">
        <Block>
          <Label htmlFor="listerSelect">{selectLabel}</Label>
          {writeMode ? (
            <>
              <TextInput
                value={internalValue}
                onChange={(e) => setInternalValue(e.target.value)}
                placeholder={placeholder}
                pattern={pattern}
                title={patternDescription}
                required={required}
              />
              {patternDescription && (
                <Text gutterTop variant="body3">
                  {patternDescription}
                </Text>
              )}
            </>
          ) : (
            <Select
              value={internalValue ? internalValue : ""}
              onChange={(e) => {
                const item = (items || []).find(
                  (i) => i.value === e.target.value
                );

                if (item) {
                  setInternalValue(item.value);
                } else {
                  setInternalValue(undefined);
                }
              }}
            >
              <option value="" disabled>
                Vennligst velg…
              </option>
              {(items || [])
                //.filter((i) => !(values || []).includes(i.value))
                .map((i) => (
                  <option
                    key={i._id}
                    value={i.value}
                    disabled={unique && values?.includes(i.value)}
                  >
                    {i.label}
                  </option>
                ))}
            </Select>
          )}
          <ButtonList align="right" gutterTop>
            {!!onDeleteClick && (
              <Button
                type="button"
                outlined
                onClick={() => {
                  if (window.confirm("Er du sikker på at du vil slette?")) {
                    onDeleteClick();
                    modal.despawnModal();
                  }
                }}
              >
                Slett
              </Button>
            )}
            <Button
              type="button"
              outlined
              onClick={() => {
                modal.despawnModal();
              }}
            >
              Avbryt
            </Button>
            {!!internalValue && <Button type="submit">Lagre</Button>}
          </ButtonList>
        </Block>
      </Modal>
    </Form>
  );
};

export default OfflineLister;
