import React, {
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { ThemeContext } from "styled-components";
import BusyBoy from "../../components/BusyBoy/BusyBoy";
import Crumb from "../../components/Crumb/Crumb";
import { useApi } from "../../hooks/useApi";
import useApiUrl from "../../hooks/useApiUrl";
import Block from "../../style-guide/Block/Block";
import Card from "../../style-guide/Card/Card";
import EmptyState from "../../style-guide/EmptyState/EmptyState";
import Fader from "../../style-guide/Fader/Fader";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import { BiggerLoading } from "../../style-guide/Loading/Loading";
import Section from "../../style-guide/Section/Section";
import { Table, Td, Th, Tr } from "../../style-guide/Table/Table";
import Text from "../../style-guide/Text/Text";
import { CommerceStats } from "../../types/apiResponses";
import calculatePercentageGrowth from "../../util/calculatePercentageGrowth";
import createInputField from "../../util/createInputField";
import { currencyFormat, numberFormat } from "../../util/intl";

const OrdersByPeriod = React.lazy(
  () => import("../../components/Charts/StatGroupLineChart")
);

const Commerce: React.FC = () => {
  const themeContext = useContext(ThemeContext);
  const commerceEndpoint = useApiUrl(["stats", "commerce"]);
  const [fromDate, setFromDate] = useState(null as Date | null);
  const [toDate, setToDate] = useState(null as Date | null);

  const commerce = useApi<CommerceStats>({
    endpoint: commerceEndpoint,
    initialData: {
      ordersLast7Days: {
        currentPeriod: 0,
        lastPeriod: 0
      },
      ordersByPeriod: [],
      ordersByProduct: []
    }
  });

  useEffect(() => {
    commerce.fetch({
      endpoint: commerceEndpoint,
      queryParams: {
        fromDate,
        toDate
      }
    });
  }, [fromDate, toDate]);

  const percentageGrowth = useMemo(
    () =>
      calculatePercentageGrowth(
        commerce.resource.ordersLast7Days.currentPeriod,
        commerce.resource.ordersLast7Days.lastPeriod
      ),
    [commerce.resource]
  );

  return (
    <Fader>
      <Block hugTop>
        <Text element="h1" variant="display3">
          <Crumb url="/rapporter">Rapporter</Crumb>
          Butikk
        </Text>
      </Block>
      <BusyBoy>
        <Block hugTop>
          <Flex>
            <FlexKid>
              {createInputField({
                key: "fromDate",
                type: "time",
                label: "Fra-dato",
                hugTop: true,
                value: fromDate,
                onChange: (value) => setFromDate(value)
              })}
            </FlexKid>
            <FlexKid spaceLeft>
              {createInputField({
                key: "toDate",
                type: "time",
                label: "Til-dato",
                hugTop: true,
                value: toDate,
                onChange: (value) => setToDate(value)
              })}
            </FlexKid>
          </Flex>
        </Block>
        <Flex align="flex-start">
          <FlexKid flex={2}>
            <Section hugTop>
              <Card>
                <Block>
                  <Text element="h2" variant="title">
                    Omsetning per dag
                  </Text>
                </Block>
                {commerce.hasFetched &&
                  commerce.resource.ordersByPeriod.length < 2 && (
                    <Block hugTop>
                      <Card outlined>
                        <EmptyState
                          title="Mangler data"
                          text="Her er det dessverre ikke nok data til å vise en graf... enda!"
                        />
                      </Card>
                    </Block>
                  )}
                {commerce.resource.ordersByPeriod.length > 1 && (
                  <Block hugTop>
                    <Suspense
                      fallback={<BiggerLoading loading label="Laster graf…" />}
                    >
                      <OrdersByPeriod
                        valueLabel="Omsetning"
                        data={commerce.resource.ordersByPeriod}
                      />
                    </Suspense>
                  </Block>
                )}
              </Card>
            </Section>
            <Section>
              <Card>
                <Block>
                  <Text element="h2" variant="title">
                    Mest solgte produkter
                  </Text>
                </Block>
                {commerce.hasFetched &&
                  commerce.resource.ordersByProduct.length < 1 && (
                    <Block hugTop>
                      <Card outlined>
                        <EmptyState
                          title="Mangler data"
                          text="Her er det dessverre ikke nok data til å vise en graf... enda!"
                        />
                      </Card>
                    </Block>
                  )}
                {commerce.resource.ordersByProduct.length > 0 && (
                  <Table>
                    <thead>
                      <Tr>
                        <Th>SKU</Th>
                        <Th>Produkt</Th>
                        <Th align="right">Salg</Th>
                      </Tr>
                    </thead>
                    <tbody>
                      {commerce.resource.ordersByProduct.length > 0 ? (
                        commerce.resource.ordersByProduct.map((p) => (
                          <Tr key={p._id.sku}>
                            <Td>
                              {p._id.sku || (
                                <Text variant="body3">Mangler SKU</Text>
                              )}
                            </Td>
                            <Td>{p._id.name}</Td>
                            <Td align="right">
                              {numberFormat.format(p.count)}
                            </Td>
                          </Tr>
                        ))
                      ) : (
                        <Tr>
                          <Td colSpan={2}>
                            <Text>Ingen!</Text>
                          </Td>
                        </Tr>
                      )}
                    </tbody>
                  </Table>
                )}
              </Card>
            </Section>
          </FlexKid>
          <FlexKid flex={1} spaceLeft>
            <Card outlined>
              <Block>
                <Text variant="display3">
                  {currencyFormat.format(
                    commerce.resource.ordersLast7Days.currentPeriod
                  )}
                </Text>
                {commerce.resource.ordersLast7Days.currentPeriod > 0 && (
                  <Text
                    variant="title"
                    color={
                      percentageGrowth >= 0
                        ? themeContext.colors.good
                        : themeContext.colors.warning
                    }
                  >
                    {percentageGrowth > 0 && "+"}
                    {percentageGrowth === 0
                      ? "+∞"
                      : numberFormat.format(percentageGrowth)}
                    %
                  </Text>
                )}
                <Text element="h2" variant="subheading" gutterTop>
                  Omsetning siste 7 dager
                </Text>
              </Block>
            </Card>
          </FlexKid>
        </Flex>
      </BusyBoy>
    </Fader>
  );
};

export default Commerce;
