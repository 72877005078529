import React, { useContext, useEffect, useMemo } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import { BreadcrumbsContext, Crumb } from "../../context/Breadcrumbs";
import Block from "../../style-guide/Block/Block";
import Fader from "../../style-guide/Fader/Fader";

const Wrapper = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Item = styled.li`
  text-transform: capitalize;
  color: ${(props) => props.theme.colors.textSecondary};

  &:not(:last-child) {
    margin-right: ${(props) => props.theme.spacing.xs};
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  > * {
    display: flex;
    justify-content: center;
  }
`;

const Icon = styled.span`
  margin-left: ${(props) => props.theme.spacing.xs};
  font-size: ${(props) => props.theme.sizes.negOne};
  display: flex;
  align-items: center;
`;

interface Props {
  crumbs?: Crumb[];
  startAtIndex?: number;
}

const Breadcrumbs: React.FC<Props> = ({ crumbs, startAtIndex }) => {
  const location = useLocation();
  const breadcrumbs = useContext(BreadcrumbsContext);

  useEffect(() => {
    let path = "";

    breadcrumbs.setCrumbs([
      {
        slug: "index",
        path: "",
        label: "Hjem"
      },
      ...(crumbs ||
        location.pathname
          .split("/")
          .filter((p) => !!p)
          .map((p) => {
            const crumb = breadcrumbs.crumbs.find((c) => c.slug === p);

            path += `/${p}`;

            if (crumb) {
              return {
                ...crumb,
                path
              };
            }

            return {
              slug: p,
              label: p,
              path
            };
          }))
    ]);
  }, [crumbs, location.pathname, breadcrumbs.setCrumbs]);

  const usedCrumbs = useMemo<Crumb[]>(() => {
    if (typeof startAtIndex === "number") {
      return breadcrumbs.crumbs.slice(startAtIndex);
    }

    return breadcrumbs.crumbs;
  }, [breadcrumbs.crumbs, startAtIndex]);

  if (usedCrumbs.length === 0) {
    return null;
  }

  return (
    <Block hugTop hugBottom>
      <Wrapper>
        {usedCrumbs.map((c, k) => (
          <BreadcrumbItem
            key={c.slug}
            crumb={c}
            isLast={k + 1 === usedCrumbs.length}
          />
        ))}
      </Wrapper>
    </Block>
  );
};

interface BreadcrumbItemProps {
  crumb: Crumb;
  isLast: boolean;
}

const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({ crumb, isLast }) => {
  return (
    <Item>
      <Fader variant="left">
        <Link
          to={crumb.path}
          onClick={() => {
            if (typeof crumb.onClick === "function") {
              crumb.onClick();
            }
          }}
        >
          {crumb.label}
        </Link>
        {!isLast && (
          <Icon>
            <AiOutlineRight />
          </Icon>
        )}
      </Fader>
    </Item>
  );
};

export default Breadcrumbs;
