import React from "react";
import styled, { css } from "styled-components";

const StyledAvatar = styled.div<Props>`
  width: ${(props) => props.size || "42px"};
  height: ${(props) => props.size || "42px"};
  border-radius: ${(props) => (props.square && props.image ? 0 : "50%")};
  background-color: ${(props) =>
    props.transparent ? "transparent" : props.theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1;

  ${(props) =>
    props.image &&
    css`
      background-image: url(${props.image});
      background-position: center;
      background-size: ${props.contain ? "contain" : "cover"};
      background-repeat: no-repeat;
    `};
`;

interface Props {
  size?: string;
  image?: string;
  contain?: boolean;
  baseUrl?: string;
  transparent?: boolean;
  square?: boolean;
  fallback?: string;
}

const Avatar: React.FC<Props> = ({ image, fallback, transparent, ...rest }) => {
  const initials = React.useMemo<string | null>(() => {
    if (!image && fallback) {
      return fallback
        .split(" ")
        .filter((s) => s.length > 0)
        .map((s) => s[0])
        .join("")
        .toUpperCase();
    }

    return null;
  }, [image, fallback]);

  return (
    <StyledAvatar
      image={image}
      transparent={transparent || !initials}
      {...rest}
    >
      {initials}
    </StyledAvatar>
  );
};

export default Avatar;
