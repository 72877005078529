import styled, { css } from "styled-components";
import { FlexAlign } from "../../types/general";

export type InlineEditElementBackground =
  | "background"
  | "backgroundInBetween"
  | "backgroundVariant";

interface InlineEditElementProps {
  background?: InlineEditElementBackground;
  isolated?: boolean;
}

const InlineEditElement = styled.div<InlineEditElementProps>`
  border-top: 1px solid ${(props) => props.theme.colors.borderDim};

  ${(props) =>
    props.background &&
    css`
      background-color: ${props.theme.colors[props.background]};
    `}

  ${(props) =>
    props.isolated &&
    css`
      border: 1px solid ${(props) => props.theme.colors.borderDim};
      border-radius: 8px;
    `}
`;

interface InlineEditHeaderProps {
  alignItems?: FlexAlign;
  fullClickExpand?: boolean;
}

const InlineEditHeader = styled.div<InlineEditHeaderProps>`
  display: flex;
  align-items: ${(props) => props.alignItems || "center"};

  ${(props) =>
    props.fullClickExpand &&
    css`
      cursor: pointer;
    `}
`;

interface InlineEditBodyProps {
  isolated?: boolean;
}

const InlineEditBody = styled.div<InlineEditBodyProps>`
  border-top: 1px solid ${(props) => props.theme.colors.borderDim};
  background-color: ${(props) => props.theme.colors.backgroundInBetween};

  ${(props) =>
    props.isolated &&
    css`
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    `}
`;

interface InlineEditColumnProps {
  width?: string;
  alignRight?: boolean;
  alignSelf?: FlexAlign;
  textAlign?: string;
  tight?: boolean;
}

const InlineEditColumn = styled.div<InlineEditColumnProps>`
  padding: ${(props) =>
    props.tight
      ? `${props.theme.spacing.xs} calc(${props.theme.spacing.medium} / 2)`
      : `${props.theme.spacing.small} calc(${props.theme.spacing.medium} / 2)`};

  &:first-child {
    padding-left: ${(props) => props.theme.spacing.medium};
  }

  &:last-child {
    padding-right: ${(props) => props.theme.spacing.medium};
  }

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${(props) =>
    props.alignSelf &&
    css`
      align-self: ${props.alignSelf};
    `};

  ${(props) =>
    props.alignRight &&
    css`
      margin-left: auto;
      text-align: right;
    `};

  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `};
`;

const InlineEditHead = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
`;

const InlineEditButtonPlaceholder = styled.div`
  width: 30px;
`;

export {
  InlineEditElement,
  InlineEditHeader,
  InlineEditBody,
  InlineEditColumn,
  InlineEditHead,
  InlineEditButtonPlaceholder
};
