import React, { useCallback, useMemo } from "react";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineFileAdd
} from "react-icons/ai";
import ProductSelector, {
  EmptyState,
  Wrapper
} from "../ProductSelector/ProductSelector";
import PageSelector, { Content } from "../PageSelector/PageSelector";
import { Page, Product } from "../../types/apiResponses";
import { Button, ButtonList } from "../../style-guide/Button/Button";
import Text from "../../style-guide/Text/Text";
import CrudModal, { createCrudModalField } from "../CrudModal/CrudModal";
import Field from "../../style-guide/Inputs/Field";
import Label from "../../style-guide/Inputs/Label";

type LinkType = "custom" | "page" | "product";

interface TextLines {
  line1: string;
  line2: string;
}

interface RawLinkerData {
  type?: LinkType;
  page?: string;
  product?: string;
  label?: string;
  ariaLabel?: string;
  url?: string;
}

interface PopulatedLinkerData {
  type?: LinkType;
  page?: Page;
  product?: Product;
  label?: string;
  ariaLabel?: string;
  url?: string;
}

interface Props {
  title: string;
  populatedData?: PopulatedLinkerData;
  readOnly?: boolean;
  onChange: (value: RawLinkerData) => any;
}

const Linker: React.FC<Props> = ({
  title,
  onChange,
  populatedData,
  readOnly
}) => {
  const { spawnModal } = useModal();

  const handleChange = useCallback(
    (data: PopulatedLinkerData) => {
      const type = data.type || "page";
      const rawData: RawLinkerData = {
        ...data,
        type,
        page: type === "page" && data.page ? data.page._id : undefined,
        product:
          type === "product" && data.product ? data.product._id : undefined
      };

      onChange(rawData);
    },
    [onChange]
  );

  const createEditModal = useCallback(() => {
    spawnModal(
      <CrudModal
        title={title}
        initialData={populatedData || {}}
        onSubmit={handleChange}
        fields={[
          {
            key: "type",
            render: (payload) =>
              createCrudModalField({
                payload,
                key: "type",
                type: "select",
                label: "Lenketype",
                nonNullable: true,
                options: [
                  {
                    value: "page",
                    label: "Side/kategori"
                  },
                  {
                    value: "product",
                    label: "Produkt"
                  },
                  {
                    value: "custom",
                    label: "Egendefinert"
                  }
                ]
              })
          },
          {
            key: "page",
            hide: (data) => data.type && data.type !== "page",
            render: (payload) => (
              <Field hugBottom>
                <Label>Side/kategori</Label>
                <PageSelector
                  page={payload.data.page ? payload.data.page : undefined}
                  queryParams={{
                    draft: false,
                    isProductPage: false,
                    fetchInherited: true
                  }}
                  readOnly={readOnly}
                  onChange={(page) => payload.setField("page", page)}
                />
              </Field>
            )
          },
          {
            key: "product",
            hide: (data) => data.type !== "product",
            render: (payload) => (
              <Field hugBottom>
                <Label>Produkt</Label>
                <ProductSelector
                  product={
                    payload.data.product ? payload.data.product : undefined
                  }
                  queryParams={{
                    draft: false
                  }}
                  readOnly={readOnly}
                  onChange={(product) => payload.setField("product", product)}
                />
              </Field>
            )
          },
          {
            key: "url",
            hide: (data) => data.type !== "custom",
            render: (payload) =>
              createCrudModalField({
                payload,
                key: "url",
                type: "text",
                label: "URL",
                placeholder: "https://eksempel.no",
                required: true
              })
          },
          {
            key: "label",
            render: (payload) =>
              createCrudModalField({
                payload,
                key: "label",
                type: "text",
                label: "Etikett"
              })
          },
          {
            key: "ariaLabel",
            render: (payload) =>
              createCrudModalField({
                payload,
                key: "ariaLabel",
                type: "text",
                label: "ARIA-etikett"
              })
          }
        ]}
      />
    );
  }, [populatedData, handleChange, readOnly]);

  const textLines = useMemo<TextLines | undefined>(() => {
    if (populatedData) {
      if (populatedData.type === "product" && populatedData.product) {
        return {
          line1: populatedData.product.name,
          line2: "Produkt"
        };
      } else if (populatedData.type === "custom") {
        return {
          line1: populatedData.url || "Mangler lenke",
          line2: "Egendefinert"
        };
      } else if (populatedData.page) {
        return {
          line1: populatedData.page.title,
          line2: "Side/kategori"
        };
      }
    }

    return undefined;
  }, [populatedData]);

  return (
    <Wrapper>
      <Content>
        {textLines ? (
          <>
            <Text variant="body2">{textLines.line1}</Text>
            <Text variant="body3">{textLines.line2}</Text>
          </>
        ) : (
          <EmptyState>
            <AiOutlineFileAdd />
          </EmptyState>
        )}
      </Content>
      <ButtonList align="center" tight vertical>
        <Button
          type="button"
          circular
          outlined
          smaller
          disabled={readOnly}
          onClick={createEditModal}
        >
          <AiOutlineEdit />
        </Button>
        {textLines && (
          <Button
            type="button"
            circular
            outlined
            smaller
            disabled={readOnly}
            onClick={() => {
              if (!readOnly) {
                if (window.confirm("Er du sikker på at du vil slette?")) {
                  onChange({});
                }
              }
            }}
          >
            <AiOutlineDelete />
          </Button>
        )}
      </ButtonList>
    </Wrapper>
  );
};

export default Linker;
