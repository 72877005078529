function getPopulatedValue<T>(
  data: any,
  key: string,
  fallback?: string
): T | string {
  if (data && typeof data !== "string" && key in data) {
    return data[key] as T;
  }

  return fallback || "";
}

export default getPopulatedValue;
