import React from "react";
import { Switch, Route } from "react-router";
import Experiment from "./Experiment";
import Experiments from "./Experiments";

const ExperimentRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/eksperimenter" component={Experiments} />
      <Route
        path="/eksperimenter/:experiment"
        render={(props) => (
          <Experiment key={props.match.params.experiment} {...props} />
        )}
      />
    </Switch>
  );
};

export default ExperimentRoutes;
