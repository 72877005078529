import React, { useCallback, useContext } from "react";
import { Template, Site } from "../../../types/apiResponses";
import Text from "../../../style-guide/Text/Text";
import { Table, Th, Td, Tr } from "../../../style-guide/Table/Table";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import { templateRoles } from "../../../constants/localizations";
import useTheme from "../../../hooks/useTheme";
import { useSearch } from "../../../hooks/useApi";
import useApiUrl from "../../../hooks/useApiUrl";
import Select from "../../../style-guide/Inputs/Select";
import useForm from "../../../hooks/useForm";
import localize from "../../../util/localize";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";
import useNotifications from "../../../hooks/useNotifications";
import Crumb from "../../../components/Crumb/Crumb";
import { SiteContext } from "../../../context/Site";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Doorman from "../../../components/Doorman/Doorman";
import { ButtonLink } from "../../../style-guide/Button/Button";
import useSite from "../../../hooks/useSite";

const TemplateUsage: React.FC = () => {
  const theme = useTheme();
  const site = useSite();
  const siteUrl = useApiUrl(["site"]);
  const templatesUrl = useApiUrl(["templates"]);
  const notifications = useNotifications();
  const siteContext = useContext(SiteContext);

  const templatesSearch = useSearch<Template>({
    endpoint: templatesUrl,
    fetchOnMount: true,
    queryParams: {
      fetchInherited: true
    },
    limit: 100
  });

  const { data, setField, submitting } = useForm<Site>(
    { templateDistribution: {} },
    {
      endpoint: siteUrl,
      prefillEndpoint: siteUrl,
      method: "PATCH",
      diff: true,
      onSuccess: (resource) => {
        if (resource) {
          notifications.spawn({
            title: "Mal endret"
          });

          siteContext.setData(resource);
        }
      }
    }
  );

  const onSet = useCallback(
    (role: string, id: string) => {
      const template = templatesSearch.results.find((t) => t.draftFor === id);

      if (template) {
        if (
          window.confirm(
            `Er du sikker på at du vil endre malen for ${localize(
              templateRoles,
              role
            )} til ${template.name}?`
          )
        ) {
          setField({
            path: `templateDistribution.${role}`,
            value: {
              ref: id
            },
            submit: true
          });
        }
      }
    },
    [templatesSearch.results, setField]
  );

  return (
    <>
      <Block hugTop>
        <Flex>
          <FlexKid flex={1}>
            <Text element="h1" variant="display3">
              <Crumb url="/innstillinger/maler">Maler</Crumb>
              Malbruk
            </Text>
          </FlexKid>
          <Doorman type="contributor">
            <FlexKid>
              <ButtonLink to="liste/opprett">Opprett mal</ButtonLink>
            </FlexKid>
          </Doorman>
        </Flex>
      </Block>
      <BusyBoy busy={submitting}>
        <Card>
          <Table>
            <thead>
              <Tr>
                <Th>Sidetype</Th>
                <Th>Mal</Th>
              </Tr>
            </thead>
            <tbody>
              {Object.keys(templateRoles)
                .filter((k) => {
                  if (theme) {
                    if (
                      (k === "cart" ||
                        k === "cart" ||
                        k === "productPage" ||
                        k === "product") &&
                      !site.hasEcommerce
                    ) {
                      return false;
                    }

                    return true;
                  }

                  return false;
                })
                .map((k) => {
                  const value = data.templateDistribution[k as "frontPage"];

                  return (
                    <Tr key={k}>
                      <Td verticalAlign="middle">{templateRoles[k]}</Td>
                      <Td>
                        <Select
                          value={value && value.ref ? value.ref : ""}
                          onChange={(e) => onSet(k, e.target.value)}
                        >
                          <option value="" disabled>
                            Ingen
                          </option>
                          {templatesSearch.results.map((t) => (
                            <option key={t._id} value={t.draftFor}>
                              {t.name}
                              {t.site !== site._id && " – Global"}
                            </option>
                          ))}
                        </Select>
                      </Td>
                    </Tr>
                  );
                })}
            </tbody>
          </Table>
        </Card>
      </BusyBoy>
    </>
  );
};

export default TemplateUsage;
