import React, { useEffect, useState } from "react";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import { Discount } from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Section from "../../../style-guide/Section/Section";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import createPublisherInputField from "../../../util/createPublisherInputField";
import {
  discountType,
  discountOfferType
} from "../../../constants/localizations";
import createInputField from "../../../util/createInputField";

interface Props {
  publisher: UsePublisherInterface<Discount>;
}

const General: React.FC<Props> = ({ publisher }) => {
  const form = publisher.form;
  const data = form.data;

  const [hasChangedValue, setHasChangedValue] = useState(false);
  const [useEndDate, setUseEndDate] = useState(false);

  useEffect(() => {
    if (!publisher.isNew && !hasChangedValue) {
      setUseEndDate(!!data.endDate);
    }
  }, [publisher.isNew, hasChangedValue, data.endDate]);

  return (
    <Fader>
      <Flex align="flex-start">
        <FlexKid flex={1}>
          <Section hugTop>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Generelt
                  </Text>
                </Section>
                {createPublisherInputField(publisher, {
                  path: "name",
                  label: "Navn",
                  type: "text",
                  required: true
                })}
                {createPublisherInputField(publisher, {
                  path: "__type",
                  label: "Type",
                  type: "radio",
                  options: Object.keys(discountType).map((k) => ({
                    value: k,
                    label: discountType[k]
                  }))
                })}
              </Block>
            </Card>
          </Section>
          <Section>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Tilbud
                  </Text>
                </Section>
                {createPublisherInputField(publisher, {
                  path: "offerType",
                  label: "Avslagsutregning",
                  type: "radio",
                  options: Object.keys(discountOfferType).map((k) => ({
                    value: k,
                    label: discountOfferType[k]
                  }))
                })}
                {createPublisherInputField(publisher, {
                  path: "offerValue",
                  label: "Avslagsverdi",
                  type: "text",
                  required: true
                })}
              </Block>
            </Card>
          </Section>
        </FlexKid>
        <FlexKid flex={1} spaceLeft>
          <Card>
            <Block>
              <Section hugTop>
                <Text element="h2" variant="title">
                  Aktiv
                </Text>
              </Section>
              {createPublisherInputField(publisher, {
                path: "active",
                label: "Aktiv",
                type: "boolean"
              })}
              {createPublisherInputField(publisher, {
                path: "startDate",
                label: "Starttidspunkt",
                type: "time",
                required: true
              })}
              {createInputField({
                key: "useEndDate",
                label: "Sett sluttidspunkt",
                type: "boolean",
                value: useEndDate,
                readOnly: publisher.isRevision,
                onChange: (value) => {
                  setUseEndDate(value);
                  setHasChangedValue(true);
                  form.setField({ path: "endDate", value: null });
                }
              })}
              {useEndDate &&
                createPublisherInputField(publisher, {
                  path: "endDate",
                  label: "Sluttidspunkt",
                  type: "time",
                  required: true
                })}
            </Block>
          </Card>
        </FlexKid>
      </Flex>
    </Fader>
  );
};

export default General;
