import React from "react";
import { Switch, Route } from "react-router";
import PageDesigner from "./PageDesigner";
import TemplateDesigner from "./TemplateDesigner";
import ThemeDesigner from "./ThemeDesigner";
import GlobalDesigner from "./GlobalDesigner";
import ComponentDesigner from "./ComponentDesigner";
import { PowersearchModal } from "../../components/Powersearch/PowersearchModal";

const DesignerRoutes: React.FC = () => {
  return (
    <>
    <Switch>
      <Route
        exact
        path="/designer/sider/:page"
        render={(props) => <PageDesigner key={props.match.url} {...props} />}
      />
      <Route
        exact
        path="/designer/kategorier/:page"
        render={(props) => (
          <PageDesigner key={props.match.url} {...props} isCategory />
        )}
      />
      <Route
        exact
        path="/designer/produktsider/:page"
        render={(props) => (
          <PageDesigner key={props.match.url} {...props} isProductPage />
        )}
      />
      <Route
        exact
        path="/designer/maler/:template"
        component={TemplateDesigner}
      />
      <Route
        exact
        path="/designer/temainnstillinger"
        component={ThemeDesigner}
      />
      <Route exact path="/designer/globalt" component={GlobalDesigner} />
      <Route
        exact
        path="/designer/komponenter/:component"
        component={ComponentDesigner}
      />
    </Switch>
      <PowersearchModal />
    </>
  );
};

export default DesignerRoutes;
