import styled, { css } from "styled-components";
import { FADE_IN_LEFT } from "../Fader/Fader";

interface Props {
  sidebar?: boolean;
  widerSidebar?: boolean;
}

const Wrapper = styled.div<Props>`
  display: grid;
  min-height: 100vh;
  grid-template-columns: 18rem 1fr;
  grid-template-rows: 109px auto;
  grid-template-areas:
    "sidebar top"
    "sidebar main";

  ${(props) =>
    props.widerSidebar &&
    css`
      grid-template-columns: 32rem 1fr;
    `}
`;

interface MainProps {
  noMaxWidth?: boolean;
  fullColumn?: boolean;
}

const Main = styled.div<MainProps>`
  grid-area: main;
  width: 100%;
  max-width: 100rem;
  margin: 0 auto;
  padding: ${(props) =>
    `0 ${props.theme.spacing.medium} ${props.theme.spacing.medium} 0`};
  padding-bottom: 80px;

  ${(props) =>
    props.noMaxWidth &&
    css`
      max-width: none;
    `}

  ${(props) =>
    props.fullColumn &&
    css`
      grid-area: top / main;
    `}
`;

interface MainBlockProps {
  smaller?: boolean;
}

const MainBlock = styled.div<MainBlockProps>`
  width: 100%;
  max-width: 100rem;
  margin: 0 auto;
  padding: ${(props) => `0 ${props.theme.spacing.medium}`};

  ${(props) =>
    props.smaller &&
    css`
      max-width: 42rem;
    `}
`;

interface SidebarProps {
  wider?: boolean;
}

const Sidebar = styled.div<SidebarProps>`
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 18rem;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  animation: ${FADE_IN_LEFT} 0.24s ease-out both;

  ${(props) =>
    props.wider &&
    css`
      width: 32rem;
    `}
`;

const SidebarFooter = styled.div`
  margin-top: auto;
  padding: ${(props) => props.theme.spacing.small} 0;
`;

const TopBar = styled.div`
  grid-area: top;
  padding: ${(props) =>
    `${props.theme.spacing.medium} ${props.theme.spacing.medium} ${props.theme.spacing.medium} 0`};
  width: 100%;
  max-width: 100rem;
  margin: 0 auto;
  position: relative;
  z-index: 1;
`;

export { Wrapper, Main, MainBlock, Sidebar, SidebarFooter, TopBar };
