import React, { ReactNode, useMemo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Image } from "@ludens-reklame/rubics-sdk";
import {
  CardStyles,
  CardProps
} from "@ludens-reklame/rubics-theme/dist/components/Card";
import Avatar from "../../style-guide/Avatar/Avatar";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import Text from "../../style-guide/Text/Text";
import { Company, CompanyTeam, Customer } from "../../types/apiResponses";
import fallbackImage from "../../util/images/empty_preview.png";

const Wrapper = styled(Link)<CardProps>`
  ${CardStyles}

  transition: box-shadow 0.12s ease-in-out;
  padding: ${(props) =>
    `${props.theme.spacing.xs} ${props.theme.spacing.small}`};

  &:hover,
  &:focus {
    box-shadow: ${(props) => props.theme.shadows.normal};
    text-decoration: none;
  }

  &:active {
    box-shadow: none;
  }

  p:first-child {
    color: ${(props) => props.theme.colors.action};
  }
`;

interface Props {
  to: string;
  line1: ReactNode;
  line2?: ReactNode;
  image?: string;
  containImage?: boolean;
  squareImage?: boolean;
}

const Chip: React.FC<Props> = ({
  to,
  line1,
  line2,
  image,
  containImage,
  squareImage
}) => {
  return (
    <Wrapper to={to} outlined>
      <Flex>
        <FlexKid>
          <Avatar
            image={image || fallbackImage}
            transparent
            contain={containImage}
            square={squareImage}
            size="34px"
          />
        </FlexKid>
        <FlexKid flex={1} spaceLeft tight>
          <Text variant="body2">{line1}</Text>
          {line2 && <Text variant="body3">{line2}</Text>}
        </FlexKid>
      </Flex>
    </Wrapper>
  );
};

interface CompanyChipProps {
  company: Company;
  team?: CompanyTeam;
}

const CompanyChip: React.FC<CompanyChipProps> = ({ company, team }) => {
  const logoUrl = useMemo<string>(() => {
    if (company.logo && typeof company.logo !== "string") {
      return Image.createUrl({
        image: {
          url: company.logo.secure_url,
          alt: company.name
        },
        transforms: "w_108,h_72,c_pad"
      });
    }

    return fallbackImage;
  }, [company]);

  return (
    <Chip
      to={`/b2b/bedrifter/${company._id}`}
      line1={company.name}
      line2={team ? team.name : undefined}
      image={logoUrl}
      containImage
      squareImage
    />
  );
};

interface CustomerChipProps {
  customer: Customer;
}

const CustomerChip: React.FC<CustomerChipProps> = ({ customer }) => {
  return (
    <Chip
      to={`/butikk/kunder/${customer._id}`}
      line1={customer.name}
      line2={customer.anonymous ? "Anonym" : customer.email}
      image={customer.profileImage}
    />
  );
};

export default Chip;
export { CompanyChip, CustomerChip };
