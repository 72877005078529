import React, { useMemo } from "react";
import { PriceType, ProductType } from "@ludens-reklame/rubics-sdk";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import { Product, Page, Template } from "../../../types/apiResponses";
import useApiUrl from "../../../hooks/useApiUrl";
import { useSearch } from "../../../hooks/useApi";
import Fader from "../../../style-guide/Fader/Fader";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Section from "../../../style-guide/Section/Section";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import createPublisherInputField from "../../../util/createPublisherInputField";
import {
  productType,
  productPriceType,
  stockStatus,
  backorders
} from "../../../constants/localizations";
import Overflower from "../../../style-guide/Overflower/Overflower";
import Tree from "../../../components/Tree/Tree";
import { Backorders } from "../../../constants/api";
import useSite from "../../../hooks/useSite";
import { DEFAULT_TAX_CLASS_NAME } from "../../../constants/general";
import ProductPreview from "../../../components/ProductPreview/ProductPreview";
import BundleContent from "../Components/BundleContent";
import Field from "../../../style-guide/Inputs/Field";
import Label from "../../../style-guide/Inputs/Label";
import Tagger from "../../../components/Tagger/Tagger";
import useTheme from "../../../hooks/useTheme";
import Putter from "../../../components/Putter/Putter";
import Lister from "../../../components/Lister/Lister";
import { getPotentiallyPopulatedId } from "../../../util/getPotentiallyPopulatedId";

interface Props {
  publisher: UsePublisherInterface<Product>;
  productId: string;
  templates: Template[];
  hasTemplate: boolean;
}

const General: React.FC<Props> = ({ publisher, templates, hasTemplate }) => {
  const site = useSite();
  const theme = useTheme();
  const form = publisher.form;
  const data = form.data;
  const productSchema = theme?.productProps || [];

  const categoryTreeUrl = useApiUrl(["pages", "tree"]);

  const categoryTreeSearch = useSearch<Page>({
    endpoint: categoryTreeUrl,
    fetchOnMount: true,
    queryParams: {
      isCategory: true,
      fetchInherited: true
    }
  });

  const hasMultipleTaxClasses = useMemo<boolean>(
    () => (site.taxClasses || []).length > 1,
    [site]
  );

  return (
    <Fader>
      <Flex align="flex-start">
        <FlexKid flex={2}>
          <Section hugTop>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Generelt
                  </Text>
                </Section>
                {createPublisherInputField(publisher, {
                  path: "name",
                  label: "Navn",
                  type: "text",
                  required: true,
                  autosave: true
                })}
                {!publisher.isNew &&
                  createPublisherInputField(publisher, {
                    path: "slug",
                    label: "Permalenke",
                    type: "text",
                    autosave: true
                  })}
                {createPublisherInputField(publisher, {
                  path: "type",
                  label: "Produkttype",
                  type: "radio",
                  value: publisher.form.data.type || ProductType.Default,
                  options: Object.keys(productType).map((k) => ({
                    label: productType[k],
                    value: k
                  })),
                  autosave: true
                })}
                {createPublisherInputField(publisher, {
                  path: "description",
                  label: "Beskrivelse",
                  type: "textarea",
                  autosave: true
                })}
                {createPublisherInputField(publisher, {
                  path: "extendedDescription",
                  label: "Utvidet beskrivelse",
                  type: "draft",
                  autosave: true,
                  syncState: publisher.form.syncState
                })}
                <Field hugBottom>
                  <Label htmlFor="tags">Emneknagger</Label>
                  <Tagger
                    tags={form.getValue("tags")}
                    readOnly={publisher.isRevision}
                    placeholder="Legg til emneknagg"
                    inline={false}
                    onChange={(tags) =>
                      form.setField({
                        path: "tags",
                        value: tags,
                        submit: !publisher.isNew
                      })
                    }
                  />
                </Field>
                {!publisher.isNew &&
                  publisher.form.data.draftFor &&
                  createPublisherInputField(publisher, {
                    path: "draftFor",
                    label: "Intern id",
                    type: "text",
                    readOnly: true
                  })}
              </Block>
            </Card>
          </Section>
          {categoryTreeSearch.results.length > 0 && (
            <Section>
              <Card>
                <Block>
                  <Section hugTop>
                    <Text element="h2" variant="title">
                      Kategorier
                    </Text>
                  </Section>
                  {data.categories.length > 0 && (
                    <Section hugTop>
                      {createPublisherInputField(publisher, {
                        path: "primaryCategory",
                        label: "Hovedkategori",
                        type: "select",
                        required: false,
                        nullFalsy: true,
                        options: data.categories
                          .filter((c) => !!c.ref && typeof c.ref !== "string")
                          .map((c) => ({
                            label: (c.ref as Page).title,
                            value: (c.ref as Page)._id
                          })),
                        autosave: true
                      })}
                    </Section>
                  )}
                  <Overflower maxHeight="22rem">
                    <Tree
                      tree={categoryTreeSearch.results}
                      labelField="title"
                      dontCompensate
                      readOnly={publisher.isRevision}
                      values={data.categories
                        .filter((c) => !!c.ref)
                        .map((c) =>
                          typeof c.ref === "string" ? c.ref : c.ref._id
                        )}
                      onChange={(id, selected) => {
                        const exists = data.categories
                          .filter((c) => !!c.ref)
                          .some((c) =>
                            typeof c.ref === "string"
                              ? c.ref === id
                              : c.ref._id === id
                          );

                        if (!selected && exists) {
                          form.setField({
                            path: "categories",
                            value: data.categories
                              .filter((c) => !!c.ref)
                              .filter((c) =>
                                typeof c.ref === "string"
                                  ? c.ref !== id
                                  : c.ref._id !== id
                              ),
                            submit: !publisher.isNew
                          });
                        } else if (selected && !exists) {
                          form.setField({
                            path: "categories",
                            value: [...data.categories, { ref: id }],
                            submit: !publisher.isNew
                          });
                        }
                      }}
                    />
                  </Overflower>
                </Block>
              </Card>
            </Section>
          )}
          {productSchema.length > 0 && (
            <Section>
              <Card>
                <Block>
                  <Section hugTop>
                    <Text element="h2" variant="title">
                      Tilpasset innhold
                    </Text>
                  </Section>
                  <Putter
                    props={productSchema}
                    form={form}
                    pathPrefix="props"
                    previewPathPrefix="propsPopulated"
                    disableCopyPaste
                  />
                </Block>
              </Card>
            </Section>
          )}
          <BundleContent publisher={publisher} />
          <Section>
            <Card>
              <Block>
                <Section hugTop hugBottom>
                  <Text element="h2" variant="title">
                    Lager
                  </Text>
                </Section>
                {createPublisherInputField(publisher, {
                  path: "sku",
                  label: "SKU",
                  type: "text",
                  autosave: true
                })}
                {createPublisherInputField(publisher, {
                  path: "manageStock",
                  label: "Styr lager for produkt?",
                  type: "boolean",
                  autosave: true
                })}
                {!data.manageStock ? (
                  createPublisherInputField(publisher, {
                    path: "stockStatus",
                    label: "Lagerstatus",
                    type: "radio",
                    autosave: true,
                    options: Object.keys(stockStatus).map((k) => ({
                      label: stockStatus[k],
                      value: k
                    }))
                  })
                ) : (
                  <>
                    {createPublisherInputField(publisher, {
                      path: "stockCount",
                      label: "Lagerbeholdning",
                      type: "number",
                      autosave: true
                    })}
                    {createPublisherInputField(publisher, {
                      path: "backorders",
                      label: "Tillat restordre?",
                      type: "radio",
                      autosave: true,
                      options: Object.keys(backorders)
                        // We don't support backorder notifications yet
                        .filter((k) => k !== Backorders.Notify)
                        .map((k) => ({
                          label: backorders[k],
                          value: k
                        }))
                    })}
                  </>
                )}
              </Block>
            </Card>
          </Section>
        </FlexKid>
        <FlexKid flex={1} spaceLeft>
          {!publisher.isNew && hasTemplate && (
            <Section hugTop>
              <Card outlined>
                <Block>
                  <ProductPreview product={data} />
                </Block>
              </Card>
            </Section>
          )}
          <Section hugTop={publisher.isNew || !hasTemplate}>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Kostnad
                  </Text>
                </Section>
                {!data.bundle &&
                  createPublisherInputField(publisher, {
                    path: "price.type",
                    label: "Pristype",
                    type: "radio",
                    options: Object.keys(productPriceType).map((k) => ({
                      label: productPriceType[k],
                      value: k
                    })),
                    autosave: true
                  })}
                {data.price.type !== PriceType.ByAppointment && (
                  <>
                    {!data.bundle && (
                      <>
                        <Flex>
                          <FlexKid flex={1}>
                            {createPublisherInputField(publisher, {
                              path: "price.value",
                              label: "Pris",
                              type: "number",
                              autosave: true
                            })}
                          </FlexKid>
                          <FlexKid flex={1} spaceLeft>
                            {createPublisherInputField(publisher, {
                              path: "price.compareAtValue",
                              label: "Førpris",
                              type: "number",
                              autosave: true
                            })}
                          </FlexKid>
                        </Flex>
                      </>
                    )}
                    <Flex>
                      <FlexKid flex={1}>
                        {createPublisherInputField(publisher, {
                          path: "price.prefix",
                          label: "Prefiks",
                          type: "text",
                          autosave: true,
                          hugTop: data.bundle
                        })}
                      </FlexKid>
                      <FlexKid flex={1} spaceLeft>
                        {createPublisherInputField(publisher, {
                          path: "price.suffix",
                          label: "Suffiks",
                          type: "text",
                          autosave: true,
                          hugTop: data.bundle
                        })}
                      </FlexKid>
                    </Flex>
                    {!data.bundle &&
                      hasMultipleTaxClasses &&
                      createPublisherInputField(publisher, {
                        path: "taxClass",
                        label: "Avgiftsklasse",
                        type: "select",
                        nonNullable: true,
                        nullFalsy: true,
                        options: (site.taxClasses || []).map((c) => ({
                          label:
                            c.name === DEFAULT_TAX_CLASS_NAME
                              ? "Standard"
                              : c.name,
                          value: c.name === DEFAULT_TAX_CLASS_NAME ? "" : c._id
                        })),
                        autosave: true
                      })}
                    {site.partialPaymentEnabled &&
                      createPublisherInputField(publisher, {
                        path: "paymentPlan",
                        label: "Betalingsplan",
                        type: "ref",
                        value:
                          data.paymentPlan &&
                          typeof data.paymentPlan !== "string"
                            ? data.paymentPlan.name
                            : "",
                        ref: {
                          url: ["payment-plans"],
                          searchKey: "name",
                          titleKey: "name",
                          subtitleKey: "description"
                        },
                        autosave: true
                      })}
                  </>
                )}
                {createPublisherInputField(publisher, {
                  path: "subscribable",
                  label: "Abonnerbar",
                  type: "boolean",
                  autosave: true
                })}
                {data.subscribable &&
                  createPublisherInputField(publisher, {
                    path: "subscriptionIntervalDays",
                    label: "Abonnementsintervall (i dager)",
                    type: "number",
                    required: true,
                    autosave: true
                  })}
              </Block>
            </Card>
          </Section>
          <Section>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Maler
                  </Text>
                </Section>
                {createPublisherInputField(publisher, {
                  path: "template",
                  label: "Produktmal",
                  type: "ref",
                  value:
                    data.template && typeof data.template !== "string"
                      ? data.template.name
                      : "",
                  ref: {
                    url: ["product-templates"],
                    searchKey: "name",
                    titleKey: "name"
                  },
                  autosave: true
                })}
                {createPublisherInputField(publisher, {
                  path: "content",
                  label: "Innholdsmal",
                  type: "ref",
                  value:
                    data.content && typeof data.content !== "string"
                      ? data.content.title
                      : undefined,
                  ref: {
                    url: ["pages"],
                    searchKey: "title",
                    titleKey: "title",
                    query: {
                      draft: false,
                      isProductPage: true
                    }
                  },
                  autosave: true
                })}
                {createPublisherInputField(publisher, {
                  path: "pageTemplate",
                  label: "Sidemal",
                  type: "select",
                  value: form.getValue("pageTemplate.ref"),
                  setField: (value) => {
                    form.setField({
                      path: "pageTemplate",
                      value: {
                        ref: value || null
                      },
                      submit: !publisher.isNew
                    });
                  },
                  options: templates.map((t) => ({
                    value: t._id,
                    label: t.site !== site._id ? `${t.name} – Global` : t.name
                  })),
                  autosave: true
                })}
              </Block>
            </Card>
          </Section>
          <Section>
            <Card>
              <Block>
                <Section hugTop hugBottom>
                  <Text element="h2" variant="title">
                    Annet
                  </Text>
                </Section>
                {createPublisherInputField(publisher, {
                  path: "timeControlledAvailability",
                  label: "Tidsstyrt tilgjengelighet",
                  type: "boolean",
                  autosave: true
                })}
                {data.timeControlledAvailability && (
                  <>
                    {createPublisherInputField(publisher, {
                      path: "availableFrom",
                      label: "Tilgjengelig fra",
                      type: "time",
                      autosave: true
                    })}
                    {createPublisherInputField(publisher, {
                      path: "availableTo",
                      label: "Tilgjengelig til",
                      type: "time",
                      autosave: true
                    })}
                  </>
                )}
                {createPublisherInputField(publisher, {
                  path: "hideInStore",
                  label: "Skjul i nettbutikk",
                  type: "boolean",
                  autosave: true
                })}
                {createPublisherInputField(publisher, {
                  path: "notForIndependentSale",
                  label: "Kan ikke kjøpes selvstendig",
                  type: "boolean",
                  autosave: true
                })}
                {createPublisherInputField(publisher, {
                  path: "limitation.enabled",
                  label: "Sett kjøpsbegrensning",
                  type: "boolean",
                  autosave: true
                })}
                {form.getValue("limitation.enabled") && (
                  <>
                    {createPublisherInputField(publisher, {
                      path: "limitation.per",
                      label: "Begrensning gjelder per",
                      type: "select",
                      options: [
                        {
                          value: "order",
                          label: "Ordre"
                        }
                      ],
                      nonNullable: true,
                      autosave: true
                    })}
                    {createPublisherInputField(publisher, {
                      path: "limitation.min",
                      label: "Minimum per ordre",
                      type: "number",
                      min: 0,
                      autosave: true
                    })}
                    {createPublisherInputField(publisher, {
                      path: "limitation.max",
                      label: "Maksimum per ordre",
                      type: "number",
                      min: 0,
                      autosave: true
                    })}
                  </>
                )}
                {createPublisherInputField(publisher, {
                  path: "customWeight",
                  label: "Søkevekting",
                  type: "number",
                  autosave: true,
                  placeholder: "0"
                })}
                <Field hugBottom>
                  <Label htmlFor="labels">Etiketter</Label>
                  <Lister
                    id="labels"
                    url={["labels"]}
                    items={(data.labels || []).map((a) =>
                      getPotentiallyPopulatedId(a)
                    )}
                    previewItems={data.labels || []}
                    searchKey="name"
                    labelKey="name"
                    itemToString={(label) => label?.name || ""}
                    readOnly={publisher.isRevision}
                    onChange={(items, previewItems) =>
                      form.setField({
                        path: "labels",
                        value: items.map((i) =>
                          previewItems.find((a) => a._id === i)
                        ),
                        submit: true
                      })
                    }
                    searchLabel="Søk etter etiketter"
                    filter={(item) =>
                      !(data.labels || []).find(
                        (l) => l && typeof l !== "string" && l._id === item._id
                      )
                    }
                  />
                </Field>
              </Block>
            </Card>
          </Section>
          {site.bookingEnabled && (
            <Section hugBottom>
              <Card>
                <Block>
                  <Section hugTop>
                    <Text element="h2" variant="title">
                      Booking
                    </Text>
                  </Section>
                  {createPublisherInputField(publisher, {
                    path: "bookingService",
                    label: "Tjeneste",
                    type: "ref",
                    autosave: true,
                    value:
                      data.bookingService &&
                      typeof data.bookingService !== "string"
                        ? data.bookingService.name
                        : "",
                    ref: {
                      url: ["booking", "services"],
                      searchKey: "name",
                      titleKey: "name",
                      subtitleKey: "description"
                    }
                  })}
                  {!!data.bookingService &&
                    createPublisherInputField(publisher, {
                      path: "bookingDurationAdjustment",
                      label: "Juster booking (minutter x antall kjøpt)",
                      type: "number",
                      autosave: true
                    })}
                </Block>
              </Card>
            </Section>
          )}
        </FlexKid>
      </Flex>
    </Fader>
  );
};

export default General;
