import storage from "./storage";
import api, { Service } from "./api";

async function logout(): Promise<void> {
  await api({
    endpoint: "logout",
    service: Service.Auth,
    queryParams: {
      doNotRedirect: true
    }
  });

  storage.delete("user");
  window.location.href = "/logg-inn";
}

export default logout;
