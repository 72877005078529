import { ReactNode } from "react";
import { UsePublisherInterface } from "../hooks/usePublisher";

class PublisherHelpers {
  public static getResourceTitle(
    publisher: UsePublisherInterface<any>,
    titleField: string,
    fallback: ReactNode
  ): ReactNode {
    if (publisher.form.loadingPrefill) {
      return "Laster…";
    }

    return publisher.form.data[titleField] || fallback;
  }

  public static createDashboardUrl(
    publisher: UsePublisherInterface<any>,
    path?: string[]
  ): string {
    const qs = window.location.search;
    return `${publisher.dashboardUrl}${path ? `/${path.join("/")}` : ""}${qs}`;
  }
}

export default PublisherHelpers;
