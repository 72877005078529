import React, { useEffect } from "react";
import {
  RouteComponentProps,
  Switch,
  Route,
  useHistory
} from "react-router-dom";
import { AiOutlineProfile, AiOutlineEdit } from "react-icons/ai";
import { Template as ITemplate } from "../../../types/apiResponses";
import Text from "../../../style-guide/Text/Text";
import Form from "../../../style-guide/Inputs/Form";
import Fader from "../../../style-guide/Fader/Fader";
import Block from "../../../style-guide/Block/Block";
import usePublisher, {
  UsePublisherInterface
} from "../../../hooks/usePublisher";
import PublisherHelpers from "../../../util/PublisherHelpers";
import {
  InlineActions,
  InlineActionLink
} from "../../../style-guide/InlineActions/InlineActions";
import Section from "../../../style-guide/Section/Section";
import ActionBar from "../../../components/ActionBar/ActionBar";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Card from "../../../style-guide/Card/Card";
import createPublisherInputField from "../../../util/createPublisherInputField";
import useBreadcrumbs from "../../../hooks/useBreadcrumbs";
import Crumb from "../../../components/Crumb/Crumb";
import { RevisionType } from "../../../constants/api";

interface Props
  extends RouteComponentProps<{
    template: string;
  }> {}

const Template: React.FC<Props> = ({ match }) => {
  const breadcrumbs = useBreadcrumbs();
  const history = useHistory();
  const publisher = usePublisher<ITemplate>({
    id: match.params.template,
    baseUrl: ["templates"],
    baseDashboardUrl: "/innstillinger/maler/liste",
    publishEndpoint: "publish",
    discardEndpoint: "discard",
    onCreateNew: (template) => {
      history.push(`/innstillinger/maler/liste/${template._id}`);
      history.push(`/designer/maler/${template._id}`);
    },
    pathsToCopy: [
      {
        path: "name",
        label: "Navn"
      },
      {
        path: "components",
        label: "Seksjoner"
      }
    ]
  });

  useEffect(() => {
    breadcrumbs.swapEntry(match.params.template, publisher.form.data.name);
  }, [breadcrumbs, publisher.form.data, match.params.template]);

  return (
    <Form onSubmit={publisher.form.submit}>
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/innstillinger/maler/liste">Oversikt</Crumb>
            {PublisherHelpers.getResourceTitle(publisher, "name", "Ny mal")}
          </Text>
        </Block>
        <Block hugTop hugBottom>
          <InlineActions>
            <InlineActionLink
              exact
              to={PublisherHelpers.createDashboardUrl(publisher)}
            >
              <AiOutlineProfile /> Generelt
            </InlineActionLink>
            {!publisher.isNew && (
              <InlineActionLink
                highlight
                exact
                to={`/designer/maler/${match.params.template}${window.location.search}`}
              >
                <AiOutlineEdit /> Gå til designmodus
              </InlineActionLink>
            )}
          </InlineActions>
        </Block>
        <Section hugTop>
          <Switch>
            <Route
              exact
              path={publisher.dashboardUrl}
              render={() => <General publisher={publisher} />}
            />
          </Switch>
        </Section>
      </Fader>
      <ActionBar
        publisher={publisher}
        publish
        discard
        destroy
        revisions
        revisionType={RevisionType.Template}
        revisionReference={publisher.form.data.draftFor}
        experiment={publisher.experiment}
      />
    </Form>
  );
};

interface PageProps {
  publisher: UsePublisherInterface<ITemplate>;
}

const General: React.FC<PageProps> = ({ publisher }) => {
  return (
    <Fader>
      <Flex align="flex-start">
        <FlexKid flex={2}>
          <Section hugTop>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Generelt
                  </Text>
                </Section>
                {createPublisherInputField(publisher, {
                  path: "name",
                  label: "Navn",
                  type: "text",
                  required: true,
                  autosave: true
                })}
                {createPublisherInputField(publisher, {
                  path: "partial",
                  label: "Undermal",
                  type: "boolean",
                  autosave: true
                })}
                {createPublisherInputField(publisher, {
                  path: "fetchChildren",
                  label: "Hent undersider",
                  type: "boolean",
                  autosave: true
                })}
              </Block>
            </Card>
          </Section>
        </FlexKid>
        <FlexKid flex={1} />
      </Flex>
    </Fader>
  );
};

export default Template;
