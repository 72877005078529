import React, { MouseEvent } from "react";
import styled from "styled-components";
import { useRouteMatch } from "react-router";
import { NavLink } from "react-router-dom";
import AnimateHeight from "react-animate-height";

const Menu = styled.ul`
  margin: 0;
  padding: ${(props) => `0 ${props.theme.spacing.xs}`};
  list-style: none;
`;

const SubMenu = styled(Menu)`
  margin-top: 0;
  padding: 0;

  a {
    margin-left: 32px;
  }
`;

const MenuItemWrapper = styled.li``;

const MenuLink = styled(NavLink)`
  display: flex;
  text-decoration: none;
  color: ${(props) => props.theme.colors.textSecondary};
  padding: ${(props) =>
    `${props.theme.spacing.xs} ${props.theme.spacing.small}`};

  &:hover,
  &.active {
    color: ${(props) => props.theme.colors.textPrimary};
    font-weight: 600;
    text-decoration: none;
  }
`;

const Icon = styled.i`
  display: flex;
  padding: 0 ${(props) => props.theme.spacing.xs} 0 0;
  align-items: center;
  justify-content: center;
  width: 32px;
`;

const Label = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface MenuItemProps {
  to: string;
  exact?: boolean;
  icon?: React.ReactNode;
  subMenu?: React.ReactNode;
  noActiveState?: boolean;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => any;
}

const MenuItem: React.FC<MenuItemProps> = ({
  to,
  exact,
  icon,
  subMenu,
  onClick,
  noActiveState,
  children
}) => {
  const routeMatch = useRouteMatch(to);

  return (
    <MenuItemWrapper>
      <MenuLink
        to={to}
        exact={exact}
        activeClassName={noActiveState ? "silent-active" : "active"}
        onClick={onClick}
      >
        {icon && <Icon>{icon}</Icon>}
        <Label>{children}</Label>
      </MenuLink>
      {subMenu && (
        <AnimateHeight height={routeMatch ? "auto" : 0}>
          {subMenu}
        </AnimateHeight>
      )}
    </MenuItemWrapper>
  );
};

export { Menu, MenuItem, SubMenu };
