import { Component } from "../types/apiResponses";
import { useSearch } from "./useApi";
import useApiUrl from "./useApiUrl";

interface UseComponentsInterface {
  components: Component[];
  loading: boolean;
}

function useComponents(): UseComponentsInterface {
  const url = useApiUrl(["components"]);

  const fetch = useSearch<Component>({
    endpoint: url,
    fetchOnMount: true,
    limit: 100,
    queryParams: {
      draft: false,
      fetchInherited: true
    }
  });

  return {
    components: fetch.results,
    loading: fetch.loading
  };
}

export default useComponents;
