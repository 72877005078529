import * as React from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { RubicsImage } from "@ludens-reklame/rubics-sdk";
import { SearchInterface, SearchOpts, useSearch } from "../../hooks/useApi";
import useApiUrl from "../../hooks/useApiUrl";
import { useDebounce } from "../../hooks/useThrottle";
import getIcon from "../../util/getIcon";

export interface PowerSearchResponse {
  _id: string;
  title: string;
  path: string;
  editPath?: string;
  type: "page" | "media" | "product" | "menu" | "template";
  score: number;
  icon?: string;
  subtitle?: string;
  description?: string;
  image?: RubicsImage;
}

export const defaultActive = 0;
export const iterateKeys = ['Tab', 'ArrowDown', 'ArrowUp'];

export type IUsePowerSearchRet = Omit<SearchInterface<PowerSearchResponse>, "search"> & { search: (t: any) => void; };
export const usePowerSearch = (): IUsePowerSearchRet => {
  const endpoint = useApiUrl(["power-search"]);
  const opts = React.useMemo((): SearchOpts<any> => ({ endpoint, fetchOnMount: false, limit: 5 }), [endpoint]);
  const { search, ...rest } = useSearch<PowerSearchResponse>(opts);
  const throttledSearch = useDebounce(search, 225);
  const handleSearch = React.useCallback(
    (q: any) => q && throttledSearch({ ...opts, queryParams: { q } }),
    [throttledSearch, opts]
  );

  return React.useMemo(() => ({ ...rest, search: handleSearch }), [rest, handleSearch]);
};

export const getIconFromType = (item: PowerSearchResponse) => {
  if (item.icon) return getIcon(item.icon);
  switch (item.type) {
    case "page":
      return getIcon("pages");
    case "media":
      return getIcon("image");
    case "product":
      return getIcon("products");
    case "menu":
      return getIcon("layout");
    case "template":
      return getIcon("file");
    default:
      return <AiOutlineSearch />;
  }
};
export const getNameFromType = (item: PowerSearchResponse) => {
  switch (item.type) {
    case "page":
      return "Side";
    case "media":
      return "Fil";
    case "product":
      return "Produkt";
    case "menu":
      return "Meny";
    case "template":
      return "Mal";
    default:
      return item.type;
  }
};

export const powerSearchKeyboardShortcut = (show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>) => (e: KeyboardEvent) => {
  if (!show) {
    if ((e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement)) {
      return;
    }
    // Should not trigger inside a draftJS editor
    if (e.target && (e.target as any).className.match(/drafteditor/i)) {
      return;
    }
  }
  if (e.shiftKey && e.code === "Space") {
    if (!show) {
      setShow(s =>  !s);
      e.preventDefault();
    }
  } else if (show && e.code === "Escape") {
    setShow(false);
    e.stopPropagation();
  }
}
