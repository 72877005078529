import React, { useMemo, useCallback } from "react";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { DependencyMap, Dependents } from "../../types/apiResponses";
import { Modal, ModalBody, ModalActions } from "../../style-guide/Modal/Modal";
import Block from "../../style-guide/Block/Block";
import Text from "../../style-guide/Text/Text";
import { ButtonList, Button } from "../../style-guide/Button/Button";
import {
  Elements,
  SimpleElement,
  Element
} from "../../style-guide/Elements/Elements";
import Section from "../../style-guide/Section/Section";
import Ruler from "../../style-guide/Ruler/Ruler";

type OnNav = (url: string) => any;

interface DependencyLabel {
  _id: string;
  label: string;
}

interface Props {
  dependents: DependencyMap[];
  onNav: OnNav;
  dependentLabels?: DependencyLabel[];
}

const DependentsModal: React.FC<Props> = ({
  dependents,
  onNav,
  dependentLabels
}) => {
  const { despawnModal } = useModal();

  const dependableDependents = useMemo<DependencyMap[]>(
    () => dependents.filter((d) => d.hasDependents),
    [dependents]
  );

  return (
    <Modal>
      <ModalBody>
        <Block hugBottom>
          <Text variant="title" gutterBottom>
            Oops!
          </Text>
          <Text variant="body3" gutterBottom>
            Du kan ikke slette{" "}
            {dependableDependents.length === 1 ? "denne" : "disse"} før du
            fjerner referansene fra disse ressursene:
          </Text>
        </Block>
        {dependableDependents.map((d) => (
          <Depentent
            key={d._id}
            dependent={d}
            dependentLabels={dependentLabels}
            showLabel={dependents.length > 1}
            onNav={(url) => {
              despawnModal();
              onNav(url);
            }}
          />
        ))}
      </ModalBody>
      <ModalActions>
        <ButtonList align="right">
          <Button
            type="button"
            outlined
            onClick={() => {
              despawnModal();
            }}
          >
            OK
          </Button>
        </ButtonList>
      </ModalActions>
    </Modal>
  );
};

interface DepententProps {
  dependent: DependencyMap;
  onNav: OnNav;
  dependentLabels?: DependencyLabel[];
  showLabel?: boolean;
}

const Depentent: React.FC<DepententProps> = ({
  dependent,
  onNav,
  dependentLabels,
  showLabel
}) => {
  const { spawnModal } = useModal();

  const label = useMemo<string | undefined>(() => {
    if (showLabel && Array.isArray(dependentLabels)) {
      const labelEntry = dependentLabels.find((l) => l._id === dependent._id);

      if (labelEntry) {
        return labelEntry.label;
      }
    }

    return undefined;
  }, [showLabel, dependent, dependentLabels]);

  const hasGlobal = useMemo(() => {
    return (
      dependent.site.length > 0 ||
      dependent.templateDistribution.length > 0 ||
      dependent.globalProps.length > 0 ||
      dependent.globalPropsDraft.length > 0 ||
      dependent.themeConfig.length > 0 ||
      dependent.themeConfigDraft.length > 0
    );
  }, [dependent]);

  const onClick = useCallback(
    (url: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      onNav(url);
    },
    [onNav]
  );

  const renderIcon = useCallback(
    (details: string[]) => (
      <a
        aria-label="Vis liste over referanser"
        href="#"
        onClick={(e) => {
          e.preventDefault();
          spawnModal(<DepententDetailModal details={details} />);
        }}
      >
        {details.length}
      </a>
    ),
    [spawnModal]
  );

  const renderElement = useCallback(
    (
      dependent: Dependents | string,
      type:
        | "template"
        | "page"
        | "product"
        | "dataSet"
        | "experiment"
        | "component"
        | "productTemplate"
    ) => {
      if (typeof dependent === "string") {
        return null;
      }

      let urlPrefix: string;
      let createLink: boolean = false;
      let isDraft: boolean = false;

      switch (type) {
        case "template":
          urlPrefix = "/innstillinger/maler/liste";
          createLink = dependent.reference.draft || false;
          isDraft = true;
          break;
        case "page":
          urlPrefix = "/innhold/sider";
          createLink = dependent.reference.draft || false;
          isDraft = true;
          break;
        case "product":
          urlPrefix = "/butikk/produkter";
          createLink = dependent.reference.draft || false;
          isDraft = true;
          break;
        case "dataSet":
          urlPrefix = `/innhold/data-sets/${dependent.reference.title}`;
          createLink = true;
          break;
        case "experiment":
          urlPrefix = "/eksperimenter";
          createLink = true;
          break;
        case "component":
          urlPrefix = "/innhold/komponenter";
          createLink = dependent.reference.draft || false;
          isDraft = true;
          break;
        case "productTemplate":
          urlPrefix = "/butikk/produktmaler";
          createLink = true;
          break;
        default:
          urlPrefix = "";
          break;
      }

      const url = `${urlPrefix}/${
        type === "template"
          ? dependent.reference.draftFor
          : dependent.reference._id
      }`;

      return (
        <Element
          key={dependent.reference._id}
          rightIcon={renderIcon(dependent.dependents)}
        >
          {createLink ? (
            <a
              href={url}
              onClick={(e) => {
                e.preventDefault();
                onNav(url);
              }}
            >
              {dependent.reference.title} {isDraft ? "(Kladd)" : ""}
            </a>
          ) : (
            dependent.reference.title
          )}
        </Element>
      );
    },
    [onNav]
  );

  return (
    <>
      {showLabel && <Ruler />}
      <Block hugTop hugBottom>
        {label && <Text variant="subheading">{label}</Text>}
        {hasGlobal && (
          <Section>
            <Text variant="body2" gutterBottom>
              Generelt
            </Text>
            <Elements>
              {dependent.site.length > 0 && (
                <Element rightIcon={renderIcon(dependent.site)}>
                  Nettside
                </Element>
              )}
              {dependent.templateDistribution.length > 0 && (
                <Element rightIcon={renderIcon(dependent.templateDistribution)}>
                  Malbruk
                </Element>
              )}
              {dependent.globalProps.length > 0 && (
                <Element rightIcon={renderIcon(dependent.globalProps)}>
                  Globalt innhold
                </Element>
              )}
              {dependent.globalPropsDraft.length > 0 && (
                <Element rightIcon={renderIcon(dependent.globalPropsDraft)}>
                  <a
                    href="/designer/globalt"
                    onClick={onClick("/designer/globalt")}
                  >
                    Globalt innhold (kladd)
                  </a>
                </Element>
              )}
              {dependent.themeConfig.length > 0 && (
                <Element rightIcon={renderIcon(dependent.themeConfig)}>
                  Temainstillinger
                </Element>
              )}
              {dependent.themeConfigDraft.length > 0 && (
                <Element rightIcon={renderIcon(dependent.themeConfigDraft)}>
                  <a
                    href="/designer/temainnstillinger"
                    onClick={onClick("/designer/temainnstillinger")}
                  >
                    Temainstillinger (kladd)
                  </a>
                </Element>
              )}
            </Elements>
          </Section>
        )}
        {dependent.templates.length > 0 && (
          <Section>
            <Text variant="body2" gutterBottom>
              Maler
            </Text>
            <Elements>
              {dependent.templates.map((p) => renderElement(p, "template"))}
            </Elements>
          </Section>
        )}
        {dependent.pages.length > 0 && (
          <Section>
            <Text variant="body2" gutterBottom>
              Sider
            </Text>
            <Elements>
              {dependent.pages.map((p) => renderElement(p, "page"))}
            </Elements>
          </Section>
        )}
        {dependent.components.length > 0 && (
          <Section>
            <Text variant="body2" gutterBottom>
              Komponenter
            </Text>
            <Elements>
              {dependent.components.map((p) => renderElement(p, "component"))}
            </Elements>
          </Section>
        )}
        {dependent.products.length > 0 && (
          <Section>
            <Text variant="body2" gutterBottom>
              Produkter
            </Text>
            <Elements>
              {dependent.products.map((p) => renderElement(p, "product"))}
            </Elements>
          </Section>
        )}
        {dependent.productTemplates.length > 0 && (
          <Section>
            <Text variant="body2" gutterBottom>
              Produktmaler
            </Text>
            <Elements>
              {dependent.productTemplates.map((p) =>
                renderElement(p, "productTemplate")
              )}
            </Elements>
          </Section>
        )}
        {dependent.experiments.length > 0 && (
          <Section>
            <Text variant="body2" gutterBottom>
              Eksperimenter
            </Text>
            <Elements>
              {dependent.experiments.map((p) => renderElement(p, "experiment"))}
            </Elements>
          </Section>
        )}
        {dependent.dataSets.length > 0 && (
          <Section>
            <Text variant="body2" gutterBottom>
              Annen data
            </Text>
            <Elements>
              {dependent.dataSets.map((p) => renderElement(p, "dataSet"))}
            </Elements>
          </Section>
        )}
      </Block>
    </>
  );
};

interface DepententDetailModalProps {
  details: string[];
}

const DepententDetailModal: React.FC<DepententDetailModalProps> = ({
  details
}) => {
  const { despawnModal } = useModal();

  return (
    <Modal>
      <ModalBody>
        <Block>
          <Text variant="title" gutterBottom>
            Referanser
          </Text>
          <Section hugBottom>
            <Elements>
              {details.map((d) => (
                <SimpleElement key={d}>
                  <Text variant="body3">
                    <pre>{d}</pre>
                  </Text>
                </SimpleElement>
              ))}
            </Elements>
          </Section>
        </Block>
      </ModalBody>
      <ModalActions>
        <ButtonList align="right">
          <Button
            type="button"
            outlined
            onClick={() => {
              despawnModal();
            }}
          >
            Lukk
          </Button>
        </ButtonList>
      </ModalActions>
    </Modal>
  );
};

export default DependentsModal;
