import React, { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { AiOutlineTags } from "react-icons/ai";
import { VariantType } from "@ludens-reklame/rubics-sdk";
import {
  Attribute as IAttribute,
  Product,
  ProductAttribute,
  ProductTemplate,
  ProductTemplateAttribute
} from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import Card from "../../../style-guide/Card/Card";
import { Button, ButtonList } from "../../../style-guide/Button/Button";
import Block from "../../../style-guide/Block/Block";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Text from "../../../style-guide/Text/Text";
import { UseFormInterface } from "../../../hooks/useForm";
import { createObjectId } from "../../../util/createId";
import {
  InlineEditColumn,
  InlineEditHead
} from "../../../style-guide/InlineEdit/InlineEdit";
import { Dragger, Dropper } from "../../../components/DnD/DnD";
import Attribute from "../../ProductTemplates/Components/Attribute";
import EmptyState from "../../../style-guide/EmptyState/EmptyState";
import useApiUrl from "../../../hooks/useApiUrl";
import { useSearch } from "../../../hooks/useApi";
import { Table, Tr, Th, Td } from "../../../style-guide/Table/Table";
import AttributeEdit from "../Components/AttributeEdit";
import Section from "../../../style-guide/Section/Section";
import Ruler from "../../../style-guide/Ruler/Ruler";

type Attributer = Pick<
  Product,
  "attributes" | "template" | "templateAttributes"
>;

interface Props {
  form: UseFormInterface<Attributer>;
  submit: boolean;
  noSimpleVariants?: boolean;
  readOnly?: boolean;
}

const Attributes: React.FC<Props> = ({
  form,
  submit,
  noSimpleVariants,
  readOnly
}) => {
  const [newId, setNewId] = useState<string | null>(null);
  const attributesUrl = useApiUrl(["attributes"]);

  const templateAttributes = useMemo<ProductTemplateAttribute[]>(() => {
    if (form.data.template) {
      return (form.data.template as ProductTemplate).attributes;
    }

    return [];
  }, [form.data.template]);

  const mergedAttributes = useMemo<ProductTemplateAttribute[]>(() => {
    return [...(form.data.templateAttributes || []), ...templateAttributes];
  }, [form.data.templateAttributes, templateAttributes]);

  const attrSearchOpts = useMemo(
    () => ({
      endpoint: attributesUrl,
      queryParams: {
        _id: mergedAttributes
          .filter((a) => a.ref)
          .map((a) => a.ref)
          .join()
      }
    }),
    [attributesUrl, mergedAttributes]
  );

  const attributesSearch = useSearch<IAttribute>({
    ...attrSearchOpts
  });

  useEffect(() => {
    if (attrSearchOpts.queryParams._id) {
      attributesSearch.search(attrSearchOpts);
    }
  }, [attrSearchOpts.queryParams._id]);

  const attributes = useMemo<ProductTemplateAttribute[]>(() => {
    return mergedAttributes
      .filter((a) => !a.filterable)
      .map((a) => {
        const ref = attributesSearch.results.find((aa) => aa._id === a.ref);

        if (ref) {
          a.populatedRef = ref;
        }

        return a;
      })
      .filter((a) => !!a.populatedRef);
  }, [mergedAttributes, attributesSearch.results]);

  const orphanedAttributes = useMemo<ProductAttribute[]>(() => {
    return form.data.attributes.filter(
      (a) => !attributes.some((aa) => aa.populatedRef!.name === a.name)
    );
  }, [form.data.attributes, attributes]);

  return (
    <Fader>
      <Section hugTop>
        <Card>
          <Block>
            <Flex>
              <FlexKid flex={1}>
                <Text element="h2" variant="title">
                  Tilgjengelige attributter
                </Text>
              </FlexKid>
              {!readOnly && (
                <FlexKid>
                  <Button
                    type="button"
                    outlined
                    onClick={() => {
                      const id = createObjectId();

                      form.setField({
                        path: "templateAttributes",
                        value: [
                          { _id: id },
                          ...(form.data.templateAttributes || [])
                        ],
                        submit
                      });

                      setNewId(id);
                    }}
                  >
                    Legg til attributt
                  </Button>
                </FlexKid>
              )}
            </Flex>
          </Block>
          <InlineEditHead>
            <InlineEditColumn width="400px">Attributt</InlineEditColumn>
            {!noSimpleVariants && (
              <InlineEditColumn width="200px">
                For enkle varianter?
              </InlineEditColumn>
            )}
          </InlineEditHead>
          <Dropper
            id="templateAttributes"
            onDragEnd={(r) => {
              if (r.destination) {
                const list = Array.from(form.data.templateAttributes || []);
                const [removed] = list.splice(r.source.index, 1);

                list.splice(r.destination.index, 0, removed);

                form.setField({
                  path: "templateAttributes",
                  value: list,
                  submit
                });
              }
            }}
          >
            {(form.data.templateAttributes || []).length > 0 ? (
              form.data.templateAttributes!.map((a, k) => {
                const ref = attributesSearch.results.find(
                  (aa) => aa._id === a.ref
                );

                if (ref) {
                  a.populatedRef = ref;
                }

                return (
                  <Dragger
                    key={a._id}
                    id={a._id}
                    index={k}
                    delegateDragHandle
                    isDragDisabled={readOnly}
                  >
                    <Attribute
                      key={a._id}
                      attribute={a}
                      usedAttributes={form.data.templateAttributes!}
                      readOnly={readOnly}
                      noSimpleVariants={noSimpleVariants}
                      isNew={a._id === newId}
                      onChange={(data) =>
                        form.setField({
                          path: "templateAttributes",
                          value: form.data.templateAttributes!.map((aa, kk) => {
                            if (kk === k) {
                              return data;
                            }

                            return aa;
                          }),
                          submit
                        })
                      }
                      onDelete={() => {
                        if (
                          window.confirm("Er du sikker på at du vil slette?")
                        ) {
                          form.setField({
                            path: "templateAttributes",
                            value: form.data.templateAttributes!.filter(
                              (_, kk) => kk !== k
                            ),
                            submit
                          });
                        }
                      }}
                    />
                  </Dragger>
                );
              })
            ) : (
              <>
                <Ruler />
                <Block hugTop>
                  <Text variant="subheading">
                    Ingen attributter lagt til på produkt
                  </Text>
                </Block>
              </>
            )}
          </Dropper>
        </Card>
      </Section>
      {!attributesSearch.loading && orphanedAttributes.length > 0 && (
        <Section>
          <Card>
            <Block>
              <Text element="h2" variant="title">
                Attributter uten tilknytning
              </Text>
            </Block>
            <Table>
              <thead>
                <Tr>
                  <Th>Navn</Th>
                  <Th>Verdi</Th>
                  <Th align="right">Handlinger</Th>
                </Tr>
              </thead>
              <tbody>
                {orphanedAttributes.map((a) => {
                  return (
                    <tr key={a._id}>
                      <Td verticalAlign="middle">{a.name}</Td>
                      <Td verticalAlign="middle">{a.value}</Td>
                      <Td verticalAlign="middle" align="right">
                        <ButtonList align="right">
                          <Button
                            type="button"
                            outlined
                            disabled={readOnly}
                            onClick={() => {
                              if (
                                !readOnly &&
                                window.confirm(
                                  "Er du sikker på at du vil slette?"
                                )
                              ) {
                                form.setField({
                                  path: "attributes",
                                  value: form.data.attributes.filter(
                                    (aa) => aa._id !== a._id
                                  ),
                                  submit: true
                                });
                              }
                            }}
                          >
                            Slett
                          </Button>
                        </ButtonList>
                      </Td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
        </Section>
      )}
      {!attributesSearch.loading && attributes.length === 0 ? (
        <Card outlined>
          <Block>
            <EmptyState
              title="Ingen tilgjengelige attributter!"
              icon={<AiOutlineTags />}
              text={
                <>
                  <Text gutterBottom>
                    For å kunne velge attributter til et produkt, eller
                    produktets enkle varianter, må du legge til minst én
                    tilgjengelig attributt.
                  </Text>
                  <Text gutterBottom>
                    Dette gjøres i ved å trykke på <em>Legg til attributt</em> i
                    boksen til høyre, men det krever at du har opprettet minst
                    én{" "}
                    <strong>
                      <Link to="/butikk/attributter">Attributt</Link>
                    </strong>
                    .
                  </Text>
                  {!noSimpleVariants && (
                    <Text>
                      Dersom en attributt er merket <em>For enkle varianter</em>{" "}
                      kan den kun brukes på enkle varianter.
                    </Text>
                  )}
                </>
              }
            />
          </Block>
        </Card>
      ) : (
        <Card>
          <Block>
            <Text element="h2" variant="title">
              Attributtverdier
            </Text>
          </Block>
          <Table>
            <thead>
              <Tr>
                <Th>Navn</Th>
                <Th>Verdi</Th>
              </Tr>
            </thead>
            <tbody>
              {attributes.map((a) => {
                const attribute = a.populatedRef!;

                return (
                  <AttributeEdit
                    key={attribute._id}
                    attribute={attribute}
                    attributer={form.data}
                    setField={form.setField}
                    saveOnSelect={submit}
                    readOnly={readOnly}
                  />
                );
              })}
            </tbody>
          </Table>
        </Card>
      )}
    </Fader>
  );
};

export function getMetaFieldLabel(type: VariantType): string {
  switch (type) {
    case VariantType.Image:
      return "Bilde";
    case VariantType.Icon:
      return "Ikon";
    case VariantType.Color:
      return "Farge";
    default:
      return "Meta";
  }
}

export default Attributes;
