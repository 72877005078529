import styled from "styled-components";

const Dotter = styled.ul`
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Dot = styled.li`
  display: inline;

  &:not(:last-child)::after {
    content: " · ";
  }
`;

export { Dotter, Dot };
