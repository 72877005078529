import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineFileText } from "react-icons/ai";
import { ProductTemplate } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import { ButtonLink } from "../../style-guide/Button/Button";
import Tabler from "../../components/Tabler/Tabler";

interface Props {}

const ProductTemplates: React.FC<Props> = () => {
  return (
    <Tabler
      title="Produktmaler"
      crumb={{
        label: "Butikk",
        path: "/butikk"
      }}
      actions={
        <ButtonLink to="produktmaler/opprett">Opprett produktmal</ButtonLink>
      }
      emptyState={{
        title: "Opprett din første produktmal",
        icon: <AiOutlineFileText />,
        text:
          "Dersom du har flere produkter som deler attributter eller kundetilpasningsfelter, er det en fordel å samle dem under en felles produktmal, slik at du slipper unødvendig publisering.",
        action: (
          <ButtonLink to="produktmaler/opprett">Opprett produktmal</ButtonLink>
        )
      }}
      url={["product-templates"]}
      columns={[
        {
          Header: "Mal",
          accessor: "title"
        }
      ]}
      createRow={(template: ProductTemplate) => ({
        ...template,
        title: <TitleRow template={template} />
      })}
    />
  );
};

interface TitleRowProps {
  template: ProductTemplate;
}

const TitleRow: React.FC<TitleRowProps> = ({ template }) => {
  return (
    <>
      <Text variant="body2">
        <Link to={`/butikk/produktmaler/${template._id}`}>{template.name}</Link>
      </Text>
      <Text variant="body3">{template.description || "Ingen beskrivelse"}</Text>
    </>
  );
};

export default ProductTemplates;
