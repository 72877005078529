import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import {
  Experiment,
  ExperimentReference,
  ExperimentVariant,
  Page,
  Template
} from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Text from "../../../style-guide/Text/Text";
import {
  Button,
  ButtonLink,
  ButtonList
} from "../../../style-guide/Button/Button";
import {
  InlineEditColumn,
  InlineEditHead
} from "../../../style-guide/InlineEdit/InlineEdit";
import createId from "../../../util/createId";
import InlineEdit from "../../../components/InlineEdit/InlineEdit";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import createInputField from "../../../util/createInputField";
import { useSearch } from "../../../hooks/useApi";
import Label from "../../../style-guide/Inputs/Label";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";
import Lister from "../../../components/Lister/Lister";
import Section from "../../../style-guide/Section/Section";
import { currencyFormat, numberFormat } from "../../../util/intl";
import { StatType } from "@ludens-reklame/rubics-sdk";

interface Props {
  publisher: UsePublisherInterface<Experiment>;
}

const Variants: React.FC<Props> = ({ publisher }) => {
  const form = publisher.form;

  return (
    <Fader>
      <Card>
        <Block>
          <Flex>
            <FlexKid flex={1}>
              <Text element="h2" variant="title">
                Varianter
              </Text>
            </FlexKid>
            <FlexKid>
              <Button
                type="button"
                outlined
                disabled={form.data.active}
                onClick={() => {
                  form.setField({
                    path: "variants",
                    value: [
                      {
                        id: createId(),
                        name: `Variant ${(form.data.variants || []).length}`,
                        data: {}
                      },
                      ...(form.data.variants || [])
                    ]
                  });
                }}
              >
                Ny variant
              </Button>
            </FlexKid>
          </Flex>
        </Block>
        <InlineEditHead>
          <InlineEditColumn width="400px">Navn</InlineEditColumn>
          <InlineEditColumn width="100px">Vekting</InlineEditColumn>
          {form.data.active && (
            <>
              <InlineEditColumn width="100px">Deltakere</InlineEditColumn>
              <InlineEditColumn width="150px">Målfullføringer</InlineEditColumn>
              <InlineEditColumn width="150px">Fullføringsrate</InlineEditColumn>
              <InlineEditColumn width="150px">Målverdi</InlineEditColumn>
            </>
          )}
        </InlineEditHead>
        {(form.data.variants || []).length > 0 ? (
          form.data.variants!.map((v, k) => (
            <Variant
              //@ts-ignore
              key={v._id || v.id}
              variant={v}
              experiment={form.data}
              isNew={!v._id}
              onChange={(data, save) =>
                form.setField({
                  path: "variants",
                  value: (form.data.variants || []).map((vv, kk) => {
                    if (kk === k) {
                      return data;
                    }

                    return vv;
                  }),
                  submit: save
                })
              }
              onDelete={() => {
                if (window.confirm("Er du sikker på at du vil slette?")) {
                  form.setField({
                    path: "variants",
                    value: (form.data.variants || []).filter(
                      (_, kk) => kk !== k
                    )
                  });
                }
              }}
            />
          ))
        ) : (
          <Block hugTop>
            <Text variant="subheading">Ingen varianter enda</Text>
          </Block>
        )}
      </Card>
    </Fader>
  );
};

interface VariantProps {
  variant: ExperimentVariant;
  experiment: Experiment;
  isNew: boolean;
  onChange: (variant: ExperimentVariant, save?: boolean) => any;
  onDelete: () => any;
}

const Variant: React.FC<VariantProps> = ({
  variant,
  experiment,
  isNew,
  onChange,
  onDelete
}) => {
  const [previewPages, setPreviewPages] = useState<Page[]>([]);
  const [previewTemplates, setPreviewTemplates] = useState<Template[]>([]);

  const pagesSearch = useSearch<Page>({
    endpoint: "pages",
    fetchOnMount: (variant.data.pages || []).length > 0,
    queryParams: {
      id: (variant.data.pages || []).map((p) => p.reference).join(","),
      draft: false
    }
  });

  const templatesSearch = useSearch<Template>({
    endpoint: "templates",
    fetchOnMount: (variant.data.templates || []).length > 0,
    queryParams: {
      id: (variant.data.templates || []).map((p) => p.reference).join(","),
      draft: false
    }
  });

  const isBaseline = useMemo<boolean>(() => variant.name === "_baseline", [
    variant.name
  ]);

  useEffect(() => {
    setPreviewPages(pagesSearch.results);
  }, [pagesSearch.results]);

  useEffect(() => {
    setPreviewTemplates(templatesSearch.results);
  }, [templatesSearch.results]);

  return (
    <BusyBoy busy={pagesSearch.loading}>
      <InlineEdit
        expanded={isNew}
        actions={[
          <Button
            type="button"
            aria-label="Slett"
            circular
            outlined
            smaller
            disabled={experiment.active || isBaseline}
            onClick={onDelete}
          >
            <AiOutlineDelete />
          </Button>
        ]}
        headerColumns={[
          {
            width: "400px",
            node: isBaseline ? (
              <Text>Standard</Text>
            ) : (
              createInputField({
                key: "name",
                type: "text",
                value: variant.name,
                required: true,
                hugTop: true,
                readOnly: experiment.active,
                onChange: (value) => {
                  onChange({
                    ...variant,
                    name: value
                  });
                }
              })
            )
          },
          {
            width: "100px",
            node: createInputField({
              key: "weight",
              type: "number",
              value: variant.weight,
              required: true,
              hugTop: true,
              autoFocus: isNew,
              readOnly: experiment.active,
              onChange: (value) => {
                onChange({
                  ...variant,
                  weight: value
                });
              }
            })
          },
          ...(experiment.active && variant.stats
            ? [
                {
                  width: "100px",
                  node: (
                    <Text>{numberFormat.format(variant.stats.entries)}</Text>
                  )
                },
                {
                  width: "150px",
                  node: <Text>{numberFormat.format(variant.stats.goals)}</Text>
                },
                {
                  width: "150px",
                  node: <Text>{variant.stats.successRate * 100}%</Text>
                },
                {
                  width: "150px",
                  node: (
                    <Text>
                      {experiment.goal.type === StatType.OrderCreated ||
                      experiment.goal.type === StatType.OrderLine ||
                      experiment.goal.type === StatType.CartAdd
                        ? `${currencyFormat.format(variant.stats.goalValue)}`
                        : numberFormat.format(variant.stats.goalValue)}
                    </Text>
                  )
                }
              ]
            : [])
        ]}
      >
        {!isBaseline && (
          <Block>
            {experiment.active ? (
              <Text variant="body3" align="center">
                Eksperiment er underveis!
              </Text>
            ) : (
              <Flex>
                <FlexKid flex={2}>
                  <Section hugTop>
                    <Label>Sider</Label>
                    <Lister
                      // @ts-ignore
                      id={`variant_pages_${variant._id || variant.id}`}
                      items={(variant.data.pages || []).map((p) => p.reference)}
                      previewItems={previewPages}
                      url={["pages"]}
                      searchKey="title"
                      labelKey="title"
                      itemToString={(p) => p?.title}
                      disableDrag
                      renderRightIcon={(item) => (
                        <Link
                          to={`/innhold/sider/${item}?experiment=${experiment._id}&experimentVariant=${variant._id}`}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <AiOutlineEdit />
                        </Link>
                      )}
                      onChange={(items, previewItems) => {
                        const pages: ExperimentReference[] = items.map((id) => {
                          const existing = (variant.data.pages || []).find(
                            (p) => p.reference === id
                          );

                          if (!existing) {
                            return {
                              reference: id,
                              data: {}
                            };
                          }

                          return existing;
                        });

                        onChange(
                          {
                            ...variant,
                            data: {
                              ...variant.data,
                              pages
                            }
                          },
                          true
                        );

                        setPreviewPages(previewItems);
                      }}
                      query={{
                        draft: false
                      }}
                      filter={(p) =>
                        !(variant.data.pages || []).some(
                          (pp) => p._id === pp.reference
                        )
                      }
                    />
                  </Section>
                  <Section>
                    <Label>Maler</Label>
                    <Lister
                      // @ts-ignore
                      id={`variant_templates_${variant._id || variant.id}`}
                      items={(variant.data.templates || []).map(
                        (p) => p.reference
                      )}
                      previewItems={previewTemplates}
                      url={["templates"]}
                      searchKey="name"
                      labelKey="name"
                      itemToString={(p) => p?.name}
                      disableDrag
                      renderRightIcon={(item) => (
                        <Link
                          to={`/innstillinger/maler/liste/${item}?experiment=${experiment._id}&experimentVariant=${variant._id}`}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <AiOutlineEdit />
                        </Link>
                      )}
                      onChange={(items, previewItems) => {
                        const templates: ExperimentReference[] = items.map(
                          (id) => {
                            const existing = (
                              variant.data.templates || []
                            ).find((p) => p.reference === id);

                            if (!existing) {
                              return {
                                reference: id,
                                data: {}
                              };
                            }

                            return existing;
                          }
                        );

                        onChange(
                          {
                            ...variant,
                            data: {
                              ...variant.data,
                              templates
                            }
                          },
                          true
                        );

                        setPreviewTemplates(previewItems);
                      }}
                      query={{
                        draft: false
                      }}
                      filter={(p) =>
                        !(variant.data.pages || []).some(
                          (pp) => p._id === pp.reference
                        )
                      }
                    />
                  </Section>
                  <Section hugBottom>
                    <Label>Globale endringer</Label>
                    <ButtonList align="stretch">
                      <ButtonLink
                        outlined
                        to={`/designer/temainnstillinger?experiment=${experiment._id}&experimentVariant=${variant._id}`}
                      >
                        Endre tema
                      </ButtonLink>
                      <ButtonLink
                        outlined
                        to={`/designer/globalt?experiment=${experiment._id}&experimentVariant=${variant._id}`}
                      >
                        Endre globalt innhold
                      </ButtonLink>
                    </ButtonList>
                  </Section>
                </FlexKid>
                <FlexKid flex={1} spaceLeft></FlexKid>
              </Flex>
            )}
          </Block>
        )}
      </InlineEdit>
    </BusyBoy>
  );
};

export default Variants;
