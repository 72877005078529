import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import Downshift from "downshift";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { useSearch } from "../../hooks/useApi";
import { RequestData } from "../../util/api";
import TextInput from "../../style-guide/Inputs/TextInput";
import { Button, ButtonList } from "../../style-guide/Button/Button";
import Card from "../../style-guide/Card/Card";
import BusyBoy from "../BusyBoy/BusyBoy";
import { Elements, Element } from "../../style-guide/Elements/Elements";
import useApiUrl from "../../hooks/useApiUrl";
import Text from "../../style-guide/Text/Text";

const Wrapper = styled.div`
  position: relative;
`;

const ElementsWrapper = styled.div`
  left: 0;
  z-index: 2;
  right: 0;
  overflow-y: auto;
`;

const Actions = styled.div`
  position: absolute;
  right: 6px;
  top: 6px;
  display: flex;
  z-index: 1;
`;

export type ItemToString = (item: any) => string;
export type ArrayFilter = (item: any) => boolean;

interface Props {
  url: string[];
  itemToString: ItemToString;
  itemToSubString?: ItemToString;
  searchKey: string;
  onChange?: (item: any) => any;
  value?: string;
  query?: RequestData;
  nonDeletable?: boolean;
  required?: boolean;
  filter?: ArrayFilter;
  placeholder?: string;
  readOnly?: boolean;
  autoFocus?: boolean;
  id?: string;
}

const SearchAndSelect: React.FC<Props> = ({
  url,
  itemToString,
  itemToSubString,
  searchKey,
  onChange,
  value,
  query,
  nonDeletable,
  required,
  filter,
  placeholder,
  readOnly,
  autoFocus,
  id
}) => {
  const endpoint = useApiUrl(url);
  const queryOpts = {
    endpoint,
    limit: 10,
    queryParams: {
      ...(query || {}),
      [searchKey]: value
    }
  };

  const [internalValue, setInternalValue] = useState(value);
  const [editing, setEditing] = useState(false);
  const { results, search, loading } = useSearch<any>(queryOpts);

  useEffect(() => setInternalValue(value), [value]);

  const filteredResults = useMemo(
    () =>
      results.filter((r) => {
        if (filter) {
          return filter(r);
        }

        return true;
      }),
    [results, filter]
  );

  if (readOnly) {
    return (
      <TextInput
        required={required}
        placeholder={placeholder}
        value={value}
        id={id}
        readOnly
      />
    );
  }

  return (
    <Downshift
      inputValue={internalValue}
      isOpen={editing}
      initialSelectedItem={value}
      itemToString={itemToString}
      onChange={(item) => {
        if (typeof onChange === "function") {
          onChange(item);
        }

        setInternalValue(itemToString(item));
      }}
      onInputValueChange={(value) => {
        if (typeof value === "string") {
          if (value !== internalValue) {
            search({
              ...queryOpts,
              queryParams: {
                ...queryOpts.queryParams,
                [searchKey]: value
              }
            });
          }

          setInternalValue(value);
        }
      }}
      onStateChange={(state) => {
        if (typeof state.isOpen === "boolean" && state.isOpen !== editing) {
          setEditing(state.isOpen);

          if (state.isOpen) {
            search(queryOpts);
          }
        }

        if (state.type === "__autocomplete_change_input__") {
          setInternalValue(state.inputValue || "");
        }
      }}
    >
      {({
        getInputProps,
        getMenuProps,
        isOpen,
        getItemProps,
        highlightedIndex
      }) => (
        <div>
          <Wrapper>
            <TextInput
              {...getInputProps()}
              required={required}
              placeholder={placeholder}
              autoFocus={autoFocus}
              id={id}
            />
            <Actions>
              <ButtonList tight>
                {!!internalValue && !nonDeletable && (
                  <Button
                    type="button"
                    onClick={() => {
                      if (
                        window.confirm(
                          "Er du sikker på at du vil fjerne denne?"
                        )
                      ) {
                        if (typeof onChange === "function") {
                          onChange(undefined);
                        }

                        setInternalValue("");
                        search({
                          ...queryOpts,
                          queryParams: {
                            ...queryOpts.queryParams,
                            [searchKey]: ""
                          }
                        });
                      }
                    }}
                    outlined
                    circular
                    smaller
                  >
                    <AiOutlineDelete />
                  </Button>
                )}
                <Button
                  type="button"
                  onClick={() => {
                    setEditing(!editing);

                    if (!editing) {
                      search(queryOpts);
                    }
                  }}
                  outlined
                  circular
                  smaller
                  tabIndex={-1}
                >
                  {isOpen ? <FaAngleUp /> : <FaAngleDown />}
                </Button>
              </ButtonList>
            </Actions>
            {isOpen && (
              <ElementsWrapper>
                <Card>
                  <Elements {...getMenuProps()}>
                    <BusyBoy busy={loading}>
                      {filteredResults.length > 0 ? (
                        filteredResults.map((r, k) => (
                          <Element
                            rightIcon={<AiOutlinePlus />}
                            subText={
                              itemToSubString ? itemToSubString(r) : undefined
                            }
                            {...getItemProps({
                              key: r._id,
                              index: k,
                              item: r,
                              className:
                                highlightedIndex === k ? "active" : undefined
                            })}
                          >
                            {itemToString(r)}
                          </Element>
                        ))
                      ) : (
                        <Element>
                          <Text variant="body3">Ingen treff!</Text>
                        </Element>
                      )}
                    </BusyBoy>
                  </Elements>
                </Card>
              </ElementsWrapper>
            )}
          </Wrapper>
        </div>
      )}
    </Downshift>
  );
};

export default SearchAndSelect;
