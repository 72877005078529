import React from "react";
import { Switch, Route } from "react-router";
import Discounts from "./Discounts";
import Discount from "./Discount";

const DiscountsRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/butikk/tilbud" component={Discounts} />
      <Route
        path="/butikk/tilbud/:discount"
        render={(props) => (
          <Discount key={props.match.params.discount} {...props} />
        )}
      />
    </Switch>
  );
};

export default DiscountsRoutes;
