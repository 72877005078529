import React, { useEffect } from "react";
import { RouteComponentProps, Prompt, Switch, Route } from "react-router-dom";
import { Experiment as IExperiment } from "../../types/apiResponses";
import { AiOutlineCopy, AiOutlineProfile } from "react-icons/ai";
import Text from "../../style-guide/Text/Text";
import Form from "../../style-guide/Inputs/Form";
import Fader from "../../style-guide/Fader/Fader";
import Block from "../../style-guide/Block/Block";
import usePublisher from "../../hooks/usePublisher";
import PublisherHelpers from "../../util/PublisherHelpers";
import {
  InlineActions,
  InlineActionLink
} from "../../style-guide/InlineActions/InlineActions";
import Section from "../../style-guide/Section/Section";
import ActionBar from "../../components/ActionBar/ActionBar";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import Crumb from "../../components/Crumb/Crumb";
import { LEAVE_PAGE_MESSAGE } from "../../constants/general";
import General from "./SubViews/General";
import Variants from "./SubViews/Variants";

interface Props
  extends RouteComponentProps<{
    experiment: string;
  }> {}

const Experiment: React.FC<Props> = ({ match }) => {
  const experimentId = match.params.experiment;
  const breadcrumbs = useBreadcrumbs();

  const publisher = usePublisher<IExperiment>({
    id: experimentId,
    baseUrl: ["experiments"],
    baseDashboardUrl: "/eksperimenter",
    initialData: {
      name: "",
      type: "page",
      active: false,
      goal: {
        type: "pageView"
      },
      variants: []
    },
    pathsToCopy: [
      {
        path: "name",
        label: "Navn"
      },
      {
        path: "type",
        label: "Type"
      },
      {
        path: "description",
        label: "Beskrivelse"
      },
      {
        path: "fromDate",
        label: "Fra-tidspunkt"
      },
      {
        path: "toDate",
        label: "Til-tidspunkt"
      },
      {
        path: "reference",
        label: "Referanse"
      },
      {
        path: "goal",
        label: "Mål"
      },
      {
        path: "variants",
        label: "Varianter"
      }
    ]
  });

  useEffect(() => {
    breadcrumbs.swapEntry(experimentId, publisher.form.data.name);
  }, [breadcrumbs, publisher.form.data, experimentId]);

  return (
    <Form onSubmit={publisher.form.submit}>
      <Prompt
        when={publisher.form.hasMadeChanges}
        message={(location) => {
          if (location.pathname.includes(experimentId)) {
            return true;
          }

          return LEAVE_PAGE_MESSAGE;
        }}
      />
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/eksperimenter">Eksperimenter</Crumb>
            {PublisherHelpers.getResourceTitle(
              publisher,
              "name",
              "Nytt eksperiment"
            )}
          </Text>
        </Block>
        <Block hugTop hugBottom>
          <InlineActions>
            <InlineActionLink exact to={publisher.dashboardUrl}>
              <AiOutlineProfile /> Generelt
            </InlineActionLink>
            {!publisher.isNew && (
              <InlineActionLink
                exact
                to={`${publisher.dashboardUrl}/varianter`}
              >
                <AiOutlineCopy /> Varianter
              </InlineActionLink>
            )}
          </InlineActions>
        </Block>
        <Section hugTop>
          <Switch>
            <Route
              exact
              path={publisher.dashboardUrl}
              render={() => <General publisher={publisher} />}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/varianter`}
              render={() => <Variants publisher={publisher} />}
            />
          </Switch>
        </Section>
      </Fader>
      <ActionBar publisher={publisher} destroy />
    </Form>
  );
};

export default Experiment;
