import React from "react";
import { Switch, Route } from "react-router";
import Catalogs from "./Catalogs";
import Catalog from "./Catalog";
import { CatalogType } from "../../constants/api";

const CatalogsRoutes: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path="/butikk/produktkataloger"
        render={(props) => (
          <Catalogs key="product" type={CatalogType.Product} {...props} />
        )}
      />
      <Route
        path="/butikk/produktkataloger/:catalog"
        render={(props) => (
          <Catalog
            key={props.match.params.catalog}
            type={CatalogType.Product}
            {...props}
          />
        )}
      />
      <Route
        exact
        path="/innhold/innholdskataloger"
        render={(props) => (
          <Catalogs key="content" type={CatalogType.Content} {...props} />
        )}
      />
      <Route
        path="/innhold/innholdskataloger/:catalog"
        render={(props) => (
          <Catalog
            key={props.match.params.catalog}
            type={CatalogType.Content}
            {...props}
          />
        )}
      />
    </Switch>
  );
};

export default CatalogsRoutes;
