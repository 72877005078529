import React, { useContext } from "react";
import { Member } from "../../../types/apiResponses";
import Text from "../../../style-guide/Text/Text";
import { UserContext } from "../../../context/User";
import localize from "../../../util/localize";
import { memberPermissions } from "../../../constants/localizations";
import { useSearch, useApi } from "../../../hooks/useApi";
import useApiUrl from "../../../hooks/useApiUrl";
import { Table, Tr, Th, Td } from "../../../style-guide/Table/Table";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";
import Select from "../../../style-guide/Inputs/Select";
import Card from "../../../style-guide/Card/Card";
import useForm from "../../../hooks/useForm";
import useNotifications from "../../../hooks/useNotifications";
import { Button } from "../../../style-guide/Button/Button";

const Members: React.FC = () => {
  const {
    data: { id }
  } = useContext(UserContext);
  const membersUrl = useApiUrl(["members"]);

  const membersSearch = useSearch<Member>({
    endpoint: membersUrl,
    fetchOnMount: true
  });

  return (
    <BusyBoy busy={membersSearch.loading}>
      <Card>
        <Table>
          <thead>
            <Tr>
              <Th>Navn</Th>
              <Th>E-postadresse</Th>
              <Th>Tilgangsnivå</Th>
              <Th align="right">Handlinger</Th>
            </Tr>
          </thead>
          <tbody>
            {membersSearch.results.map((member) => (
              <PermissionHandler
                member={member}
                currentUserID={id}
                refetchMembers={() =>
                  membersSearch.search({ endpoint: membersUrl })
                }
              />
            ))}
          </tbody>
        </Table>
      </Card>
    </BusyBoy>
  );
};

const PermissionHandler: React.FC<{
  member: Member;
  currentUserID: string;
  refetchMembers: () => void;
}> = ({ member, currentUserID, refetchMembers }) => {
  const notifications = useNotifications();
  const isMe = member.user === currentUserID;
  const memberUrl = `members/${member._id}`;
  const memberDeleteUrl = `members/${member.user}`;

  const { data, setField, submitting } = useForm<Member>(
    { permissions: "" },
    {
      endpoint: memberUrl,
      method: "PATCH",
      diff: true,
      onSuccess: () => {
        notifications.spawn({
          title: `Rettigheter endret for ${member.userData.name}`
        });
      }
    }
  );

  const deleteMemberRequest = useApi<any>({
    endpoint: memberDeleteUrl,
    method: "DELETE",
    initialData: null,
    onSuccess: () => {
      notifications.spawn({
        title: `Bruker slettet`
      });
      refetchMembers();
    }
  });

  return (
    <BusyBoy as={Tr} busy={deleteMemberRequest.loading}>
      <Td verticalAlign="middle">
        {member.userData.name}{" "}
        {isMe && (
          <Text element="span" variant="subtitle">
            (Deg)
          </Text>
        )}
      </Td>
      <Td verticalAlign="middle">
        <Text variant="body3">{member.userData.email}</Text>
      </Td>
      <Td verticalAlign="middle">
        {!isMe && member.permissions !== "admin" ? (
          <Select
            value={data.permissions || member.permissions}
            disabled={submitting}
            onChange={(e) => {
              if (
                window.confirm(
                  `Er du sikker på at du vil endre tilgangsnivået til ${
                    member.userData.name || member.userData.email
                  }?`
                )
              ) {
                setField({
                  path: "permissions",
                  value: e.target.value,
                  submit: true
                });
              }
            }}
          >
            {Object.keys(memberPermissions).map((k) => {
              return <option value={k}>{memberPermissions[k]}</option>;
            })}
          </Select>
        ) : (
          <Text variant="body3">
            {localize(memberPermissions, member.permissions)}
          </Text>
        )}
      </Td>
      <Td align="right">
        <Button
          disabled={isMe}
          outlined
          onClick={(e) => {
            e.preventDefault();

            if (window.confirm("Er du sikker på at du vil fjerne bruker?")) {
              deleteMemberRequest.fetch();
            }
          }}
        >
          Fjern
        </Button>
      </Td>
    </BusyBoy>
  );
};

export default Members;
