import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineTags } from "react-icons/ai";
import { Attribute } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import { ButtonLink } from "../../style-guide/Button/Button";
import Tabler from "../../components/Tabler/Tabler";
import { Dot, Dotter } from "../../style-guide/Dotter/Dotter";

interface Props {}

const Attributes: React.FC<Props> = () => {
  return (
    <Tabler
      title="Attributter"
      crumb={{
        label: "Butikk",
        path: "/butikk"
      }}
      actions={
        <ButtonLink to="attributter/opprett">Opprett attributt</ButtonLink>
      }
      emptyState={{
        title: "Opprett din første attributt",
        icon: <AiOutlineTags />,
        text: (
          <>
            <Text gutterBottom>
              Attributter brukes for å skille produkter som enten er koblet
              sammen, eller har varianter.
            </Text>
            <Text>
              Attributter kan være hva som helst, men typisk er det{" "}
              <em>Farge</em>, <em>Størrelse</em>, etc.
            </Text>
          </>
        ),
        action: (
          <ButtonLink to="attributter/opprett">Opprett attributt</ButtonLink>
        )
      }}
      url={["attributes"]}
      columns={[
        {
          Header: "Attributt",
          accessor: "title"
        },
        {
          Header: "Valg",
          accessor: "options"
        }
      ]}
      createRow={(attribute: Attribute) => ({
        ...attribute,
        title: <TitleRow attribute={attribute} />,
        options: (
          <Text variant="body3">
            {attribute.options.map((o) => o.value).join(", ")}
          </Text>
        )
      })}
    />
  );
};

interface TitleRowProps {
  attribute: Attribute;
}

const TitleRow: React.FC<TitleRowProps> = ({ attribute }) => {
  return (
    <>
      <Text variant="body2">
        <Link to={`/butikk/attributter/${attribute._id}`}>
          {attribute.internalName || attribute.name}
        </Link>
      </Text>
      <Text variant="body3">
        <Dotter>
          <Dot>{attribute.name}</Dot>
          {attribute.description && <Dot>{attribute.description}</Dot>}
        </Dotter>
      </Text>
    </>
  );
};

export default Attributes;
