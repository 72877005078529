import React from "react";
import { FaBoxes } from "react-icons/fa";
import { AiOutlineGlobal } from "react-icons/ai";
import Fader from "../../../style-guide/Fader/Fader";
import Text from "../../../style-guide/Text/Text";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import { CardLink } from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Icon from "../../../components/Icon/Icon";
import Crumb from "../../../components/Crumb/Crumb";
import Section from "../../../style-guide/Section/Section";
import Grid from "../../../style-guide/Grid/Grid";

const ShippingNavigation: React.FC = () => {
  return (
    <Fader>
      <Block hugTop>
        <Text element="h1" variant="display3">
          <Crumb url="/Innstillinger">Innstillinger</Crumb>
          Frakt
        </Text>
      </Block>
      <Section hugTop>
        <Grid gap="medium" columns={3}>
          <CardLink to="/innstillinger/frakt/soner" stretch>
            <Block flex>
              <Flex flex={1}>
                <FlexKid flex={1}>
                  <Text element="h2" variant="title">
                    Fraktsoner
                  </Text>
                  <Text variant="body3">Administrer fraktsoner og metoder</Text>
                </FlexKid>
                <FlexKid spaceLeft>
                  <Icon size="large">
                    <AiOutlineGlobal />
                  </Icon>
                </FlexKid>
              </Flex>
            </Block>
          </CardLink>
          <CardLink to="/innstillinger/frakt/klasser">
            <Block flex>
              <Flex flex={1}>
                <FlexKid flex={1}>
                  <Text element="h2" variant="title">
                    Fraktklasser
                  </Text>
                  <Text variant="body3">Administrer fraktklasser</Text>
                </FlexKid>
                <FlexKid spaceLeft>
                  <Icon size="large">
                    <FaBoxes />
                  </Icon>
                </FlexKid>
              </Flex>
            </Block>
          </CardLink>
        </Grid>
      </Section>
    </Fader>
  );
};

export default ShippingNavigation;
