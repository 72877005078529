import React from "react";
import styled from "styled-components";
import ellipsis from "../../util/ellipsis";

const Wrapper = styled.div`
  padding: ${props => props.theme.spacing.small};
  border: 1px solid ${props => props.theme.colors.borderDim};
  width: 100%;
  background: white;
`;

const GoogleTitle = styled.h1`
  font-size: 1.125rem;
  line-height: 1.33;
  font-weight: normal;
  color: #190eab;
  margin: 2px 0;
`;

const GoogleURL = styled.p`
  color: #006621;
  margin: 2px 0;
  font-size: 0.875rem;
`;

const GoogleDescription = styled.p`
  font-size: small;
  color: #545454;
  line-height: 1.54;
  word-wrap: break-word;
  margin: 2px 0;

  span {
    color: #777;
  }
`;

interface Props {
  title?: string;
  description?: string;
  date?: string;
  url: string;
}

const GooglePreview: React.FC<Props> = ({ title, description, date, url }) => {
  return (
    <Wrapper>
      {title && <GoogleTitle>{title}</GoogleTitle>}
      <GoogleURL>{url}</GoogleURL>
      {description && (
        <GoogleDescription>
          <span>{date && date}</span> - {ellipsis(description)}
        </GoogleDescription>
      )}
    </Wrapper>
  );
};

export default GooglePreview;
