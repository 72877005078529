import React, { useCallback, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { CgListTree } from "react-icons/cg";
import { AiOutlineDelete } from "react-icons/ai";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import Fader from "../../../style-guide/Fader/Fader";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Card from "../../../style-guide/Card/Card";
import { useSearch } from "../../../hooks/useApi";
import { Product } from "../../../types/apiResponses";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import ProductPicker, {
  ProductTable
} from "../../../components/ProductPicker/ProductPicker";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import { Button, ButtonList } from "../../../style-guide/Button/Button";
import EmptyState from "../../../style-guide/EmptyState/EmptyState";
import Form from "../../../style-guide/Inputs/Form";
import {
  Modal,
  ModalActions,
  ModalBody
} from "../../../style-guide/Modal/Modal";

interface Props {
  publisher: UsePublisherInterface<Product>;
}

const CrossSell: React.FC<Props> = ({ publisher }) => {
  const { spawnModal } = useModal();
  const form = publisher.form;
  const product = form.data;
  const readOnly = publisher.isRevision;
  const crossSell = product.crossSell || [];

  const queryOpts = {
    endpoint: "products",
    queryParams: {
      draft: false,
      crossSell: product._id
    }
  };

  const crossSellProductsSearch = useSearch<Product>({
    ...queryOpts,
    fetchOnMount: true
  });

  const addProductsModal = useCallback(() => {
    spawnModal(
      <CrossSellModal
        excludeIds={[
          product.draftFor!,
          ...crossSell.map((i) => i.product as string)
        ]}
        onSelect={(products) => {
          form.setField({
            path: "crossSell",
            value: [...crossSell, ...products.map((p) => ({ product: p._id }))],
            submit: true,
            onSuccess: () => crossSellProductsSearch.search(queryOpts)
          });
        }}
      />
    );
  }, [spawnModal, crossSell, queryOpts]);

  return (
    <Fader>
      {crossSellProductsSearch.hasSearched &&
        crossSellProductsSearch.results.length > 0 && (
          <Card>
            <Block>
              <Flex>
                <FlexKid flex={1}>
                  <Text element="h2" variant="title">
                    Produkter for kryss-salg
                  </Text>
                </FlexKid>
                {!readOnly && (
                  <FlexKid>
                    <Button type="button" outlined onClick={addProductsModal}>
                      Legg til produkter
                    </Button>
                  </FlexKid>
                )}
              </Flex>
            </Block>
            <InfiniteScroll
              pageStart={2}
              initialLoad={false}
              loadMore={() => {
                crossSellProductsSearch.search({
                  ...queryOpts,
                  paginate: true
                });
              }}
              hasMore={crossSellProductsSearch.hasMore}
              useWindow
            >
              <ProductTable
                products={crossSellProductsSearch.results}
                linkable
                renderActions={(p) => {
                  if (!crossSell.some((c) => c.product === p._id)) {
                    return (
                      <Text variant="body3" align="right">
                        Fra mal
                      </Text>
                    );
                  }

                  return (
                    <Button
                      type="button"
                      aria-label="Fjern produkt fra kryss-salg"
                      circular
                      outlined
                      smaller
                      disabled={readOnly}
                      onClick={() => {
                        if (readOnly) {
                          return;
                        }

                        form.setField({
                          path: "crossSell",
                          value: crossSell.filter((i) => i.product !== p._id),
                          submit: true,
                          onSuccess: () =>
                            crossSellProductsSearch.search(queryOpts)
                        });
                      }}
                    >
                      <AiOutlineDelete />
                    </Button>
                  );
                }}
              />
            </InfiniteScroll>
          </Card>
        )}
      {crossSellProductsSearch.hasSearched &&
        crossSellProductsSearch.results.length === 0 && (
          <Block>
            <EmptyState
              title="Ingen produkter lagt til for kryss-salg"
              icon={<CgListTree />}
              text={
                <Text>
                  Kryss-salg kan brukes til å vise relaterte produkter på en
                  produktside. Du kan enten koble produkter for kryss-salg på
                  enkeltprodukter, som her, eller ved bruk av{" "}
                  <em>Produktmaler</em>.
                </Text>
              }
              action={
                <Button onClick={addProductsModal}>Legg til produkter</Button>
              }
            />
          </Block>
        )}
    </Fader>
  );
};

interface CrossSellModalProps {
  excludeIds: string[];
  onSelect: (products: Product[]) => any;
}

export const CrossSellModal: React.FC<CrossSellModalProps> = ({
  excludeIds,
  onSelect
}) => {
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);

  const modalBodyRef = useRef<HTMLElement | null>(null);
  const { despawnModal } = useModal();

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSelect(selectedProducts);
        despawnModal();
      }}
    >
      <Modal fullscreen>
        <ModalBody ref={(ref) => (modalBodyRef.current = ref)}>
          <Block>
            <Text variant="title" gutterBottom>
              Finn produkter som skal kryss-selges
            </Text>
            <ProductPicker
              useWindow={false}
              getScrollParent={() => modalBodyRef.current}
              queryParams={{ draft: false }}
              filter={(p) => !excludeIds.includes(p._id)}
              renderView={(products) => (
                <ProductTable
                  products={products}
                  multipleSelect
                  onSelect={(products) => setSelectedProducts(products)}
                />
              )}
            />
          </Block>
        </ModalBody>
        <ModalActions>
          <ButtonList align="right">
            <Button
              type="button"
              outlined
              onClick={(e) => {
                despawnModal();
              }}
            >
              Avbryt
            </Button>
            <Button type="submit" disabled={selectedProducts.length < 1}>
              Legg til produkter
            </Button>
          </ButtonList>
        </ModalActions>
      </Modal>
    </Form>
  );
};

export default CrossSell;
