import React, { useMemo, useState, useCallback, useEffect } from "react";
import moment from "moment";
import Decimal from "decimal.js";
import { AiOutlineCloudUpload, AiOutlineHourglass } from "react-icons/ai";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import {
  GenericResponse,
  Order,
  OrderPayment,
  OrderPaymentItem,
  OrderRefund
} from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import Section from "../../../style-guide/Section/Section";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import { Table, Th, Tr, Td } from "../../../style-guide/Table/Table";
import { currencyFormat } from "../../../util/intl";
import Ruler from "../../../style-guide/Ruler/Ruler";
import { orderPaymentStatus } from "../../../constants/localizations";
import { OrderPaymentStatus } from "../../../constants/api";
import { Button, ButtonList } from "../../../style-guide/Button/Button";
import api from "../../../util/api";
import useNotifications from "../../../hooks/useNotifications";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";
import localize from "../../../util/localize";
import {
  Modal,
  ModalActions,
  ModalBody
} from "../../../style-guide/Modal/Modal";
import Form from "../../../style-guide/Inputs/Form";
import createInputField from "../../../util/createInputField";

interface Props {
  publisher: UsePublisherInterface<Order>;
}

const Payments: React.FC<Props> = ({ publisher }) => {
  const order = publisher.form.data;

  const [loading, setLoading] = useState<boolean>(false);
  const notifications = useNotifications();
  const { spawnModal } = useModal();

  const handlePayment = useCallback(
    async (payment: OrderPayment) => {
      if (!loading) {
        setLoading(true);
        notifications.spawn({
          title: "Aktiverer betaling…",
          icon: <AiOutlineCloudUpload />
        });

        try {
          await api({
            method: "POST",
            endpoint: `orders/${order._id}/payments/${payment._id}/charge`
          });

          await publisher.form.refresh();

          notifications.spawn({ title: "Betaling aktivert" });
        } catch (error) {
          console.error(error);
        }

        setLoading(false);
      }
    },
    [order, loading, notifications]
  );

  return (
    <Fader>
      {(order.payments || []).length > 0 && (
        <BusyBoy busy={loading}>
          <Section hugTop>
            <Card>
              <Table>
                <thead>
                  <Tr>
                    <Th>Reservert</Th>
                    <Th>Trukket</Th>
                    <Th>Metode</Th>
                    <Th align="right">Å betale</Th>
                    <Th align="right">Reservert sum</Th>
                    <Th align="right">Trukket sum</Th>
                    <Th align="center">Handlinger</Th>
                  </Tr>
                </thead>
                <tbody>
                  {order.payments.map((p) => (
                    <Tr key={p._id}>
                      <Td verticalAlign="middle">
                        {p.reservationTimestamp ? (
                          moment(p.reservationTimestamp).format(
                            "D[.] MMMM Y [kl.] HH:mm"
                          )
                        ) : (
                          <Text variant="body3">Nei</Text>
                        )}
                      </Td>
                      <Td verticalAlign="middle">
                        {p.chargeTimestamp ? (
                          moment(p.chargeTimestamp).format(
                            "D[.] MMMM Y [kl.] HH:mm"
                          )
                        ) : p.chargeStatus === OrderPaymentStatus.Waiting ? (
                          <Text variant="body3">Nei</Text>
                        ) : (
                          <Text>
                            {localize(orderPaymentStatus, p.chargeStatus)}
                          </Text>
                        )}
                      </Td>
                      <Td verticalAlign="middle">
                        {p.method ? (
                          `${p.method} (${p.provider})`
                        ) : (
                          <Text variant="body3">N/A</Text>
                        )}
                      </Td>
                      <Td verticalAlign="middle" align="right">
                        {currencyFormat.format(p.total)}
                      </Td>
                      <Td verticalAlign="middle" align="right">
                        {currencyFormat.format(p.reservedSum)}
                      </Td>
                      <Td verticalAlign="middle" align="right">
                        {currencyFormat.format(p.chargedSum)}
                      </Td>
                      <Td verticalAlign="middle" align="right">
                        <ButtonList vertical tight align="stretch">
                          <Button
                            outlined
                            smaller
                            aria-label="Aktiver betaling"
                            disabled={
                              publisher.isRevision ||
                              p.chargeStatus !== OrderPaymentStatus.Waiting ||
                              p.charged ||
                              p.reservationStatus === OrderPaymentStatus.Voided
                            }
                            onClick={async () => {
                              if (
                                !p.charged &&
                                window.confirm(
                                  "Er du sikker på at du vil aktivere betaling?"
                                )
                              ) {
                                await handlePayment(p);
                              }
                            }}
                          >
                            Aktiver
                          </Button>
                          <Button
                            outlined
                            smaller
                            aria-label="Refunder betaling"
                            disabled={
                              publisher.isRevision ||
                              p.fullyRefunded ||
                              (p.reserved &&
                                (!p.charged ||
                                  p.chargeStatus ===
                                    OrderPaymentStatus.Processing))
                            }
                            onClick={() =>
                              spawnModal(
                                <RefundModal
                                  order={order}
                                  payment={p}
                                  onRefund={publisher.form.refresh}
                                />
                              )
                            }
                          >
                            Refunder
                          </Button>
                        </ButtonList>
                      </Td>
                    </Tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Section>
        </BusyBoy>
      )}
      {(order.refunds || []).length > 0 && (
        <BusyBoy busy={loading}>
          <Section>
            <Card>
              <Block>
                <Text element="h2" variant="title">
                  Refunderinger
                </Text>
              </Block>
              <Table>
                <thead>
                  <Tr>
                    <Th>Refundert</Th>
                    <Th>Kommentar</Th>
                    <Th align="right">Refundert sum</Th>
                  </Tr>
                </thead>
                <tbody>
                  {order.refunds.map((r) => (
                    <Tr key={r._id}>
                      <Td>
                        {moment(r.timestamp).format("D[.] MMMM Y [kl.] HH:mm")}
                      </Td>
                      <Td>
                        {r.note || <Text variant="body3">Ingen kommentar</Text>}
                      </Td>
                      <Td align="right">{currencyFormat.format(r.total)}</Td>
                    </Tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Section>
        </BusyBoy>
      )}
    </Fader>
  );
};

interface LineRefundData extends Pick<OrderPaymentItem, "_id" | "type"> {
  amount: number;
  sum: number;
}

interface RefundModalProps {
  order: Order;
  payment: OrderPayment;
  onRefund: () => any;
}

const RefundModal: React.FC<RefundModalProps> = ({
  order,
  payment,
  onRefund
}) => {
  const [sum, setSum] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [restock, setRestock] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [lineData, setLineData] = useState<LineRefundData[]>([]);
  const { despawnModal } = useModal();
  const notifications = useNotifications();

  const isSinglePayment = useMemo<boolean>(
    () => (order.payments || []).length === 1,
    [order]
  );

  const existingRefunds = useMemo<OrderRefund[]>(() => {
    return (order.refunds || []).filter((r) => r.payment === payment._id);
  }, [order, payment]);

  const totalAvailableForRefund = useMemo<number>(() => {
    return new Decimal(payment.total)
      .minus(payment.refundedSum || 0)
      .toNumber();
  }, [payment]);

  const refund = useCallback(async () => {
    try {
      setLoading(true);

      notifications.spawn({
        title: "Refunderer betaling…",
        icon: <AiOutlineHourglass />
      });

      const response = await api<GenericResponse | OrderRefund>({
        method: "POST",
        endpoint: `orders/${order._id}/payments/${payment._id}/refunds`,
        body: {
          sum: parseFloat(sum),
          note,
          items: lineData
            .filter(
              (l) =>
                (typeof l.sum === "number" && l.sum > 0) ||
                (typeof l.amount === "number" && l.amount > 0)
            )
            .map((l) => ({
              ref: l._id,
              amount: l.amount,
              sum: l.sum,
              restock: l.type === "orderLine" && restock
            }))
        }
      });

      if ("status" in response) {
        throw new Error();
      } else {
        notifications.spawn({
          title: "Betaling refundert"
        });

        despawnModal();
        onRefund();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [order, payment, sum, note, restock, lineData, notifications, onRefund]);

  const handleSetLineData = useCallback(
    (line: LineRefundData) => {
      const existIndex = lineData.findIndex((l) => l._id === line._id);

      if (existIndex !== -1) {
        setLineData(
          lineData.map((l, k) => {
            if (k === existIndex) {
              return line;
            }

            return l;
          })
        );
      } else {
        setLineData([...lineData, line]);
      }
    },
    [lineData]
  );

  useEffect(() => {
    const lineDataSum = lineData.reduce((value, line) => {
      return value + line.sum;
    }, 0);

    setSum(lineDataSum > 0 ? lineDataSum.toString() : "");
  }, [lineData]);

  useEffect(() => {
    if (isSinglePayment) {
      setRestock(true);
    }
  }, [isSinglePayment]);

  return (
    <BusyBoy busy={loading}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          refund();
        }}
      >
        <Modal>
          <ModalBody>
            <Block>
              <Text variant="title">Refunder betaling</Text>
            </Block>
            <Ruler hugBottom />
            <Table>
              <thead>
                <Tr>
                  <Th>Ordrelinje</Th>
                  <Th align="right">Antall</Th>
                  <Th align="right">Betalt</Th>
                  <Th>Antall å refundere</Th>
                  <Th>Sum å refundere</Th>
                </Tr>
              </thead>
              <tbody>
                {payment.items.map((i, k) => (
                  <RefundablePaymentLine
                    key={i._id}
                    index={k}
                    order={order}
                    existingRefunds={existingRefunds}
                    item={i}
                    onChange={handleSetLineData}
                  />
                ))}
              </tbody>
            </Table>
            <Ruler />
            <Block hugTop>
              {createInputField({
                key: "refund",
                type: "number",
                label: "Sum å refundere",
                required: true,
                value: sum,
                max: totalAvailableForRefund,
                placeholder: `0.01 - ${totalAvailableForRefund}`,
                readOnly: true,
                onChange: setSum
              })}
              {isSinglePayment &&
                createInputField({
                  key: "restock",
                  type: "boolean",
                  label: "Juster lager",
                  value: restock,
                  onChange: setRestock
                })}
              {createInputField({
                key: "note",
                type: "textarea",
                label: "Kommentar",
                value: note,
                placeholder: "Valgfri kommentar",
                onChange: setNote
              })}
            </Block>
          </ModalBody>
          <ModalActions>
            <ButtonList align="right">
              <Button
                type="button"
                outlined
                onClick={() => {
                  despawnModal();
                }}
              >
                Avbryt
              </Button>
              <Button type="submit">Refunder</Button>
            </ButtonList>
          </ModalActions>
        </Modal>
      </Form>
    </BusyBoy>
  );
};

interface LineItem {
  _id: string;
  type: OrderPaymentItem["type"];
  label: string;
  amount: number;
  total: number;
}

interface RefundablePaymentLineProps {
  index: number;
  order: Order;
  existingRefunds: OrderRefund[];
  item: OrderPaymentItem;
  onChange: (line: LineRefundData) => any;
}

const RefundablePaymentLine: React.FC<RefundablePaymentLineProps> = ({
  index,
  order,
  existingRefunds,
  item,
  onChange
}) => {
  const [amount, setAmount] = useState<string>("");
  const [sum, setSum] = useState<string>("");

  const lineItem = useMemo<LineItem | undefined>(() => {
    if (item.type === "orderLine") {
      const orderLine = order.orderLines.find((l) => l._id === item.ref);

      if (orderLine) {
        return {
          _id: item._id,
          type: "orderLine",
          label: orderLine.name,
          amount: orderLine.amount,
          total: new Decimal(item.total).plus(item.totalTax).toNumber()
        };
      }

      return undefined;
    }

    const shippingLine = order.shippingLines.find((l) => l._id === item.ref);

    if (shippingLine) {
      return {
        _id: item._id,
        type: "shippingLine",
        label: shippingLine.methodName,
        amount: 1,
        total: item.total + item.totalTax
      };
    }

    return undefined;
  }, [order, item]);

  const availableForRefund = useMemo<{ amount: number; sum: number }>(() => {
    const refundData: { amount: number; sum: number } = { amount: 0, sum: 0 };

    if (lineItem) {
      refundData.amount = lineItem.amount;
      refundData.sum = lineItem.total;

      for (let i = 0; i < existingRefunds.length; i++) {
        const refund = existingRefunds[i];

        for (let ii = 0; ii < refund.items.length; ii++) {
          const item = refund.items[ii];

          if (item.ref === lineItem._id) {
            refundData.amount -= item.amount;
            refundData.sum -= item.total + item.totalTax;
          }
        }
      }
    }

    return refundData;
  }, [lineItem, existingRefunds]);

  const canRefund = useMemo<boolean>(
    () => availableForRefund.sum > 0,
    [availableForRefund]
  );

  useEffect(() => {
    const amountNum = parseFloat(amount || "0");

    if (amountNum > 0 && lineItem && lineItem.total > 0) {
      const sum = (lineItem.total / lineItem.amount) * amountNum;

      if (sum > availableForRefund.sum) {
        setSum(availableForRefund.sum.toString());
      } else {
        setSum(sum.toString());
      }
    } else {
      setSum("");
    }
  }, [lineItem, amount, availableForRefund]);

  useEffect(() => {
    if (lineItem) {
      onChange({
        _id: lineItem._id,
        type: lineItem.type,
        amount: parseFloat(amount || "0"),
        sum: parseFloat(sum || "0")
      });
    }
  }, [amount, sum, lineItem]);

  if (
    !lineItem ||
    (availableForRefund.amount === 0 && availableForRefund.sum === 0)
  ) {
    return null;
  }

  return (
    <Tr>
      <Td verticalAlign="middle">{lineItem.label}</Td>
      <Td align="right" verticalAlign="middle">
        {lineItem.amount}
      </Td>
      <Td align="right" verticalAlign="middle">
        {currencyFormat.format(lineItem.total)}
      </Td>
      <Td verticalAlign="middle">
        {createInputField({
          key: `amount_${lineItem._id}`,
          type: "number",
          hugTop: true,
          placeholder: `0 - ${availableForRefund.amount}`,
          max: availableForRefund.amount,
          value: amount,
          autoFocus: index === 0,
          onChange: setAmount
        })}
      </Td>
      <Td verticalAlign="middle">
        {createInputField({
          key: `sum_${lineItem._id}`,
          type: "number",
          hugTop: true,
          placeholder: canRefund ? `0 - ${availableForRefund.sum}` : "0",
          disabled: !canRefund,
          value: sum,
          max: availableForRefund.sum,
          onChange: setSum
        })}
      </Td>
    </Tr>
  );
};

export default Payments;
