import React, { useMemo } from "react";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import { Product, Site } from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Section from "../../../style-guide/Section/Section";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import createPublisherInputField from "../../../util/createPublisherInputField";

interface Props {
  publisher: UsePublisherInterface<Product>;
  site: Site;
}

const Shipping: React.FC<Props> = ({ publisher, site }) => {
  const hasShippingClasses = useMemo(
    () => (site.shippingClasses || []).length > 0,
    [site.shippingClasses]
  );

  const product = publisher.form.data;

  return (
    <Fader>
      <Flex align="flex-start">
        <FlexKid flex={2}>
          <Section hugTop>
            <Card>
              <Block>
                {createPublisherInputField(publisher, {
                  path: "virtual",
                  label: "Produktet er virtuelt?",
                  type: "boolean",
                  hugTop: true,
                  autosave: true
                })}
                {hasShippingClasses &&
                  !product.virtual &&
                  createPublisherInputField(publisher, {
                    path: "shipping.class",
                    label: "Fraktklasse",
                    type: "select",
                    autosave: true,
                    nullFalsy: true,
                    options: site.shippingClasses.map((c) => ({
                      label: c.name,
                      value: c._id
                    }))
                  })}
                {!product.virtual && (
                  <>
                    <Flex>
                      <FlexKid flex={1}>
                        {createPublisherInputField(publisher, {
                          path: "shipping.weight",
                          label: "Vekt (kg)",
                          type: "number",
                          autosave: true
                        })}
                      </FlexKid>
                      <FlexKid flex={1} spaceLeft>
                        {createPublisherInputField(publisher, {
                          path: "shipping.length",
                          label: "Lengde (cm)",
                          type: "number",
                          autosave: true
                        })}
                      </FlexKid>
                    </Flex>
                    <Flex>
                      <FlexKid flex={1}>
                        {createPublisherInputField(publisher, {
                          path: "shipping.width",
                          label: "Bredde (cm)",
                          type: "number",
                          autosave: true
                        })}
                      </FlexKid>
                      <FlexKid flex={1} spaceLeft>
                        {createPublisherInputField(publisher, {
                          path: "shipping.height",
                          label: "Høyde (cm)",
                          type: "number",
                          autosave: true
                        })}
                      </FlexKid>
                    </Flex>
                  </>
                )}
              </Block>
            </Card>
          </Section>
        </FlexKid>
      </Flex>
    </Fader>
  );
};

export default Shipping;
