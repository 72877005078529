import React, { useEffect } from "react";
import { Prompt, Route, RouteComponentProps, Switch } from "react-router-dom";
import { AiOutlineProfile, AiOutlineShoppingCart } from "react-icons/ai";
import { GiReceiveMoney } from "react-icons/gi";
import { Customer as ICustomer } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import Fader from "../../style-guide/Fader/Fader";
import Block from "../../style-guide/Block/Block";
import usePublisher from "../../hooks/usePublisher";
import Section from "../../style-guide/Section/Section";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import Crumb from "../../components/Crumb/Crumb";
import Form from "../../style-guide/Inputs/Form";
import ActionBar from "../../components/ActionBar/ActionBar";
import PublisherHelpers from "../../util/PublisherHelpers";
import {
  InlineActionLink,
  InlineActions
} from "../../style-guide/InlineActions/InlineActions";
import General from "./SubViews/General";
import { LEAVE_PAGE_MESSAGE } from "../../constants/general";
import Orders from "./SubViews/Orders";
import useSite from "../../hooks/useSite";
import Subscriptions from "./SubViews/Subscriptions";

interface Props
  extends RouteComponentProps<{
    customer: string;
  }> {}

const Customer: React.FC<Props> = ({ match }) => {
  const customerId = match.params.customer;

  const site = useSite();
  const breadcrumbs = useBreadcrumbs();
  const publisher = usePublisher<ICustomer>({
    id: customerId,
    baseUrl: ["customers"],
    baseDashboardUrl: "/butikk/kunder",
    initialData: {}
  });

  useEffect(() => {
    breadcrumbs.swapEntry(customerId, publisher.form.data.name || customerId);
  }, [breadcrumbs, publisher.form.data, customerId]);

  return (
    <Form onSubmit={publisher.form.submit}>
      <Prompt
        when={publisher.form.hasMadeChanges}
        message={(location) => {
          if (location.pathname.includes(customerId)) {
            return true;
          }

          return LEAVE_PAGE_MESSAGE;
        }}
      />
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/butikk/kunder">Kunder</Crumb>
            {PublisherHelpers.getResourceTitle(publisher, "name", "Ny kunde")}
          </Text>
        </Block>
        {!publisher.isNew && (
          <Block hugTop hugBottom>
            <InlineActions>
              <InlineActionLink exact to={publisher.dashboardUrl}>
                <AiOutlineProfile /> Generelt
              </InlineActionLink>
              <InlineActionLink exact to={`${publisher.dashboardUrl}/ordrer`}>
                <AiOutlineShoppingCart /> Ordrer
              </InlineActionLink>
              {site.subscriptionsEnabled && (
                <InlineActionLink
                  exact
                  to={`${publisher.dashboardUrl}/abonnementer`}
                >
                  <GiReceiveMoney /> Abonnementer
                </InlineActionLink>
              )}
            </InlineActions>
          </Block>
        )}
        <Section hugTop>
          <Switch>
            <Route
              exact
              path={publisher.dashboardUrl}
              render={() => <General publisher={publisher} />}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/ordrer`}
              render={() => <Orders publisher={publisher} />}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/abonnementer`}
              render={() => <Subscriptions publisher={publisher} />}
            />
          </Switch>
        </Section>
      </Fader>
      <ActionBar publisher={publisher} destroy />
    </Form>
  );
};

export default Customer;
