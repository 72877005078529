import React from "react";
import { Switch, Route } from "react-router";
import DataSet from "./DataSet";
import DataSetEdit from "./DataSetEdit";

const DataSetRoutes: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path="/innhold/data-sets/:dataSet"
        render={(props) => (
          <DataSet key={props.match.params.dataSet} {...props} />
        )}
      />
      <Route
        path="/innhold/data-sets/:dataSet/:resource"
        render={(props) => <DataSetEdit key={props.match.url} {...props} />}
      />
    </Switch>
  );
};

export default DataSetRoutes;
