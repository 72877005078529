import { useContext, useEffect } from "react";
import { ThemeSwitcherContext, Theme } from "../context/ThemeSwitcher";

function useThemeSwitcher(theme?: Theme): void {
  const themeContext = useContext(ThemeSwitcherContext);

  useEffect(() => {
    if (theme && themeContext.theme !== theme) {
      themeContext.setTheme(theme);
    }

    return () => {
      if (themeContext.theme !== themeContext.defaultTheme) {
        themeContext.setTheme(themeContext.defaultTheme);
      }
    };
  }, [theme, themeContext]);
}

export default useThemeSwitcher;
