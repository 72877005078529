import React from "react";
import voucher from "voucher-code-generator";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import { Discount } from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import { Button } from "../../../style-guide/Button/Button";
import createId from "../../../util/createId";
import {
  InlineEditHead,
  InlineEditColumn
} from "../../../style-guide/InlineEdit/InlineEdit";
import Code from "../Components/Code";
import Ruler from "../../../style-guide/Ruler/Ruler";

interface Props {
  publisher: UsePublisherInterface<Discount>;
}

const Codes: React.FC<Props> = ({ publisher }) => {
  const form = publisher.form;

  return (
    <Fader>
      <Card>
        <Block>
          <Flex>
            <FlexKid flex={1}>
              <Text element="h2" variant="title">
                Tilbudskoder
              </Text>
            </FlexKid>
            <FlexKid>
              <Button
                type="button"
                outlined
                disabled={publisher.isRevision}
                onClick={() => {
                  if (!publisher.isRevision) {
                    form.setField({
                      path: "codes",
                      value: [
                        {
                          id: createId(),
                          code: voucher.generate()[0].toUpperCase(),
                          active: true
                        },
                        ...(form.data.codes || [])
                      ]
                    });
                  }
                }}
              >
                Ny tilbudskode
              </Button>
            </FlexKid>
          </Flex>
        </Block>
        <InlineEditHead>
          <InlineEditColumn width="400px">Kode</InlineEditColumn>
          <InlineEditColumn width="400px">Etikett</InlineEditColumn>
          <InlineEditColumn width="150px">Aktiv</InlineEditColumn>
        </InlineEditHead>
        {(form.data.codes || []).length > 0 ? (
          form.data.codes!.map((c, k) => (
            <Code
              //@ts-ignore
              key={c._id || c.id}
              code={c}
              isNew={!c._id}
              readOnly={publisher.isRevision}
              onChange={(data) =>
                form.setField({
                  path: "codes",
                  value: (form.data.codes || []).map((oo, kk) => {
                    if (kk === k) {
                      return data;
                    }

                    return oo;
                  })
                })
              }
              onDelete={() => {
                if (window.confirm("Er du sikker på at du vil slette?")) {
                  form.setField({
                    path: "codes",
                    value: (form.data.codes || []).filter((_, kk) => kk !== k)
                  });
                }
              }}
            />
          ))
        ) : (
          <>
            <Ruler />
            <Block hugTop>
              <Text variant="subheading">Ingen tilbudskoder enda</Text>
            </Block>
          </>
        )}
      </Card>
    </Fader>
  );
};

export default Codes;
