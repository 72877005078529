import getBaseApiUrl from "./getBaseApiUrl";

export interface DashboardMessagePayload {
  type: "refresh" | "scrollTo" | "openComponent";
  data?: any;
}

class Frame {
  private frame: HTMLIFrameElement | null = null;
  private url: string;

  constructor(id: string) {
    this.frame = document.getElementById(id) as HTMLIFrameElement;
    this.url = getBaseApiUrl();
  }

  public reload(): void {
    this.sendMessage({
      type: "refresh"
    });
  }

  public scrollTo(id: string): void {
    this.sendMessage({
      type: "scrollTo",
      data: id
    });
  }

  private sendMessage(payload: DashboardMessagePayload) {
    if (this.frame) {
      this.frame.contentWindow!.postMessage(payload, this.url);
    }
  }
}

export default Frame;
