import React, {
  FunctionComponent,
  createContext,
  useState,
  ReactNode,
  useCallback,
  useRef
} from "react";
import uuid from "uuid/v4";

export type NotificationType = "default" | "error";

export interface Notification {
  id: string;
  fadeOut: boolean;
  content: ReactNode;
  icon?: ReactNode;
  type?: NotificationType;
}

export interface SpawnNotificationOpts {
  content: ReactNode;
  icon?: ReactNode;
  id?: string;
  type?: NotificationType;
}

const NotificationContext = createContext({
  notifications: [] as Notification[],
  spawn: (opts: SpawnNotificationOpts) => {},
  despawn: (id: string) => {}
});

const NotificationProvider: FunctionComponent = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const internalNotifications = useRef(notifications);

  internalNotifications.current = notifications;

  const deswpawn = useCallback((id: string) => {
    setNotifications(internalNotifications.current.filter((n) => n.id !== id));
  }, []);

  const queueDespawn = useCallback(
    (id: string) => {
      setNotifications(
        internalNotifications.current.map((n) => {
          if (n.id === id) {
            n.fadeOut = true;
          }

          return n;
        })
      );

      window.setTimeout(() => {
        deswpawn(id);
      }, 240);
    },
    [deswpawn]
  );

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        spawn: (opts) => {
          const id = opts.id || uuid();

          setNotifications([
            {
              id,
              fadeOut: false,
              content: opts.content,
              icon: opts.icon,
              type: opts.type
            },
            ...notifications
          ]);

          if (!opts.id) {
            window.setTimeout(() => {
              queueDespawn(id);
            }, 2400);
          }
        },
        despawn: (id) => {
          setNotifications(
            notifications.map((n) => {
              if (n.id === id) {
                n.fadeOut = true;
              }

              return n;
            })
          );

          window.setTimeout(() => {
            deswpawn(id);
          }, 240);
        }
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationContext, NotificationProvider };
