import React, { useCallback, useMemo, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import { Company, CompanyFile } from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import { Button } from "../../../style-guide/Button/Button";
import { createObjectId } from "../../../util/createId";
import {
  InlineEditHead,
  InlineEditColumn
} from "../../../style-guide/InlineEdit/InlineEdit";
import Ruler from "../../../style-guide/Ruler/Ruler";
import InlineEdit from "../../../components/InlineEdit/InlineEdit";
import { numberFormat } from "../../../util/intl";
import { MediaPickerModal } from "../../Media/Media";
import api from "../../../util/api";

interface Props {
  publisher: UsePublisherInterface<Company>;
}

const Files: React.FC<Props> = ({ publisher }) => {
  const form = publisher.form;

  const { spawnModal } = useModal();
  const [newId, setNewId] = useState<string | null>(null);

  const handleSpawnUploadModal = useCallback(() => {
    spawnModal(
      <MediaPickerModal
        isPrivate
        onUploaded={async (media) => {
          if (media && media.ref) {
            const id = createObjectId();

            form.setField({
              path: "files",
              value: [
                {
                  _id: id,
                  media: media.ref
                },
                ...(form.data.files || [])
              ],
              submit: true
            });

            setNewId(id);
          }
        }}
      />
    );
  }, [spawnModal, form]);

  const handleDelete = useCallback(
    async (file: CompanyFile, index: number) => {
      let fileIsDeleted: boolean = false;

      if (file.media && typeof file.media !== "string") {
        const response = await api<{ message: string }>({
          endpoint: `media/${file.media._id}`,
          method: "DELETE"
        });

        if ("message" in response && response.message === "resourceDeleted") {
          fileIsDeleted = true;
        }
      } else {
        fileIsDeleted = true;
      }

      if (fileIsDeleted) {
        form.setField({
          path: "files",
          value: form.data.files.filter((_, kk) => kk !== index),
          submit: true
        });
      }
    },
    [form]
  );

  return (
    <Fader>
      <Card>
        <Block>
          <Flex>
            <FlexKid flex={1}>
              <Text element="h2" variant="title">
                Filer
              </Text>
            </FlexKid>
            {!publisher.isRevision && (
              <FlexKid spaceLeft>
                <Button type="button" outlined onClick={handleSpawnUploadModal}>
                  Last opp fil
                </Button>
              </FlexKid>
            )}
          </Flex>
        </Block>
        <InlineEditHead>
          <InlineEditColumn width="320px">Navn</InlineEditColumn>
          <InlineEditColumn width="100px" alignRight>
            Nedlastninger
          </InlineEditColumn>
          <InlineEditColumn width="119px" alignRight />
        </InlineEditHead>
        {form.data.files.length > 0 ? (
          form.data.files.map((f, k) => (
            <File
              key={f._id}
              file={f}
              isNew={f._id === newId}
              onDelete={() => handleDelete(f, k)}
            />
          ))
        ) : (
          <>
            <Ruler />
            <Block hugTop>
              <Text variant="subheading">Ingen filer enda</Text>
            </Block>
          </>
        )}
      </Card>
    </Fader>
  );
};

interface FileProps {
  file: CompanyFile;
  isNew: boolean;
  onDelete: () => any;
}

const File: React.FC<FileProps> = ({ file, isNew, onDelete }) => {
  const populatedMedia = useMemo(
    () =>
      file.media && typeof file.media !== "string" ? file.media : undefined,
    [file]
  );

  return (
    <InlineEdit
      expanded={isNew}
      actions={[
        <Button
          type="button"
          aria-label="Slett"
          circular
          outlined
          smaller
          onClick={() => {
            if (window.confirm("Er du sikker på at du vil slette?")) {
              onDelete();
            }
          }}
        >
          <AiOutlineDelete />
        </Button>
      ]}
      headerColumns={[
        {
          width: "320px",
          node: (
            <Text>
              {populatedMedia
                ? populatedMedia.original_filename
                : "Fil slettet"}
            </Text>
          )
        },
        {
          width: "100px",
          alignRight: true,
          node: (
            <Text variant="body3" align="right">
              {populatedMedia
                ? numberFormat.format(populatedMedia.downloads)
                : "N/A"}
            </Text>
          )
        }
      ]}
    />
  );
};

export default Files;
