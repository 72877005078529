import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineFileAdd
} from "react-icons/ai";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { Page } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import { Button, ButtonList } from "../../style-guide/Button/Button";
import CrudModal from "../CrudModal/CrudModal";
import { RequestData } from "../../util/api";
import PagePicker, { PageTable } from "../PagePicker/PagePicker";
import usePageMeta from "../../hooks/usePageMeta";
import useParents from "../../hooks/useParents";

export const Wrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 4px;
  display: flex;

  > * {
    &:last-child {
      margin: 0 6px;
      flex: 1;
    }
  }
`;

export const Content = styled.div`
  padding: ${(props) =>
    `${props.theme.spacing.xs} ${props.theme.spacing.small}`};
  width: 100%;
  border-right: 1px solid ${(props) => props.theme.colors.borderDim};
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.theme.sizes.plusFive};
  color: ${(props) => props.theme.colors.border};
  height: 60px;
  width: 100%;
`;

interface Props {
  page?: Page;
  queryParams?: RequestData;
  onChange: (page?: Page) => any;
  readOnly?: boolean;
  filter?: (page: Page) => boolean;
}

const PageSelector: React.FC<Props> = ({
  page,
  queryParams,
  onChange,
  readOnly,
  filter
}) => {
  const { spawnModal } = useModal();
  const pageMeta = usePageMeta("page");
  const parents = useParents(page, true);

  const parentPath = useMemo<string>(() => {
    return parents.map((b) => b.slug).join("/");
  }, [parents]);

  const addPageModal = useCallback(() => {
    if (readOnly) {
      return;
    }

    spawnModal(
      <CrudModal
        title={`Velg ${pageMeta.singular}`}
        initialData={{}}
        fullscreen
        onSubmit={(data) => {
          if (!readOnly && data && Array.isArray(data.pages)) {
            if (data.pages.length > 0) {
              onChange(data.pages[0]);
            } else {
              onChange(undefined);
            }
          }
        }}
        fields={[
          {
            key: "page",
            render: (payload) => (
              <PagePicker
                queryParams={queryParams}
                pageMeta={pageMeta}
                useWindow={false}
                getScrollParent={() => payload.modalBodyRef.current}
                filter={filter}
                renderView={(pages) => (
                  <PageTable
                    pages={pages}
                    pageMeta={pageMeta}
                    selectedPages={page ? [page] : undefined}
                    onSelect={(pages) => payload.setField("pages", pages)}
                  />
                )}
              />
            )
          }
        ]}
      />
    );
  }, [page, queryParams, onChange, readOnly, filter]);

  return (
    <Wrapper>
      <Content>
        {page ? (
          <>
            <Text variant="body2">{page.title}</Text>
            <Text variant="body3">/{parentPath}</Text>
          </>
        ) : (
          <EmptyState>
            <AiOutlineFileAdd />
          </EmptyState>
        )}
      </Content>
      <ButtonList align="center" tight vertical>
        <Button
          type="button"
          circular
          outlined
          smaller
          disabled={readOnly}
          onClick={addPageModal}
        >
          <AiOutlineEdit />
        </Button>
        {page && (
          <Button
            type="button"
            circular
            outlined
            smaller
            disabled={readOnly}
            onClick={() => {
              if (!readOnly) {
                if (window.confirm("Er du sikker på at du vil slette?")) {
                  onChange();
                }
              }
            }}
          >
            <AiOutlineDelete />
          </Button>
        )}
      </ButtonList>
    </Wrapper>
  );
};

export default PageSelector;
