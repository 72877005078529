import { useContext, useCallback } from "react";
import { BreadcrumbsContext, Crumb } from "../context/Breadcrumbs";

interface UseBreadcrumbsInterface {
  crumbs: Crumb[];
  swapEntry: (slug: string, label: string) => void;
}

function useBreadcrumbs(): UseBreadcrumbsInterface {
  const breadcrumbs = useContext(BreadcrumbsContext);

  const swapEntry = useCallback(
    (slug: string, label: string) => {
      if (breadcrumbs.crumbs.some(c => c.slug === slug && c.label !== label)) {
        breadcrumbs.setCrumbs(
          breadcrumbs.crumbs.map(c => {
            if (c.slug === slug) {
              c.label = label;
            }

            return c;
          })
        );
      }
    },
    [breadcrumbs]
  );

  return {
    crumbs: breadcrumbs.crumbs,
    swapEntry
  };
}

export default useBreadcrumbs;
