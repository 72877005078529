import React from "react";
import { Switch, Route } from "react-router-dom";
import { AiOutlineUsergroupAdd, AiOutlineMail } from "react-icons/ai";
import Text from "../../../style-guide/Text/Text";
import Fader from "../../../style-guide/Fader/Fader";
import Block from "../../../style-guide/Block/Block";
import Crumb from "../../../components/Crumb/Crumb";
import {
  InlineActions,
  InlineActionLink
} from "../../../style-guide/InlineActions/InlineActions";

import Section from "../../../style-guide/Section/Section";
import Members from "./Members";
import Invites from "./Invites";

const MembersOverview: React.FC = () => {
  return (
    <div>
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/innstillinger">Innstillinger</Crumb>
            Brukere
          </Text>
        </Block>
        <Block hugTop hugBottom>
          <InlineActions>
            <InlineActionLink exact to={`/innstillinger/brukere`}>
              <AiOutlineUsergroupAdd /> Alle brukere
            </InlineActionLink>
            <InlineActionLink exact to={`/innstillinger/brukere/invitasjoner`}>
              <AiOutlineMail /> Invitasjoner
            </InlineActionLink>
          </InlineActions>
        </Block>
        <Section hugTop>
          <Switch>
            <Route exact path={`/innstillinger/brukere`} component={Members} />
            <Route
              exact
              path={`/innstillinger/brukere/invitasjoner`}
              component={Invites}
            />
          </Switch>
        </Section>
      </Fader>
    </div>
  );
};

export default MembersOverview;
