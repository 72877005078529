import React from "react";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import Fader from "../../../style-guide/Fader/Fader";
import { Company } from "../../../types/apiResponses";
import OrdersTable from "../../Orders/Orders";

interface Props {
  publisher: UsePublisherInterface<Company>;
}

const Orders: React.FC<Props> = ({ publisher }) => {
  return (
    <Fader>
      <OrdersTable
        hideTitle
        query={{
          company: publisher.form.data._id
        }}
      />
    </Fader>
  );
};

export default Orders;
