import React, { ReactNode } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import { StatType, Image as ImageClass } from "@ludens-reklame/rubics-sdk";
import useApiUrl from "../../hooks/useApiUrl";
import { SearchOpts, useSearch } from "../../hooks/useApi";
import {
  TimelineObj,
  Order,
  Product,
  Page,
  Site,
  App,
  FormEntry
} from "../../types/apiResponses";
import {
  Timeline as StyledTimeline,
  TimelineItem,
  TimelineHl,
  TimelineItemWrapper
} from "../../style-guide/Timeline/Timeline";
import BusyBoy from "../BusyBoy/BusyBoy";
import { currencyFormat } from "../../util/intl";
import Avatar from "../../style-guide/Avatar/Avatar";
import fallbackImage from "../../util/images/empty_preview.png";
import { ExtendedStatType } from "../../constants/api";
import Card, { CardHero } from "../../style-guide/Card/Card";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import Text from "../../style-guide/Text/Text";
import Image from "../../style-guide/Image/Image";
import convertImage from "../../util/convertImage";
import Section from "../../style-guide/Section/Section";
import { OrderTable } from "../../views/Orders/SubViews/General";
import Ruler from "../../style-guide/Ruler/Ruler";
import { EntriesTable } from "../../views/Forms/SubViews/Entries";
import { ButtonLink } from "../../style-guide/Button/Button";
import Block from "../../style-guide/Block/Block";

interface Props {
  types: (StatType | ExtendedStatType)[];
}

const Timeline: React.FC<Props> = ({ types }) => {
  const timelineUrl = useApiUrl(["stats", "timeline"]);

  const timelineSearchOpts: SearchOpts<TimelineObj> = {
    endpoint: timelineUrl,
    queryParams: {
      type: types.join(",")
    }
  };

  const timeline = useSearch<TimelineObj>(timelineSearchOpts);

  return (
    <InfiniteScroll
      pageStart={1}
      loadMore={() =>
        timeline.search({
          ...timelineSearchOpts,
          paginate: true
        })
      }
      hasMore={timeline.hasMore}
    >
      <BusyBoy busy={timeline.loading}>
        <StyledTimeline>
          {timeline.results.map((t) => {
            if (t.__type === ExtendedStatType.SystemMessage) {
              if (!t.messageData) {
                return null;
              }

              const image =
                t.messageData.images.length > 0
                  ? t.messageData.images[0]
                  : undefined;

              return (
                <TimelineItemWrapper key={t._id}>
                  <Card>
                    {image && <CardHero image={image.url} />}
                    <Block>
                      <Text element="h2" variant="display2" gutterBottom>
                        {t.messageData.title}
                      </Text>
                      <Text
                        element="div"
                        variant="body3"
                        html={t.messageData.text}
                      />
                    </Block>
                  </Card>
                </TimelineItemWrapper>
              );
            }

            return (
              <TimelineItem
                key={t._id}
                icon={
                  <Avatar
                    size="36px"
                    image={t.user?.profileImage || fallbackImage}
                  />
                }
                topLine={
                  <>
                    <TimelineHl>
                      {t.user?.name || t.user?.email || "Anonym bruker"}
                    </TimelineHl>{" "}
                    {getType(t)}
                  </>
                }
                byline={moment(t.timestamp).fromNow()}
              >
                {getContent(t)}
              </TimelineItem>
            );
          })}
        </StyledTimeline>
      </BusyBoy>
    </InfiniteScroll>
  );
};

function getContent(item: TimelineObj): ReactNode {
  switch (item.__type) {
    case StatType.OrderCreated:
      return getOrderCreatedContent(item.order);
    case ExtendedStatType.PublishedProduct:
      return getProductContent(item.product, item.draftId);
    case ExtendedStatType.PublishedPage:
      return getPageContent(item.page, item.draftId);
    case ExtendedStatType.CreatedSite:
      return getSiteContent(item.site);
    case ExtendedStatType.InstalledApp:
    case ExtendedStatType.UninstalledApp:
      return getAppContent(item.app);
    case StatType.FormSubmit:
      return getFormEntryContent(item.formEntry);
    default:
      return "Her mangler det innhold, gitt!";
  }
}

function getOrderCreatedContent(order?: Order): ReactNode {
  if (!order) {
    return "Ny ordre ble opprettet, men denne orderen er nå slettet!";
  }

  return (
    <>
      <strong>
        {order.invoiceInformation.name || order.invoiceInformation.email}
      </strong>{" "}
      la inn{" "}
      <strong>
        <Link to={`/butikk/ordrer/${order._id}`}>
          Ordre #{order.orderNumber}
        </Link>
      </strong>{" "}
      til en verdi av <strong>{currencyFormat.format(order.total)}</strong>.
      <Section hugBottom>
        <Card outlined>
          <OrderTable order={order} />
        </Card>
      </Section>
    </>
  );
}

function getProductContent(product?: Product, draftId?: string): ReactNode {
  if (!product) {
    return "Produkt ble publisert, men dette produktet er nå slettet!";
  }

  let image = product.images.find((i) => i.primary);

  if (!image && product.images.length > 0) {
    image = product.images[0];
  }

  return (
    <Flex>
      <FlexKid>
        <Image
          noMaxWidth
          width={150}
          height={125}
          src={ImageClass.createUrl({
            image: image ? convertImage(image) : undefined,
            transforms: "w_250,h_200,c_pad"
          })}
        />
      </FlexKid>
      <FlexKid flex={1} spaceLeft>
        <Text variant="title">
          <Link to={`/butikk/produkter/${draftId}`}>{product.name}</Link>
        </Text>
        <Text variant="body3">{product.sku || "Mangler SKU"}</Text>
        <Text variant="body3">
          {product.description || "Mangler beskrivelse"}
        </Text>
      </FlexKid>
    </Flex>
  );
}

function getPageContent(page?: Page, draftId?: string): ReactNode {
  if (!page) {
    return "En side ble publisert, men denne siden er nå slettet!";
  }

  let path = "sider";

  if (page.isProductPage) {
    path = "produktsider";
  } else if (page.isCategory) {
    path = "kategorier";
  }

  return (
    <Flex>
      {!page.isProductPage && (
        <FlexKid>
          <Image
            noMaxWidth
            width={150}
            height={125}
            src={ImageClass.createUrl({
              image: page.featuredImage
                ? convertImage(page.featuredImage)
                : undefined,
              transforms: "w_250,h_200,c_fill"
            })}
          />
        </FlexKid>
      )}
      <FlexKid flex={1} spaceLeft={!page.isProductPage}>
        <Text variant="title">
          <Link to={`/innhold/${path}/${draftId}`}>{page.title}</Link>
        </Text>
        <Text variant="body3">{page.preamble || "Mangler ingress"}</Text>
      </FlexKid>
    </Flex>
  );
}

function getSiteContent(site?: Site): ReactNode {
  if (!site) {
    return "Her har det skjedd noe galt!";
  }

  return (
    <>
      <Text variant="title">It's aliiiiiiiiiive!</Text>
      <Text variant="subheading">{site.displayName || site.name}</Text>
      <Ruler gutterTop />
      <Text variant="body2">Velkommen til Rubics!</Text>
      <Text variant="body3">
        Dersom du trenger hjelp, er det bare å{" "}
        <a href="mailto:stian@ludensgruppen.no">ta kontakt</a>, vi er her for å
        hjelpe!
      </Text>
    </>
  );
}

function getAppContent(app?: App): ReactNode {
  if (!app) {
    return "Her har det skjedd noe galt!";
  }

  return (
    <Flex>
      <FlexKid flex={1}>
        <Text variant="title">
          <Link to={`/apper/${app.identifier}`}>{app.name}</Link>
        </Text>
        <Text variant="body3">{app.description || "Mangler beskrivelse"}</Text>
      </FlexKid>
      <FlexKid spaceLeft>
        <Image noMaxWidth height={40} src={app.logo} alt={app.name} />
      </FlexKid>
    </Flex>
  );
}

function getFormEntryContent(formEntry?: FormEntry): ReactNode {
  if (!formEntry || !formEntry.form || typeof formEntry.form === "string") {
    return "Skjema ble sendt inn, men dataen nå slettet!";
  }

  return (
    <>
      <Flex>
        <FlexKid flex={1}>
          <Text variant="title">
            <Link to={`/skjemaer/${formEntry.form._id}`}>
              {formEntry.form.name}
            </Link>
          </Text>
          <Text variant="body3">
            {formEntry.form.internalDescription || "Mangler beskrivelse"}
          </Text>
        </FlexKid>
        <FlexKid spaceLeft>
          <ButtonLink
            to={`/skjemaer/${formEntry.form._id}/innsendelser`}
            outlined
          >
            Gå til innsendelser
          </ButtonLink>
        </FlexKid>
      </Flex>
      <Section hugBottom>
        <Card outlined>
          <EntriesTable
            form={formEntry.form}
            entries={[formEntry]}
            hasFetchedEntries
            truncateFieldLimit={3}
          />
        </Card>
      </Section>
    </>
  );
}

function getType(item: TimelineObj): ReactNode {
  switch (item.__type) {
    case ExtendedStatType.CreatedSite:
      return "opprettet en nettside";
    case StatType.OrderCreated:
      return "la inn en ordre";
    case ExtendedStatType.PublishedProduct:
      return "publiserte et produkt";
    case ExtendedStatType.PublishedPage:
      let pageType = "side";

      if (item.page?.isCategory) {
        pageType = "kategori";
      } else if (item.page?.isProductPage) {
        pageType = "produktside";
      }

      return `publiserte en ${pageType}`;
    case ExtendedStatType.InstalledApp:
      return "installerte en app";
    case ExtendedStatType.UninstalledApp:
      return "avinstallerte en app";
    case StatType.FormSubmit:
      return "sendte inn et skjema";
    default:
      return null;
  }
}

export default Timeline;
