import React, { useCallback, useMemo, useState } from "react";
import { AiOutlineDelete, AiOutlineHourglass } from "react-icons/ai";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import {
  Company,
  CompanyTeam,
  CompanyMember,
  Customer
} from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import { Button, ButtonList } from "../../../style-guide/Button/Button";
import createId, { createObjectId } from "../../../util/createId";
import {
  InlineEditHead,
  InlineEditColumn
} from "../../../style-guide/InlineEdit/InlineEdit";
import Ruler from "../../../style-guide/Ruler/Ruler";
import InlineEdit from "../../../components/InlineEdit/InlineEdit";
import { currencyFormat, numberFormat } from "../../../util/intl";
import createInputField from "../../../util/createInputField";
import Section from "../../../style-guide/Section/Section";
import { Table, Tr, Th, Td } from "../../../style-guide/Table/Table";
import CrudModal, {
  createCrudModalField
} from "../../../components/CrudModal/CrudModal";
import { companyRole } from "../../../constants/localizations";
import { CompanyRole } from "../../../constants/api";
import SearchAndSelect from "../../../components/SearchAndSelect/SearchAndSelect";
import Label from "../../../style-guide/Inputs/Label";
import localize from "../../../util/localize";
import Avatar from "../../../style-guide/Avatar/Avatar";
import fallbackImage from "../../../util/images/empty_preview.png";
import api from "../../../util/api";
import useNotifications from "../../../hooks/useNotifications";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";

interface Props {
  publisher: UsePublisherInterface<Company>;
}

const Teams: React.FC<Props> = ({ publisher }) => {
  const form = publisher.form;

  const [newId, setNewId] = useState<string | null>(null);

  const handleTeam = useCallback(() => {
    const id = createObjectId();

    form.setField({
      path: "teams",
      value: [
        {
          _id: id,
          members: []
        },
        ...(form.data.teams || [])
      ]
    });

    setNewId(id);
  }, [form]);

  return (
    <Fader>
      <Card>
        <Block>
          <Flex>
            <FlexKid flex={1}>
              <Text element="h2" variant="title">
                Avdelinger
              </Text>
            </FlexKid>
            {!publisher.isRevision && (
              <FlexKid spaceLeft>
                <Button type="button" outlined onClick={handleTeam}>
                  Ny Avdeling
                </Button>
              </FlexKid>
            )}
          </Flex>
        </Block>
        <InlineEditHead>
          <InlineEditColumn width="320px">Navn</InlineEditColumn>
          <InlineEditColumn width="100px" alignRight>
            Ordrer
          </InlineEditColumn>
          <InlineEditColumn width="320px" alignRight>
            Totalt
          </InlineEditColumn>
          <InlineEditColumn width="320px" alignRight>
            Gjennomsnittsum per ordre
          </InlineEditColumn>
          <InlineEditColumn width="119px" alignRight />
        </InlineEditHead>
        {form.data.teams.length > 0 ? (
          form.data.teams.map((t, k) => (
            <Team
              key={t._id}
              team={t}
              isNew={t._id === newId}
              onChange={(updatedTeam) => {
                form.setField({
                  path: "teams",
                  value: form.data.teams.map((tt, kk) => {
                    if (kk === k) {
                      return updatedTeam;
                    }

                    return tt;
                  })
                });
              }}
              onDelete={() =>
                form.setField({
                  path: "teams",
                  value: form.data.teams.filter((_, kk) => kk !== k)
                })
              }
            />
          ))
        ) : (
          <>
            <Ruler />
            <Block hugTop>
              <Text variant="subheading">Ingen avdelinger enda</Text>
            </Block>
          </>
        )}
      </Card>
    </Fader>
  );
};

interface TeamProps {
  team: CompanyTeam;
  isNew: boolean;
  onChange: (team: CompanyTeam) => any;
  onDelete: () => any;
}

const Team: React.FC<TeamProps> = ({ team, isNew, onChange, onDelete }) => {
  const { spawnModal } = useModal();
  const notifications = useNotifications();
  const [creatingCustomer, setCreatingCustomer] = useState<boolean>(false);

  const orderData = useMemo(() => {
    const orders = team.orders || 0;
    const ordersSum = team.ordersSum || 0;

    return {
      orders: orders,
      ordersSum: currencyFormat.format(ordersSum),
      orderAverage:
        orders > 0 && ordersSum > 0
          ? currencyFormat.format(ordersSum / orders)
          : currencyFormat.format(0)
    };
  }, [team]);

  const createCustomerModal = useCallback(
    (callback: (customer: Customer) => any) => {
      spawnModal(
        <CrudModal
          title="Opprett kunde"
          initialData={{
            sendInvite: true
          }}
          fields={[
            {
              key: "name",
              render: (payload) =>
                createCrudModalField({
                  payload,
                  key: "name",
                  type: "text",
                  label: "Navn",
                  required: true
                })
            },
            {
              key: "email",
              render: (payload) =>
                createCrudModalField({
                  payload,
                  key: "email",
                  type: "email",
                  label: "E-post",
                  required: true
                })
            },
            {
              key: "sendInvite",
              render: (payload) =>
                createCrudModalField({
                  payload,
                  key: "sendInvite",
                  type: "boolean",
                  label: "Send invitasjon på e-post",
                  required: true
                })
            }
          ]}
          onSubmit={async (customer) => {
            setCreatingCustomer(true);
            notifications.spawn({
              title: "Oppretter kunde…",
              icon: <AiOutlineHourglass />
            });

            const response = await api<Customer>({
              endpoint: "customers",
              method: "POST",
              body: customer
            });

            if (response && "_id" in response) {
              notifications.spawn({
                title: "Kunde opprettet"
              });

              callback(response);
            }

            setCreatingCustomer(false);
          }}
        />
      );
    },
    [notifications]
  );

  const addMemberModal = useCallback(
    (member?: CompanyMember, key?: number) => {
      spawnModal(
        <CrudModal
          title="Medlem"
          initialData={
            member || {
              internalId: createId(),
              role: CompanyRole.Employee
            }
          }
          onSubmit={(data) => {
            if (member) {
              onChange({
                ...team,
                members: (team.members || []).map((m, k) => {
                  if (k === key) {
                    return data;
                  }

                  return m;
                })
              });
            } else
              onChange({
                ...team,
                members: [...(team.members || []), data]
              });
          }}
          fields={[
            {
              key: "customer",
              render: (payload) => (
                <BusyBoy busy={creatingCustomer}>
                  <Label htmlFor="customer">Kunde *</Label>
                  <SearchAndSelect
                    id="customer"
                    url={["customers"]}
                    searchKey="name"
                    placeholder="Søk etter navn"
                    itemToString={(customer) => (customer ? customer.name : "")}
                    itemToSubString={(customer) =>
                      customer && customer.email
                        ? customer.email
                        : "Mangler e-post"
                    }
                    query={{
                      anonymous: false
                    }}
                    readOnly={creatingCustomer}
                    filter={(i) =>
                      !team.members.some((m) => {
                        if (m.customer) {
                          if (typeof m.customer === "string") {
                            return i._id === m.customer;
                          }

                          return i._id === m.customer._id;
                        }

                        return false;
                      })
                    }
                    value={
                      payload.data.customer &&
                      typeof payload.data.customer !== "string"
                        ? payload.data.customer.name
                        : ""
                    }
                    onChange={(customer) => {
                      payload.setField("customer", customer ? customer : null);
                    }}
                    required
                  />
                </BusyBoy>
              )
            },
            {
              key: "add",
              render: (payload) => (
                <Button
                  type="button"
                  outlined
                  disabled={creatingCustomer}
                  onClick={() =>
                    createCustomerModal((customer) => {
                      payload.setField("customer", customer ? customer : null);
                    })
                  }
                >
                  ...eller lag ny kunde
                </Button>
              )
            },
            {
              key: "role",
              render: (payload) =>
                createCrudModalField({
                  payload,
                  key: "role",
                  type: "select",
                  label: "Rolle",
                  required: true,
                  options: Object.keys(companyRole).map((k) => ({
                    value: k,
                    label: companyRole[k]
                  }))
                })
            }
          ]}
        />
      );
    },
    [team, createCustomerModal, creatingCustomer]
  );

  return (
    <InlineEdit
      expanded={isNew}
      actions={[
        <Button
          type="button"
          aria-label="Slett"
          circular
          outlined
          smaller
          onClick={() => {
            if (window.confirm("Er du sikker på at du vil slette?")) {
              onDelete();
            }
          }}
        >
          <AiOutlineDelete />
        </Button>
      ]}
      headerColumns={[
        {
          width: "320px",
          node: (
            <>
              <Text>{team.name || "Mangler navn"}</Text>
              <Text variant="body3">
                {numberFormat.format(team.members.length)} medlemmer
              </Text>
            </>
          )
        },
        {
          width: "100px",
          alignRight: true,
          node: (
            <Text variant="body3" align="right">
              {orderData.orders}
            </Text>
          )
        },
        {
          width: "320px",
          alignRight: true,
          node: (
            <Text variant="body3" align="right">
              {orderData.ordersSum}
            </Text>
          )
        },
        {
          width: "320px",
          alignRight: true,
          node: (
            <Text variant="body3" align="right">
              {orderData.orderAverage}
            </Text>
          )
        }
      ]}
    >
      <Block>
        <Flex align="top">
          <FlexKid flex={1}>
            {createInputField({
              key: "name",
              type: "text",
              label: "Navn",
              value: team.name,
              required: true,
              hugTop: true,
              autoFocus: isNew,
              onChange: (value) => {
                onChange({
                  ...team,
                  name: value
                });
              }
            })}
          </FlexKid>
        </Flex>
        <Section tight hugBottom>
          <Section tight>
            <Flex>
              <FlexKid flex={1}>
                <Text>Medlemmer</Text>
              </FlexKid>
              <FlexKid>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    addMemberModal();
                  }}
                >
                  + Legg til medlem
                </a>
              </FlexKid>
            </Flex>
          </Section>
          <Card outlined>
            <Table>
              <thead>
                <Tr>
                  <Th>Medlem</Th>
                  <Th>Rolle</Th>
                  <Th align="right">Ordrer</Th>
                  <Th align="right">Totalt</Th>
                  <Th align="right">Gjennomsnittsum per ordre</Th>
                  <Th align="right">Handlinger</Th>
                </Tr>
              </thead>
              <tbody>
                {(team.members || []).length > 0 ? (
                  (team.members || []).map((m, k) => (
                    <Member
                      //@ts-ignore
                      key={m._id || m.internalId}
                      member={m}
                      onEditClick={(member) => addMemberModal(member, k)}
                      onDeleteClick={() => {
                        onChange({
                          ...team,
                          members: (team.members || []).filter(
                            (_, kk) => kk !== k
                          )
                        });
                      }}
                    />
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={3}>
                      <Text variant="subheading">Ingen medlemmer enda</Text>
                    </Td>
                  </Tr>
                )}
              </tbody>
            </Table>
          </Card>
        </Section>
      </Block>
    </InlineEdit>
  );
};

interface MemberProps {
  member: CompanyMember;
  onEditClick: (member: CompanyMember) => any;
  onDeleteClick: () => any;
}

const Member: React.FC<MemberProps> = ({
  member,
  onEditClick,
  onDeleteClick
}) => {
  const orderData = useMemo(() => {
    const orders = member.orders || 0;
    const ordersSum = member.ordersSum || 0;

    return {
      orders: orders,
      ordersSum: currencyFormat.format(ordersSum),
      orderAverage:
        orders > 0 && ordersSum > 0
          ? currencyFormat.format(ordersSum / orders)
          : currencyFormat.format(0)
    };
  }, [member]);

  return (
    <Tr>
      <Td>
        <Flex>
          <FlexKid centerContent>
            <Avatar
              size="36px"
              image={
                member.customer &&
                typeof member.customer !== "string" &&
                member.customer.profileImage
                  ? member.customer.profileImage
                  : fallbackImage
              }
            />
          </FlexKid>
          <FlexKid spaceLeft tight>
            <Text>
              {member.customer && typeof member.customer !== "string"
                ? member.customer.name
                : "Finner ikke kunde"}
            </Text>
            <Text variant="body3">
              {member.customer && typeof member.customer !== "string"
                ? member.customer.email || "Mangler e-post"
                : "Finner ikke kunde"}
            </Text>
          </FlexKid>
        </Flex>
      </Td>
      <Td>{localize(companyRole, member.role)}</Td>
      <Td align="right">
        <Text variant="body3" align="right">
          {orderData.orders}
        </Text>
      </Td>
      <Td align="right">
        <Text variant="body3" align="right">
          {orderData.ordersSum}
        </Text>
      </Td>
      <Td align="right">
        <Text variant="body3" align="right">
          {orderData.orderAverage}
        </Text>
      </Td>
      <Td verticalAlign="middle" align="right">
        <ButtonList align="right">
          <Button type="button" outlined onClick={() => onEditClick(member)}>
            Rediger
          </Button>
          <Button
            type="button"
            outlined
            onClick={() => {
              if (
                window.confirm("Er du sikker på at du vil fjerne medlemmet?")
              ) {
                onDeleteClick();
              }
            }}
          >
            Fjern
          </Button>
        </ButtonList>
      </Td>
    </Tr>
  );
};

export default Teams;
