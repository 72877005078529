import React, { FunctionComponent, createContext, useState } from "react";
import { Site, Member } from "../types/apiResponses";
import { CustomerCreation, Themes, UserCreation } from "../constants/api";

const templateDist = {
  ref: null,
  source: null
};

const defaultSite: Site = {
  _id: "",
  name: "",
  displayName: null,
  logo: null,
  theme: {
    name: Themes.Commie,
    config: {},
    configDraft: {}
  },
  baseStyles: {
    color: {
      primary: "",
      secondary: "",
      onPrimary: "",
      onSecondary: ""
    }
  },
  fonts: {
    google: [],
    typekit: [],
    custom: []
  },
  members: [],
  invites: [],
  staticPages: {
    frontPage: null
  },
  templateDistribution: {
    frontPage: templateDist,
    page: templateDist,
    product: templateDist,
    category: templateDist,
    productPage: templateDist,
    cart: templateDist,
    checkout: templateDist
  },
  protection: null,
  tags: [],
  domains: [],
  domainConfigurations: [],
  canonicalDomain: null,
  favicon: null,
  shippingClasses: [],
  shippingZones: [],
  paymentProviders: [],
  publishProductPriceExcludingTax: false,
  displayProductPriceExcludingTax: false,
  displayCartPriceExcludingTax: false,
  taxClasses: [],
  hasEcommerce: false,
  b2bEnabled: false,
  bookingEnabled: false,
  departmentsEnabled: false,
  partialPaymentEnabled: false,
  subscriptionsEnabled: false,
  userCreation: UserCreation.Invite,
  customerCreation: CustomerCreation.Public,
  redirectToCanonical: false,
  askForMarketingConsent: false,
  emailTexts: undefined,
  cookieDialogEnabled: false,
  cookieDefinitions: [],
  cookieDialogMeta: {},
  emailTemplate: undefined,
  emailTemplateProps: undefined
};

const SiteContext = createContext({
  data: defaultSite,
  permissions: undefined as Member | undefined,
  setData: (data: Site) => {},
  setPermissions: (permissions?: Member) => {}
});

const SiteProvider: FunctionComponent = ({ children }) => {
  const [state, setState] = useState<Site>(defaultSite);
  const [permissions, setPermissions] = useState<Member | undefined>();

  return (
    <SiteContext.Provider
      value={{
        data: state,
        permissions,
        setData: setState,
        setPermissions
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

export { SiteContext, SiteProvider };
