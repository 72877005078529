import { useMemo } from "react";
import useQueryParams from "./useQueryParams";

interface QueryParams {
  experiment?: string;
  experimentVariant?: string;
}

interface ExperimentIdInterface {
  experiment: string;
  experimentVariant: string;
}

function useExperimentId(): ExperimentIdInterface | undefined {
  const queryParams = useQueryParams<QueryParams>();
  const data = useMemo<ExperimentIdInterface | undefined>(() => {
    if (queryParams.experiment && queryParams.experimentVariant) {
      return {
        experiment: queryParams.experiment,
        experimentVariant: queryParams.experimentVariant
      };
    }

    return undefined;
  }, [queryParams]);

  return data;
}

export default useExperimentId;
