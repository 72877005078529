import React, { useMemo, useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import equal from "deep-equal";
import { STOREFRONT_ENDPOINT } from "@ludens-reklame/rubics-sdk";
import { Wrapper, Sidebar, Main } from "../../style-guide/Layout/Layout";
import Block from "../../style-guide/Block/Block";
import Preview from "../../components/Preview/Preview";
import Text from "../../style-guide/Text/Text";
import Section from "../../style-guide/Section/Section";
import usePreviewToken from "../../hooks/usePreviewToken";
import useApiUrl from "../../hooks/useApiUrl";
import useTheme from "../../hooks/useTheme";
import { Page, Site } from "../../types/apiResponses";
import Frame from "../../util/Frame";
import Fader from "../../style-guide/Fader/Fader";
import Putter from "../../components/Putter/Putter";
import ActionBar from "../../components/ActionBar/ActionBar";
import usePublisher from "../../hooks/usePublisher";
import { ButtonLink } from "../../style-guide/Button/Button";
import { AiOutlineClose } from "react-icons/ai";
import { useSearch } from "../../hooks/useApi";
import apiUrl from "../../util/apiUrl";
import { SiteContext } from "../../context/Site";
import useThemeSwitcher from "../../hooks/useThemeSwitcher";
import useRevisionId from "../../hooks/useRevisionId";
import { RevisionType } from "../../constants/api";
import useSite from "../../hooks/useSite";
import getBaseApiUrl from "../../util/getBaseApiUrl";
import useExperimentId from "../../hooks/useExperimentId";
import { RequestData } from "../../util/api";

interface Props extends RouteComponentProps {}

const GlobalDesigner: React.FC<Props> = () => {
  const site = useSite();
  const experimentId = useExperimentId();
  const revisionId = useRevisionId();
  const previewToken = usePreviewToken();
  const theme = useTheme();
  const siteContext = useContext(SiteContext);
  const pageSearchUrl = useApiUrl(["pages"]);
  const url = useApiUrl(["site"]);
  const isExperiment = useMemo<boolean>(() => !!experimentId, [experimentId]);

  const publisher = usePublisher<Site>({
    id: "",
    baseUrl: ["site"],
    baseDashboardUrl: "/designer/globalt",
    publishEndpoint: "global-props/publish",
    discardEndpoint: "global-props/discard",
    standalonePublishDiscardEndpoint: true,
    skipId: true,
    onPublishDiscard: (site) => {
      const iframe = new Frame("preview");
      iframe.reload();
      siteContext.setData(site);
    }
  });

  useThemeSwitcher("light");

  const pagesSearch = useSearch<Page>({
    endpoint: pageSearchUrl,
    fetchOnMount: true,
    queryParams: {
      slug: "index",
      draft: false,
      fetchInherited: true
    }
  });

  if (pagesSearch.results.length > 0) {
    siteContext.data.staticPages.frontPage = pagesSearch.results[0]._id;
  }

  const previewUrl = useMemo(
    () =>
      previewToken && siteContext.data.staticPages.frontPage
        ? getBaseApiUrl() +
          "/" +
          STOREFRONT_ENDPOINT +
          "/" +
          apiUrl([
            "pages",
            siteContext.data.staticPages.frontPage +
              "/render?preview=true&token=" +
              previewToken +
              (revisionId ? `&revision=${revisionId}` : "") +
              (!!experimentId
                ? `&experiment=${experimentId.experiment}&experimentVariant=${experimentId.experimentVariant}`
                : "")
          ])
        : null,
    [
      siteContext.data.staticPages.frontPage,
      previewToken,
      experimentId,
      revisionId
    ]
  );

  const modified = useMemo(
    () =>
      !equal(
        siteContext.data.globalPropsOriginal,
        siteContext.data.globalPropsDraft
      ),
    [siteContext.data.globalPropsOriginal, siteContext.data.globalPropsDraft]
  );

  const queryParams = useMemo<RequestData | undefined>(
    () =>
      isExperiment
        ? {
            experiment: experimentId!.experiment,
            experimentVariant: experimentId!.experimentVariant
          }
        : undefined,
    [isExperiment, experimentId]
  );

  if (!theme) {
    return null;
  }

  return (
    <Wrapper widerSidebar>
      <Sidebar wider>
        <Block>
          <Fader variant="left">
            <Section hugTop>
              <Text element="h1" variant="display3">
                Globalt innhold
              </Text>
            </Section>
          </Fader>
          <Fader variant="left">
            <Section>
              <ButtonLink to="/innhold" outlined alternate>
                <AiOutlineClose /> Lukk designer
              </ButtonLink>
            </Section>
            <Section>
              <Putter
                endpoint={url}
                prefillEndpoint={url}
                method="PATCH"
                pathPrefix="globalPropsDraft"
                previewPathPrefix="globalPropsPopulated"
                props={theme.globalProps}
                diff
                prefillFn={(resource) => {
                  const site = resource as Site;

                  if (!site.globalProps) {
                    site.globalProps = {};
                  }

                  if (!site.globalPropsDraft) {
                    site.globalPropsDraft = {};
                  }

                  return {
                    globalProps: site.globalProps,
                    globalPropsDraft: site.globalPropsDraft,
                    globalPropsOriginal: site.globalPropsOriginal,
                    globalPropsPopulated: site.globalPropsPopulated
                  };
                }}
                onSubmit={async (site) => {
                  const iframe = new Frame("preview");

                  siteContext.setData(site as Site);
                  iframe.reload();
                }}
                refreshHash={publisher.publishDiscardHash}
                queryParams={queryParams}
                prefillQueryParams={queryParams}
              />
            </Section>
          </Fader>
        </Block>
      </Sidebar>
      <Main noMaxWidth fullColumn>
        <Section>
          {previewUrl && (
            <Fader>
              <Preview id="preview" src={previewUrl} />
            </Fader>
          )}
        </Section>
        <ActionBar
          publisher={publisher}
          modified={modified}
          discard
          publish
          revisions
          revisionType={RevisionType.GlobalProps}
          revisionReference={site._id}
          experiment={experimentId?.experiment}
        />
      </Main>
    </Wrapper>
  );
};

export default GlobalDesigner;
