import React from "react";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import { ProductTemplate } from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import Section from "../../../style-guide/Section/Section";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import createPublisherInputField from "../../../util/createPublisherInputField";

interface Props {
  publisher: UsePublisherInterface<ProductTemplate>;
}

const General: React.FC<Props> = ({ publisher }) => {
  return (
    <Fader>
      <Card>
        <Block>
          <Section hugTop>
            <Text element="h2" variant="title">
              Generelt
            </Text>
          </Section>
          {createPublisherInputField(publisher, {
            path: "name",
            label: "Navn",
            type: "text",
            required: true
          })}
          {createPublisherInputField(publisher, {
            path: "description",
            label: "Beskrivelse",
            type: "textarea"
          })}
        </Block>
      </Card>
    </Fader>
  );
};

export default General;
