import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineExperiment } from "react-icons/ai";
import { Experiment } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import Tabler from "../../components/Tabler/Tabler";
import { Dotter, Dot } from "../../style-guide/Dotter/Dotter";
import { ButtonLink } from "../../style-guide/Button/Button";
import Status from "../../style-guide/Status/Status";

interface Props {}

const Experiments: React.FC<Props> = () => {
  return (
    <Tabler
      title="Eksperimenter"
      actions={
        <ButtonLink to="eksperimenter/opprett">Opprett eksperiment</ButtonLink>
      }
      emptyState={{
        title: "Opprett ditt første eksperimenter",
        icon: <AiOutlineExperiment />,
        text: "Eksperimenter lar deg kjøre A/B-tester hvor du kan gjøre visuelle endringer på nettsiden din som kun et utvalg besøkende ser, for så å sammenlikne resultatene med en benchmark.",
        action: (
          <ButtonLink to="eksperimenter/opprett">
            Opprett eksperiment
          </ButtonLink>
        )
      }}
      url={["experiments"]}
      columns={[
        {
          Header: "Navn",
          accessor: "name",
          verticalAlign: "middle"
        },
        {
          Header: "Status",
          accessor: "status",
          verticalAlign: "middle",
          align: "center"
        }
      ]}
      createRow={(experiment: Experiment) => ({
        ...experiment,
        name: <TitleRow experiment={experiment} />,
        status: (
          <Status variant={!experiment.active ? "neutral" : undefined}>
            {experiment.active ? "Aktiv" : "Inaktiv"}
          </Status>
        )
      })}
    />
  );
};

interface TitleRowProps {
  experiment: Experiment;
}

const TitleRow: React.FC<TitleRowProps> = ({ experiment }) => {
  return (
    <>
      <Text variant="body2">
        <Link to={`/eksperimenter/${experiment._id}`}>{experiment.name}</Link>
      </Text>
      <Text variant="body3">
        <Dotter>
          <Dot>
            <Text element="span" variant="body3">
              {experiment.description || "Mangler beskrivelse"}
            </Text>
          </Dot>
        </Dotter>
      </Text>
    </>
  );
};

export default Experiments;
