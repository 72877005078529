import React, { useCallback } from "react";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import useForm, { UseFormInterface } from "./useForm";
import { useApi } from "./useApi";
import useNotifications from "./useNotifications";
import { Button, ButtonList } from "../style-guide/Button/Button";
import BusyBoy from "../components/BusyBoy/BusyBoy";
import { Modal, ModalBody, ModalActions } from "../style-guide/Modal/Modal";
import Block from "../style-guide/Block/Block";
import Text from "../style-guide/Text/Text";

export interface UseSimplePatchOpts<T> {
  endpoint: string;
  initialData: any;
  nameLabel?: string;
  publish?: boolean;
  publishEndpoint?: string;
  onSuccess?: (resource?: T) => any;
}

export interface UseSimplePatchInterface<T> {
  loading: boolean;
  form: UseFormInterface<T>;
}

function useSimplePatch<T>(
  opts: UseSimplePatchOpts<T>
): UseSimplePatchInterface<T> {
  const modal = useModal();
  const notifications = useNotifications();

  const handleDone = useCallback(
    (resource?: T) => {
      if (typeof opts.onSuccess === "function") {
        opts.onSuccess(resource);
      }
    },
    [opts.onSuccess]
  );

  const publishRequest = useApi<any>({
    endpoint: `${opts.endpoint}/${opts.publishEndpoint}`,
    method: "POST",
    initialData: null,
    onSuccess: (resource) => {
      modal.despawnModal();
      handleDone(resource);

      if (resource) {
        notifications.spawn({
          title: "Publisering fullført"
        });
      }
    }
  });

  const form = useForm<T>(opts.initialData, {
    endpoint: opts.endpoint,
    method: "PATCH",
    diff: true,
    onSuccess: (resource) => {
      if (opts.publish && resource) {
        modal.spawnModal(
          <Modal maxWidth="24rem">
            <ModalBody>
              <Block>
                <BusyBoy busy={publishRequest.loading}>
                  <Text variant="title">Ønsker du å publisere endringene?</Text>
                  {opts.nameLabel && (
                    <Text variant="subheading" gutterTop>
                      {opts.nameLabel}
                    </Text>
                  )}
                </BusyBoy>
              </Block>
            </ModalBody>
            <ModalActions>
              <ButtonList align="right">
                <Button
                  type="button"
                  outlined
                  onClick={() => {
                    modal.despawnModal();
                    handleDone(resource);
                  }}
                >
                  Nei
                </Button>
                <Button onClick={() => publishRequest.fetch()}>Ja</Button>
              </ButtonList>
            </ModalActions>
          </Modal>
        );
      } else if (!opts.publish) {
        handleDone(resource);
      }
    }
  });

  return {
    loading: form.submitting || publishRequest.loading,
    form
  };
}

export default useSimplePatch;
