import { useMemo } from "react";
import useSite from "./useSite";

function useCanonicalUrl(path?: string[]): string {
  const site = useSite();

  return useMemo<string>(() => {
    let url: string;

    if (site.canonicalDomain) {
      url = `https://${site.canonicalDomain}`;
    } else {
      if (
        process.env.REACT_APP_RUBICS_URL?.includes(
          process.env.REACT_APP_RUBICS_DOMAIN || "rubics.as"
        )
      ) {
        url = `https://${site.name}.${process.env.REACT_APP_RUBICS_DOMAIN}`;
      } else {
        url = process.env.REACT_APP_RUBICS_URL || "";
      }
    }

    if (path && path.length > 0) {
      return url + "/" + path.filter((p) => p !== "index").join("/");
    }

    return url;
  }, [site.canonicalDomain, site.name, path]);
}

export default useCanonicalUrl;
