import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  ReactNode
} from "react";
import styled, { css } from "styled-components";
import { useDropzone } from "react-dropzone";
import deepEqual from "deep-equal";
import InfiniteScroll from "react-infinite-scroller";
import uuid from "uuid/v4";
import { FaAngleUp } from "react-icons/fa";
import {
  AiOutlineEdit,
  AiOutlineFileZip,
  AiOutlineFilePdf,
  AiOutlineVideoCamera,
  AiOutlineFileUnknown,
  AiOutlineFileAdd
} from "react-icons/ai";
import { Image as ImageClass, RubicsImage } from "@ludens-reklame/rubics-sdk";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import TextInput from "../../style-guide/Inputs/TextInput";
import Label from "../../style-guide/Inputs/Label";
import Select from "../../style-guide/Inputs/Select";
import { useSearch, SearchOpts } from "../../hooks/useApi";
import BusyBoy from "../../components/BusyBoy/BusyBoy";
import Block from "../../style-guide/Block/Block";
import { Button, ButtonList } from "../../style-guide/Button/Button";
import { Media as IMedia } from "../../types/apiResponses";
import api from "../../util/api";
import transformImage from "../../util/transformImage";
import useApiUrl from "../../hooks/useApiUrl";
import { ModalBody, ModalActions, Modal } from "../../style-guide/Modal/Modal";
import Form from "../../style-guide/Inputs/Form";
import Field from "../../style-guide/Inputs/Field";
import Ruler from "../../style-guide/Ruler/Ruler";

interface WrapperProps {
  minimized?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  padding: ${(props) => props.theme.spacing.small};
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 8px;

  ${(props) =>
    props.minimized &&
    css`
      padding: 0;
      display: flex;

      > * {
        &:last-child {
          margin: 0 6px;
          flex: 1;
        }
      }
    `};
`;

const ImagePreviewWrapper = styled.div`
  cursor: pointer;
  margin-top: ${(props) => props.theme.spacing.small};
`;

interface PreviewProps {
  minimized?: boolean;
}

const ImagePreview = styled.div<PreviewProps>`
  min-height: 200px;
  margin-bottom: ${(props) => props.theme.spacing.small};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.theme.sizes.plusFive};
  color: ${(props) => props.theme.colors.border};

  ${(props) =>
    props.minimized &&
    css`
      padding: ${(props) => props.theme.spacing.xs};
      margin-bottom: 0;
      min-height: auto;
      height: 120px;
      width: 100%;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-right: 1px solid ${(props) => props.theme.colors.borderDim};
    `};
`;

const FilePreview = styled.div<PreviewProps>`
  margin-bottom: ${(props) => props.theme.spacing.small};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.border};

  svg {
    font-size: ${(props) => props.theme.sizes.plusFive};
  }

  p {
    color: ${(props) => props.theme.colors.textSecondary};
    margin: 0.5rem 0 0;
  }

  ${(props) =>
    props.minimized &&
    css`
      padding: ${(props) => props.theme.spacing.xs};
      margin-bottom: 0;
      min-height: auto;
      height: 120px;
      width: 100%;
      border-right: 1px solid ${(props) => props.theme.colors.borderDim};
    `};
`;

const Img = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
`;

const DropZoneRoot = styled.div<{ dragging: boolean }>`
  ${(props) =>
    props.dragging &&
    css`
      opacity: 0.5;
    `};
`;

export const Medias = styled.div`
  display: grid;
  grid-gap: ${(props) => props.theme.spacing.medium};
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-template-rows: auto;
`;

export const MediaWrapper = styled.div<{ selected?: boolean }>`
  width: 100%;
  height: auto;
  display: block;
  position: relative;
  color: ${(props) => props.theme.colors.textPrimary};
  text-decoration: none;
  border: 1px solid ${(props) => props.theme.colors.borderDim};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.12s ease-in-out;

  &:hover {
    text-decoration: none;
  }

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 120px;
    object-fit: contain;
  }

  ${(props) =>
    props.selected &&
    css`
      border-color: ${(props) => props.theme.colors.action};
      box-shadow: ${(props) => props.theme.shadows.large};
      transform: scale(1.04);
    `};
`;

export const MediaContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${(props) => props.theme.spacing.small};
  margin-top: auto;
`;

export const MediaActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.colors.borderDim};
  margin-top: auto;
  padding: ${(props) =>
    `${props.theme.spacing.xs} ${props.theme.spacing.small}`};
`;

export const Image = styled.img`
  display: block;
  cursor: pointer;
  border-radius: 8px;
  margin-top: auto;
`;

const FileWrapper = styled.div<{ width?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 120px;
  color: ${(props) => props.theme.colors.border};

  svg {
    display: block;
    font-size: 5em;
  }

  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
`;

export const DeleteButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const FileName = styled.p`
  margin: auto 0 0;
  padding-top: ${(props) => props.theme.spacing.xs};
  font-size: ${(props) => props.theme.sizes.negOne};
  text-align: center;
  word-break: break-all;
`;

export const FileAlt = styled.p`
  margin: auto 0 0;
  font-size: ${(props) => props.theme.sizes.negOne};
  color: ${(props) => props.theme.colors.textSecondary};
  text-align: center;
`;

interface MediaPickerProps {
  onChange: (image: RubicsImage | null) => any;
  value?: RubicsImage;
  editableAlt?: boolean;
  editableCaption?: boolean;
  editableTransforms?: boolean;
  editableLazyLoad?: boolean;
  hideLibrary?: boolean;
  minimize?: boolean;
  readOnly?: boolean;
  isFile?: boolean;
  isPrivate?: boolean;
}

const MediaPicker: React.FC<MediaPickerProps> = ({
  onChange,
  value,
  editableAlt,
  editableCaption,
  editableLazyLoad,
  hideLibrary,
  minimize,
  readOnly,
  isFile,
  isPrivate
}) => {
  const modal = useModal();
  const [isExpanded, setIsExpanded] = useState(!minimize);
  const [uploadingImages, setUploadingImages] = useState(false);
  const defaultEmptyImage: RubicsImage = {
    ref: "",
    url: "",
    alt: "",
    caption: ""
  };

  const [image, setImage] = useState(!!value ? value : defaultEmptyImage);

  useEffect(() => {
    if (!!value) {
      if (!deepEqual(value, image)) {
        setImage(value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value?.ref]);

  const randomId = useMemo(() => uuid(), []);
  const mediaUrl = useApiUrl(["media"]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setUploadingImages(true);

      acceptedFiles.forEach(async (file: File) => {
        const media = (await api<IMedia>({
          endpoint: mediaUrl,
          method: "POST",
          body: {
            file
          },
          queryParams: {
            private: isPrivate
          },
          multipart: true
        })) as IMedia;

        setUploadingImages(false);

        const imageData = {
          ...image,
          ref: media._id,
          url: media.secure_url,
          filename: media.original_filename,
          type: media.type
        };

        setImage(imageData);
        onChange(imageData);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [!!value, onChange, isPrivate]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function triggerLibrary() {
    modal.spawnModal(
      <Library
        value={value}
        onMediaSelect={(data) => {
          const imageData = {
            ...image,
            ref: data._id,
            url: data.secure_url,
            filename: data.original_filename,
            type: data.type
          };

          setImage(imageData);
          onChange(imageData);
        }}
        onClose={modal.despawnModal}
        isFile={isFile}
      />
    );
  }

  if (!isExpanded) {
    return (
      <Wrapper
        minimized
        onClick={() => {
          if (!readOnly) {
            setIsExpanded(true);
          }
        }}
      >
        {isFile ? (
          <FilePreview minimized>
            {image.url ? (
              <File type={image.type} label={image.filename} />
            ) : (
              <AiOutlineFileAdd />
            )}
          </FilePreview>
        ) : (
          <ImagePreview minimized>
            {image.url ? (
              <Img
                src={ImageClass.createUrl({ image })}
                alt="Forhåndsvisning"
              />
            ) : (
              <AiOutlineFileAdd />
            )}
          </ImagePreview>
        )}
        <ButtonList align="center" tight>
          <Button
            type="button"
            circular
            outlined
            smaller
            disabled={readOnly}
            onClick={() => {
              if (!readOnly) {
                setIsExpanded(true);
              }
            }}
          >
            <AiOutlineEdit />
          </Button>
        </ButtonList>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <ButtonList>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Button outlined smaller type="button">
            Last opp
          </Button>
        </div>
        {!hideLibrary && (
          <Button
            type="button"
            outlined
            autoFocus
            smaller
            onClick={(e) => {
              e.preventDefault();
              triggerLibrary();
            }}
          >
            Bibliotek
          </Button>
        )}
        {!!image.ref && (
          <Button
            type="button"
            smaller
            outlined
            onClick={(e) => {
              e.preventDefault();
              setImage(defaultEmptyImage);
              onChange(null);
            }}
          >
            Fjern
          </Button>
        )}
      </ButtonList>
      <ImagePreviewWrapper>
        <DropZoneRoot {...getRootProps()} dragging={isDragActive}>
          <input {...getInputProps()} />
          <BusyBoy busy={uploadingImages}>
            {isFile ? (
              <FilePreview>
                {image.url ? (
                  <File type={image.type} label={image.filename} />
                ) : (
                  <AiOutlineFileAdd />
                )}
              </FilePreview>
            ) : (
              <ImagePreview>
                {image.url ? (
                  <Img
                    src={ImageClass.createUrl({ image })}
                    alt="Forhåndsvisning"
                  />
                ) : (
                  <AiOutlineFileAdd />
                )}
              </ImagePreview>
            )}
          </BusyBoy>
        </DropZoneRoot>
      </ImagePreviewWrapper>
      {editableAlt && (
        <Field>
          <Label htmlFor={`alt-selector-${randomId}`}>Alternativ tekst</Label>
          <TextInput
            type="text"
            id={`alt-selector-${randomId}`}
            value={image.alt}
            onChange={(e) => {
              const updatedImage = { ...image, alt: e.target.value };

              setImage(updatedImage);
              onChange(updatedImage);
            }}
            disabled={uploadingImages}
          />
        </Field>
      )}
      {editableCaption && (
        <Field>
          <Label htmlFor={`caption-selector-${randomId}`}>Bildetekst</Label>
          <TextInput
            type="text"
            id={`caption-selector-${randomId}`}
            value={image.caption}
            onChange={(e) => {
              const updatedImage = { ...image, caption: e.target.value };

              setImage(updatedImage);
              onChange(updatedImage);
            }}
            disabled={uploadingImages}
          />
        </Field>
      )}
      {editableLazyLoad && (
        <Field>
          <Label htmlFor={`lazy-load-selector-${randomId}`}>Lazy load</Label>
          <Select
            id={`lazy-load-selector-${randomId}`}
            disabled={uploadingImages}
            value={
              typeof image.lazyLoad === "boolean"
                ? image.lazyLoad
                  ? "true"
                  : "false"
                : "true"
            }
            onChange={(e) => {
              const data = { ...image, lazyLoad: e.target.value === "true" };
              setImage(data);
              onChange(data);
            }}
          >
            <option value="false">Nei</option>
            <option value="true">Ja</option>
          </Select>
        </Field>
      )}
      {minimize && (
        <>
          <Ruler gutterTop tight />
          <ButtonList align="right">
            <Button
              type="button"
              circular
              outlined
              smaller
              onClick={() => {
                setIsExpanded(false);
              }}
            >
              <FaAngleUp />
            </Button>
          </ButtonList>
        </>
      )}
    </Wrapper>
  );
};

interface LibraryProps {
  onMediaSelect: (mediaObj: IMedia) => any;
  onClose?: () => void;
  value?: RubicsImage;
  isFile?: boolean;
}

const Library: React.FC<LibraryProps> = ({
  onMediaSelect,
  onClose,
  value,
  isFile
}) => {
  const defaultSelectedMedia: IMedia = {
    _id: "",
    url: "",
    original_filename: "",
    format: "",
    secure_url: "",
    cloudinary_id: "",
    fileType: isFile ? "raw" : "image",
    type: "",
    downloads: 0,
    tags: []
  };

  const url = useApiUrl(["media/search"]);
  const [media, setMedia] = useState<IMedia[]>([]);
  const [selectedMedia, selectMedia] = useState<IMedia>(defaultSelectedMedia);
  const [searchString, setSearchString] = useState("");
  const scrollParentRef = useRef<HTMLElement | null>(null);

  const queryOpts: SearchOpts<IMedia> = {
    endpoint: url,
    onSuccess: (medias, allMedias) => {
      setMedia(allMedias);

      if (value) {
        const selected = medias.find((m) => m._id === value.ref);

        if (selected) {
          selectMedia(selected);
        }
      }
    }
  };

  const { search, hasMore, loading } = useSearch<IMedia>(queryOpts);

  return (
    <Modal fullscreen>
      <ModalBody ref={(ref) => (scrollParentRef.current = ref)}>
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            search({
              ...queryOpts,
              paginate: true,
              queryParams: {
                name: searchString.length > 0 ? searchString : undefined,
                fileType: isFile ? undefined : "image",
                private: false
              }
            });
          }}
          hasMore={hasMore}
          useWindow={false}
          getScrollParent={() => scrollParentRef.current}
        >
          <BusyBoy busy={loading}>
            <Block>
              <Medias>
                {media.map((m) => (
                  <MediaWrapper
                    as="a"
                    href="#"
                    key={m._id}
                    selected={selectedMedia && selectedMedia._id === m._id}
                    onClick={(
                      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                    ) => {
                      e.preventDefault();
                      selectMedia({ ...selectedMedia, ...m });
                    }}
                  >
                    <MediaContent>
                      {m.fileType === "image" ? (
                        <Image
                          src={transformImage(m.secure_url, "w_400")}
                          alt={m.original_filename}
                        />
                      ) : (
                        <File type={m.type} />
                      )}
                      <FileName>{m.original_filename}</FileName>
                      {m.altText && <FileAlt>{m.altText}</FileAlt>}
                      {(m.tags || []).length > 0 && (
                        <FileAlt>{m.tags.join(", ")}</FileAlt>
                      )}
                    </MediaContent>
                  </MediaWrapper>
                ))}
              </Medias>
            </Block>
          </BusyBoy>
        </InfiniteScroll>
      </ModalBody>
      <ModalActions multiple>
        <Form
          onSubmit={(e) => {
            e.preventDefault();

            search({
              ...queryOpts,
              queryParams: {
                text: searchString.length > 0 ? searchString : undefined,
                fileType: isFile ? undefined : "image"
              }
            });
          }}
        >
          <ButtonList>
            <TextInput
              type="text"
              placeholder="Søk etter media"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            />
            <Button outlined>Søk</Button>
          </ButtonList>
        </Form>
        <ButtonList align="right">
          <Button type="button" outlined onClick={onClose}>
            Avbryt
          </Button>
          <Button
            disabled={!selectedMedia._id}
            onClick={() => {
              onMediaSelect(selectedMedia);

              if (typeof onClose === "function") {
                onClose();
              }
            }}
          >
            Velg
          </Button>
        </ButtonList>
      </ModalActions>
    </Modal>
  );
};

interface FileProps {
  type?: string;
  label?: string;
  width?: number;
}

export const File: React.FC<FileProps> = ({ type = "", label, width }) => {
  const icon = useMemo<ReactNode>(() => {
    if (type.startsWith("video")) {
      return <AiOutlineVideoCamera />;
    }

    switch (type) {
      case "application/pdf":
        return <AiOutlineFilePdf />;
      case "application/zip":
        return <AiOutlineFileZip />;
      default:
        return <AiOutlineFileUnknown />;
    }
  }, [type]);

  return (
    <FileWrapper width={width}>
      {icon}
      {label && <p>{label}</p>}
    </FileWrapper>
  );
};

export default MediaPicker;
