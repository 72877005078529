import styled from "styled-components";

interface Props {
  size?: "small" | "medium" | "large";
}

const sizes = {
  small: "30rem",
  medium: "60rem",
  large: "50rem"
};

const WidthConstraints = styled.div<Props>`
  margin: 0 auto;
  max-width: ${props => sizes[props.size || "medium"]};
`;

export default WidthConstraints;
