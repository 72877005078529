import React from "react";
import { AiOutlineBank, AiOutlineFile } from "react-icons/ai";
import Fader from "../../../style-guide/Fader/Fader";
import Text from "../../../style-guide/Text/Text";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import { CardLink } from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Icon from "../../../components/Icon/Icon";
import Crumb from "../../../components/Crumb/Crumb";
import Section from "../../../style-guide/Section/Section";
import Grid from "../../../style-guide/Grid/Grid";

const SettingsNavigation: React.FC = () => {
  return (
    <Fader>
      <Block hugTop>
        <Text element="h1" variant="display3">
          <Crumb url="/Innstillinger">Innstillinger</Crumb>
          Maler
        </Text>
      </Block>
      <Section hugTop>
        <Grid gap="medium" columns={3}>
          <CardLink to="/innstillinger/maler/liste">
            <Block flex>
              <Flex flex={1}>
                <FlexKid flex={1}>
                  <Text element="h2" variant="title">
                    Oversikt over maler
                  </Text>
                  <Text variant="body3">Endre og opprett maler</Text>
                </FlexKid>
                <FlexKid spaceLeft>
                  <Icon size="large">
                    <AiOutlineBank />
                  </Icon>
                </FlexKid>
              </Flex>
            </Block>
          </CardLink>
          <CardLink to="/innstillinger/maler/bruk" stretch>
            <Block flex>
              <Flex flex={1}>
                <FlexKid flex={1}>
                  <Text element="h2" variant="title">
                    Malbruk
                  </Text>
                  <Text variant="body3">
                    Bestem hvilke maler som skal brukes til hvilke sidetyper
                  </Text>
                </FlexKid>
                <FlexKid spaceLeft>
                  <Icon size="large">
                    <AiOutlineFile />
                  </Icon>
                </FlexKid>
              </Flex>
            </Block>
          </CardLink>
        </Grid>
      </Section>
    </Fader>
  );
};

export default SettingsNavigation;
