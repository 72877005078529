import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Card,
  CardStyles,
  CardActions,
  CardImage,
  CardHero,
  CardProps
} from "@ludens-reklame/rubics-theme/dist/components/Card";

const CardLink = styled(Link)<CardProps>`
  ${CardStyles}

  transition: transform 0.12s ease-in-out;

  &:hover,
  &:focus {
    transform: scale(1.025);
    text-decoration: none;
  }
`;

export default Card;
export { CardLink, CardImage, CardActions, CardHero };
