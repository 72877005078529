import React, { useCallback, useState } from "react";
import {
  AiOutlineCloudDownload,
  AiOutlineCloudUpload,
  AiOutlineDoubleRight
} from "react-icons/ai";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { Redirect } from "../../../types/apiResponses";
import Text from "../../../style-guide/Text/Text";
import { Button, ButtonList } from "../../../style-guide/Button/Button";
import Tabler from "../../../components/Tabler/Tabler";
import CrudModal, {
  createCrudModalField
} from "../../../components/CrudModal/CrudModal";
import Pre from "../../../style-guide/Pre/Pre";
import Ruler from "../../../style-guide/Ruler/Ruler";
import api, { Service } from "../../../util/api";
import Status from "../../../style-guide/Status/Status";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";
import useNotifications from "../../../hooks/useNotifications";

const Redirects: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataHash, setDataHash] = useState<string>("");
  const { spawnModal } = useModal();
  const notifications = useNotifications();

  const exportCsv = useCallback(async () => {
    notifications.spawn({
      title: "Eksporterer redirects…",
      icon: <AiOutlineCloudDownload />
    });

    const response = await api<Response>({
      endpoint: "redirects/export",
      noJSON: true
    });

    notifications.spawn({ title: "Redirects eksportert" });

    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
    URL.revokeObjectURL(url);
  }, [notifications]);

  const uploadModal = useCallback(() => {
    spawnModal(
      <CrudModal
        title="Importer redirects"
        initialData={{}}
        onSubmit={async (data) => {
          if (!loading) {
            setLoading(true);
            notifications.spawn({
              title: "Importerer redirects…",
              icon: <AiOutlineCloudUpload />
            });

            try {
              await api<Redirect[]>({
                method: "POST",
                service: Service.Admin,
                endpoint: "redirects",
                multipart: true,
                body: data
              });

              setDataHash(Date.now().toString());
              notifications.spawn({ title: "Redirects importert" });
            } catch (error) {
              console.error(error);
            }

            setLoading(false);
          }
        }}
        submitLabel="Importer"
        fields={[
          {
            key: "description",
            render: () => (
              <>
                <Text gutterBottom>
                  For å importere redirect-regler i Rubics, må du laste opp et{" "}
                  <strong>kommaseparert</strong> CSV-ark strukturert som
                  eksempelet under:
                </Text>
                <Pre>
                  {`/gammel/url,/ny/url,301\n/gammel/url-2,/ny/url-2,302\n/gammel/url-3,/ny/url-3`}
                </Pre>
                <Text gutterTop gutterBottom>
                  Første kolonne er gammel URL (uten https, www og domenenavn),
                  andre kolonne er ny URL (uten https, www og domenenavn) og
                  tredje kolonne er redirect-koden (301 for permanent redirect
                  og 302 for midlertidig redirect. 301 er standard dersom denne
                  kolonnen ikke brukes.).
                </Text>
                <Text gutterTop gutterBottom>
                  En redirect vil kun skje dersom ingen sider eller produkter
                  med matchende permalenk finnes i Rubics.
                </Text>
                <Status variant="bad">
                  <strong>Viktig!</strong>
                </Status>
                <Text gutterTop>
                  Når du importerer et redirect-ark, <strong>ERSTATTER</strong>{" "}
                  du alle tidligere redirect-oppføringer, så husk å ha med de
                  gamle oppføringene i det nye arket, dersom du ønsker å beholde
                  dem.
                </Text>
                <Ruler gutterTop />
              </>
            )
          },
          {
            key: "csv",
            render: (payload) =>
              createCrudModalField({
                payload,
                key: "csv",
                type: "file",
                label: "Redirect-ark (CSV-fil)",
                accept: ".csv",
                required: true
              })
          }
        ]}
      />
    );
  }, [loading, notifications]);

  return (
    <BusyBoy busy={loading}>
      <Tabler
        key={dataHash}
        title="Redirects"
        crumb={{
          label: "Innstillinger",
          path: "/innstillinger"
        }}
        actions={
          <ButtonList>
            <Button outlined onClick={exportCsv}>
              Eksporter
            </Button>
            <Button onClick={uploadModal}>Importer redirects</Button>
          </ButtonList>
        }
        emptyState={{
          title: "Ingen redirect-regler lagt til",
          icon: <AiOutlineDoubleRight />,
          text: "Dersom du har flyttet fra en tidligere nettside, og ønsker å omdirigere trafikk fra URL-er som ikke lenger eksisterer, kan du administrere det her.",
          action: <Button onClick={uploadModal}>Importer redirects</Button>
        }}
        url={["redirects"]}
        columns={[
          {
            Header: "Fra",
            accessor: "from"
          },
          {
            Header: "Til",
            accessor: "to"
          },
          {
            Header: "Type",
            accessor: "code",
            align: "center"
          }
        ]}
        createRow={(redirect) => redirect}
        limit={999999999}
      />
    </BusyBoy>
  );
};

export default Redirects;
