import React from "react";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import Fader from "../../../style-guide/Fader/Fader";
import { Customer } from "../../../types/apiResponses";
import SubscriptionsTable from "../../Subscriptions/Subscriptions";

interface Props {
  publisher: UsePublisherInterface<Customer>;
}

const Subscriptions: React.FC<Props> = ({ publisher }) => {
  return (
    <Fader>
      <SubscriptionsTable
        hideTitle
        query={{
          customer: publisher.form.data._id
        }}
      />
    </Fader>
  );
};

export default Subscriptions;
