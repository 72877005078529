import React, { useCallback, useMemo, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import { useDebouncedCallback } from "use-debounce/lib";
import {
  Image as ImageClass,
  Product as ProductClass
} from "@ludens-reklame/rubics-sdk";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import {
  Product,
  SimpleVariant as ISimpleVariant,
  Attribute,
  ProductTemplate
} from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import Card from "../../../style-guide/Card/Card";
import convertImage from "../../../util/convertImage";
import { Button, ButtonList } from "../../../style-guide/Button/Button";
import { Table, Th, Td, Tr } from "../../../style-guide/Table/Table";
import Image from "../../../style-guide/Image/Image";
import { AiOutlineSearch, AiOutlineTags } from "react-icons/ai";
import Select from "../../../style-guide/Inputs/Select";
import Block from "../../../style-guide/Block/Block";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Text from "../../../style-guide/Text/Text";
import TextInput from "../../../style-guide/Inputs/TextInput";
import Section from "../../../style-guide/Section/Section";
import useTipster from "../../../hooks/useTipster";
import { SearchOpts, useSearch, useApi } from "../../../hooks/useApi";
import useApiUrl from "../../../hooks/useApiUrl";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";
import useSimplePatch from "../../../hooks/useSimplePatch";
import ConnectionSearchModal from "../Components/ConnectionSearchModal";
import { Dotter, Dot } from "../../../style-guide/Dotter/Dotter";
import localize from "../../../util/localize";
import { variantType } from "../../../constants/localizations";
import ConnectionNewModal from "../Components/ConnectionNewModal";
import SimpleVariant from "./SimpleVariant";
import { ANY_ATTRIBUTE_VALUE_PLACEHOLDER } from "../../../constants/general";
import {
  InlineEditHead,
  InlineEditColumn
} from "../../../style-guide/InlineEdit/InlineEdit";
import EmptyState from "../../../style-guide/EmptyState/EmptyState";
import Ruler from "../../../style-guide/Ruler/Ruler";

interface Props {
  publisher: UsePublisherInterface<Product>;
}

const Variants: React.FC<Props> = ({ publisher }) => {
  const form = publisher.form;
  const data = form.data;

  const [hasAddedSimpleVariant, setHasAddedSimpleVariant] = useState(false);
  const history = useHistory();
  const { spawnModal } = useModal();
  const connectedProductsTipster = useTipster("connectedProducts");

  // Connections search
  const connectionsUrl = useApiUrl(["products"]);

  const connectionsConfig: SearchOpts<Product> = {
    endpoint: connectionsUrl,
    queryParams: {
      draft: true,
      ...(data.master ? { master: data.master } : { _id: data._id })
    }
  };

  const connectionsSearch = useSearch<Product>(connectionsConfig);

  const handleConnectionsSearch = useCallback(
    (query: string) => {
      connectionsSearch.search({
        ...connectionsConfig,
        queryParams: {
          ...connectionsConfig.queryParams,
          text: query
        }
      });
    },
    [connectionsSearch, connectionsConfig]
  );

  const [debouncedConnectionsSearch] = useDebouncedCallback(
    handleConnectionsSearch,
    500
  );

  useEffect(() => {
    connectionsSearch.search(connectionsConfig);
  }, [data.master]);

  // Simple variants search
  const simpleVariantsUrl = useApiUrl([
    "products",
    data._id,
    "simple-variants"
  ]);

  const simpleVariantsConfig: SearchOpts<ISimpleVariant> = {
    endpoint: simpleVariantsUrl
  };

  const simpleVariantsSearch = useSearch<ISimpleVariant>({
    ...simpleVariantsConfig,
    fetchOnMount: true
  });

  useEffect(() => {
    simpleVariantsSearch.search(simpleVariantsConfig);
  }, [publisher.publishDiscardHash]);

  const createSimpleVariant = useApi<null>({
    endpoint: simpleVariantsUrl,
    method: "POST",
    initialData: null,
    headers: {
      "x-webhook-disable": true
    },
    onSuccess: () => {
      simpleVariantsSearch.search(simpleVariantsConfig);
    }
  });

  const handleCreateSimpleVariant = useCallback(async () => {
    createSimpleVariant.fetch();
    setHasAddedSimpleVariant(true);
  }, [createSimpleVariant.fetch]);

  const attributesUrl = useApiUrl(["attributes"]);

  const attributeIds = useMemo<string[]>(() => {
    let ids: string[] = [];

    if (data.template) {
      ids = (data.template as ProductTemplate).attributes
        .filter((a) => a.filterable)
        .map((a) => a.ref);
    }

    if (data.templateAttributes) {
      ids = [
        ...ids,
        ...(data.templateAttributes || [])
          .filter((a) => a.filterable)
          .map((a) => a.ref)
      ];
    }

    return ids;
  }, [data.template, data.templateAttributes]);

  const attributesSearch = useSearch<Attribute>({
    endpoint: attributesUrl,
    fetchOnMount: attributeIds.length > 0,
    queryParams: {
      _id: attributeIds.join(",")
    }
  });

  return (
    <Fader>
      <Flex align="top">
        <FlexKid flex={2}>
          <Card>
            <Block>
              <Section hugTop>
                <Text element="h2" variant="title">
                  Koblede produkter
                </Text>
              </Section>
              <Flex>
                <FlexKid flex={1}>
                  <TextInput
                    icon={<AiOutlineSearch />}
                    placeholder="Søk etter koblet produkt"
                    onChange={(e) => debouncedConnectionsSearch(e.target.value)}
                  />
                </FlexKid>
                {!publisher.isRevision && (
                  <FlexKid spaceLeft>
                    <ButtonList>
                      <Button
                        type="button"
                        outlined
                        onClick={() =>
                          spawnModal(
                            <ConnectionSearchModal
                              product={data}
                              setField={form.setField}
                              onChange={(product) => {
                                connectionsSearch.search({
                                  ...connectionsConfig,
                                  queryParams: {
                                    ...connectionsConfig.queryParams,
                                    _id: undefined,
                                    master: product.master
                                  }
                                });

                                form.refresh();
                              }}
                            />
                          )
                        }
                      >
                        Koble eksisterende produkt
                      </Button>
                      <Button
                        type="button"
                        outlined
                        onClick={() =>
                          spawnModal(
                            <ConnectionNewModal
                              product={data}
                              setField={form.setField}
                              onSelect={(action, product) => {
                                if (action === "CREATE") {
                                  history.push(
                                    `/butikk/produkter/opprett?master=${data.master}`
                                  );
                                } else if (product) {
                                  history.push(
                                    `/butikk/produkter/${product._id}`
                                  );
                                }
                              }}
                            />
                          )
                        }
                      >
                        Koble nytt produkt
                      </Button>
                    </ButtonList>
                  </FlexKid>
                )}
              </Flex>
            </Block>
            <InfiniteScroll
              pageStart={1}
              loadMore={() => {
                connectionsSearch.search({
                  ...connectionsConfig,
                  paginate: true
                });
              }}
              initialLoad={false}
              hasMore={connectionsSearch.hasMore}
            >
              <BusyBoy busy={connectionsSearch.loading}>
                <Table>
                  <thead>
                    <Tr>
                      <Th style={{ width: "1px" }} />
                      <Th>Produkt</Th>
                      <Th>Attributter</Th>
                      <Th>Grupper i listevisning</Th>
                      <Th align="right">Handlinger</Th>
                    </Tr>
                  </thead>
                  <tbody>
                    {connectionsSearch.results.length > 0 ? (
                      <>
                        {connectionsSearch.results
                          // Sort so that the current product is always at the top of the list
                          .sort((a, b) => {
                            if (a._id === data._id) {
                              return -1;
                            }

                            return b._id === data._id ? 1 : 0;
                          })
                          .map((v, k) => (
                            <ConnectedProduct
                              key={v._id}
                              product={v}
                              parent={data}
                              readOnly={publisher.isRevision}
                              highlight={
                                k === 0 && connectionsSearch.results.length > 1
                              }
                              onRemoveConnection={(p) => {
                                if (p && p._id === data._id) {
                                  form.refresh();
                                } else {
                                  connectionsSearch.search(connectionsConfig);
                                }
                              }}
                            />
                          ))}
                      </>
                    ) : (
                      <Tr>
                        <Td colSpan={5}>
                          <Text variant="subheading">
                            Ingen varianter funnet!
                          </Text>
                        </Td>
                      </Tr>
                    )}
                  </tbody>
                </Table>
              </BusyBoy>
            </InfiniteScroll>
          </Card>
        </FlexKid>
        {connectedProductsTipster.show && (
          <FlexKid flex={1} spaceLeft>
            <Card outlined>
              <Block>
                <Text element="h3" variant="title">
                  Hva er et koblet produkt?
                </Text>
                <Text variant="body3" gutterBottom>
                  Fordelen med å bruke koblede produkter i stedet for enkle
                  varianter, er at koblede produkter får egne produktsider, noe
                  som kan være positivt for SEO, samt at de dukker opp i
                  søkeresultater på nettsiden.
                </Text>
                <Text variant="body3" gutterBottom>
                  <strong>Eksempel:</strong> Dersom du selger en mobiltelefon i
                  to forskjellige størrelser (64GB og 128GB), kan det være en
                  fordel å lage de som frittstående produkter, for så å koble
                  dem sammen, slik at kunder som søker
                  <em>"Mobiltelefon 128GB"</em> kommer rett til det produktet de
                  ønsker å kjøpe.
                </Text>
                <Text variant="body3">
                  <strong>Merk:</strong> For at to eller flere produkter skal
                  kunne vises som varianter av hverandre i nettbutikken, må de
                  dele minst én attributt av samme navn.
                </Text>
                <ButtonList gutterTop>
                  <Button
                    type="button"
                    outlined
                    onClick={() => connectedProductsTipster.toggleShow(false)}
                  >
                    Skjul tips
                  </Button>
                </ButtonList>
              </Block>
            </Card>
          </FlexKid>
        )}
      </Flex>
      <Section>
        <Card>
          <Block>
            <Flex>
              <FlexKid flex={1}>
                <Text element="h2" variant="title">
                  Enkle varianter
                </Text>
              </FlexKid>
              {!publisher.isRevision && (
                <FlexKid spaceLeft>
                  <Button
                    type="button"
                    outlined
                    onClick={() => handleCreateSimpleVariant()}
                    disabled={
                      createSimpleVariant.loading ||
                      attributesSearch.results.length === 0
                    }
                  >
                    Ny enkel variant
                  </Button>
                </FlexKid>
              )}
            </Flex>
          </Block>
          {attributeIds.length === 0 ? (
            <EmptyState
              title="Ingen attributter lagt til for enkle varianter"
              icon={<AiOutlineTags />}
              text={
                <>
                  <Text gutterBottom>
                    For å kunne opprette enkle varianter må du legge til minst
                    én attributt merket <em>For enkle varianter</em>.
                  </Text>
                  <Text>
                    Dette kan gjøres i produktfanen{" "}
                    <strong>
                      <Link to="attributter">Attributter</Link>
                    </strong>
                    .
                  </Text>
                </>
              }
            />
          ) : (
            <>
              <InlineEditHead>
                <InlineEditColumn width="230px">Navn</InlineEditColumn>
                {attributesSearch.results.map((a) => (
                  <InlineEditColumn key={a._id} width="180px">
                    {a.name}
                  </InlineEditColumn>
                ))}
              </InlineEditHead>
              <BusyBoy
                busy={
                  simpleVariantsSearch.loading || createSimpleVariant.loading
                }
              >
                {simpleVariantsSearch.results.length > 0 ? (
                  simpleVariantsSearch.results.map((v, k) => (
                    <SimpleVariant
                      key={v._id}
                      variant={v}
                      publisher={publisher}
                      attributes={attributesSearch.results}
                      isNew={
                        hasAddedSimpleVariant &&
                        k + 1 === simpleVariantsSearch.results.length
                      }
                      onDelete={() =>
                        simpleVariantsSearch.search(simpleVariantsConfig)
                      }
                    />
                  ))
                ) : (
                  <>
                    <Ruler />
                    <Block hugTop>
                      <Text variant="subheading">Ingen varianter funnet!</Text>
                    </Block>
                  </>
                )}
              </BusyBoy>
            </>
          )}
        </Card>
      </Section>
    </Fader>
  );
};

interface ConnectedProductProps {
  parent: Product;
  product: Product;
  highlight?: boolean;
  readOnly?: boolean;
  onRemoveConnection: (product?: Product) => any;
}

const ConnectedProduct: React.FC<ConnectedProductProps> = ({
  parent,
  product,
  highlight,
  readOnly,
  onRemoveConnection
}) => {
  const isSelf = parent._id === product._id;

  const endpoint = useApiUrl(["products", product._id]);
  const simplePatch = useSimplePatch<Product>({
    endpoint,
    initialData: product,
    publish: !isSelf,
    publishEndpoint: "publish",
    nameLabel: product.name,
    onSuccess: onRemoveConnection
  });

  const image = useMemo(
    () =>
      ProductClass.getPrimaryImage({
        images: product.images.map(convertImage)
      }),
    [product.images]
  );

  const imageUrl = useMemo(
    () => ImageClass.createUrl({ image, transforms: "w_82,h_82,c_pad" }),
    [image]
  );

  return (
    <Tr highlight={highlight}>
      <Td verticalAlign="middle">
        <Image width={41} height={41} src={imageUrl} alt={image.alt} />
      </Td>
      <Td verticalAlign="middle">
        <Text variant="body2">
          <Link to={`/butikk/produkter/${product._id}`}>{product.name}</Link>
        </Text>
        <Text variant="body3">{product.sku || "Mangler SKU"}</Text>
      </Td>
      <Td verticalAlign="middle">
        <Text variant="body3">
          {product.attributes.length > 0 ? (
            <Dotter>
              {product.attributes.map((a) => (
                <Dot key={a._id}>
                  {localize(variantType, a.name)}:{" "}
                  {a.value === ANY_ATTRIBUTE_VALUE_PLACEHOLDER
                    ? "Enhver verdi"
                    : a.value}
                </Dot>
              ))}
            </Dotter>
          ) : (
            "Ingen"
          )}
        </Text>
      </Td>
      <Td verticalAlign="middle">
        <BusyBoy busy={simplePatch.loading}>
          <Select
            value={simplePatch.form.data.variant ? "true" : "false"}
            disabled={readOnly || simplePatch.loading}
            onChange={(e) => {
              simplePatch.form.setField({
                path: "variant",
                value: e.target.value === "true",
                submit: true
              });
            }}
          >
            <option value="false">Nei</option>
            <option value="true">Ja</option>
          </Select>
        </BusyBoy>
      </Td>
      <Td align="right">
        <ButtonList align="right">
          <Button
            type="button"
            outlined
            disabled={readOnly || !product.master}
            onClick={() => {
              if (
                product.master &&
                window.confirm("Er du sikker på at du vil fjerne koblingen?")
              ) {
                simplePatch.form.setField({
                  path: "master",
                  value: null,
                  submit: true
                });
              }
            }}
          >
            Fjern kobling
          </Button>
        </ButtonList>
      </Td>
    </Tr>
  );
};

export default Variants;
