import React, { useContext } from "react";
import { Site } from "../../../types/apiResponses";
import { SiteContext } from "../../../context/Site";
import { UserContext } from "../../../context/User";
import createPublisherInputField from "../../../util/createPublisherInputField";
import usePublisher from "../../../hooks/usePublisher";
import Crumb from "../../../components/Crumb/Crumb";
import Section from "../../../style-guide/Section/Section";
import Card from "../../../style-guide/Card/Card";
import StickyBar from "../../../style-guide/StickyBar/StickyBar";
import Loading from "../../../style-guide/Loading/Loading";
import { Button } from "../../../style-guide/Button/Button";
import Text from "../../../style-guide/Text/Text";
import Form from "../../../style-guide/Inputs/Form";
import Block from "../../../style-guide/Block/Block";

const CookieMeta: React.FC = () => {
  const user = useContext(UserContext).data;
  const siteContext = useContext(SiteContext);

  const publisher = usePublisher<Site>({
    id: user.site!,
    baseUrl: ["site"],
    baseDashboardUrl: "/innstillinger/informasjonskapsler/konfigurasjon",
    initialData: {},
    skipId: true,
    pathsToCopy: [
      {
        path: "cookieDialogMeta.intro",
        label: "Introduksjon til cookie-dialogen"
      }
    ],
    onSubmit: (site: Site) => {
      if (site) {
        siteContext.setData(site);
      }
    }
  });

  return (
    <Form onSubmit={publisher.form.submit}>
      <Block hugTop hugBottom>
        <Text element="h1" variant="display3">
          <Crumb url="/innstillinger/informasjonskapsler">
            Informasjonskapsler
          </Crumb>
          Konfigurasjon
        </Text>
      </Block>
      <Section>
        <Card>
          <Block>
            {createPublisherInputField(publisher, {
              path: "cookieDialogMeta.intro",
              label: "Introduksjon i cookie-dialogen",
              type: "draft",
              syncState: publisher.form.syncState
            })}
            {createPublisherInputField(publisher, {
              path: "cookieDialogMeta.primaryColor",
              label: "Primærfarge",
              type: "color",
              syncState: publisher.form.syncState
            })}
            {createPublisherInputField(publisher, {
              path: "cookieDialogMeta.textColor",
              label: "Tekstfarge",
              type: "color",
              syncState: publisher.form.syncState
            })}
            {createPublisherInputField(publisher, {
              path: "cookieDialogMeta.backgroundColor",
              label: "Bakgrunnsfarge",
              type: "color",
              syncState: publisher.form.syncState
            })}
            {createPublisherInputField(publisher, {
              path: "cookieDialogMeta.cardColor",
              label: "Kortfarge",
              type: "color",
              syncState: publisher.form.syncState
            })}
            {createPublisherInputField(publisher, {
              path: "cookieDialogMeta.borderColor",
              label: "Rammefarge",
              type: "color",
              syncState: publisher.form.syncState
            })}
          </Block>
        </Card>
      </Section>
      <StickyBar>
        <Loading loading={publisher.form.submitting} />
        <Button disabled={!publisher.form.hasMadeChanges}>Lagre</Button>
      </StickyBar>
    </Form>
  );
};

export default CookieMeta;
