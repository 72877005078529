import React from "react";
import { useHistory } from "react-router-dom";
import Fader from "../../style-guide/Fader/Fader";
import Card from "../../style-guide/Card/Card";
import WidthConstraints from "../../style-guide/WidthConstraints/WidthConstraints";
import { useSearch } from "../../hooks/useApi";
import useSiteSelection from "../../hooks/useSiteSelection";
import { Site } from "../../types/apiResponses";
import Table from "../../components/Table/Table";
import BusyBoy from "../../components/BusyBoy/BusyBoy";
import Text from "../../style-guide/Text/Text";
import Block from "../../style-guide/Block/Block";
import { Button, ButtonList } from "../../style-guide/Button/Button";
import EmptyState from "../../style-guide/EmptyState/EmptyState";
import Section from "../../style-guide/Section/Section";
import logout from "../../util/logout";
import apiUrl from "../../util/apiUrl";

const SiteSelection: React.FC = () => {
  const history = useHistory();
  const { setSite } = useSiteSelection();

  const { results, loading, hasSearched } = useSearch<Site>({
    endpoint: apiUrl(["sites"]),
    fetchOnMount: true
  });

  function handleSiteSelection(site: Site) {
    setSite(site);
    history.replace("/");
  }

  return (
    <Fader>
      <WidthConstraints>
        {hasSearched && results.length === 0 ? (
          <EmptyState
            title="Å nei, du mangler en nettside!"
            text="Dessverre får du ikke gjort så mye med det akkurat nå. Dersom det er meningen at du skal ha en nettside, kan du høre med vedkommende som drifter nettsiden, så kan de invitere deg!"
            action={
              <Button outlined onClick={async () => await logout()}>
                Logg ut
              </Button>
            }
          />
        ) : (
          <WidthConstraints size="small">
            <Section>
              <Block hugTop>
                <Text element="h1" variant="display1">
                  Vennligst velg nettsted
                </Text>
              </Block>
              <Card>
                <BusyBoy busy={loading}>
                  <Table
                    columns={[
                      {
                        Header: "Nettsted",
                        accessor: "displayName"
                      },
                      {
                        Header: "",
                        accessor: "action",
                        align: "right",
                        verticalAlign: "middle"
                      }
                    ]}
                    data={results.map((s) => ({
                      ...s,
                      displayName: (
                        <>
                          <Text variant="body2">{s.displayName || s.name}</Text>
                          <Text variant="body3">
                            {s.canonicalDomain
                              ? s.canonicalDomain
                              : `${s.name}.${process.env.REACT_APP_RUBICS_DOMAIN}`}
                          </Text>
                        </>
                      ),
                      action: (
                        <Button outlined onClick={() => handleSiteSelection(s)}>
                          Velg
                        </Button>
                      )
                    }))}
                  />
                </BusyBoy>
              </Card>
            </Section>
            <Block hugTop>
              <ButtonList align="center">
                <Button outlined onClick={async () => await logout()}>
                  Logg ut
                </Button>
              </ButtonList>
            </Block>
          </WidthConstraints>
        )}
      </WidthConstraints>
    </Fader>
  );
};

export default SiteSelection;
