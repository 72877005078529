import React, { useEffect, useCallback, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import { AiOutlineDelete, AiOutlineSave } from "react-icons/ai";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import useApiUrl from "./useApiUrl";
import useForm, { FieldCleaner, UseFormInterface } from "./useForm";
import { useApi } from "./useApi";
import useCopyPaste, { CopyPastePath } from "./useCopyPaste";
import useNotifications from "./useNotifications";
import useQueryParams from "./useQueryParams";
import createId from "../util/createId";
import useRevisionId from "./useRevisionId";
import { DependencyMap } from "../types/apiResponses";
import api from "../util/api";
import DependentsModal from "../components/DependentsModal/DependentsModal";
import useExperimentId from "./useExperimentId";

interface IdImplementer {
  _id: string;
  draftFor?: string;
}

interface Opts<T> {
  id: string;
  baseUrl: string[];
  baseDashboardUrl: string;
  publishEndpoint?: string;
  discardEndpoint?: string;
  initialData?: any;
  pathsToCopy?: CopyPastePath[];
  useDraftId?: boolean;
  idField?: string;
  revision?: string;
  skipId?: boolean;
  standalonePublishDiscardEndpoint?: boolean;
  onDeleteFallbackUrl?: string;
  skipDependentsCheck?: boolean;
  bypassIgnoreKeys?: string[];
  onCreateNew?: (resource: any) => any;
  onPublishDiscard?: (resource: any) => any;
  onSubmit?: (resource: any) => any;
  cleanField?: FieldCleaner;
  onPaste?: (data: Partial<T>) => Partial<T>;
}

export interface UsePublisherInterface<T> {
  dashboardUrl: string;
  isNew: boolean;
  isRevision: boolean;
  revision: string | undefined;
  loading: boolean;
  publishDiscardHash: string;
  form: UseFormInterface<T>;
  experiment?: string;
  experimentVariant?: string;
  delete: () => Promise<void>;
  publish: () => Promise<void>;
  discard: () => Promise<void>;
  rollback: () => Promise<void>;
  setLoading: (loading: boolean) => void;
}

function usePublisher<T extends IdImplementer>(
  opts: Opts<T>
): UsePublisherInterface<T> {
  const isNew = opts.id === "opprett";

  const [publishDiscardHash, setPublishDiscardHash] = useState("");
  const [externalLoading, setExternalLoading] = useState(false);
  const notifications = useNotifications();
  const queryParams = useQueryParams<T>();
  const revision = useRevisionId();
  const experiment = useExperimentId();
  const history = useHistory();
  const { spawnModal } = useModal();
  const urlString = useApiUrl(
    isNew || opts.skipId ? opts.baseUrl : [...opts.baseUrl, opts.id]
  );

  const initialData = opts.initialData || {};
  const isRevision = !!opts.revision || !!revision;

  const form = useForm<T>(
    isNew ? { ...initialData, ...queryParams } : initialData,
    {
      endpoint: urlString,
      method: isNew ? "POST" : "PATCH",
      prefillEndpoint: !isNew ? urlString : undefined,
      prefillQueryParams: isRevision
        ? {
            revision: opts.revision || revision
          }
        : !!experiment
        ? experiment
        : undefined,
      replaceData: true,
      queryParams: experiment,
      cleanField: opts.cleanField,
      onSuccess: (resource) => {
        if (isNew) {
          if (typeof opts.onCreateNew === "function") {
            opts.onCreateNew(resource);
          } else {
            history.push(
              `${opts.baseDashboardUrl}/${resource[opts.idField || "_id"]}`
            );
          }
        } else if (!opts.publishEndpoint) {
          notifications.spawn({
            title: "Endringer lagret",
            icon: <AiOutlineSave />
          });
        }

        if (typeof opts.onSubmit === "function") {
          opts.onSubmit(resource);
        }
      }
    }
  );

  const deleteRequest = useApi<any>({
    endpoint: urlString,
    method: "DELETE",
    initialData: null,
    onSuccess: () => {
      if (opts.onDeleteFallbackUrl) {
        history.push(opts.onDeleteFallbackUrl);
      } else {
        history.push(opts.baseDashboardUrl);
      }

      notifications.spawn({
        title: "Sletting fullført",
        icon: <AiOutlineDelete />
      });
    }
  });

  const rollbackEndpoint = useMemo(() => {
    if (urlString.endsWith("/")) {
      return `${urlString}rollback/${revision}`;
    }

    return `${urlString}/rollback/${revision}`;
  }, [urlString, revision]);

  const rollbackRequest = useApi<any>({
    endpoint: rollbackEndpoint,
    method: "POST",
    initialData: null,
    onSuccess: (resource) => {
      if (resource) {
        window.location.assign(window.location.pathname);
      }
    }
  });

  const checkDependenciesEndpoint = useApiUrl(["resource-dependencies"]);

  const publishDiscardEndpoint = useMemo(() => {
    if (opts.standalonePublishDiscardEndpoint) {
      return {
        publish: opts.publishEndpoint || "",
        discard: opts.discardEndpoint || ""
      };
    }

    if (urlString.endsWith("/")) {
      return {
        publish: `${urlString}${opts.publishEndpoint}`,
        discard: `${urlString}${opts.discardEndpoint}`
      };
    }

    return {
      publish: `${urlString}/${opts.publishEndpoint}`,
      discard: `${urlString}/${opts.discardEndpoint}`
    };
  }, [urlString, opts.publishEndpoint, opts.standalonePublishDiscardEndpoint]);

  const publishDiscardRequest = useApi<any>({
    method: "POST",
    endpoint: publishDiscardEndpoint.publish,
    initialData: null,
    onSuccess: (resource) => {
      if (resource) {
        notifications.spawn({
          title: "Handling fullført"
        });

        setPublishDiscardHash(createId());
        form.refresh();

        if (typeof opts.onPublishDiscard === "function") {
          opts.onPublishDiscard(resource);
        }
      }
    }
  });

  const handlePublish = useCallback(async () => {
    if (opts.publishEndpoint && !isRevision) {
      if (window.confirm("Er du sikker på at du vil publisere endringene?")) {
        await publishDiscardRequest.fetch();
      }
    }
  }, [opts.publishEndpoint, publishDiscardRequest]);

  const handleDiscard = useCallback(async () => {
    if (opts.discardEndpoint && !isRevision) {
      if (window.confirm("Er du sikker på at du vil forkaste endringene?")) {
        await publishDiscardRequest.fetch({
          method: "POST",
          endpoint: publishDiscardEndpoint.discard
        });
      }
    }
  }, [urlString, publishDiscardRequest, opts.discardEndpoint]);

  const handleSubmit = useCallback(async () => {
    if (!opts.publishEndpoint && !isRevision) {
      await form.submit();
    }
  }, [urlString, form, opts.publishEndpoint]);

  const handleDelete = useCallback(async () => {
    if (!isRevision) {
      setExternalLoading(true);

      const dependents = !opts.skipDependentsCheck
        ? await api<DependencyMap[]>({
            endpoint: checkDependenciesEndpoint,
            queryParams: {
              resources: form.data.draftFor || form.data._id
            }
          })
        : [];

      setExternalLoading(false);

      if (
        Array.isArray(dependents) &&
        dependents.some((d) => d.hasDependents)
      ) {
        spawnModal(
          <DependentsModal dependents={dependents} onNav={history.push} />
        );
      } else {
        if (window.confirm("Er du sikker på at du vil slette?")) {
          await deleteRequest.fetch();
        }
      }
    }
  }, [deleteRequest]);

  const handleRollback = useCallback(async () => {
    if (isRevision) {
      if (
        window.confirm(
          "Er du sikker på at du vil revertere til denne versjonen?"
        )
      ) {
        await rollbackRequest.fetch();
      }
    }
  }, [rollbackRequest]);

  const copyPaste = useCopyPaste({
    pathsToCopy: opts.pathsToCopy || [],
    ignoreKeys: ["_id", "__v"],
    bypassIgnoreKeys: opts.bypassIgnoreKeys,
    onPaste: (data) => {
      if (!isRevision && opts.pathsToCopy && opts.pathsToCopy.length > 0) {
        if (typeof opts.onPaste === "function") {
          data = opts.onPaste(data as T);
        }

        form.setData({
          data: {
            ...form.data,
            ...data
          },
          submit: !isNew
        });
      }
    }
  });

  useHotkeys("p", () => {
    handlePublish();
  });

  useHotkeys("u", () => {
    handleDiscard();
  });

  useHotkeys("l", () => {
    handleSubmit();
  });

  useHotkeys("ctrl+shift+d, command+shift+d", () => {
    handleDelete();
  });

  useHotkeys(
    "c",
    () => {
      if (opts.pathsToCopy && opts.pathsToCopy.length > 0) {
        copyPaste.copy(form.data);
      }
    },
    {},
    [form.data]
  );

  useHotkeys("v", () => {
    copyPaste.paste();
  });

  const handleUnload = useCallback(
    (e: BeforeUnloadEvent) => {
      if (form.submitting || form.hasMadeChanges) {
        e.preventDefault();
        e.returnValue = "";
      }
    },
    [form.submitting, form.hasMadeChanges]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [handleUnload]);

  const identifier = createIdentifier(form.data, opts.useDraftId);
  const loading =
    externalLoading ||
    form.submitting ||
    deleteRequest.loading ||
    publishDiscardRequest.loading;

  const dashboardUrl = useMemo(() => {
    if (opts.skipId) {
      return opts.baseDashboardUrl;
    }

    return `${opts.baseDashboardUrl}/${identifier}`;
  }, [opts.baseDashboardUrl, identifier, opts.skipId]);

  return {
    dashboardUrl,
    isNew,
    isRevision,
    revision: revision || opts.revision,
    loading,
    form,
    publishDiscardHash,
    experiment: experiment?.experiment,
    experimentVariant: experiment?.experimentVariant,
    delete: handleDelete,
    publish: handlePublish,
    discard: handleDiscard,
    rollback: handleRollback,
    setLoading: setExternalLoading
  };
}

function createIdentifier(data: IdImplementer, useDraft?: boolean): string {
  if (!data._id) {
    return "opprett";
  }

  if (useDraft && data.draftFor) {
    return data.draftFor;
  }

  return data._id;
}

export default usePublisher;
