import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import * as qs from "qs";

function useQueryParams<T>(): T {
  const location = useLocation();

  const data = useMemo(() => {
    return qs.parse(location.search, {
      ignoreQueryPrefix: true
    }) as T;
  }, [location.search]);

  return data;
}

export default useQueryParams;
