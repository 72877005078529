import React from "react";
import { Switch, Route } from "react-router";
import SettingsNavigation from "./Main";
import Templates from "./Templates/Templates";
import Template from "./Templates/Template";
import TemplateOverview from "./Templates/TemplateOverview";
import TemplateUsage from "./Templates/TemplateUsage";
import MembersOverview from "./Members/MembersOverview";
import Site from "./Site/Site";
import ShippingNavigation from "./Shipping/ShippingOverview";
import ShippingClasses from "./Shipping/ShippingClasses";
import ShippingClass from "./Shipping/ShippingClass";
import ShippingZones from "./Shipping/ShippingZones";
import ShippingZone from "./Shipping/ShippingZone";
import User from "./User/UserOverview";
import ClientCredentials from "./ClientCredentials/ClientCredentials";
import ClientCredential from "./ClientCredentials/ClientCredential";
import PaymentProviders from "./PaymentProviders/PaymentProviders";
import Taxes from "./Taxes/Taxes";
import B2B from "./B2B/B2B";
import Domains from "./Domains/Domains";
import Activity from "./Activity/Activity";
import Redirects from "./Redirects/Redirects";
import PartialPayments from "./PartialPayments/PartialPayments";
import PaymentPlan from "./PartialPayments/PaymentPlan";
import Booking from "./Booking/Booking";
import Departments from "./Departments/Departments";
import Department from "./Departments/Department";
import Subscription from "./Subscription/Subscription";
import OrderReceipt from "./EmailTexts/OrderReceipt";
import EmailsOverview from "./EmailTexts/EmailTextsOverview";
import CookiesOverview from "./Cookies/CookiesOverview";
import DefaultCookies from "./Cookies/DefaultCookies";
import CookieMeta from "./Cookies/CookieMeta";
import CustomCookies from "./Cookies/CustomCookies";
import EmailTemplatesOverview from "./EmailTemplates/EmailTemplatesOverview";
import Catalogs from "./Catalogs/Catalogs";

const SettingsRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/innstillinger" component={SettingsNavigation} />
      <Route exact path="/innstillinger/nettsted" component={Site} />
      <Route exact path="/innstillinger/maler" component={TemplateOverview} />
      <Route exact path="/innstillinger/maler/bruk" component={TemplateUsage} />
      <Route exact path="/innstillinger/maler/liste" component={Templates} />
      <Route
        exact
        path="/innstillinger/maler/liste/:template"
        component={Template}
      />
      <Route path="/innstillinger/b2b" component={B2B} />
      <Route path="/innstillinger/brukere" component={MembersOverview} />
      <Route exact path="/innstillinger/domener" component={Domains} />
      <Route exact path="/innstillinger/avdelinger" component={Departments} />
      <Route
        exact
        path="/innstillinger/avdelinger/:department"
        component={Department}
      />
      <Route
        exact
        path="/innstillinger/tilgangsnokler"
        component={ClientCredentials}
      />
      <Route
        exact
        path="/innstillinger/tilgangsnokler/:clientCredential"
        component={ClientCredential}
      />
      <Route exact path="/innstillinger/frakt" component={ShippingNavigation} />
      <Route
        exact
        path="/innstillinger/frakt/klasser"
        component={ShippingClasses}
      />
      <Route
        exact
        path="/innstillinger/frakt/klasser/:shippingClass"
        component={ShippingClass}
      />
      <Route
        exact
        path="/innstillinger/frakt/soner"
        component={ShippingZones}
      />
      <Route
        exact
        path="/innstillinger/frakt/soner/:shippingZone"
        component={ShippingZone}
      />
      <Route exact path="/innstillinger/avgifter" component={Taxes} />
      <Route
        exact
        path="/innstillinger/betalingslosninger"
        component={PaymentProviders}
      />
      <Route exact path="/innstillinger/bruker" component={User} />
      <Route exact path="/innstillinger/aktivitetslogg" component={Activity} />
      <Route
        exact
        path="/innstillinger/informasjonskapsler"
        component={CookiesOverview}
      />
      <Route
        exact
        path="/innstillinger/informasjonskapsler/innebygde"
        component={DefaultCookies}
      />
      <Route
        exact
        path="/innstillinger/informasjonskapsler/konfigurasjon"
        component={CookieMeta}
      />
      <Route
        exact
        path="/innstillinger/informasjonskapsler/egendefinerte"
        component={CustomCookies}
      />
      <Route exact path="/innstillinger/redirects" component={Redirects} />
      <Route exact path="/innstillinger/booking" component={Booking} />
      <Route
        exact
        path="/innstillinger/delbetaling"
        component={PartialPayments}
      />
      <Route
        exact
        path="/innstillinger/delbetaling/:paymentPlan"
        component={PaymentPlan}
      />
      <Route
        exact
        path="/innstillinger/epost-tekster"
        component={EmailsOverview}
      />
      <Route
        exact
        path="/innstillinger/epost-tekster/ordrekvittering"
        component={OrderReceipt}
      />
      <Route
        exact
        path="/innstillinger/epostmaler"
        component={EmailTemplatesOverview}
      />
      <Route exact path="/innstillinger/abonnement" component={Subscription} />
      <Route path="/innstillinger/kataloger" component={Catalogs} />
    </Switch>
  );
};

export default SettingsRoutes;
