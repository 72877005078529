import { AppComponent } from "../types/apiResponses";
import { useSearch } from "./useApi";
import useApiUrl from "./useApiUrl";

interface UseAppComponentsInterface {
  components: AppComponent[];
  loading: boolean;
}

function useAppComponents(): UseAppComponentsInterface {
  const url = useApiUrl(["app-components"]);

  const fetch = useSearch<AppComponent>({
    endpoint: url,
    fetchOnMount: true
  });

  return {
    components: fetch.results,
    loading: fetch.loading
  };
}

export default useAppComponents;
