import React, { useMemo } from "react";
import * as jsondiffpatch from "jsondiffpatch";
import Block from "../../style-guide/Block/Block";
import Text from "../../style-guide/Text/Text";
import Section from "../../style-guide/Section/Section";
import useRevision from "../../hooks/useRevision";

import "jsondiffpatch/dist/formatters-styles/html.css";

interface Props {
  revisionId: string;
  original?: any;
}

const Diff: React.FC<Props> = ({ revisionId, original }) => {
  const revision = useRevision(revisionId);

  const diff = useMemo(() => {
    if (!revision.revision) {
      return undefined;
    }

    const diff = revision.revision.diff;

    delete diff.modified;
    delete diff.modifiedDate;

    return diff;
  }, [revision.revision]);

  return (
    <Block>
      <Section hugTop>
        <Text variant="title">Diff</Text>
      </Section>
      {diff && (
        <div
          dangerouslySetInnerHTML={{
            __html: jsondiffpatch.formatters.html.format(diff, original || {})
          }}
        />
      )}
    </Block>
  );
};

export default Diff;
