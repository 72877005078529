import React, { ReactNode, useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import styled from "styled-components";
import Card from "../../style-guide/Card/Card";
import { InlineActionButton } from "../../style-guide/InlineActions/InlineActions";

interface Item {
  linkComponent: ReactNode;
  hidden?: boolean;
}

interface Props {
  items: Item[];
}

const ExpandableList: React.FC<Props> = ({ items, children }) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    function handleClick() {
      setExpanded(false);
    }

    if (expanded) {
      document.addEventListener("click", handleClick);
    } else {
      document.removeEventListener("click", handleClick);
    }

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [expanded]);

  return (
    <>
      <Wrapper>
        <InlineActionButton onClick={() => setExpanded(!expanded)}>
          <FaChevronDown />
          {children}
        </InlineActionButton>
        {expanded && (
          <ListWrapper>
            <Card closer>
              <List>
                {items
                  .filter((i) => !i.hidden)
                  .map((i, k) => (
                    <li key={k}>{i.linkComponent}</li>
                  ))}
              </List>
            </Card>
          </ListWrapper>
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const ListWrapper = styled.div`
  position: absolute;
  z-index: 1;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.xs};
  margin: 0;
  padding: ${(props) =>
    `${props.theme.spacing.xs} calc(${props.theme.spacing.medium} * 0.5)`};

  > * {
    display: block;
    min-width: 12em;
    font-weight: 600;
  }

  li a {
    display: block;
  }
`;

export default ExpandableList;
