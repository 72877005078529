import React, { ReactNode } from "react";
import styled from "styled-components";
import { FaRegSadTear } from "react-icons/fa";
import Text from "../Text/Text";
import Fader from "../Fader/Fader";
import Section from "../Section/Section";

const Wrapper = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacing.large};
  align-items: center;
  justify-content: center;
  max-width: 54rem;
  margin: 0 auto;
`;

const Content = styled.div`
  flex: 1;
`;

interface IconProps {
  smaller?: boolean;
}

const Icon = styled.div<IconProps>`
  flex: ${(props) => (props.smaller ? 0.33 : 1)};
  font-size: ${(props) => (props.smaller ? "6rem" : "14rem")};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.borderDim};
`;

interface Props {
  title: ReactNode;
  text?: ReactNode;
  action?: ReactNode;
  icon?: ReactNode;
  smaller?: boolean;
  hideIcon?: boolean;
}

const EmptyState: React.FC<Props> = ({
  title,
  text,
  action,
  icon,
  smaller,
  hideIcon
}) => {
  return (
    <Fader>
      <Wrapper>
        <Content>
          <Text variant="display1" gutterBottom>
            {title}
          </Text>
          {text && <Text variant="body3">{text}</Text>}
          {action && <Section hugBottom>{action}</Section>}
        </Content>
        {!hideIcon && <Icon smaller={smaller}>{icon || <FaRegSadTear />}</Icon>}
      </Wrapper>
    </Fader>
  );
};

export default EmptyState;
