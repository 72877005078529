import React from "react";
import { Switch, Route } from "react-router";
import Customer from "./Customer";
import Customers from "./Customers";

const CustomersRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/butikk/kunder" component={Customers} />
      <Route
        path="/butikk/kunder/:customer"
        render={(props) => (
          <Customer key={props.match.params.customer} {...props} />
        )}
      />
    </Switch>
  );
};

export default CustomersRoutes;
