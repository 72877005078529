import React, { useCallback, useState } from "react";
import { AiOutlineCloudDownload, AiOutlineCloudUpload } from "react-icons/ai";
import { FaCookieBite } from "react-icons/fa";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { CookieDefinition } from "@ludens-reklame/rubics-sdk";
import api, { Service } from "../../../util/api";
import localize from "../../../util/localize";
import useNotifications from "../../../hooks/useNotifications";
import { consentCategory } from "../../../constants/localizations";
import CrudModal, {
  createCrudModalField
} from "../../../components/CrudModal/CrudModal";
import Tabler from "../../../components/Tabler/Tabler";
import Crumb from "../../../components/Crumb/Crumb";
import Text from "../../../style-guide/Text/Text";
import { Button, ButtonList } from "../../../style-guide/Button/Button";
import Ruler from "../../../style-guide/Ruler/Ruler";

const CustomCookies: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataHash, setDataHash] = useState<string>("");
  const { spawnModal } = useModal();
  const notifications = useNotifications();

  const exportCsv = useCallback(
    async (example?: boolean) => {
      notifications.spawn({
        title: "Eksporterer cookies…",
        icon: <AiOutlineCloudDownload />
      });

      const response = await api<Response>({
        endpoint: example ? "cookies/export-example" : "cookies/export",
        noJSON: true
      });

      notifications.spawn({ title: "Cookies eksportert" });

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
      URL.revokeObjectURL(url);
    },
    [notifications]
  );

  const uploadModal = useCallback(() => {
    spawnModal(
      <CrudModal
        title="Importer cookies"
        initialData={{}}
        onSubmit={async (data) => {
          if (!loading) {
            setLoading(true);
            notifications.spawn({
              title: "Importerer cookies…",
              icon: <AiOutlineCloudUpload />
            });

            try {
              await api({
                endpoint: "cookies/import",
                method: "POST",
                multipart: true,
                body: data
              });

              setDataHash(Date.now().toString());
              notifications.spawn({ title: "Cookies importert" });
            } catch (error) {
              console.error(error);
              notifications.spawn({
                title: "Feil i importering",
                type: "error"
              });
            }

            setLoading(false);
          }
        }}
        submitLabel="Importer"
        fields={[
          {
            key: "description",
            render: () => (
              <>
                <Text gutterBottom>
                  For å importere informasjonskapsler i Rubics, må du laste opp
                  et <strong>kommaseparert</strong> CSV-ark strukturert som
                  eksempelet du kan laste ned her:
                </Text>
                <Button type="button" outlined onClick={() => exportCsv(true)}>
                  Last ned eksempelfil
                </Button>
                <Ruler gutterTop />
              </>
            )
          },
          {
            key: "csv",
            render: (payload) =>
              createCrudModalField({
                payload,
                key: "csv",
                type: "file",
                label: "CSV-fil",
                accept: ".csv",
                required: true
              })
          }
        ]}
      />
    );
  }, [loading, notifications, exportCsv]);

  return (
    <Tabler
      key={dataHash}
      tight
      title={
        <Text element="h1" variant="display3">
          <Crumb url="/innstillinger/informasjonskapsler">
            Informasjonskapsler
          </Crumb>
          Egendefinerte
        </Text>
      }
      service={Service.Storefront}
      emptyState={{
        title: "Ingen cookies enda",
        icon: <FaCookieBite />,
        text: "Du er lovpålagt å informere brukere om bruk av cookies. Dette gjøres automatisk av Rubics for de innebygde cookiene, men du må selv legge inn cookies fra tredjeparter du bruker."
      }}
      actions={
        <ButtonList>
          <Button outlined onClick={() => exportCsv()}>
            Eksporter
          </Button>
          <Button onClick={uploadModal}>Importer</Button>
        </ButtonList>
      }
      url={["cookie-dialog/definitions"]}
      queryParams={{
        onlyCustom: true
      }}
      columns={[
        {
          Header: "Navn",
          accessor: "name"
        },
        {
          Header: "Formål",
          accessor: "purpose"
        },
        {
          Header: "Samtykkekategori",
          accessor: "consentCategory"
        },
        {
          Header: "Utløpstid",
          accessor: "expiry"
        },
        {
          Header: "Leverandør",
          accessor: "vendor"
        }
      ]}
      createRow={(cookie: CookieDefinition) => ({
        name: <pre>{cookie.name}</pre>,
        purpose: <Text variant="body3">{cookie.purpose}</Text>,
        consentCategory: (
          <Text variant="body3">
            <Text variant="body3">
              {cookie.consentCategories
                .map((c) => localize(consentCategory, c))
                .join(", ")}
            </Text>
          </Text>
        ),
        expiry: <Text variant="body3">{cookie.expiry}</Text>,
        vendor: <Text variant="body3">{cookie.vendor}</Text>
      })}
    />
  );
};

export default CustomCookies;
