import { useCallback, useRef, useState } from "react";
import throttle from "lodash.throttle";

export default function useThrottle(searchMethod: Function, limit: number) {
  const throttledFunc = useRef(
    //@ts-ignore I don't know what this does
    throttle((...args) => searchMethod(...args), limit)
  ).current;

  return throttledFunc;
}

export function useDebounce<T extends any>(
  fn: (state: T) => any,
  wait = 500,
): (state: T) => any {
  const waitTimer = useRef<number>(-1);

  return useCallback(
    (newState: T) => {
      if (wait > 0) {
        if (waitTimer.current !== -1) clearTimeout(waitTimer.current);
        waitTimer.current = setTimeout(() => fn(newState), wait);
      } else {
        fn(newState);
      }
    },
    [wait, fn]
  );
}