import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { AiOutlineHourglass } from "react-icons/ai";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import Text from "../../../style-guide/Text/Text";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Crumb from "../../../components/Crumb/Crumb";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import { Button, ButtonList } from "../../../style-guide/Button/Button";
import {
  InlineEditColumn,
  InlineEditHead
} from "../../../style-guide/InlineEdit/InlineEdit";
import Ruler from "../../../style-guide/Ruler/Ruler";
import CrudModal, {
  createCrudModalField
} from "../../../components/CrudModal/CrudModal";
import { useApi } from "../../../hooks/useApi";
import useNotifications from "../../../hooks/useNotifications";
import { SiteContext } from "../../../context/Site";
import { DomainConfiguration, Site } from "../../../types/apiResponses";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";
import InlineEdit from "../../../components/InlineEdit/InlineEdit";
import Status from "../../../style-guide/Status/Status";
import localize from "../../../util/localize";
import { domainValidationStatus } from "../../../constants/localizations";
import { DomainValidationStatus } from "../../../constants/api";
import Pre from "../../../style-guide/Pre/Pre";
import Label from "../../../style-guide/Inputs/Label";
import api from "../../../util/api";

const Domains: React.FC = () => {
  const siteContext = useContext(SiteContext);
  const { spawnModal } = useModal();
  const notifications = useNotifications();

  const siteFetch = useApi<Site | null>({
    endpoint: "site",
    initialData: null,
    onSuccess: (site) => {
      if (site) {
        siteContext.setData(site);
      }
    }
  });

  const createDomainRequest = useApi<null>({
    endpoint: "site/domains",
    method: "POST",
    initialData: null,
    onSuccess: () => {
      siteFetch.fetch();
      notifications.spawn({
        title: "Domene lagt til"
      });
    }
  });

  const spawnDomainModal = useCallback(() => {
    spawnModal(
      <CrudModal
        title="Legg til domene"
        initialData={{}}
        onSubmit={(data) => {
          notifications.spawn({
            title: "Legger til domene…",
            icon: <AiOutlineHourglass />
          });

          createDomainRequest.fetch({
            endpoint: "site/domains",
            method: "POST",
            body: {
              domain: data.domain
            }
          });
        }}
        fields={[
          {
            key: "domain",
            render: (payload) =>
              createCrudModalField({
                payload,
                key: "domain",
                type: "text",
                label: "Domene",
                placeholder: "www.mitt-domene.no",
                description: "Kun domenet skal legges inn her, uten https://",
                required: true
              })
          }
        ]}
      />
    );
  }, [spawnModal, createDomainRequest]);

  return (
    <>
      <Block hugTop>
        <Flex>
          <FlexKid flex={1}>
            <Text element="h1" variant="display3">
              <Crumb url="/innstillinger">Innstillinger</Crumb>
              Domener
            </Text>
          </FlexKid>
          <FlexKid>
            <Button onClick={spawnDomainModal}>Legg til domene</Button>
          </FlexKid>
        </Flex>
      </Block>
      <BusyBoy busy={createDomainRequest.loading || siteFetch.loading}>
        <Card>
          <InlineEditHead>
            <InlineEditColumn width="320px">Domene</InlineEditColumn>
            <InlineEditColumn width="230px" textAlign="center">
              Status
            </InlineEditColumn>
            <InlineEditColumn width="80px" alignRight />
          </InlineEditHead>
          {(siteContext.data.domainConfigurations || []).length > 0 ? (
            siteContext.data.domainConfigurations.map((c) => (
              <Domain
                key={c._id}
                configuration={c}
                onChange={siteFetch.fetch}
              />
            ))
          ) : (
            <>
              <Ruler />
              <Block hugTop>
                <Text variant="subheading">Ingen domener lagt til enda</Text>
              </Block>
            </>
          )}
        </Card>
      </BusyBoy>
    </>
  );
};

interface DomainProps {
  configuration: DomainConfiguration;
  onChange: () => any;
}

const Domain: React.FC<DomainProps> = ({ configuration, onChange }) => {
  const [_conf, setConf] = useState<DomainConfiguration>(configuration);
  const [tryingAgain, setTryingAgain] = useState<boolean>(false);

  const notifications = useNotifications();

  const checkStatusRequest = useApi<null | DomainConfiguration>({
    endpoint: "site/domain-status",
    method: "GET",
    queryParams: {
      domain: _conf.domain
    },
    initialData: null,
    onSuccess: (config) => {
      if (config) {
        if (config.status !== _conf.status) {
          onChange();
        }

        setConf(config);
      }
    }
  });

  useEffect(() => {
    if (!_conf.status || _conf.status === DomainValidationStatus.Pending) {
      const interval = window.setInterval(() => {
        if (!tryingAgain) {
          checkStatusRequest.fetch();
        }
      }, [10000]);

      return () => {
        window.clearInterval(interval);
      };
    }
  }, []);

  const tryAgain = useCallback(async () => {
    if (tryingAgain) {
      return;
    }

    try {
      setTryingAgain(true);

      notifications.spawn({
        title: "Prøver igjen…",
        icon: <AiOutlineHourglass />
      });

      const config = await api<DomainConfiguration>({
        endpoint: "site/domains",
        method: "POST",
        body: {
          domain: _conf.domain
        }
      });

      if ("domain" in config) {
        setConf(config);

        notifications.spawn({
          title: "Domene lagt til på nytt"
        });
      }
    } catch (error) {
    } finally {
      setTryingAgain(false);
    }
  }, [_conf, tryingAgain, notifications]);

  const content = useMemo<ReactNode>(() => {
    if (_conf.status === DomainValidationStatus.Issued) {
      return (
        <Block>
          <Text variant="title" gutterBottom>
            Ruting av domene
          </Text>
          <Text gutterBottom>
            Hos din domeneleverandør må du legge inn en A-oppføring som vist til
            høyre for å få tilgang til din nye nettside på dette domenet.
          </Text>
          <Text>
            Det kan ta litt tid før domenet blir aktivert, så smør deg med en
            dose tålmodighet.
          </Text>
          <Ruler gutterTop />
          <Label>DNS-oppføring</Label>
          <Pre>
            {_conf.domain}. A {_conf.ip1}
          </Pre>
        </Block>
      );
    } else if (
      _conf.status === DomainValidationStatus.Pending &&
      _conf.verifyName
    ) {
      return (
        <Block>
          <Text variant="title" gutterBottom>
            Verifisering av domene
          </Text>
          <Text gutterBottom>
            Hos din domeneleverandør må du legge inn en CNAME-oppføring som vist
            til nedenfor.
          </Text>
          <Text>
            Det kan ta litt tid før domenet blir verifisert, så smør deg med en
            dose tålmodighet.
          </Text>
          <Ruler gutterTop />
          <Label>DNS-oppføring</Label>
          <Pre>
            {_conf.verifyName} CNAME {_conf.verifyValue}
          </Pre>
        </Block>
      );
    }

    return null;
  }, [_conf]);

  return (
    <InlineEdit
      key={`${_conf.status}_${_conf.verifyName}`}
      headerColumns={[
        {
          width: "320px",
          node: <Text>{_conf.domain}</Text>
        },
        {
          width: "230px",
          node:
            _conf.status === DomainValidationStatus.TimedOut ? (
              <ButtonList align="stretch">
                <Button
                  outlined
                  disabled={tryingAgain}
                  onClick={() => {
                    tryAgain();
                  }}
                >
                  {tryingAgain ? "Laster…" : "Feilet, prøv igjen"}
                </Button>
              </ButtonList>
            ) : (
              <Status
                fullWidth
                variant={
                  _conf.status === DomainValidationStatus.Issued
                    ? "good"
                    : _conf.verifyName
                    ? "neutral"
                    : undefined
                }
              >
                {_conf.verifyName
                  ? localize(
                      domainValidationStatus,
                      _conf.status || DomainValidationStatus.Pending
                    )
                  : "Vennligst vent…"}
              </Status>
            )
        }
      ]}
      expanded={
        _conf.status === DomainValidationStatus.Pending && !!_conf.verifyName
      }
    >
      {content}
    </InlineEdit>
  );
};

export default Domains;
