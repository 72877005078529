import { useContext, useCallback } from "react";
import { UserContext } from "../context/User";
import { Site } from "../types/apiResponses";
import storage from "../util/storage";
import { UserState } from "../types/general";

type SiteSelectorImplementor = Pick<Site, "_id" | "name">;

export interface UseSiteSelectionInterface {
  setSite: (site: SiteSelectorImplementor) => void;
  clearSite: () => void;
}

function useSiteSelection(): UseSiteSelectionInterface {
  const user = useContext(UserContext);

  const setSite = useCallback(
    (site: SiteSelectorImplementor) => {
      const userState: UserState = {
        ...user.data,
        site: site.name,
        siteId: site._id
      };

      storage.setSerialize("user", userState);
      storage.set("site", site.name);
      user.setData(userState);
    },
    [user]
  );

  const clearSite = useCallback(() => {
    const userState: UserState = {
      ...user.data,
      site: null,
      siteId: null
    };

    storage.setSerialize("user", userState);
    storage.delete("site");
    user.setData(userState);

    window.location.href = "/velg-nettside";
  }, [user]);

  return {
    setSite,
    clearSite
  };
}

export default useSiteSelection;
