import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import moment from "moment";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineFileText,
  AiOutlineSearch
} from "react-icons/ai";
import { IoMdGlobe } from "react-icons/io";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { useSearch } from "../../hooks/useApi";
import useApiUrl from "../../hooks/useApiUrl";
import Block from "../../style-guide/Block/Block";
import Card from "../../style-guide/Card/Card";
import { Element, Elements } from "../../style-guide/Elements/Elements";
import { Table, Tr, Th, Td } from "../../style-guide/Table/Table";
import Text from "../../style-guide/Text/Text";
import { Catalog, CategoryTreeEntry, Page } from "../../types/apiResponses";
import Status from "../../style-guide/Status/Status";
import { EntityStatus } from "../../constants/api";
import localize from "../../util/localize";
import { entityStatus } from "../../constants/localizations";
import EmptyState from "../../style-guide/EmptyState/EmptyState";
import BusyBoy from "../BusyBoy/BusyBoy";
import TextInput from "../../style-guide/Inputs/TextInput";
import { useDebouncedCallback } from "use-debounce/lib";
import { ApiOpts, RequestData } from "../../util/api";
import useMultiSelect from "../../hooks/useMultiSelect";
import { Dot, Dotter } from "../../style-guide/Dotter/Dotter";
import useSite from "../../hooks/useSite";
import useParents from "../../hooks/useParents";
import { CloneParentPageModal } from "../../views/Pages/Pages";
import { PageMeta } from "../../hooks/usePageMeta";

const TableWrapper = styled.div`
  min-width: 900px;
`;

interface Props {
  pageMeta: PageMeta;
  renderView?: (pages: Page[]) => ReactNode;
  queryParams?: RequestData;
  useWindow?: boolean;
  getScrollParent?: () => HTMLElement | null;
  filter?: (page: Page) => boolean;
  hideCategories?: boolean;
}

const PagePicker: React.FC<Props> = ({
  pageMeta,
  renderView,
  queryParams,
  useWindow,
  getScrollParent,
  filter,
  hideCategories
}) => {
  const [usedQuery, setUsedQuery] = useState("");
  const [query, setQuery] = useState("");
  const [itemPath, setItemPath] = useState<CategoryTreeEntry[]>([]);
  const [primaryParent, setPrimaryParent] = useState<string | undefined>(
    undefined
  );

  const pagesUrl = useApiUrl(["pages"]);
  const categoryTreeUrl = useApiUrl(["pages", "tree"]);

  const categoryTreeSearch = useSearch<CategoryTreeEntry>({
    endpoint: categoryTreeUrl,
    queryParams: {
      removeChildless: true
    },
    fetchOnMount: true
  });

  useEffect(() => {
    setItemPath([
      {
        _id: "_top",
        title: "_top",
        parent: null,
        children: categoryTreeSearch.results
      }
    ]);
  }, [categoryTreeSearch.results]);

  const lastEntry = useMemo<CategoryTreeEntry>(
    () => itemPath[itemPath.length - 1],
    [itemPath]
  );

  const pagesSearch = useSearch<Page>({
    endpoint: pagesUrl,
    onSuccess: () => setUsedQuery(query)
  });

  useEffect(() => {
    if (lastEntry) {
      setPrimaryParent(lastEntry._id !== "_top" ? lastEntry._id : undefined);
    }
  }, [lastEntry]);

  const queryOpts = useMemo<ApiOpts>(
    () => ({
      endpoint: pagesUrl,
      queryParams: {
        ...queryParams,
        descendantOf: primaryParent,
        text: query
      }
    }),
    [queryParams, pagesUrl, primaryParent, query]
  );

  const handleSearch = useCallback(() => {
    pagesSearch.search(queryOpts);
  }, [pagesSearch.search, queryOpts]);

  const [debouncedSearch] = useDebouncedCallback(handleSearch, 500);

  useEffect(() => {
    if (pagesSearch.hasSearched) {
      debouncedSearch();
    }
  }, [query]);

  useEffect(() => {
    pagesSearch.search({
      endpoint: pagesUrl,
      queryParams: {
        ...queryParams,
        descendantOf: primaryParent,
        text: query
      }
    });
  }, [primaryParent]);

  const filteredResults = useMemo<Page[]>(() => {
    if (!filter) {
      return pagesSearch.results;
    }

    return pagesSearch.results.filter(filter);
  }, [pagesSearch.results, filter]);

  const hasCategories = categoryTreeSearch.results.length > 0;
  const categoryEntries = lastEntry?.children || [];

  return (
    <Card>
      <BusyBoy busy={pagesSearch.loading}>
        {!hideCategories && hasCategories && lastEntry && (
          <Block>
            <Text variant="subheading" gutterBottom>
              Hierarki
            </Text>
            <Elements>
              {lastEntry._id !== "_top" && (
                <Element
                  href="#"
                  icon={<AiOutlineArrowLeft />}
                  onClick={(e) => {
                    e.preventDefault();
                    setItemPath(itemPath.slice(0, -1));
                  }}
                >
                  {lastEntry.title}
                </Element>
              )}
              {categoryEntries.map((c) => (
                <Element
                  key={c._id}
                  href="#"
                  rightIcon={<AiOutlineArrowRight />}
                  onClick={(e) => {
                    e.preventDefault();
                    setItemPath([...itemPath, c]);
                  }}
                >
                  {c.title}
                </Element>
              ))}
            </Elements>
          </Block>
        )}
        <Block hugTop={!hideCategories && hasCategories && !!lastEntry}>
          <Text variant="subheading" gutterBottom>
            {pageMeta.title}
          </Text>
          <TextInput
            icon={<AiOutlineSearch />}
            placeholder={`Søk etter ${pageMeta.plural}`}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </Block>
        <InfiniteScroll
          pageStart={2}
          initialLoad={false}
          loadMore={() => {
            pagesSearch.search({
              ...queryOpts,
              paginate: true
            });
          }}
          hasMore={pagesSearch.hasMore}
          useWindow={useWindow}
          getScrollParent={getScrollParent}
        >
          {pagesSearch.hasSearched && filteredResults.length > 0 && (
            <>
              {typeof renderView === "function" ? (
                renderView(filteredResults)
              ) : (
                <PageTable
                  pages={filteredResults}
                  pageMeta={pageMeta}
                  linkable
                />
              )}
            </>
          )}
        </InfiniteScroll>
        {pagesSearch.hasSearched &&
          filteredResults.length === 0 &&
          !usedQuery && (
            <EmptyState
              title={
                lastEntry.title === "_top" && useWindow
                  ? "Opprett din første side"
                  : `Ingen ${pageMeta.plural} funnet`
              }
              text={
                lastEntry.title === "_top" ? (
                  pageMeta.description
                ) : (
                  <>
                    Vi fant dessverre {pageMeta.plural} med forelder{" "}
                    <em>{lastEntry.title}</em>.
                  </>
                )
              }
              icon={<AiOutlineFileText />}
            />
          )}
        {pagesSearch.hasSearched &&
          filteredResults.length === 0 &&
          usedQuery && (
            <EmptyState
              title={
                <>
                  Ingen treff på "<em>{usedQuery}</em>"
                </>
              }
            />
          )}
      </BusyBoy>
    </Card>
  );
};

interface PageRowProps {
  page: Page;
  pageMeta: PageMeta;
  linkable?: boolean;
  selectable?: boolean;
  onChange?: (selected: boolean) => any;
  selectedPages?: Page[];
  multipleSelect?: boolean;
  displayCatalogs?: boolean;
}

const PageRow: React.FC<PageRowProps> = ({
  page,
  pageMeta,
  linkable,
  onChange,
  selectable,
  selectedPages,
  multipleSelect,
  displayCatalogs
}) => {
  const site = useSite();
  const { spawnModal } = useModal();
  const selected = useMemo<boolean>(() => {
    if (selectable && Array.isArray(selectedPages)) {
      return selectedPages.some((p) => p._id === page._id);
    }

    return false;
  }, [page, selectable, selectedPages]);

  const parents = useParents(page);

  const crumb: ReactNode = useMemo(() => {
    if (parents.length < 1) {
      return "Ingen forelder";
    }

    return parents.map((b) => b.label).join(" > ");
  }, [parents]);

  const isInherited = useMemo<boolean>(() => {
    return site._id ? page.site !== site._id : false;
  }, [page.site, site._id]);

  return (
    <Tr>
      {selectable && (
        <Td verticalAlign="middle" tight>
          <input
            id={`page_${page._id}`}
            name="page-picker-pages"
            type={multipleSelect ? "checkbox" : "radio"}
            onChange={(e) => {
              if (onChange) {
                if (multipleSelect) {
                  onChange(e.target.checked);
                } else {
                  onChange(true);
                }
              }
            }}
            checked={selected}
          />
        </Td>
      )}
      <Td tight>
        <Text variant="body2">
          {linkable ? (
            <Link
              to={`/innhold/${pageMeta.plural}/${page._id}`}
              onClick={(e) => {
                if (isInherited) {
                  e.preventDefault();
                  spawnModal(
                    <CloneParentPageModal page={page} pageMeta={pageMeta} />
                  );
                }
              }}
            >
              {page.title}
            </Link>
          ) : selectable ? (
            <label htmlFor={`page_${page._id}`}>{page.title}</label>
          ) : (
            page.title
          )}{" "}
          {isInherited && (
            <>
              {" "}
              <IoMdGlobe />
            </>
          )}
        </Text>
        <Text variant="body3">
          <Dotter>
            <Dot>{page.isCategory ? "Kategori" : "Side"}</Dot>
            {page.redirectEnabled && typeof page.redirectUrl === "string" && (
              <Dot>Omdirigires til {page.redirectUrl}</Dot>
            )}
            <Dot>{crumb}</Dot>
            {page.modified && (
              <Dot>
                <Text element="span" variant="subtitle">
                  Upubliserte endringer
                </Text>
              </Dot>
            )}
          </Dotter>
        </Text>
      </Td>
      {displayCatalogs && (
        <Td verticalAlign="middle" tight>
          <Text variant="body3">
            {page.inStandardCatalog !== false && <span>Standard</span>}
            {(page.catalogEntries || [])
              .filter((e) => typeof e.catalog !== "string")
              .map((e) => {
                const catalog = e.catalog as Catalog;

                return (
                  <span key={e._id}>
                    ,{" "}
                    {linkable ? (
                      <Link to={`/innhold/innholdskataloger/${catalog._id}`}>
                        {catalog.name}
                      </Link>
                    ) : (
                      catalog.name
                    )}
                  </span>
                );
              })}
          </Text>
        </Td>
      )}
      <Td tight verticalAlign="middle">
        <Text variant="body3">{page.author ? page.author.name : "Ingen"}</Text>
      </Td>
      <Td tight verticalAlign="middle">
        <Text variant="body3">{moment(page.modifiedDate).fromNow()}</Text>
      </Td>
      <Td align="center" verticalAlign="middle" tight>
        <Status
          variant={
            page.status === EntityStatus.Inactive ? "neutral" : undefined
          }
        >
          {localize(entityStatus, page.status || EntityStatus.Inactive)}
        </Status>
      </Td>
    </Tr>
  );
};

interface PageTableProps {
  pages: Page[];
  pageMeta: PageMeta;
  linkable?: boolean;
  onSelect?: (pages: Page[]) => any;
  multipleSelect?: boolean;
  selectedPages?: Page[];
}

export const PageTable: React.FC<PageTableProps> = ({
  pages,
  pageMeta,
  linkable,
  onSelect,
  multipleSelect,
  selectedPages
}) => {
  const site = useSite();
  const multiSelect = useMultiSelect<Page>({
    elements: pages,
    onSelect,
    multipleSelect,
    initialValue: selectedPages
  });

  const selectable = useMemo<boolean>(() => {
    return typeof onSelect === "function";
  }, [onSelect]);

  return (
    <TableWrapper>
      <Table>
        <thead>
          <Tr>
            {selectable && (
              <Th style={{ width: "1px" }}>
                {multipleSelect && (
                  <input
                    type="checkbox"
                    onChange={(e) => multiSelect.selectAll(e.target.checked)}
                  />
                )}
              </Th>
            )}
            <Th>Side</Th>
            {(site.b2bEnabled || site.departmentsEnabled) && <Th>Kataloger</Th>}
            <Th>Forfatter</Th>
            <Th>Sist endret</Th>
            <Th style={{ width: "120px" }} align="center">
              Status
            </Th>
          </Tr>
        </thead>
        <tbody>
          {pages.map((p) => (
            <PageRow
              key={p._id}
              page={p}
              pageMeta={pageMeta}
              linkable={linkable}
              selectable={selectable}
              onChange={(selected) => multiSelect.select(p, selected)}
              selectedPages={multiSelect.selectedElements}
              multipleSelect={multipleSelect}
              displayCatalogs={site.b2bEnabled || site.departmentsEnabled}
            />
          ))}
        </tbody>
      </Table>
    </TableWrapper>
  );
};

export default PagePicker;
