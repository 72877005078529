import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { AiOutlineAppstore } from "react-icons/ai";
import Fader from "../../style-guide/Fader/Fader";
import Text from "../../style-guide/Text/Text";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import Card from "../../style-guide/Card/Card";
import Block from "../../style-guide/Block/Block";
import Section from "../../style-guide/Section/Section";
import { Table, Tr, Th, Td } from "../../style-guide/Table/Table";
import { useSearch } from "../../hooks/useApi";
import { App } from "../../types/apiResponses";
import useApiUrl from "../../hooks/useApiUrl";
import InfiniteScroll from "react-infinite-scroller";
import BusyBoy from "../../components/BusyBoy/BusyBoy";
import fallbackImage from "../../util/images/empty_preview.png";
import { ButtonList, Button } from "../../style-guide/Button/Button";
import EmptyState from "../../style-guide/EmptyState/EmptyState";
import Doorman from "../../components/Doorman/Doorman";
import api from "../../util/api";
import useNotifications from "../../hooks/useNotifications";

const Logo = styled.img`
  display: block;
  width: 50px;
`;

const Apps: React.FC = () => {
  const [uninstalling, setUninstalling] = useState(false);
  const endpoint = useApiUrl(["apps", "installed"]);
  const notifications = useNotifications();
  const searchOpts = {
    endpoint: endpoint
  };

  const search = useSearch<App>(searchOpts);

  const uninstall = useCallback(
    async (app: App) => {
      if (
        window.confirm(
          `Er du sikker på at du vil avinstallere ${app.name}? Dette kan ikke angres.`
        )
      ) {
        setUninstalling(true);

        await api({
          method: "POST",
          endpoint: `apps/${app.identifier}/uninstall`
        });

        setUninstalling(false);

        notifications.spawn({ title: `${app.name} avinstallert` });
        search.search(searchOpts);
      }
    },
    [search, searchOpts]
  );

  return (
    <Fader>
      <Block hugTop>
        <Text element="h1" variant="display3">
          Apper
        </Text>
      </Block>
      <Section hugTop>
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            search.search({
              ...searchOpts,
              paginate: true
            });
          }}
          hasMore={search.hasMore}
        >
          <BusyBoy busy={search.loading || uninstalling}>
            {search.hasSearched && search.results.length > 0 ? (
              <Card>
                <Table>
                  <thead>
                    <Tr>
                      <Th>Installerte apper</Th>
                      <Doorman type="admin">
                        <Th align="right">Handlinger</Th>
                      </Doorman>
                    </Tr>
                  </thead>
                  <tbody>
                    {search.results.map((a) => (
                      <Tr key={a._id}>
                        <Td>
                          <Flex>
                            <FlexKid>
                              <Logo
                                src={a.logo || fallbackImage}
                                alt={a.name}
                              />
                            </FlexKid>
                            <FlexKid flex={1} spaceLeft>
                              <Text variant="body2">
                                <Link to={`apper/${a.identifier}`}>
                                  {a.name}
                                </Link>
                              </Text>
                              <Text variant="body3">
                                {a.description || "Mangler beskrivelse"}
                              </Text>
                            </FlexKid>
                          </Flex>
                        </Td>
                        <Doorman type="admin">
                          <Td align="right">
                            <ButtonList align="right">
                              <Button
                                type="button"
                                outlined
                                onClick={() => uninstall(a)}
                              >
                                Avinstaller
                              </Button>
                            </ButtonList>
                          </Td>
                        </Doorman>
                      </Tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            ) : (
              <EmptyState
                title="Ingen apper installert"
                icon={<AiOutlineAppstore />}
                text="Ekspander din Rubics-funksjonalitet ved å installere noen apper!"
              />
            )}
          </BusyBoy>
        </InfiniteScroll>
      </Section>
    </Fader>
  );
};

export default Apps;
