import React, { ReactNode, useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
import { useDebouncedCallback } from "use-debounce/lib";
import {
  Image,
  Product as ProductClass,
  ProductData,
  PriceType
} from "@ludens-reklame/rubics-sdk";
import { useApi } from "../../hooks/useApi";
import PreviewImage from "../../style-guide/PreviewImage/PreviewImage";
import Text from "../../style-guide/Text/Text";
import { Product } from "../../types/apiResponses";
import { Service } from "../../util/api";
import { currencyFormat } from "../../util/intl";
import usePreviewToken from "../../hooks/usePreviewToken";

const Variants = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: ${(props) => props.theme.spacing.xs};
  margin-bottom: ${(props) => props.theme.spacing.small};
`;

const Variant = styled.li<{ unavailable?: boolean }>`
  ${(props) =>
    props.unavailable &&
    css`
      opacity: 0.34;
    `}
`;

const ColorPreview = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  height: 32px;
  width: 32px;
  border-radius: 4px;
`;

const ImagePreview = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.border};
`;

const VariantValue = styled.div`
  border: 1px solid ${(props) => props.theme.colors.border};
  padding: 0 ${(props) => props.theme.spacing.xs};
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
`;

interface ProductStore {
  pageContext: {
    productData: ProductData;
  };
}

interface Props {
  product: Product;
}

const ProductPreview: React.FC<Props> = ({ product }) => {
  const token = usePreviewToken();

  const requestOpts = {
    service: Service.Storefront,
    endpoint: `products/${product._id}`
  };

  const request = useApi<ProductStore | null>({
    ...requestOpts,
    initialData: null
  });

  const [debouncedRequest] = useDebouncedCallback(request.fetch, 1000);

  const productData = useMemo<ProductData | null>(() => {
    if (request.resource) {
      return request.resource.pageContext.productData;
    }

    return null;
  }, [request.resource]);

  const primaryImageUrl = useMemo<string>(() => {
    return Image.createUrl({
      image: ProductClass.getPrimaryImage(
        productData ? productData : { images: [] }
      ),
      transforms: "w_558,h_372,c_pad"
    });
  }, [productData]);

  useEffect(() => {
    if (!request.loading && token) {
      const opts = {
        ...requestOpts,
        queryParams: {
          preview: true,
          token: token
        }
      };

      if (request.hasFetched) {
        debouncedRequest(opts);
      } else {
        request.fetch(opts);
      }
    }
  }, [token, product, debouncedRequest]);

  const pricing = useMemo<ReactNode>(() => {
    if (productData) {
      const type = productData.price.type;

      if (type === PriceType.ByAppointment) {
        return <Text variant="body2">Pris etter avtale</Text>;
      }

      const value = currencyFormat.format(productData.price.value);
      const compareCost = productData.price.compareAtValue
        ? currencyFormat.format(productData.price.compareAtValue)
        : undefined;
      const min = productData.price.minPrice
        ? currencyFormat.format(productData.price.minPrice)
        : undefined;
      const max = productData.price.maxPrice
        ? currencyFormat.format(productData.price.maxPrice)
        : undefined;

      if (min && max) {
        return (
          <Text variant="body2">
            {min} – {max}
          </Text>
        );
      }

      return (
        <>
          <Text variant="body2">
            {productData.price.prefix ? `${productData.price.prefix} ` : ""}
            {value}
            {productData.price.suffix ? ` ${productData.price.suffix}` : ""}
          </Text>
          {compareCost && (
            <Text variant="body3">
              <s>Før {compareCost}</s>
            </Text>
          )}
        </>
      );
    }

    return null;
  }, [productData]);

  const variations = useMemo<ReactNode>(() => {
    if (productData && Array.isArray(productData.variantDistribution)) {
      return productData.variantDistribution
        .filter((variant) => variant.values.some((value) => !!value.product))
        .map((variant) => (
          <Variants key={variant.name}>
            {variant.values.map((value) => {
              if (variant.type === "color") {
                return (
                  <Variant
                    key={value._id}
                    unavailable={!value.product}
                    title={`${variant.label}: ${value.value}`}
                  >
                    <ColorPreview color={value.meta} />
                  </Variant>
                );
              }

              if (variant.type === "image" || variant.type === "icon") {
                if (!value.meta) {
                  return null;
                }

                return (
                  <Variant
                    key={value._id}
                    unavailable={!value.product}
                    title={`${variant.label}: ${value.value}`}
                  >
                    <ImagePreview
                      src={Image.createUrl({
                        image: value.meta,
                        transforms: "w_64,h_64,c_pad"
                      })}
                      alt={value.value}
                    />
                  </Variant>
                );
              }

              return (
                <Variant
                  key={value._id}
                  unavailable={!value.product}
                  title={`${variant.label}: ${value.value}`}
                >
                  <VariantValue>{value.value}</VariantValue>
                </Variant>
              );
            })}
          </Variants>
        ));
    }

    return null;
  }, [productData]);

  return (
    <>
      <Text variant="subtitle" gutterBottom>
        Forhåndsvisning
      </Text>
      <PreviewImage src={primaryImageUrl} alt="Forhåndsvisning" />
      {!productData ? (
        <Text variant="body3">Laster…</Text>
      ) : (
        <>
          <Text variant="display1" gutterBottom>
            {productData.name}
          </Text>
          {productData.description && (
            <Text variant="body3" gutterBottom>
              {productData.description}
            </Text>
          )}
          {variations}
          {pricing}
        </>
      )}
    </>
  );
};

export default ProductPreview;
