import React from "react";
import styled from "styled-components";
import { useHotkeys } from "react-hotkeys-hook";
import { AiOutlineLeft } from "react-icons/ai";
import { Button, ButtonList } from "../../style-guide/Button/Button";
import Fader from "../../style-guide/Fader/Fader";

const Wrapper = styled.div``;

interface Props {
  onClose: () => any;
  disabled?: boolean;
}

const SidebarPager: React.FC<Props> = ({ onClose, disabled, children }) => {
  useHotkeys("ctrl+shift+left, command+shift+left", () => {
    onClose();
  });

  return (
    <Fader variant="left">
      <Wrapper>
        {!disabled && (
          <ButtonList>
            <Button type="button" outlined alternate onClick={onClose}>
              <AiOutlineLeft /> Tilbake
            </Button>
          </ButtonList>
        )}
        {children}
      </Wrapper>
    </Fader>
  );
};

export default SidebarPager;
