import * as React from "react";
import { useHistory } from "react-router-dom";
import { defaultActive, iterateKeys, IUsePowerSearchRet, usePowerSearch } from "./powersearch.utils";
import { PowerSearchWrapper } from "./powersearch.styles";

export const powerSearchEditKey = 'Control';

interface IPowersearchContextProps extends Pick<IUsePowerSearchRet, "search" | "results" | "loading"> {
  focused: number;
  setFocused: React.Dispatch<React.SetStateAction<number>>;
  onSelect: (e: KeyboardEvent | React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => void;
}

interface IPowersearchProviderProps {
  active: boolean;
  onClose: () => void;
}

export const PowersearchContext = React.createContext<IPowersearchContextProps>({
  focused: -1,
  setFocused: () => {
  },
  loading: false,
  results: [],
  search: () => {
  },
  onSelect: () => {
  }
});

export function PowersearchProvider({ active, onClose, children }: React.PropsWithChildren<IPowersearchProviderProps>) {
  const history = useHistory();
  const { search, results, loading } = usePowerSearch();
  const [focused, setFocused] = React.useState<number>(defaultActive);
  const loadingRef = React.useRef<boolean>(false);
  const itemsLength = results.length;

  const onSelect = React.useCallback((e: KeyboardEvent | React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    e.preventDefault();
    const selected = results[index];
    if (selected) {
      const to = e.ctrlKey && selected.editPath ? selected.editPath : selected.path;
      if (e.shiftKey) {
        window.open(window.location.origin + to, '_blank');
      } else {
        history.push(to);
      }
    }
    onClose();
  }, [history, results, onClose]);

  const handleIterate = React.useCallback((e: KeyboardEvent) => {
    const classes = document.getElementsByClassName("powersearch-item") as HTMLCollectionOf<HTMLLIElement>;
    setFocused(s => {
      const newV = e.code === "ArrowUp" || (e.shiftKey && e.code === "Tab") ? --s : ++s;
      const newState = newV < 0 ? itemsLength - 1 : newV >= itemsLength ? 0 : newV;
      if (classes[newState]) {
        classes[newState].focus();
      }
      return newState;
    });
    e.preventDefault();
  }, [itemsLength]);

  React.useEffect(() => {
    const keydown = (e: KeyboardEvent) => {
      if (!active) return;
      if (e.code === "Enter") {
        onSelect(e, focused === -1 ? 0 : focused);
      } else if (iterateKeys.includes(e.code)) {
        if(itemsLength > 0) {
          e.preventDefault();
          handleIterate(e);
        } else if(e.code === 'Tab') {
          onClose();
        }
      }
    };
    document.addEventListener("keydown", keydown);
    return () => {
      document.removeEventListener("keydown", keydown);
    };
  }, [active, onSelect, focused, handleIterate, itemsLength, onClose]);

  React.useEffect(() => {
    if (loadingRef.current) {
      setFocused(defaultActive);
    }
    loadingRef.current = loading;
  }, [loading]);

  return (
    <PowersearchContext.Provider value={{ search, results, loading, focused, setFocused, onSelect }}>
      <PowerSearchWrapper>
        {children}
      </PowerSearchWrapper>
    </PowersearchContext.Provider>
  );
}
