import React, { useEffect, useMemo } from "react";
import { Prompt, Route, RouteComponentProps, Switch } from "react-router-dom";
import { AiOutlineLayout, AiOutlineProfile } from "react-icons/ai";
import { FaBoxes } from "react-icons/fa";
import { Catalog as ICatalog } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import Fader from "../../style-guide/Fader/Fader";
import Block from "../../style-guide/Block/Block";
import usePublisher from "../../hooks/usePublisher";
import Section from "../../style-guide/Section/Section";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import Crumb from "../../components/Crumb/Crumb";
import Form from "../../style-guide/Inputs/Form";
import ActionBar from "../../components/ActionBar/ActionBar";
import PublisherHelpers from "../../util/PublisherHelpers";
import {
  InlineActionLink,
  InlineActions
} from "../../style-guide/InlineActions/InlineActions";
import General from "./SubViews/General";
import { LEAVE_PAGE_MESSAGE } from "../../constants/general";
import Products from "./SubViews/Products";
import Content from "./SubViews/Content";
import { CatalogType } from "../../constants/api";
import cleanPopulatedIds from "../../util/cleanPopulatedIds";
import useSite from "../../hooks/useSite";

export interface CreateableCatalog extends ICatalog {
  addContentFrom?: string;
}

interface Props
  extends RouteComponentProps<{
    catalog: string;
  }> {
  type: CatalogType;
}

const Catalog: React.FC<Props> = ({ match, type }) => {
  const site = useSite();
  const meta = useMemo(() => {
    switch (type) {
      case CatalogType.Content:
        return {
          title: "Innholdskataloger",
          singular: "innholdskatalog",
          plural: "innholdskataloger",
          section: "innhold"
        };

      default:
        return {
          title: "Produktkataloger",
          singular: "produktkatalog",
          plural: "produktkataloger",
          section: "butikk"
        };
    }
  }, [type]);

  const catalogId = match.params.catalog;
  const breadcrumbs = useBreadcrumbs();
  const publisher = usePublisher<CreateableCatalog>({
    id: catalogId,
    baseUrl: ["catalogs"],
    baseDashboardUrl: `/${meta.section}/${meta.plural}`,
    initialData: { type },
    skipDependentsCheck: true,
    cleanField: cleanPopulatedIds(["addContentFrom"])
  });

  useEffect(() => {
    breadcrumbs.swapEntry(catalogId, publisher.form.data.name || catalogId);
  }, [breadcrumbs, publisher.form.data, catalogId]);

  const inherited = !publisher.isNew && publisher.form.data.site !== site._id;

  return (
    <Form onSubmit={publisher.form.submit}>
      <Prompt
        when={publisher.form.hasMadeChanges}
        message={(location) => {
          if (location.pathname.includes(catalogId)) {
            return true;
          }

          return LEAVE_PAGE_MESSAGE;
        }}
      />
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url={`/${meta.section}/${meta.plural}`}>{meta.title}</Crumb>
            {PublisherHelpers.getResourceTitle(
              publisher,
              "name",
              `Ny ${meta.singular}`
            )}
          </Text>
        </Block>
        {!publisher.isNew && (
          <Block hugTop hugBottom>
            <InlineActions>
              {!inherited && (
                <InlineActionLink exact to={publisher.dashboardUrl}>
                  <AiOutlineProfile /> Generelt
                </InlineActionLink>
              )}
              {type === CatalogType.Product && (
                <InlineActionLink
                  exact
                  to={`${publisher.dashboardUrl}/produkter`}
                >
                  <FaBoxes /> Produkter
                </InlineActionLink>
              )}
              {type === CatalogType.Content && (
                <InlineActionLink
                  exact
                  to={`${publisher.dashboardUrl}/innhold`}
                >
                  <AiOutlineLayout /> Innhold
                </InlineActionLink>
              )}
            </InlineActions>
          </Block>
        )}
        <Section hugTop>
          <Switch>
            <Route
              exact
              path={publisher.dashboardUrl}
              render={() => <General publisher={publisher} type={type} />}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/produkter`}
              render={() => (
                <Products publisher={publisher} inherited={inherited} />
              )}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/innhold`}
              render={() => <Content publisher={publisher} />}
            />
          </Switch>
        </Section>
      </Fader>
      {!inherited && <ActionBar publisher={publisher} destroy />}
    </Form>
  );
};

export default Catalog;
