import React, { useState } from "react";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import Fader from "../../../style-guide/Fader/Fader";
import Section from "../../../style-guide/Section/Section";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import createPublisherInputField from "../../../util/createPublisherInputField";
import useSite from "../../../hooks/useSite";
import { DEFAULT_TAX_CLASS_NAME } from "../../../constants/general";
import { CatalogType } from "../../../constants/api";
import { CreateableCatalog } from "../Catalog";
import createInputField from "../../../util/createInputField";

interface Props {
  publisher: UsePublisherInterface<CreateableCatalog>;
  type: CatalogType;
}

const General: React.FC<Props> = ({ publisher, type }) => {
  const site = useSite();
  const [addContentType, setAddContentType] = useState<string>("");

  return (
    <Fader>
      <Card>
        <Block>
          <Section hugTop>
            <Text element="h2" variant="title">
              Generelt
            </Text>
          </Section>
          {createPublisherInputField(publisher, {
            path: "name",
            label: "Navn",
            type: "text",
            required: true
          })}
          {createPublisherInputField(publisher, {
            path: "description",
            label: "Beskrivelse",
            type: "textarea"
          })}
          {type === CatalogType.Product &&
            createPublisherInputField(publisher, {
              path: "defaultTaxClass",
              label: "Avgiftsklasse",
              type: "select",
              nonNullable: true,
              nullFalsy: true,
              options: (site.taxClasses || []).map((c) => ({
                label: c.name === DEFAULT_TAX_CLASS_NAME ? "Standard" : c.name,
                value: c.name === DEFAULT_TAX_CLASS_NAME ? "" : c._id
              }))
            })}
          {publisher.isNew && (
            <>
              {createInputField({
                key: "_addContentFromController",
                label: "Kopier innhold fra",
                type: "radio",
                value: addContentType,
                onChange: (value) => {
                  setAddContentType(value);

                  if (value === "standard") {
                    publisher.form.setField({
                      path: "addContentFrom",
                      value: "standard"
                    });
                  } else {
                    publisher.form.setField({
                      path: "addContentFrom",
                      value: ""
                    });
                  }
                },
                options: [
                  {
                    label: "Ingen",
                    value: ""
                  },
                  {
                    label: "Standardkatalog",
                    value: "standard"
                  },
                  {
                    label: "Egendefinert katalog",
                    value: "custom"
                  }
                ]
              })}
              {addContentType === "custom" &&
                createPublisherInputField(publisher, {
                  path: "addContentFrom",
                  label: "Velg katalog å kopiere fra",
                  type: "ref",
                  required: true,
                  value:
                    publisher.form.data.addContentFrom !== "standard"
                      ? publisher.form.getValue("addContentFrom.name")
                      : "",
                  ref: {
                    url: ["catalogs"],
                    titleKey: "name",
                    searchKey: "name",
                    query: {
                      type
                    }
                  }
                })}
            </>
          )}
        </Block>
      </Card>
    </Fader>
  );
};

export default General;
