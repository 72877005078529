import React, { useContext } from "react";
import { Prompt, RouteComponentProps } from "react-router";
import { SiteThemeContext } from "../../context/Theme";
import useApiUrl from "../../hooks/useApiUrl";
import usePublisher from "../../hooks/usePublisher";
import BusyBoy from "../../components/BusyBoy/BusyBoy";
import Putter from "../../components/Putter/Putter";
import ActionBar from "../../components/ActionBar/ActionBar";
import Card from "../../style-guide/Card/Card";
import Form from "../../style-guide/Inputs/Form";
import Block from "../../style-guide/Block/Block";
import Section from "../../style-guide/Section/Section";
import { LEAVE_PAGE_MESSAGE } from "../../constants/general";
import Fader from "../../style-guide/Fader/Fader";
import Text from "../../style-guide/Text/Text";
import Crumb from "../../components/Crumb/Crumb";

interface Params {
  dataSet: string;
  resource: string;
}

const DataSetEdit: React.FC<RouteComponentProps<Params>> = ({ match }) => {
  const url = useApiUrl(["data-sets"]);
  const theme = useContext(SiteThemeContext).data;

  const dataSet =
    theme && Array.isArray(theme.dataSets)
      ? theme.dataSets.find((d) => d.name === match.params.dataSet)
      : undefined;

  const publisher = usePublisher<any>({
    id: match.params.resource,
    baseUrl: ["data-sets", match.params.dataSet],
    baseDashboardUrl: "/innhold/data-sets/" + match.params.dataSet
  });

  if (!dataSet) {
    return null;
  }

  return (
    <Form onSubmit={publisher.form.submit}>
      <Prompt
        when={publisher.form.hasMadeChanges}
        message={() => LEAVE_PAGE_MESSAGE}
      />
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url={`/innhold/data-sets/${dataSet.name}`}>
              {dataSet.label}
            </Crumb>
            Rediger
          </Text>
        </Block>
        <BusyBoy
          busy={publisher.form.loadingPrefill || publisher.form.submitting}
        >
          <Card>
            <Block>
              <Section hugTop>
                <Text element="h2" variant="title">
                  Generelt
                </Text>
              </Section>
              <Putter
                props={dataSet.props}
                method="PATCH"
                endpoint={url}
                pathPrefix="props"
                previewPathPrefix="propsPopulated"
                dontAutoSave
                form={publisher.form}
              />
            </Block>
          </Card>
        </BusyBoy>
      </Fader>
      <ActionBar publisher={publisher} destroy />
    </Form>
  );
};

export default DataSetEdit;
