import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Text from "../../../style-guide/Text/Text";
import Block from "../../../style-guide/Block/Block";
import Crumb from "../../../components/Crumb/Crumb";
import { useApi } from "../../../hooks/useApi";
import {
  Site,
  ShippingZone as IShippingZone
} from "../../../types/apiResponses";
import useApiUrl from "../../../hooks/useApiUrl";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";
import Card from "../../../style-guide/Card/Card";
import EmptyState from "../../../style-guide/EmptyState/EmptyState";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import { ButtonLink } from "../../../style-guide/Button/Button";
import Fader from "../../../style-guide/Fader/Fader";
import { Table, Tr, Th, Td } from "../../../style-guide/Table/Table";
import { AiOutlineGlobal } from "react-icons/ai";

const ShippingZones: React.FC = () => {
  const url = useApiUrl(["site"]);
  const fetch = useApi<Site | null>({
    endpoint: url,
    initialData: null,
    fetchOnMount: true
  });

  const zones = useMemo<IShippingZone[]>(() => {
    return fetch.resource?.shippingZones || [];
  }, [fetch.resource]);

  return (
    <Fader>
      <Block hugTop>
        <Flex>
          <FlexKid flex={1}>
            <Text element="h1" variant="display3">
              <Crumb url="/innstillinger/frakt">Frakt</Crumb>
              Fraktsoner
            </Text>
          </FlexKid>
          <FlexKid>
            <ButtonLink to="soner/opprett">Opprett fraktsone</ButtonLink>
          </FlexKid>
        </Flex>
      </Block>
      <BusyBoy busy={fetch.loading}>
        {fetch.hasFetched && zones.length > 0 ? (
          <Card>
            <Table>
              <thead>
                <Tr>
                  <Th>Navn</Th>
                  <Th>Metoder</Th>
                </Tr>
              </thead>
              <tbody>
                {zones.map((z) => (
                  <Tr key={z._id}>
                    <Td>
                      <Text variant="body2">
                        <Link to={`soner/${z._id}`}>{z.name}</Link>
                      </Text>
                    </Td>
                    <Td>
                      <Text variant="body3">
                        {z.methods.length > 0
                          ? z.methods.map((m) => m.name).join(", ")
                          : "Mangler metoder"}
                      </Text>
                    </Td>
                  </Tr>
                ))}
              </tbody>
            </Table>
          </Card>
        ) : (
          <EmptyState
            title="Opprett din første fraktsone"
            icon={<AiOutlineGlobal />}
            text="Bruk fraktsoner for å bestemme hvilke frakttyper som er tilgjengelige i forskjellige geografiske områder."
            action={
              <ButtonLink to="soner/opprett">Opprett fraktsone</ButtonLink>
            }
          />
        )}
      </BusyBoy>
    </Fader>
  );
};

export default ShippingZones;
