import React, { useCallback, useMemo, useState } from "react";
import moment from "moment";
import { AiOutlineDelete } from "react-icons/ai";
import { FaRegSadCry } from "react-icons/fa";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { UsePublisherInterface } from "../../../../hooks/usePublisher";
import {
  BookingService,
  BookingServiceEvent,
  BookingAvailability,
  GenericResponse
} from "../../../../types/apiResponses";
import Fader from "../../../../style-guide/Fader/Fader";
import { Flex, FlexKid } from "../../../../style-guide/Flex/Flex";
import Section from "../../../../style-guide/Section/Section";
import Card from "../../../../style-guide/Card/Card";
import Block from "../../../../style-guide/Block/Block";
import Text from "../../../../style-guide/Text/Text";
import createPublisherInputField from "../../../../util/createPublisherInputField";
import {
  bookingDurationUnit,
  bookingServiceType,
  days
} from "../../../../constants/localizations";
import {
  InlineEditColumn,
  InlineEditHead
} from "../../../../style-guide/InlineEdit/InlineEdit";
import Ruler from "../../../../style-guide/Ruler/Ruler";
import { Button, ButtonList } from "../../../../style-guide/Button/Button";
import createId from "../../../../util/createId";
import InlineEdit from "../../../../components/InlineEdit/InlineEdit";
import createInputField from "../../../../util/createInputField";
import { BookingServiceType } from "../../../../constants/api";
import { Table, Tr, Th, Td } from "../../../../style-guide/Table/Table";
import CrudModal, {
  createCrudModalField
} from "../../../../components/CrudModal/CrudModal";
import OfflineLister from "../../../../components/OfflineLister/OfflineLister";
import Field from "../../../../style-guide/Inputs/Field";
import Label from "../../../../style-guide/Inputs/Label";
import localize from "../../../../util/localize";
import Lister from "../../../../components/Lister/Lister";
import api, { Service } from "../../../../util/api";
import useNotifications from "../../../../hooks/useNotifications";

interface Props {
  publisher: UsePublisherInterface<BookingService>;
}

const General: React.FC<Props> = ({ publisher }) => {
  const form = publisher.form;
  const data = form.data;

  return (
    <Fader>
      <Flex align="flex-start">
        <FlexKid flex={1}>
          <Section hugTop>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Generelt
                  </Text>
                </Section>
                {createPublisherInputField(publisher, {
                  path: "type",
                  label: "Type",
                  type: "radio",
                  readOnly: !publisher.isNew,
                  options: Object.keys(bookingServiceType).map((k) => ({
                    value: k,
                    label: bookingServiceType[k]
                  })),
                  required: true
                })}
                {createPublisherInputField(publisher, {
                  path: "name",
                  label: "Navn",
                  type: "text",
                  required: true
                })}
                {createPublisherInputField(publisher, {
                  path: "description",
                  label: "Beskrivelse",
                  type: "textarea"
                })}
                {createPublisherInputField(publisher, {
                  path: "slots",
                  label: "Kapasitet",
                  type: "number",
                  min: 1
                })}
                {createPublisherInputField(publisher, {
                  path: "duration",
                  label: "Varighet (minutter)",
                  type: "number",
                  readOnly:
                    data.type === BookingServiceType.Event && !publisher.isNew
                })}
                {data.type === BookingServiceType.Appointment &&
                  createPublisherInputField(publisher, {
                    path: "roundDuration",
                    label: "Rund opp varighet til nærmeste…",
                    type: "select",
                    options: Object.keys(bookingDurationUnit).map((k) => ({
                      value: k,
                      label: bookingDurationUnit[k]
                    }))
                  })}
                {createPublisherInputField(publisher, {
                  path: "requiredProductPurchase",
                  label: "Kan kun bookes gjennom produktkjøp",
                  type: "boolean"
                })}
                {data.requiredProductPurchase &&
                  createPublisherInputField(publisher, {
                    path: "mergeBookings",
                    label: "Flere kjøp samles i én booking",
                    type: "boolean"
                  })}
                {createPublisherInputField(publisher, {
                  path: "price",
                  label: "Pris",
                  type: "number",
                  required: true
                })}
                {data.mergeBookings &&
                  createPublisherInputField(publisher, {
                    path: "pricePer",
                    label: "Pris kalkuleres per…",
                    type: "select",
                    options: Object.keys(bookingDurationUnit).map((k) => ({
                      value: k,
                      label: bookingDurationUnit[k]
                    }))
                  })}
              </Block>
            </Card>
          </Section>
          {data.type === BookingServiceType.Appointment && (
            <Section>
              <Card>
                <Block>
                  <Section hugTop>
                    <Text element="h2" variant="title">
                      Begrensninger
                    </Text>
                  </Section>
                  {createPublisherInputField(publisher, {
                    path: "scheduleLimitDays",
                    label: "Kan ikke bookes lenger enn X dager frem i tid",
                    type: "number"
                  })}
                  {createPublisherInputField(publisher, {
                    path: "scheduleLimitHours",
                    label: "Kan tidligst bookes X timer i forveien",
                    type: "number"
                  })}
                </Block>
              </Card>
            </Section>
          )}
        </FlexKid>
        <FlexKid flex={1} spaceLeft>
          <Section hugTop>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Ressurser
                  </Text>
                </Section>
                <Lister
                  id="resources"
                  url={["booking", "resources"]}
                  searchKey="name"
                  labelKey="name"
                  items={data.resources.map((r) =>
                    typeof r.ref === "string" ? r.ref : r.ref._id
                  )}
                  disableDrag
                  previewItems={data.resources
                    .filter((r) => typeof r.ref !== "string")
                    .map((r) => r.ref)}
                  itemToString={(elem) => (elem ? elem.name : "")}
                  filter={(value) => {
                    return !data.resources.some(
                      (s) =>
                        typeof s.ref !== "string" && s.ref._id === value._id
                    );
                  }}
                  onChange={(items, previewItems) =>
                    form.setData({
                      data: {
                        ...data,
                        resources: previewItems
                          .filter((i) => items.includes(i._id))
                          .map((i) => ({
                            ref: i
                          }))
                      }
                    })
                  }
                />
              </Block>
            </Card>
          </Section>
          <Section>
            {data.type === BookingServiceType.Event ? (
              <Events publisher={publisher} />
            ) : (
              <Availability publisher={publisher} />
            )}
          </Section>
        </FlexKid>
      </Flex>
    </Fader>
  );
};

interface AvailabilityProps {
  publisher: UsePublisherInterface<Pick<BookingService, "availability">>;
}

export const Availability: React.FC<AvailabilityProps> = ({ publisher }) => {
  const form = publisher.form;
  const data = form.data;

  const { spawnModal } = useModal();

  const dayAvailabilities = useMemo<BookingAvailability[]>(
    () => (data.availability || []).filter((a) => !!a.day),
    [data.availability]
  );

  const dateAvailabilities = useMemo<BookingAvailability[]>(
    () => (data.availability || []).filter((a) => !!a.date),
    [data.availability]
  );

  const createAvailabilityModal = useCallback(
    (type: "day" | "date", availability?: BookingAvailability) => {
      spawnModal(
        <CrudModal
          title={`${availability ? "Endre" : "Legg til"} ${
            type === "day" ? "dag" : "dato"
          }`}
          initialData={availability || { hours: [] }}
          onSubmit={(value) => {
            form.setField({
              path: "availability",
              value: availability
                ? form.data.availability.map((a) => {
                    if (type === "day" && a.day === availability.day) {
                      return value;
                    }

                    if (type === "date" && a.date === availability.date) {
                      return value;
                    }

                    return a;
                  })
                : [...(form.data.availability || []), value]
            });
          }}
          onDelete={
            availability
              ? () => {
                  form.setField({
                    path: "availability",
                    value: form.data.availability.filter((a) => {
                      if (type === "day") {
                        return a.day !== availability.day;
                      }

                      return a.date !== availability.date;
                    })
                  });
                }
              : undefined
          }
          fields={[
            {
              key: type,
              render: (payload) =>
                createCrudModalField({
                  payload,
                  key: type,
                  label: type === "day" ? "Dag" : "Dato",
                  required: true,
                  type: type === "day" ? "select" : "date",
                  options: Object.keys(days)
                    .filter(
                      (d) =>
                        d === payload.data.day ||
                        !form.data.availability.some((a) => a.day === d)
                    )
                    .map((d) => ({
                      value: d,
                      label: days[d]
                    }))
                })
            },
            {
              key: "hours",
              render: (payload) => (
                <Field hugBottom>
                  <Label>Tilgjengelige tidspunkter</Label>
                  <Text variant="body3" gutterBottom>
                    Dersom denne dagen/datoen skal være utilgjengelig, kan du la
                    lista med tidspunkter være tom.
                  </Text>
                  <OfflineLister
                    values={payload.data.hours || []}
                    writeMode
                    selectLabel="Tidspunkt"
                    placeholder="08:00-12:00"
                    onChange={(value) => payload.setField("hours", value)}
                    pattern="^[0-2]\d:[0-5]\d-[0-2]\d:[0-5]\d$"
                    patternDescription="Må være to klokkeslett (24-timersklokke) delt med en delestrek (-). Eksempel: 08:00-12:00"
                  />
                </Field>
              )
            }
          ]}
        />
      );
    },
    [form]
  );

  return (
    <>
      <Section hugTop>
        <Card>
          <Block>
            <Flex>
              <FlexKid flex={1}>
                <Text element="h2" variant="title">
                  Tilgjengelighet i ukedag
                </Text>
              </FlexKid>
              <FlexKid>
                <Button
                  type="button"
                  outlined
                  onClick={() => createAvailabilityModal("day")}
                >
                  Legg til dag
                </Button>
              </FlexKid>
            </Flex>
          </Block>
          <Table>
            <thead>
              <Th>Dag</Th>
              <Th>Tilgjengelighet</Th>
              <Th align="right">Handlinger</Th>
            </thead>
            <tbody>
              {dayAvailabilities.length > 0 ? (
                dayAvailabilities.map((a) => (
                  <Tr key={a._id}>
                    <Td verticalAlign="middle">{localize(days, a.day!)}</Td>
                    <Td verticalAlign="middle">
                      {a.hours.length > 0
                        ? a.hours.map((h) => <Text key={h}>{h}</Text>)
                        : "Utilgjengelig"}
                    </Td>
                    <Td align="right" verticalAlign="middle">
                      <ButtonList align="right">
                        <Button
                          type="button"
                          outlined
                          onClick={() => createAvailabilityModal("day", a)}
                        >
                          Endre
                        </Button>
                      </ButtonList>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={3}>
                    <Text variant="subheading">Ingen dager lagt til</Text>
                  </Td>
                </Tr>
              )}
            </tbody>
          </Table>
        </Card>
      </Section>
      <Section>
        <Card>
          <Block>
            <Flex>
              <FlexKid flex={1}>
                <Text element="h2" variant="title">
                  Tilgjengelighet på dato
                </Text>
              </FlexKid>
              <FlexKid>
                <Button
                  type="button"
                  outlined
                  onClick={() => createAvailabilityModal("date")}
                >
                  Legg til dato
                </Button>
              </FlexKid>
            </Flex>
          </Block>
          <Table>
            <thead>
              <Th>Dato</Th>
              <Th>Tilgjengelighet</Th>
              <Th align="right">Handlinger</Th>
            </thead>
            <tbody>
              {dateAvailabilities.length > 0 ? (
                dateAvailabilities.map((a) => (
                  <Tr key={a._id}>
                    <Td verticalAlign="middle">
                      {moment(a.date).format("D[.]M[.]YYYY")}
                    </Td>
                    <Td verticalAlign="middle">
                      {a.hours.length > 0
                        ? a.hours.map((h) => <Text key={h}>{h}</Text>)
                        : "Utilgjengelig"}
                    </Td>
                    <Td align="right" verticalAlign="middle">
                      <ButtonList align="right">
                        <Button
                          type="button"
                          outlined
                          onClick={() => createAvailabilityModal("date", a)}
                        >
                          Endre
                        </Button>
                      </ButtonList>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={3}>
                    <Text variant="subheading">Ingen datoer lagt til</Text>
                  </Td>
                </Tr>
              )}
            </tbody>
          </Table>
        </Card>
      </Section>
    </>
  );
};

interface EventsProps {
  publisher: UsePublisherInterface<BookingService>;
}

const Events: React.FC<EventsProps> = ({ publisher }) => {
  const form = publisher.form;
  const data = form.data;

  return (
    <Card>
      <Block>
        <Flex>
          <FlexKid flex={1}>
            <Text element="h2" variant="title">
              Begivenheter
            </Text>
          </FlexKid>
          <FlexKid>
            <Button
              type="button"
              outlined
              onClick={() => {
                form.setField({
                  path: "events",
                  value: [
                    {
                      id: createId()
                    },
                    ...(data.events || [])
                  ]
                });
              }}
            >
              Ny begivenhet
            </Button>
          </FlexKid>
        </Flex>
      </Block>
      <InlineEditHead>
        <InlineEditColumn width="300px">Starter</InlineEditColumn>
        <InlineEditColumn width="150px">Kapasitet</InlineEditColumn>
        <InlineEditColumn width="120px" alignRight>
          Handlinger
        </InlineEditColumn>
      </InlineEditHead>
      {(data.events || []).length > 0 ? (
        data.events.map((e, k) => (
          <Event
            //@ts-ignore
            key={e._id || e.id}
            service={data}
            event={e}
            isNew={!e._id}
            onChange={(event) =>
              form.setField({
                path: "events",
                value: (data.events || []).map((ee, kk) => {
                  if (kk === k) {
                    return event;
                  }

                  return ee;
                })
              })
            }
            onDelete={(unsaved) => {
              if (unsaved) {
                form.setField({
                  path: "events",
                  value: (data.events || []).filter((ee, kk) => {
                    if (kk === k) {
                      return false;
                    }

                    return true;
                  })
                });
              } else {
                form.refresh();
              }
            }}
          />
        ))
      ) : (
        <>
          <Ruler />
          <Block hugTop>
            <Text variant="subheading">Ingen begivenheter enda</Text>
          </Block>
        </>
      )}
    </Card>
  );
};

interface EventProps {
  service: BookingService;
  event: BookingServiceEvent;
  isNew: boolean;
  onChange: (event: BookingServiceEvent) => any;
  onDelete: (unsaved: boolean) => any;
}

const Event: React.FC<EventProps> = ({
  service,
  event,
  isNew,
  onChange,
  onDelete
}) => {
  const [deleting, setDeleting] = useState<boolean>(false);
  const notifications = useNotifications();

  return (
    <InlineEdit
      expanded={isNew}
      alignItems="flex-start"
      headerColumns={[
        {
          width: "300px",
          node: createInputField({
            key: "start",
            type: "time",
            value: event.start,
            required: true,
            hugTop: true,
            autoFocus: isNew,
            readOnly: !isNew,
            disabled: !isNew,
            onChange: (value) => {
              onChange({
                ...event,
                start: value
              });
            }
          })
        },
        {
          width: "150px",
          node: createInputField({
            key: "slots",
            type: "number",
            value: event.slots,
            placeholder: `${service.slots}`,
            hugTop: true,
            readOnly: !isNew,
            disabled: !isNew,
            onChange: (value) => {
              onChange({
                ...event,
                slots: value
              });
            }
          })
        },
        {
          width: "120px",
          alignRight: true,
          alignSelf: "center",
          node: (
            <Button
              type="button"
              aria-label="Slett begivenhet"
              circular
              outlined
              smaller
              disabled={deleting}
              onClick={async () => {
                if (window.confirm("Er du sikker på at du vil slette?")) {
                  if (!event._id) {
                    onDelete(true);
                  } else {
                    setDeleting(true);

                    notifications.spawn({
                      title: "Sletter begivenhet…"
                    });

                    try {
                      await api<GenericResponse>({
                        service: Service.Admin,
                        endpoint: `booking/services/${service._id}/events/${event._id}`,
                        method: "DELETE"
                      });

                      notifications.spawn({
                        title: "Begivenhet slettet"
                      });

                      onDelete(false);
                    } catch (error) {
                      notifications.spawn({
                        title: "Begivenhet kan ikke slettes",
                        subtitle:
                          "Kanseller bookingene før begivenheten kan slettes",
                        type: "error",
                        icon: <FaRegSadCry />
                      });
                    } finally {
                      setDeleting(false);
                    }
                  }
                }
              }}
            >
              <AiOutlineDelete />
            </Button>
          )
        }
      ]}
    />
  );
};

export default General;
