import styled from "styled-components";

interface Props {
  maxHeight: string;
}

const Overflower = styled.div<Props>`
  overflow-y: auto;
  max-height: ${props => props.maxHeight};
`;

export default Overflower;
