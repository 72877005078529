import React, { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { Page, Template } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import {
  Button,
  ButtonExternalLink,
  ButtonList
} from "../../style-guide/Button/Button";
import useSite from "../../hooks/useSite";
import { Modal, ModalActions, ModalBody } from "../../style-guide/Modal/Modal";
import BusyBoy from "../../components/BusyBoy/BusyBoy";
import Block from "../../style-guide/Block/Block";
import useForm from "../../hooks/useForm";
import Form from "../../style-guide/Inputs/Form";
import createInputField from "../../util/createInputField";
import { useSearch } from "../../hooks/useApi";
import Label from "../../style-guide/Inputs/Label";
import Field from "../../style-guide/Inputs/Field";
import EmptyState from "../../style-guide/EmptyState/EmptyState";
import Card from "../../style-guide/Card/Card";
import undraftSlug from "../../util/undraftSlug";
import useCanonicalUrl from "../../hooks/useCanonicalUrl";
import Fader from "../../style-guide/Fader/Fader";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import Crumb from "../../components/Crumb/Crumb";
import PagePicker from "../../components/PagePicker/PagePicker";
import usePageMeta, { PageMeta } from "../../hooks/usePageMeta";
import PageSelector from "../../components/PageSelector/PageSelector";

interface Props {
  isCategory?: boolean;
  isProductPage?: boolean;
}

const Pages: React.FC<Props> = ({ isCategory, isProductPage }) => {
  const { spawnModal } = useModal();
  const pageMeta = usePageMeta(
    isCategory ? "category" : isProductPage ? "productPage" : "page"
  );

  return (
    <Fader>
      <Block hugTop>
        <Flex>
          <FlexKid flex={1}>
            <Text element="h1" variant="display3">
              <Crumb url="/innhold">Innhold</Crumb>
              {pageMeta.title}
            </Text>
          </FlexKid>
          <FlexKid>
            <Button
              onClick={() =>
                spawnModal(
                  <CreatePageModal
                    pageMeta={pageMeta}
                    isCategory={isCategory}
                    isProductPage={isProductPage}
                  />
                )
              }
            >
              Opprett {pageMeta.singular}
            </Button>
          </FlexKid>
        </Flex>
      </Block>
      <PagePicker
        pageMeta={pageMeta}
        queryParams={{
          isCategory: !!isCategory,
          isProductPage: !!isProductPage,
          fetchInherited: true
        }}
      />
    </Fader>
  );
};

interface CreatePageModalProps {
  pageMeta: PageMeta;
  isCategory?: boolean;
  isProductPage?: boolean;
}

const CreatePageModal: React.FC<CreatePageModalProps> = ({
  pageMeta,
  isCategory,
  isProductPage
}) => {
  const site = useSite();
  const history = useHistory();
  const { despawnModal } = useModal();
  const form = useForm<Page>(
    { isCategory, isProductPage },
    {
      endpoint: "pages",
      method: "POST",
      onSuccess: (page: Page) => {
        if ("_id" in page) {
          despawnModal();
          history.push(`/innhold/${pageMeta.plural}/${page._id}`);
        }
      }
    }
  );

  const templatesSearch = useSearch<Template>({
    endpoint: "templates",
    fetchOnMount: true,
    queryParams: {
      draft: false,
      partial: false,
      fetchInherited: true,
      removeOverrides: true
    },
    limit: 100
  });

  const defaultTemplate = useMemo<Template | undefined>(() => {
    let id: string | null = null;

    if (isCategory) {
      id = site.templateDistribution.category.ref;
    } else if (isProductPage) {
      id = site.templateDistribution.productPage.ref;
    } else {
      id = site.templateDistribution.page.ref;
    }

    if (id) {
      return templatesSearch.results.find((t) => t._id === id);
    }

    return undefined;
  }, [
    isCategory,
    isProductPage,
    site.templateDistribution,
    templatesSearch.results
  ]);

  const noTemplates = useMemo<boolean>(() => {
    return templatesSearch.hasSearched && templatesSearch.results.length === 0;
  }, [templatesSearch.hasSearched, templatesSearch.results.length]);

  return (
    <Form onSubmit={form.submit}>
      <Modal maxWidth="32rem">
        <BusyBoy busy={form.submitting}>
          <ModalBody>
            <Block>
              <Text variant="title" gutterBottom>
                Opprett {pageMeta.singular}
              </Text>
              {createInputField({
                key: "title",
                label: "Tittel",
                value: form.getValue("title"),
                required: true,
                type: "text",
                onChange: (value) =>
                  form.setField({
                    path: "title",
                    value
                  })
              })}
              {noTemplates ? (
                <Field>
                  <Label>Mal *</Label>
                  <Card outlined>
                    <EmptyState
                      title="Ingen maler opprettet"
                      hideIcon
                      text={
                        <>
                          <p>
                            Du trenger minst én mal for å opprette sider. Du kan
                            opprette en mal ved å gå til{" "}
                            <Link
                              to="/innstillinger/maler/liste"
                              onClick={() => despawnModal()}
                            >
                              Innstillinger &rsaquo; Maler &rsaquo; Oversikt
                              over maler
                            </Link>
                          </p>
                          <p>
                            Du må også huske å tilegne standardmaler for
                            forskjellige sidetyper, som <em>Forside</em>,{" "}
                            <em>Artikkel</em>, og så videre. Dette gjøres ved å
                            gå til{" "}
                            <Link
                              to="/innstillinger/maler/bruk"
                              onClick={() => despawnModal()}
                            >
                              Innstillinger &rsaquo; Maler &rsaquo; Malbruk
                            </Link>
                          </p>
                        </>
                      }
                      smaller
                    />
                  </Card>
                </Field>
              ) : (
                <BusyBoy busy={templatesSearch.loading}>
                  {createInputField({
                    key: "template",
                    label: "Mal",
                    value:
                      form.getValue("template.ref") ||
                      defaultTemplate?._id ||
                      "",
                    placeholder: "Velg mal",
                    required: true,
                    type: "select",
                    disabled: templatesSearch.loading,
                    options: templatesSearch.results.map((t) => ({
                      value: t._id,
                      label: t.site !== site._id ? `${t.name} – Global` : t.name
                    })),
                    onChange: (value) =>
                      form.setField({
                        path: "template",
                        value: {
                          ref: value || null
                        }
                      })
                  })}
                </BusyBoy>
              )}
              {!isProductPage && (
                <Field hugBottom>
                  <Label>Forelder</Label>
                  <PageSelector
                    page={form.getValue("parent")}
                    onChange={(page) => {
                      form.setField({
                        path: "parent",
                        value: page
                      });
                    }}
                    queryParams={{
                      draft: false,
                      isProductPage: false,
                      fetchInherited: true
                    }}
                  />
                </Field>
              )}
            </Block>
          </ModalBody>
          <ModalActions>
            <ButtonList align="right">
              <Button
                type="button"
                outlined
                onClick={() => {
                  despawnModal();
                }}
              >
                Avbryt
              </Button>
              <Button
                type="submit"
                disabled={templatesSearch.loading || noTemplates}
              >
                {form.submitting ? "Oppretter…" : "Opprett"}
              </Button>
            </ButtonList>
          </ModalActions>
        </BusyBoy>
      </Modal>
    </Form>
  );
};

interface CloneParentPageModalProps {
  page: Page;
  pageMeta: PageMeta;
}

export const CloneParentPageModal: React.FC<CloneParentPageModalProps> = ({
  page,
  pageMeta
}) => {
  const history = useHistory();
  const canonical = useCanonicalUrl([undraftSlug(page.slug)]);
  const { despawnModal } = useModal();

  const form = useForm<Page>(
    { id: page._id },
    {
      endpoint: "pages/clone",
      method: "POST",
      onSuccess: (page: Page) => {
        if ("_id" in page) {
          despawnModal();
          history.push(`/innhold/${pageMeta.plural}/${page._id}`);
        }
      }
    }
  );

  return (
    <Form onSubmit={form.submit}>
      <Modal maxWidth="32rem">
        <BusyBoy busy={form.submitting}>
          <ModalBody>
            <Block>
              <Text variant="title" gutterBottom>
                Globalt innhold
              </Text>
              <Text gutterBottom>
                <strong>{page.title}</strong> er publisert globalt, som betyr at
                du ikke kan gjøre endringer her.
              </Text>
              <Text>
                Dersom du ønsker å gjøre endringer på denne {pageMeta.singular}
                n, kan du lage en lokal kopi, som vil overstyre innholdet som er
                publisert globalt.
              </Text>
            </Block>
          </ModalBody>
          <ModalActions>
            <ButtonList align="right">
              <Button
                type="button"
                outlined
                onClick={() => {
                  despawnModal();
                }}
              >
                Avbryt
              </Button>
              <ButtonExternalLink
                outlined
                href={canonical}
                target="_blank"
                rel="noopener noreferrer"
              >
                Vis {pageMeta.singular}
              </ButtonExternalLink>
              <Button type="submit">
                {form.submitting ? "Lager kopi…" : "Lag kopi"}
              </Button>
            </ButtonList>
          </ModalActions>
        </BusyBoy>
      </Modal>
    </Form>
  );
};

export default Pages;
