import React, { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { AiOutlineFile } from "react-icons/ai";
import { IoMdGlobe } from "react-icons/io";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { Site, Template } from "../../../types/apiResponses";
import Text from "../../../style-guide/Text/Text";
import { ButtonLink } from "../../../style-guide/Button/Button";
import Tabler from "../../../components/Tabler/Tabler";
import { Dotter, Dot } from "../../../style-guide/Dotter/Dotter";
import useSite from "../../../hooks/useSite";
import useForm from "../../../hooks/useForm";
import Form from "../../../style-guide/Inputs/Form";
import {
  Modal,
  ModalActions,
  ModalBody
} from "../../../style-guide/Modal/Modal";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";
import Block from "../../../style-guide/Block/Block";
import { Button, ButtonList } from "../../../style-guide/Button/Button";

const Templates: React.FC = () => {
  const site = useSite();

  return (
    <Tabler
      title="Oversikt"
      crumb={{
        label: "Maler",
        path: "/innstillinger/maler"
      }}
      actions={<ButtonLink to="liste/opprett">Opprett mal</ButtonLink>}
      emptyState={{
        title: "Opprett din første mal",
        icon: <AiOutlineFile />,
        text: (
          <>
            <Text gutterBottom>
              Maler er superviktig i Rubics. Uten en mal får du ikke publisert
              noe innhold!
            </Text>
            <Text gutterBottom>
              En mal er rett og slett en samling komponenter som bestemmer
              hvordan en side kan se ut, og du kan publisere en mal, enkelt og
              greit, uten noe som helst involvering fra utviklere.
            </Text>
            <Text>
              Alle innholdstyper trenger en mal. Sider, kategorier, produkter,
              handlekurv, osv.
            </Text>
          </>
        ),
        action: <ButtonLink to="liste/opprett">Opprett mal</ButtonLink>
      }}
      url={["templates"]}
      queryParams={{
        fetchInherited: true,
        removeOverridden: true
      }}
      columns={[
        {
          Header: "Navn",
          accessor: "title"
        }
      ]}
      createRow={(template: Template) => ({
        ...template,
        title: <TitleRow template={template} site={site} />
      })}
    />
  );
};

interface TitleRowProps {
  template: Template;
  site: Site;
}

const TitleRow: React.FC<TitleRowProps> = ({ template, site }) => {
  const { spawnModal } = useModal();
  const isInherited = useMemo<boolean>(() => {
    return site._id ? template.site !== site._id : false;
  }, [template.site, site._id]);

  return (
    <>
      <Text variant="body2">
        <Link
          to={`/innstillinger/maler/liste/${template._id}`}
          onClick={(e) => {
            if (isInherited) {
              e.preventDefault();
              spawnModal(<CloneParentTemplateModal template={template} />);
            }
          }}
        >
          {template.name}
          {isInherited && (
            <>
              {" "}
              <IoMdGlobe />
            </>
          )}
        </Link>
      </Text>
      <Text variant="body3">
        <Dotter>
          {template.partial && <Dot>Undermal</Dot>}
          {template.modified ? (
            <Dot>
              <Text element="span" variant="subtitle">
                Upubliserte endringer
              </Text>
            </Dot>
          ) : (
            <Dot>Ingen endringer</Dot>
          )}
          {template.overrides && typeof template.overrides !== "string" && (
            <Dot>Overstyrer global mal {template.overrides.name}</Dot>
          )}
        </Dotter>
      </Text>
    </>
  );
};

interface CloneParentTemplateModalProps {
  template: Template;
}

const CloneParentTemplateModal: React.FC<CloneParentTemplateModalProps> = ({
  template
}) => {
  const history = useHistory();
  const { despawnModal } = useModal();

  const form = useForm<Template>(
    { id: template._id },
    {
      endpoint: "templates/clone",
      method: "POST",
      onSuccess: (template: Template) => {
        if ("_id" in template) {
          despawnModal();
          history.push(`/innstillinger/maler/liste/${template._id}`);
        }
      }
    }
  );

  return (
    <Form onSubmit={form.submit}>
      <Modal maxWidth="32rem">
        <BusyBoy busy={form.submitting}>
          <ModalBody>
            <Block>
              <Text variant="title" gutterBottom>
                Globalt innhold
              </Text>
              <Text gutterBottom>
                <strong>{template.name}</strong> er publisert globalt, som betyr
                at du ikke kan gjøre endringer her.
              </Text>
              <Text>
                Dersom du ønsker å gjøre endringer på denne malen , kan du lage
                en lokal kopi, som vil overstyre innholdet som er publisert
                globalt.
              </Text>
            </Block>
          </ModalBody>
          <ModalActions>
            <ButtonList align="right">
              <Button
                type="button"
                outlined
                onClick={() => {
                  despawnModal();
                }}
              >
                Avbryt
              </Button>
              <Button type="submit">
                {form.submitting ? "Lager kopi…" : "Lag kopi"}
              </Button>
            </ButtonList>
          </ModalActions>
        </BusyBoy>
      </Modal>
    </Form>
  );
};

export default Templates;
