import styled, { css } from "styled-components";

interface Props {
  variant?: "neutral" | "bad" | "good";
  wider?: boolean;
  onBackground?: boolean;
  fullWidth?: boolean;
}

const Status = styled.span<Props>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.textPrimary};
  border-radius: 8px;
  height: 42px;
  padding: 0 ${(props) => props.theme.spacing.small};
  width: 90px;

  ${(props) =>
    props.variant === "good" &&
    css`
      background-color: ${props.theme.colors.goodBackground};
    `}

  ${(props) =>
    props.variant === "neutral" &&
    css`
      background-color: ${props.theme.colors.neutralBackground};
    `}

  ${(props) =>
    props.variant === "bad" &&
    css`
      background-color: ${props.theme.colors.warningBackground};
    `}

  ${(props) =>
    props.onBackground &&
    css`
      border: 1px solid ${props.theme.colors.borderDim};
    `}

  ${(props) =>
    props.wider &&
    css`
      width: 180px;
    `}
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
`;

const StatusBulb = styled.i<Pick<Props, "variant">>`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.neutral};

  ${(props) =>
    props.variant === "good" &&
    css`
      background-color: ${props.theme.colors.good};
    `}

  ${(props) =>
    props.variant === "bad" &&
    css`
      background-color: ${props.theme.colors.warning};
    `}
`;

export default Status;
export { StatusBulb };
