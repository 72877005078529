import React, { useContext } from "react";
import {
  AiOutlineFile,
  AiOutlineShop,
  AiOutlineUsergroupAdd,
  AiOutlineDollar,
  AiOutlineKey,
  AiOutlineFileText,
  AiOutlineCompass,
  AiOutlineUnorderedList,
  AiOutlineDoubleRight,
  AiOutlinePartition,
  AiOutlineCalendar,
  AiOutlineMail,
  AiOutlineBook
} from "react-icons/ai";
import { RiBuildingLine } from "react-icons/ri";
import { GiReceiveMoney } from "react-icons/gi";
import { MdBusiness, MdPalette } from "react-icons/md";
import { FiTruck } from "react-icons/fi";
import Fader from "../../style-guide/Fader/Fader";
import Text from "../../style-guide/Text/Text";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import { CardLink } from "../../style-guide/Card/Card";
import Block from "../../style-guide/Block/Block";
import Icon from "../../components/Icon/Icon";
import Section from "../../style-guide/Section/Section";
import Grid from "../../style-guide/Grid/Grid";
import Avatar from "../../style-guide/Avatar/Avatar";
import { UserContext } from "../../context/User";
import fallbackImage from "../../util/images/empty_preview.png";
import Doorman from "../../components/Doorman/Doorman";
import useSite from "../../hooks/useSite";
import useSiteSelection from "../../hooks/useSiteSelection";

const SettingsNavigation: React.FC = () => {
  const user = useContext(UserContext);
  const site = useSite();
  const siteSelection = useSiteSelection();

  return (
    <Fader>
      <Block hugTop>
        <Text element="h1" variant="display3">
          Innstillinger
        </Text>
      </Block>
      <Section hugTop>
        <Block hugTop hugBottom>
          <Text element="h2" variant="subheading" gutterBottom>
            Generelt
          </Text>
        </Block>
        <Grid gap="medium" columns={3}>
          <Doorman type="admin">
            <CardLink to="/innstillinger/nettsted">
              <Block flex>
                <Flex flex={1}>
                  <FlexKid flex={1}>
                    <Text element="h3" variant="title">
                      Nettsted
                    </Text>
                    <Text variant="body3">Administrer nettsted</Text>
                  </FlexKid>
                  <FlexKid spaceLeft>
                    <Icon size="large">
                      <AiOutlineShop />
                    </Icon>
                  </FlexKid>
                </Flex>
              </Block>
            </CardLink>
          </Doorman>
          <Doorman type="admin">
            <CardLink to="/innstillinger/domener">
              <Block flex>
                <Flex flex={1}>
                  <FlexKid flex={1}>
                    <Text element="h3" variant="title">
                      Domener
                    </Text>
                    <Text variant="body3">Administrer domener</Text>
                  </FlexKid>
                  <FlexKid spaceLeft>
                    <Icon size="large">
                      <AiOutlineCompass />
                    </Icon>
                  </FlexKid>
                </Flex>
              </Block>
            </CardLink>
          </Doorman>
          <Doorman type="admin">
            <CardLink to="/innstillinger/brukere" stretch>
              <Block flex>
                <Flex flex={1}>
                  <FlexKid flex={1}>
                    <Text element="h3" variant="title">
                      Brukere
                    </Text>
                    <Text variant="body3">Administrer brukere</Text>
                  </FlexKid>
                  <FlexKid>
                    <Icon size="large">
                      <AiOutlineUsergroupAdd />
                    </Icon>
                  </FlexKid>
                </Flex>
              </Block>
            </CardLink>
          </Doorman>
          <Doorman type="admin">
            <CardLink to="/innstillinger/redirects">
              <Block flex>
                <Flex flex={1}>
                  <FlexKid flex={1}>
                    <Text element="h3" variant="title">
                      Redirects
                    </Text>
                    <Text variant="body3">
                      Dersom du har flyttet fra en tidligere nettside og ønsker
                      å omdirigere trafikk fra URL-er som ikke lenger
                      eksisterer, kan du administrere det her
                    </Text>
                  </FlexKid>
                  <FlexKid spaceLeft>
                    <Icon size="large">
                      <AiOutlineDoubleRight />
                    </Icon>
                  </FlexKid>
                </Flex>
              </Block>
            </CardLink>
          </Doorman>
          <Doorman type="admin">
            <CardLink to="/innstillinger/booking">
              <Block flex>
                <Flex flex={1}>
                  <FlexKid flex={1}>
                    <Text element="h3" variant="title">
                      Booking
                    </Text>
                    <Text variant="body3">
                      Aktiver/deaktiver booking-funksjonalitet
                    </Text>
                  </FlexKid>
                  <FlexKid spaceLeft>
                    <Icon size="large">
                      <AiOutlineCalendar />
                    </Icon>
                  </FlexKid>
                </Flex>
              </Block>
            </CardLink>
          </Doorman>
          {!site.departmentOf && (
            <Doorman type="admin">
              <CardLink to="/innstillinger/avdelinger">
                <Block flex>
                  <Flex flex={1}>
                    <FlexKid flex={1}>
                      <Text element="h3" variant="title">
                        Avdelinger
                      </Text>
                      <Text variant="body3">Administrer avdelinger</Text>
                    </FlexKid>
                    <FlexKid spaceLeft>
                      <Icon size="large">
                        <RiBuildingLine />
                      </Icon>
                    </FlexKid>
                  </Flex>
                </Block>
              </CardLink>
            </Doorman>
          )}
          <Doorman type="admin">
            <CardLink to="/innstillinger/tilgangsnokler">
              <Block flex>
                <Flex flex={1}>
                  <FlexKid flex={1}>
                    <Text element="h3" variant="title">
                      Tilgangsnøkler
                    </Text>
                    <Text variant="body3">Administrer tilgangsnøkler</Text>
                  </FlexKid>
                  <FlexKid spaceLeft>
                    <Icon size="large">
                      <AiOutlineKey />
                    </Icon>
                  </FlexKid>
                </Flex>
              </Block>
            </CardLink>
          </Doorman>
          <Doorman type="admin">
            <CardLink to="/innstillinger/aktivitetslogg">
              <Block flex>
                <Flex flex={1}>
                  <FlexKid flex={1}>
                    <Text element="h3" variant="title">
                      Aktivitetslogg
                    </Text>
                    <Text variant="body3">
                      Se aktivitetslogg for administratorer og bidragsytere
                    </Text>
                  </FlexKid>
                  <FlexKid spaceLeft>
                    <Icon size="large">
                      <AiOutlineUnorderedList />
                    </Icon>
                  </FlexKid>
                </Flex>
              </Block>
            </CardLink>
          </Doorman>
          {site.cookieDialogEnabled && (
            <Doorman type="admin">
              <CardLink to="/innstillinger/informasjonskapsler">
                <Block flex>
                  <Flex flex={1}>
                    <FlexKid flex={1}>
                      <Text element="h3" variant="title">
                        Informasjonskapsler
                      </Text>
                      <Text variant="body3">
                        Administrer informasjonskapsler
                      </Text>
                    </FlexKid>
                    <FlexKid spaceLeft>
                      <Icon size="large">
                        <AiOutlineCompass />
                      </Icon>
                    </FlexKid>
                  </Flex>
                </Block>
              </CardLink>
            </Doorman>
          )}
        </Grid>
      </Section>
      <Section>
        <Block hugTop hugBottom>
          <Text element="h2" variant="subheading" gutterBottom>
            Visuelt
          </Text>
        </Block>
        <Grid gap="medium" columns={3}>
          <Doorman type="admin">
            <CardLink to="/innstillinger/maler">
              <Block flex>
                <Flex flex={1}>
                  <FlexKid flex={1}>
                    <Text element="h3" variant="title">
                      Maler
                    </Text>
                    <Text variant="body3">Administrer maler og malbruk</Text>
                  </FlexKid>
                  <FlexKid spaceLeft>
                    <Icon size="large">
                      <AiOutlineFile />
                    </Icon>
                  </FlexKid>
                </Flex>
              </Block>
            </CardLink>
          </Doorman>
          <Doorman type="admin">
            <CardLink to="/designer/temainnstillinger">
              <Block flex>
                <Flex flex={1}>
                  <FlexKid flex={1}>
                    <Text element="h3" variant="title">
                      Tema
                    </Text>
                    <Text variant="body3">
                      Endre fonter, farger og andre temainnstillinger
                    </Text>
                  </FlexKid>
                  <FlexKid spaceLeft>
                    <Icon size="large">
                      <MdPalette />
                    </Icon>
                  </FlexKid>
                </Flex>
              </Block>
            </CardLink>
          </Doorman>
        </Grid>
      </Section>
      {site.hasEcommerce && (
        <Section>
          <Block hugTop hugBottom>
            <Text element="h2" variant="subheading" gutterBottom>
              Butikk
            </Text>
          </Block>
          <Grid gap="medium" columns={3}>
            <Doorman type="admin">
              <CardLink to="/innstillinger/betalingslosninger">
                <Block flex>
                  <Flex flex={1}>
                    <FlexKid flex={1}>
                      <Text element="h3" variant="title">
                        Betalingsløsninger
                      </Text>
                      <Text variant="body3">
                        Administrer betalingsløsninger og -metoder
                      </Text>
                    </FlexKid>
                    <FlexKid spaceLeft>
                      <Icon size="large">
                        <AiOutlineDollar />
                      </Icon>
                    </FlexKid>
                  </Flex>
                </Block>
              </CardLink>
            </Doorman>
            <Doorman type="admin">
              <CardLink to="/innstillinger/frakt">
                <Block flex>
                  <Flex flex={1}>
                    <FlexKid flex={1}>
                      <Text element="h3" variant="title">
                        Frakt
                      </Text>
                      <Text variant="body3">
                        Administrer fraktklasser, soner og metoder
                      </Text>
                    </FlexKid>
                    <FlexKid spaceLeft>
                      <Icon size="large">
                        <FiTruck />
                      </Icon>
                    </FlexKid>
                  </Flex>
                </Block>
              </CardLink>
            </Doorman>
            <Doorman type="admin">
              <CardLink to="/innstillinger/avgifter">
                <Block flex>
                  <Flex flex={1}>
                    <FlexKid flex={1}>
                      <Text element="h3" variant="title">
                        Avgifter
                      </Text>
                      <Text variant="body3">Administrer avgifter</Text>
                    </FlexKid>
                    <FlexKid spaceLeft>
                      <Icon size="large">
                        <AiOutlineFileText />
                      </Icon>
                    </FlexKid>
                  </Flex>
                </Block>
              </CardLink>
            </Doorman>
            <Doorman type="admin">
              <CardLink to="/innstillinger/b2b" stretch>
                <Block flex>
                  <Flex flex={1}>
                    <FlexKid flex={1}>
                      <Text element="h3" variant="title">
                        B2B
                      </Text>
                      <Text variant="body3">
                        Administrer B2B-funksjonalitet
                      </Text>
                    </FlexKid>
                    <FlexKid spaceLeft>
                      <Icon size="large">
                        <MdBusiness />
                      </Icon>
                    </FlexKid>
                  </Flex>
                </Block>
              </CardLink>
            </Doorman>
            <Doorman type="admin">
              <CardLink to="/innstillinger/abonnement" stretch>
                <Block flex>
                  <Flex flex={1}>
                    <FlexKid flex={1}>
                      <Text element="h3" variant="title">
                        Abonnement
                      </Text>
                      <Text variant="body3">Administrer abonnement</Text>
                    </FlexKid>
                    <FlexKid spaceLeft>
                      <Icon size="large">
                        <GiReceiveMoney />
                      </Icon>
                    </FlexKid>
                  </Flex>
                </Block>
              </CardLink>
            </Doorman>
            <Doorman type="contributor">
              <CardLink to="/innstillinger/delbetaling" stretch>
                <Block flex>
                  <Flex flex={1}>
                    <FlexKid flex={1}>
                      <Text element="h3" variant="title">
                        Delbetaling
                      </Text>
                      <Text variant="body3">Administrer delbetaling</Text>
                    </FlexKid>
                    <FlexKid spaceLeft>
                      <Icon size="large">
                        <AiOutlinePartition />
                      </Icon>
                    </FlexKid>
                  </Flex>
                </Block>
              </CardLink>
            </Doorman>
            <Doorman type="admin">
              <CardLink to="/innstillinger/epost-tekster">
                <Block flex>
                  <Flex flex={1}>
                    <FlexKid flex={1}>
                      <Text element="h3" variant="title">
                        Tekster til utsendelser
                      </Text>
                      <Text variant="body3">
                        Rediger utgående e-poster-tekster som for eksempel
                        ordrekvittering
                      </Text>
                    </FlexKid>
                    <FlexKid spaceLeft>
                      <Icon size="large">
                        <AiOutlineMail />
                      </Icon>
                    </FlexKid>
                  </Flex>
                </Block>
              </CardLink>
            </Doorman>
            <Doorman type="admin">
              <CardLink to="/innstillinger/epostmaler">
                <Block flex>
                  <Flex flex={1}>
                    <FlexKid flex={1}>
                      <Text element="h3" variant="title">
                        E-postmaler
                      </Text>
                      <Text variant="body3">
                        Velg mellom ulike e-postmaler for utsendelser
                      </Text>
                    </FlexKid>
                    <FlexKid spaceLeft>
                      <Icon size="large">
                        <AiOutlineMail />
                      </Icon>
                    </FlexKid>
                  </Flex>
                </Block>
              </CardLink>
            </Doorman>
            {site.departmentsEnabled && (
              <Doorman type="admin">
                <CardLink to="/innstillinger/kataloger">
                  <Block flex>
                    <Flex flex={1}>
                      <FlexKid flex={1}>
                        <Text element="h3" variant="title">
                          Produktkataloger
                        </Text>
                        <Text variant="body3">
                          Generelle innstillinger for produktkataloger
                        </Text>
                      </FlexKid>
                      <FlexKid spaceLeft>
                        <Icon size="large">
                          <AiOutlineBook />
                        </Icon>
                      </FlexKid>
                    </Flex>
                  </Block>
                </CardLink>
              </Doorman>
            )}
          </Grid>
        </Section>
      )}
      <Section>
        <Block hugTop hugBottom>
          <Text element="h2" variant="subheading" gutterBottom>
            Bruker
          </Text>
        </Block>
        <Grid gap="medium" columns={3}>
          <CardLink to="/innstillinger/bruker">
            <Block flex>
              <Flex flex={1}>
                <FlexKid flex={1}>
                  <Text element="h3" variant="title">
                    Bruker
                  </Text>
                  <Text variant="body3">
                    Endre dashbordtemaet eller logg ut
                  </Text>
                </FlexKid>
                <FlexKid spaceLeft>
                  <Avatar image={user.data.profileImage || fallbackImage} />
                </FlexKid>
              </Flex>
            </Block>
          </CardLink>
          <CardLink
            to="#"
            onClick={(e) => {
              e.preventDefault();

              if (window.confirm("Er du sikker på at du vil bytte nettside?")) {
                siteSelection.clearSite();
              }
            }}
          >
            <Block flex>
              <Flex flex={1}>
                <FlexKid flex={1}>
                  <Text element="h3" variant="title">
                    Bytt nettside
                  </Text>
                  <Text variant="body3">
                    Dersom du administrerer flere nettsider kan du bytte mellom
                    dem her
                  </Text>
                </FlexKid>
                <FlexKid spaceLeft>
                  <Avatar
                    contain
                    square
                    image={
                      site.logo && typeof site.logo !== "string"
                        ? site.logo.secure_url
                        : undefined
                    }
                    fallback={site.displayName || site.name}
                  />
                </FlexKid>
              </Flex>
            </Block>
          </CardLink>
        </Grid>
      </Section>
    </Fader>
  );
};

export default SettingsNavigation;
