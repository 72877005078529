import { useState, useEffect, useContext } from "react";
import api, { ApiOpts } from "../util/api";
import { UserContext } from "../context/User";

interface Opts<T> extends ApiOpts {
  initialData: T;
  fetchOnMount?: boolean;
  askBeforeFetch?: string;
  onSuccess?: (resource: T) => any;
}

interface ExtendedApiOpts extends ApiOpts {
  askBeforeFetch?: string;
  onSuccess?: (resource: any) => any;
}

interface UseApiInterface<T> {
  resource: T;
  loading: boolean;
  fetch: Request;
  hasFetched: boolean;
}

export type Request = (fetchOpts?: ExtendedApiOpts) => Promise<void>;

function useApi<T>(opts: Opts<T>): UseApiInterface<T> {
  const user = useContext(UserContext);
  const [data, setData] = useState(opts.initialData);
  const [hasFetched, setHasFetched] = useState(false);
  const [loading, setLoading] = useState(false);

  async function fetchData(fetchOpts?: ExtendedApiOpts) {
    const optsToUse = fetchOpts || opts;

    if (!optsToUse.askBeforeFetch || window.confirm(optsToUse.askBeforeFetch)) {
      setLoading(true);
      setHasFetched(true);

      try {
        const response = await api<T>(optsToUse);

        setLoading(false);
        setData(response as T);

        if (fetchOpts && typeof fetchOpts.onSuccess === "function") {
          fetchOpts.onSuccess(response as T);
        }

        if (typeof opts.onSuccess === "function") {
          opts.onSuccess(response as T);
        }
      } catch (error) {
        setLoading(false);

        if ("message" in error && error.message === "notLoggedIn") {
          user.logOut();
        } else {
          console.error(error);
        }
      }
    }
  }

  useEffect(() => {
    if (opts.fetchOnMount) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    resource: data,
    loading,
    fetch: fetchData,
    hasFetched
  };
}

export interface SearchOpts<T> extends ApiOpts {
  initialResults?: T[];
  fetchOnMount?: boolean;
  limit?: number;
  reverse?: boolean;
  onInitialFetch?: () => any;
  onSuccess?: (results: T[], all: T[]) => any;
}

interface SearchApiOpts extends ApiOpts {
  paginate?: boolean;
}

export interface SearchInterface<T> {
  results: T[];
  loading: boolean;
  hasMore: boolean;
  search: SearchRequest;
  hasSearched: boolean;
  setResults: (results: T[]) => void;
}

export type SearchRequest = (searchOpts: SearchApiOpts) => Promise<void>;

const LIMIT = 20;

function useSearch<T>(opts: SearchOpts<T>): SearchInterface<T> {
  const user = useContext(UserContext);
  const [results, setResults] = useState(opts.initialResults || []);
  const [hasMore, setHasMore] = useState(true);
  const [hasFetched, setHasFetched] = useState(false);
  const [loading, setLoading] = useState(opts.fetchOnMount || false);

  async function fetchData(searchOpts: SearchApiOpts) {
    let skip = 0;

    setHasMore(false);
    setLoading(true);

    try {
      if (searchOpts.paginate) {
        skip = results.length;
      }

      const limit = opts.limit || LIMIT;

      const response = (await api<T[]>({
        ...searchOpts,
        queryParams: { ...(searchOpts.queryParams || {}), skip, limit }
      })) as T[];

      setLoading(false);
      setHasFetched(true);

      let updatedResults: T[] = [];

      if (opts.reverse) {
        updatedResults = searchOpts.paginate
          ? [...response.reverse(), ...results]
          : response.reverse();
      } else {
        updatedResults = searchOpts.paginate
          ? [...results, ...response]
          : response;
      }

      setResults(updatedResults);

      if (Array.isArray(response) && response.length < limit) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }

      if (!searchOpts.paginate && typeof opts.onInitialFetch === "function") {
        opts.onInitialFetch();
      }

      if (typeof opts.onSuccess === "function") {
        opts.onSuccess(response, updatedResults);
      }
    } catch (error) {
      setLoading(false);

      if ("message" in error && error.message === "notLoggedIn") {
        user.logOut();
      } else {
        console.error(error);
      }
    }
  }

  useEffect(() => {
    if (opts.fetchOnMount) {
      fetchData(opts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    results,
    loading,
    hasMore,
    search: fetchData,
    hasSearched: hasFetched,
    setResults
  };
}

export { useApi, useSearch };
