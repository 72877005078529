import { ReactNode } from "react";
import { UsePublisherInterface } from "../hooks/usePublisher";
import Field from "../style-guide/Inputs/Field";
import createInputField, { CreateInputFieldOpts } from "./createInputField";

export interface Field extends Omit<CreateInputFieldOpts, "key" | "onChange"> {
  path?: string;
  autosave?: boolean;
  setField?: (value: any) => any;
  onBlur?: () => any;
}

function createPublisherInputField(
  publisher: UsePublisherInterface<any>,
  field: Field
): ReactNode {
  if (!field.path) {
    throw new Error("Missing path");
  }

  const value = field.value || publisher.form.getValue(field.path);
  const placeholder = field.placeholder;
  const autoSave = field.autosave && !publisher.isNew;

  const setField =
    typeof field.setField === "function"
      ? (val: any) => field.setField!(val)
      : (val: any, debounce?: boolean) =>
          publisher.form.setField({
            path: field.path!,
            value: val,
            submit: autoSave,
            debounce
          });

  const onBlur =
    typeof field.onBlur === "function" ? () => field.onBlur!() : undefined;

  let debounce = true;

  switch (field.type) {
    case "ref":
    case "select":
    case "radio":
    case "boolean":
      debounce = false;

      break;
  }

  return createInputField({
    ...field,
    key: field.path,
    value,
    placeholder,
    readOnly: publisher.isRevision || field.readOnly,
    onChange: (value) => setField(value, debounce),
    onBlur
  });
}

export default createPublisherInputField;
