import React, { useCallback, useMemo, useState } from "react";
import moment from "moment";
import { RubicsCart } from "@ludens-reklame/rubics-sdk";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import { Customer, Order, Subscription } from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Section from "../../../style-guide/Section/Section";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import { CustomerChip } from "../../../components/Chip/Chip";
import Field from "../../../style-guide/Inputs/Field";
import createPublisherInputField from "../../../util/createPublisherInputField";
import { useApi } from "../../../hooks/useApi";
import useApiUrl from "../../../hooks/useApiUrl";
import Cart from "../../../components/Cart/Cart";
import { Button, ButtonList } from "../../../style-guide/Button/Button";
import api, { Service } from "../../../util/api";
import useNotifications from "../../../hooks/useNotifications";
import { useHistory } from "react-router-dom";

interface Props {
  publisher: UsePublisherInterface<Subscription>;
}

const General: React.FC<Props> = ({ publisher }) => {
  const subscription = publisher.form.data;
  const [creatingOrder, setCreatingOrder] = useState<boolean>(false);
  const notifications = useNotifications();
  const history = useHistory();
  const cartUrl = useApiUrl(["subscriptions", subscription._id, "cart"]);

  const cart = useApi<RubicsCart | null>({
    initialData: null,
    endpoint: cartUrl,
    fetchOnMount: true
  });

  const customer = useMemo<Customer | undefined>(() => {
    if (subscription.customer && typeof subscription.customer !== "string") {
      return subscription.customer;
    }

    return undefined;
  }, [subscription]);

  const createOrder = useCallback(async () => {
    if (creatingOrder) {
      return;
    }

    if (
      window.confirm(
        "Er du sikker på at du vil opprette en ordre på dette abonnementet?"
      )
    ) {
      try {
        setCreatingOrder(true);

        notifications.spawn({
          title: "Oppretter ordre…"
        });

        const order = await api<Order>({
          service: Service.Admin,
          endpoint: `subscriptions/${subscription._id}/orders`,
          method: "POST"
        });

        if (!("orderNumber" in order)) {
          throw order;
        }

        notifications.spawn({
          title: "Ordre opprettet"
        });

        history.push(`/butikk/ordrer/${order._id}`);
      } catch (error) {
        console.error(error);
      } finally {
        setCreatingOrder(false);
      }
    }
  }, [creatingOrder, subscription, notifications]);

  return (
    <Fader>
      <Flex align="flex-start">
        <FlexKid flex={5}>
          <Section hugTop>
            <Card>
              <Block>
                <Text element="h2" variant="title">
                  Produkter
                </Text>
              </Block>
              {cart.resource && <Cart data={cart.resource} />}
            </Card>
          </Section>
        </FlexKid>
        <FlexKid flex={2} spaceLeft>
          <Section hugTop>
            <Card>
              <Block>
                <Field hugTop hugBottom>
                  <Text>Opprettet</Text>
                  <Text variant="body3">
                    {moment(subscription.startedDate).format(
                      "D[.] MMMM Y [kl.] HH:mm"
                    )}
                  </Text>
                </Field>
                {subscription.active && (
                  <Field hugBottom>
                    <Text>Neste ordre</Text>
                    <Text variant="body3">
                      {moment(subscription.renewalDate).format(
                        "D[.] MMMM Y [kl.] HH:mm"
                      )}
                    </Text>
                  </Field>
                )}
                {createPublisherInputField(publisher, {
                  path: "active",
                  label: "Aktiv",
                  type: "boolean"
                })}
                {subscription.active && (
                  <Field hugBottom>
                    <ButtonList align="stretch">
                      <Button
                        outlined
                        onClick={createOrder}
                        disabled={creatingOrder}
                      >
                        {creatingOrder ? "Lager ny ordre…" : "Lag ny ordre"}
                      </Button>
                    </ButtonList>
                  </Field>
                )}
              </Block>
            </Card>
          </Section>
          {customer && (
            <Section hugTop>
              <Card>
                <Block>
                  <Section hugTop>
                    <Text element="h2" variant="title">
                      Kunde
                    </Text>
                  </Section>
                  {customer && (
                    <Field hugBottom>
                      <CustomerChip customer={customer} />
                    </Field>
                  )}
                </Block>
              </Card>
            </Section>
          )}
        </FlexKid>
      </Flex>
    </Fader>
  );
};

export default General;
