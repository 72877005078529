import React, { useEffect, useCallback } from "react";
import { MdErrorOutline } from "react-icons/md";
import useNotifications from "../hooks/useNotifications";

export const RUBICS_ERROR_EVENT_NAME = "rubicserror";

const NotificationEventHandler: React.FC = ({ children }) => {
  const { spawn } = useNotifications();

  const eventHandler = useCallback((e: Event) => {
    spawn({
      title: "Oida...",
      subtitle:
        "Noe galt skjedde! Vennligst prøv igjen eller oppdater nettleservinduet",
      //@ts-ignore TS doesn't support custom events for some reason
      errorMessage: e.detail instanceof Error ? e.detail.message : undefined,
      icon: <MdErrorOutline />,
      type: "error"
    });
  }, []);

  useEffect(() => {
    document.addEventListener(RUBICS_ERROR_EVENT_NAME, eventHandler);

    return () => {
      document.removeEventListener(RUBICS_ERROR_EVENT_NAME, eventHandler);
    };
  }, [eventHandler]);

  return <>{children}</>;
};

export default NotificationEventHandler;
