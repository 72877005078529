import React, { useState, useEffect, useCallback, useMemo } from "react";
import { AiOutlineGold, AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { useHotkeys } from "react-hotkeys-hook";
import {
  AppComponent,
  Component,
  TemplateComponent
} from "../../types/apiResponses";
import { FaGripLines } from "react-icons/fa";
import getIcon from "../../util/getIcon";
import createId from "../../util/createId";
import useForm, { PrefillFn } from "../../hooks/useForm";
import Frame from "../../util/Frame";
import Section from "../../style-guide/Section/Section";
import { Elements, Element } from "../../style-guide/Elements/Elements";
import { Dropper, Dragger } from "../DnD/DnD";
import SidebarPager from "../SidebarPager/SidebarPager";
import Putter from "../Putter/Putter";
import Text from "../../style-guide/Text/Text";
import { ButtonList, Button } from "../../style-guide/Button/Button";
import useRevisionId from "../../hooks/useRevisionId";
import Label from "../../style-guide/Inputs/Label";
import Ruler from "../../style-guide/Ruler/Ruler";
import getComponentDescription from "../../util/getComponentDescription";
import { RequestData } from "../../util/api";
import useComponents from "../../hooks/useComponents";
import useCopyPaste from "../../hooks/useCopyPaste";
import TextInput from "../../style-guide/Inputs/TextInput";
import EmptyState from "../../style-guide/EmptyState/EmptyState";

interface Props {
  endpoint: string;
  onClose: () => any;
  components: AppComponent[];
  prefillEndpoint?: string;
  prefillFn?: PrefillFn;
  onSubmit?: (resource: any) => any;
  parent?: string;
  onLoad?: (loading: boolean) => any;
  onNavigateComponent?: (exit?: boolean) => any;
  initialEditingSection?: TemplateComponent;
  initialEditingIndex?: number;
  queryParams?: RequestData;
  prefillQueryParams?: RequestData;
}

const RichContentEditor: React.FC<Props> = ({
  endpoint,
  prefillEndpoint,
  components,
  prefillFn,
  onSubmit,
  parent,
  onClose,
  onLoad,
  onNavigateComponent,
  initialEditingSection,
  initialEditingIndex,
  queryParams,
  prefillQueryParams
}) => {
  const revision = useRevisionId();
  const customComponents = useComponents();

  const [addingSection, setAddingSection] = useState(false);
  const [editingIndex, setEditingIndex] = useState(initialEditingIndex || -1);
  const [editingSection, setEditingSection] = useState(initialEditingSection);
  const [componentSearchQuery, setComponentSearchQuery] = useState<string>("");

  useEffect(() => {
    setEditingSection(initialEditingSection);
    setEditingIndex(
      typeof initialEditingIndex === "number" ? initialEditingIndex : -1
    );
  }, [initialEditingSection, initialEditingIndex]);

  const form = useForm<TemplateComponent>(
    {
      children: []
    },
    {
      endpoint: endpoint || "",
      method: "PATCH",
      prefillEndpoint,
      prefillFn,
      prefillQueryParams: revision
        ? {
            revision
          }
        : prefillQueryParams,
      onSuccess: onSubmit,
      replaceData: true,
      queryParams
    }
  );

  useEffect(() => {
    if (parent) {
      form.setField({
        path: "parent",
        value: parent
      });
    }
  }, [parent]);

  useEffect(() => {
    if (typeof onLoad === "function") {
      onLoad(form.submitting);
    }
  }, [onLoad, form.submitting]);

  const handleOnNavigateComponent = useCallback(
    (exit: boolean) => {
      if (typeof onNavigateComponent === "function") {
        onNavigateComponent(exit);
      }
    },
    [onNavigateComponent]
  );

  const themeComponent = useMemo<AppComponent | undefined>(() => {
    return !!editingSection
      ? components.find((c) => c.name === editingSection.component)
      : undefined;
  }, [editingSection, components]);

  const customComponent = useMemo<Component | undefined>(() => {
    return !!editingSection && editingSection.componentRef
      ? customComponents.components.find(
          (c) => c._id === editingSection.componentRef
        )
      : undefined;
  }, [editingSection, customComponents.components]);

  const sortedComponents = useMemo<(AppComponent | Component)[]>(() => {
    return [
      ...components.filter((c) => c.usableInRichContent),
      ...customComponents.components
    ].sort((a, b) => {
      const aTitle = "label" in a && a.label ? a.label : a.name;
      const bTitle = "label" in b && b.label ? b.label : b.name;

      if (aTitle < bTitle) {
        return -1;
      }

      if (aTitle > bTitle) {
        return 1;
      }

      return 0;
    });
  }, [components, customComponents.components]);

  const queriedComponents = useMemo<(AppComponent | Component)[]>(() => {
    if (componentSearchQuery.length > 0) {
      const query = componentSearchQuery.toLowerCase();

      return sortedComponents.filter((c) => {
        let title: string;

        if ("label" in c && c.label) {
          title = c.label;
        } else {
          title = c.name;
        }

        return title.toLowerCase().includes(query);
      });
    }

    return sortedComponents;
  }, [sortedComponents, componentSearchQuery]);

  const copyPaste = useCopyPaste({
    pathsToCopy: [{ path: "children", label: "Innhold" }],
    onPaste: (data) => {
      form.setData({
        data: {
          ...form.data,
          parent,
          children: ((data.children as TemplateComponent[]) || []).map((c) => {
            return { ...c, internalId: createId() };
          })
        },
        submit: true
      });
    }
  });

  useHotkeys(
    "c",
    () => {
      copyPaste.copy(form.data);
    },
    {},
    [form.data]
  );

  useHotkeys("v", () => {
    copyPaste.paste();
  });

  return (
    <>
      {!addingSection && !editingSection && (
        <SidebarPager onClose={onClose} disabled={!editingSection}>
          <Ruler />
          <Section>
            <Label>Seksjoner</Label>
            <Elements>
              <Dropper
                id="richContentComponents"
                onDragEnd={(r) => {
                  if (r.destination) {
                    const list = Array.from(
                      (form.data.children as TemplateComponent[]) || []
                    );

                    const [removed] = list.splice(r.source.index, 1);

                    list.splice(r.destination.index, 0, removed);
                    form.setField({
                      path: "children",
                      value: list,
                      submit: true
                    });
                  }
                }}
              >
                {((form.data.children as TemplateComponent[]) || []).map(
                  (c, k) => {
                    const themeComponent = !!c.component
                      ? components.find((tc) => tc.name === c.component)
                      : null;

                    const customComponent = !!c.componentRef
                      ? customComponents.components.find(
                          (cc) => cc._id === c.componentRef
                        )
                      : null;

                    return (
                      <Dragger
                        key={c.internalId || c._id}
                        id={c.internalId || c._id}
                        index={k}
                        isDragDisabled={!!revision}
                      >
                        <Element
                          icon={
                            c.componentRef ? (
                              <AiOutlineGold />
                            ) : themeComponent ? (
                              themeComponent.appIcon ? (
                                <img
                                  src={themeComponent.appIcon}
                                  alt={themeComponent.appName}
                                />
                              ) : (
                                getIcon(themeComponent.icon)
                              )
                            ) : undefined
                          }
                          rightIcon={!revision ? <FaGripLines /> : undefined}
                          biggerRightSpace
                          subText={
                            customComponent && customComponent.description
                              ? customComponent.description
                              : getComponentDescription(themeComponent)
                          }
                          onClick={(e) => {
                            e.preventDefault();

                            if (!!c.component) {
                              setEditingSection(c);
                              setEditingIndex(k);
                              handleOnNavigateComponent(false);
                            }

                            const iframe = new Frame("preview");
                            iframe.scrollTo(c.name);
                          }}
                        >
                          {customComponent
                            ? customComponent.name
                            : themeComponent
                            ? themeComponent.label || themeComponent.name
                            : c.component}
                        </Element>
                      </Dragger>
                    );
                  }
                )}
              </Dropper>
              {!revision && (
                <Element
                  icon={<AiOutlinePlus />}
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setAddingSection(true);
                    handleOnNavigateComponent(false);
                    setComponentSearchQuery("");
                  }}
                >
                  Legg til innhold
                </Element>
              )}
            </Elements>
          </Section>
        </SidebarPager>
      )}
      {addingSection && (
        <SidebarPager
          onClose={() => {
            setAddingSection(false);
            handleOnNavigateComponent(true);
          }}
        >
          <Section>
            <Text element="h2" variant="title">
              Legg til innhold
            </Text>
          </Section>
          <Section>
            <TextInput
              icon={<AiOutlineSearch />}
              placeholder="Søk etter komponenter"
              aria-label="Søk etter komponenter"
              value={componentSearchQuery}
              onChange={(e) => setComponentSearchQuery(e.target.value)}
              autoFocus
            />
          </Section>
          <Section>
            <Elements>
              {queriedComponents.length === 0 ? (
                <EmptyState title="Ingen komponenter funnet" smaller />
              ) : (
                queriedComponents.map((c) => (
                  <Element
                    key={c.name}
                    icon={
                      "site" in c ? (
                        <AiOutlineGold />
                      ) : c.appIcon ? (
                        <img src={c.appIcon} alt={c.appName} />
                      ) : (
                        getIcon(c.icon)
                      )
                    }
                    rightIcon={<AiOutlinePlus />}
                    minHeight
                    subText={getComponentDescription(c)}
                    href="#"
                    biggerRightSpace
                    onClick={(e) => {
                      e.preventDefault();
                      const id = createId();

                      const component = {
                        _id: id,
                        internalId: id,
                        name: id,
                        component: c.name,
                        props: {},
                        app: "app" in c && c.app ? c.app : undefined,
                        componentRef: "site" in c ? c._id : undefined
                      };

                      const components =
                        (form.data.children as TemplateComponent[]) || [];

                      form.setField({
                        path: "children",
                        value: [
                          ...components,
                          component
                        ] as TemplateComponent[],
                        submit: true,
                        onSuccess: () => {
                          setEditingSection(component);
                          setEditingIndex(components.length);
                          handleOnNavigateComponent(false);

                          const iframe = new Frame("preview");
                          iframe.reload();

                          window.setTimeout(() => {
                            iframe.scrollTo(component.name);
                          }, 750);
                        }
                      });

                      setAddingSection(false);
                    }}
                  >
                    {"label" in c && c.label ? c.label : c.name}
                  </Element>
                ))
              )}
            </Elements>
          </Section>
        </SidebarPager>
      )}
      {editingSection && (
        <SidebarPager
          onClose={() => {
            setEditingSection(undefined);
            setEditingIndex(-1);
            handleOnNavigateComponent(true);
          }}
        >
          {!!customComponent ? (
            <Section>
              <Text element="h2" variant="title">
                {customComponent.name}
              </Text>
              <Text variant="body3">
                {customComponent.description || "Mangler beskrivelse"}
              </Text>
            </Section>
          ) : (
            !!themeComponent && (
              <>
                <Section>
                  <Text element="h2" variant="title">
                    {themeComponent.label || themeComponent.name}
                  </Text>
                  <Text variant="body3">
                    {getComponentDescription(themeComponent)}
                  </Text>
                </Section>
                {Array.isArray(themeComponent.props) && (
                  <Section>
                    {editingIndex !== -1 && (
                      <Putter
                        props={themeComponent.props}
                        form={form}
                        pathPrefix={`children.${editingIndex}.props`}
                        previewPathPrefix={`children.${editingIndex}.propsPopulated`}
                        onSubmit={onSubmit}
                      />
                    )}
                  </Section>
                )}
              </>
            )
          )}
          {!revision && (
            <Section>
              <ButtonList align="stretch">
                <Button
                  type="button"
                  alternate
                  onClick={() => {
                    if (
                      window.confirm(
                        "Er du sikker på at du vil slette innholdet?"
                      )
                    ) {
                      form.submit(undefined, {
                        children: (
                          (form.data.children as TemplateComponent[]) || []
                        ).filter((c) => c._id !== editingSection._id)
                      });

                      setEditingSection(undefined);
                      setEditingIndex(-1);
                      handleOnNavigateComponent(true);
                    }
                  }}
                >
                  Slett seksjon
                </Button>
              </ButtonList>
            </Section>
          )}
        </SidebarPager>
      )}
    </>
  );
};

export default RichContentEditor;
