import React, { useEffect, useCallback } from "react";
import { RouteComponentProps, Prompt } from "react-router-dom";
import {
  DraggableStateSnapshot,
  DraggableProvidedDragHandleProps
} from "react-beautiful-dnd";
import { MdDragHandle } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import useBreadcrumbs from "../../../hooks/useBreadcrumbs";
import usePublisher from "../../../hooks/usePublisher";
import {
  ShippingZone as IShippingZone,
  ShippingMethod as IShippingMethod,
  ShippingMethodClassDefinition,
  ShippingClass
} from "../../../types/apiResponses";
import Form from "../../../style-guide/Inputs/Form";
import { LEAVE_PAGE_MESSAGE } from "../../../constants/general";
import Fader from "../../../style-guide/Fader/Fader";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import Crumb from "../../../components/Crumb/Crumb";
import PublisherHelpers from "../../../util/PublisherHelpers";
import Section from "../../../style-guide/Section/Section";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import ActionBar from "../../../components/ActionBar/ActionBar";
import Card from "../../../style-guide/Card/Card";
import createPublisherInputField from "../../../util/createPublisherInputField";
import { Button, ButtonList } from "../../../style-guide/Button/Button";
import {
  InlineEditHead,
  InlineEditColumn
} from "../../../style-guide/InlineEdit/InlineEdit";
import InlineEdit from "../../../components/InlineEdit/InlineEdit";
import createId from "../../../util/createId";
import createInputField from "../../../util/createInputField";
import {
  ShippingType,
  TaxStatus,
  ShippingClassCostCalculation
} from "../../../constants/api";
import {
  shippingType,
  shippingClassCostCalculation
} from "../../../constants/localizations";
import localize from "../../../util/localize";
import { Table, Tr, Th, Td } from "../../../style-guide/Table/Table";
import { currencyFormat } from "../../../util/intl";
import CrudModal, {
  createCrudModalField
} from "../../../components/CrudModal/CrudModal";
import useSite from "../../../hooks/useSite";
import { Dropper, Dragger } from "../../../components/DnD/DnD";
import Ruler from "../../../style-guide/Ruler/Ruler";

interface Props
  extends RouteComponentProps<{
    shippingZone: string;
  }> {}

const ShippingZone: React.FC<Props> = ({ match }) => {
  const shippingZoneId = match.params.shippingZone;

  const site = useSite();
  const breadcrumbs = useBreadcrumbs();

  const publisher = usePublisher<IShippingZone>({
    id: shippingZoneId,
    baseUrl: ["shipping", "zones"],
    baseDashboardUrl: "/innstillinger/frakt/soner",
    initialData: {
      methods: []
    },
    pathsToCopy: [
      {
        path: "name",
        label: "Navn"
      },
      {
        path: "methods",
        label: "Metoder"
      }
    ]
  });

  useEffect(() => {
    breadcrumbs.swapEntry(shippingZoneId, publisher.form.data.name);
  }, [breadcrumbs, publisher.form.data, shippingZoneId]);

  const form = publisher.form;
  const zone = publisher.form.data;

  return (
    <Form onSubmit={form.submit}>
      <Prompt when={form.hasMadeChanges} message={() => LEAVE_PAGE_MESSAGE} />
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/innstillinger/frakt/soner">Fraktsoner</Crumb>
            {PublisherHelpers.getResourceTitle(
              publisher,
              "name",
              "Ny fraktsone"
            )}
          </Text>
        </Block>
        <Section>
          <Card>
            <Block>
              <Section hugTop>
                <Text element="h2" variant="title">
                  Generelt
                </Text>
              </Section>
              {createPublisherInputField(publisher, {
                path: "name",
                label: "Navn",
                type: "text",
                required: true
              })}
            </Block>
          </Card>
        </Section>
        <Section>
          <Card>
            <Block>
              <Flex>
                <FlexKid flex={1}>
                  <Text element="h2" variant="title">
                    Metoder
                  </Text>
                </FlexKid>
                {!publisher.isRevision && (
                  <FlexKid spaceLeft>
                    <Button
                      type="button"
                      outlined
                      onClick={() => {
                        form.setField({
                          path: "methods",
                          value: [
                            {
                              id: createId(),
                              type: ShippingType.FlatRate,
                              name: "",
                              taxStatus: TaxStatus.Taxable,
                              classes: [],
                              costCalculation:
                                ShippingClassCostCalculation.Class,
                              default: false
                            },
                            ...(zone.methods || [])
                          ]
                        });
                      }}
                    >
                      Ny fraktmetode
                    </Button>
                  </FlexKid>
                )}
              </Flex>
            </Block>
            <InlineEditHead>
              <InlineEditColumn width="350px">Navn</InlineEditColumn>
              <InlineEditColumn width="230px">Type</InlineEditColumn>
            </InlineEditHead>
            {zone.methods.length > 0 ? (
              <Dropper
                id="shippingMethods"
                onDragEnd={(r) => {
                  if (r.destination) {
                    const list = Array.from(form.data.methods || []);
                    const [removed] = list.splice(r.source.index, 1);

                    list.splice(r.destination.index, 0, removed);

                    form.setField({
                      path: "methods",
                      value: list
                    });
                  }
                }}
              >
                {zone.methods.map((m, k) => (
                  <Dragger
                    //@ts-ignore
                    key={m._id || m.id}
                    //@ts-ignore
                    id={m._id || m.id}
                    index={k}
                    delegateDragHandle
                  >
                    <ShippingMethod
                      method={m}
                      shippingClasses={site.shippingClasses || []}
                      selectedShippingClasses={m.classes}
                      isNew={!m._id}
                      onChange={(data) =>
                        form.setField({
                          path: "methods",
                          value: (zone.methods || []).map((mm, kk) => {
                            if (kk === k) {
                              return data;
                            }

                            return mm;
                          })
                        })
                      }
                      onDelete={() => {
                        if (
                          window.confirm("Er du sikker på at du vil slette?")
                        ) {
                          form.setField({
                            path: "methods",
                            value: (zone.methods || []).filter(
                              (_, kk) => kk !== k
                            )
                          });
                        }
                      }}
                    />
                  </Dragger>
                ))}
              </Dropper>
            ) : (
              <>
                <Ruler />
                <Block hugTop>
                  <Text variant="subheading">Ingen fraktmetoder funnet!</Text>
                </Block>
              </>
            )}
          </Card>
        </Section>
      </Fader>
      <ActionBar publisher={publisher} destroy />
    </Form>
  );
};

interface ShippingMethodProps {
  shippingClasses: ShippingClass[];
  selectedShippingClasses: ShippingMethodClassDefinition[];
  method: IShippingMethod;
  isNew: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  dragState?: DraggableStateSnapshot;
  onChange: (method: IShippingMethod) => any;
  onDelete: () => any;
}

const ShippingMethod: React.FC<ShippingMethodProps> = ({
  shippingClasses,
  selectedShippingClasses,
  method,
  isNew,
  onChange,
  onDelete,
  dragHandleProps,
  dragState
}) => {
  const { spawnModal } = useModal();

  const addClassModal = useCallback(
    (classDef?: ShippingMethodClassDefinition, key?: number) => {
      spawnModal(
        <CrudModal
          title={classDef ? "Endre fraktklasse" : "Legg til fraktklasse"}
          initialData={classDef || { internalId: createId() }}
          onSubmit={(data) => {
            if (classDef) {
              onChange({
                ...method,
                classes: method.classes.map((c, k) => {
                  if (k === key) {
                    return data;
                  }

                  return c;
                })
              });
            } else
              onChange({
                ...method,
                classes: [...method.classes, data]
              });
          }}
          fields={[
            {
              key: "class",
              render: (payload) =>
                createCrudModalField({
                  payload,
                  key: "class",
                  type: "select",
                  label: "Fraktklasse",
                  required: true,
                  options: shippingClasses
                    .filter(
                      (c) =>
                        c._id === payload.data.class ||
                        !selectedShippingClasses.some(
                          (cc) => cc.class === c._id
                        )
                    )
                    .map((c) => ({
                      value: c._id,
                      label: c.name
                    }))
                })
            },
            {
              key: "cost",
              render: (payload) =>
                createCrudModalField({
                  payload,
                  key: "cost",
                  type: "number",
                  label: "Pris",
                  value: payload.data.cost > 0 ? payload.data.cost : undefined,
                  onChange: (value) =>
                    payload.setField(
                      "cost",
                      value.length > 0 ? parseFloat(value) : 0
                    )
                })
            }
          ]}
        />
      );
    },
    [method, shippingClasses]
  );

  return (
    <InlineEdit
      expanded={isNew}
      alignItems="flex-start"
      background={
        dragState && dragState.isDragging ? "backgroundVariant" : undefined
      }
      actions={[
        <Button
          type="button"
          aria-label="Slett"
          circular
          outlined
          smaller
          onClick={onDelete}
        >
          <AiOutlineDelete />
        </Button>,
        <Button
          type="button"
          aria-label="Endre rekkefølge"
          outlined
          circular
          smaller
          {...dragHandleProps}
        >
          <MdDragHandle />
        </Button>
      ]}
      headerColumns={[
        {
          width: "350px",
          alignSelf: "center",
          node: <Text>{method.name || "Mangler navn"}</Text>
        },
        {
          width: "230px",
          alignSelf: "center",
          node: (
            <Text variant="body3">{localize(shippingType, method.type)}</Text>
          )
        }
      ]}
    >
      <Block>
        <Flex align="top">
          <FlexKid flex={1}>
            {createInputField({
              key: "name",
              type: "text",
              label: "Navn",
              value: method.name,
              required: true,
              hugTop: true,
              autoFocus: isNew,
              onChange: (value) => {
                onChange({
                  ...method,
                  name: value
                });
              }
            })}
            {createInputField({
              key: "type",
              type: "radio",
              label: "Type",
              value: method.type,
              required: true,
              options: Object.keys(shippingType).map((k) => ({
                value: k,
                label: shippingType[k]
              })),
              onChange: (value) => {
                onChange({
                  ...method,
                  type: value
                });
              }
            })}
            {createInputField({
              key: "default",
              type: "boolean",
              label: "Er standard fraktmetode?",
              value: method.default,
              required: true,
              onChange: (value) => {
                onChange({
                  ...method,
                  default: value
                });
              }
            })}
          </FlexKid>
          <FlexKid flex={1} spaceLeft>
            {createInputField({
              key: "cost",
              type: "number",
              label: "Pris",
              value: method.cost,
              hugTop: true,
              onChange: (value) => {
                onChange({
                  ...method,
                  cost: value
                });
              }
            })}
            {createInputField({
              key: "costCalculation",
              type: "radio",
              label: "Priskalkulasjon",
              value: method.costCalculation,
              required: true,
              options: Object.keys(shippingClassCostCalculation).map((k) => ({
                value: k,
                label: shippingClassCostCalculation[k]
              })),
              onChange: (value) => {
                onChange({
                  ...method,
                  costCalculation: value
                });
              }
            })}
          </FlexKid>
        </Flex>
        <Section hugBottom tight>
          <Section tight>
            <Flex>
              <FlexKid flex={1}>
                <Text>Fraktklasser</Text>
              </FlexKid>
              <FlexKid>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    addClassModal();
                  }}
                >
                  + Legg til fraktklasse
                </a>
              </FlexKid>
            </Flex>
          </Section>
          <Card outlined>
            <Table>
              <thead>
                <Tr>
                  <Th>Fraktklasse</Th>
                  <Th align="right">Pris</Th>
                  <Th align="right">Handlinger</Th>
                </Tr>
              </thead>
              <tbody>
                {method.classes.length > 0 ? (
                  method.classes.map((c, k) => {
                    const shippingClass = shippingClasses.find(
                      (cc) => cc._id === c.class
                    );

                    return (
                      //@ts-ignore
                      <Tr key={c._id || c.internalId}>
                        <Td verticalAlign="middle">
                          {shippingClass?.name || c.class}
                        </Td>
                        <Td verticalAlign="middle" align="right">
                          <Text variant="body3" align="right">
                            {currencyFormat.format(c.cost || 0)}
                          </Text>
                        </Td>
                        <Td verticalAlign="middle" align="right">
                          <ButtonList align="right">
                            <Button
                              type="button"
                              outlined
                              onClick={() => addClassModal(c, k)}
                            >
                              Rediger
                            </Button>
                            <Button
                              type="button"
                              outlined
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Er du sikker på at du vil slette?"
                                  )
                                ) {
                                  onChange({
                                    ...method,
                                    classes: method.classes.filter(
                                      (_, kk) => kk !== k
                                    )
                                  });
                                }
                              }}
                            >
                              Slett
                            </Button>
                          </ButtonList>
                        </Td>
                      </Tr>
                    );
                  })
                ) : (
                  <Tr>
                    <Td colSpan={3}>
                      <Text variant="subheading">
                        Ingen fraktklasser lagt til
                      </Text>
                    </Td>
                  </Tr>
                )}
              </tbody>
            </Table>
          </Card>
        </Section>
      </Block>
    </InlineEdit>
  );
};

export default ShippingZone;
