import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { MdBusiness } from "react-icons/md";
import { Image } from "@ludens-reklame/rubics-sdk";
import { Company } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import { ButtonLink } from "../../style-guide/Button/Button";
import Tabler from "../../components/Tabler/Tabler";
import { currencyFormat, numberFormat } from "../../util/intl";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import fallbackImage from "../../util/images/empty_preview.png";

const Companies: React.FC = () => {
  return (
    <Tabler
      title="Bedrifter"
      queryField="name"
      queryLabel="Søk etter bedrifter"
      crumb={{
        label: "B2B",
        path: "/b2b"
      }}
      actions={<ButtonLink to="bedrifter/opprett">Opprett bedrift</ButtonLink>}
      emptyState={{
        title: "Ingen bedrifter enda",
        icon: <MdBusiness />,
        text: "Ved å opprette bedrifter og tilegne dem ansatte, vil du kunne tilby utvalgte produktkataloger med kundespesifikke priser.",
        action: <ButtonLink to="bedrifter/opprett">Opprett bedrift</ButtonLink>
      }}
      url={["companies"]}
      columns={[
        {
          Header: "Bedrift",
          accessor: "title"
        },
        {
          Header: "Produktkatalog",
          accessor: "productCatalog"
        },
        {
          Header: "Innholdskatalog",
          accessor: "contentCatalog"
        },
        {
          Header: "Ordrer",
          accessor: "orders",
          align: "right"
        },
        {
          Header: "Totalt",
          accessor: "ordersSum",
          align: "right"
        },
        {
          Header: "Gjennomsnittsum per ordre",
          accessor: "orderAverage",
          align: "right"
        }
      ]}
      createRow={(company: Company) => ({
        ...company,
        title: <TitleRow company={company} />,
        productCatalog: (
          <Text variant="body3">
            {company.productCatalog &&
            typeof company.productCatalog !== "string" ? (
              <Link
                to={`/butikk/produktkataloger/${company.productCatalog._id}`}
              >
                {company.productCatalog.name}
              </Link>
            ) : (
              "Standard"
            )}
          </Text>
        ),
        contentCatalog: (
          <Text variant="body3">
            {company.contentCatalog &&
            typeof company.contentCatalog !== "string" ? (
              <Link
                to={`/innhold/innholdskataloger/${company.contentCatalog._id}`}
              >
                {company.contentCatalog.name}
              </Link>
            ) : (
              "Standard"
            )}
          </Text>
        ),
        orders: (
          <Text variant="body3" align="right">
            {numberFormat.format(company.orders)}
          </Text>
        ),
        ordersSum: (
          <Text variant="body3" align="right">
            {currencyFormat.format(company.ordersSum)}
          </Text>
        ),
        orderAverage: (
          <Text variant="body3" align="right">
            {company.orders > 0 && company.ordersSum > 0
              ? currencyFormat.format(company.ordersSum / company.orders)
              : currencyFormat.format(0)}
          </Text>
        )
      })}
    />
  );
};

interface RowProps {
  company: Company;
}

const TitleRow: React.FC<RowProps> = ({ company }) => {
  const logoUrl = useMemo<string>(() => {
    if (company.logo && typeof company.logo !== "string") {
      return Image.createUrl({
        image: {
          url: company.logo.secure_url,
          alt: company.name
        },
        transforms: "w_108,h_72,c_pad"
      });
    }

    return fallbackImage;
  }, [company]);

  return (
    <Flex>
      <FlexKid centerContent>
        <img width="54px" height="36px" src={logoUrl} alt={company.name} />
      </FlexKid>
      <FlexKid spaceLeft tight>
        <Text variant="body2">
          <Link to={`/b2b/bedrifter/${company._id}`}>{company.name}</Link>
        </Text>
        <Text variant="body3">{company.teams.length} avdelinger</Text>
      </FlexKid>
    </Flex>
  );
};

export default Companies;
