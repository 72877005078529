import React, { useMemo } from "react";
import styled from "styled-components";
import { ADMIN_ENDPOINT } from "@ludens-reklame/rubics-sdk";
import Fader from "../../style-guide/Fader/Fader";
import Text from "../../style-guide/Text/Text";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import Card from "../../style-guide/Card/Card";
import Block from "../../style-guide/Block/Block";
import Section from "../../style-guide/Section/Section";
import { Table, Tr, Th, Td } from "../../style-guide/Table/Table";
import { useSearch } from "../../hooks/useApi";
import { App } from "../../types/apiResponses";
import InfiniteScroll from "react-infinite-scroller";
import BusyBoy from "../../components/BusyBoy/BusyBoy";
import fallbackImage from "../../util/images/empty_preview.png";
import {
  ButtonList,
  ButtonExternalLink
} from "../../style-guide/Button/Button";
import EmptyState from "../../style-guide/EmptyState/EmptyState";
import Doorman from "../../components/Doorman/Doorman";
import getBaseApiUrl from "../../util/getBaseApiUrl";

//TODO: Replace this whole file with a proper marketplace

const Logo = styled.img`
  display: block;
  width: 50px;
`;

const List: React.FC = () => {
  const baseApiUrl = useMemo<string>(
    () => `${getBaseApiUrl()}/${ADMIN_ENDPOINT}`,
    []
  );

  const searchOpts = {
    endpoint: "apps"
  };

  const search = useSearch<App>(searchOpts);

  return (
    <Fader>
      <Block hugTop>
        <Text element="h1" variant="display3">
          Apper
        </Text>
      </Block>
      <Section hugTop>
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            search.search({
              ...searchOpts,
              paginate: true
            });
          }}
          hasMore={search.hasMore}
        >
          <BusyBoy busy={search.loading}>
            {search.hasSearched && search.results.length > 0 ? (
              <Card>
                <Table>
                  <thead>
                    <Tr>
                      <Th>Apper</Th>
                      <Doorman type="admin">
                        <Th align="right">Handlinger</Th>
                      </Doorman>
                    </Tr>
                  </thead>
                  <tbody>
                    {search.results.map((a) => (
                      <Tr key={a._id}>
                        <Td>
                          <Flex>
                            <FlexKid>
                              <Logo
                                src={a.logo || fallbackImage}
                                alt={a.name}
                              />
                            </FlexKid>
                            <FlexKid flex={1} spaceLeft>
                              <Text variant="body2">{a.name}</Text>
                              <Text variant="body3">
                                {a.description || "Mangler beskrivelse"}
                              </Text>
                            </FlexKid>
                          </Flex>
                        </Td>
                        <Doorman type="admin">
                          <Td align="right">
                            <ButtonList align="right">
                              <ButtonExternalLink
                                outlined
                                disabled={a.installed}
                                onClick={(e) => {
                                  if (
                                    !window.confirm(
                                      `Er du sikker på at du vil installere ${a.name}?`
                                    )
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                href={`${baseApiUrl}/apps/${a.identifier}/install`}
                              >
                                {a.installed ? "Installert" : "Installer"}
                              </ButtonExternalLink>
                            </ButtonList>
                          </Td>
                        </Doorman>
                      </Tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            ) : (
              <EmptyState
                title="Ingen apper funnet"
                text="Veldig rart at ikke det finnes noen apper, a gitt!"
              />
            )}
          </BusyBoy>
        </InfiniteScroll>
      </Section>
    </Fader>
  );
};

export default List;
