import React, { useMemo, useEffect } from "react";
import { RouteComponentProps, Switch, Route, Link } from "react-router-dom";
import {
  AiOutlineProfile,
  AiOutlineEdit,
  AiOutlineWarning,
  AiOutlineEye,
  AiOutlineDelete,
  AiOutlineBook
} from "react-icons/ai";
import { Image, STOREFRONT_ENDPOINT } from "@ludens-reklame/rubics-sdk";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import {
  Template,
  Page as IPage,
  PageCatalogEntry,
  Catalog
} from "../../types/apiResponses";
import useApiUrl from "../../hooks/useApiUrl";
import { useSearch } from "../../hooks/useApi";
import {
  CatalogType,
  EntityStatus,
  EntityVisibility,
  RevisionType
} from "../../constants/api";
import GooglePreview from "../../components/GooglePreview/GooglePreview";
import Text from "../../style-guide/Text/Text";
import Form from "../../style-guide/Inputs/Form";
import Fader from "../../style-guide/Fader/Fader";
import Block from "../../style-guide/Block/Block";
import usePublisher, { UsePublisherInterface } from "../../hooks/usePublisher";
import PublisherHelpers from "../../util/PublisherHelpers";
import {
  InlineActions,
  InlineActionLink
} from "../../style-guide/InlineActions/InlineActions";
import Section from "../../style-guide/Section/Section";
import ActionBar from "../../components/ActionBar/ActionBar";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import Card from "../../style-guide/Card/Card";
import createPublisherInputField from "../../util/createPublisherInputField";
import Ruler from "../../style-guide/Ruler/Ruler";
import Label from "../../style-guide/Inputs/Label";
import Field from "../../style-guide/Inputs/Field";
import useSite from "../../hooks/useSite";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import Crumb from "../../components/Crumb/Crumb";
import Tagger from "../../components/Tagger/Tagger";
import useCanonicalUrl from "../../hooks/useCanonicalUrl";
import cleanRefs from "../../util/cleanRefs";
import PreviewImage from "../../style-guide/PreviewImage/PreviewImage";
import { entityVisibility } from "../../constants/localizations";
import createId from "../../util/createId";
import Preview from "../../components/Preview/Preview";
import getBaseApiUrl from "../../util/getBaseApiUrl";
import createInputField from "../../util/createInputField";
import usePreviewToken from "../../hooks/usePreviewToken";
import useRevisionId from "../../hooks/useRevisionId";
import useExperimentId from "../../hooks/useExperimentId";
import ExpandableList from "../../components/ExpandableList/ExpandableList";
import { ButtonLink, Button } from "../../style-guide/Button/Button";
import { AddCatalogModal } from "../Products/SubViews/Catalogs";
import InlineEdit from "../../components/InlineEdit/InlineEdit";
import SEOHelper from "../../components/SEOHelper/SEOHelper";
import PageSelector from "../../components/PageSelector/PageSelector";

interface Props
  extends RouteComponentProps<{
    page: string;
  }> {
  isCategory?: boolean;
  isProductPage?: boolean;
}

const Page: React.FC<Props> = ({ match, isCategory, isProductPage }) => {
  const site = useSite();
  const breadcrumbs = useBreadcrumbs();
  const templatesUrl = useApiUrl(["templates"]);

  const templatesSearch = useSearch<Template>({
    endpoint: templatesUrl,
    fetchOnMount: true,
    queryParams: {
      draft: false,
      fetchInherited: true,
      removeOverrides: true
    },
    limit: 100
  });

  const pageMeta = useMemo(() => {
    if (isCategory) {
      return {
        singular: "kategori",
        plural: "kategorier"
      };
    }

    if (isProductPage) {
      return {
        singular: "produktside",
        plural: "produktsider"
      };
    }

    return {
      singular: "side",
      plural: "sider"
    };
  }, [isCategory, isProductPage]);

  const publisher = usePublisher<IPage>({
    id: match.params.page,
    baseUrl: ["pages"],
    baseDashboardUrl: `/innhold/${pageMeta.plural}`,
    publishEndpoint: "publish",
    discardEndpoint: "discard",
    cleanField: cleanRefs([{ key: "plug", refPath: "image.ref" }]),
    initialData: {
      isCategory,
      isProductPage,
      visibility: EntityVisibility.Public,
      inStandardCatalog: true,
      entityStatus: EntityStatus.Inactive
    },
    pathsToCopy: [
      {
        path: "title",
        label: "Tittel"
      },
      {
        path: "preamble",
        label: "Ingress"
      },
      {
        path: "featuredImage",
        label: "Uthevet bilde"
      },
      {
        path: "template",
        label: "Mal"
      },
      {
        path: "components",
        label: "Innhold"
      },
      {
        path: "visibility",
        label: "Tilgjengelighet"
      },
      {
        path: "password",
        label: "Passord"
      },
      {
        path: "seo",
        label: "SEO"
      },
      {
        path: "plug",
        label: "Plugg"
      },
      {
        path: "parent",
        label: "Forelder"
      },
      {
        path: "tags",
        label: "Emneknagger"
      },
      ...(site.b2bEnabled
        ? [
            {
              path: "inStandardCatalog",
              label: "Tilgjengelig i standardkatalog"
            }
          ]
        : [])
    ],
    bypassIgnoreKeys: ["plug", "featuredImage", "parent"],
    onPaste: (data) => {
      return {
        ...data,
        components: Array.isArray(data.components)
          ? data.components.map((c) => ({
              ...c,
              children: Array.isArray(c.children)
                ? c.children.map((cc) => {
                    const id = createId();

                    return {
                      ...cc,
                      _id: id,
                      internalId: id
                    };
                  })
                : undefined
            }))
          : undefined
      };
    }
  });

  const templateId = useMemo(() => {
    if (publisher.form.data.template && publisher.form.data.template.ref) {
      return publisher.form.data.template.ref;
    }

    if (publisher.form.data.slug === "index") {
      return site.templateDistribution.frontPage.ref;
    } else if (publisher.form.data.isCategory) {
      return site.templateDistribution.category.ref;
    } else if (publisher.form.data.isProductPage) {
      return site.templateDistribution.productPage.ref;
    }

    return site.templateDistribution.page.ref;
  }, [publisher.form.data, site.templateDistribution]);

  const templateDraftId = useMemo<string | undefined>(() => {
    return templatesSearch.results.find(
      (t) => t.site === site._id && t._id === templateId
    )?.draftRef;
  }, [templateId, templatesSearch.results, site._id]);

  useEffect(() => {
    breadcrumbs.swapEntry(match.params.page, publisher.form.data.title);
  }, [breadcrumbs, publisher.form.data, match.params.page]);

  const canonical = useCanonicalUrl([publisher.form.data.slug]);

  return (
    <Form onSubmit={publisher.form.submit}>
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url={`/innhold/${pageMeta.plural}`}>{pageMeta.plural}</Crumb>
            {PublisherHelpers.getResourceTitle(
              publisher,
              "title",
              `Ny ${pageMeta.singular}`
            )}
          </Text>
        </Block>
        <Block hugTop hugBottom>
          <InlineActions>
            <InlineActionLink
              exact
              to={PublisherHelpers.createDashboardUrl(publisher)}
            >
              <AiOutlineEdit /> Innhold
            </InlineActionLink>
            <InlineActionLink
              exact
              to={PublisherHelpers.createDashboardUrl(publisher, ["generelt"])}
            >
              <AiOutlineProfile /> Generelt
            </InlineActionLink>
            {!isProductPage && (
              <InlineActionLink
                exact
                to={PublisherHelpers.createDashboardUrl(publisher, [
                  "synlighet"
                ])}
              >
                <AiOutlineEye /> Synlighet
              </InlineActionLink>
            )}
            <InlineActionLink
              exact
              hideUntilActive
              to={PublisherHelpers.createDashboardUrl(publisher, ["kataloger"])}
            >
              <AiOutlineBook /> Kataloger
            </InlineActionLink>
            <ExpandableList
              items={[
                {
                  linkComponent: (
                    <Link
                      to={PublisherHelpers.createDashboardUrl(publisher, [
                        "kataloger"
                      ])}
                    >
                      Kataloger
                    </Link>
                  ),
                  hidden: !site.b2bEnabled && !site.departmentsEnabled
                },
                {
                  linkComponent: (
                    <Link to={`/innstillinger/maler/liste/${templateDraftId}`}>
                      Gå til mal
                    </Link>
                  ),
                  hidden: !templateDraftId
                },
                {
                  linkComponent: (
                    <a
                      href={canonical}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Vis {pageMeta.singular}
                    </a>
                  ),
                  hidden: publisher.isNew || isProductPage
                }
              ]}
            >
              Mer
            </ExpandableList>
            {!templateId && !publisher.form.loadingPrefill && (
              <InlineActionLink
                highlight
                warning
                exact
                to="/innstillinger/maler/bruk"
              >
                <AiOutlineWarning /> Mangler mal
              </InlineActionLink>
            )}
          </InlineActions>
        </Block>
        <Section hugTop>
          <Switch>
            <Route
              exact
              path={publisher.dashboardUrl}
              render={() => (
                <Content publisher={publisher} plural={pageMeta.plural} />
              )}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/generelt`}
              render={() => (
                <General
                  publisher={publisher}
                  isProductPage={isProductPage || false}
                  templates={templatesSearch.results}
                  templateId={templateId || undefined}
                />
              )}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/synlighet`}
              render={() => (
                <SEO
                  publisher={publisher}
                  isProductPage={isProductPage || false}
                  templates={templatesSearch.results}
                />
              )}
            />
            <Route
              exact
              path={`${publisher.dashboardUrl}/kataloger`}
              render={() => (
                <Catalogs
                  publisher={publisher}
                  isProductPage={isProductPage || false}
                  templates={templatesSearch.results}
                />
              )}
            />
          </Switch>
        </Section>
      </Fader>
      <ActionBar
        publisher={publisher}
        publish
        discard
        destroy
        revisions
        revisionReference={publisher.form.data.draftFor}
        revisionType={RevisionType.Page}
        experiment={publisher.experiment}
        showEntityStatus={!isProductPage}
      />
    </Form>
  );
};

interface ContentProps {
  publisher: UsePublisherInterface<IPage>;
  plural: string;
}

const Content: React.FC<ContentProps> = ({ publisher, plural }) => {
  const url = useApiUrl(["pages", publisher.form.data._id]);
  const previewToken = usePreviewToken();
  const revisionId = useRevisionId();
  const experimentId = useExperimentId();
  const baseDesignerUrl = `/designer/${plural}/${publisher.form.data._id}${window.location.search}`;

  const previewUrl = useMemo<string | undefined>(() => {
    if (!previewToken) {
      return undefined;
    }

    return (
      getBaseApiUrl() +
      "/" +
      STOREFRONT_ENDPOINT +
      "/" +
      url +
      "/render?preview=true&token=" +
      previewToken +
      (revisionId ? `&revision=${revisionId}` : "") +
      (!!experimentId
        ? `&experiment=${experimentId.experiment}&experimentVariant=${experimentId.experimentVariant}`
        : "")
    );
  }, [url, previewToken, revisionId, experimentId]);

  if (!previewUrl) {
    return null;
  }

  return (
    <Fader>
      <Preview
        id="preview"
        src={previewUrl}
        height="64vh"
        actionButtons={
          <ButtonLink outlined smaller to={baseDesignerUrl}>
            Rediger
          </ButtonLink>
        }
      />
    </Fader>
  );
};

interface PageProps {
  publisher: UsePublisherInterface<IPage>;
  isProductPage: boolean;
  templates: Template[];
  templateId?: string;
}

const General: React.FC<PageProps> = ({
  publisher,
  isProductPage,
  templates,
  templateId
}) => {
  const site = useSite();
  const form = publisher.form;
  const data = form.data;

  const exampleImage = useMemo(() => {
    const plugImage = form.getValue("plug.image");
    const featuredImage = form.getValue("featuredImage");

    if (plugImage && plugImage.ref) {
      return {
        url: plugImage.ref.secure_url,
        alt: "Eksempel"
      };
    } else if (featuredImage && featuredImage.ref) {
      return {
        url: featuredImage.ref.secure_url,
        alt: "Eksempel"
      };
    }

    return undefined;
  }, [form]);

  return (
    <Fader>
      <Flex align="flex-start">
        <FlexKid flex={2}>
          <Section hugTop>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Generelt
                  </Text>
                </Section>
                {createPublisherInputField(publisher, {
                  path: "title",
                  label: "Tittel",
                  type: "text",
                  required: true,
                  autosave: true
                })}
                {!isProductPage &&
                  !publisher.isNew &&
                  createPublisherInputField(publisher, {
                    path: "slug",
                    label: "Permalenke",
                    type: "text",
                    autosave: true
                  })}
                {createPublisherInputField(publisher, {
                  path: "preamble",
                  label: "Ingress",
                  type: "textarea",
                  autosave: true
                })}
                {createPublisherInputField(publisher, {
                  path: "featuredImage",
                  label: "Fremhevet bilde",
                  type: "media",
                  editableAlt: true,
                  autosave: true,
                  minimize: true
                })}
              </Block>
            </Card>
          </Section>
        </FlexKid>
        <FlexKid flex={1} spaceLeft>
          <Section hugTop>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Innstillinger
                  </Text>
                </Section>
                {!isProductPage &&
                  createPublisherInputField(publisher, {
                    path: "publishDate",
                    label: "Publiseringstidspunkt",
                    type: "time",
                    autosave: true
                  })}
                {createPublisherInputField(publisher, {
                  path: "template",
                  label: "Mal",
                  type: "select",
                  value: form.getValue("template.ref") || templateId,
                  setField: (value) => {
                    form.setField({
                      path: "template",
                      value: {
                        ref: value || null
                      },
                      submit: !publisher.isNew
                    });
                  },
                  options: templates.map((t) => ({
                    value: t._id,
                    label: t.site !== site._id ? `${t.name} – Global` : t.name
                  })),
                  autosave: true
                })}
                {!isProductPage && (
                  <Field>
                    <Label>Forelder</Label>
                    <PageSelector
                      page={form.getValue("parent")}
                      onChange={(page) => {
                        form.setField({
                          path: "parent",
                          value: page ? page : null,
                          submit: true
                        });
                      }}
                      queryParams={{
                        draft: false,
                        isProductPage: false,
                        fetchInherited: true
                      }}
                      readOnly={publisher.isRevision}
                      filter={(p) => p._id !== data.draftFor}
                    />
                  </Field>
                )}
                {!isProductPage && (
                  <Field hugBottom>
                    <Label htmlFor="tags">Emneknagger</Label>
                    <Tagger
                      tags={form.getValue("tags")}
                      readOnly={publisher.isRevision}
                      placeholder="Legg til emneknagg"
                      inline={false}
                      onChange={(tags) =>
                        form.setField({
                          path: "tags",
                          value: tags,
                          submit: !publisher.isNew
                        })
                      }
                    />
                  </Field>
                )}
              </Block>
            </Card>
          </Section>
        </FlexKid>
      </Flex>
      {!isProductPage && (
        <>
          <Ruler />
          <Flex align="flex-start">
            <FlexKid flex={2}>
              <Section hugTop>
                <Card>
                  <Block>
                    <Section hugTop>
                      <Text element="h2" variant="title">
                        Plugg
                      </Text>
                      <Text variant="body3" gutterTop>
                        Plugg er hvordan siden vil vises når den refereres til
                        av andre sider. For eksempel, i en artikkelliste på
                        forsiden, eller i et søkeresultat.
                      </Text>
                    </Section>
                    {createPublisherInputField(publisher, {
                      path: "plug.title",
                      label: "Tittel",
                      type: "text",
                      placeholder: form.getValue("title"),
                      autosave: true
                    })}
                    {createPublisherInputField(publisher, {
                      path: "plug.excerpt",
                      label: "Utdrag",
                      type: "textarea",
                      placeholder: form.getValue("preamble"),
                      autosave: true
                    })}
                    {createPublisherInputField(publisher, {
                      path: "plug.image",
                      label: "Bilde",
                      type: "media",
                      editableAlt: true,
                      autosave: true,
                      minimize: true
                    })}
                  </Block>
                </Card>
              </Section>
            </FlexKid>
            <FlexKid flex={1} spaceLeft>
              <Section hugTop>
                {!publisher.isNew && (
                  <Card outlined>
                    <Block>
                      <Text variant="subtitle" gutterBottom>
                        Eksempel på plugg
                      </Text>
                      {exampleImage && (
                        <PreviewImage
                          src={Image.createUrl({ image: exampleImage })}
                        />
                      )}
                      <Text variant="display1" gutterBottom>
                        {form.getValue("plug.title") || form.getValue("title")}
                      </Text>
                      <Text variant="body3" gutterBottom>
                        {form.getValue("plug.excerpt") ||
                          form.getValue("preamble")}
                      </Text>
                      <Text variant="body2">Les mer</Text>
                    </Block>
                  </Card>
                )}
              </Section>
            </FlexKid>
          </Flex>
        </>
      )}
    </Fader>
  );
};

const Catalogs: React.FC<PageProps> = ({ publisher }) => {
  const form = publisher.form;
  const data = form.data;
  const { spawnModal } = useModal();

  return (
    <Fader>
      <Section hugTop>
        <Card>
          <Block>
            {createPublisherInputField(publisher, {
              path: "inStandardCatalog",
              label: "Tilgjengelig i standardkatalog?",
              type: "boolean",
              autosave: true,
              hugTop: true
            })}
          </Block>
        </Card>
      </Section>
      <Section>
        <Card>
          <Block>
            <Flex>
              <FlexKid flex={1}>
                <Text element="h2" variant="title">
                  Kataloger
                </Text>
              </FlexKid>
              {!publisher.isRevision && (
                <FlexKid>
                  <Button
                    type="button"
                    outlined
                    onClick={() =>
                      spawnModal(
                        <AddCatalogModal
                          type={CatalogType.Content}
                          existingEntries={(data.catalogEntries || []).map(
                            (c) => {
                              if (typeof c.catalog === "string") {
                                return c.catalog;
                              }

                              return c.catalog._id;
                            }
                          )}
                          onSave={(values) => {
                            form.setField({
                              path: "catalogEntries",
                              value: [...values, ...data.catalogEntries],
                              submit: true
                            });
                          }}
                        />
                      )
                    }
                  >
                    Legg til i kataloger
                  </Button>
                </FlexKid>
              )}
            </Flex>
          </Block>
          {(data.catalogEntries || []).length === 0 ? (
            <>
              <Ruler />
              <Block hugTop>
                <Text variant="subheading">
                  Ikke tilgjengelig i noen kataloger
                </Text>
              </Block>
            </>
          ) : (
            data.catalogEntries.map((c) => (
              <CatalogRow
                key={c._id}
                catalogEntry={c}
                readOnly={publisher.isRevision}
                onDelete={() => {
                  if (!publisher.isRevision) {
                    form.setField({
                      path: "catalogEntries",
                      value: data.catalogEntries.filter(
                        (cc) => cc._id !== c._id
                      ),
                      submit: true
                    });
                  }
                }}
              />
            ))
          )}
        </Card>
      </Section>
    </Fader>
  );
};

interface CatalogRowProps {
  catalogEntry: PageCatalogEntry;
  readOnly: boolean;
  onDelete: () => any;
}

const CatalogRow: React.FC<CatalogRowProps> = ({
  catalogEntry,
  readOnly,
  onDelete
}) => {
  const catalog = catalogEntry.catalog as Catalog;

  return (
    <InlineEdit
      tight
      actions={[
        <Button
          type="button"
          aria-label="Fjern"
          circular
          outlined
          smaller
          disabled={readOnly}
          onClick={() => {
            if (readOnly) {
              return;
            }

            if (window.confirm("Er du sikker på at du vil fjerne?")) {
              onDelete();
            }
          }}
        >
          <AiOutlineDelete />
        </Button>
      ]}
      headerColumns={[
        {
          width: "320px",
          node: (
            <>
              <Text variant="body2">
                <Link to={`/innhold/innholdskataloger/${catalog._id}`}>
                  {catalog.name}
                </Link>
              </Text>
              <Text variant="body3">
                {catalog.description || "Mangler beskrivelse"}
              </Text>
            </>
          )
        }
      ]}
    />
  );
};

const SEO: React.FC<PageProps> = ({ publisher }) => {
  const form = publisher.form;
  const canonical = useCanonicalUrl([form.data.slug]);
  const site = useSite();

  return (
    <Fader>
      <Section hugTop>
        <Card>
          <Block>
            <Section hugTop>
              <Text element="h2" variant="title">
                Generelt
              </Text>
            </Section>
            {createPublisherInputField(publisher, {
              path: "visibility",
              label: "Tilgjengelighet",
              type: "radio",
              options: Object.keys(entityVisibility).map((k) => ({
                value: k,
                label: entityVisibility[k]
              })),
              autosave: true
            })}
            {form.data.visibility === EntityVisibility.Password && (
              <>
                {createInputField({
                  type: "text",
                  label: "Brukernavn",
                  value: site.name,
                  description: "Kan ikke endres.",
                  readOnly: true,
                  onChange: () => {},
                  key: "siteName"
                })}

                {createPublisherInputField(publisher, {
                  path: "password",
                  label: "Passord",
                  type: "text",
                  autosave: true
                })}
              </>
            )}
            {createPublisherInputField(publisher, {
              path: "blockRobots",
              label: "Blokker siden for roboter",
              type: "boolean",
              autosave: true
            })}
            {createPublisherInputField(publisher, {
              path: "customCanonicalEnabled",
              label: "Bruk egendefinert canonical-URL",
              type: "boolean",
              autosave: true
            })}
            {form.data.customCanonicalEnabled &&
              createPublisherInputField(publisher, {
                path: "customCanonicalUrl",
                label: "Canonical-URL",
                type: "url",
                required: true,
                placeholder: "https://eksempel.no/min-url",
                autosave: true
              })}
            {createPublisherInputField(publisher, {
              path: "redirectEnabled",
              label: "Redirect til URL",
              description:
                "Ved å skru på Redirect til URL, vil besøkende bli omdirigert til URL-en du skriver inn og ikke til siden.",
              type: "boolean",
              autosave: true
            })}
            {form.data.redirectEnabled && (
              <>
                {createPublisherInputField(publisher, {
                  path: "redirectUrl",
                  label: "Redirect-URL",
                  type: "url",
                  required: true,
                  placeholder: "https://eksempel.no/min-url",
                  autosave: true
                })}
                {createPublisherInputField(publisher, {
                  path: "redirectStatusCode",
                  label: "Redirect-statuskode",
                  type: "radio",
                  value: form.data.redirectStatusCode || 302,
                  options: [
                    {
                      value: 302,
                      label: "302 (midlertidig)"
                    },
                    {
                      value: 301,
                      label: "301 (permanent)"
                    }
                  ],
                  autosave: true
                })}
              </>
            )}
          </Block>
        </Card>
      </Section>
      <Ruler hugBottom />
      <Flex align="flex-start">
        <FlexKid flex={2}>
          <Section>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Google
                  </Text>
                </Section>
                {createPublisherInputField(publisher, {
                  path: "seo.metaTitle",
                  label: "Tittel",
                  type: "text",
                  placeholder: form.getValue("title"),
                  autosave: true,
                  helpText: (
                    <SEOHelper
                      limit={60}
                      value={
                        form.getValue("seo.metaTitle")
                          ? form.getValue("seo.metaTitle")
                          : form.getValue("title")
                          ? form.getValue("title")
                          : ""
                      }
                    />
                  )
                })}
                {createPublisherInputField(publisher, {
                  path: "seo.metaDescription",
                  label: "Beskrivelse",
                  type: "textarea",
                  placeholder: form.getValue("preamble"),
                  autosave: true,
                  helpText: (
                    <SEOHelper
                      limit={155}
                      value={
                        form.getValue("seo.metaDescription")
                          ? form.getValue("seo.metaDescription")
                          : form.getValue("preamble")
                          ? form.getValue("preamble")
                          : ""
                      }
                    />
                  )
                })}
                {createPublisherInputField(publisher, {
                  path: "seo.metaImage",
                  label: "Bilde",
                  type: "media",
                  setField: (v) => {
                    if (v && v.ref) {
                      form.setField({
                        path: "seo.metaImage",
                        value: v.ref,
                        submit: true
                      });
                    } else {
                      form.setField({
                        path: "seo.metaImage",
                        value: null,
                        submit: true
                      });
                    }
                  },
                  value: form.getValue("seo.metaImagePopulated")
                })}
              </Block>
            </Card>
          </Section>
          <Section>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Facebook
                  </Text>
                </Section>
                {createPublisherInputField(publisher, {
                  path: "seo.ogTitle",
                  label: "Tittel",
                  type: "text",
                  placeholder: form.getValue("title"),
                  autosave: true,
                  helpText: (
                    <SEOHelper
                      limit={60}
                      value={
                        form.getValue("seo.ogTitle")
                          ? form.getValue("seo.ogTitle")
                          : form.getValue("title")
                          ? form.getValue("title")
                          : ""
                      }
                    />
                  )
                })}
                {createPublisherInputField(publisher, {
                  path: "seo.ogDescription",
                  label: "Beskrivelse",
                  type: "textarea",
                  autosave: true,
                  placeholder: form.getValue("preamble"),
                  helpText: (
                    <SEOHelper
                      limit={200}
                      value={
                        form.getValue("seo.ogDescription")
                          ? form.getValue("seo.ogDescription")
                          : form.getValue("preamble")
                          ? form.getValue("preamble")
                          : ""
                      }
                    />
                  )
                })}
                {createPublisherInputField(publisher, {
                  path: "seo.ogImage",
                  label: "Bilde",
                  type: "media",
                  setField: (v) => {
                    if (v && v.ref) {
                      form.setField({
                        path: "seo.ogImage",
                        value: v.ref,
                        submit: true
                      });
                    } else {
                      form.setField({
                        path: "seo.ogImage",
                        value: null,
                        submit: true
                      });
                    }
                  },
                  value: form.getValue("seo.ogImagePopulated")
                })}
              </Block>
            </Card>
          </Section>
          <Section>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Twitter
                  </Text>
                </Section>
                {createPublisherInputField(publisher, {
                  path: "seo.twitterTitle",
                  label: "Tittel",
                  type: "text",
                  placeholder: form.getValue("title"),
                  autosave: true,
                  helpText: (
                    <SEOHelper
                      limit={70}
                      value={
                        form.getValue("seo.twitterTitle")
                          ? form.getValue("seo.twitterTitle")
                          : form.getValue("title")
                          ? form.getValue("title")
                          : ""
                      }
                    />
                  )
                })}
                {createPublisherInputField(publisher, {
                  path: "seo.twitterDescription",
                  label: "Beskrivelse",
                  type: "textarea",
                  autosave: true,
                  placeholder: form.getValue("preamble"),
                  helpText: (
                    <SEOHelper
                      limit={200}
                      value={
                        form.getValue("seo.twitterDescription")
                          ? form.getValue("seo.twitterDescription")
                          : form.getValue("preamble")
                          ? form.getValue("preamble")
                          : ""
                      }
                    />
                  )
                })}
                {createPublisherInputField(publisher, {
                  path: "seo.twitterImage",
                  label: "Bilde",
                  type: "media",
                  setField: (v) => {
                    if (v && v.ref) {
                      form.setField({
                        path: "seo.twitterImage",
                        value: v.ref,
                        submit: true
                      });
                    } else {
                      form.setField({
                        path: "seo.twitterImage",
                        value: null,
                        submit: true
                      });
                    }
                  },
                  value: form.getValue("seo.twitterImagePopulated")
                })}
              </Block>
            </Card>
          </Section>
        </FlexKid>
        <FlexKid flex={1} spaceLeft>
          <Section>
            <Card outlined>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Forhåndsvisning
                  </Text>
                </Section>
                <GooglePreview
                  url={canonical}
                  title={
                    form.getValue("seo.metaTitle") || form.getValue("title")
                  }
                  description={
                    form.getValue("seo.metaDescription") ||
                    form.getValue("preamble")
                  }
                />
              </Block>
            </Card>
          </Section>
        </FlexKid>
      </Flex>
    </Fader>
  );
};

export default Page;
