import React, { FunctionComponent, createContext, useState } from "react";

export interface Crumb {
  label: string;
  slug: string;
  path: string;
  onClick?: () => any;
}

const BreadcrumbsContext = createContext({
  crumbs: [] as Crumb[],
  setCrumbs: (crumbs: Crumb[]) => {}
});

const BreadcrumbsProvider: FunctionComponent = ({ children }) => {
  const [crumbs, setCrumbs] = useState<Crumb[]>([]);

  return (
    <BreadcrumbsContext.Provider
      value={{
        crumbs,
        setCrumbs
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export { BreadcrumbsContext, BreadcrumbsProvider };
