import React, { useEffect } from "react";
import { RouteComponentProps, Switch, Route, Prompt } from "react-router-dom";
import { Order as IOrder } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import Fader from "../../style-guide/Fader/Fader";
import Block from "../../style-guide/Block/Block";
import usePublisher from "../../hooks/usePublisher";
import Section from "../../style-guide/Section/Section";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import Crumb from "../../components/Crumb/Crumb";
import Form from "../../style-guide/Inputs/Form";
import ActionBar from "../../components/ActionBar/ActionBar";
import { OrderType, RevisionType } from "../../constants/api";
import { LEAVE_PAGE_MESSAGE } from "../../constants/general";
import General from "../Orders/SubViews/General";

interface Props
  extends RouteComponentProps<{
    quote: string;
  }> {}

const Quote: React.FC<Props> = ({ match }) => {
  const quoteId = match.params.quote;

  const breadcrumbs = useBreadcrumbs();

  const publisher = usePublisher<IOrder>({
    id: quoteId,
    baseUrl: ["orders"],
    baseDashboardUrl: "/b2b/tilbudsforesporsler",
    initialData: {
      type: OrderType.Quote,
      invoiceInformation: {},
      orderLines: []
    }
  });

  const form = publisher.form;

  useEffect(() => {
    breadcrumbs.swapEntry(quoteId, publisher.form.data.orderNumber);
  }, [breadcrumbs, publisher.form.data, quoteId]);

  return (
    <Form onSubmit={publisher.form.submit}>
      <Prompt
        when={form.hasMadeChanges}
        message={(location) => {
          if (location.pathname.includes(quoteId)) {
            return true;
          }

          return LEAVE_PAGE_MESSAGE;
        }}
      />
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/b2b/tilbudsforesporsler">Tilbudsforespørsel</Crumb>
            {form.data.orderNumber ? `#${form.data.orderNumber}` : "Laster…"}
          </Text>
        </Block>
        <Section>
          <Switch>
            <Route
              exact
              path={publisher.dashboardUrl}
              render={() => <General publisher={publisher} />}
            />
          </Switch>
        </Section>
      </Fader>
      <ActionBar
        publisher={publisher}
        revisions
        revisionType={RevisionType.Order}
        revisionReference={publisher.form.data._id}
      />
    </Form>
  );
};

export default Quote;
