import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";
import { Customer } from "../../types/apiResponses";
import Text from "../../style-guide/Text/Text";
import { ButtonLink } from "../../style-guide/Button/Button";
import Tabler from "../../components/Tabler/Tabler";
import { currencyFormat, numberFormat } from "../../util/intl";
import fallbackImage from "../../util/images/empty_preview.png";
import Avatar from "../../style-guide/Avatar/Avatar";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";

const Customers: React.FC = () => {
  return (
    <Tabler
      tight
      title="Kunder"
      queryField="name"
      queryLabel="Søk etter kunder"
      crumb={{
        label: "Butikk",
        path: "/butikk"
      }}
      actions={<ButtonLink to="kunder/opprett">Opprett kunde</ButtonLink>}
      emptyState={{
        title: "Ingen kunder enda",
        icon: <AiOutlineUser />,
        text: "Kunder i Rubics opprettes automatisk når de handler i nettbutikken din, men du kan også legge til kunder manuelt.",
        action: <ButtonLink to="kunder/opprett">Opprett kunde</ButtonLink>
      }}
      url={["customers"]}
      columns={[
        {
          Header: "Kunde",
          accessor: "title"
        },
        {
          Header: "Sist aktiv",
          accessor: "lastActive"
        },
        {
          Header: "Ordrer",
          accessor: "orders",
          align: "right"
        },
        {
          Header: "Ordresum",
          accessor: "ordersSum",
          align: "right"
        },
        {
          Header: "Gjennomsnittsum per ordre",
          accessor: "orderAverage",
          align: "right"
        }
      ]}
      createRow={(customer: Customer) => ({
        ...customer,
        title: <TitleRow customer={customer} />,
        lastActive: (
          <Text variant="body3">
            {customer.lastActive
              ? moment(customer.lastActive).fromNow()
              : "Aldri"}
          </Text>
        ),
        orders: (
          <Text variant="body3" align="right">
            {numberFormat.format(customer.orders)}
          </Text>
        ),
        ordersSum: (
          <Text variant="body3" align="right">
            {currencyFormat.format(customer.ordersSum)}
          </Text>
        ),
        orderAverage: (
          <Text variant="body3" align="right">
            {customer.orders > 0 && customer.ordersSum > 0
              ? currencyFormat.format(customer.ordersSum / customer.orders)
              : currencyFormat.format(0)}
          </Text>
        )
      })}
    />
  );
};

interface RowProps {
  customer: Customer;
}

const TitleRow: React.FC<RowProps> = ({ customer }) => {
  return (
    <Flex>
      <FlexKid centerContent>
        <Avatar size="36px" image={customer.profileImage || fallbackImage} />
      </FlexKid>
      <FlexKid spaceLeft tight>
        <Text variant="body2">
          <Link to={`/butikk/kunder/${customer._id}`}>{customer.name}</Link>
        </Text>
        <Text variant="body3">
          {customer.anonymous
            ? "Anonym"
            : customer.email || "Mangler e-postadresse"}
        </Text>
      </FlexKid>
    </Flex>
  );
};

export default Customers;
