import React from "react";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import { Customer } from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import Section from "../../../style-guide/Section/Section";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import createPublisherInputField from "../../../util/createPublisherInputField";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";

interface Props {
  publisher: UsePublisherInterface<Customer>;
}

const General: React.FC<Props> = ({ publisher }) => {
  return (
    <Fader>
      <Flex align="flex-start">
        <FlexKid flex={2}>
          <Card>
            <Block>
              <Section hugTop>
                <Text element="h2" variant="title">
                  Generelt
                </Text>
              </Section>
              {createPublisherInputField(publisher, {
                path: "name",
                label: "Navn",
                type: "text",
                required: true
              })}
              {createPublisherInputField(publisher, {
                path: "email",
                label: "E-post",
                type: "email",
                required: true
              })}
              {createPublisherInputField(publisher, {
                path: "phone",
                label: "Telefonnummer",
                type: "text"
              })}
              {publisher.isNew &&
                createPublisherInputField(publisher, {
                  path: "sendInvite",
                  label: "Send invitasjon på e-post",
                  type: "boolean"
                })}
            </Block>
          </Card>
        </FlexKid>
        <FlexKid flex={1} spaceLeft>
          <Card>
            <Block>
              <Section hugTop>
                <Text element="h2" variant="title">
                  Adresse
                </Text>
              </Section>
              {createPublisherInputField(publisher, {
                path: "address",
                label: "Adresse",
                type: "text"
              })}
              {createPublisherInputField(publisher, {
                path: "zip",
                label: "Postnummer",
                type: "text"
              })}
              {createPublisherInputField(publisher, {
                path: "zip",
                label: "Postnummer",
                type: "text"
              })}
              {createPublisherInputField(publisher, {
                path: "city",
                label: "Sted",
                type: "text"
              })}
            </Block>
          </Card>
        </FlexKid>
      </Flex>
    </Fader>
  );
};

export default General;
