import React, { useContext, useState } from "react";
import styled, { css } from "styled-components";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { EmailTemplate, Site as ISite } from "../../../../types/apiResponses";
import { SiteContext } from "../../../../context/Site";
import { UserContext } from "../../../../context/User";
import usePublisher from "../../../../hooks/usePublisher";
import OrderTemplateEdit from "./OrderTemplateEdit";
import Text from "../../../../style-guide/Text/Text";
import Fader from "../../../../style-guide/Fader/Fader";
import Block from "../../../../style-guide/Block/Block";
import Section from "../../../../style-guide/Section/Section";
import Card from "../../../../style-guide/Card/Card";
import EmptyState from "../../../../style-guide/EmptyState/EmptyState";
import { Radio } from "../../../../style-guide/Inputs/Radio";
import {
  Modal,
  ModalActions,
  ModalBody
} from "../../../../style-guide/Modal/Modal";
import { Button, ButtonList } from "../../../../style-guide/Button/Button";
import Form from "../../../../style-guide/Inputs/Form";

interface Props {
  templates: EmailTemplate[];
  selectedTemplate?: EmailTemplate;
  despawnModal: () => void;
}

const OrderTemplatesSelection: React.FC<Props> = ({
  templates = [],
  selectedTemplate,
  despawnModal
}) => {
  const siteContext = useContext(SiteContext);
  const user = useContext(UserContext).data;
  const modal = useModal();
  const [_selectedTemplate, _setSelectedTemplate] = useState(selectedTemplate);

  const publisher = usePublisher<ISite>({
    id: user.site!,
    baseUrl: ["site"],
    baseDashboardUrl: "/innstillinger/epostmaler",
    initialData: {
      emailTemplate: _selectedTemplate,
      emailTemplateProps: {}
    },
    skipId: true,
    onSubmit: (site: ISite) => {
      if (site) {
        siteContext.setData(site);
      }

      if (_selectedTemplate) {
        modal.spawnModal(
          <OrderTemplateEdit
            template={_selectedTemplate}
            despawnModal={modal.despawnAllModals}
          />
        );
      }
    }
  });

  return (
    <Form onSubmit={publisher.form.submit}>
      <Modal fullscreen>
        <ModalBody>
          <Fader>
            <Section>
              <Card>
                <Block>
                  <Section hugTop hugBottom>
                    {templates.length > 0 ? (
                      <Templates>
                        {templates.map((template) => {
                          const isActive =
                            template._id === siteContext.data.emailTemplate;

                          return (
                            <TemplateWrapper
                              key={template._id}
                              active={template._id === _selectedTemplate?._id}
                            >
                              <img
                                src={template.preview}
                                alt={template.displayName}
                              />
                              <TemplateContent>
                                <Text variant="body2">
                                  {template.displayName}
                                </Text>
                              </TemplateContent>
                              <TemplateActions>
                                <Radio
                                  label={isActive ? "Aktiv" : "Velg"}
                                  standalone
                                  disabled={isActive}
                                  name="emailTemplate"
                                  checked={
                                    template._id === _selectedTemplate?._id
                                  }
                                  onChange={() => {
                                    _setSelectedTemplate(template);
                                    publisher.form.setField({
                                      path: "emailTemplate",
                                      value: template._id,
                                      submit: false
                                    });
                                  }}
                                />
                              </TemplateActions>
                            </TemplateWrapper>
                          );
                        })}
                      </Templates>
                    ) : (
                      <EmptyState title="Ingen maler funnet for ordrekvitteringer" />
                    )}
                  </Section>
                </Block>
              </Card>
            </Section>
          </Fader>
        </ModalBody>
        <ModalActions>
          <ButtonList align="right">
            <Button outlined onClick={despawnModal}>
              Avbryt
            </Button>
            <Button
              type="submit"
              onClick={() =>
                publisher.form.setField({
                  path: "emailTemplateProps",
                  value: {}
                })
              }
              disabled={!publisher.form.hasMadeChanges}
            >
              Lagre
            </Button>
          </ButtonList>
        </ModalActions>
      </Modal>
    </Form>
  );
};

export default OrderTemplatesSelection;

const Templates = styled.div`
  display: grid;
  grid-gap: ${(props) => props.theme.spacing.medium};
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: auto;
`;

const TemplateWrapper = styled.div<{ active: boolean }>`
  width: 100%;
  height: auto;
  display: block;
  position: relative;
  color: ${(props) => props.theme.colors.textPrimary};
  text-decoration: none;
  border: 1px solid ${(props) => props.theme.colors.borderDim};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.12s ease-in-out;

  &:hover {
    text-decoration: none;
  }

  img {
    width: auto;
    height: 100%;
    max-width: 100%;
    max-height: 240px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  ${(props) =>
    props.active &&
    css`
      border-color: ${(props) => props.theme.colors.action};
      box-shadow: ${(props) => props.theme.shadows.large};
      transform: scale(1.04);
    `};
`;

const TemplateContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${(props) => props.theme.spacing.small};
  margin-top: auto;
`;

const TemplateActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.colors.borderDim};
  margin-top: auto;
  padding: ${(props) =>
    `${props.theme.spacing.xs} ${props.theme.spacing.small}`};
`;

export const Wrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 4px;
  display: flex;

  > * {
    &:first-child {
      flex: 3;
    }
    &:last-child {
      margin: 0 6px;
      flex: 1;
    }
  }
`;

export const Content = styled.div`
  padding: ${(props) =>
    `${props.theme.spacing.xs} ${props.theme.spacing.small}`};
  border-right: 1px solid ${(props) => props.theme.colors.borderDim};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const CustomEmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.border};
  width: 100%;
`;
