import React, { useCallback, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { AiOutlineDelete } from "react-icons/ai";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import { Catalog, Page as IPage } from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import { Button } from "../../../style-guide/Button/Button";
import {
  InlineEditHead,
  InlineEditColumn
} from "../../../style-guide/InlineEdit/InlineEdit";
import Ruler from "../../../style-guide/Ruler/Ruler";
import Section from "../../../style-guide/Section/Section";
import InlineEdit from "../../../components/InlineEdit/InlineEdit";
import CrudModal from "../../../components/CrudModal/CrudModal";
import { useSearch } from "../../../hooks/useApi";
import api, { Service } from "../../../util/api";
import BusyBoy from "../../../components/BusyBoy/BusyBoy";
import createPublisherInputField from "../../../util/createPublisherInputField";
import PagePicker, {
  PageTable
} from "../../../components/PagePicker/PagePicker";
import usePageMeta from "../../../hooks/usePageMeta";

interface Props {
  publisher: UsePublisherInterface<Catalog>;
}

const Content: React.FC<Props> = ({ publisher }) => {
  const form = publisher.form;

  const [loading, setLoading] = useState<boolean>(false);
  const { spawnModal } = useModal();
  const pageMeta = usePageMeta("page");

  const searchOpts = {
    endpoint: "pages",
    queryParams: {
      catalog: form.data._id
    }
  };

  const searcher = useSearch<IPage>(searchOpts);

  const togglePages = useCallback(
    async (action: "add" | "remove", ids: string[]) => {
      setLoading(true);

      await api({
        service: Service.Admin,
        method: "POST",
        endpoint: `catalogs/${form.data._id}/${action}-content`,
        body: {
          ids
        }
      });

      setLoading(false);

      searcher.search(searchOpts);
    },
    [form.data._id, searcher, searchOpts]
  );

  const addPageModal = useCallback(() => {
    spawnModal(
      <CrudModal
        title="Legg til innhold"
        fullscreen
        initialData={{}}
        onSubmit={(data) => {
          if (data && data.pages) {
            togglePages(
              "add",
              //@ts-ignore
              data.pages.map((p) => p._id)
            );
          }
        }}
        fields={[
          {
            key: "page",
            render: (payload) => (
              <PagePicker
                pageMeta={pageMeta}
                queryParams={{
                  isProductPage: false,
                  notInCatalog: form.data._id
                }}
                useWindow={false}
                getScrollParent={() => payload.modalBodyRef.current}
                renderView={(pages) => (
                  <PageTable
                    pageMeta={pageMeta}
                    pages={pages}
                    multipleSelect
                    onSelect={(pages) => payload.setField("pages", pages)}
                  />
                )}
              />
            )
          }
        ]}
      />
    );
  }, [form.data, togglePages]);

  return (
    <Fader>
      <BusyBoy busy={loading}>
        <Section>
          <Card>
            <Block>
              <Section hugTop>
                <Text element="h2" variant="title">
                  Generelt
                </Text>
              </Section>
              {createPublisherInputField(publisher, {
                path: "entrypoint",
                label: "Inngangsside",
                description: "Kun relevant for B2B-bedrifter",
                type: "ref",
                placeholder: "Søk etter en side",
                value:
                  form.data.entrypoint &&
                  typeof form.data.entrypoint !== "string"
                    ? form.data.entrypoint.title
                    : form.data.entrypoint,
                ref: {
                  url: ["pages"],
                  searchKey: "title",
                  titleKey: "title",
                  query: {
                    draft: false,
                    isProductPage: false
                  }
                }
              })}
            </Block>
          </Card>
        </Section>
        <Section>
          <Card>
            <Block>
              <Flex>
                <FlexKid flex={1}>
                  <Text element="h2" variant="title">
                    Innhold
                  </Text>
                </FlexKid>
                {!publisher.isRevision && (
                  <FlexKid spaceLeft>
                    <Button type="button" outlined onClick={addPageModal}>
                      Legg til innhold
                    </Button>
                  </FlexKid>
                )}
              </Flex>
            </Block>
            <InlineEditHead>
              <InlineEditColumn width="320px">Side/kategori</InlineEditColumn>
              <InlineEditColumn width="80px" alignRight />
            </InlineEditHead>
            <InfiniteScroll
              pageStart={0}
              loadMore={() =>
                searcher.search({
                  ...searchOpts,
                  paginate: true
                })
              }
              hasMore={searcher.hasMore}
            >
              {searcher.hasSearched && searcher.results.length > 0 ? (
                searcher.results.map((p) => (
                  <Page
                    key={p._id}
                    page={p}
                    onDelete={(page) => {
                      togglePages("remove", [page._id]);
                    }}
                  />
                ))
              ) : (
                <>
                  <Ruler />
                  <Block hugTop>
                    <Text variant="subheading">
                      Her er det ikke lagt til noe innhold enda
                    </Text>
                  </Block>
                </>
              )}
            </InfiniteScroll>
          </Card>
        </Section>
      </BusyBoy>
    </Fader>
  );
};

interface PageProps {
  page: IPage;
  onDelete: (page: IPage) => any;
}

const Page: React.FC<PageProps> = ({ page, onDelete }) => {
  return (
    <InlineEdit
      tight
      actions={[
        <Button
          type="button"
          aria-label="Fjern"
          circular
          outlined
          smaller
          onClick={() => {
            if (window.confirm("Er du sikker på at du vil fjerne?")) {
              onDelete(page);
            }
          }}
        >
          <AiOutlineDelete />
        </Button>
      ]}
      headerColumns={[
        {
          width: "320px",
          node: (
            <>
              <Text>{page.title}</Text>
              <Text variant="body3">{page.preamble || "Mangler ingress"}</Text>
            </>
          )
        }
      ]}
    />
  );
};

export default Content;
