import styled, { css } from "styled-components";

interface Props {
  size?: "normal" | "large";
}

const Icon = styled.span<Props>`
  ${props =>
    props.size &&
    css`
      font-size: ${props => props.theme.sizes.plusTwo};
    `}
`;

export default Icon;
