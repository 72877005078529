import React, { useEffect, useMemo } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import {
  SimpleVariant as ISimpleVariant,
  Product,
  AttributeTemplateAttribute
} from "../../../types/apiResponses";
import InlineEdit from "../../../components/InlineEdit/InlineEdit";
import useApiUrl from "../../../hooks/useApiUrl";
import useForm, { FieldSetter } from "../../../hooks/useForm";
import TextInput from "../../../style-guide/Inputs/TextInput";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import Select from "../../../style-guide/Inputs/Select";
import { ANY_ATTRIBUTE_VALUE_PLACEHOLDER } from "../../../constants/general";
import Block from "../../../style-guide/Block/Block";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import createInputField from "../../../util/createInputField";
import Section from "../../../style-guide/Section/Section";
import Text from "../../../style-guide/Text/Text";
import { Button } from "../../../style-guide/Button/Button";
import { useApi } from "../../../hooks/useApi";
import CustomizeOptions from "../../ProductTemplates/SubViews/CustomizeOptions";
import { backorders, stockStatus } from "../../../constants/localizations";
import { Backorders, StockStatus } from "../../../constants/api";

interface Props {
  publisher: UsePublisherInterface<Product>;
  variant: ISimpleVariant;
  attributes: AttributeTemplateAttribute[];
  isNew: boolean;
  onDelete: () => any;
}

const SimpleVariant: React.FC<Props> = ({
  publisher,
  variant,
  attributes,
  isNew,
  onDelete
}) => {
  const parent = publisher.form.data;

  const url = useApiUrl([
    "products",
    parent._id,
    "simple-variants",
    variant._id
  ]);

  const form = useForm<ISimpleVariant>(variant, {
    endpoint: url,
    method: "PATCH",
    onSuccess: () => {
      publisher.form.refresh();
    }
  });

  useEffect(() => {
    form.setData({
      data: variant
    });
  }, [variant]);

  const deleteVariant = useApi<null>({
    endpoint: url,
    method: "DELETE",
    initialData: null,
    onSuccess: () => {
      onDelete();
      publisher.form.refresh();
    }
  });

  useEffect(() => {
    publisher.setLoading(form.submitting || deleteVariant.loading);
  }, [form.submitting, deleteVariant.loading]);

  return (
    <InlineEdit
      headerColumns={[
        {
          width: "230px",
          node: (
            <TextInput
              id={`${variant._id}_name`}
              value={form.getValue("name")}
              readOnly={publisher.isRevision}
              placeholder={parent.name}
              autoFocus={isNew}
              onChange={(e) =>
                form.setField({
                  path: "name",
                  value: e.target.value,
                  submit: true,
                  debounce: true
                })
              }
            />
          )
        },
        ...attributes.map((a) => ({
          width: "180px",
          node: (
            <AttributeColumn
              attribute={a}
              readOnly={publisher.isRevision}
              variant={form.data}
              setField={form.setField}
            />
          )
        }))
      ]}
      actions={[
        <Button
          type="button"
          circular
          smaller
          outlined
          disabled={publisher.isRevision}
          onClick={() => {
            if (window.confirm("Er du sikker på at du vil slette varianten?")) {
              deleteVariant.fetch();
            }
          }}
          aria-label="Slett variant"
        >
          <AiOutlineDelete />
        </Button>
      ]}
    >
      <Block>
        <Flex align="top">
          <FlexKid flex={1}>
            {createInputField({
              type: "text",
              key: "name",
              label: "Navn",
              readOnly: publisher.isRevision,
              value: form.getValue("name"),
              placeholder: parent.name,
              hugTop: true,
              onChange: (value) =>
                form.setField({
                  path: "name",
                  value,
                  submit: true,
                  debounce: true
                })
            })}
            {createInputField({
              type: "textarea",
              key: "description",
              label: "Beskrivelse",
              readOnly: publisher.isRevision,
              value: form.getValue("description"),
              placeholder: parent.description,
              onChange: (value) =>
                form.setField({
                  path: "description",
                  value,
                  submit: true,
                  debounce: true
                })
            })}
            {createInputField({
              type: "boolean",
              key: "timeControlledAvailability",
              label: "Tidsstyrt tilgjengelighet",
              readOnly: publisher.isRevision,
              value: form.getValue("timeControlledAvailability"),
              onChange: (value) =>
                form.setField({
                  path: "timeControlledAvailability",
                  value,
                  submit: true,
                  debounce: true
                })
            })}
            {form.data.timeControlledAvailability && (
              <>
                {createInputField({
                  type: "time",
                  key: "availableFrom",
                  label: "Tilgjengelig fra",
                  readOnly: publisher.isRevision,
                  value: form.getValue("availableFrom"),
                  onChange: (value) =>
                    form.setField({
                      path: "availableFrom",
                      value,
                      submit: true,
                      debounce: true
                    })
                })}
                {createInputField({
                  type: "time",
                  key: "availableTo",
                  label: "Tilgjengelig til",
                  readOnly: publisher.isRevision,
                  value: form.getValue("availableTo"),
                  onChange: (value) =>
                    form.setField({
                      path: "availableTo",
                      value,
                      submit: true,
                      debounce: true
                    })
                })}
              </>
            )}
          </FlexKid>
          <FlexKid flex={1} spaceLeft>
            {createInputField({
              type: "number",
              key: "price.value",
              label: "Pris",
              readOnly: publisher.isRevision,
              value: form.getValue("price.value"),
              placeholder:
                typeof parent.price.value === "number"
                  ? `${parent.price.value}`
                  : undefined,
              hugTop: true,
              onChange: (value) =>
                form.setField({
                  path: "price.value",
                  value,
                  submit: true,
                  debounce: true
                })
            })}
            {createInputField({
              type: "number",
              key: "price.compareAtValue",
              label: "Tidligere pris",
              readOnly: publisher.isRevision,
              value: form.getValue("price.compareAtValue"),
              placeholder:
                typeof parent.price.compareAtValue === "number"
                  ? `${parent.price.compareAtValue}`
                  : undefined,
              onChange: (value) =>
                form.setField({
                  path: "price.compareAtValue",
                  value,
                  submit: true,
                  debounce: true
                })
            })}
            {createInputField({
              type: "text",
              key: "sku",
              label: "SKU",
              readOnly: publisher.isRevision,
              value: form.getValue("sku"),
              placeholder: parent.sku,
              onChange: (value) =>
                form.setField({
                  path: "sku",
                  value,
                  submit: true,
                  debounce: true
                })
            })}
            {!parent.manageStock ? (
              createInputField({
                type: "radio",
                key: "stockStatus",
                label: "Lagerstatus",
                readOnly: publisher.isRevision,
                value: form.getValue("stockStatus") || StockStatus.InStock,
                placeholder: parent.stockStatus,
                options: Object.keys(stockStatus).map((k) => ({
                  label: stockStatus[k],
                  value: k
                })),
                onChange: (value) =>
                  form.setField({
                    path: "stockStatus",
                    value,
                    submit: true,
                    debounce: true
                  })
              })
            ) : (
              <>
                {createInputField({
                  type: "number",
                  key: "stockCount",
                  label: "Lagerbeholdning",
                  readOnly: publisher.isRevision,
                  value: form.getValue("stockCount"),
                  placeholder: `${parent.stockCount}`,
                  onChange: (value) =>
                    form.setField({
                      path: "stockCount",
                      value,
                      submit: true,
                      debounce: true
                    })
                })}
                {createInputField({
                  type: "radio",
                  key: "backorders",
                  label: "Tillat restordre?",
                  readOnly: publisher.isRevision,
                  value: form.getValue("backorders") || "no",
                  placeholder: parent.backorders,
                  options: Object.keys(backorders)
                    // We don't support backorder notifications yet
                    .filter((k) => k !== Backorders.Notify)
                    .map((k) => ({
                      label: backorders[k],
                      value: k
                    })),
                  onChange: (value) =>
                    form.setField({
                      path: "backorders",
                      value,
                      submit: true,
                      debounce: true
                    })
                })}
              </>
            )}
          </FlexKid>
        </Flex>
        <Section hugBottom>
          <Text gutterBottom>Brukertilpasning</Text>
          <CustomizeOptions
            form={form}
            readOnly={publisher.isRevision}
            submitOnSet
            outline
            search
          />
        </Section>
      </Block>
    </InlineEdit>
  );
};

interface AttributeColumnProps {
  attribute: AttributeTemplateAttribute;
  variant: ISimpleVariant;
  setField: FieldSetter;
  readOnly?: boolean;
}

const AttributeColumn: React.FC<AttributeColumnProps> = ({
  attribute,
  variant,
  setField,
  readOnly
}) => {
  const variantAttribute = useMemo(
    () => variant.attributes.find((a) => a.name === attribute.name),
    [variant.attributes, attribute.name]
  );

  const option = useMemo(
    () =>
      variantAttribute
        ? attribute.options.find((o) => o.value === variantAttribute.value)
        : undefined,
    [variantAttribute, attribute.options]
  );

  return (
    <Select
      disabled={readOnly}
      value={
        option
          ? option._id
          : variantAttribute &&
            variantAttribute.value === ANY_ATTRIBUTE_VALUE_PLACEHOLDER
          ? variantAttribute.value
          : ""
      }
      onChange={(e) => {
        const option = attribute.options.find((o) => o._id === e.target.value);

        if (e.target.value === ANY_ATTRIBUTE_VALUE_PLACEHOLDER || option) {
          const value = {
            name: attribute.name,
            type: attribute.type,
            value: option ? option.value : ANY_ATTRIBUTE_VALUE_PLACEHOLDER,
            meta: option ? option.meta : undefined,
            categorization: option ? option.categorization : undefined
          };

          if (variantAttribute) {
            setField({
              path: "attributes",
              value: variant.attributes.map((a) => {
                if (a.name === attribute.name) {
                  return {
                    ...a,
                    ...value
                  };
                }

                return a;
              }),
              submit: true
            });
          } else {
            setField({
              path: "attributes",
              value: [...variant.attributes, value],
              submit: true
            });
          }
        } else if (!e.target.value) {
          setField({
            path: "attributes",
            value: variant.attributes.filter((a) => {
              if (a.name === attribute.name) {
                return false;
              }

              return true;
            }),
            submit: true
          });
        }
      }}
    >
      <option value="">Ingen verdi</option>
      <option value={ANY_ATTRIBUTE_VALUE_PLACEHOLDER}>Enhver verdi</option>
      {attribute.options.map((o) => (
        <option key={o._id} value={o._id}>
          {o.value}
        </option>
      ))}
    </Select>
  );
};

export default SimpleVariant;
