import styled, { css } from "styled-components";

interface FlexProps {
  align?: string;
  justify?: string;
  direction?: "column" | "row";
  flex?: number;
}

const Flex = styled.div<FlexProps>`
  display: flex;
  align-items: ${(props) => props.align || "center"};
  justify-content: ${(props) => props.justify || "flex-start"};

  ${(props) =>
    props.direction &&
    css`
      flex-direction: ${props.direction};
    `};

  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `};
`;

interface FlexKidProps {
  hugRight?: boolean;
  spaceLeft?: boolean;
  spaceRight?: boolean;
  centerContent?: boolean;
  alignSelf?: string;
  flex?: number;
  width?: string;
  maxWidth?: string;
  flexBasis?: string;
  tight?: boolean;
  borderLeft?: boolean;
}

const FlexKid = styled.div<FlexKidProps>`
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `};

  ${(props) =>
    props.flexBasis &&
    css`
      flex-basis: ${props.flexBasis};
    `};

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `};

  ${(props) =>
    props.hugRight &&
    css`
      margin-left: auto;
    `};

  ${(props) =>
    props.spaceLeft &&
    css`
      padding-left: ${props.tight
        ? props.theme.spacing.small
        : props.theme.spacing.medium};
    `};

  ${(props) =>
    props.spaceRight &&
    css`
      padding-right: ${props.tight
        ? props.theme.spacing.small
        : props.theme.spacing.medium};
    `};

  ${(props) =>
    props.centerContent &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `};

  ${(props) =>
    props.alignSelf &&
    css`
      align-self: ${props.alignSelf};
    `};

  ${(props) =>
    props.borderLeft &&
    css`
      border-left: 1px solid ${(props) => props.theme.colors.border};
    `};
`;

export { Flex, FlexKid };
