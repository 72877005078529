import styled, { css } from "styled-components";

export const powersearchBoxStyles = css`
  background: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.textPrimary};
  border: none;
  width: 100%;
`;

export const PowerSearchWrapper = styled.div`
  .visually-hidden {
    visibility: hidden;
  }
`;

export const Fixed = styled.div`
  position: fixed;
  width: min(60vw, 700px);
  top: 100px;
  left: 50%;
  translate: -50% 0;
  z-index: 10;
  
  input {
    background: ${props => props.theme.colors.background} !important;
  }
`;

export const InfoWrapper = styled.div`
  margin-top: 1rem;
  padding: .25rem 1.5rem;
  background: ${props => props.theme.colors.background}; 
  border-radius: ${props => props.theme.sizes.negOne};
`;

export const BackgroundDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%;
  background: rgba(0,0,0,.3);
`;

export const SearchWrapper = styled.ul`
  list-style-type: none;
  margin: 1rem 0 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: .25rem;
  background: ${props => props.theme.colors.background};
  border-radius: ${props => props.theme.sizes.negOne};

  >li:first-child {
    border-radius: ${props => props.theme.sizes.negOne} ${props => props.theme.sizes.negOne} 0 0;
  }
  >li:last-child {
    border-radius: 0 0 ${props => props.theme.sizes.negOne} ${props => props.theme.sizes.negOne};
  }
`;

export const SearchHits = styled.li<{ active: boolean; }>`
  ${powersearchBoxStyles};
  outline: none;
  display: grid;
  grid-template-columns: 25px 1fr;
  grid-template-rows: auto auto;
  align-items: center;
  column-gap: 1rem;
  >:first-child {
    svg {
      height: 20px;
      width: 20px;
    }
    grid-row: 1 / 3;
  }
  
  text-align: left;
  position: relative;
  padding: 1rem;
  height: 70px;

  text-decoration: none !important;
  
  h3, p {
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  p {
    font-size: .8rem;
    opacity: .8;
    margin-top: .25rem;
  }
  ${props => props.active && css`
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.textOnPrimary};
  `};
  &:focus {
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.textOnPrimary};
  }  
  &:hover {
    background: ${props => props.theme.colors.primary}DD;
    color: ${props => props.theme.colors.textOnPrimary};
    cursor: pointer;
  }
`;
