import React, { useEffect, useMemo } from "react";
import { RouteComponentProps, Prompt } from "react-router-dom";
import {
  DraggableProvidedDragHandleProps,
  DraggableStateSnapshot
} from "react-beautiful-dnd";
import { AiOutlineDelete } from "react-icons/ai";
import { MdDragHandle } from "react-icons/md";
import useBreadcrumbs from "../../../hooks/useBreadcrumbs";
import usePublisher from "../../../hooks/usePublisher";
import {
  PaymentPlan as IPaymentPlan,
  PaymentPlanItem
} from "../../../types/apiResponses";
import Form from "../../../style-guide/Inputs/Form";
import { LEAVE_PAGE_MESSAGE } from "../../../constants/general";
import Fader from "../../../style-guide/Fader/Fader";
import ActionBar from "../../../components/ActionBar/ActionBar";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import Crumb from "../../../components/Crumb/Crumb";
import PublisherHelpers from "../../../util/PublisherHelpers";
import Section from "../../../style-guide/Section/Section";
import Card from "../../../style-guide/Card/Card";
import createPublisherInputField from "../../../util/createPublisherInputField";
import {
  InlineEditHead,
  InlineEditColumn
} from "../../../style-guide/InlineEdit/InlineEdit";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import { Button } from "../../../style-guide/Button/Button";
import Ruler from "../../../style-guide/Ruler/Ruler";
import createId from "../../../util/createId";
import InlineEdit from "../../../components/InlineEdit/InlineEdit";
import createInputField from "../../../util/createInputField";
import { Dragger, Dropper } from "../../../components/DnD/DnD";

interface Props
  extends RouteComponentProps<{
    paymentPlan: string;
  }> {}

const PaymentPlan: React.FC<Props> = ({ match }) => {
  const paymentPlanId = match.params.paymentPlan;

  const breadcrumbs = useBreadcrumbs();

  const publisher = usePublisher<IPaymentPlan>({
    id: paymentPlanId,
    baseUrl: ["payment-plans"],
    baseDashboardUrl: "/innstillinger/delbetaling",
    initialData: {
      name: "",
      plan: []
    },
    pathsToCopy: [
      {
        path: "name",
        label: "Navn"
      },
      {
        path: "description",
        label: "Beskrivelse"
      },
      {
        path: "plan",
        label: "Betalingsplan"
      }
    ]
  });

  useEffect(() => {
    breadcrumbs.swapEntry(paymentPlanId, publisher.form.data.name);
  }, [breadcrumbs, publisher.form.data, paymentPlanId]);

  return (
    <Form onSubmit={publisher.form.submit}>
      <Prompt
        when={publisher.form.hasMadeChanges}
        message={(location) => {
          if (location.pathname.includes(paymentPlanId)) {
            return true;
          }

          return LEAVE_PAGE_MESSAGE;
        }}
      />
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/innstillinger/delbetaling">Delbetaling</Crumb>
            {PublisherHelpers.getResourceTitle(
              publisher,
              "name",
              "Ny betalingsplan"
            )}
          </Text>
        </Block>
        <Section>
          <Card>
            <Block>
              <Section hugTop>
                <Text element="h2" variant="title">
                  Generelt
                </Text>
              </Section>
              {createPublisherInputField(publisher, {
                path: "name",
                label: "Navn",
                type: "text",
                required: true
              })}
              {createPublisherInputField(publisher, {
                path: "description",
                label: "Beskrivelse",
                type: "textarea"
              })}
            </Block>
          </Card>
        </Section>
        <Section>
          <Card>
            <Block>
              <Flex>
                <FlexKid flex={1}>
                  <Text element="h2" variant="title">
                    Deler
                  </Text>
                </FlexKid>
                <FlexKid spaceLeft>
                  <Button
                    type="button"
                    outlined
                    onClick={() => {
                      const plans = publisher.form.data.plan || [];

                      publisher.form.setField({
                        path: "plan",
                        value: [
                          ...plans,
                          {
                            id: createId(),
                            percentage: plans.reduce((v, p) => {
                              return v - p.percentage;
                            }, 100)
                          }
                        ]
                      });
                    }}
                  >
                    Ny del
                  </Button>
                </FlexKid>
              </Flex>
            </Block>
            <InlineEditHead>
              <InlineEditColumn width="400px">Etikett</InlineEditColumn>
              <InlineEditColumn width="180px">
                Andel å betale (%)
              </InlineEditColumn>
              <InlineEditColumn width="180px">
                Dager til betaling
              </InlineEditColumn>
            </InlineEditHead>
            {publisher.form.data.plan.length > 0 ? (
              <Dropper
                id="planItems"
                onDragEnd={(r) => {
                  if (r.destination) {
                    const list = Array.from(publisher.form.data.plan || []);
                    const [removed] = list.splice(r.source.index, 1);

                    list.splice(r.destination.index, 0, removed);

                    publisher.form.setField({
                      path: "plan",
                      value: list.map((i, k) => {
                        if (k === 0) {
                          delete i.dueDays;
                        }

                        return i;
                      })
                    });
                  }
                }}
              >
                {publisher.form.data.plan.map((p, k) => (
                  <Dragger
                    //@ts-ignore
                    key={p._id || p.id}
                    //@ts-ignore
                    id={p._id || p.id}
                    index={k}
                    delegateDragHandle
                  >
                    <PlanItem
                      isNew={!p._id}
                      isFirst={k === 0}
                      item={p}
                      items={publisher.form.data.plan}
                      onChange={(data) =>
                        publisher.form.setField({
                          path: "plan",
                          value: (publisher.form.data.plan || []).map(
                            (pp, kk) => {
                              if (kk === k) {
                                return data;
                              }

                              return pp;
                            }
                          )
                        })
                      }
                      onDelete={() => {
                        if (
                          window.confirm("Er du sikker på at du vil slette?")
                        ) {
                          publisher.form.setField({
                            path: "plan",
                            value: (publisher.form.data.plan || []).filter(
                              (_, kk) => kk !== k
                            )
                          });
                        }
                      }}
                    />
                  </Dragger>
                ))}
              </Dropper>
            ) : (
              <>
                <Ruler />
                <Block hugTop>
                  <Text variant="subheading">Ingen deler funnet!</Text>
                </Block>
              </>
            )}
          </Card>
        </Section>
      </Fader>
      <ActionBar publisher={publisher} destroy />
    </Form>
  );
};

interface PlanItemProps {
  item: PaymentPlanItem;
  items: PaymentPlanItem[];
  isNew: boolean;
  isFirst: boolean;
  onChange: (item: PaymentPlanItem) => any;
  onDelete: () => any;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  dragState?: DraggableStateSnapshot;
}

const PlanItem: React.FC<PlanItemProps> = ({
  item,
  items,
  isNew,
  isFirst,
  onChange,
  onDelete,
  dragHandleProps,
  dragState
}) => {
  const minMaxValue = useMemo<number>(() => {
    return (
      items
        //@ts-ignore
        .filter((i) => i._id !== item._id || i.id !== item.id)
        .reduce((v, i) => {
          return v - i.percentage;
        }, 100)
    );
  }, [item, items]);

  return (
    <InlineEdit
      background={
        dragState && dragState.isDragging ? "backgroundVariant" : undefined
      }
      actions={[
        <Button
          type="button"
          aria-label="Slett"
          circular
          outlined
          smaller
          onClick={onDelete}
        >
          <AiOutlineDelete />
        </Button>,
        <Button
          type="button"
          aria-label="Endre rekkefølge"
          outlined
          circular
          smaller
          {...dragHandleProps}
        >
          <MdDragHandle />
        </Button>
      ]}
      headerColumns={[
        {
          width: "400px",
          node: createInputField({
            key: "label",
            type: "text",
            value: item.label,
            required: true,
            hugTop: true,
            autoFocus: isNew,
            onChange: (value) => {
              onChange({
                ...item,
                label: value
              });
            }
          })
        },
        {
          width: "180px",
          node: createInputField({
            key: "percentage",
            type: "number",
            value: item.percentage,
            required: true,
            hugTop: true,
            min: minMaxValue,
            max: minMaxValue,
            onChange: (value) => {
              onChange({
                ...item,
                percentage: value
              });
            }
          })
        },
        {
          width: "180px",
          node: isFirst
            ? null
            : createInputField({
                key: "dueDays",
                type: "number",
                value: item.dueDays,
                hugTop: true,
                required: true,
                onChange: (value) => {
                  onChange({
                    ...item,
                    dueDays: value
                  });
                }
              })
        }
      ]}
    />
  );
};

export default PaymentPlan;
