import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { AiOutlineClose, AiOutlineStar } from "react-icons/ai";
import { STOREFRONT_ENDPOINT } from "@ludens-reklame/rubics-sdk";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { Wrapper, Sidebar, Main } from "../../style-guide/Layout/Layout";
import { Elements, Element } from "../../style-guide/Elements/Elements";
import Block from "../../style-guide/Block/Block";
import Preview from "../../components/Preview/Preview";
import Text from "../../style-guide/Text/Text";
import Section from "../../style-guide/Section/Section";
import getIcon from "../../util/getIcon";
import usePreviewToken from "../../hooks/usePreviewToken";
import useApiUrl from "../../hooks/useApiUrl";
import useTheme from "../../hooks/useTheme";
import {
  Page,
  TemplateComponent,
  AppComponent
} from "../../types/apiResponses";
import Frame, { DashboardMessagePayload } from "../../util/Frame";
import SidebarPager from "../../components/SidebarPager/SidebarPager";
import Fader from "../../style-guide/Fader/Fader";
import Putter from "../../components/Putter/Putter";
import ActionBar from "../../components/ActionBar/ActionBar";
import usePublisher from "../../hooks/usePublisher";
import {
  Button,
  ButtonLink,
  ButtonList
} from "../../style-guide/Button/Button";
import RichContentEditor from "../../components/RichContentEditor/RichContentEditor";
import useThemeSwitcher from "../../hooks/useThemeSwitcher";
import useRevisionId from "../../hooks/useRevisionId";
import { RevisionType } from "../../constants/api";
import getBaseApiUrl from "../../util/getBaseApiUrl";
import useAppComponents from "../../hooks/useAppComponents";
import getComponentDescription from "../../util/getComponentDescription";
import EmptyState from "../../style-guide/EmptyState/EmptyState";
import Card from "../../style-guide/Card/Card";
import { Modal, ModalActions, ModalBody } from "../../style-guide/Modal/Modal";
import { RequestData } from "../../util/api";
import useExperimentId from "../../hooks/useExperimentId";
import useSite from "../../hooks/useSite";

interface ComponentInitiatePayload {
  parent?: string;
  component?: string;
}

interface Props
  extends RouteComponentProps<{
    page: string;
  }> {
  isCategory?: boolean;
  isProductPage?: boolean;
}

const PageDesigner: React.FC<Props> = ({
  match,
  isCategory,
  isProductPage
}) => {
  const pageId = match.params.page;
  const urlArray = ["pages"];

  const [editingRichContent, setEditingRichContent] = useState(false);
  const [editingRichContentComponent, setEditingRichContentComponent] =
    useState(undefined as TemplateComponent | undefined);
  const [editingRichContentIndex, setEditingRichContentIndex] = useState(-1);

  const experimentId = useExperimentId();
  const revisionId = useRevisionId();
  const url = useApiUrl([...urlArray, pageId]);
  const previewToken = usePreviewToken();
  const theme = useTheme();
  const appComponents = useAppComponents();
  const modal = useModal();
  const isExperiment = useMemo<boolean>(() => !!experimentId, [experimentId]);
  const site = useSite();

  const publisher = usePublisher<Page>({
    id: pageId,
    baseUrl: urlArray,
    baseDashboardUrl: "/designer/sider",
    publishEndpoint: "publish",
    discardEndpoint: "discard",
    onDeleteFallbackUrl: `/innhold/${
      isCategory ? "kategorier" : isProductPage ? "produktsider" : "sider"
    }`,
    onPublishDiscard: () => {
      const iframe = new Frame("preview");
      iframe.reload();
    }
  });

  const form = publisher.form;

  const [editingSection, setEditingSection] = useState(
    undefined as TemplateComponent | undefined
  );

  const components = useMemo<TemplateComponent[]>(() => {
    if (!form.data.template || !form.data.template.data) {
      return [];
    }

    return form.data.template.data.components.filter((c) => !!c.component);
  }, [form.data.template]);

  const themeComponents = useMemo<AppComponent[]>(() => {
    if (!theme) {
      return [];
    }

    return [...theme.components, ...appComponents.components].filter((c) => {
      if (c.name === "global:booking") {
        return site.bookingEnabled;
      }

      return true;
    });
  }, [theme, appComponents.components, site]);

  const component = useMemo<AppComponent | undefined>(() => {
    let component: AppComponent | undefined = undefined;

    if (!!editingSection) {
      return themeComponents.find((c) => c.name === editingSection.component);
    }

    return component;
  }, [editingSection, themeComponents]);

  useThemeSwitcher("light");

  const pageMeta = useMemo(() => {
    if (form.data.isCategory) {
      return {
        plural: "kategorier"
      };
    }

    if (form.data.isProductPage) {
      return {
        plural: "produktsider"
      };
    }

    return {
      plural: "sider"
    };
  }, [form.data.isCategory, form.data.isProductPage]);

  useEffect(() => {
    setEditingRichContent(false);
  }, [publisher.publishDiscardHash]);

  const previewUrl = useMemo<string>(
    () =>
      getBaseApiUrl() +
      "/" +
      STOREFRONT_ENDPOINT +
      "/" +
      url +
      "/render?preview=true&showControls=true&token=" +
      previewToken +
      (revisionId ? `&revision=${revisionId}` : "") +
      (!!experimentId
        ? `&experiment=${experimentId.experiment}&experimentVariant=${experimentId.experimentVariant}`
        : ""),
    [url, previewToken, revisionId, experimentId]
  );

  const initiateComponent = useCallback(
    (data: ComponentInitiatePayload) => {
      const templateComponent = components.find((c) => {
        if (data.parent) {
          return c.name === data.parent;
        }

        return c.name === data.component;
      });

      if (templateComponent) {
        const pageComponent = form.data.components.find(
          (c) => c.parent === data.parent
        );

        if (data.parent) {
          const children =
            pageComponent &&
            Array.isArray(pageComponent.children) &&
            pageComponent.children.length > 0
              ? pageComponent.children
              : [];

          const child = children.find((c) => {
            return c.name === data.component;
          });

          const index = children.findIndex((c) => {
            return c.name === data.component;
          });

          if (child) {
            setEditingSection(templateComponent);
            setEditingRichContent(true);
            setEditingRichContentComponent(child);
            setEditingRichContentIndex(index);
          } else {
            modal.spawnModal(
              <Modal maxWidth="10rem">
                <ModalBody>
                  <Block>
                    <Text variant="title" gutterBottom>
                      Ups...
                    </Text>
                    <Text>
                      Denne seksjonen er publisert i malen{" "}
                      <strong>{form.data.template.data?.name}</strong>. Dersom
                      du ønsker å endre seksjonen må du gjøre det i malen.
                    </Text>
                  </Block>
                </ModalBody>
                <ModalActions>
                  <ButtonList align="right">
                    <Button
                      type="button"
                      outlined
                      onClick={() => {
                        modal.despawnModal();
                      }}
                    >
                      Lukk
                    </Button>
                  </ButtonList>
                </ModalActions>
              </Modal>
            );
          }
        } else {
          setEditingSection(templateComponent);
          setEditingRichContent(false);
          setEditingRichContentComponent(undefined);
          setEditingRichContentIndex(-1);
        }
      }
    },
    [components, form.data.components]
  );

  useEffect(() => {
    function handle(e: MessageEvent) {
      if (
        e.origin.endsWith(process.env.REACT_APP_RUBICS_DOMAIN || "rubics.as")
      ) {
        const payload: DashboardMessagePayload = e.data;

        if (payload.type === "openComponent") {
          initiateComponent(payload.data);
        }
      }
    }

    window.addEventListener("message", handle);

    return () => {
      window.removeEventListener("message", handle);
    };
  }, [initiateComponent]);

  const queryParams = useMemo<RequestData | undefined>(
    () =>
      isExperiment
        ? {
            experiment: experimentId!.experiment,
            experimentVariant: experimentId!.experimentVariant
          }
        : undefined,
    [isExperiment, experimentId]
  );

  if (!theme) {
    return null;
  }

  return (
    <Wrapper widerSidebar>
      <Sidebar wider>
        <Block>
          <Fader variant="left">
            <Section hugTop>
              <Text element="h1" variant="display3">
                {publisher.form.data.title}
              </Text>
            </Section>
          </Fader>
          {!editingSection && (
            <Fader variant="left">
              <Section>
                <ButtonLink
                  to={`/innhold/${pageMeta.plural}/${pageId}${window.location.search}`}
                  outlined
                >
                  <AiOutlineClose /> Lukk designer
                </ButtonLink>
              </Section>
              <Section>
                <Text element="h2" variant="title">
                  Seksjoner
                </Text>
              </Section>
              <Elements>
                {components
                  .filter((c) => !c.componentRef)
                  .map((c) => {
                    const component = !!c.component
                      ? themeComponents.find((tc) => tc.name === c.component)
                      : undefined;

                    if (
                      !component ||
                      ((!component.props || component.props.length < 1) &&
                        !component.richContent)
                    ) {
                      return null;
                    }

                    return (
                      <Element
                        key={c._id}
                        icon={
                          component ? (
                            component.appIcon ? (
                              <img
                                src={component.appIcon}
                                alt={component.appName}
                              />
                            ) : (
                              getIcon(component.icon)
                            )
                          ) : (
                            <AiOutlineStar />
                          )
                        }
                        biggerRightSpace
                        minHeight
                        subText={getComponentDescription(component)}
                        href="#"
                        rightIcon={<FaChevronRight />}
                        onClick={(e) => {
                          e.preventDefault();

                          if (!!c.component) {
                            setEditingSection(c);
                          }

                          const iframe = new Frame("preview");
                          iframe.scrollTo(c.name);
                        }}
                      >
                        {component ? component.label || component.name : null}
                      </Element>
                    );
                  })}
              </Elements>
              {form.data.template && (
                <Section>
                  <Card outlined>
                    <Block>
                      <Text element="h3" variant="body2">
                        Ønsker du å legge til eller fjerne seksjoner?
                      </Text>
                      <Text gutterTop>
                        Denne siden bruker malen{" "}
                        <strong>{form.data.template.data?.name}</strong>. Dersom
                        du ønsker å legge til, fjerne eller endre rekkefølge på
                        seksjoner, må du gjøre det i malen.
                      </Text>
                      <Text gutterTop>
                        <strong>OBS</strong>: Endringer du gjør i malen påvirker
                        alle sider som bruker den.
                      </Text>
                      <ButtonList gutterTop>
                        <ButtonLink
                          outlined
                          to={`/designer/maler/${form.data.template.data?._id}`}
                        >
                          Rediger mal
                        </ButtonLink>
                      </ButtonList>
                    </Block>
                  </Card>
                </Section>
              )}
            </Fader>
          )}
          {editingSection && (
            <>
              {!!component && !editingRichContent && (
                <SidebarPager onClose={() => setEditingSection(undefined)}>
                  <Section>
                    <Text element="h2" variant="title">
                      {component.label || component.name}
                    </Text>
                    <Text variant="body3">
                      {getComponentDescription(component)}
                    </Text>
                  </Section>
                  {Array.isArray(component.props) &&
                  component.props.length > 0 ? (
                    <Section>
                      <Putter
                        endpoint={url + "/components/" + editingSection.name}
                        prefillEndpoint={url}
                        prefillFn={(page) =>
                          page
                            ? (page as Page).components.find(
                                (c) => c.parent === editingSection.name
                              )
                            : undefined
                        }
                        method="PATCH"
                        props={component.props}
                        parent={editingSection.name}
                        pathPrefix="props"
                        previewPathPrefix="propsPopulated"
                        onSubmit={(page) => {
                          form.setData({ data: page as Page, reset: true });
                          const iframe = new Frame("preview");
                          iframe.reload();
                        }}
                        onLoad={publisher.setLoading}
                        refreshHash={publisher.publishDiscardHash}
                        queryParams={queryParams}
                        prefillQueryParams={queryParams}
                      />
                    </Section>
                  ) : !component.richContent ? (
                    <Card outlined>
                      <EmptyState
                        title="Denne seksjonen har ingen felter å endre!"
                        text={
                          <span>
                            Det kan være seksjonen henter innhold fra{" "}
                            <strong>
                              <Link to="/designer/globalt">
                                Globalt innhold
                              </Link>
                            </strong>
                            . Prøv der!
                          </span>
                        }
                        smaller
                      />
                    </Card>
                  ) : null}
                </SidebarPager>
              )}
              {!!component && component.richContent && (
                <RichContentEditor
                  key={publisher.publishDiscardHash}
                  endpoint={url + "/components/" + editingSection.name}
                  components={themeComponents}
                  prefillEndpoint={url}
                  prefillFn={(page) =>
                    page
                      ? (page as Page).components.find(
                          (c) => c.parent === editingSection.name
                        )
                      : undefined
                  }
                  onSubmit={(page) => {
                    form.setData({ data: page as Page, reset: true });
                    const iframe = new Frame("preview");
                    iframe.reload();
                  }}
                  parent={editingSection.name}
                  onClose={() => setEditingSection(undefined)}
                  onLoad={publisher.setLoading}
                  onNavigateComponent={(exit) => setEditingRichContent(!exit)}
                  initialEditingSection={editingRichContentComponent}
                  initialEditingIndex={editingRichContentIndex}
                  queryParams={queryParams}
                  prefillQueryParams={queryParams}
                />
              )}
            </>
          )}
        </Block>
      </Sidebar>
      <Main noMaxWidth fullColumn>
        <Section>
          {previewToken && (
            <Fader>
              <Preview id="preview" src={previewUrl} />
            </Fader>
          )}
        </Section>
        <ActionBar
          publisher={publisher}
          discard
          publish
          destroy
          revisions
          revisionReference={form.data.draftFor}
          revisionType={RevisionType.Page}
          experiment={experimentId?.experiment}
          showEntityStatus
        />
      </Main>
    </Wrapper>
  );
};

export default PageDesigner;
