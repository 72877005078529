import React, { useContext } from "react";
import { AiOutlineSave } from "react-icons/ai";
import { EmailTemplate, Site } from "../../../../types/apiResponses";
import { SiteContext } from "../../../../context/Site";
import useNotifications from "../../../../hooks/useNotifications";
import useForm from "../../../../hooks/useForm";
import BusyBoy from "../../../../components/BusyBoy/BusyBoy";
import Putter from "../../../../components/Putter/Putter";
import Text from "../../../../style-guide/Text/Text";
import Form from "../../../../style-guide/Inputs/Form";
import Fader from "../../../../style-guide/Fader/Fader";
import Block from "../../../../style-guide/Block/Block";
import Section from "../../../../style-guide/Section/Section";
import Card from "../../../../style-guide/Card/Card";
import { Button, ButtonList } from "../../../../style-guide/Button/Button";
import {
  Modal,
  ModalActions,
  ModalBody
} from "../../../../style-guide/Modal/Modal";
import EmptyState from "../../../../style-guide/EmptyState/EmptyState";

interface Props {
  template: EmailTemplate;
  despawnModal: () => void;
}

const OrderTemplateEdit: React.FC<Props> = ({ template, despawnModal }) => {
  const siteContext = useContext(SiteContext);
  const { spawn } = useNotifications();
  const form = useForm(
    {},
    {
      endpoint: "site",
      method: "PATCH",
      prefillEndpoint: "site",
      onSuccess: (site) => {
        siteContext.setData(site as Site);

        spawn({
          title: "Endringer lagret",
          icon: <AiOutlineSave />
        });

        despawnModal();
      }
    }
  );

  const noTemplateProps: boolean = template.props.length === 0;

  return (
    <Form onSubmit={form.submit}>
      <Modal>
        <ModalBody>
          <Fader>
            <BusyBoy busy={form.loadingPrefill || form.submitting}>
              <Section hugTop hugBottom>
                <Card>
                  <img
                    src={template.preview}
                    alt={template.displayName}
                    width={650}
                  />
                  <Block>
                    <Section hugTop>
                      <Text variant="title">{template.displayName}</Text>
                    </Section>
                    <Section hugTop>
                      {noTemplateProps ? (
                        <Card outlined>
                          <EmptyState
                            title="Ingen felter å endre!"
                            text={
                              <span>
                                Du har ingen muligheter til å påvirke denne
                                malen.
                              </span>
                            }
                            smaller
                          />
                        </Card>
                      ) : (
                        <Putter
                          props={template.props}
                          pathPrefix="emailTemplateProps"
                          previewPathPrefix="emailTemplateProps"
                          dontCreateFormElement
                          dontAutoSave={true}
                          prefillEndpoint="site"
                          endpoint="site"
                          form={form}
                          method="PATCH"
                        />
                      )}
                    </Section>
                  </Block>
                </Card>
              </Section>
            </BusyBoy>
          </Fader>
        </ModalBody>
        <ModalActions>
          <ButtonList align="right">
            <Button outlined onClick={despawnModal}>
              Avbryt
            </Button>
            <Button type="submit" disabled={!form.hasMadeChanges}>
              Lagre
            </Button>
          </ButtonList>
        </ModalActions>
      </Modal>
    </Form>
  );
};

export default OrderTemplateEdit;
