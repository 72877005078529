import React, { useEffect, useState, useMemo, useCallback } from "react";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import {
  Discount,
  Page,
  ShippingClass,
  Product
} from "../../../types/apiResponses";
import Fader from "../../../style-guide/Fader/Fader";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Section from "../../../style-guide/Section/Section";
import Card from "../../../style-guide/Card/Card";
import Block from "../../../style-guide/Block/Block";
import Text from "../../../style-guide/Text/Text";
import createPublisherInputField from "../../../util/createPublisherInputField";
import {
  discountOfferType,
  discountAppliesToType
} from "../../../constants/localizations";
import createInputField from "../../../util/createInputField";
import { DiscountAppliesToType } from "../../../constants/api";
import Field from "../../../style-guide/Inputs/Field";
import Label from "../../../style-guide/Inputs/Label";
import Lister from "../../../components/Lister/Lister";
import { Element, Elements } from "../../../style-guide/Elements/Elements";
import CrudModal from "../../../components/CrudModal/CrudModal";
import ProductPicker, {
  ProductTable
} from "../../../components/ProductPicker/ProductPicker";
import OfflineLister from "../../../components/OfflineLister/OfflineLister";
import useSite from "../../../hooks/useSite";

interface Props {
  publisher: UsePublisherInterface<Discount>;
}

const AppliesTo: React.FC<Props> = ({ publisher }) => {
  const site = useSite();
  const form = publisher.form;
  const data = form.data;

  const [hasChangedValue, setHasChangedValue] = useState(false);
  const [useLimitations, setUseLimitations] = useState(false);

  const { spawnModal } = useModal();

  useEffect(() => {
    if (!publisher.isNew && !hasChangedValue) {
      setUseLimitations(typeof data.cartRules.limit === "number");
    }
  }, [publisher.isNew, hasChangedValue, data.cartRules]);

  const filterMeta = useMemo(() => {
    switch (data.appliesTo) {
      case DiscountAppliesToType.Categories:
        return {
          label: "Kategorier",
          searchEndpoint: ["pages"],
          searchKey: "title",
          labelKey: "title",
          query: {
            draft: false,
            isCategory: true
          },
          itemToString: (item: Page) => (item ? item.title : "")
        };
      default:
        return {
          label: "Produkter",
          searchEndpoint: ["products"],
          searchKey: "name",
          labelKey: "name",
          query: {
            draft: false
          },
          itemToString: (item: Product) => (item ? item.name : "")
        };
    }
  }, [data.appliesTo]);

  const addProductModal = useCallback(() => {
    spawnModal(
      <CrudModal
        title="Legg til produkter"
        initialData={{}}
        fullscreen
        onSubmit={(data) => {
          if (data && Array.isArray(data.products)) {
            form.setData({
              data: {
                ...form.data,
                appliesToValues: [
                  ...form.data.appliesToValues,
                  ...(data.products as Product[]).map((p) => p._id)
                ],
                populatedAppliesToValues: [
                  ...form.data.populatedAppliesToValues,
                  ...(data.products as Product[])
                ]
              }
            });
          }
        }}
        fields={[
          {
            key: "product",
            render: (payload) => (
              <ProductPicker
                useWindow={false}
                getScrollParent={() => payload.modalBodyRef.current}
                queryParams={{
                  draft: false
                }}
                filter={(p) => {
                  if (form.data.appliesToValues.includes(p._id)) {
                    return false;
                  }

                  return true;
                }}
                renderView={(products) => (
                  <ProductTable
                    products={products}
                    multipleSelect
                    onSelect={(products) =>
                      payload.setField("products", products)
                    }
                  />
                )}
              />
            )
          }
        ]}
      />
    );
  }, [form]);

  return (
    <Fader>
      <Flex align="flex-start">
        <FlexKid flex={1}>
          <Section hugTop>
            <Card>
              <Block>
                <Section hugTop>
                  <Text element="h2" variant="title">
                    Hva gjelder tilbudet for?
                  </Text>
                </Section>
                {createInputField({
                  key: "appliesTo",
                  label: "Tilbudet gjelder for",
                  type: "select",
                  options: Object.keys(discountAppliesToType).map((k) => ({
                    value: k,
                    label: discountAppliesToType[k]
                  })),
                  readOnly: publisher.isRevision,
                  value: form.getValue("appliesTo"),
                  onChange: (value) =>
                    form.setData({
                      data: {
                        ...data,
                        appliesTo: value,
                        appliesToValues: [],
                        populatedAppliesToValues: []
                      }
                    })
                })}
                {data.appliesTo !== DiscountAppliesToType.All &&
                  data.appliesTo !== DiscountAppliesToType.AllShipping &&
                  data.appliesTo !== DiscountAppliesToType.Products &&
                  data.appliesTo !== DiscountAppliesToType.ShippingClasses &&
                  data.appliesTo !==
                    DiscountAppliesToType.ShippingClassesExcluded && (
                    <Field hugBottom>
                      <Label htmlFor="appliesToValues">
                        {filterMeta.label}
                      </Label>
                      <Lister
                        id="appliesToValues"
                        url={filterMeta.searchEndpoint}
                        searchKey={filterMeta.searchKey}
                        labelKey={filterMeta.labelKey}
                        readOnly={publisher.isRevision}
                        query={filterMeta.query}
                        items={form.getValue("appliesToValues")}
                        previewItems={form.getValue("populatedAppliesToValues")}
                        itemToString={filterMeta.itemToString}
                        onChange={(items, previewItems) =>
                          form.setData({
                            data: {
                              ...data,
                              appliesToValues: items,
                              populatedAppliesToValues: previewItems
                            }
                          })
                        }
                      />
                    </Field>
                  )}
                {(data.appliesTo === DiscountAppliesToType.ShippingClasses ||
                  data.appliesTo ===
                    DiscountAppliesToType.ShippingClassesExcluded) && (
                  <Field hugBottom>
                    <Label>Fraktklasser</Label>
                    <OfflineLister
                      items={site.shippingClasses.map((s) => ({
                        _id: s._id,
                        value: s._id,
                        label: s.name
                      }))}
                      values={form.getValue("appliesToValues")}
                      onChange={(items) =>
                        form.setData({
                          data: {
                            ...data,
                            appliesToValues: items
                          }
                        })
                      }
                      readOnly={publisher.isRevision}
                    />
                  </Field>
                )}
                {data.appliesTo === DiscountAppliesToType.Products && (
                  <Field hugBottom>
                    <Label>{filterMeta.label}</Label>
                    <Elements>
                      {data.appliesToValues.map((v, k) => {
                        const populatedValue = (
                          data.populatedAppliesToValues as Product[]
                        ).find((p) => p._id === v);

                        if (!populatedValue) {
                          return null;
                        }

                        return (
                          <Element
                            key={populatedValue._id}
                            href="#"
                            rightIcon={<AiOutlineDelete />}
                            disabled={publisher.isRevision}
                            onClick={(e) => {
                              e.preventDefault();

                              if (publisher.isRevision) {
                                return;
                              }

                              if (
                                window.confirm(
                                  "Er du sikker på at du vil fjerne produktet?"
                                )
                              ) {
                                form.setData({
                                  data: {
                                    ...data,
                                    appliesToValues:
                                      data.appliesToValues.filter(
                                        (_, kk) => kk !== k
                                      )
                                  }
                                });
                              }
                            }}
                          >
                            <Text>{populatedValue.name}</Text>
                            <Text variant="body3">
                              {populatedValue.sku || "Mangler SKU"}
                            </Text>
                          </Element>
                        );
                      })}
                      <Element
                        icon={<AiOutlinePlus />}
                        href="#"
                        disabled={publisher.isRevision}
                        onClick={(e) => {
                          e.preventDefault();

                          if (publisher.isRevision) {
                            return;
                          }

                          addProductModal();
                        }}
                      >
                        Legg til produkter
                      </Element>
                    </Elements>
                  </Field>
                )}
              </Block>
            </Card>
          </Section>
        </FlexKid>
        <FlexKid flex={1} spaceLeft>
          {data.appliesTo !== DiscountAppliesToType.AllShipping &&
            data.appliesTo !== DiscountAppliesToType.ShippingClasses && (
              <Card>
                <Block>
                  <Section hugTop>
                    <Text element="h2" variant="title">
                      Begrensninger
                    </Text>
                  </Section>
                  {createInputField({
                    key: "useLimitations",
                    label: "Sett begrensning",
                    type: "boolean",
                    value: useLimitations,
                    readOnly: publisher.isRevision,
                    onChange: (value) => {
                      setUseLimitations(value);
                      setHasChangedValue(true);
                      form.setField({
                        path: "cartRules",
                        value: {
                          limit: null,
                          limitSelection: null
                        }
                      });
                    }
                  })}
                  {useLimitations && (
                    <>
                      {createPublisherInputField(publisher, {
                        path: "cartRules.limit",
                        label: "Antall per ordre (billigst)",
                        type: "number",
                        options: Object.keys(discountOfferType).map((k) => ({
                          value: k,
                          label: discountOfferType[k]
                        }))
                      })}
                    </>
                  )}
                </Block>
              </Card>
            )}
        </FlexKid>
      </Flex>
    </Fader>
  );
};

export default AppliesTo;
