import React, { useCallback, useEffect, useState } from "react";
import Calendar from "react-calendar";
import moment from "moment";
import { useSearch } from "../../hooks/useApi";
import useApiUrl from "../../hooks/useApiUrl";
import { BookingAvailableDate, BookingSlot } from "../../types/apiResponses";
import BusyBoy from "../BusyBoy/BusyBoy";
import { Flex, FlexKid } from "../../style-guide/Flex/Flex";
import Text from "../../style-guide/Text/Text";
import { Radio, Radios } from "../../style-guide/Inputs/Radio";
import Overflower from "../../style-guide/Overflower/Overflower";

interface Props {
  bookingServiceId: string;
  onSlotSelect: (slot: BookingSlot) => any;
}

const Scheduler: React.FC<Props> = ({ bookingServiceId, onSlotSelect }) => {
  const [start, setStart] = useState<Date>(moment().startOf("month").toDate());
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedSlot, setSelectedSlot] = useState<BookingSlot | null>(null);

  const availableDatesUrl = useApiUrl([
    "booking",
    "services",
    bookingServiceId,
    "available-dates"
  ]);

  const availableDatesSearch = useSearch<BookingAvailableDate>({
    endpoint: availableDatesUrl
  });

  const availabilityUrl = useApiUrl([
    "booking",
    "services",
    bookingServiceId,
    "availability"
  ]);

  const availabilitySearch = useSearch<BookingSlot>({
    endpoint: availabilityUrl
  });

  const handleViewChange = useCallback((date: Date) => {
    setStart(moment(date).startOf("month").toDate());
  }, []);

  const handleSelectDate = useCallback((date: Date) => {
    setSelectedDate(date);

    const momentDate = moment(date);

    availabilitySearch.search({
      endpoint: availabilityUrl,
      queryParams: {
        start: momentDate.startOf("day").toDate(),
        end: momentDate.endOf("day").toDate()
      }
    });
  }, []);

  const handleSelectSlot = useCallback(
    (slot: BookingSlot) => {
      setSelectedSlot(slot);
      onSlotSelect(slot);
    },
    [onSlotSelect]
  );

  useEffect(() => {
    availableDatesSearch.search({
      endpoint: availableDatesUrl,
      queryParams: {
        start,
        end: moment(start).endOf("month").toDate()
      }
    });
  }, [start, availableDatesUrl]);

  return (
    <Flex
      align={
        selectedDate && availabilitySearch.hasSearched ? "flex-start" : "center"
      }
    >
      <FlexKid width="250px" flex={1}>
        <BusyBoy busy={availableDatesSearch.loading}>
          <Calendar
            onViewChange={({ activeStartDate }) =>
              handleViewChange(activeStartDate)
            }
            onActiveStartDateChange={({ activeStartDate }) =>
              handleViewChange(activeStartDate)
            }
            onChange={handleSelectDate}
            tileDisabled={({ date, view }) => {
              if (view === "month") {
                if (
                  availableDatesSearch.results.some((r) =>
                    moment(r.date).isSame(date, "date")
                  )
                ) {
                  return false;
                }

                return true;
              }

              return false;
            }}
          />
        </BusyBoy>
      </FlexKid>
      <FlexKid width="250px" flex={1} spaceLeft>
        <BusyBoy busy={availabilitySearch.loading}>
          {selectedDate && availabilitySearch.hasSearched ? (
            <Overflower maxHeight="260px">
              <Radios>
                {availabilitySearch.results.map((s) => (
                  <Radio
                    key={s.start}
                    label={moment(s.start).format("HH:mm")}
                    checked={
                      selectedSlot ? selectedSlot.start === s.start : false
                    }
                    onChange={() => handleSelectSlot(s)}
                  />
                ))}
              </Radios>
            </Overflower>
          ) : (
            <Text align="center">Vennligst velg en dato til venstre.</Text>
          )}
        </BusyBoy>
      </FlexKid>
    </Flex>
  );
};

export default Scheduler;
