import React from "react";
import { Switch, Route } from "react-router";
import Media from "./Media";

const MediaRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/innhold/filer" component={Media} />
    </Switch>
  );
};

export default MediaRoutes;
