import styled from "styled-components";

interface Props {
  gap?: "small" | "medium";
  columns?: number;
}

const Grid = styled.div<Props>`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => props.columns || 4},
    minmax(0, 1fr)
  );
  grid-auto-rows: 1fr;
  grid-gap: ${(props) =>
    props.gap === "medium"
      ? props.theme.spacing.medium
      : props.theme.spacing.small};
`;

export default Grid;
