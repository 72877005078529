import { RubicsImage } from "@ludens-reklame/rubics-sdk";
import { ImageRef, Media } from "../types/apiResponses";

function convertImage(image: RubicsImage | ImageRef): RubicsImage {
  if (
    typeof image.ref !== "string" &&
    typeof image.ref !== "undefined" &&
    image.ref !== null
  ) {
    return {
      ...image,
      ref: (image.ref as Media)._id,
      url: (image.ref as Media).secure_url
    };
  }

  return image as RubicsImage;
}

export default convertImage;
