import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import {
  DraggableProvidedDragHandleProps,
  DraggableStateSnapshot
} from "react-beautiful-dnd";
import {
  Product as ProductClass,
  RubicsImage,
  Image as ImageClass
} from "@ludens-reklame/rubics-sdk";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import { MdDragHandle } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import { UsePublisherInterface } from "../../../hooks/usePublisher";
import { BundleContentEntry, Product } from "../../../types/apiResponses";
import Card from "../../../style-guide/Card/Card";
import { Button } from "../../../style-guide/Button/Button";
import Block from "../../../style-guide/Block/Block";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import Text from "../../../style-guide/Text/Text";
import Section from "../../../style-guide/Section/Section";
import {
  InlineEditColumn,
  InlineEditHead
} from "../../../style-guide/InlineEdit/InlineEdit";
import Ruler from "../../../style-guide/Ruler/Ruler";
import { Dragger, Dropper } from "../../../components/DnD/DnD";
import BundleProductSearchModal from "../Components/BundleProductSearchModal";
import InlineEdit from "../../../components/InlineEdit/InlineEdit";
import createInputField from "../../../util/createInputField";
import convertImage from "../../../util/convertImage";
import Image from "../../../style-guide/Image/Image";
import { currencyFormat } from "../../../util/intl";
import { DiscountOfferType } from "../../../constants/api";
import createPublisherInputField from "../../../util/createPublisherInputField";

interface Props {
  publisher: UsePublisherInterface<Product>;
}

const BundleContent: React.FC<Props> = ({ publisher }) => {
  const form = publisher.form;

  const { spawnModal } = useModal();

  return (
    <Section hugTop>
      <Card>
        <Block>
          <Text element="h2" variant="title">
            Pakke
          </Text>
          {createPublisherInputField(publisher, {
            path: "bundle",
            label: "Er pakke?",
            type: "boolean",
            autosave: true
          })}
        </Block>
        {form.data.bundle && (
          <>
            <Ruler hugBottom />
            <Block>
              <Flex>
                <FlexKid flex={1}>
                  <Text element="h3" variant="title">
                    Pakkeinnhold
                  </Text>
                </FlexKid>
                {!publisher.isRevision && (
                  <FlexKid>
                    <Button
                      type="button"
                      outlined
                      onClick={() => {
                        spawnModal(
                          <BundleProductSearchModal
                            bundleContent={form.data.bundleContent || []}
                            onSelect={(products) => {
                              form.setField({
                                path: "bundleContent",
                                value: [
                                  ...(form.data.bundleContent || []),
                                  ...products.map((p) => ({
                                    product: p._id,
                                    amount: 1
                                  }))
                                ],
                                submit: true
                              });
                            }}
                          />
                        );
                      }}
                    >
                      Legg til produkter
                    </Button>
                  </FlexKid>
                )}
              </Flex>
            </Block>
            <InlineEditHead>
              <InlineEditColumn width="240px">Produkt</InlineEditColumn>
              <InlineEditColumn width="100px">Antall</InlineEditColumn>
              <InlineEditColumn width="120px">
                Antall kan justeres?
              </InlineEditColumn>
              <InlineEditColumn width="120px">Pris</InlineEditColumn>
              <InlineEditColumn width="120px">Kostnad</InlineEditColumn>
            </InlineEditHead>
            <Dropper
              id="bundleContent"
              onDragEnd={(r) => {
                if (r.destination) {
                  const list = Array.from(form.data.bundleContent || []);
                  const [removed] = list.splice(r.source.index, 1);

                  list.splice(r.destination.index, 0, removed);

                  form.setField({
                    path: "bundleContent",
                    value: list,
                    submit: true
                  });
                }
              }}
            >
              {(form.data.bundleContent || []).length > 0 ? (
                form.data.bundleContent!.map((c, k) => {
                  return (
                    <Dragger
                      key={c._id}
                      id={c._id}
                      index={k}
                      delegateDragHandle
                      isDragDisabled={publisher.isRevision}
                    >
                      <BundledProduct
                        key={c._id}
                        bundledProduct={c}
                        readOnly={publisher.isRevision}
                        onChange={(data) =>
                          form.setField({
                            path: "bundleContent",
                            value: form.data.bundleContent!.map((cc, kk) => {
                              if (kk === k) {
                                return data;
                              }

                              return cc;
                            }),
                            submit: true
                          })
                        }
                        onDelete={() => {
                          if (
                            window.confirm(
                              "Er du sikker på at du vil fjerne produktet fra pakken?"
                            )
                          ) {
                            form.setField({
                              path: "bundleContent",
                              value: form.data.bundleContent!.filter(
                                (_, kk) => kk !== k
                              ),
                              submit: true
                            });
                          }
                        }}
                      />
                    </Dragger>
                  );
                })
              ) : (
                <>
                  <Ruler />
                  <Block hugTop>
                    <Text variant="subheading">
                      Ingen produkter lagt til i pakken
                    </Text>
                  </Block>
                </>
              )}
            </Dropper>
          </>
        )}
      </Card>
    </Section>
  );
};

interface BundledProductProps {
  bundledProduct: BundleContentEntry;
  onChange: (bundledProduct: BundleContentEntry) => any;
  onDelete: () => any;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  dragState?: DraggableStateSnapshot;
  readOnly?: boolean;
}

const BundledProduct: React.FC<BundledProductProps> = ({
  bundledProduct,
  onChange,
  onDelete,
  dragHandleProps,
  dragState,
  readOnly
}) => {
  const product = useMemo<Product | undefined>(() => {
    return !!bundledProduct.product &&
      typeof bundledProduct.product !== "string"
      ? bundledProduct.product
      : undefined;
  }, [bundledProduct]);

  const image = useMemo<RubicsImage | undefined>(() => {
    if (!product) {
      return undefined;
    }

    return ProductClass.getPrimaryImage({
      images: product.images.map(convertImage)
    });
  }, [product]);

  const imageUrl = useMemo<string>(
    () => ImageClass.createUrl({ image, transforms: "w_82,h_82,c_pad" }),
    [image]
  );

  const cost = useMemo<number>(() => {
    if (!product) {
      return 0;
    }

    let price = product.price.value;

    if (typeof bundledProduct.price === "number") {
      if (bundledProduct.priceType === DiscountOfferType.NewPrice) {
        price = bundledProduct.price;
      }
    }

    return price * bundledProduct.amount;
  }, [bundledProduct, product]);

  return (
    <InlineEdit
      background={
        dragState && dragState.isDragging ? "backgroundVariant" : undefined
      }
      alignItems="flex-start"
      actions={[
        <Button
          type="button"
          aria-label="Slett"
          circular
          outlined
          smaller
          disabled={readOnly}
          onClick={onDelete}
        >
          <AiOutlineDelete />
        </Button>,
        <Button
          type="button"
          aria-label="Endre rekkefølge"
          outlined
          circular
          smaller
          disabled={readOnly}
          {...dragHandleProps}
        >
          <MdDragHandle />
        </Button>
      ]}
      headerColumns={[
        {
          width: "240px",
          node: (
            <Flex>
              <FlexKid>
                <Image
                  width={41}
                  height={41}
                  src={imageUrl}
                  alt={image ? image.alt : "Mangler bilde"}
                />
              </FlexKid>
              <FlexKid flex={1} spaceLeft tight>
                {product ? (
                  <>
                    <Text variant="body2">
                      {product.draftRef ? (
                        <Link to={`/butikk/produkter/${product.draftRef}`}>
                          {product.name}
                        </Link>
                      ) : (
                        product.name
                      )}
                    </Text>
                    <Text variant="body3">{product.sku || "Mangler SKU"}</Text>
                  </>
                ) : (
                  <Text variant="body3">Produkt slettet</Text>
                )}
              </FlexKid>
            </Flex>
          )
        },
        {
          width: "100px",
          alignSelf: "center",
          node: createInputField({
            key: "amount",
            type: "number",
            hugTop: true,
            value: bundledProduct.amount,
            readOnly,
            onChange: (value) =>
              onChange({
                ...bundledProduct,
                amount: value
              })
          })
        },
        {
          width: "120px",
          alignSelf: "center",
          node: createInputField({
            key: "amountIsAdjustable",
            type: "boolean",
            hugTop: true,
            value: bundledProduct.amountIsAdjustable,
            readOnly,
            onChange: (value) =>
              onChange({
                ...bundledProduct,
                amountIsAdjustable: value
              })
          })
        },
        {
          width: "120px",
          alignSelf: "center",
          node: createInputField({
            key: "price",
            type: "number",
            hugTop: true,
            value: bundledProduct.price,
            placeholder:
              product && typeof product.price.value === "number"
                ? product.price.value.toString()
                : undefined,
            readOnly,
            onChange: (value) => {
              onChange({
                ...bundledProduct,
                price: value,
                priceType:
                  value.length > 0 ? DiscountOfferType.NewPrice : undefined
              });
            }
          })
        },
        {
          width: "120px",
          alignSelf: "center",
          node: <Text>{currencyFormat.format(cost)}</Text>
        }
      ]}
    />
  );
};

export default BundleContent;
