import styled, { css } from "styled-components";

interface Props {
  hugTop?: boolean;
  hugBottom?: boolean;
  buttons?: boolean;
}

const Field = styled.div<Props>`
  margin: ${(props) => props.theme.spacing.small} 0;

  ${(props) =>
    props.hugTop &&
    css`
      margin-top: 0;
    `}

  ${(props) =>
    props.hugBottom &&
    css`
      margin-bottom: 0;
    `}


  ${(props) =>
    props.buttons &&
    css`
      display: flex;
      justify-content: flex-end;
    `}

    > *:not(:last-child) {
    margin-right: ${(props) => props.theme.spacing.small};
  }
`;

const Fields = styled.div`
  display: flex;

  > *:first-child {
    flex: 3;
  }

  > *:nth-child(2) {
    flex: 7;
    margin-left: ${(props) => props.theme.spacing.xs};
  }
`;

export default Field;
export { Fields };
