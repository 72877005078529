import React, { ReactNode } from "react";
import {
  FaPalette,
  FaFont,
  FaBox,
  FaAd,
  FaCreditCard,
  FaList,
  FaGlobe
} from "react-icons/fa";
import {
  AiOutlineSmile,
  AiOutlineFileImage,
  AiOutlineInteraction,
  AiOutlineCode,
  AiOutlineTag,
  AiOutlineTags,
  AiOutlineShoppingCart,
  AiOutlineCreditCard,
  AiOutlineLock,
  AiOutlineLayout,
  AiOutlineVideoCamera,
  AiOutlineEdit,
  AiOutlineBorderBottom,
  AiOutlinePicture,
  AiOutlineCopy,
  AiOutlineUser,
  AiOutlineCalendar,
  AiOutlineForm,
  AiOutlineLink,
  AiOutlineShopping,
  AiOutlineExperiment,
  AiOutlineLineChart,
  AiOutlineAppstore,
  AiOutlineSetting,
  AiOutlineShop,
  AiOutlineCompass,
  AiOutlineUsergroupAdd,
  AiOutlineDoubleRight,
  AiOutlineKey,
  AiOutlineBank,
  AiOutlineFile, AiOutlineFileText, AiOutlineDollar, AiOutlinePartition
} from "react-icons/ai";
import { FiPackage, FiTruck } from "react-icons/fi";
import { RiBuildingLine, RiLayoutMasonryLine } from "react-icons/ri";
import { BsLightning } from "react-icons/bs";
import { MdBusiness, MdPalette } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";

function getIcon(type?: string): ReactNode {
  switch (type) {
    case "layout":
      return <AiOutlineLayout />;
    case "fonts":
      return <FaFont />;
    case "colors":
      return <FaPalette />;
    case "component":
      return <FaBox />;
    case "banner":
      return <FaAd />;
    case "header":
      return <AiOutlineSmile />;
    case "content":
      return <AiOutlineEdit />;
    case "richText":
      return <FaFont />;
    case "cart":
      return <AiOutlineShoppingCart />;
    case "shopping":
      return <AiOutlineShopping />;
    case "shop":
      return <AiOutlineShop />;
    case "checkout":
      return <AiOutlineCreditCard />;
    case "hero":
      return <AiOutlinePicture />;
    case "product":
      return <AiOutlineTag />;
    case "products":
      return <AiOutlineTags />;
    case "image":
      return <AiOutlineFileImage />;
    case "footer":
      return <AiOutlineBorderBottom />;
    case "pages":
      return <AiOutlineCopy />;
    case "login":
      return <AiOutlineLock />;
    case "cta":
      return <FaCreditCard />;
    case "video":
      return <AiOutlineVideoCamera />;
    case "list":
      return <FaList />;
    case "geo":
      return <FaGlobe />;
    case "placeholder":
      return <AiOutlineInteraction />;
    case "code":
      return <AiOutlineCode />;
    case "myPage":
      return <AiOutlineUser />;
    case "booking":
      return <AiOutlineCalendar />;
    case "form":
      return <AiOutlineForm />;
    case "link":
      return <AiOutlineLink />;
    case "cards":
      return <RiLayoutMasonryLine />;
    case "lightning":
      return <BsLightning />;
    case "experiment":
      return <AiOutlineExperiment />;
    case "chart":
      return <AiOutlineLineChart />;
    case "app":
      return <AiOutlineAppstore />;
    case "settings":
      return <AiOutlineSetting />;
    case "compass":
      return <AiOutlineCompass />;
    case "userGroupAdd":
      return <AiOutlineUsergroupAdd />;
    case "right":
      return <AiOutlineDoubleRight />;
    case "building":
      return <RiBuildingLine />;
    case "key":
      return <AiOutlineKey />;
    case "bank":
      return <AiOutlineBank />;
    case "file":
      return <AiOutlineFile />;
    case "fileText":
      return <AiOutlineFileText />;
    case "palette":
      return <MdPalette />;
    case "business":
      return <MdBusiness />;
    case "subscription":
      return <GiReceiveMoney />;
    case "dollar":
      return <AiOutlineDollar />;
    case "partialPayment":
      return <AiOutlinePartition />;
    case "freight":
      return <FiTruck />;
    default:
      return <FiPackage />;
  }
}

export default getIcon;
