import React, { useCallback, useContext } from "react";
import { RouteComponentProps, Prompt } from "react-router-dom";
import {
  Site as ISite,
  TaxClass as ITaxClass,
  TaxRate
} from "../../../types/apiResponses";
import Text from "../../../style-guide/Text/Text";
import Form from "../../../style-guide/Inputs/Form";
import Fader from "../../../style-guide/Fader/Fader";
import Block from "../../../style-guide/Block/Block";
import usePublisher from "../../../hooks/usePublisher";
import Section from "../../../style-guide/Section/Section";
import ActionBar from "../../../components/ActionBar/ActionBar";
import Card from "../../../style-guide/Card/Card";
import createPublisherInputField from "../../../util/createPublisherInputField";
import Crumb from "../../../components/Crumb/Crumb";
import { UserContext } from "../../../context/User";
import {
  DEFAULT_TAX_CLASS_NAME,
  LEAVE_PAGE_MESSAGE
} from "../../../constants/general";
import { RevisionType } from "../../../constants/api";
import { SiteContext } from "../../../context/Site";
import { Flex, FlexKid } from "../../../style-guide/Flex/Flex";
import { Button, ButtonList } from "../../../style-guide/Button/Button";
import createId from "../../../util/createId";
import {
  InlineEditColumn,
  InlineEditHead
} from "../../../style-guide/InlineEdit/InlineEdit";
import useModal from "@ludens-reklame/rubics-react/dist/hooks/useModal";
import CrudModal, {
  createCrudModalField
} from "../../../components/CrudModal/CrudModal";
import InlineEdit from "../../../components/InlineEdit/InlineEdit";
import { AiOutlineDelete } from "react-icons/ai";
import createInputField from "../../../util/createInputField";
import { Table, Tr, Th, Td } from "../../../style-guide/Table/Table";

interface Props extends RouteComponentProps<{}> {}

const Taxes: React.FC<Props> = () => {
  const user = useContext(UserContext).data;
  const siteContext = useContext(SiteContext);

  const publisher = usePublisher<ISite>({
    id: user.site!,
    baseUrl: ["site"],
    baseDashboardUrl: "/innstillinger/avgifter",
    skipId: true,
    pathsToCopy: [
      {
        path: "publishProductPriceExcludingTax",
        label: "Publiser priser uten avgifter"
      },
      {
        path: "displayProductPriceExcludingTax",
        label: "Vis produktpriser uten avgifter"
      },
      {
        path: "displayCartPriceExcludingTax",
        label: "Vis priser i handlekurven uten avgifter"
      }
    ],
    onSubmit: (site: ISite) => {
      if (site) {
        siteContext.setData(site);
      }
    }
  });

  return (
    <Form onSubmit={publisher.form.submit}>
      <Prompt
        when={publisher.form.hasMadeChanges}
        message={() => LEAVE_PAGE_MESSAGE}
      />
      <Fader>
        <Block hugTop hugBottom>
          <Text element="h1" variant="display3" gutterBottom>
            <Crumb url="/innstillinger">Innstillinger</Crumb>
            Avgifter
          </Text>
        </Block>
        <Section>
          <Card>
            <Block>
              <Section hugTop>
                <Text element="h2" variant="title">
                  Generelt
                </Text>
              </Section>
              {createPublisherInputField(publisher, {
                path: "publishProductPriceExcludingTax",
                label: "Publiser priser uten avgifter",
                type: "boolean"
              })}
              {createPublisherInputField(publisher, {
                path: "displayProductPriceExcludingTax",
                label: "Vis produktpriser uten avgifter",
                type: "boolean"
              })}
              {createPublisherInputField(publisher, {
                path: "displayCartPriceExcludingTax",
                label: "Vis priser i handlekurven uten avgifter",
                type: "boolean"
              })}
            </Block>
          </Card>
        </Section>
        <Section>
          <Card>
            <Block>
              <Flex>
                <FlexKid flex={1}>
                  <Text element="h2" variant="title">
                    Avgiftsklasser
                  </Text>
                </FlexKid>
                {!publisher.isRevision && (
                  <FlexKid spaceLeft>
                    <Button
                      type="button"
                      outlined
                      onClick={() => {
                        publisher.form.setField({
                          path: "taxClasses",
                          value: [
                            {
                              id: createId(),
                              name: "",
                              rates: []
                            },
                            ...(publisher.form.data.taxClasses || [])
                          ]
                        });
                      }}
                    >
                      Ny avgiftsklasse
                    </Button>
                  </FlexKid>
                )}
              </Flex>
            </Block>
            <InlineEditHead>
              <InlineEditColumn width="350px">Navn</InlineEditColumn>
              <InlineEditColumn width="230px">Beskrivelse</InlineEditColumn>
            </InlineEditHead>
            {(publisher.form.data.taxClasses || []).length > 0 ? (
              publisher.form.data.taxClasses.map((c, k) => (
                <TaxClass
                  //@ts-ignore
                  key={c._id || c.id}
                  taxClass={c}
                  isNew={!c._id}
                  onChange={(data) =>
                    publisher.form.setField({
                      path: "taxClasses",
                      value: (publisher.form.data.taxClasses || []).map(
                        (cc, kk) => {
                          if (kk === k) {
                            return data;
                          }

                          return cc;
                        }
                      )
                    })
                  }
                  onDelete={() => {
                    if (window.confirm("Er du sikker på at du vil slette?")) {
                      publisher.form.setField({
                        path: "taxClasses",
                        value: (publisher.form.data.taxClasses || []).filter(
                          (_, kk) => kk !== k
                        )
                      });
                    }
                  }}
                />
              ))
            ) : (
              <Block hugTop>
                <Text variant="subheading">Ingen avgiftsklasser funnet!</Text>
              </Block>
            )}
          </Card>
        </Section>
      </Fader>
      <ActionBar
        publisher={publisher}
        revisions
        revisionType={RevisionType.Site}
        revisionReference={publisher.form.data._id}
      />
    </Form>
  );
};

interface TaxClassProps {
  taxClass: ITaxClass;
  isNew: boolean;
  onChange: (taxClass: ITaxClass) => any;
  onDelete: () => any;
}

const TaxClass: React.FC<TaxClassProps> = ({
  taxClass,
  isNew,
  onChange,
  onDelete
}) => {
  const { spawnModal } = useModal();

  const addRateModal = useCallback(
    (rate?: TaxRate, key?: number) => {
      spawnModal(
        <CrudModal
          title={rate ? "Endre avgift" : "Legg til avgift"}
          initialData={rate || { internalId: createId() }}
          onSubmit={(data) => {
            if (rate) {
              onChange({
                ...taxClass,
                rates: taxClass.rates.map((r, k) => {
                  if (k === key) {
                    return data;
                  }

                  return r;
                })
              });
            } else
              onChange({
                ...taxClass,
                rates: [...taxClass.rates, data]
              });
          }}
          fields={[
            {
              key: "class",
              render: (payload) =>
                createCrudModalField({
                  payload,
                  key: "name",
                  type: "text",
                  label: "Navn",
                  required: true,
                  placeholder: "MVA"
                })
            },
            {
              key: "value",
              render: (payload) =>
                createCrudModalField({
                  payload,
                  key: "value",
                  type: "number",
                  label: "Sats",
                  required: true
                })
            },
            {
              key: "priority",
              render: (payload) =>
                createCrudModalField({
                  payload,
                  key: "priority",
                  type: "number",
                  label: "Prioritet",
                  required: true
                })
            },
            {
              key: "compound",
              render: (payload) =>
                createCrudModalField({
                  payload,
                  key: "compound",
                  type: "boolean",
                  label: "Sammensatt"
                })
            },
            {
              key: "shipping",
              render: (payload) =>
                createCrudModalField({
                  payload,
                  key: "shipping",
                  type: "boolean",
                  label: "Frakt"
                })
            }
          ]}
        />
      );
    },
    [taxClass]
  );

  return (
    <InlineEdit
      expanded={isNew}
      alignItems="flex-start"
      actions={[
        <Button
          type="button"
          aria-label="Slett"
          circular
          outlined
          smaller
          disabled={taxClass.name === DEFAULT_TAX_CLASS_NAME}
          onClick={onDelete}
        >
          <AiOutlineDelete />
        </Button>
      ]}
      headerColumns={[
        {
          width: "350px",
          alignSelf: "center",
          node: (
            <Text>
              {taxClass.name
                ? taxClass.name === DEFAULT_TAX_CLASS_NAME
                  ? "Standard"
                  : taxClass.name
                : "Mangler navn"}
            </Text>
          )
        },
        {
          width: "230px",
          alignSelf: "center",
          node: (
            <Text variant="body3">
              {taxClass.description || "Mangler beskrivelse"}
            </Text>
          )
        }
      ]}
    >
      <Block>
        {createInputField({
          key: "name",
          type: "text",
          label: "Navn",
          value:
            taxClass.name === DEFAULT_TAX_CLASS_NAME
              ? "Standard"
              : taxClass.name,
          required: true,
          hugTop: true,
          autoFocus: isNew,
          readOnly: taxClass.name === DEFAULT_TAX_CLASS_NAME,
          onChange: (value) => {
            onChange({
              ...taxClass,
              name: value
            });
          }
        })}
        {createInputField({
          key: "description",
          type: "textarea",
          label: "Beskrivelse",
          value: taxClass.description,
          onChange: (value) => {
            onChange({
              ...taxClass,
              description: value
            });
          }
        })}
        <Section hugBottom tight>
          <Section tight>
            <Flex>
              <FlexKid flex={1}>
                <Text>Avgifter</Text>
              </FlexKid>
              <FlexKid>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    addRateModal();
                  }}
                >
                  + Legg til avgift
                </a>
              </FlexKid>
            </Flex>
          </Section>
          <Card outlined>
            <Table>
              <thead>
                <Tr>
                  <Th>Navn</Th>
                  <Th align="right">Sats</Th>
                  <Th align="center">Prioritet</Th>
                  <Th align="center">Sammensatt</Th>
                  <Th align="center">Frakt</Th>
                  <Th align="right">Handlinger</Th>
                </Tr>
              </thead>
              <tbody>
                {taxClass.rates.length > 0 ? (
                  taxClass.rates.map((r, k) => {
                    return (
                      //@ts-ignore
                      <Tr key={r._id || r.internalId}>
                        <Td verticalAlign="middle">{r.name}</Td>
                        <Td verticalAlign="middle" align="right">
                          <Text variant="body3" align="right">
                            {r.value}%
                          </Text>
                        </Td>
                        <Td verticalAlign="middle" align="center">
                          <Text variant="body3" align="center">
                            {r.priority}
                          </Text>
                        </Td>
                        <Td verticalAlign="middle" align="center">
                          <Text variant="body3" align="center">
                            {r.compound ? "Ja" : "Nei"}
                          </Text>
                        </Td>
                        <Td verticalAlign="middle" align="center">
                          <Text variant="body3" align="center">
                            {r.shipping ? "Ja" : "Nei"}
                          </Text>
                        </Td>
                        <Td verticalAlign="middle" align="right">
                          <ButtonList align="right">
                            <Button
                              type="button"
                              outlined
                              onClick={() => addRateModal(r, k)}
                            >
                              Rediger
                            </Button>
                            <Button
                              type="button"
                              outlined
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Er du sikker på at du vil slette?"
                                  )
                                ) {
                                  onChange({
                                    ...taxClass,
                                    rates: taxClass.rates.filter(
                                      (_, kk) => kk !== k
                                    )
                                  });
                                }
                              }}
                            >
                              Slett
                            </Button>
                          </ButtonList>
                        </Td>
                      </Tr>
                    );
                  })
                ) : (
                  <Tr>
                    <Td colSpan={3}>
                      <Text variant="subheading">Ingen avgifter lagt til</Text>
                    </Td>
                  </Tr>
                )}
              </tbody>
            </Table>
          </Card>
        </Section>
      </Block>
    </InlineEdit>
  );
};

export default Taxes;
